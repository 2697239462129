import dictionaryService from '@/services/modules/dictionaryService'

export const localization = {
  clientsTable: {
    labels: {
      customerNumber: dictionaryService.translate(
        'ClientSimpleOverview.Labels.CustomerNumber'
      ) as string,
      customerName: dictionaryService.translate(
        'ClientSimpleOverview.Labels.CustomerName'
      ) as string,
      customerEmail: dictionaryService.translate(
        'ClientSimpleOverview.Labels.CustomerEmail'
      ) as string,
      relationManagerName: dictionaryService.translate(
        'ClientSimpleOverview.Labels.RelationManagerName'
      ) as string,
    }
  },
  labels: {
    close: dictionaryService.translate('Labels.Common.Close') as string,
    cancel: dictionaryService.translate('Labels.Common.Cancel') as string,
    save: dictionaryService.translate('Labels.Common.Save') as string,
    title: dictionaryService.translate('ClientSimpleOverview.Labels.Title') as string,
    searchRelationManager: dictionaryService.translate(
      'ClientSimpleOverview.Labels.SearchRelationManager'
    ) as string,
    downloadExcel: dictionaryService.translate(
      'ClientSimpleOverview.Labels.DownloadExcel'
    ) as string,
    downloadOrganizationsToExcel: dictionaryService.translate(
      'ClientSimpleOverview.Labels.DownloadOrganizationsToExcel'
    ) as string,
    downloadContactsToExcel: dictionaryService.translate(
      'ClientSimpleOverview.Labels.DownloadContactsToExcel'
    ) as string,
    searchPlaceholder: dictionaryService.translate(
      'ClientSimpleOverview.Labels.Search'
    ) as string,
    showInactiveClients: dictionaryService.translate(
      'ClientSimpleOverview.Labels.ShowInactiveClients'
    ) as string,
    addClient: dictionaryService.translate(
      'ClientSimpleOverview.Labels.AddClient'
    ) as string,
    createContact: dictionaryService.translate(
      'ClientSimpleOverview.Labels.CreateContact'
    ) as string,
    createOrganization: dictionaryService.translate(
      'ClientSimpleOverview.Labels.CreateOrganization'
    ) as string,
    pagePrevious: dictionaryService.translate('Labels.Common.PagingPrevious') as string,
    pageNext: dictionaryService.translate('Labels.Common.PagingNext') as string,
    pageOf: dictionaryService.translate('Labels.Common.PageXOfY') as string,
    email: dictionaryService.translate('ClientSimpleOverview.Labels.Email') as string,
    firstName: dictionaryService.translate(
      'ClientSimpleOverview.Labels.FirstName'
    ) as string,
    lastName: dictionaryService.translate(
      'ClientSimpleOverview.Labels.LastName'
    ) as string,
    gender: dictionaryService.translate('ClientSimpleOverview.Labels.Gender') as string,
    language: dictionaryService.translate(
      'ClientSimpleOverview.Labels.Language'
    ) as string,
    relationManager: dictionaryService.translate(
      'ClientSimpleOverview.Labels.RelationManagerName'
    ) as string,
    selectClientType: dictionaryService.translate(
      'ClientSimpleOverview.Labels.SelectClientType'
    ) as string,
    addOtherClient: dictionaryService.translate(
      'ClientSimpleOverview.Labels.AddOtherClient'
    ) as string,
    goToClient: dictionaryService.translate(
      'ClientSimpleOverview.Labels.GoToClient'
    ) as string
  },
  messages: {
    noResults: dictionaryService.translate(
      'ClientSimpleOverview.Messages.NoResults'
    ) as string,
    clientSuccessfullyCreated: dictionaryService.translate(
      'ClientSimpleOverview.Messages.ClientSuccessfullyCreated'
    ) as string
  }
}
