<template>
  <maz-fade-overlay :show-overlay="isActive">
    <div class="c-modal modal fade show d-block">
      <div
        v-click-outside="onClose"
        class="modal-dialog modal-dialog-centered"
        :class="[size]"
      >
        <transition name="slide-down">
          <div class="modal-content">
            <div class="modal-header">
              <slot name="modal-header">
                <h3 class="modal-title">{{ title }}</h3>
                <div @click="onClose" class="close" />
              </slot>
            </div>
            <div class="modal-body modal-body--margin-bottom">
              <slot />
            </div>
            <div class="o-separator o-separator--extra-small" />
            <div class="modal-footer d-flex flex-wrap flex-sm-nowrap">
              <slot name="modal-footer">
                <maz-button
                  :label="closeLabel"
                  @click.native="onClose"
                  class="c-btn c-btn--empty"
                />
              </slot>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </maz-fade-overlay>
</template>

<script>
import Services from '@/services'
import mazButton from '../mazButton'
import mazFadeOverlay from '../mazFadeOverlay'
import vClickOutside from 'v-click-outside'

export default {
  name: 'maz-modal',
  components: {
    'maz-button': mazButton,
    'maz-fade-overlay': mazFadeOverlay
  },
  directives: {
    clickOutside: vClickOutside.directive
  },

  data() {
    return {
      isActive: false
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    'close-label': {
      type: String,
      default: Services.dictionaryService.translate('Labels.Common.Cancel')
    },
    size: {
      type: String,
      default: 'modal-dialog',
      validator: size =>
        [
          'modal-dialog--auto-width',
          'modal-sm',
          'modal-md',
          'modal-lg',
          'modal-xl',
          'modal-dialog',
          'modal-dialog--video'
        ].indexOf(size) !== -1
    }
  },
  mounted() {
    this.isActive = true
    document.documentElement.classList.add('lightbox-open')
  },
  beforeDestroy() {
    this.isActive = false
    document.documentElement.classList.remove('lightbox-open')
  },
  methods: {
    onClose() {
      this.$emit('on-close', false)
    }
  }
}
</script>

<style scoped lang="scss">
//Most of the styling comes from bootstrap modal
.c-modal {
  overflow-y: scroll;
}
</style>
