import { currentGetterContext } from './index'
export const getters = {
  LOADER: (state: any): boolean => state.loader,
  TAB_NAVIGATION: (state: any) => state.tabNavigation,
  CLIENT_TITLE: (state: any) => state.clientTitle,
  CLIENT_NUMBER: (state: any) => state.clientNumber,
  LABELS(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.labels
  }
}
