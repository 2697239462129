<template>
  <div class="c-login-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-7">
          <h2>{{ config.Title }}</h2>
          <div class="c-login-body__video-preview d-lg-none">
            <transition name="fade">
              <div class="layover-image" v-show="!started">
                <fa :icon="['fal', 'play-circle']" @click="playMobileVideo" />
                <img :src="config.VideoImageUrl" alt="video-image-preview" />
              </div>
            </transition>
            <youtube
              ref="mobilePlayerRef"
              :video-id="videoId"
              :fit-parent="true"
              :resize="true"
            />
          </div>
          <div class="dynamic-text" v-html="config.Text" />
          <a v-if="config.CTAUrl" :href="config.CTAUrl">
            <maz-button class="c-btn c-btn--ghost">
              {{ config.CTATitle }}
            </maz-button>
          </a>
        </div>
        <div class="col-lg-5">
          <div class="c-login-body__video-preview d-none d-lg-block">
            <div class="layover-image">
              <fa :icon="['fal', 'play-circle']" @click="openVideoModal" />
              <img :src="config.VideoImageUrl" alt="video-image-preview" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <maz-modal @on-close="closeVideoModal" v-if="showModal" size="modal-dialog--video">
      <div>
        <youtube
          ref="desktopPlayerRef"
          @ready="onDesktopReady"
          :video-id="videoId"
          :resize="true"
          :fit-parent="true"
          :width="1136"
          :height="639"
        />
      </div>

      <!-- replaces default cancel button with empty space: -->
      <div slot="modal-footer" />
    </maz-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import VueYoutube from 'vue-youtube'
import { getIdFromUrl } from 'vue-youtube'
Vue.use(VueYoutube)

import mazButton from '@/components/general/mazButton/index.vue'
import mazModal from '@/components/general/mazModal/index.vue'

export default {
  name: 'mazClientLoginBody',

  components: {
    mazButton,
    mazModal
  },

  props: {
    config: {
      type: Object
    }
  },

  data() {
    return {
      started: false,
      showModal: false
    }
  },

  methods: {
    onDesktopReady() {
      this.$refs.desktopPlayerRef.player.playVideo()
    },
    openVideoModal() {
      this.showModal = true
    },
    closeVideoModal() {
      this.showModal = false
    },
    async playMobileVideo() {
      await this.mobilePlayer.playVideo().then(() => {
        this.started = true
      })
    }
  },

  computed: {
    mobilePlayer() {
      return this.$refs.mobilePlayerRef.player
    },
    videoId() {
      return getIdFromUrl(this.config.VideoUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styling/custom/settings/__main.scss';

.c-login-body {
  padding: 60px 0;
  background: $white-smoke;
  h2 {
    margin-bottom: 28px;
    font-weight: 500;
    font-size: 22px;
    font-family: 'Halyard-Medium', sans-serif;
    line-height: 24px;
    @include media-breakpoint-up(lg) {
      padding-right: 60px;
      font-size: 30px;
      line-height: 36px;
    }
  }
  .dynamic-text {
    margin-bottom: 32px;
    @include media-breakpoint-up(lg) {
      padding-right: 60px;
    }
  }
  a,
  .c-btn {
    @include media-breakpoint-up(lg) {
      display: inline-block;
      width: auto;
    }
    text-decoration: none;
  }
  .c-btn a:hover {
    text-decoration: none !important;
  }

  .c-btn--ghost {
    background-color: $white-smoke;
  }

  .c-login-body__video-preview {
    position: relative;
    z-index: 0;
    max-width: 100%;
    height: 0; //aspect ratio. see https://embedresponsively.com/
    margin-bottom: 22px;
    padding-bottom: 56.25%; //aspect ratio.
    overflow: hidden;
    user-select: none;
    .layover-image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      margin: auto;
      img {
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .fa-play-circle {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      margin: auto;
      color: white;
      font-size: 84px;
      cursor: pointer;
    }
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.15s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
