<template>
  <div class="d-md-flex justify-content-between qa-supply-request-details">
    <div class="d-lg-flex">
      <div class="text-nowrap pr-md-5 mb-3">
        <maz-audit-info
          :audit_due_date="supply_request_audit.dueDate"
          :audit_due_date_text="supply_request_audit.dueDateLabel"
          :audit_finacial_year="supply_request_audit.financialYear"
          :audit_type="supply_request_audit.type"
        />
      </div>
      <div class="pr-5">
        <maz-assign-contacts
          :labels="labels"
          :store="store"
          :supplyRequestAssignContacts="supplyRequestAssignContacts"
          @assignedContactsOnInitialLoad="getAssignContactsList"
          @onSubmitAssignedContacts="setAssignedContacts"
          ref="assignContacts"
          v-if="exists"
        />
      </div>
    </div>
    <div class="o-separator o-separator--medium d-md-none d-none d-sm-block" />
    <div class="o-separator o-separator--extra-small d-sm-none" />

    <div class="u-nowrap-sm">
      <maz-detail-action-buttons
        :audit_logs="supply_request_audit"
        :supply_request_permissions="supply_request_permissions"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import mazAuditInfo from '../../general/mazAuditInfo'
import mazDetailsActionButtons from '../../custom/mazDetailsActionButtons'
import mazAssignContacts from '../../general/mazAssignContacts'
import Services from '@/services'

const _targetStore = 'supplyRequest2DetailStore/'

export default {
  name: 'maz-supply-request-details',
  components: {
    'maz-audit-info': mazAuditInfo,
    'maz-detail-action-buttons': mazDetailsActionButtons,
    'maz-assign-contacts': mazAssignContacts
  },
  props: {
    supply_request_audit: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    details_audit_logs: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    supply_request_permissions: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      store: 'supplyRequest2DetailStore/'
    }
  },
  computed: {
    ...mapGetters({
      id: _targetStore + 'ID',
      exists: _targetStore + 'EXISTS',
      supplyRequestAssignContacts: _targetStore + 'SUPPLY_REQUEST_ASSIGN_CONTACTS',
      labels: _targetStore + 'LABELS'
    })
  },
  methods: {
    getAssignContactsList() {
      const body = {
        supplyRequestId: this.id
      }
      this.$store.dispatch(_targetStore + 'GET_ASSIGN_CONTACTS', body)
    },
    setAssignedContacts() {
      const body = {
        supplyRequestId: this.id,
        assignContactsIds: Services.assignContactsService.processAssignedContactsListIds(
          this.supplyRequestAssignContacts.assignedContactsList
        )
      }

      this.$store.dispatch(_targetStore + 'POST_ASSIGN_CONTACTS', body)
    }
  }
}
</script>
