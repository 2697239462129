//Check ./readme.md
//Don't register this store as a global store

import { createDirectStore } from 'direct-vuex'
import Vue from 'vue'
import Vuex from 'vuex'
import { clientMaintenance } from './modules/clientMaintenance'
import { clientMaintenanceAdd } from './modules/clientMaintenanceAdd'
import { clientMaintenanceOverviewStore } from './modules/clientMaintenanceOverview'
import { clientMyProfilePage } from './modules/clientMyProfilePage'
import { employeeAdd } from './modules/employeeAdd'
import { employeeMaintenance } from './modules/employeeMaintenance'
import { employeeOverview } from './modules/employeeOverview'
import supplyRequest2AddStore from './modules/supplyRequest2AddStore'
import { supplyRequest2DelegatedSegmentStore } from './modules/supplyRequest2DelegatedSegmentStore'
import supplyRequest2DetailStore from './modules/supplyRequest2DetailStore'
import supplyRequest2EditStore from './modules/supplyRequest2EditStore'
import supplyRequest2TemplateMaintenanceStore from './modules/supplyRequest2TemplateMaintenanceStore'

Vue.use(Vuex)

const { store, rootActionContext, moduleActionContext } = createDirectStore({
  modules: {
    clientMaintenanceOverviewStore: clientMaintenanceOverviewStore,
    clientMaintenance: clientMaintenance,
    clientMaintenanceAdd: clientMaintenanceAdd,
    clientMyProfilePage: clientMyProfilePage,
    employeeAdd: employeeAdd,
    employeeMaintenance: employeeMaintenance,
    employeeOverview: employeeOverview,
    supplyRequest2AddStore,
    supplyRequest2DetailStore,
    supplyRequest2EditStore,
    supplyRequest2TemplateMaintenanceStore,
    supplyRequest2DelegatedSegmentStore: supplyRequest2DelegatedSegmentStore
  }
})

export default store

export { rootActionContext, moduleActionContext }

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store
declare module 'vuex' {
  interface Store<S> {
    direct: AppStore
  }
}
