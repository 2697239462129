<template>
  <ul class="c-tab-navigation">
    <li
      :class="{'c-tab-navigation__item--is-active': index == activeTabIndex}"
      :key="index"
      @click="onTabClick(index)"
      class="c-tab-navigation__item"
      v-for="(label, key, index) in labels"
    >{{label}}</li>
  </ul>
</template>

<script>
export default {
  name: 'maz-tab-navigation',
  props: {
    labels: {
      required: true,
      type: Object
    },
    activeTabIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    onTabClick(index) {
      this.$emit('tab-click', index)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-tab-navigation {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 4px 4px 0;
  padding: 0;
  color: $white;
  font-size: 20px;
  font-family: 'Halyard-Medium', sans-serif;
  text-decoration: none;
  list-style: none;

  @include media-breakpoint-down(md) {
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    &::-webkit-scrollbar {
      width: 10px;
      height: 4px;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      height: 4px;
      background: $blue;
    }
  }

  .c-tab-navigation__item {
    margin-right: 2px;
    padding: 23px 24px;
    color: $dark-blue;
    background-color: $white;
    cursor: pointer;

    &:hover {
      background-color: $light-steel-blue;
    }
  }

  .c-tab-navigation__item--is-active {
    color: $dark-gray;
    background-color: $light-steel-blue;
  }
}
</style>
