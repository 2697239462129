import config from '@/api/modules/config'
import employeeMaintenanceApi from '@/api/modules/employeeMaintenanceApi'
import axiosService from '@/services/modules/axiosService'
import helpersService from '@/services/modules/helpersService'
import { currentActionContext } from './index'

const _targetApi = employeeMaintenanceApi
const _portController = 'EmployeeEmployeeJson'
const _baseEndpoint = config.endpointBase + _portController + '/'

export const actions = {
  GET_EMPLOYEE_MAINTENANCE_OVERVIEW_EMPLOYEE_LIST(
    ctx: any,
    payload: MazInterfaces.EmployeeMaintenanceOverview.GetEmployees
  ) {
    const { commit } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getOverview
    )
    const body = helpersService.convertObjectKeysToPascalCase(payload)
    commit.UPDATE_EMPLOYEE_OVERVIEW_LOADER_MUTATION(true)

    axiosService.post(endpoint, body, (res: any) => {
      if (res) {
        const resBody = helpersService.convertObjectKeysToCamelCase(res)
        commit.GET_EMPLOYEE_OVERVIEW_LIST_MUTATION(resBody.employees)
        commit.SET_EMPLOYEE_OVERVIEW_CURRENT_PAGE_MUTATION(resBody.currentPage)
        commit.SET_EMPLOYEE_OVERVIEW_MAX_PAGE_COUNTER_MUTATION(resBody.pageCount)
        commit.SET_EMPLOYEE_PERMISSION_MUTATION(resBody.employeePermission)
      }
      commit.UPDATE_EMPLOYEE_OVERVIEW_LOADER_MUTATION(false)
    })
  },
  SEARCH_EMPLOYEE_MAINTENANCE_OVERVIEW_EMPLOYEES(
    ctx: any,
    payload: MazInterfaces.EmployeeMaintenanceOverview.EmployeeSearchTerm
  ) {
    const { commit, dispatch } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.searchEmployees
    )
    const body = helpersService.convertObjectKeysToPascalCase(payload)
    axiosService.post(endpoint, body, (res: any) => {
      const resBody = helpersService.convertObjectKeysToCamelCase(res)
      commit.UPDATE_EMPLOYEE_OVERVIEW_SEARCH_EMPLOYEE_LIST_MUTATION(resBody.results)
    })
    //Reset pagination
    dispatch.UPDATE_EMPLOYEE_MAINTENANCE_OVERVIEW_CURRENT_PAGE(1)
  },
  UPDATE_EMPLOYEE_MAINTENANCE_OVERVIEW_CURRENT_PAGE(ctx: any, payload: number) {
    const { commit } = currentActionContext(ctx)
    commit.SET_EMPLOYEE_MAINTENANCE_OVERVIEW_CURRENT_PAGE_MUTATION(payload)
  }
}
