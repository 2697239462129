import clientMaintenancePermissionsColumnOrderService from '@/services/modules/clientMaintenancePermissionsColumnOrderService'
import helpersService from '@/services/modules/helpersService'
import { localization } from './localization'

export default () => ({
  id: helpersService.getSearchId('?oid=', window.location.search) as string,
  loader: {
    global: false,
    pendingActions: false,
    completedActions: false,
    organizationContactPermissions: false,
    clientServices: false,
    editOrganizationDetails: false,
    employees: false
  },
  clientDetails: {} as MazInterfaces.ClientMaintenance.OrganizationClientDetails,
  //TODO: Move "editedClientDetails" to the .vue page as it is not necessary to be in the vuex store
  editedClientDetails: {} as MazInterfaces.ClientMaintenance.OrganizationDetails,
  relationManagers: [] as MazInterfaces.ClientMaintenance.RelationManager[],
  countries: [] as MazInterfaces.ClientMaintenance.Countries[],
  employeesConnectedToOrganization: [] as object[],
  isEditMode: false,
  tabNavigation: {
    ...localization.tabNavigation, //TODO: Move localization.
    activeTabIndex: 0
  },
  activeCollapsiblePanels: {
    completedActions: false,
    pendingActions: true
  },
  pendingActionsTable: {
    ...localization.pendingActionsTable, //TODO: Move localization.
    rows: [] as object[],
    currentPage: 1,
    pageCount: 0
  },
  completedActionsTable: {
    ...localization.completedActionsTable, //TODO: Move localization.
    rows: [] as object[],
    currentPage: 1,
    pageCount: 0
  },
  organizationContactPermissionsTable: {
    ...localization.organizationContactPermissionsTable,
    rows: [] as object[],
    type: undefined
  },
  clientServicesTable: {
    labels: { ...localization.clientServicesTable.labels }, //TODO: Move localization.
    rows: [] as object[]
  },
  organizationContactPermissionsModal: {
    columns: clientMaintenancePermissionsColumnOrderService,
    details: {},
    labels: { ...localization.organizationContactPermissionsModal.labels } //TODO: Move localization.
  },
  contactsModal: {
    originalContactsList: [] as object[],
    selectedContactsList: [] as object[],
    availableContactList: [] as object[]
  },
  employeesModal: {
    originalEmployeesList: [] as object[],
    selectedEmployeesList: [] as object[],
    availableEmployeesList: [] as object[]
  },
  messages: { ...localization.messages },
  labels: { ...localization.labels },
  addContactUrl: '' as string,
  connectUrl: '' as string,
})
