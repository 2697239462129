<template>
  <div
    :class="placementClass"
    class="c-popover"
    ref="target-popover"
    v-click-outside="onClose"
  >
    <div @click="toggleShow">
      <slot name="parent-element" />
    </div>
    <div class="c-popover__container" v-if="isActive">
      <span class="c-popover__arrow" />
      <slot name="popover-container" />
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  name: 'maz-popover',

  directives: {
    clickOutside: vClickOutside.directive
  },

  data() {
    return {
      isActive: false
    }
  },
  props: {
    'popover-placement': {
      type: String,
      default: 'bottom'
    }
  },
  computed: {
    placementClass() {
      return `c-popover--is-${this.popoverPlacement.toLowerCase()}`
    }
  },
  methods: {
    toggleShow() {
      this.isActive = !this.isActive
      this.$emit('on-click', this.isActive)
    },
    onClose() {
      if (this.isActive) {
        this.isActive = !this.isActive
        this.$emit('on-click', this.isActive)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

// TODO: A variables file should be created for the spacing variables in order to
// make the spacing across the application consistent
$arrow-spacing: 8px;

.c-popover {
  position: relative;
  display: flex;

  //TODO: Refactor position of element without "position absolute"
  // try using flex
  .c-popover__container {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: $white;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2);

    & * {
      margin: 0 0 10px 0;
    }
  }

  .c-popover__arrow {
    position: absolute;
    top: -8px;
    align-self: center;
    width: 14px;
    height: 10px;
    margin: 0;
    background-image: url('~@/assets/images/icon_arrow_up.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.c-popover--is-top,
  &.c-popover--is-bottom {
    justify-content: center;

    .c-popover__container {
      align-items: stretch;
    }
  }
  &.c-popover--is-left,
  &.c-popover--is-right {
    align-items: center;

    .c-popover__container {
      align-items: stretch;
    }
  }

  &.c-popover--is-top {
    .c-popover__container {
      bottom: 140%;
    }
    .c-popover__arrow {
      top: 100%;
      transform: rotate(180deg);
    }
  }
  &.c-popover--is-bottom {
    .c-popover__container {
      top: 140%;
    }
  }
  &.c-popover--is-left {
    .c-popover__container {
      right: 110%;
    }

    .c-popover__arrow {
      top: calc(50% - #{$arrow-spacing});
      left: calc(100% - #{$arrow-spacing / 2});
      transform: rotate(90deg);
    }
  }
  &.c-popover--is-right {
    .c-popover__container {
      left: 110%;
    }

    .c-popover__arrow {
      top: calc(50% - #{$arrow-spacing});
      left: calc(0% - #{$arrow-spacing});
      transform: rotate(-90deg);
    }
  }

  // TODO: As a general rule it's not a good idea to have conditional styling as they are against the spirit of web standards
  // Flex doesn't work properly in Internet Explorer because of this and due to a tight schedule
  // This piece of code should be removed if its decided that the project no longer supports Internet Explorer or if a better fix is found
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    // IE10+ CSS here

    &.c-popover--is-top,
    &.c-popover--is-bottom {
      .c-popover__container {
        left: -40%;
      }
      .c-popover__arrow {
        left: 50%;
      }
    }
    &.c-popover--is-left,
    &.c-popover--is-right {
      .c-popover__container {
        top: -50%;
      }
    }
  }
}
</style>
