import { defineModule, localActionContext, localGetterContext } from 'direct-vuex'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import state from './state'

export const employeeAdd = defineModule({
  namespaced: true as true,
  state,
  getters,
  actions,
  mutations
})

//direct-vuex helper of current module action & getter context
//https://github.com/paroi-tech/direct-vuex#alternatively-use-localgettercontext-and-localactioncontext
export const currentActionContext = (context: any) =>
  localActionContext(context, employeeAdd)
export const currentGetterContext = (args: [any, any, any, any]) =>
  localGetterContext(args, employeeAdd)
