<template>
  <maz-lightbox-container
    class="c-delegate-users-lightbox"
    :show="show"
    @close="onCancelClose"
  >
    <div v-show="!loading">
      <h3 class="c-lightbox__title c-delegate-users-title">
        {{ translate('DelegateUsersLightbox.Labels.SelectUsersToDelegate') }}
      </h3>
      <div class="row">
        <maz-user-selection
          class="col-xs-12 c-delegate-users__selection-container"
          :class="isEmployeePortal ? 'col-sm-6' : 'col-sm-12'"
          :title="translate('WorkflowDefinitionAdd.Labels.SelectUsers')"
          :searchPlaceholder="translate('WorkflowDefinitionAdd.Labels.SearchUsers')"
          :selectedTitle="translate('WorkflowDefinitionAdd.Labels.SelectedUsers')"
          :list="contactsToDelegate"
          :selected-list.sync="selectedContacts"
        />
        <maz-user-selection
          class="col-xs-12 col-sm-6 c-delegate-users__selection-container"
          :list="employeesToDelegate"
          :selected-list.sync="selectedEmployees"
          v-if="isEmployeePortal"
        />
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 mt-3">
          <maz-button class="c-delegate-users-button" @click.native="confirmDelegation">
            {{ translate('DelegateUsersLightbox.Labels.DelegateTask') }}
          </maz-button>
        </div>
      </div>
    </div>

    <div class="loading-container" v-show="loading">
      <fa icon="spinner" spin size="1x" />
    </div>
  </maz-lightbox-container>
</template>

<script>
import config from '@/api/modules/config'
import dictionaryService from '@/services/modules/dictionaryService'
import toastr from 'toastr'
import axios from 'axios'
import mazButton from '@/components/general/mazButton/index.vue'
import mazUserSelection from '@/components/general/mazUserSelection/index.vue'
import mazLightboxContainer from '@/components/general/mazLightboxContainer/index.vue'

let apiEndpoints = null

function filterAssignedUsersFromAvailables(users, assignedUsers) {
  if (!assignedUsers.length || !users.length) return users

  return users.filter(function(user) {
    // If the current user is found on the assigned users list and is not delegated
    // remove it from the list of users (return false);
    const found = assignedUsers.some(function(assigned) {
      return assigned.Id === user.Id && assigned.IsDelegated === false
    })

    return !found
  })
}
function getSelectedFromAssignedUsersByType(_this, type) {
  if (_this.localAssignedUsers) {
    return _this.localAssignedUsers.filter(function(user) {
      return user.Type === type && user.IsDelegated === true
    })
  }

  return []
}
function setAssignedUsersByTypeFromSelected(_this, type, selectedUsers) {
  // Copy to temporary list
  let assignedUsers = _this.localAssignedUsers.slice()

  // Removal action
  assignedUsers = assignedUsers.filter(function(assignedUser) {
    if (assignedUser.Type === type) {
      // If the assigned user is delegated and still occurs in the selected list,
      // it means it wasn't removed, so we keep it. Otherwise, return false to remove it from the list.
      if (assignedUser.IsDelegated) {
        return selectedUsers.some(function(selected) {
          return selected.Id === assignedUser.Id
        })
      }
    }
    return assignedUser
  })

  // If there are new selected users, add them to the assigned users list
  selectedUsers.forEach(function(selected) {
    let found
    assignedUsers.forEach(function(assignedUser) {
      if (selected.Id === assignedUser.Id) {
        found = assignedUser
      }
    })
    if (!found) assignedUsers.push(selected)
  })

  _this.localAssignedUsers = assignedUsers
}

const portalController =
  config.portalType === 'Employee'
    ? 'ShareDocumentsEmployeeJson'
    : 'ShareDocumentsClientJson'

export default {
  name: 'mazDelegateUsersLightbox',
  props: {
    show: {
      required: true,
      type: Boolean
    },
    workflowId: {
      required: true,
      type: String
    },
    taskId: {
      required: true,
      type: String
    },
    onClose: {
      type: Function
    },
    oid: {
      required: false,
      type: String
    },
    cid: {
      required: false,
      type: String
    },
    serviceid: {
      required: false,
      type: String
    },
    assignedUsers: {
      required: true,
      type: Array
    }
  },
  components: {
    mazButton,
    mazUserSelection,
    mazLightboxContainer
  },
  mounted() {
    apiEndpoints = {
      employeesEndpoint:
        config.endpointBase +
        portalController +
        '/GetEmployees?organizationId={organizationId}&contactId={contactId}',
      contactsEndpoint:
        config.endpointBase + portalController + '/GetContactsForWorkflow',
      setDelegatedUsersEndpoint:
        config.endpointBase + portalController + '/SetDelegatedUsers'
    }
  },
  data() {
    return {
      isEmployeePortal: config.portalType === 'Employee',
      saving: false,

      employeesToDelegate: [],
      contactsToDelegate: [],
      localAssignedUsers: []
    }
  },
  watch: {
    show: function(show) {
      if (show) {
        this.getContactsToDelegate()
        if (config.portalType === 'Employee') this.getEmployeesToDelegate()
      }
    },
    assignedUsers: function(newAssignedUsers) {
      if (newAssignedUsers.length) {
        // Remove employees and contacts which are already on the assigned users list (and are not delegated)
        this.employeesToDelegate = filterAssignedUsersFromAvailables(
          this.employeesToDelegate,
          newAssignedUsers
        )
        this.contactsToDelegate = filterAssignedUsersFromAvailables(
          this.contactsToDelegate,
          newAssignedUsers
        )
      }

      // Add users from changed assigned users to the local assigned users list
      this.localAssignedUsers = newAssignedUsers.map(function(au) {
        // Mapping for assigned users model (adds missing UserId and UserType props)
        au.UserId = au.Id
        au.UserType = au.Type
        return au
      })
    }
  },
  methods: {
    getContactsToDelegate() {
      const endpoint =
        apiEndpoints.contactsEndpoint +
        '?oid=' +
        this.oid +
        '&cid=' +
        this.cid +
        '&serviceid=' +
        this.serviceid

      return axios.get(endpoint).then(users => {
        if (!users.data || !users.data.length) {
          users.data = []
        }

        const usersArray = users.data.map(u => {
          if ('UserId' in u && !('Id' in u)) u.Id = u.UserId

          if ('UserType' in u && !('Type' in u)) u.Type = u.UserType

          return u
        })

        this.contactsToDelegate = filterAssignedUsersFromAvailables(
          usersArray,
          this.assignedUsers
        )
      })
    },
    getEmployeesToDelegate() {
      const endpoint = apiEndpoints.employeesEndpoint
        .replace('{organizationId}', this.oid)
        .replace('{contactId}', this.cid)

      return axios.get(endpoint).then(users => {
        if (!users.data || !users.data.length) {
          users.data = []
        }

        this.employeesToDelegate = users.data.map(u => {
          if ('UserId' in u && !('Id' in u)) u.Id = u.UserId

          if ('UserType' in u && !('Type' in u)) u.Type = u.UserType

          return u
        })
      })
    },

    translate: function(label) {
      return dictionaryService.translate(label)
    },
    onCancelClose: function() {
      // Reset list of users
      this.localAssignedUsers = this.assignedUsers

      // Call parent close
      this.onClose(false)
    },
    confirmDelegation: function() {
      this.saving = true

      const data = {
        delegatedAssignedUsers: this.localAssignedUsers,
        workflowId: this.workflowId,
        taskId: this.taskId
      }

      axios
        .post(apiEndpoints.setDelegatedUsersEndpoint, data)
        .then(res => {
          // Delegated users set with success.
          // Emit changes to parent component/page to updated selected users and close this lightbox.
          this.$emit('update:assignedUsers', this.localAssignedUsers)
          this.onClose(true)

          toastr.success(
            dictionaryService.translate(
              'DelegateUsersLightbox.Messages.DelegationSuccessful'
            ),
            res,
            true
          )
        })
        .catch(err => {
          toastr.error(
            dictionaryService.translate('DelegateUsersLightbox.Messages.ErrorDelegating'),
            err,
            true
          )
        })
        .then(() => {
          this.saving = false
        })
    }
  },
  computed: {
    loading: function() {
      return this.saving
    },
    selectedEmployees: {
      get: function() {
        return getSelectedFromAssignedUsersByType(this, 100)
      },
      set: function(selectedUsers) {
        setAssignedUsersByTypeFromSelected(this, 100, selectedUsers)
      }
    },
    selectedContacts: {
      get: function() {
        return getSelectedFromAssignedUsersByType(this, 200)
      },
      set: function(selectedUsers) {
        setAssignedUsersByTypeFromSelected(this, 200, selectedUsers)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-delegate-users-lightbox {
  .c-container-fluid {
    min-width: initial;
  }

  .c-delegate-users-title {
    margin-bottom: 20px;
    text-transform: none;
  }

  .c-delegate-users-list {
    max-height: 400px;
    margin-bottom: 20px;
    padding: 0 15px;
    overflow-y: auto;

    .c-delegate-users-column-label {
      color: $dark-blue;
      font-weight: 700;
      font-size: 20px;
    }

    tr {
      margin-bottom: 10px;

      td {
        border-top: none;

        &:first-child {
          width: 50px;
          max-width: 50px;
        }
      }

      .custom-checkbox {
        position: relative;

        &:before {
          position: absolute;
          z-index: 10;
          display: block;
          width: 20px;
          height: 20px;
          background-color: $white;
          border-radius: 3px;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
          content: '';
          pointer-events: none;
        }
        &.checked:after {
          //font-family: FontAwesome;
          position: absolute;
          top: 2px;
          left: 4px;
          z-index: 11;
          width: 20px;
          height: 20px;
          color: $dark-blue;
          content: '\f00c';
          pointer-events: none;
        }
        input[type='checkbox'] {
          width: 20px;
          height: 20px;
          cursor: pointer;
          opacity: 0;
        }
      }

      .c-delegate-users-user-name,
      .c-delegate-users-user-email,
      .c-delegate-users-user-type {
        font-size: 16px;
      }

      .c-delegate-users-user-email {
        font-style: italic;
      }
    }
  }

  .c-delegate-users-button {
    @include halyard-medium;
    float: right;
    text-align: center;

    &:disabled {
      opacity: 0.6;
    }
  }

  .c-delegate-users__selection-container {
    min-width: 300px;
  }
}
</style>