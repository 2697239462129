<template>
  <div class="c-template-subcategory">
    <div class="row d-flex align-items-center justify-content-between">
      <div class="col-12 col-sm-auto">
        <div class="d-flex align-items-center">
          <maz-state-indicator />
          <h4 class="c-subcategory__title">
            {{ parentCategoryIndexPosition }}{{ indexPosition }} {{ subCategory.title }}
          </h4>
        </div>
      </div>
      <div
        class="c-icons c-icons--categories mt-4 mt-sm-0 col-12 col-sm-auto justify-content-center"
      >
        <div class="c-icons__item c-icons__item--move" />
        <div
          :data-target="'#modalEditSubCategory' + subCategory.id"
          @click="showCurrentInput(subCategory)"
          class="c-icons__item c-icons__item--edit"
          data-toggle="modal"
        />
        <div
          @click="onDeleteSubCategory(category, subCategory)"
          class="c-icons__item c-icons__item--trash"
        />
      </div>
    </div>
    <div
      class="o-separator o-separator--extra-small"
      v-if="subCategory.segments.length"
    />
    <div class="c-subcategory__content">
      <draggable
        :options="{ group: 'subCategory.segments' }"
        ghost-class="ghost"
        handle=".c-icons__item--move"
        v-model="changeSegmentOrder"
      >
        <maz-template-maintenance-segment
          :category="category"
          :id="segment.id"
          :index="index"
          :indexPosition="(index + 1).toString()"
          :key="segment.id.clean"
          :segment="segment"
          :subCategory="subCategory"
          @clicked="onClickChild"
          ref="segment"
          v-for="(segment, index) in subCategory.segments"
        />
      </draggable>

      <!-- Start of adding a segment -->
      <div :class="{ disabled: isLinkDisabled }" class="e-add-item mt-2">
        <div class="e-icon-add" />
        <a
          :disabled="isLinkDisabled"
          @click="addNewSegment(category, subCategory)"
          class="e-link-bold"
          >{{ labels.supplyRequestTemplateMaintenanceCreateSegment }}</a
        >
        <!-- data-toggle="modal" :data-target="'#modalAddSegment'+index" -->
      </div>
      <!-- End of adding a segment -->

      <!-- Start of modal edit subcategory -->
      <div
        :id="'modalEditSubCategory' + subCategory.id"
        aria-hidden="true"
        class="modal fade"
        role="dialog"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title">{{ labels.edit }}</h3>
              <button
                @click="onCancelEditSubCategory"
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              />
            </div>
            <div class="modal-body modal-body--margin-bottom">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{
                      labels.supplyRequestTemplateMaintenanceSubCategoryTitle
                    }}</label>
                    <input class="form-control" v-model="editSubCategoryTitleInput" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div
                    class="form-group float-sm-right mb-0 d-flex flex-wrap flex-sm-no-wrap"
                  >
                    <maz-button
                      :label="labels.cancel"
                      @click.native="onCancelEditSubCategory"
                      aria-label="Close"
                      class="c-btn--empty order-2 order-sm-1"
                      data-dismiss="modal"
                    />
                    <maz-button
                      :label="labels.confirm"
                      @click.native="onEditSubCategory(category, subCategory)"
                      aria-label="Close"
                      class="c-btn--green order-1 order-sm-2"
                      data-dismiss="modal"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End of modal edit subcategory -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import mazButton from '../../general/mazButton'
import mazStateIndicator from '../../general/mazStateIndicator'
import mazTemplateMaintenanceSegment from '../mazTemplateMaintenanceSegment'
import draggable from 'vuedraggable'

const _targetStore = 'supplyRequest2TemplateMaintenanceStore/'

export default {
  name: 'maz-template-maintenance-sub-category',
  components: {
    'maz-button': mazButton,
    'maz-state-indicator': mazStateIndicator,
    'maz-template-maintenance-segment': mazTemplateMaintenanceSegment,
    draggable: draggable
  },
  props: {
    parentCategoryIndexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    indexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    index: {
      required: false,
      type: Number,
      default() {
        return ''
      }
    },
    subCategory: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      segmentTitleInput: '',
      editSubCategoryTitleInput: '',
      isLinkDisabled: false
    }
  },
  computed: {
    ...mapGetters({
      labels: _targetStore + 'LABELS',
      segmentAddedId: _targetStore + 'TEMPLATE_MAINTENANCE_SEGMENT_ADDED_ID'
    }),
    changeSegmentOrder: {
      get() {
        return this.subCategory.segments
      },
      set(value) {
        const body = {
          category: this.category,
          subCategory: this.subCategory,
          segments: value
        }
        this.$store.dispatch(_targetStore + 'SET_ORDERED_SEGMENTS', body)
      }
    }
  },
  methods: {
    onCancelSegment() {
      this.cleanNewSegmentInputs()
    },
    cleanNewSegmentInputs() {
      this.segmentTitleInput = ''
    },
    onCancelEditSubCategory() {
      this.cleanEditSubCategoryInputs()
    },
    cleanEditSubCategoryInputs() {
      this.editSubCategoryTitleInput = ''
    },
    onEditSubCategory(category, subCategory) {
      const body = {
        categoryId: category.id,
        subCategoryId: subCategory.id,
        subCategoryData: {
          title: this.editSubCategoryTitleInput
        }
      }

      this.$store.dispatch(_targetStore + 'EDIT_SUBCATEGORIES', body)

      this.cleanEditSubCategoryInputs()
    },
    addNewSegment(category, subCategory) {
      const body = {
        category: category,
        subCategory: subCategory,
        segmentData: {
          id: this.getGuidId(),
          title: '',
          description: '',
          segmentItems: []
        }
      }

      this.$store.dispatch(_targetStore + 'GET_SEGMENTS', body)

      const indexOfLastSegment = this.subCategory.segments.length - 1
      this.isLinkDisabled = true

      // TODO REFACTOR - AVOID USING TIMEOUTS //
      setTimeout(() => {
        this.onEditSegment(indexOfLastSegment, this.segmentAddedId)
      }, 100)
    },
    showCurrentInput(subCategory) {
      this.editSubCategoryTitleInput = subCategory.title
    },
    onDeleteSubCategory(category, subCategory) {
      const body = {
        category: category,
        subCategory: subCategory
      }

      this.$store.dispatch(_targetStore + 'DELETE_SUBCATEGORY', body)
    },
    onEditSegment(index, segmentId) {
      this.$refs.segment[index].onEditSegment(segmentId)
    },
    onClickChild(value) {
      if (value === true) {
        this.isLinkDisabled = false
      }
    },
    getGuidId() {
      let uuidValue = '',
        k,
        randomValue
      for (k = 0; k < 32; k++) {
        randomValue = (Math.random() * 16) | 0

        if (k == 8 || k == 12 || k == 16 || k == 20) {
          uuidValue += '-'
        }
        uuidValue += (k == 12
          ? 4
          : k == 16
            ? (randomValue & 3) | 8
            : randomValue
        ).toString(16)
      }
      return uuidValue
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/__main.scss';

.c-template-subcategory {
  @include subcategory;

  + .c-template-subcategory {
    margin-top: 40px;
  }
}
</style>
