<template>
  <label class="maz-validation-message">
    <slot name="default">
      {{ message }}
    </slot>
  </label>
</template>
<script>
export default {
  name: 'mazValidationMessage',

  props: {
    message: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styling/custom/settings/__main.scss';
.maz-validation-message {
  /*
  Total single line height is same as o-separator--extra-small (24px)

  You can use the following pattern:
  <maz-validation-message v-if="errors.property" message="error" />
  <div v-else class="o-separator o-separator--extra-small" />

  This will result in the exact same spacer, as long as message is not longer
  then one line.
  */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  min-height: 20px;
  margin: 4px 0 0;
  color: $status-red;
  text-align: right;
  & span {
    color: $status-red;
    font-size: 14px;
    & a {
      color: $status-red;
      text-decoration: underline;
    }
  }
}
</style>
