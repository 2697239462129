<template>
  <div class="c-datepicker__container">
    <input
      :currentDate="currentDate"
      :disabled="disabled"
      :minDate="minDate"
      :placeholder="placeholder"
      :value="value"
      @input="updateValue($event.target.value)"
      class="form-control c-forms__input datepicker"
      readonly="true"
      type="text"
    />
  </div>
</template>

<script>
import moment from 'moment'
import $ from 'jquery'
import 'jquery-ui/ui/widgets/datepicker'

window.jQuery = $ // To prevent 'is not a function' errors
window.$ = $ // To prevent 'is not a function' errors

export default {
  name: 'maz-date-picker',
  props: {
    date_format: {
      required: false,
      type: String,
      default() {
        return 'dd-mm-yy'
      }
    },
    value: {
      required: false,
      type: String,
      default() {
        return moment().format('DD-MM-YYYY')
      }
    },
    placeholder: {
      required: false,
      type: String,
      default() {
        return moment().format('DD-MM-YYYY')
      }
    },
    disabled: {
      required: false,
      type: Boolean,
      default() {
        return false
      }
    },
    currentDate: {
      required: false,
      type: String,
      default() {
        return moment().format('DD-MM-YYYY')
      }
    },
    minDate: {
      required: false,
      type: String,
      default() {
        return moment().format('DD-MM-YYYY')
      }
    }
  },
  methods: {
    updateValue(value) {
      this.$emit('onDateSelected', value)
    }
  },
  mounted() {
    const currentLanguage = $('html').attr('lang')

    moment.locale(currentLanguage)
    const momentLocaleData = moment.localeData()

    $.datepicker.regional['user'] = {
      monthNames: momentLocaleData._months,
      monthNamesShort: momentLocaleData._monthsShort,
      dayNames: momentLocaleData._weekdays,
      dayNamesShort: momentLocaleData._weekdaysMin,
      dayNamesMin: momentLocaleData._weekdaysMin,
      firstDay: momentLocaleData._week.dow
    }

    this.$nextTick(() => {
      $(this.$el)
        .find('input')
        .datepicker({
          showOn: 'both',
          buttonImage: '',
          minDate: this.minDate,
          showOtherMonths: true,
          selectOtherMonths: true,
          dateFormat: this.date_format,
          onSelect: _date => {
            this.updateValue(_date)
          },
          onClose: _date => {
            this.updateValue(_date)
          }
        })
        .datepicker('setDate', this.currentDate)

      $.datepicker.setDefaults($.datepicker.regional['user'])
    })
  }
}
</script>
