







import mazClientLoginHeader from '@/components/custom/mazClientLoginHeader/index.vue'
import mazClientLoginBody from '@/components/custom/mazClientLoginBody/index.vue'

import Vue from 'vue'

import authenticationApi from '@/api/modules/authenticationApi'
import axiosService from '@/services/modules/axiosService'

import type { ClientLoginConfig } from '@/interfaces/authenticationInterfaces'

const _targetendpoint = authenticationApi.getClientLoginConfigData

export default Vue.extend({
  name: 'clientLoginPage',

  data() {
    return {
      config: {} as ClientLoginConfig
    }
  },

  components: {
    mazClientLoginHeader,
    mazClientLoginBody
  },

  created() {
    axiosService.get(_targetendpoint, (res: ClientLoginConfig) => {
      this.config = res
    })
  }
})
