
















































































































































































































































import mazButton from "@/components/general/mazButton/index.vue";
import mazList from "@/components/general/mazList/index.vue";
import mazModal from "@/components/general/mazModal/index.vue";
import mazSpinner from "@/components/general/mazSpinner/index.vue";
import mazSwitch from "@/components/general/mazSwitch/index.vue";
import mazTextInput from "@/components/general/mazTextInput/index.vue";
import store from "@/store/store2";
import Vue from "vue";

export default Vue.extend({
  name: "maz-client-maintenance-organization-permissions",

  components: {
    mazButton,
    mazSpinner,
    mazModal,
    mazSwitch,
    mazTextInput,
    mazList,
  },

  data() {
    return {
      showContactsModal: false,
      showPermissionsModal: false,
      showUnlinkContactModal: false,
      contactId: "",
    };
  },

  created() {
    this.getOrganizationContactPermissions();
  },

  computed: {
    shouldRedirect(): boolean {
      return (
        this.addContact !== "" &&
        this.addContact !== undefined &&
        this.addContact !== null
      );
    },
    addContact(): string {
      return store.getters.clientMaintenance.organization.ADD_CONTACT_URL;
    },
    id(): string {
      return store.getters.clientMaintenance.ID;
    },
    loader(): any {
      return store.getters.clientMaintenance.organization.LOADER;
    },
    labels(): any {
      return store.getters.clientMaintenance.organization.LABELS;
    },
    messages(): any {
      return store.getters.clientMaintenance.organization.MESSAGES;
    },
    permissions(): any {
      return store.getters.clientMaintenance.organization.PERMISSIONS;
    },
    organizationContactPermissionsTable(): any {
      return store.getters.clientMaintenance.organization
        .ORGANIZATION_CONTACT_PERMISSION_TABLE;
    },
    organizationContactPermissionsModal(): any {
      return store.getters.clientMaintenance.organization
        .ORGANIZATION_CONTACT_PERMISSION_MODAL;
    },
    contactsModal(): any {
      return store.getters.clientMaintenance.organization.CONTACTS_MODAL;
    },
  },

  methods: {
    redirectToPowerApp(): void {
      window.open(this.addContact, "_blank")?.focus();
    },
    /**
     * VUEX
     */
    getClientMaintenanceOrganizationContactPermissions(body: any): void {
      store.dispatch.clientMaintenance.organization.GET_ORGANIZATION_CONTACT_PERMISSIONS(
        body
      );
    },
    getClientMaintenanceClientPermissionsModal(contact: any): void {
      store.dispatch.clientMaintenance.organization.GET_CLIENT_MAINTENANCE_CLIENT_PERMISSIONS_MODAL(
        contact
      );
    },
    getConnectableContacts(): void {
      store.dispatch.clientMaintenance.organization.GET_CONNECTABLE_CONTACTS();
    },
    updateAvailableConnectableContacts(body: any): void {
      store.dispatch.clientMaintenance.organization.UPDATE_AVAILABLE_CONNECTABLE_CONTACTS(
        body
      );
    },
    updateClientMaintenanceContactsList(body: any): void {
      store.dispatch.clientMaintenance.organization.UPDATE_CONTACTS_LIST(body);
    },
    updateClientMaintenanceOrganizationContacts(body: any): void {
      store.dispatch.clientMaintenance.organization.UPDATE_ORGANIZATION_CONTACTS(body);
    },
    updateClientMaintenanceContactPermission(body: any): void {
      store.dispatch.clientMaintenance.organization.UPDATE_CLIENT_MAINTENANCE_CONTACT_PERMISSION(
        body
      );
    },
    /**
     * COMPONENT METHODS
     */
    getOrganizationContactPermissions() {
      const body = {
        clientId: this.id,
      };
      this.getClientMaintenanceOrganizationContactPermissions(body);
    },
    openClientPermissionsModal(contact: object) {
      this.getClientMaintenanceClientPermissionsModal(contact);
      this.showPermissionsModal = true;
    },
    saveUpdatedOrganizationContacts(contacts: any) {
      const tempIds = contacts.map((x: any) => x.Id);
      //TODO: Refactor object keys using the PascalCase helper service
      const body = {
        OrganizationId: this.id,
        ContactIds: tempIds.length > 0 ? tempIds : null,
      };
      if (body.ContactIds !== null) {
        this.updateClientMaintenanceOrganizationContacts(body);
        this.showContactsModal = false;
      }
    },
    updatePermission(
      serviceIndex: number,
      permissionIndex: number,
      permissionIsSet: boolean
    ) {
      //TODO: Refactor object keys using the PascalCase helper service
      const body = {
        OrganizationId: this.id,
        ContactId: this.organizationContactPermissionsModal.details.Id,
        ServiceId: this.organizationContactPermissionsModal.details.Services[serviceIndex]
          .Id,
        PermissionId: this.organizationContactPermissionsModal.details.Services[
          serviceIndex
        ].Permissions[permissionIndex].Id,
        IsSet: !permissionIsSet,
      };

      this.updateClientMaintenanceContactPermission(body);
    },
    unlinkContact(contact: any) {
      this.showUnlinkContactModal = true;
      this.contactId = contact.Id;
    },
    cancelUnlinkContact(value: boolean) {
      this.showUnlinkContactModal = value;
    },
    confirmUnlinkContact() {
      this.showUnlinkContactModal = false;
      if (this.contactId) this.deleteContact(this.contactId);
    },
    deleteContact(contactId: string) {
      const body = {
        OrganizationId: this.id,
        ContactId: contactId,
      };
      store.dispatch.clientMaintenance.organization.DELETE_CLIENT_MAINTENANCE_ORGANIZATION_CONTACT(
        body
      );
    },
  },

  watch: {
    showContactsModal: function (value: boolean): void {
      if (value) {
        this.getConnectableContacts();
      }
    },
  },
});
