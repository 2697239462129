









































































































































































































import Vue from 'vue'
import store from '@/store/store2'
import mazAlert from '@/components/general/mazAlert/index.vue'
import mazButton from '@/components/general/mazButton/index.vue'
import mazDropDown from '@/components/general/mazDropDown/index.vue'
import mazModal from '@/components/general/mazModal/index.vue'
import mazTextInput from '@/components/general/mazTextInput/index.vue'
import mazValidationMessage from '@/components/general/mazValidationMessage/index.vue'

import { isString, isEmpty, pickBy, startsWith, trim } from 'lodash'
import dictionaryService from '@/services/modules/dictionaryService'

export default Vue.extend({
  name: 'mazEmployeeOverviewAddEmployeeModal',
  components: {
    mazAlert,
    mazButton,
    mazDropDown,
    mazModal,
    mazTextInput,
    mazValidationMessage
  },

  props: {
    employeePermission: {
      type: Object as () => MazInterfaces.EmployeeMaintenance.EmployeePermission,
      default: null
    }
  },

  data() {
    return {
      showModal: false,
      employeeDetails: {
        firstName: '',
        lastName: '',
        initials: '',
        fullName: '',
        gender: '',
        salutation: '',
        email: '',
        employeeNumber: '',
        language: ''
      } as MazInterfaces.EmployeeMaintenanceAddEmployee.CreateEmployeeDetails,
      errors: {}
    }
  },

  computed: {
    labels(): any {
      return store.getters.employeeAdd.LABELS
    },
    messages(): any {
      return store.getters.employeeAdd.MESSAGES
    },
    genderOptions(): any {
      return store.getters.employeeAdd.GENDER_OPTIONS
    },
    languageOptions(): any {
      return store.getters.employeeAdd.LANGUAGE_OPTIONS
    },
    shouldRefreshOnClose(): boolean {
      return store.getters.employeeAdd.SHOULD_REFRESH_ON_CLOSE
    },
    employeeUrl(): string {
      return store.getters.employeeAdd.EMPLOYEE_URL
    },
    mayShowModal(): boolean {
      return store.getters.employeeAdd.MAY_SHOW_MODAL
    },
    modalHeaderText(): string {
      return store.getters.employeeAdd.MODAL_HEADER_TEXT
    },
    validationErrors(): any {
      return store.getters.employeeAdd.VALIDATION_ERRORS
    },
    existingEmailDetails(): any {
      return store.getters.employeeAdd.EXISTING_EMAIL_DETAILS
    },
    invalidEmailDetails(): any {
      return store.getters.employeeAdd.INVALID_EMAIL_DETAILS
    }
  },

  methods: {
    open(): void {
      this.setupModal()
      this.showModal = true
    },
    close(): void {
      this.showModal = false
      this.$emit('close', this.shouldRefreshOnClose)
    },
    setupModal(): void {
      this.employeeDetails = {
        firstName: '',
        lastName: '',
        initials: '',
        fullName: '',
        gender: '',
        salutation: '',
        email: '',
        employeeNumber: '',
        language: ''
      }
      this.errors = {}
      store.dispatch.employeeAdd.SETUP_MODAL()
    },
    save(): void {
      const body = this.employeeDetails
      this.fieldValidator(body, [
        'employeeNumber',
        'email',
        'firstName',
        'lastName',
        'initials',
        'fullName',
        'salutation',
        'gender',
        'language'
      ])
      store.dispatch.employeeAdd.SAVE_EMPLOYEE(body)
    },
    fieldValidator(objectToValidate: any, fields: string[]) {
      fields.forEach((field: string) => {
        //if string, check if it has white spaces
        if (
          isString(objectToValidate[field]) &&
          trim(objectToValidate[field]).length === 0
        ) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired }
          }
          return
        }

        if (isEmpty(objectToValidate[field])) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired }
          }
        } else {
          const filteredErrors = pickBy(this.errors, function (value, key) {
            return !startsWith(key, field)
          })
          this.errors = filteredErrors
        }
      })
    },
    validationError(field: string): string {
      const err = this.validationErrors.find(
        (err: any) => err.PropertyName === field
      )?.ErrorCode
      if (err) {
        return dictionaryService.translate(`EmployeeDetails.Messages.${err}`)
      }
      return ''
    },
    resetError(field: string): void {
      this.$delete(this.errors, field)
      store.dispatch.employeeAdd.RESET_VALIDATION_ERROR(field)
    }
  },

  watch: {
    employeePermission(value: MazInterfaces.EmployeeMaintenance.EmployeePermission) {
      store.commit.employeeAdd.SET_EMPLOYEE_PERMISSION_MUTATION(value)
    }
  }
})
