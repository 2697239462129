








































































import Vue from 'vue'
import store from '@/store/store2'
export default Vue.extend({
  name: 'mazClientMyProfilePageDetails',

  computed: {
    clientDetails() {
      return store.getters.clientMyProfilePage.clientMyProfilePageDetails.CLIENT_DETAILS
    },
    labels(): any {
      return store.getters.clientMyProfilePage.clientMyProfilePageDetails.LABELS
    }
  }
})
