import $ from 'jquery'
import 'jquery-ui/ui/widgets/dialog'

let pkiInstance = null

//This code depends on the jquery that is being loaded from require,
// once require is removed this code should be changed
function startSigning(accessToken, sessionId, encryptionKey, options) {
  return new Promise((resolve, reject) => {
    window.$(document).on('PKISigningError', reject)
    window.$(document).on('PKISigningFinished', resolve)

    if (pkiInstance === null) {
      window.define = undefined

      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src =
        $('.js-mazars-dev').length > 0
          ? 'https://accengine.pkisigning.io/scripts/pkisigningapi.js'
          : 'https://engine.pkisigning.io/scripts/pkisigningapi.js'
      document.getElementsByTagName('head')[0].appendChild(script)

      window.$(document).bind('PKISigningInitiated', () => {
        pkiInstance = window.$.PKISigning
        pkiInstance.startSigning(accessToken, sessionId, encryptionKey, options)

        console.log('PKI loaded successfully', pkiInstance)
      })
    } else {
      pkiInstance.startSigning(accessToken, sessionId, encryptionKey, options)
    }
  })
}

export default {
  startSigning
}
