export default {
  getAddSupplyRequestInitialData:
    'getaddsupplyrequestinitialdata?customerId={customerId}',
  getAddSupplyRequestPhases:
    'getaddsupplyrequestphases?serviceId={serviceId}&customerId={customerId}',
  getAddSupplyRequestLanguages:
    'getaddsupplyrequestlanguages?serviceId={serviceId}&phaseId={phaseId}&customerId={customerId}',
  getTemplatesByServiceIdPhaseIdLanguageCode:
    'gettemplatesbyserviceidphaseidlanguagecode?serviceId={serviceId}&phaseId={phaseId}&languageCode={languageCode}&customerId={customerId}',
  getTemplatePreview: 'gettemplate?supplyrequesttemplateid={templateId}',
  addSupplyRequest: 'addsupplyrequest'
}
