//Required components
import mazTimeLine from '../../components/custom/mazTimeLine'

export default {
  el: '#timelineVueContent',
  components: {
    'maz-time-line': mazTimeLine,
    timeline: mazTimeLine
  }
}
