














































































































































































import Vue from 'vue'
import store from '@/store/store2'
import config from '@/api/modules/config'
import helpersService from '@/services/modules/helpersService'
import employeeMaintenanceApi from '@/api/modules/employeeMaintenanceApi'

import mazAutoCompleteInput from '@/components/general/mazAutoCompleteInput/index.vue'
import mazCheckbox from '@/components/general/mazCheckbox/index.vue'
import mazSpinner from '@/components/general/mazSpinner/index.vue'
import mazTitle from '@/components/general/mazTitle/index.vue'

import mazEmployeeOverviewAddEmployeeModal from '@/components/custom/mazEmployeeOverviewAddEmployeeModal/index.vue'

export default Vue.extend({
  name: 'maz-employee-overview-page',
  components: {
    mazAutoCompleteInput,
    mazCheckbox,
    mazSpinner,
    mazTitle,
    mazEmployeeOverviewAddEmployeeModal
  },
  data() {
    return {
      searchTerm: '' as string,
      showInactiveEmployees: false as boolean,
      isDisabled: true as boolean
    }
  },
  computed: {
    labels(): any {
      return store.getters.employeeOverview.LABELS
    },
    searchEmployeeList(): any {
      return store.getters.employeeOverview.SEARCH_EMPLOYEE_LIST
    },
    currentPage(): number {
      return store.getters.employeeOverview.CURRENT_PAGE
    },
    loader(): boolean {
      return store.getters.employeeOverview.LOADER
    },
    employeeList(): any {
      return store.getters.employeeOverview.EMPLOYEE_LIST
    },
    maxPageCounter(): number {
      return store.getters.employeeOverview.MAX_PAGE_COUNTER
    },
    messages(): any {
      return store.getters.employeeOverview.MESSAGES
    },
    employeePermission(): MazInterfaces.EmployeeMaintenance.EmployeePermission {
      return store.getters.employeeOverview.EMPLOYEE_PERMISSION
    },
    csvExportUrl(): string {
      let endpoint = helpersService.buildEndpointRequest(
        config.endpointBaseCsv + 'Employee/' + employeeMaintenanceApi.exportOverview
      )
      // Because we are trying to replace a boolean value, the "helpersService.buildEndpointRequest" will not work
      // Because of that we are doing it manually
      endpoint = endpoint.replace('{showInactiveEmployees}', this.showInactiveEmployees)
      return endpoint
    }
  },
  methods: {
    /**
     * ACTIONS
     */
    getEmployeeMaintenanceOverviewEmployeeList(): void {
      const getEmployeeBody: MazInterfaces.EmployeeMaintenanceOverview.GetEmployees = {
        searchTerm: this.searchTerm,
        pageNumber: this.currentPage,
        showInactiveEmployees: this.showInactiveEmployees
      }
      store.dispatch.employeeOverview.GET_EMPLOYEE_MAINTENANCE_OVERVIEW_EMPLOYEE_LIST(
        getEmployeeBody
      )
    },
    searchEmployeeMaintenanceEmployees(value: any) {
      this.searchTerm = value
      const searchEmployeeBody: MazInterfaces.EmployeeMaintenanceOverview.EmployeeSearchTerm =
        {
          searchTerm: this.searchTerm,
          showInactiveEmployees: this.showInactiveEmployees
        }
      store.dispatch.employeeOverview.SEARCH_EMPLOYEE_MAINTENANCE_OVERVIEW_EMPLOYEES(
        searchEmployeeBody
      )
    },
    onItemSelect(val: string): void {
      this.searchTerm = val
      this.getEmployeeMaintenanceOverviewEmployeeList()
    },
    redirectPage(url: string): void {
      window.location.href = url
      // //Clear the search term before getting data
      // this.searchTerm = ''
    },
    handleClick(e: any, url: string): void {
      if (e.ctrlKey) {
        window.open(url)
      } else {
        this.redirectPage(url)
      }
    },
    updateEmployeeMaintenanceCurrentPage(payload: number): void {
      store.dispatch.employeeOverview.UPDATE_EMPLOYEE_MAINTENANCE_OVERVIEW_CURRENT_PAGE(
        payload
      )
      this.getEmployeeMaintenanceOverviewEmployeeList()
    }
  },
  created() {
    this.getEmployeeMaintenanceOverviewEmployeeList()
  },
  watch: {
    showInactiveEmployees() {
      //Clear the search term before getting data
      this.searchTerm = ''
      this.getEmployeeMaintenanceOverviewEmployeeList()
    },
    searchTerm(val) {
      if (val.length === 0) {
        this.getEmployeeMaintenanceOverviewEmployeeList()
      }
    }
  }
})
