<template>
  <div class="c-lightbox-container" v-if="show" v-on:click="onClose">
    <div class="c-lightbox-container__window">
      <div
        class="c-lightbox-container__centered-container"
        :class="{ 'c-lightbox-container--wide': isWide }"
      >
        <div class="c-lightbox-container__content-container" v-on:click.stop>
          <div class="c-lightbox-close-button" v-on:click="onClose">
            <fa icon="times" style="font-size: 2rem" />
          </div>

          <div>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "mazLightboxContainer",
  mounted() {
    //In case of IE, set width of lightbox manually
    this.setLightboxWidth();
  },
  props: {
    show: {
      required: true,
      type: Boolean
    },
    isWide: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show: function (visible) {
      if (visible) {
        $("html").addClass("lightbox-open");

        //In case of IE, set width of lightbox manually
        this.setLightboxWidth();
      } else {
        $(".c-lightbox").scrollTop(0);
        $("html").removeClass("lightbox-open");
        const styleAttributeValue = $("body").attr("style");

        if (
          typeof styleAttributeValue !== typeof undefined &&
          styleAttributeValue !== false
        ) {
          $("body").removeAttr("style");
        }
      }
    }
  },
  methods: {
    setLightboxWidth() {
      if (window.navigator.userAgent.indexOf("Trident") !== -1) {
        this.$nextTick(() => {
          const elementWidth = $(this.$el)
            .find(".c-lightbox-container__content-container")
            .outerWidth();
          $(this.$el)
            .find(".c-lightbox-container__centered-container")
            .css("width", elementWidth + "px");
        });
      }
    },
    onClose() {
      this.$emit('close')
    }
  },
  beforeDestroy() {
    // To make sure the 'lightbox-open' is always removed from the html tag
    // and style from body
    if ($("html").hasClass("lightbox-open")) {
      $("html").removeClass("lightbox-open");
      const styleAttributeValue = $("body").attr("style");

      if (
        typeof styleAttributeValue !== typeof undefined &&
        styleAttributeValue !== false
      ) {
        $("body").removeAttr("style");
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/styling/custom/settings/__main.scss';
.c-lightbox-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow: auto;
  background-color: rgba(#000, 0.5);

  .c-lightbox-container__window {
    display: flex;
    flex-direction: column;
    height: 100%;

    .c-lightbox-container__centered-container {
      width: initial;
      margin: auto;
      padding: 40px 0;

      .c-lightbox-container__content-container {
        position: relative;
        padding: 40px 2em 2em;
        background-color: white;
        border-radius: 6px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

        .c-lightbox-close-button {
          position: absolute;
          top: 10px;
          right: 10px;
          color: $dark-blue;
          cursor: pointer;
        }
      }
    }
  }
  .c-lightbox-supply-request-clone {
    h3 {
      margin: 0;
      color: $dark-slate-blue;
      text-align: left;
      text-transform: none;
    }
    p {
      float: none;
      width: auto;
      margin: 10px 0;
      margin-bottom: 0;
      font-family: 'Halyard-Book', sans-serif;
      text-align: left;
      text-transform: none;
    }

    .c-lightbox-supply-request-clone-section {
      margin-top: 2em;
      padding-top: 20px;
      border-top: 1px solid $mid-steel-blue;
      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
      }
    }
    .c-auto-complete-input__options {
      top: 37px;
      max-height: 150px;
      overflow-y: scroll;
    }
    .c-lightbox-supply-request-clone-search-bar-container {
      .c-auto-complete-input {
        height: 39px;
        margin-bottom: 1em;
        padding: 0;
        input {
          height: 39px;
          border-radius: 4px 0 0 4px;
        }
      }
      .btn {
        padding: 7px 22px;
        border-radius: 0 3px 3px 0;
      }
    }
  }
  &--wide {
    width: 80% !important;
    max-width: 900px;
  }
  .c-lightbox-container__title {
    color: $steel-blue;
    text-transform: none;
  }

  legend {
    margin-bottom: 10px;
    border: 0;
  }
}
</style>