export const mutations = {
  UPDATE_EMPLOYEE_DETAILS_EDIT_MODE_MUTATION(state: any, payload: boolean) {
    state.isEditMode = payload
  },
  SET_COUNTRIES_MUTATION(
    state: any,
    payload: MazInterfaces.EmployeeMaintenance.Countries[]
  ) {
    state.countries = payload
  },
  SET_LANGUAGES_MUTATION(
    state: any,
    payload: MazInterfaces.EmployeeMaintenance.Language[]
  ): void {
    state.languages = payload
  },
  SET_GENDERS_MUTATION(
    state: any,
    payload: MazInterfaces.EmployeeMaintenance.Gender[]
  ): void {
    state.genders = payload
  },
  UPDATE_EMPLOYEE_MAINTENANCE_DETAILS_MUTATION(state: any, payload: any) {
    state.employeeDetails = payload
    state.editedEmployeeDetails = { ...payload.employeeDetail } //Create a copy of the organization object
  },
  UPDATE_CONNECTED_CLIENTS_DETAILS_MUTATION(state: any, payload: any) {
    state.connectedClients.rows = { ...payload.customers }
    if (payload.customers.length) state.connectedClients.available = true
  },
  UPDATE_EMPLOYEE_CONNECTED_CLIENTS_LOADER_MUTATION(state: any, payload: object) {
    state.loader = { ...state.loader, ...payload }
  },
  RESET_VALIDATION_ERRORS_MUTATION(state: any) {
    state.validationErrors = []
  },
  RESET_VALIDATION_ERROR_MUTATION(state: any, payload: string) {
    const idx = state.validationErrors.findIndex(
      (obj: any) => obj.PropertyName === payload
    )
    if (idx > -1) {
      state.validationErrors.splice(idx, 1)
    }
  },
  RESET_EMPLOYEE_DETAILS_MUTATION(state: any) {
    state.editedEmployeeDetails = { ...state.employeeDetails.employeeDetail }
    state.validationErrors = []
  },
  UPDATE_LOADER_MUTATION(state: any, payload: any) {
    state.loader = { ...state.loader, ...payload }
  },
  SET_VALIDATION_ERRORS_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.ValidationError[]
  ) {
    state.validationErrors = payload
  }
}
