import toastr from 'toastr'
import Api from '../../api'
import Services from '../../services'

const _isEmployeePortal = Api.config.portalType === 'Employee' ? true : false
const _portController = _isEmployeePortal
  ? 'SupplyRequest2EmployeeJson'
  : 'SupplyRequest2ClientJson'
const _baseEndpoint = Api.config.endpointBase + _portController + '/'
const _fileEndpoint = Api.config.endpointBaseFile + 'SupplyRequest2Employee/'
const _targetApi = Api.supplyRequest2DetailApi

export default {
  namespaced: true,
  state: {
    id: Services.helpersService.getSearchId('?srid=', window.location.search),
    exists: false,
    notFoundMessage: false,
    supplyRequestHeader: {
      title: '',
      client: {
        name: '',
        url: ''
      },
      state: {
        code: null,
        label: ''
      },
      actionIcons: {
        labels: {
          cloneSupplyRequestTitle: Services.dictionaryService.translate(
            'Labels.SupplyRequest.Clone.SelectCustomer.Title'
          ),
          cloneSupplyRequestIntro: Services.dictionaryService.translate(
            'Labels.SupplyRequest.Clone.SelectCustomer.Intro'
          ),
          cloneSupplyRequestButton: Services.dictionaryService.translate(
            'Labels.SupplyRequest.Clone.SelectCustomer.Button.Clone'
          ),
          cloneSupplyRequestSearchHint: Services.dictionaryService.translate(
            'Labels.SupplyRequest.Clone.SelectCustomer.SearchHint'
          ),
          cloneSupplyRequestRolloverTitle: Services.dictionaryService.translate(
            'Labels.SupplyRequest.Clone.RollOver.Title'
          ),
          cloneSupplyRequestRolloverIntro: Services.dictionaryService.translate(
            'Labels.SupplyRequest.Clone.RollOver.Intro'
          ),
          cloneSupplyRequestRolloverButton: Services.dictionaryService.translate(
            'Labels.SupplyRequest.Clone.Button.Clone'
          )
        }
      },
      download: {
        downloadZipUrl: '',
        supplyRequestDownloadZip: false
      }
    },
    supplyRequestAudit: {
      type: '',
      financialYear: '',
      dueDateLabel: '',
      dueDateOriginal: '',
      dueDate: '',
      labels: {
        showAuditLogsLabel: Services.dictionaryService.translate(
          'RequestAuditTrailViewerLightbox.Labels.ButtonShowAll'
        ),
        exportAuditLogsLabel: Services.dictionaryService.translate(
          'RequestAuditTrailViewerDropdown.Labels.Button.Export'
        ),
        createSignalLabel: Services.dictionaryService.translate('Reminder.Labels.Create'),
        titleSendSignalLabel: Services.dictionaryService.translate(
          'Reminder.Labels.Title'
        ),
        subTitleSendSignalLabel: Services.dictionaryService.translate(
          'Reminder.Labels.SubTitle'
        ),
        emailFieldLabel: Services.dictionaryService.translate('Reminder.Labels.Subject'),
        emailTextFieldLabel: Services.dictionaryService.translate(
          'Reminder.Labels.EmailText'
        )
      },
      exportInfo: {
        mayExport: false,
        exportUrl: ''
      },
      logs: [],
      exists: false
    },
    supplyRequestDescription: {
      description: '',
      labels: {
        showLabel: Services.dictionaryService.translate('Labels.Common.ShowLess'),
        hideLabel: Services.dictionaryService.translate('Labels.Common.ShowMore')
      }
    },
    supplyRequestPermissions: {
      isEmployeePortal: _isEmployeePortal,
      labels: {
        submitSupply: Services.dictionaryService.translate(
          'SupplyRequestView.Labels.SubmitSupply'
        ),
        submitAll: Services.dictionaryService.translate(
          'SupplyRequestDetail.Labels.SubmitAll'
        ),
        approve: Services.dictionaryService.translate(
          'SupplyRequestView.Labels.ApproveButton'
        ),
        approveHover: Services.dictionaryService.translate(
          'SupplyRequestView.Labels.ApproveButtonHover'
        ),
        reject: Services.dictionaryService.translate(
          'SupplyRequestView.Labels.RejectButton'
        ),
        rejectHover: Services.dictionaryService.translate(
          'SupplyRequestView.Labels.RejectButtonHover'
        ),
        rejectDateFieldLabel: Services.dictionaryService.translate(
          'SupplyRequestView.Labels.SelectDate'
        ),
        submitMotiveTitle: Services.dictionaryService.translate(
          'SupplyRequestEdit.Labels.SubmitMotiveTitle'
        ),
        submitMotivePlaceholder: Services.dictionaryService.translate(
          'SupplyRequestEdit.Labels.SubmitMotive'
        ),
        categoriesNotClose: Services.dictionaryService.translate(
          'Messages.SupplyRequest.CategoriesNotClose'
        )
      },
      maySubmit: false,
      mayApprove: false,
      mayReject: false,
      showSubmit: false,
      maySendSignal: false,
      mayClone: false,
      mayDelete: false,
      mayAddSubItems: false,
      mayDownloadZip: false,
      maySendToDMS: false
    },
    supplyRequestCategories: {
      editMode: false,
      categoryAddedId: '',
      categories: [],
      activeCategoryId: null
    },
    supplyRequestSegmentCommentPost: {
      withSuccess: false,
      commentEditorId: null,
      textArea: null
    },
    supplyRequestDelegationOverview: {
      numberOfSegments: 0,
      categories: []
    },
    supplyRequestUpdatedSegmentsOverview: {
      numberOfSegments: 0,
      segments: []
    },
    supplyRequestCustomers: [],
    supplyRequestNavigation: {
      targetSegment: null
    },
    supplyRequestFileUploader: {
      labels: {
        dropFiles: Services.dictionaryService.translate('FileUploader.Labels.DropFiles'),
        addFiles: Services.dictionaryService.translate('FileUploader.Labels.AddFiles'),
        fileProcessing: Services.dictionaryService.translate(
          'FileUploader.Labels.FileProcessing'
        ),
        fileNotAllowed: Services.dictionaryService.translate(
          'FileUploader.Labels.FileNotAllowed'
        ),
        fileProcessingComplete: Services.dictionaryService.translate(
          'FileUploader.Labels.FileProcessingComplete'
        ),
        fileProcessingError: Services.dictionaryService.translate(
          'FileUploader.Labels.FileProcessingError'
        ),
        fileLoadError: Services.dictionaryService.translate(
          'FileUploader.Labels.FileLoadError'
        ),
        fileLoading: Services.dictionaryService.translate(
          'FileUploader.Labels.FileLoading'
        ),
        fileProcessingAborted: Services.dictionaryService.translate(
          'FileUploader.Labels.FileProcessingAborted'
        ),
        fileProcessingRevertError: Services.dictionaryService.translate(
          'FileUploader.Labels.FileProcessingRevertError'
        ),
        fileRemoveError: Services.dictionaryService.translate(
          'FileUploader.Labels.FileRemoveError'
        ),
        fileSizeNotAvailable: Services.dictionaryService.translate(
          'FileUploader.Labels.FileSizeNotAvailable'
        ),
        invalidField: Services.dictionaryService.translate(
          'FileUploader.Labels.InvalidField'
        ),
        fileWaitingForSize: Services.dictionaryService.translate(
          'FileUploader.Labels.FileWaitingForSize'
        ),
        maxFileSizeExceeded: Services.dictionaryService.translate(
          'FileUploader.Labels.MaxFileSizeExceeded'
        )
      },
      fileDeleted: false,
      chunkSize: 0,
      maxFileSize: 0,
      fileUploadExtensionsCsv: ''
    },
    supplyRequestDelegations: {
      delegationDeleted: false
    },
    supplyRequestAssignContacts: {
      contactsList: [],
      assignedContactsList: []
    },
    labels: {
      cancel: Services.dictionaryService.translate('Labels.Common.Cancel'),
      confirm: Services.dictionaryService.translate('Labels.Common.Confirm'),
      save: Services.dictionaryService.translate('Labels.Common.Save'),

      supplyRequestNotExistsTitle: Services.dictionaryService.translate(
        'Messages.SupplyRequest.NotFoundTitle'
      ),
      supplyRequestNotExistsText: Services.dictionaryService.translate(
        'Messages.SupplyRequest.NotFoundText'
      ),
      supplyRequestEditCategoryTitle: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.Category.Title'
      ),
      supplyRequestEditCategoryShortTitle: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.Category.ShortTitle'
      ),
      supplyRequestEditAddCategory: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.AddCategory.Title'
      ),
      supplyRequestEditAddSubCategory: Services.dictionaryService.translate(
        'SuppyRequestEdit.Labels.AddSubCategory'
      ),
      supplyRequestEditTitle: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.Title'
      ),
      supplyRequestEditAddSegment: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.AddSegment.Title'
      ),
      supplyRequestConfirmationAddNewItem: Services.dictionaryService.translate(
        'SupplyRequestView.ConfirmationAddNewItem'
      ),
      supplyRequestConfirmationAddNewItemTitle: Services.dictionaryService.translate(
        'SupplyRequestView.ConfirmationAddNewItemTitle'
      ),
      supplyRequestDescription: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.Description'
      ),
      supplyRequestAddListItem: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.AddSegmentItem.Button'
      ),
      supplyRequestDownloadAllCategoryFilesButton: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.DownloadAllCategoryFilesButton'
      ),
      supplyRequestDownloadAllSubCategoryFilesButton: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.DownloadAllSubCategoryFilesButton'
      ),
      supplyRequestDownloadAllSegmentFilesButton: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.DownloadAllSegmentFilesButton'
      ),
      supplyRequestDelegateButton: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.DelegateButton'
      ),
      supplyRequestCommentButton: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.CommentButton'
      ),
      supplyRequestEmailLabel: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.Email'
      ),
      supplyRequestPersonalNotesLabel: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.PersonalNotes'
      ),
      supplyRequestDelegateDueDate: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.DelegateDueDate'
      ),
      supplyRequestActiveUntil: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.ActiveUntil'
      ),
      supplyRequestEditResponsibleContacts: Services.dictionaryService.translate(
        'Labels.SupplyRequest.EditResponsibleUsers'
      ),
      supplyRequestSelectedResponsibleContacts: Services.dictionaryService.translate(
        'Labels.SupplyRequest.SelectedResponsibleUsers'
      ),
      supplyRequestResponsibleUsersErrorMessage: Services.dictionaryService.translate(
        'Labels.SupplyRequest.ResponsibleUsersErrorMessage'
      ),
      supplyRequestResponsibleUsersErrorLink: Services.dictionaryService.translate(
        'Labels.SupplyRequest.ResponsibleUsersErrorLink'
      ),
      supplyRequestUpdatedTag: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.UpdatedTag'
      ),
      supplyRequestUpdatedSegmentsHeader: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.UpdatedSegmentsHeader'
      ),
      supplyRequestDelegationOverviewHeader: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.DelegationOverviewHeader'
      ),
      supplyRequestDownloadAllFilesInProgress: Services.dictionaryService.translate(
        'Messages.SupplyRequest.DownloadAllFilesInProgress'
      ),
      supplyRequestConfirmationDeleteSupplyRequestTitle: Services.dictionaryService.translate(
        'SupplyRequestView.ConfirmationDeleteSupplyRequestTitle'
      ),
      supplyRequestConfirmationDeleteSupplyRequest: Services.dictionaryService.translate(
        'SupplyRequestView.ConfirmationDeleteSupplyRequest'
      ),
      delegationMarkedAsDone: Services.dictionaryService.translate(
        'SupplyRequestDelegatedSegment.Labels.DelegationMarkedAsDone'
      ),

      supplyRequestNotApplicableLabel: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.SetToNotApplicable'
      ),
      supplyRequestNotApplicableUndoLabel: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.SetToApplicable'
      ),
      supplyRequestSetToIsComplete: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.SetToIsComplete'
      ),
      supplyRequestSetToIsNotComplete: Services.dictionaryService.translate(
        'SupplyRequestView.Labels.SetToIsNotComplete'
      )
    }
  },
  getters: {
    ID: state => {
      return state.id
    },
    EXISTS: state => {
      return state.exists
    },
    NOT_FOUND_MESSAGE: state => {
      return state.notFoundMessage
    },
    SUPPLY_REQUEST_HEADER: state => {
      return state.supplyRequestHeader
    },
    SUPPLY_REQUEST_AUDIT: state => {
      return state.supplyRequestAudit
    },
    SUPPLY_REQUEST_DESCRIPTION: state => {
      return state.supplyRequestDescription
    },
    SUPPLY_REQUEST_PERMISSIONS: state => {
      return state.supplyRequestPermissions
    },
    SUPPLY_REQUEST_CATEGORIES: state => {
      return state.supplyRequestCategories
    },
    SUPPLY_REQUEST_FILE_UPLOADER: state => {
      return state.supplyRequestFileUploader
    },
    SUPPLY_REQUEST_DELEGATIONS: state => {
      return state.supplyRequestDelegations
    },
    SUPPLY_REQUEST_SEGMENT_COMMENT_POST: state => {
      return state.supplyRequestSegmentCommentPost
    },
    SUPPLY_REQUEST_DELEGATION_OVERVIEW: state => {
      return state.supplyRequestDelegationOverview
    },
    SUPPLY_REQUEST_UPDATED_SEGMENTS_OVERVIEW: state => {
      return state.supplyRequestUpdatedSegmentsOverview
    },
    SUPPLY_REQUEST_CUSTOMERS: state => {
      return state.supplyRequestCustomers
    },
    SUPPLY_REQUEST_NAVIGATION_TARGET_SEGMENT: state => {
      return state.supplyRequestNavigation.targetSegment
    },
    SUPPLY_REQUEST_CATEGORY_ADDED_ID: state => {
      return state.supplyRequestCategories.categoryAddedId
    },
    SUPPLY_REQUEST_ASSIGN_CONTACTS: state => {
      return state.supplyRequestAssignContacts
    },
    LABELS: state => {
      return state.labels
    }
  },
  actions: {
    GET_SUPPLY_REQUEST({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getSupplyRequest,
        '{supplyRequestId}',
        payload
      )

      Services.axiosService.get(endpoint, res => {
        if (res.SupplyRequest) {
          if (res.RedirectUrl === '' || res.RedirectUrl === null) {
            commit('SET_SUPPLY_REQUEST_HEADER', res.SupplyRequest)
            commit('SET_SUPPLY_REQUEST_AUDIT', res.SupplyRequest)
            commit('SET_SUPPLY_REQUEST_DESCRIPTION', res.SupplyRequest)
            commit('SET_SUPPLY_REQUEST_PERMISSIONS', res)
            commit('SET_SUPPLY_REQUEST_FILE_SETTINGS', res)
            commit('SET_EXISTS', true)

            dispatch('GET_CATEGORIES_OF_SUPPLY_REQUEST', res.SupplyRequest.Id)
            dispatch('GET_SUPPLY_REQUEST_DELEGATION_OVERVIEW', res.SupplyRequest.Id)
            dispatch('GET_SUPPLY_REQUEST_UPDATED_SEGMENTS_OVERVIEW', res.SupplyRequest.Id)
          } else {
            window.location.href = res.RedirectUrl
          }
        } else {
          commit('SET_NOT_FOUND_MESSAGE', true)
        }
      })
    },
    GET_CATEGORIES_OF_SUPPLY_REQUEST({ dispatch, commit, state }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getSupplyRequestCategories,
        '{supplyRequestId}',
        payload
      )

      const body = {
        SupplyRequestId: '',
        CategoryId: ''
      }

      Services.axiosService.get(endpoint, res => {
        commit('SET_SUPPLY_REQUEST_CATEGORIES', res)

        if (res.Categories.length > 0) {
          body.SupplyRequestId = res.SupplyRequestId
          if (state.supplyRequestCategories.activeCategoryId) {
            body.CategoryId = state.supplyRequestCategories.activeCategoryId
          } else {
            body.CategoryId = res.Categories[0].Id
          }

          dispatch('GET_CATEGORY_BY_ID', body)
        }
      })
    },
    GET_CATEGORIES_OF_SUPPLY_REQUEST_ONLY({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getSupplyRequestCategories,
        '{supplyRequestId}',
        payload.SupplyRequestId
      )

      const body = {
        SupplyRequestId: payload.SupplyRequestId,
        CategoryId: payload.CategoryId,
        CategoryAdded: true
      }

      Services.axiosService.get(endpoint, res => {
        commit('SET_SUPPLY_REQUEST_CATEGORIES', res)

        // Navigate to the category that was added new
        dispatch('GET_CATEGORY_BY_ID', body)
      })
    },
    GET_CATEGORY_BY_ID({ commit }, payload) {
      commit('UPDATE_ACTIVE_CATEGORY_ID', payload.CategoryId)
      let endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getSupplyRequestCategoryById
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{supplyRequestId}',
        payload.SupplyRequestId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{categoryId}',
        payload.CategoryId
      )

      Services.axiosService.get(endpoint, res => {
        commit('UPDATE_SUPPLY_REQUEST_CATEGORY', res)
        commit('UPDATE_SUPPLY_REQUEST_NAVIGATION_TARGET_SEGMENT', payload.SegmentId)

        if (payload.CategoryAdded === true) {
          // Only execute if a new category is added
          commit('SET_CATEGORY_ADDED_ID', payload)
        }
      })
    },
    POST_SEGMENT_MARKED_OR_NOT_AS_APPLICABLE({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.postSegmentAsNotApplicable
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.subcategoryId,
        SegmentId: payload.segmentId,
        IsNotApplicable: payload.IsNotApplicable
      }

      Services.axiosService.post(endpoint, body, res => {
        let segmentData = null
        let subCategoryData = null
        let categoryData = null

        if (res.ActionResult) {
          segmentData = {
            SupplyRequestId: payload.supplyRequestId,
            CategoryId: payload.categoryId,
            GroupSegmentId: payload.subcategoryId,
            SegmentId: payload.segmentId,
            IsNotApplicable: res.IsNotApplicable,
            MayMarkAsNotApplicableOrCompleted: res.MayMarkAsNotApplicableOrCompleted,
            MayAddComment: res.MayAddComment,
            MayDelegate: res.MayDelegate,
            MayAddFiles: res.MayAddFiles,
            IsComplete: res.IsComplete
          }

          subCategoryData = {
            SupplyRequestId: payload.supplyRequestId,
            CategoryId: payload.categoryId,
            GroupSegmentId: payload.subcategoryId,
            IsComplete: res.GroupSegmentIsComplete
          }

          categoryData = {
            SupplyRequestId: payload.supplyRequestId,
            CategoryId: payload.categoryId,
            GroupSegmentId: payload.subcategoryId,
            IsComplete: res.CategoryIsComplete,
            PercentageOfSegmentsCompleted: res.PercentageOfSegmentsCompleted
          }

          commit('SET_SUPPLY_REQUEST_PERMISSIONS', res)
          commit('UPDATE_SUPPLY_REQUEST_STATUS', res.SupplyRequestBase)
          commit('UPDATE_SEGMENT_IS_NOT_APPLICABLE_STATUS', segmentData)
          commit('UPDATE_SEGMENT_STATUS', segmentData)
          commit('UPDATE_SUBCATEGORY_STATUS', subCategoryData)
          commit('UPDATE_CATEGORY_STATUS', categoryData)

          dispatch('GET_SUPPLY_REQUEST_DELEGATION_OVERVIEW', res.SupplyRequestBase.Id)

          dispatch(
            'GET_SUPPLY_REQUEST_UPDATED_SEGMENTS_OVERVIEW',
            res.SupplyRequestBase.Id
          )
        } else {
          //TODO show toastmessage
        }
      })
    },
    POST_SEGMENT_MARKED_AS_COMPLETE({ dispatch, commit }, payload) {
      return new Promise(resolve => {
        const endpoint = Services.helpersService.buildEndpointRequest(
          _baseEndpoint + _targetApi.postMarkSegmentIsComplete
        )

        const body = {
          SupplyRequestId: payload.supplyRequestId,
          CategoryId: payload.categoryId,
          GroupSegmentId: payload.subcategoryId,
          SegmentId: payload.segmentId,
          IsComplete: payload.isComplete
        }

        Services.axiosService.post(endpoint, body, res => {
          let segmentData = null
          let subCategoryData = null
          let categoryData = null

          if (res.ActionResult) {
            segmentData = {
              SupplyRequestId: payload.supplyRequestId,
              CategoryId: payload.categoryId,
              GroupSegmentId: payload.subcategoryId,
              SegmentId: payload.segmentId,
              IsNotApplicable: res.IsNotApplicable,
              MayMarkAsNotApplicableOrCompleted: res.MayMarkAsNotApplicableOrCompleted,
              MayAddComment: res.MayAddComment,
              MayDelegate: res.MayDelegate,
              MayAddFiles: res.MayAddFiles,
              IsComplete: res.IsComplete
            }

            subCategoryData = {
              SupplyRequestId: payload.supplyRequestId,
              CategoryId: payload.categoryId,
              GroupSegmentId: payload.subcategoryId,
              IsComplete: res.GroupSegmentIsComplete
            }

            categoryData = {
              SupplyRequestId: payload.supplyRequestId,
              CategoryId: payload.categoryId,
              GroupSegmentId: payload.subcategoryId,
              IsComplete: res.CategoryIsComplete,
              PercentageOfSegmentsCompleted: res.PercentageOfSegmentsCompleted
            }

            commit('SET_SUPPLY_REQUEST_PERMISSIONS', res)
            commit('UPDATE_SUPPLY_REQUEST_STATUS', res.SupplyRequestBase)
            commit('UPDATE_SEGMENT_IS_NOT_APPLICABLE_STATUS', segmentData)
            commit('UPDATE_SEGMENT_STATUS', segmentData)
            commit('UPDATE_SUBCATEGORY_STATUS', subCategoryData)
            commit('UPDATE_CATEGORY_STATUS', categoryData)

            dispatch('GET_SUPPLY_REQUEST_DELEGATION_OVERVIEW', res.SupplyRequestBase.Id)
            dispatch(
              'GET_SUPPLY_REQUEST_UPDATED_SEGMENTS_OVERVIEW',
              res.SupplyRequestBase.Id
            )
            resolve(res.ActionResult)
          } else {
            //TODO show toastmessage
          }
        })
      })
    },
    POST_COMMENT_TO_SUPPLY_REQUEST({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.postSupplyRequestComment
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.subcategoryId,
        SegmentId: payload.segmentId,
        Comment: payload.comment
      }

      Services.axiosService.post(endpoint, body, res => {
        let segmentData = null

        if (res.ActionResult) {
          segmentData = {
            SupplyRequestId: payload.supplyRequestId,
            CategoryId: payload.categoryId,
            GroupSegmentId: payload.subcategoryId,
            SegmentId: payload.segmentId,
            PostedWithSuccess: res.ActionResult,
            CommentEditorId: payload.commentEditorId,
            TextArea: payload.textArea
          }

          commit('SET_SUPPLY_REQUEST_PERMISSIONS', res)
          commit('UPDATE_SUPPLY_REQUEST_STATUS', res.SupplyRequestBase)
          commit('UPDATE_SUPPLY_REQUEST_SEGMENT_COMMENT_POST', segmentData)

          dispatch('GET_UPDATED_SEGMENT_COMMENTS', segmentData)
          dispatch(
            'GET_SUPPLY_REQUEST_UPDATED_SEGMENTS_OVERVIEW',
            res.SupplyRequestBase.Id
          )
        } else {
          //TODO show toastmessage
        }
      })
    },
    GET_UPDATED_SEGMENT_COMMENTS({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getSegmentUpdatedComments
      )

      const body = {
        SupplyRequestId: payload.SupplyRequestId,
        CategoryId: payload.CategoryId,
        GroupSegmentId: payload.GroupSegmentId,
        SegmentId: payload.SegmentId,
        IsComplete: payload.IsComplete
      }

      const segmentData = body

      Services.axiosService.post(endpoint, body, res => {
        segmentData.Comments = res.Comments
        commit('UPDATE_SUPPLY_REQUEST_SEGMENT_COMMENTS', segmentData)
      })
    },
    GET_UPDATED_SEGMENT_FILES({ dispatch, commit }, payload) {
      let endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getFileOverviewBySegmentId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{supplyRequestId}',
        payload.supplyRequestId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{categoryId}',
        payload.categoryId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{groupSegmentId}',
        payload.subCategoryId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{segmentId}',
        payload.segmentId
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.subCategoryId,
        SegmentId: payload.segmentId
      }

      const segmentData = body

      Services.axiosService.post(endpoint, body, res => {
        segmentData.Files = res.Files
        segmentData.TotalNumberOfFilesCategory = res.TotalNumberOfFilesCategory
        segmentData.TotalNumberOfFilesSubCategory = res.TotalNumberOfFilesSubCategory
        segmentData.CategoryMayDownloadZip = res.CategoryMayDownloadZip
        segmentData.CategoryDownloadZipUrl = res.CategoryDownloadZipUrl
        segmentData.GroupSegmentMayDownloadZip = res.GroupSegmentMayDownloadZip
        segmentData.GroupSegmentDownloadZipUrl = res.GroupSegmentDownloadZipUrl
        segmentData.MayDownloadZip = res.MayDownloadZip
        segmentData.DownloadZipUrl = res.DownloadZipUrl
        segmentData.SupplyRequestMayDownloadZip = res.SupplyRequestMayDownloadZip

        commit('UPDATE_SUPPLY_REQUEST_SEGMENT_FILES', segmentData)
        commit('RESET_DELETED_FILE', segmentData)
        commit('UPDATE_INITIAL_FILES_NUMBER', segmentData)
        commit('UPDATE_SUPPLY_REQUEST_DOWNLOAD_FILES', segmentData)
        commit(
          'UPDATE_SUPPLY_REQUEST_PERMISSIONS_DOWNLOAD',
          segmentData.SupplyRequestMayDownloadZip
        )

        dispatch('GET_SUPPLY_REQUEST_UPDATED_SEGMENTS_OVERVIEW', res.SupplyRequestId)
      })
    },
    GET_UPDATED_SEGMENT_DELEGATIONS({ commit }, payload) {
      let endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getDelegationOverviewBySegmentId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{supplyRequestId}',
        payload.supplyRequestId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{categoryId}',
        payload.categoryId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{groupSegmentId}',
        payload.subCategoryId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{segmentId}',
        payload.segmentId
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.subCategoryId,
        SegmentId: payload.segmentId
      }

      const segmentData = body

      Services.axiosService.get(endpoint, res => {
        segmentData.Delegations = res
        commit('UPDATE_SUPPLY_REQUEST_SEGMENT_DELEGATIONS', segmentData)
        commit('RESET_DELETED_DELEGATION', segmentData)
        commit('UPDATE_INITIAL_DELEGATIONS_NUMBER', segmentData)
      })
    },
    RESET_POST_ACTIONS_COMMENT({ commit }, payload) {
      commit('UPDATE_SUPPLY_REQUEST_SEGMENT_COMMENT_POST', payload)
    },
    GET_AUDIT_LOGS({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getAuditLogs,
        '{supplyRequestId}',
        payload
      )

      Services.axiosService.get(endpoint, res => {
        commit('UPDATE_SUPPLY_REQUEST_AUDIT_LOGS', res)
      })
    },
    GET_CUSTOMERS({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getCustomers,
        '{searchTerm}',
        payload
      )

      Services.axiosService.get(endpoint, res => {
        commit('UPDATE_SUPPLY_REQUEST_CUSTOMERS', res)
      })
    },
    GET_SUPPLY_REQUEST_UPDATED_SEGMENTS_OVERVIEW({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getUpdatedSegmentsOverview,
        '{supplyRequestId}',
        payload
      )

      Services.axiosService.get(endpoint, res => {
        commit('SET_SUPPLY_REQUEST_UPDATED_SEGMENTS_OVERVIEW', res)
      })
    },
    GET_SUPPLY_REQUEST_DELEGATION_OVERVIEW({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getDelegationsOverview,
        '{supplyRequestId}',
        payload
      )

      Services.axiosService.get(endpoint, res => {
        commit('SET_SUPPLY_REQUEST_DELEGATION_OVERVIEW', res)
      })
    },
    GET_ATTACHMENTS({ commit }, payload) {
      let endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getAttachmentOverviewBySegmentId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{supplyRequestId}',
        payload.supplyRequestId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{categoryId}',
        payload.categoryId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{groupSegmentId}',
        payload.subCategoryId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{segmentId}',
        payload.segmentId
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.subCategoryId,
        SegmentId: payload.segmentId
      }

      const segmentData = body

      Services.axiosService.post(endpoint, body, res => {
        segmentData.Attachments = res.Attachments
        commit('UPDATE_SUPPLY_REQUEST_ATTACHMENTS', segmentData)
      })
    },
    DOWNLOAD_FILE_AUDIT_LOGS(_, payload) {
      Services.helpersService.downloadFile(payload)
    },
    POST_SUBMIT_THE_SUPPLY_REQUEST({ dispatch, commit }, payload) {
      const targetControllerAction =
        Api.config.portalType === 'Employee'
          ? _targetApi.postSubmitSupplyRequestOnClientBehalf
          : _targetApi.postSubmitSupplyRequest
      const targetSuccessKey =
        Api.config.portalType === 'Employee'
          ? 'Messages.SupplyRequest.StatusSubmittedOnBehalfOfClientSuccess'
          : 'Messages.SupplyRequest.StatusSubmittedSuccess'
      const targetErrorKey = 'Messages.SupplyRequest.StatusSubmittedError'
      const isClientPortal = Api.config.portalType === 'Employee' ? false : true

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        Reason: payload.comment
      }

      let endpoint = ''

      if (isClientPortal) {
        endpoint = Services.helpersService.buildEndpointRequest(
          _baseEndpoint + targetControllerAction,
          '{supplyRequestId}',
          payload.supplyRequestId
        )
      } else {
        endpoint = Services.helpersService.buildEndpointRequest(
          _baseEndpoint + targetControllerAction
        )
      }

      Services.axiosService.post(endpoint, body, res => {
        if (res.ActionResult) {
          commit('SET_SUPPLY_REQUEST_PERMISSIONS', res)
          commit('UPDATE_SUPPLY_REQUEST_STATUS', res.SupplyRequestBase)
          toastr.success(Services.dictionaryService.translate(targetSuccessKey))

          if (isClientPortal) {
            dispatch('GET_CATEGORIES_OF_SUPPLY_REQUEST', res.SupplyRequestBase.Id)
          }
        } else {
          toastr.error(Services.dictionaryService.translate(targetErrorKey))
        }
      })
    },
    POST_SUBMIT_SIGNAL(ctx, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.postSubmitSignal
      )

      const targetSuccessKey = 'Reminder.Messages.Success'
      const targetErrorKey = 'Reminder.Messages.NoContacts'

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        Subject: payload.inputEmailFieldText,
        Message: payload.inputEmailTextFieldText
      }

      Services.axiosService.post(endpoint, body, res => {
        if (res.ActionResult) {
          toastr.success(Services.dictionaryService.translate(targetSuccessKey))
        } else {
          toastr.error(Services.dictionaryService.translate(targetErrorKey))
        }
      })
    },
    POST_APPROVE_SUPPLY_REQUEST({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.postApproveSupplyRequest,
        '{supplyRequestId}',
        payload
      )

      const targetSuccessKey = 'Messages.SupplyRequest.StatusApprovedSuccess'
      const targetErrorKey = 'Messages.SupplyRequest.StatusSubmittedError'

      Services.axiosService.post(endpoint, null, res => {
        if (res.ActionResult) {
          commit('SET_SUPPLY_REQUEST_PERMISSIONS', res)
          commit('UPDATE_SUPPLY_REQUEST_STATUS', res.SupplyRequestBase)
          toastr.success(Services.dictionaryService.translate(targetSuccessKey))

          dispatch('GET_CATEGORIES_OF_SUPPLY_REQUEST', res.SupplyRequestBase.Id)
        } else {
          toastr.error(Services.dictionaryService.translate(targetErrorKey))
        }
      })
    },
    POST_REJECT_SUPPLY_REQUEST({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.postRejectSupplyRequest
      )

      const today = Services.momentService()
        .startOf('day')
        .format(Services.helpersService.momentDateFormat)

      const tempDueDate = payload.dueDate !== '' ? payload.dueDate : today

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        DueDate: tempDueDate
      }

      Services.axiosService.post(endpoint, body, res => {
        if (res.ActionResult) {
          commit('SET_SUPPLY_REQUEST_PERMISSIONS', res)
          commit('UPDATE_SUPPLY_REQUEST_STATUS', res.SupplyRequestBase)
          commit('UPDATE_SUPPLY_REQUEST_AUDIT_DUE_DATE', res.SupplyRequestBase.DueDate)
        }
      })
    },
    POST_COPY_SUPPLY_REQUEST(_, payload) {
      let endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.postCopySupplyRequest
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{supplyRequestId}',
        payload.SupplyRequestId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{customerId}',
        payload.CustomerId
      )

      Services.axiosService.post(endpoint, null, res => {
        window.location.href = res.RedirectUrl
      })
    },
    POST_CLONE_SUPPLY_REQUEST(_, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.postCloneSupplyRequest,
        '{supplyRequestId}',
        payload
      )

      Services.axiosService.post(endpoint, null, res => {
        window.location.href = res.RedirectUrl
      })
    },
    DELETE_FILE({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.deleteFile,
        '{supplyRequestFileId}',
        payload.fileId
      )

      Services.axiosService.delete(endpoint, () => {
        commit('UPDATE_DELETED_FILE')
      })
    },
    DELETE_DELEGATION({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.deleteDelegation,
        '{supplyRequestDelegationId}',
        payload.delegeeId
      )

      Services.axiosService.delete(endpoint, () => {
        commit('UPDATE_DELETED_DELEGATION')
        dispatch('GET_SUPPLY_REQUEST_DELEGATION_OVERVIEW', payload.supplyRequestId)
      })
    },
    UPDATE_INITIAL_FILES_NUMBER({ commit }, payload) {
      commit('UPDATE_INITIAL_FILES_NUMBER', payload)
    },
    UPDATE_INITIAL_DELEGATIONS_NUMBER({ commit }, payload) {
      commit('UPDATE_INITIAL_DELEGATION_NUMBER', payload)
    },
    POST_CATEGORY({ dispatch }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.addCategory
      )

      const body = {
        SupplyRequestId: payload.id,
        CategoryId: payload.categoryId,
        Title: payload.title,
        ShortTitle: payload.shortTitle,
        Position: payload.position
      }

      Services.axiosService.post(endpoint, body, res => {
        dispatch('GET_CATEGORIES_OF_SUPPLY_REQUEST_ONLY', res)
      })
    },
    POST_SUBCATEGORY({ dispatch }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.addSubCategory
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.subCategoryId,
        Title: payload.title,
        Position: payload.position
      }

      Services.axiosService.post(endpoint, body, res => {
        const body = {
          SupplyRequestId: res.SupplyRequestId,
          CategoryId: res.CategoryId
        }

        dispatch('GET_CATEGORY_BY_ID', body)
      })
    },
    ADD_SEGMENT({ commit }, payload) {
      commit('UPDATE_SEGMENTS', payload)
    },
    POST_ADDED_SEGMENT({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.addSegment
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.groupSegmentId,
        SegmentId: payload.segmentId,
        Title: payload.clone.title,
        Description: payload.clone.description,
        HelpText: payload.helpText,
        SegmentItems: payload.clone.segmentItems,
        Position: payload.position
      }

      Services.axiosService.post(endpoint, body, res => {
        const body = {
          SupplyRequestId: res.SupplyRequestId,
          CategoryId: res.CategoryId
        }

        const segmentData = {
          segmentData: payload,
          segmentId: res.SegmentId
        }

        const categoryData = {
          SupplyRequestId: payload.supplyRequestId,
          CategoryId: payload.categoryId,
          GroupSegmentId: payload.subcategoryId,
          IsComplete: res.CategoryIsComplete,
          PercentageOfSegmentsCompleted: res.PercentageOfSegmentsCompleted
        }

        dispatch('GET_CATEGORY_BY_ID', body)
        commit('SET_DATA_ADDED_SEGMENT', segmentData)
        commit('UPDATE_CATEGORY_STATUS', categoryData)
      })
    },
    POST_DELEGEE({ dispatch }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.addDelegation
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.groupSegmentId,
        SegmentId: payload.segmentId,
        Email: payload.email,
        Reason: payload.reason,
        DueDate: payload.dueDate
      }

      Services.axiosService.post(endpoint, body, res => {
        const body = {
          supplyRequestId: payload.supplyRequestId,
          categoryId: payload.categoryId,
          subCategoryId: payload.groupSegmentId,
          segmentId: payload.segmentId
        }

        dispatch('GET_UPDATED_SEGMENT_DELEGATIONS', body)
        dispatch('GET_SUPPLY_REQUEST_DELEGATION_OVERVIEW', res.SupplyRequestId)
      })
    },
    SET_CANCELLED({ commit }, payload) {
      commit('UPDATE_CANCELLED', payload)
    },
    UPDATE_ASSIGN_CONTACTS({ commit }, payload) {
      commit('UPDATE_ASSIGN_CONTACTS', payload)
    },
    POST_ASSIGN_CONTACTS({ dispatch }, payload) {
      //Due to the API endpoint being used on other places we are not adding the API endpoint to the "_targetApi" in order to reduce duplicated code
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + Api.assignContactsApi.updateAssignedContacts
      )

      const body = {
        supplyRequestId: payload.supplyRequestId,
        assignContactsIds: payload.assignContactsIds
      }

      Services.axiosService.post(endpoint, body, res => {
        if (res) {
          dispatch('GET_ASSIGN_CONTACTS', payload)
        } else {
          //TODO: show toastmessage
        }
      })
    },
    GET_ASSIGN_CONTACTS({ commit, getters }, payload) {
      //Due to the API endpoint being used on other places we are not adding the API endpoint to the "_targetApi" in order to reduce duplicated code
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + Api.assignContactsApi.getContacts,
        '{supplyRequestId}',
        payload.supplyRequestId
      )

      //Check if the supply request exists. If one exists then the user has access to its assigned contacts
      if (getters.EXISTS) {
        Services.axiosService.get(endpoint, res => {
          if (res) {
            const body = {
              contactsList: Services.assignContactsService.processContactsList(res),
              assignedContactsList: Services.assignContactsService.processAssignedContactsList(
                res
              )
            }

            commit('UPDATE_ASSIGN_CONTACTS', body)
          } else {
            //TODO: show toastmessage
          }
        })
      }
    },
    RESET_SUPPLY_REQUEST_NAVIGATION_TARGET_SEGMENT({ commit }, payload) {
      commit('RESET_SUPPLY_REQUEST_NAVIGATION_TARGET_SEGMENT', payload)
    },
    SET_CLICKED_STATUS_OF_DOWNLOAD({ commit }, payload) {
      commit('SET_CLICKED_STATUS_OF_DOWNLOAD', payload)
    },
    DELETE_SUPPLY_REQUEST(_, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.deleteSupplyRequest,
        '{supplyRequestId}',
        payload.supplyRequestId
      )

      Services.axiosService.delete(endpoint, res => {
        if (res.ActionResult === true) {
          window.location.href = res.RedirectUrl
        }
      })
    },
    POST_MANUAL_UPLOAD({ dispatch }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.manualUploadSupplyRequest,
        '{supplyRequestId}',
        payload
      )

      Services.axiosService.post(endpoint, null, res => {
        if (res.ActionResult) {
          dispatch('GET_SUPPLY_REQUEST', payload)
        } else {
          //TODO: show toastmessage
        }
      })
    }
  },
  mutations: {
    SET_EXISTS: (state, payload) => {
      state.exists = payload
    },
    SET_NOT_FOUND_MESSAGE: (state, payload) => {
      state.notFoundMessage = payload
    },
    SET_LABELS: (state, payload) => {
      state.labels = payload
    },
    SET_SUPPLY_REQUEST_HEADER: (state, payload) => {
      state.supplyRequestHeader.title = payload.Title
      state.supplyRequestHeader.client.name = payload.Customer.Name
      state.supplyRequestHeader.client.url = payload.Customer.Url
      state.supplyRequestHeader.state.code = payload.Status.toString()
      state.supplyRequestHeader.state.label =
        Services.processStatusService.Properties[payload.Status].translation
      state.supplyRequestHeader.download.downloadZipUrl = Services.helpersService.buildEndpointRequest(
        _fileEndpoint + _targetApi.downloadZipForSupplyRequest,
        '{supplyRequestId}',
        payload.Id
      )
    },
    SET_SUPPLY_REQUEST_AUDIT: (state, payload) => {
      state.supplyRequestAudit.type = payload.Phases[0].Name + ' - '
      state.supplyRequestAudit.financialYear = payload.FinancialYear.toString()
      state.supplyRequestAudit.dueDateLabel =
        Services.dictionaryService.translate('SupplyRequestView.Labels.RequestDueOn') +
        ': '
      const dueDateOffset = Services.momentService(payload.DueDate).utcOffset()
      const localDueDate = Services.momentService(payload.DueDate)
        .subtract(dueDateOffset, 'minutes') //calculate UTC-saved day timestamp at 00:00:00 in local format with help of offset.
        .format(Services.helpersService.momentDateFormat)
      state.supplyRequestAudit.dueDateOriginal = localDueDate
      state.supplyRequestAudit.dueDate = localDueDate
    },
    SET_SUPPLY_REQUEST_DESCRIPTION: (state, payload) => {
      state.supplyRequestDescription.description = payload.Description
    },
    SET_SUPPLY_REQUEST_PERMISSIONS: (state, payload) => {
      state.supplyRequestPermissions.maySubmit = payload.MaySubmit
      state.supplyRequestPermissions.mayApprove = payload.MayApprove
      state.supplyRequestPermissions.mayReject = payload.MayReject
      state.supplyRequestPermissions.showSubmit = payload.ShowSubmit
      state.supplyRequestPermissions.maySendSignal = payload.MaySendSignal
      state.supplyRequestPermissions.mayClone = payload.MayClone
      state.supplyRequestPermissions.mayDelete = payload.MayDelete
      state.supplyRequestPermissions.mayAddSubItems = payload.MayAddSubItems
      state.supplyRequestPermissions.mayDownloadZip = payload.MayDownloadZip
      state.supplyRequestPermissions.maySendToDMS = payload.MaySendToDMS
    },
    SET_SUPPLY_REQUEST_FILE_SETTINGS: (state, payload) => {
      state.supplyRequestFileUploader.chunkSize = payload.ChunkSize
      state.supplyRequestFileUploader.maxFileSize = payload.MaxFileSize
      state.supplyRequestFileUploader.fileUploadExtensionsCsv =
        payload.FileUploadExtensionsCsv
    },
    SET_SUPPLY_REQUEST_CATEGORIES: (state, payload) => {
      state.supplyRequestCategories.categories = Services.supplyRequest2DetailService.processCategories(
        state.id,
        payload
      )
    },
    SET_SUPPLY_REQUEST_DELEGATION_OVERVIEW: (state, payload) => {
      Services.supplyRequest2DetailService.processDelegationOverview(
        state.supplyRequestDelegationOverview,
        payload
      )
    },
    SET_SUPPLY_REQUEST_UPDATED_SEGMENTS_OVERVIEW: (state, payload) => {
      Services.supplyRequest2DetailService.processUpdatedSegmentsOverview(
        state.supplyRequestUpdatedSegmentsOverview,
        payload
      )
    },
    SET_CLICKED_STATUS_OF_DOWNLOAD: state => {
      state.supplyRequestHeader.download.supplyRequestDownloadZip = true
    },
    UPDATE_SUPPLY_REQUEST_CATEGORY: (state, payload) => {
      Services.supplyRequest2DetailService.updateCategory(
        state.supplyRequestCategories.categories,
        payload
      )
    },
    UPDATE_ACTIVE_CATEGORY_ID(state, payload) {
      state.supplyRequestCategories.activeCategoryId = payload
    },
    UPDATE_SUPPLY_REQUEST_DOWNLOAD_FILES: (state, payload) => {
      Services.supplyRequest2DetailService.updateCategoryDownloadFiles(
        state.supplyRequestCategories.categories,
        payload
      )
    },
    UPDATE_SUPPLY_REQUEST_NAVIGATION_TARGET_SEGMENT: (state, payload) => {
      state.supplyRequestNavigation.targetSegment = payload
    },
    RESET_SUPPLY_REQUEST_NAVIGATION_TARGET_SEGMENT: state => {
      state.supplyRequestNavigation.targetSegment = null
    },
    UPDATE_SUPPLY_REQUEST_STATUS: (state, payload) => {
      state.supplyRequestHeader.state.code = payload.Status.toString()
      state.supplyRequestHeader.state.label =
        Services.processStatusService.Properties[payload.Status].translation
    },
    UPDATE_SEGMENT_IS_NOT_APPLICABLE_STATUS: (state, payload) => {
      Services.supplyRequest2DetailService.updatedSegmentIsNotApplicableStatus(
        state.supplyRequestCategories.categories,
        payload
      )
    },
    UPDATE_SEGMENT_STATUS: (state, payload) => {
      Services.supplyRequest2DetailService.updatedSegmentStatus(
        state.supplyRequestCategories.categories,
        payload
      )
    },
    UPDATE_SUBCATEGORY_STATUS: (state, payload) => {
      Services.supplyRequest2DetailService.updatedSubCategoryStatus(
        state.supplyRequestCategories.categories,
        payload
      )
    },
    UPDATE_CATEGORY_STATUS: (state, payload) => {
      Services.supplyRequest2DetailService.updatedCategoryStatus(
        state.supplyRequestCategories.categories,
        payload
      )
    },
    UPDATE_SUPPLY_REQUEST_SEGMENT_COMMENT_POST: (state, payload) => {
      state.supplyRequestSegmentCommentPost.withSuccess = payload.PostedWithSuccess
      state.supplyRequestSegmentCommentPost.commentEditorId = payload.CommentEditorId
      state.supplyRequestSegmentCommentPost.textArea = payload.TextArea
    },
    UPDATE_SUPPLY_REQUEST_SEGMENT_COMMENTS: (state, payload) => {
      Services.supplyRequest2DetailService.updateSegmentComments(
        state.supplyRequestCategories.categories,
        payload
      )
    },
    UPDATE_SUPPLY_REQUEST_SEGMENT_FILES: (state, payload) => {
      Services.supplyRequest2DetailService.updateSegmentFiles(
        state.supplyRequestCategories.categories,
        payload
      )
    },
    UPDATE_SUPPLY_REQUEST_ATTACHMENTS: (state, payload) => {
      Services.supplyRequest2DetailService.updateSegmentAttachments(
        state.supplyRequestCategories.categories,
        payload
      )
    },
    UPDATE_SUPPLY_REQUEST_SEGMENT_DELEGATIONS: (state, payload) => {
      Services.supplyRequest2DetailService.updateSegmentDelegations(
        state.supplyRequestCategories.categories,
        payload
      )
    },
    UPDATE_SUPPLY_REQUEST_AUDIT_LOGS: (state, payload) => {
      Services.supplyRequest2DetailService.processAuditLogs(
        state.supplyRequestAudit,
        payload
      )

      state.supplyRequestAudit.exists = true
    },
    UPDATE_SUPPLY_REQUEST_CUSTOMERS: (state, payload) => {
      const dataSet = state.supplyRequestCustomers
      const result = Services.supplyRequest2DetailService.processCustomers(
        dataSet,
        payload
      )

      state.supplyRequestCustomers = result
    },
    UPDATE_SUPPLY_REQUEST_AUDIT_DUE_DATE: (state, payload) => {
      const dueDateOffset = Services.momentService(payload).utcOffset()
      state.supplyRequestAudit.dueDate = Services.momentService(payload) //convert to local day
        .subtract(dueDateOffset, 'minutes')
        .format(Services.helpersService.momentDateFormat)
    },
    UPDATE_DELETED_FILE: state => {
      state.supplyRequestFileUploader.fileDeleted = true
    },
    RESET_DELETED_FILE: state => {
      state.supplyRequestFileUploader.fileDeleted = false
    },
    UPDATE_DELETED_DELEGATION: state => {
      state.supplyRequestDelegations.delegationDeleted = true
    },
    RESET_DELETED_DELEGATION: state => {
      state.supplyRequestDelegations.delegationDeleted = false
    },
    UPDATE_ASSIGN_CONTACTS: (state, payload) => {
      state.supplyRequestAssignContacts.contactsList = payload.contactsList
      state.supplyRequestAssignContacts.assignedContactsList =
        payload.assignedContactsList
    },
    UPDATE_INITIAL_FILES_NUMBER: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.CategoryId) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories.findIndex(
        subCategory => subCategory.id.original === payload.GroupSegmentId
      )
      const segmentIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories[subCategoryIndex].segments.findIndex(
        segment => segment.id.original === payload.SegmentId
      )

      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].showInitialFilesNumber = false
      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].numberOfFiles =
        state.supplyRequestCategories.categories[categoryIndex].subCategories[
          subCategoryIndex
        ].segments[segmentIndex].files.length // Otherwise number isn't correct
    },
    UPDATE_INITIAL_DELEGATIONS_NUMBER: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.CategoryId) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories.findIndex(
        subCategory => subCategory.id.original === payload.GroupSegmentId
      )
      const segmentIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories[subCategoryIndex].segments.findIndex(
        segment => segment.id.original === payload.SegmentId
      )

      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].showInitialDelegationsNumber = false
      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].numberOfDelegations =
        state.supplyRequestCategories.categories[categoryIndex].subCategories[
          subCategoryIndex
        ].segments[segmentIndex].delegations.length // Otherwise number isn't correct
    },
    SET_CATEGORY_ADDED_ID: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.CategoryId) {
          categoryIndex = index
        }

        return index
      })

      // When a new category is added it should be immediately set to true
      state.supplyRequestCategories.categories[categoryIndex].isLoaded = true
      state.supplyRequestCategories.categoryAddedId = payload.CategoryId
    },
    UPDATE_SEGMENTS: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.category.id.original) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories.findIndex(
        subCategory => subCategory.id.original === payload.subCategory.id.original
      )

      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments.push(payload.segmentData)
      state.supplyRequestCategories.segmentAddedId = payload.segmentData.id

      return
    },
    SET_DATA_ADDED_SEGMENT: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.segmentData.categoryId) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories.findIndex(
        subCategory => subCategory.id.original === payload.segmentData.groupSegmentId
      )
      const segmentIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories[subCategoryIndex].segments.findIndex(
        segment => segment.id === payload.segmentId
      )

      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].title = payload.segmentData.clone.title
      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].description = payload.segmentData.clone.description
      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].segmentItems = payload.segmentData.clone.segmentItems

      return
    },
    UPDATE_CANCELLED: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.categoryId.original) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories.findIndex(
        subCategory => subCategory.id.original === payload.subCategoryId.original
      )
      const segmentIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories[subCategoryIndex].segments.findIndex(
        segment => segment.id.original === payload.segment.id.original
      )

      if (
        state.supplyRequestCategories.categories[categoryIndex].subCategories[
          subCategoryIndex
        ].segments[segmentIndex].title === '' &&
        state.supplyRequestCategories.categories[categoryIndex].subCategories[
          subCategoryIndex
        ].segments[segmentIndex].description === '' &&
        state.supplyRequestCategories.categories[categoryIndex].subCategories[
          subCategoryIndex
        ].segments[segmentIndex].segmentItems.length === 0
      ) {
        const index = state.supplyRequestCategories.categories[
          categoryIndex
        ].subCategories[subCategoryIndex].segments.findIndex(
          segment => segment.id === payload.segmentId
        )
        state.supplyRequestCategories.categories[categoryIndex].subCategories[
          subCategoryIndex
        ].segments.splice(index, 1)
      }
    },
    UPDATE_SUPPLY_REQUEST_PERMISSIONS_DOWNLOAD: (state, payload) => {
      state.supplyRequestPermissions.mayDownloadZip = payload
    }
  }
}
