import dictionaryService from '@/services/modules/dictionaryService'
import toastr from 'toastr'
import Api from '../../api'
import Services from '../../services'

const _isEmployeePortal = Api.config.portalType === 'Employee' ? true : false
const _portController = _isEmployeePortal
  ? 'SupplyRequest2EmployeeJson'
  : 'SupplyRequest2ClientJson'
const _baseEndpoint = Api.config.endpointBase + _portController + '/'
const _targetApi = Api.supplyRequest2TemplateMaintenanceApi

export default {
  namespaced: true,

  state: {
    templateData: {
      id: '',
      title: '',
      description: '',
      templateLoaded: false,
      categories: [],
      categoriesLoaded: false,
      segmentAdded: false,
      segmentAddedId: '',
      categoryDeleted: false,
      templateSaved: false,
      templateDeleted: false,
      categoryAddedId: ''
    },
    templateMaintenance: {
      templates: [],
      templatesLoaded: false
    },
    templateMaintenanceInitialData: {
      pageTitle: '',
      services: []
    },
    templateMaintenanceReferenceData: {
      phases: [],
      languages: []
    },
    labels: {
      supplyRequestTemplateMaintenanceLabelService: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.LabelService'
      ),
      supplyRequestTemplateMaintenanceSelectService: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.SelectService'
      ),
      supplyRequestTemplateMaintenanceLabelTemplate: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.LabelTemplate'
      ),
      supplyRequestTemplateMaintenanceSelectTemplate: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.SelectTemplate'
      ),
      supplyRequestTemplateMaintenanceLabelOr: Services.dictionaryService.translate(
        'Labels.Common.Or'
      ),
      supplyRequestTemplateMaintenanceLabelsDeleteTemplate: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.DeleteTemplate'
      ),
      supplyRequestTemplateMaintenanceCreateTemplate: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.CreateTemplate'
      ),
      supplyRequestTemplateMaintenanceLabelPhase: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.LabelPhase'
      ),
      supplyRequestTemplateMaintenanceSelectPhase: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.SelectPhase'
      ),
      supplyRequestTemplateMaintenanceLabelLanguage: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.LabelLanguage'
      ),
      supplyRequestTemplateMaintenanceSelectLanguage: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.SelectLanguage'
      ),
      supplyRequestTemplateMaintenanceLabelCountries: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.LabelCountries'
      ),
      supplyRequestTemplateMaintenanceSelectCountries: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.SelectCountries'
      ),
      supplyRequestTemplateMaintenanceCountriesSelected: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.CountriesSelected'
      ),
      supplyRequestTemplateMaintenanceNoCountriesSelected: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.NoCountriesSelected'
      ),
      supplyRequestTemplateMaintenanceTitle: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.Title'
      ),
      supplyRequestTemplateMaintenanceDescription: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.Description'
      ),
      supplyRequestTemplateMaintenanceConfirmLostChanges: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Messages.ConfirmLostChanges'
      ),
      supplyRequestTemplateMaintenanceConfirmDeleteTemplate: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Messages.ConfirmDeleteTemplate'
      ),
      supplyRequestTemplateMaintenanceConfirmDeleteTemplateMultipleCountries: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Messages.ConfirmDeleteTemplateMultipleCountries'
      ),
      supplyRequestTemplateMaintenanceConfirmSaveTemplateMultipleCountries: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Messages.ConfirmSaveTemplateMultipleCountries'
      ),
      supplyRequestTemplateMaintenanceCreateCategory: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.CreateCategory'
      ),
      supplyRequestTemplateMaintenanceCategoryTitle: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.CategoryTitle'
      ),
      supplyRequestTemplateMaintenanceCategoryShorttitle: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.CategoryShorttitle'
      ),

      supplyRequestTemplateMaintenanceSubCategoryTitle: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.SubCategoryTitle'
      ),
      supplyRequestTemplateMaintenanceCreateSubCategory: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.CreateSubCategory'
      ),

      supplyRequestTemplateMaintenanceCreateSegment: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.CreateSegment'
      ),
      supplyRequestTemplateMaintenanceCreateListItem: Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Labels.CreateListItem'
      ),

      cancel: Services.dictionaryService.translate('Labels.Common.Cancel'),
      confirm: Services.dictionaryService.translate('Labels.Common.Confirm'),
      edit: Services.dictionaryService.translate('Labels.Common.Edit'),
      save: Services.dictionaryService.translate('Labels.Common.Save')
    },
    createTemplate: {
      templateCreated: false,
      result: []
    },
    updateTemplate: {
      templateUpdated: false,
      result: []
    }
  },
  getters: {
    TEMPLATES: state => {
      return state.templateMaintenance
    },
    TEMPLATE_DATA: state => {
      return state.templateData
    },
    TEMPLATE_MAINTENANCE_INITIAL_DATA: state => {
      return state.templateMaintenanceInitialData
    },
    TEMPLATE_MAINTENANCE_REFERENCE_DATA: state => {
      return state.templateMaintenanceReferenceData
    },
    TEMPLATE_MAINTENANCE_TEMPLATES_LOADED: state => {
      return state.templateMaintenance.templatesLoaded
    },
    TEMPLATE_MAINTENANCE_CATEGORIES_LOADED: state => {
      return state.templateData.categoriesLoaded
    },
    TEMPLATE_DATA_TEMPLATE_DELETED: state => {
      return state.templateData.templateDeleted
    },
    TEMPLATE_DATA_TEMPLATE_CREATED: state => {
      return state.createTemplate
    },
    TEMPLATE_DATA_TEMPLATE_CREATED_CHECK: state => {
      return state.createTemplate.templateCreated
    },
    TEMPLATE_DATA_TEMPLATE_UPDATED: state => {
      return state.updateTemplate
    },
    TEMPLATE_DATA_TEMPLATE_UPDATED_CHECK: state => {
      return state.updateTemplate.templateUpdated
    },
    TEMPLATE_MAINTENANCE_SEGMENT_ADDED: state => {
      return state.templateData.segmentAdded
    },
    TEMPLATE_MAINTENANCE_SEGMENT_ADDED_ID: state => {
      return state.templateData.segmentAddedId
    },
    TEMPLATE_MAINTENANCE_CATEGORY_ADDED_ID: state => {
      return state.templateData.categoryAddedId
    },
    TEMPLATE_MAINTENANCE_CATEGORY_DELETED: state => {
      return state.templateData.categoryDeleted
    },
    LABELS: state => {
      return state.labels
    }
  },
  actions: {
    GET_TEMPLATES_BY_SERVICE_ID({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getTemplatesByServiceId,
        '{serviceId}',
        payload
      )

      Services.axiosService.get(endpoint, res => {
        commit('SET_TEMPLATES_BY_SERVICE_ID', res)
        commit('TEMPLATE_MAINTENANCE_TEMPLATES_LOADED', res)
      })
    },
    GET_TEMPLATE_DATA({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getTemplateData,
        '{templateId}',
        payload
      )

      Services.axiosService.get(endpoint, res => {
        commit('SET_TEMPLATE_DATA_AVAILABLE_COUNTRIES', res)
        commit('SET_TEMPLATE_DATA', res)
        commit('TEMPLATE_MAINTENANCE_CATEGORIES_LOADED', res)
      })
    },
    GET_TEMPLATE_MAINTENANCE_INITIAL_DATA({ commit }) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getTemplateMaintenanceInitialData
      )

      Services.axiosService.get(endpoint, res => {
        commit('SET_TEMPLATE_MAINTENANCE_INITIAL_DATA', res)
      })
    },
    GET_TEMPLATE_MAINTENANCE_REFERENCE_DATA({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getTemplateMaintenanceReferenceData,
        '{serviceId}',
        payload
      )

      Services.axiosService.get(endpoint, res => {
        commit('SET_TEMPLATE_MAINTENANCE_REFERENCE_DATA', res)
      })
    },
    DELETE_TEMPLATE({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getTemplateMaintenanceDeleteTemplate,
        '{templateId}',
        payload
      )

      const targetSuccessKey = Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Messages.DeleteTemplateSuccess'
      )

      const targetErrorKey = Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Messages.DeleteTemplateDeclined'
      )

      Services.axiosService.delete(endpoint, res => {
        if (res.ActionResult) {
          commit('UPDATE_DELETE_TEMPLATE', res)
          toastr.success(targetSuccessKey)
        } else {
          toastr.error(targetErrorKey)
        }
      })
    },
    SET_PHASE_FILTER({ commit }, payload) {
      commit('UPDATE_PHASE_FILTER', payload)
    },
    SET_LANGUAGE_FILTER({ commit }, payload) {
      commit('UPDATE_LANGUAGE_FILTER', payload)
    },
    SET_TITLE({ commit }, payload) {
      commit('UPDATE_TITLE', payload)
    },
    SET_COUNTRIES({ commit }, payload) {
      commit('UPDATE_COUNTRIES', payload)
    },
    SET_DESCRIPTION({ commit }, payload) {
      commit('UPDATE_DESCRIPTION', payload)
    },
    RESET_TEMPLATE_DATA({ commit }, payload) {
      commit('RESET_TEMPLATE_DATA', payload)
    },
    RESET_TEMPLATE_MAINTENANCE_TEMPLATES_LOADED({ commit }, payload) {
      commit('UPDATE_TEMPLATE_MAINTENANCE_TEMPLATES_LOADED', payload)
    },
    RESET_TEMPLATE_MAINTENANCE_CATEGORY_DELETED({ commit }, payload) {
      commit('UPDATE_TEMPLATE_MAINTENANCE_CATEGORY_DELETED', payload)
    },
    RESET_TEMPLATE_MAINTENANCE_TEMPLATE_CREATED({ commit }, payload) {
      commit('UPDATE_TEMPLATE_MAINTENANCE_TEMPLATE_CREATED', payload)
    },
    RESET_TEMPLATE_MAINTENANCE_TEMPLATE_UPDATED({ commit }, payload) {
      commit('UPDATE_TEMPLATE_MAINTENANCE_TEMPLATE_UPDATED', payload)
    },
    RESET_TEMPLATE_DATA_TEMPLATE_DELETED({ commit }, payload) {
      commit('UPDATE_TEMPLATE_DATA_TEMPLATE_DELETED', payload)
    },
    GET_CATEGORIES({ commit }, payload) {
      commit('UPDATE_CATEGORIES', payload)
      commit('SET_CATEGORY_ADDED_ID', payload)
    },
    GET_SUBCATEGORIES({ commit }, payload) {
      commit('UPDATE_SUBCATEGORIES', payload)
    },
    GET_SEGMENTS({ commit }, payload) {
      commit('UPDATE_SEGMENTS', payload)
    },
    SET_CANCELLED({ commit }, payload) {
      commit('UPDATE_CANCELLED', payload)
    },
    EDIT_CATEGORIES({ commit }, payload) {
      commit('EDIT_CATEGORIES', payload)
    },
    EDIT_SUBCATEGORIES({ commit }, payload) {
      commit('EDIT_SUBCATEGORIES', payload)
    },
    EDIT_SEGMENT({ commit }, payload) {
      commit('EDIT_SEGMENT', payload)
    },
    DELETE_CATEGORY({ commit }, payload) {
      commit('DELETE_CATEGORY', payload)
    },
    DELETE_SUBCATEGORY({ commit }, payload) {
      commit('DELETE_SUBCATEGORY', payload)
    },
    DELETE_SEGMENT({ commit }, payload) {
      commit('DELETE_SEGMENT', payload)
    },
    SET_ORDERED_SEGMENTS({ commit }, payload) {
      commit('UPDATE_ORDERED_SEGMENTS', payload)
    },
    SET_ORDERED_SUBCATEGORIES({ commit }, payload) {
      commit('UPDATE_ORDERED_SUBCATEGORIES', payload)
    },
    POST_TEMPLATE_MAINTENANCE_CREATE({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.postTemplateMaintenanceCreateTemplate
      )

      const body = {
        Id: payload.templateDataInfo.id,
        ServiceId: payload.serviceId,
        PhaseId: payload.templateDataInfo.phaseId,
        PhaseCode: payload.phaseCode,
        LanguageCode: payload.templateDataInfo.languageCode,
        Title: payload.templateDataInfo.title,
        Description: payload.templateDataInfo.description,
        Categories: payload.templateDataInfo.categories,
        Countries: payload.templateDataInfo.countries
      }

      const targetSuccessKey = Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Messages.CreateTemplateSuccess'
      )

      const targetErrorKey = Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Messages.CreateTemplateDeclined'
      )

      Services.axiosService.post(endpoint, body, res => {
        if (res.ActionResult) {
          commit('UPDATE_TEMPLATE_CREATED', res.SupplyRequestTemplate)
          toastr.success(targetSuccessKey)
        } else {
          toastr.error(targetErrorKey)
        }
      })
    },
    POST_TEMPLATE_MAINTENANCE_UPDATE({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.postTemplateMaintenanceUpdateTemplate
      )

      const body = {
        Id: payload.templateDataInfo.id,
        ServiceId: payload.serviceId,
        PhaseId: payload.templateDataInfo.phaseId,
        PhaseCode: payload.phaseCode,
        LanguageCode: payload.templateDataInfo.languageCode,
        Title: payload.templateDataInfo.title,
        Countries: payload.templateDataInfo.countries,
        Description: payload.templateDataInfo.description,
        Categories: payload.templateDataInfo.categories
      }

      const targetSuccessKey = Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Messages.UpdateTemplateSuccess'
      )

      const targetErrorKey = Services.dictionaryService.translate(
        'SupplyRequestTemplateMaintenance.Messages.UpdateTemplateDeclined'
      )

      Services.axiosService.post(endpoint, body, res => {
        if (res.ActionResult) {
          commit('UPDATE_TEMPLATE_UPDATED', res.SupplyRequestTemplate)
          toastr.success(targetSuccessKey)
        } else {
          toastr.error(targetErrorKey)
        }
      })
    }
  },
  mutations: {
    SET_TEMPLATES_BY_SERVICE_ID: (state, payload) => {
      state.templateMaintenance.templates = Services.supplyRequest2TemplateMaintenanceService.processTemplates(
        payload
      )
    },
    SET_TEMPLATE_DATA_AVAILABLE_COUNTRIES: (state, payload) => {
      const countries = [...state.templateMaintenanceInitialData.countries]
      if (payload.Countries) {
        payload.Countries.forEach(abbr => {
          if (!countries.find(item => item.value === abbr)) {
            countries.push({
              value: abbr,
              name: dictionaryService.translate(`Labels.Country.${abbr}`), //abbr = i.e. NLD, BEL, DEU etc.
              disabled: true
            })
          }
          state.templateData.availableCountries = countries
        })
      }
    },
    SET_TEMPLATE_DATA: (state, payload) => {
      state.templateData.id = payload.Id
      state.templateData.title = payload.Title
      state.templateData.description = payload.Description
      state.templateData.languageCode = payload.LanguageCode
      state.templateData.phaseId = payload.PhaseId
      state.templateData.templateLoaded = true
      state.templateData.countries = payload.Countries || []
      state.templateData.categories = Services.supplyRequest2TemplateMaintenanceService.processCategories(
        payload
      )
    },
    RESET_TEMPLATE_DATA: state => {
      state.templateData.title = ''
      state.templateData.description = ''
      state.templateData.languageCode = ''
      state.templateData.phaseId = ''
      state.templateData.categories = []
      state.templateData.countries = []
    },
    SET_TEMPLATE_MAINTENANCE_INITIAL_DATA: (state, payload) => {
      Services.supplyRequest2TemplateMaintenanceService.processTemplateMaintenanceInitialData(
        state.templateMaintenanceInitialData,
        payload
      )
      // set list of available countries
      state.templateData.availableCountries =
        state.templateMaintenanceInitialData.countries
    },
    SET_TEMPLATE_MAINTENANCE_REFERENCE_DATA: (state, payload) => {
      state.templateMaintenanceReferenceData.phases = Services.supplyRequest2TemplateMaintenanceService.processTemplateMaintenancePhases(
        payload
      )
      state.templateMaintenanceReferenceData.languages = Services.supplyRequest2TemplateMaintenanceService.processTemplateMaintenanceLanguages(
        payload
      )
    },
    UPDATE_DELETE_TEMPLATE(state) {
      state.templateData.templateDeleted = true
    },
    UPDATE_TEMPLATE_CREATED(state, payload) {
      state.createTemplate.templateCreated = true
      state.createTemplate.result = payload
    },
    UPDATE_TEMPLATE_UPDATED(state, payload) {
      state.updateTemplate.templateUpdated = true
      state.updateTemplate.result = payload
    },
    TEMPLATE_MAINTENANCE_TEMPLATES_LOADED: state => {
      state.templateMaintenance.templatesLoaded = true
    },
    TEMPLATE_MAINTENANCE_CATEGORIES_LOADED: state => {
      state.templateData.categoriesLoaded = true
    },
    UPDATE_PHASE_FILTER: (state, payload) => {
      state.templateData.phaseId = payload.phaseId
    },
    UPDATE_LANGUAGE_FILTER: (state, payload) => {
      state.templateData.languageCode = payload.languageCode
    },
    UPDATE_TITLE: (state, payload) => {
      state.templateData.title = payload.title
    },
    UPDATE_COUNTRIES: (state, payload) => {
      state.templateData.countries = payload.countries
    },
    UPDATE_DESCRIPTION: (state, payload) => {
      state.templateData.description = payload.description
    },
    UPDATE_CATEGORIES: (state, payload) => {
      state.templateData.categories.push(payload)

      return
    },
    UPDATE_SUBCATEGORIES: (state, payload) => {
      let categoryIndex = 0

      state.templateData.categories.forEach((value, index) => {
        if (value.id === payload.category.id) {
          categoryIndex = index
        }

        return index
      })

      state.templateData.categories[categoryIndex].groupSegments.push(
        payload.subCategoryData
      )

      return
    },
    UPDATE_SEGMENTS: (state, payload) => {
      let categoryIndex = 0

      state.templateData.categories.forEach((value, index) => {
        if (value.id === payload.category.id) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.templateData.categories[
        categoryIndex
      ].groupSegments.findIndex(subCategory => subCategory.id === payload.subCategory.id)

      state.templateData.categories[categoryIndex].groupSegments[
        subCategoryIndex
      ].segments.push(payload.segmentData)
      state.templateData.segmentAddedId = payload.segmentData.id

      return
    },
    UPDATE_CANCELLED: (state, payload) => {
      let categoryIndex = 0

      state.templateData.categories.forEach((value, index) => {
        if (value.id === payload.categoryId) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.templateData.categories[
        categoryIndex
      ].groupSegments.findIndex(subCategory => subCategory.id === payload.subCategoryId)
      const segmentIndex = state.templateData.categories[categoryIndex].groupSegments[
        subCategoryIndex
      ].segments.findIndex(segment => segment.id === payload.segmentId)

      if (
        state.templateData.categories[categoryIndex].groupSegments[subCategoryIndex]
          .segments[segmentIndex].title === '' &&
        state.templateData.categories[categoryIndex].groupSegments[subCategoryIndex]
          .segments[segmentIndex].description === '' &&
        state.templateData.categories[categoryIndex].groupSegments[subCategoryIndex]
          .segments[segmentIndex].segmentItems.length === 0
      ) {
        const index = state.templateData.categories[categoryIndex].groupSegments[
          subCategoryIndex
        ].segments.findIndex(segment => segment.id === payload.segmentId)
        state.templateData.categories[categoryIndex].groupSegments[
          subCategoryIndex
        ].segments.splice(index, 1)
      }

      return
    },
    EDIT_CATEGORIES: (state, payload) => {
      let categoryIndex = 0

      state.templateData.categories.forEach((value, index) => {
        if (value.id === payload.categoryId) {
          categoryIndex = index
        }

        return index
      })

      state.templateData.categories[categoryIndex].title = payload.categoryData.title
      state.templateData.categories[categoryIndex].shortTitle =
        payload.categoryData.shortTitle

      return
    },
    EDIT_SUBCATEGORIES: (state, payload) => {
      let categoryIndex = 0

      state.templateData.categories.forEach((value, index) => {
        if (value.id === payload.categoryId) {
          categoryIndex = index
        }

        return index
      })

      const index = state.templateData.categories[categoryIndex].groupSegments.findIndex(
        subCategory => subCategory.id === payload.subCategoryId
      )
      state.templateData.categories[categoryIndex].groupSegments[index].title =
        payload.subCategoryData.title

      return
    },
    EDIT_SEGMENT: (state, payload) => {
      let categoryIndex = 0

      state.templateData.categories.forEach((value, index) => {
        if (value.id === payload.categoryId) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.templateData.categories[
        categoryIndex
      ].groupSegments.findIndex(subCategory => subCategory.id === payload.subCategoryId)
      const segmentIndex = state.templateData.categories[categoryIndex].groupSegments[
        subCategoryIndex
      ].segments.findIndex(segment => segment.id === payload.segmentId)

      state.templateData.categories[categoryIndex].groupSegments[
        subCategoryIndex
      ].segments[segmentIndex].title = payload.clone.title
      state.templateData.categories[categoryIndex].groupSegments[
        subCategoryIndex
      ].segments[segmentIndex].description = payload.clone.description
      state.templateData.categories[categoryIndex].groupSegments[
        subCategoryIndex
      ].segments[segmentIndex].segmentItems = payload.clone.segmentItems

      return
    },
    DELETE_CATEGORY: (state, payload) => {
      const index = state.templateData.categories.findIndex(
        category => category.id === payload.categoryId
      )

      state.templateData.categories.splice(index, 1)
      state.templateData.categoryDeleted = true
    },
    DELETE_SUBCATEGORY: (state, payload) => {
      let categoryIndex = 0

      state.templateData.categories.forEach((value, index) => {
        if (value.id === payload.category.id) {
          categoryIndex = index
        }

        return index
      })

      const index = state.templateData.categories[categoryIndex].groupSegments.findIndex(
        subCategory => subCategory.id === payload.subCategory.id
      )
      state.templateData.categories[categoryIndex].groupSegments.splice(index, 1)
    },
    DELETE_SEGMENT: (state, payload) => {
      let categoryIndex = 0

      state.templateData.categories.forEach((value, index) => {
        if (value.id === payload.category.id) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.templateData.categories[
        categoryIndex
      ].groupSegments.findIndex(subCategory => subCategory.id === payload.subCategory.id)
      const segmentIndex = state.templateData.categories[categoryIndex].groupSegments[
        subCategoryIndex
      ].segments.findIndex(segment => segment.id === payload.segment.id)

      state.templateData.categories[categoryIndex].groupSegments[
        subCategoryIndex
      ].segments.splice(segmentIndex, 1)
    },
    UPDATE_TEMPLATE_MAINTENANCE_TEMPLATES_LOADED: state => {
      state.templateMaintenance.templatesLoaded = false
    },
    UPDATE_TEMPLATE_MAINTENANCE_CATEGORY_DELETED: state => {
      state.templateMaintenance.categoryDeleted = false
    },
    UPDATE_TEMPLATE_MAINTENANCE_TEMPLATE_CREATED: state => {
      state.createTemplate.templateCreated = false
    },
    UPDATE_TEMPLATE_MAINTENANCE_TEMPLATE_UPDATED: state => {
      state.updateTemplate.templateUpdated = false
    },
    UPDATE_TEMPLATE_DATA_TEMPLATE_DELETED: state => {
      state.templateData.templateDeleted = false
    },
    SET_CATEGORY_ADDED_ID: (state, payload) => {
      state.templateData.categoryAddedId = payload.id
    },
    UPDATE_ORDERED_SEGMENTS: (state, payload) => {
      let categoryIndex = 0

      state.templateData.categories.forEach((value, index) => {
        if (value.id === payload.category.id) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.templateData.categories[
        categoryIndex
      ].groupSegments.findIndex(subCategory => subCategory.id === payload.subCategory.id)

      state.templateData.categories[categoryIndex].groupSegments[
        subCategoryIndex
      ].segments = payload.segments
    },
    UPDATE_ORDERED_SUBCATEGORIES: (state, payload) => {
      let categoryIndex = 0

      state.templateData.categories.forEach((value, index) => {
        if (value.id === payload.category.id) {
          categoryIndex = index
        }

        return index
      })

      state.templateData.categories[categoryIndex].groupSegments = payload.subCategories
    }
  }
}
