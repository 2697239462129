<template>
  <div :class="['c-status-indicator', statusClass, 'qa-status-indicator']">
    <div class="c-status-indicator__dot float-left" />
  </div>
</template>

<script>
const _status_code_classes = {
  0: {
    style_classes: "c-status-indicator--grey",
  },
  1: {
    style_classes: "c-status-indicator--green",
  },
  2: {
    style_classes: "c-status-indicator--orange",
  },
  3: {
    style_classes: "c-status-indicator--red",
  },
};

export default {
  name: "maz-status-indicator",
  props: {
    status_code: {
      required: false,
      type: String,
      default: "4",
    },
  },
  computed: {
    statusClass() {
      let result = "";
      if (this.status_code !== null && this.status_code !== undefined) {
        result += _status_code_classes[this.status_code].style_classes;
      }

      return result;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styling/custom/settings/__main.scss";

.c-status-indicator {
  .c-status-indicator__dot {
    width: 28px;
    height: 28px;
    text-align: center;
    background-color: $gray;
    border-radius: 50%;
  }

  &.c-status-indicator--small {
    .c-status-indicator__dot {
      position: relative;
      display: inline-block;
      width: 13px;
      height: 13px;
      background-color: $gray;
      border-radius: 50%;
    }
  }

  &.c-status-indicator--grey {
    .c-status-indicator__dot {
      background-color: $gray;
    }
  }

  &.c-status-indicator--big {
    .c-status-indicator__dot {
      width: 28px;
      height: 28px;

      @media #{$media-query-from-desktop} {
        width: 38px;
        height: 38px;
      }
    }
  }

  &.c-status-indicator--green {
    .c-status-indicator__dot {
      background-color: $status-green;
    }
  }

  &.c-status-indicator--red {
    .c-status-indicator__dot {
      background-color: $status-red;
    }
  }

  &.c-status-indicator--orange {
    .c-status-indicator__dot {
      background-color: $status-yellow;
    }
  }

  &.c-status-indicator--centered {
    .c-status-indicator__dot {
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
