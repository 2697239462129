import helpersService from '@/services/modules/helpersService'
import momentService from '@/services/modules/momentService'
import supplyRequest2DelegatedSegmentService from '@/services/modules/supplyRequest2DelegatedSegmentService'

export const mutations = {
  SET_DELEGATION_INITIAL_DATA(state: any, payload: any) {
    state.delegationInitialData.title = payload.Title
    state.delegationInitialData.categoryId = payload.CategoryId
    state.delegationInitialData.categoryTitle = payload.CategoryTitle
    state.delegationInitialData.customerName = payload.CustomerName
    state.delegationInitialData.delegateReason = payload.DelegateReason
    state.delegationInitialData.delegationIsComplete = payload.DelegationIsComplete
    state.delegationInitialData.description = payload.Description
    const dueDateOffset = momentService(payload.DueDate).utcOffset()
    state.delegationInitialData.dueDate = momentService(payload.DueDate)
      .subtract(dueDateOffset, 'minutes')
      .format(helpersService.momentDateFormat)
    state.delegationInitialData.financialYear = payload.FinancialYear
    state.delegationInitialData.groupSegmentId = payload.GroupSegmentId
    state.delegationInitialData.groupSegmentTitle = payload.GroupSegmentTitle
    state.delegationInitialData.pageTitle = payload.PageTitle
    state.delegationInitialData.pageText = payload.PageText
    state.delegationInitialData.status = payload.Status
    state.delegationInitialData.supplyRequestId = payload.SupplyRequestId
    state.delegationInitialData.segment.description = payload.Segment.Description
    state.delegationInitialData.segment.id = payload.Segment.Id
    state.delegationInitialData.segment.segmentItems = supplyRequest2DelegatedSegmentService.updateSegmentItems(
      payload.Segment.SegmentItems
    )
    state.delegationInitialData.segment.attachments = supplyRequest2DelegatedSegmentService.updateAttachments(
      payload.Segment.Attachments
    )
    state.delegationInitialData.segment.title = payload.Segment.Title
    state.delegationInitialData.segment.isComplete = payload.Segment.IsComplete
    state.supplyRequestFileUploader.chunkSize = payload.ChunkSize
    state.supplyRequestFileUploader.maxFileSize = payload.MaxFileSize
    state.supplyRequestFileUploader.fileUploadExtensionsCsv =
      payload.FileUploadExtensionsCsv
  },

  UPDATE_SUPPLY_REQUEST_SEGMENT_FILES(state: any, payload: any) {
    state.supplyRequestFileUploader.files = supplyRequest2DelegatedSegmentService.updateFiles(
      payload
    )
  },

  UPDATE_DELETED_FILE(state: any) {
    state.supplyRequestFileUploader.fileDeleted = true
  },

  RESET_DELETED_FILE(state: any) {
    state.supplyRequestFileUploader.fileDeleted = false
  }
}
