<template>
  <button :class="[{ 'is-disabled': disabled }]" :disabled="disabled" class="c-btn">
    <slot>
      {{ label }}
    </slot>
  </button>
</template>

<script>
export default {
  name: 'maz-button',
  props: {
    label: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    disabled: {
      required: false,
      type: Boolean,
      default() {
        return false
      }
    }
  },
}
</script>
