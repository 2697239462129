export default {
  getEditSupplyRequestInitialData:
    'geteditsupplyrequestinitialdata?srid={supplyRequestId}',
  getSupplyRequestCategories: 'getcategories?srid={supplyRequestId}',
  getSupplyRequestCategoryById:
    'getcategorycontent?srid={supplyRequestId}&ctid={categoryId}',
  updateSupplyRequest: 'updatesupplyrequest',
  getCustomers: 'getcustomers?searchterm={searchTerm}',
  postCopySupplyRequest: 'copysupplyrequest?srid={supplyRequestId}&crmid={customerId}',
  postCloneSupplyRequest: 'clonesupplyrequest?srid={supplyRequestId}',
  addCategory: 'addcategory',
  deleteCategory: 'deletecategory',
  updateCategory: 'updatecategory',
  addSubCategory: 'addgroupsegment',
  deleteSubCategory: 'deletegroupsegment',
  updateSubCategory: 'updategroupsegment',
  addSegment: 'addsegment',
  updateSegment: 'updatesegment',
  deleteSegment: 'deletesegment',
  activateSupplyRequest: 'activatesupplyrequest',
  postUploadAttachment: 'uploadattachment',
  getAttachmentOverviewBySegmentId:
    'getattachmentoverviewbysegmentid?supplyrequestid={supplyRequestId}&categoryid={categoryId}&groupsegmentid={groupSegmentId}&segmentid={segmentId}',
  deleteAttachment: 'deleteattachment?srfid={supplyRequestFileId}',
  moveSegment: 'movesegment',
  moveGroupSegment: 'movegroupsegment',
  deleteSupplyRequest: 'deletesupplyrequest?srid={supplyRequestId}'
}
