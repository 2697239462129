import $ from 'jquery'

const config = {
  apiUrl: null,
  endpointBase: '/json/',
  endpointBaseFile: '/srfile/',
  endpointBaseCsv: '/csv/',
  portalType: window.__INITIAL_DATA__.portalType //Either 'Employee' or 'Client' depending on the portal
}

$(() => {
  config.apiUrl = $('.js-api').data('api-route')
})

export default config
