<template>
  <div
    class="c-multi-dropdown"
    :data-cy="dataCy"
    :class="{ 'is-open': show }"
    v-click-outside="onClickOutside"
  >
    <span class="c-multi-dropdown__placeholder" @click="toggleDropdown">{{
      placeholder
    }}</span>
    <ul class="c-multi-dropdown__options">
      <li @click.stop class="c-multi-dropdown__filter" v-if="showFilterInputField">
        <input
          ref="filter"
          @input="onFilterInput($event.target.value)"
          @focus="$event.target.select()"
          class="c-multi-dropdown__filter-input"
          type="text"
          :placeholder="filterPlaceholderLabel"
          v-model="filterValue"
        />
      </li>

      <li
        class="c-option"
        :class="{ selected: hasAllOptionsSelected }"
        @click="toggleSelectAllOptions"
        v-show="showSelectAllToggle"
      >
        {{
          hasAllOptionsSelected ? deselectAllPlaceholderLabel : selectAllPlaceholderLabel
        }}
        <svg
          width="20"
          height="15"
          viewBox="0 0 20 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.1706 2.25656C19.3998 2.48572 19.5144 2.764 19.5144 3.09138C19.5144 3.41876 19.3998 3.69703 19.1706 3.9262L10.2822 12.8146L8.61258 14.4842C8.38342 14.7134 8.10514 14.828 7.77776 14.828C7.45038 14.828 7.17211 14.7134 6.94294 14.4842L5.2733 12.8146L0.829102 8.3704C0.599935 8.14123 0.485352 7.86296 0.485352 7.53558C0.485352 7.20819 0.599935 6.92992 0.829102 6.70075L2.49874 5.03111C2.72791 4.80194 3.00618 4.68736 3.33357 4.68736C3.66095 4.68736 3.93922 4.80194 4.16839 5.03111L7.77776 8.65276L15.8313 0.586914C16.0605 0.357747 16.3388 0.243164 16.6662 0.243164C16.9935 0.243164 17.2718 0.357747 17.501 0.586914L19.1706 2.25656Z"
            fill="currentColor"
          />
        </svg>
      </li>

      <li
        class="c-option"
        v-for="(option, index) in filteredOptions"
        :key="'option' + index"
        v-on:click="onOptionSelect(option)"
        v-bind:class="{
          'is-disabled': disabledField !== '' && option[disabledField],
          selected: value.indexOf(option[valueField]) !== -1
        }"
      >
        {{ option[labelField] }}
        <svg
          width="20"
          height="15"
          viewBox="0 0 20 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.1706 2.25656C19.3998 2.48572 19.5144 2.764 19.5144 3.09138C19.5144 3.41876 19.3998 3.69703 19.1706 3.9262L10.2822 12.8146L8.61258 14.4842C8.38342 14.7134 8.10514 14.828 7.77776 14.828C7.45038 14.828 7.17211 14.7134 6.94294 14.4842L5.2733 12.8146L0.829102 8.3704C0.599935 8.14123 0.485352 7.86296 0.485352 7.53558C0.485352 7.20819 0.599935 6.92992 0.829102 6.70075L2.49874 5.03111C2.72791 4.80194 3.00618 4.68736 3.33357 4.68736C3.66095 4.68736 3.93922 4.80194 4.16839 5.03111L7.77776 8.65276L15.8313 0.586914C16.0605 0.357747 16.3388 0.243164 16.6662 0.243164C16.9935 0.243164 17.2718 0.357747 17.501 0.586914L19.1706 2.25656Z"
            fill="currentColor"
          />
        </svg>
      </li>
    </ul>
  </div>
</template>

<script>
import helpersService from '@/services/modules/helpersService'
import dictionaryService from '@/services/modules/dictionaryService'
import vClickOutside from 'v-click-outside'

export default {
  name: "mazMultiDropdown",
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    dataCy: {
      type: String,
      required: true
    },
    value: {
      required: true //v-model binding.
    },
    valueField: {
      type: String,
      default: 'value'
    },
    labelField: {
      type: String,
      default: 'label'
    },
    disabledField: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    filterPlaceholder: {
      type: String,
      default: ''
    },
    selectAllPlaceholder: {
      type: String,
      default: ''
    },
    deselectAllPlaceholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false,
      filterValue: '',
      hasAllOptionsSelected: false
    }
  },
  methods: {
    toggleDropdown() {
      if (this.show) {
        this.close()
      } else {
        this.open()
      }
    },
    onOptionSelect(item) {
      const valueClone = helpersService.cloneObject(this.value); //clone the current array
      let itemIndex = -1;
      valueClone.some((valueItem, index) => { //checks if current values include the affected item
        if (valueItem === item[this.valueField]) {
          itemIndex = index;
          return true;
        }
      });

      if (itemIndex === -1) { //adds the affected item when not included
        valueClone.push(item[this.valueField]);
      }
      else { //removes the affected item when not included 
        valueClone.splice(itemIndex, 1);
      }

      this.$emit('input', valueClone); //v-model binding version
      this.$emit('update', valueClone);//manual binding version
    },
    toggleSelectAllOptions() {
      if (this.hasAllOptionsSelected) {
        this.hasAllOptionsSelected = false
        // exclude the readonly fields from removal
        const keep = []
        this.options.forEach(element => {
          // we keep the elements that are disabled and currently selected.
          if (element[this.disabledField] === true && this.value.includes(element[this.valueField])) {
            keep.push(element[this.valueField])
          }
        })
        this.$emit('input', keep); //v-model binding version
        this.$emit('update', keep);//manual binding version
        this.$emit('none')
      } else {
        this.hasAllOptionsSelected = true
        const selected = []
        this.options.forEach(element => {
          // wee will only select the elements that aren't disabled or are already selected
          if (!element[this.disabledField] || this.value.includes(element[this.valueField]))
            selected.push(element[this.valueField])
        });
        this.$emit('input', selected); //v-model binding version
        this.$emit('update', selected);//manual binding version
        this.$emit('all')
      }
    },
    close() {
      this.filterValue = ''
      this.show = false
    },
    open() {
      this.show = true
      if (this.showFilterInputField) {
        setTimeout(() => {
          this.$refs.filter.focus()
        }, 50)
      }
    },
    onClickOutside() {
      this.close()
    },
    onFilterInput(filterValue) {
      this.$emit('filtering', filterValue)
      this.filterValue = filterValue
    }
  },

  computed: {
    showFilterInputField() {
      return this.options && this.options.length > 5
    },
    filterPlaceholderLabel() {
      return this.filterPlaceholder || dictionaryService.translate('Labels.Common.Search') + '...'
    },
    filteredOptions() {
      return this.options.filter(obj => String(obj[this.labelField]).toLowerCase().includes(String(this.filterValue).toLowerCase()))
    },
    selectAllPlaceholderLabel() {
      return this.selectAllPlaceholder || dictionaryService.translate('Labels.Common.SelectAll')
    },
    deselectAllPlaceholderLabel() {
      return this.deselectAllPlaceholder || dictionaryService.translate('Labels.Common.DeselectAll')
    },
    showSelectAllToggle() {
      return this.showFilterInputField && !this.filterValue //same logic as filter && filter must not be visible.
    }
  }
}
</script>
<style lang="scss">
@import '@/styling/custom/settings/__main.scss';
.c-multi-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 14px 12px;
  font-size: 14px;
  font-family: 'Halyard-Book', sans-serif;
  background-color: $white;
  border: 1px solid $mid-gray;
  cursor: pointer;
  transition: color 0.4s, background-color 0.4s;
  transition: border 0.2s, box-shadow 0.2s;
  user-select: none;

  &::placeholder {
    color: $dark-gray;
  }

  &:hover,
  &:focus {
    border-color: $dark-gray;
  }

  &:focus {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  }

  &.is-open {
    border-color: $dark-gray;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    .c-multi-dropdown__placeholder {
      &:before {
        transform: rotate(180deg);
      }
    }

    .c-multi-dropdown__options {
      display: block;
      margin: 0;
    }
    .c-option {
      margin: 0;
      padding: 0 1em;

      &:hover {
        color: $blue;
        background-color: $light-steel-blue;
      }
    }
  }

  &.is-empty-menu {
    color: $bg-blue;
    background-color: $white;
    cursor: not-allowed;
    opacity: 0.4;
    pointer-events: none;
  }

  .c-multi-dropdown__placeholder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 14px 0;
    color: $dark-gray;
    font-size: 14px;
    font-family: 'Halyard-Book', sans-serif;
    background-color: $white;
    &:before {
      position: absolute;
      top: 0;
      right: 14px;
      bottom: 0;
      display: flex;
      align-items: center;
      width: 24px;
      height: 100%;
      color: $dark-gray;
      font-size: 18px;
      background-image: url('~@/assets/images/icon_arrow_down_select.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 19px;
      cursor: pointer;
      content: '';
    }
  }

  .c-multi-dropdown__options {
    position: absolute;
    top: 44px;
    right: 0;
    left: -1px;
    z-index: 10;
    display: none;
    width: calc(100% + 2px);
    max-height: 300px;
    // margin: 0 -1px;
    padding: 0;
    overflow: auto;
    list-style: none;
    background-color: $white;
    border: 1px solid;
    border-color: transparent $dark-gray $dark-gray $dark-gray;
    border-radius: 0 0 4px 4px;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: $white-smoke;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 2em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $blue;
      border-radius: 2em;
    }

    svg {
      display: none;
    }

    li {
      height: 40px;
      margin: 0; //margin-reset.
      padding: 0 12px;
      overflow: hidden;
      color: $dark-gray;
      font-size: 14px;
      font-family: 'Halyard-Book', sans-serif;
      line-height: 40px;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        color: $blue;
        background-color: $light-steel-blue;

        & svg {
          color: $blue !important;
        }
      }

      &.selected {
        position: relative;

        & svg {
          position: absolute;
          top: 0;
          right: 10px;
          bottom: 0;
          display: block;
          width: auto;
          height: 10px;
          margin: auto;
          color: $status-green;
        }
      }

      &.is-disabled {
        color: #8f9fb7;
        cursor: not-allowed;
        opacity: 0.4;
        pointer-events: none;
      }
      &.c-multi-dropdown__filter {
        position: relative;
        padding: 0;
        &::before {
          position: absolute;
          top: 0;
          right: 14px;
          display: flex;
          align-items: center;
          width: 24px;
          height: 100%;
          color: $dark-gray;
          background-image: url('~@/assets/images/icon_input_filter.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 19px;
          content: '';
          pointer-events: none;
        }
        input {
          width: 100%;
          height: 100%;
          padding: 14px;
          color: $dark-gray;
          font-size: 14px;
          font-family: 'Halyard-Book', sans-serif;
          border: none;
          outline: none;
          outline-style: none;
          outline-offset: 0;
          &::placeholder {
            padding-left: 2px;
          }
        }
      }
    }
  }
}
</style>