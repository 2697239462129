import HelpersService from './helpersService'
import MomentService from './momentService'
import ProcessUserTypeService from './processUserTypeService'

function cleanPropertyValue(propertyValue, expressionsToBeRemoved) {
  let result = null
  const emptyStr = ''

  if (HelpersService.isNotNullOrUndefined(propertyValue)) {
    result = propertyValue

    if (HelpersService.isNotNullOrUndefined(expressionsToBeRemoved)) {
      expressionsToBeRemoved.forEach(expression => {
        result = result.replace(expression, emptyStr)
      })
    }
  }

  return result
}

function processId(value) {
  const result = {
    original: value,
    clean: cleanPropertyValue(value, ['{', '}'])
  }

  return result
}

function processIsComplete(value) {
  let result = 9999

  if (value) {
    result = 1010
  }

  return result.toString()
}

function generateCategoriesObjects(parentSupplyRequestId, data) {
  const result = []

  data.Categories.forEach(category => {
    result.push({
      parentSupplyRequestId: processId(parentSupplyRequestId),
      parentCategoryId: processId(category.ParentCategoryId),
      navigationId: 'navigationId' + cleanPropertyValue(category.Id, ['{', '}']), //only for navigation and nothing else
      id: processId(category.Id),
      title: category.Title,
      shortTitle: category.ShortTitle,
      isComplete: category.IsComplete,
      isCompleteCode: processIsComplete(category.IsComplete),
      order: category.Order,
      subCategories: []
    })
  })

  return result
}

function generateFinancialYearsObjects(data) {
  const result = []

  if (data.FinancialYears) {
    data.FinancialYears.forEach(year => {
      result.push({
        value: year
      })
    })

    return result
  } else {
    return 0
  }
}

function processSegmentIsComplete(value) {
  let result = 9999

  if (value) {
    result = 1020
  }

  return result.toString()
}

function generateSegmentListObjects(data) {
  const result = []

  data.forEach(listItem => {
    let resultId = cleanPropertyValue(listItem.Id, ['{', '}'])

    result.push({
      id: resultId,
      title: listItem.Title
    })

    resultId = null
  })

  return result
}

function generateAttachmentsObjects(data) {
  const result = []

  if (data === null || data === undefined) {
    return []
  } else {
    data.forEach(attachment => {
      result.push({
        id: attachment.Id,
        fileName: attachment.FileName,
        mimeType: attachment.MimeType,
        ownerEmail: attachment.OwnerEmail,
        createdDate: MomentService(attachment.CreatedDate)
          .format(HelpersService.momentDateAndTimeFormat)
          .toString(),
        mayDownload: attachment.MayDownload,
        mayDelete: attachment.MayDelete,
        downloadUrl: attachment.DownloadUrl
      })
    })
  }

  return result
}

function isMazarsComment(userType) {
  let result = false
  const user = ProcessUserTypeService[userType]

  if (HelpersService.isNotNullOrUndefined(user)) {
    if (user.userType === 'Employee') {
      result = true
    }
  }

  return result
}

function generateSegmentCommentsObjects(data) {
  const result = []

  data.forEach(comment => {
    let resultId = cleanPropertyValue(comment.Id, ['{', '}'])

    result.push({
      id: resultId,
      title: comment.Actor.Email,
      acceptedDate: comment.AcceptedDate,
      isAccepted: comment.IsAccepted,
      postTime: MomentService(comment.LastModifiedDate)
        .format(HelpersService.momentDateAndTimeFormat)
        .toString(),
      description: comment.UserComment,
      mazarsComment: isMazarsComment(comment.Actor.UserType),
      user: {
        id: comment.Actor.UserId,
        fullName: comment.Actor.Fullname,
        email: comment.Actor.Email,
        type: ProcessUserTypeService[comment.Actor.UserType]
          ? ProcessUserTypeService[comment.Actor.UserType].userType
          : null
      }
    })

    resultId = null
  })

  return result
}

function generateSegmentObjects(
  parentSupplyRequestId,
  parentCategoryId,
  parentSubCategoryId,
  data
) {
  const result = []

  data.forEach(segment => {
    result.push({
      parentSupplyRequestId: parentSupplyRequestId,
      parentCategoryId: parentCategoryId,
      parentSubCategoryId: parentSubCategoryId,
      id: processId(segment.Id),
      priority: segment.Priority,
      state: {
        code: null
      },
      title: segment.Title,
      helpText: segment.HelpText,
      description: segment.Description,
      mayAddComment: segment.MayAddComment,
      mayMarkAsNotApplicableOrCompleted: segment.MayMarkAsNotApplicableOrCompleted,
      mayAddFiles: segment.MayAddFiles,
      mayDelegate: segment.MayDelegate,
      isComplete: segment.IsComplete,
      isCompleteCode: processSegmentIsComplete(segment.IsComplete),
      segmentItems: generateSegmentListObjects(segment.SegmentItems),
      delegations: null,
      numberOfFiles: segment.NumberOfFiles,
      showInitialFilesNumber: true,
      attachments: generateAttachmentsObjects(segment.Attachments),
      comments: generateSegmentCommentsObjects(segment.AllComments)
    })
  })

  return result
}

function generateSubCategoriesObjects(data) {
  const result = []
  const dataSet = data.GroupSegments

  dataSet.forEach(subCategory => {
    result.push({
      parentSupplyRequestId: processId(data.SupplyRequestId),
      parentCategoryId: processId(data.CategoryId),
      id: processId(subCategory.Id),
      title: subCategory.Title,
      isComplete: subCategory.IsComplete,
      isCompleteCode: processIsComplete(subCategory.IsComplete),
      order: subCategory.Order,
      segments: generateSegmentObjects(
        processId(data.SupplyRequestId),
        processId(data.CategoryId),
        processId(subCategory.Id),
        subCategory.Segments
      )
    })
  })

  return result
}

function findCategory(categories, targetCategoryId, callback) {
  categories.forEach(category => {
    if (category.id.original === targetCategoryId) {
      callback(category)
    }
  })
}

function findSegment(categories, targetSegment, callback) {
  categories.forEach(category => {
    if (category.id.original === targetSegment.CategoryId) {
      category.subCategories.forEach(subCategory => {
        if (subCategory.id.original === targetSegment.GroupSegmentId) {
          subCategory.segments.forEach(segment => {
            if (segment.id.original === targetSegment.SegmentId) {
              callback(segment)
            }
          })
        }
      })
    }
  })
}

function generateCustomersObjects(data) {
  const result = []

  data.forEach(customer => {
    result.push({
      id: customer.Id,
      employeeId: customer.EmployeeId,
      customerNumber: customer.CustomerNumber,
      name: customer.Name,
      selected: customer.Selected,
      type: customer.Type
    })
  })

  return result
}

const Service = {
  processCategories(srid, payload) {
    return generateCategoriesObjects(srid, payload)
  },

  processFinancialYears(payload) {
    return generateFinancialYearsObjects(payload)
  },

  updateCategory(categories, payload) {
    const result = categories

    findCategory(categories, payload.CategoryId, category => {
      category.isComplete = payload.IsComplete
      category.subCategories = generateSubCategoriesObjects(payload)
      category.isLoaded = true
    })

    return result
  },

  updateSegmentAttachments(categories, payload) {
    const result = categories

    findSegment(categories, payload, segment => {
      segment.attachments = generateAttachmentsObjects(payload.Attachments)
    })

    return result
  },

  processCustomers(customers, payload) {
    let result = customers

    result = generateCustomersObjects(payload)

    return result
  }
}

export default Service
