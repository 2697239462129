import config from '@/api/modules/config'
import employeeMaintenanceApi from '@/api/modules/employeeMaintenanceApi'
import axiosService from '@/services/modules/axiosService'
import helpersService from '@/services/modules/helpersService'
import toastr from 'toastr'
import { currentActionContext } from './index'

const _targetApi = employeeMaintenanceApi
const _portController = 'EmployeeEmployeeJson'
const _baseEndpoint = config.endpointBase + _portController + '/'

export const actions = {
  SETUP_MODAL(ctx: any): void {
    const { commit, state } = currentActionContext(ctx)
    commit.RESET_SHOULD_REFRESH_ON_CLOSE_MUTATION()
    commit.RESET_VALIDATION_ERRORS_MUTATION()
    commit.RESET_EMPLOYEE_URL_MUTATION()
    if (!state.genders.length && !state.languages.length) {
      const endpoint = helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getEmployeeReferenceData
      )
      axiosService.get(endpoint, (res: any) => {
        if (res) {
          const body = helpersService.convertObjectKeysToCamelCase(res)
          commit.SET_GENDERS_MUTATION(body.genders)
          commit.SET_LANGUAGES_MUTATION(body.languages)
        } else {
          toastr.error(state.messages.somethingWentWrong)
        }
      })
    }
  },
  RESET_VALIDATION_ERROR(ctx: any, payload: string) {
    function capitalizeFirstLetter(string: string): string {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
    const { commit } = currentActionContext(ctx)
    commit.RESET_VALIDATION_ERROR_MUTATION(capitalizeFirstLetter(payload))
  },
  SAVE_EMPLOYEE(
    ctx: any,
    payload: MazInterfaces.EmployeeMaintenanceAddEmployee.CreateEmployeeDetails
  ): void {
    const { commit, state } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.addEmployee
    )
    const body = helpersService.convertObjectKeysToPascalCase(payload)
    axiosService.post(endpoint, body, (res: any) => {
      if (res.HasSucceeded) {
        commit.SET_EMPLOYEE_URL_MUTATION(res.Url)
        commit.SET_SHOULD_REFRESH_ON_CLOSE_MUTATION()
      } else {
        if (res.Errors?.length) {
          commit.SET_VALIDATION_ERRORS_MUTATION(res.Errors)
        }
        toastr.error(state.messages.somethingWentWrong)
      }
    })
  }
}
