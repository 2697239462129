<template>
  <div>
    <div>
      <label>{{ title }}</label>
      <maz-text-input type="text" v-model="userSearch" :placeholder="searchPlaceholder" />
      <div class="c-listusers overflow-y-auto h-30-vh scrollbars">
        <div
          v-for="(user, index) in filteredUsersList"
          :key="'usr-' + index"
          class="c-listusers__items"
          v-on:click="addUser(user)"
        >
          {{ user.Name }}
          <a href="#" class="c-listusers__items__item">
            <fa icon="plus" />
          </a>
        </div>
      </div>
    </div>
    <div class="c-listusers--selected-wrapper" v-if="selectedList.length">
      <h6>{{ selectedTitle }}</h6>
      <div
        class="c-listusers c-listusers--selected overflow-y-auto maxh-30-vh scrollbars"
      >
        <div
          class="c-listusers__items"
          v-for="(user, idx) in selectedList"
          :key="'selected-' + idx"
        >
          {{ user.Name }}
          <a href="#" v-on:click="removeUser(user)">
            <fa icon="times" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mazTextInput from "@/components/general/mazTextInput/index.vue";
import dictionaryService from "@/services/modules/dictionaryService";

const _ = {
  filter: require("lodash/filter"),
};

export default {
  name: "mazUserSelection",

  components: {
    mazTextInput,
  },

  props: {
    list: {
      required: true,
      type: Array,
    },
    selectedList: {
      required: true,
      type: Array,
    },
    title: {
      required: false,
      type: String,
      default: dictionaryService.translate(
        "WorkflowDefinitionAdd.Labels.SelectEmployees"
      ),
    },
    searchPlaceholder: {
      required: false,
      type: String,
      default: dictionaryService.translate(
        "WorkflowDefinitionAdd.Labels.SearchEmployees"
      ),
    },
    selectedTitle: {
      required: false,
      type: String,
      default: dictionaryService.translate(
        "WorkflowDefinitionAdd.Labels.SelectedEmployees"
      ),
    },
    userIdKey: {
      required: false,
      type: String,
      default: "Id",
    },
    isUserDelegatedOnAdd: {
      /**
       * prop to set default IsDelegated flag to false (i.e. workflow-details)
       */
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      usersList: [],
      userSearch: "",
    };
  },
  watch: {
    list: function () {
      this.usersList = this.list;
    },
  },
  methods: {
    translate: function (label) {
      return dictionaryService.translate(label);
    },

    addUser: function (user) {
      const selectedUsers = this.selectedList.slice(); // copy list
      user.IsDelegated = this.isUserDelegatedOnAdd;

      // Add user to selected users list
      selectedUsers.push(user);

      // Push update event to the parent component/page
      this.$emit("update:selectedList", selectedUsers);
    },
    removeUser: function (user) {
      const key = this.userIdKey || "Id";
      // Remove user from selected users list and push the update event to the parent component/page
      this.$emit(
        "update:selectedList",
        this.removeItemFromListByProp(user, this.selectedList, key)
      );
      //this.$emit('update:selectedList', user);
    },

    filterBy: function (value, collection, key) {
      key = key || "Id";
      return value
        ? _.filter(collection, function (item) {
            return item[key].toLowerCase().indexOf(value.toLowerCase()) > -1;
          })
        : collection;
    },
    removeItemFromListByProp: function (item, collection, key) {
      key = key || "Id";

      let itemKey, collectionKey;

      // If key parameter is a list of keys
      if (Array.isArray(key) && key.length > 1) {
        itemKey = key[0];
        collectionKey = key[1];
      } else {
        itemKey = collectionKey = key;
      }

      // If key is not on item, return collection unchanged
      if (!!item[itemKey] === false) return collection;

      return collection.filter(function (current) {
        // If current item doesn't have "key" property, return true (doesn't remove item)
        if (!!current[collectionKey] === false) return true;

        // If item to find and current item don't match, return true (ie: keep item in collection).
        return current[collectionKey] !== item[itemKey];
      });
    },
  },
  computed: {
    filteredUsersList: function () {
      let usersList = this.list.slice(); // Copy list to temporary one
      const key = this.userIdKey || "Id";
      // Filter by search string
      usersList = this.userSearch
        ? this.filterBy(this.userSearch, usersList, "Name")
        : usersList;

      // Filter selected users from the users list
      if (usersList.length > 0 && this.selectedList.length > 0) {
        this.selectedList.forEach((selected) => {
          usersList = this.removeItemFromListByProp(selected, usersList, key);
        });
      }

      return usersList;
    },
  },
};
</script>

<style lang="scss">
@import "@/styling/custom/settings/__main.scss";
@import "./list-users.scss";
@import "./legacy-utilities.scss";
</style>
