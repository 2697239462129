import store2 from '@/store/store2'
import Vue from 'vue'
import clientDashboardPage from './modules/clientDashboardPage.vue'
import clientLoginPage from './modules/clientLoginPage.vue'
import clientMaintenanceOverviewPage from './modules/clientMaintenanceOverviewPage.vue'
import clientMaintenancePage from './modules/clientMaintenancePage.vue'
import clientMyProfilePage from './modules/clientMyProfilePage.vue'
import employeeOverviewPage from './modules/employeeOverviewPage.vue'
import employeePage from './modules/employeePage.vue'
import sessionTimeOut from './modules/sessionTimeOut.vue'
import supplyRequest2AddPage from './modules/supplyRequest2AddPage.vue'
import supplyRequest2DelegatedSegmentPage from './modules/supplyRequest2DelegatedSegmentPage.vue'
import supplyRequest2DetailPage from './modules/supplyRequest2DetailPage.vue'
import supplyRequest2EditPage from './modules/supplyRequest2EditPage.vue'
import supplyRequest2TemplateMaintenancePage from './modules/supplyRequest2TemplateMaintenancePage.vue'
import taskDetailPage from './modules/taskDetailPage.vue'
import workflowDetailPage from './modules/workflowDetailPage.vue'

export function registerPages() {
  const modules = [
    {
      component: clientDashboardPage,
      el: 'ClientDashboard'
    },
    {
      component: clientMaintenanceOverviewPage,
      el: 'ClientMaintenanceOverview'
    },
    {
      component: clientMaintenancePage,
      el: 'ClientMaintenance'
    },
    {
      component: supplyRequest2AddPage,
      el: 'SupplyRequest2Add'
    },
    {
      component: supplyRequest2DelegatedSegmentPage,
      el: 'SupplyRequest2DelegatedSegment'
    },
    {
      component: supplyRequest2DetailPage,
      el: 'SupplyRequest2Detail'
    },
    {
      component: supplyRequest2EditPage,
      el: 'SupplyRequest2Edit'
    },
    {
      component: supplyRequest2TemplateMaintenancePage,
      el: 'SupplyRequest2TemplateMaintenance'
    },
    {
      component: workflowDetailPage,
      el: 'WorkflowDetail'
    },
    {
      component: clientLoginPage,
      el: 'ClientLoginPage'
    },
    {
      component: taskDetailPage,
      el: 'TaskDetail'
    },
    {
      component: employeePage,
      el: 'EmployeePage'
    },
    {
      component: employeeOverviewPage,
      el: 'EmployeeOverview'
    },
    {
      component: clientMyProfilePage,
      el: 'ClientMyProfilePage'
    },
    {
      component: sessionTimeOut,
      el: 'SessionTimeOut'
    }
  ]

  modules.forEach(module => {
    if (document.getElementById(module.el)) {
      if (
        module.el === 'ClientMaintenance' ||
        module.el === 'SupplyRequest2DelegatedSegment'
      ) {
        new Vue({
          render: h => h(module.component),
          store: store2.original //check direct-vuex docs and "@/store/readme.md"
        }).$mount(`#${module.el}`)
      } else {
        new module.component({
          el: `#${module.el}`
        })
      }
    }
  })
}
