import clientMaintenanceApi from '@/api/modules/clientMaintenanceApi'
import config from '@/api/modules/config'
import axiosService from '@/services/modules/axiosService'
import helpersService from '@/services/modules/helpersService'
import toastr from 'toastr'
import { currentActionContext } from './index'

const _targetApi = clientMaintenanceApi
const _baseEndpoint = config.endpointBase

export const actions = {
  GET_MY_PROFILE_CLIENT_DETAILS(ctx: any) {
    const { commit, state } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.myProfileClientJson
    )
    commit.UPDATE_CLIENT_PROFILE_PAGE_LOADER_MUTATION(true)
    axiosService.get(endpoint, (res: any) => {
      if (res) {
        const body = helpersService.convertObjectKeysToCamelCase(res)
        commit.SET_CLIENT_PROFILE_TITLE_MUTATION(body.fullName)
        commit.SET_CLIENT_PROFILE_NUMBER_MUTATION(body.oraclePersonId)
        commit.clientMyProfilePageDetails.UPDATE_CLIENT_PROFILE_DETAILS_MUTATION(body)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.UPDATE_CLIENT_PROFILE_PAGE_LOADER_MUTATION(false)
    })
  },
  UPDATE_CLIENT_PROFILE_PAGE_TAB_INDEX({ commit }: any, payload: number) {
    commit('UPDATE_CLIENT_PROFILE_PAGE_TAB_INDEX_MUTATION', payload)
  }
}
