










































































































































import Vue from 'vue'

import mazModal from '@/components/general/mazModal/index.vue'
import mazDropDown from '@/components/general/mazDropDown/index.vue'
import mazTextInput from '@/components/general/mazTextInput/index.vue'
import mazButton from '@/components/general/mazButton/index.vue'
import mazAlert from '@/components/general/mazAlert/index.vue'
import mazValidationMessage from '@/components/general/mazValidationMessage/index.vue'

import store from '@/store/store2'

import { isString, isEmpty, pickBy, startsWith, trim } from 'lodash'

import dictionaryService from '@/services/modules/dictionaryService'

export default Vue.extend({
  name: 'mazClientMaintenanceOverviewAddClientModal',

  components: {
    mazModal,
    mazDropDown,
    mazTextInput,
    mazButton,
    mazAlert,
    mazValidationMessage
  },

  props: {
    customerPermission: {
      type: Object as () => MazInterfaces.ClientMaintenance.CustomerPermission,
      default: null
    }
  },

  data() {
    return {
      showModal: false,
      contactDetails: {
        email: '',
        firstName: '',
        lastName: '',
        gender: '',
        language: '',
        primaryContactEmployeeId: ''
      } as MazInterfaces.ClientMaintenance.CreateContactDetails,
      organizationDetails: {
        legalName: '',
        primaryContactEmployeeId: ''
      } as MazInterfaces.ClientMaintenance.CreateOrganizationDetails,
      errors: {}
    }
  },

  computed: {
    shouldRedirect(): boolean {
      return this.addUrl !== '' && this.addUrl !== undefined && this.addUrl !== null
    },
    addUrl(): string {
      return store.getters.clientMaintenanceOverviewStore.ADD_URL
    },
    step(): number {
      return store.getters.clientMaintenanceAdd.STEP
    },
    labels(): any {
      return store.getters.clientMaintenanceAdd.LABELS
    },
    messages(): any {
      return store.getters.clientMaintenanceAdd.MESSAGES
    },
    clientOptions(): any {
      return store.getters.clientMaintenanceAdd.CLIENT_OPTIONS
    },
    genderOptions(): any {
      return store.getters.clientMaintenanceAdd.GENDER_OPTIONS
    },
    languageOptions(): any {
      return store.getters.clientMaintenanceAdd.LANGUAGE_OPTIONS
    },
    relationManagerOptions(): any {
      return store.getters.clientMaintenanceAdd.RELATION_MANAGER_OPTIONS
    },
    modalHeaderText(): string {
      return store.getters.clientMaintenanceAdd.MODAL_HEADER_TEXT
    },
    clientType(): number {
      return store.getters.clientMaintenanceAdd.CLIENT_TYPE
    },
    validationErrors(): any {
      return store.getters.clientMaintenanceAdd.VALIDATION_ERRORS
    },
    clientUrl(): string {
      return store.getters.clientMaintenanceAdd.CLIENT_URL
    },
    mayShowModal(): boolean {
      return store.getters.clientMaintenanceAdd.MAY_SHOW_MODAL
    },
    showSelectClientType(): boolean {
      return store.getters.clientMaintenanceAdd.SHOW_SELECT_CLIENT_TYPE
    },
    existingRelationManagerDetails(): any {
      return store.getters.clientMaintenanceAdd.EXISTING_RELATION_MANAGER_DETAILS
    },
    shouldRefreshOnClose(): boolean {
      return store.getters.clientMaintenanceAdd.SHOULD_REFRESH_ON_CLOSE
    },
    countriesList(): any {
      return store.getters.clientMaintenanceAdd.COUNTRIES_LIST
    },
    invalidEmailDetails(): any {
      return store.getters.clientMaintenanceAdd.INVALID_EMAIL_DETAILS
    },
  },

  methods: {
    open(): void {
      this.setupModal()
      this.showModal = true
    },
    close(): void {
      this.$emit('close', this.shouldRefreshOnClose)
      this.showModal = false
    },
    redirectToPowerApp(): void {
      window.open(this.addUrl, '_blank')?.focus();
    },
    setupModal(): void {
      this.contactDetails = {
        email: '',
        firstName: '',
        lastName: '',
        gender: '',
        language: '',
        primaryContactEmployeeId: '',
        countryCode: ''
      }
      this.organizationDetails = {
        legalName: '',
        primaryContactEmployeeId: '',
        countryCode: ''
      }
      this.errors = {}
      store.dispatch.clientMaintenanceAdd.SETUP_MODAL()
    },
    setClientType(clientType: number): void {
      store.dispatch.clientMaintenanceAdd.SET_CLIENT_TYPE(clientType)
    },
    save(): void {
      if (this.clientType === 10) {
        const body = this.organizationDetails
        this.fieldValidator(body, [
          'legalName',
          'primaryContactEmployeeId',
          'countryCode'
        ])
        store.dispatch.clientMaintenanceAdd.SAVE_ORGANIZATION(body)
      } else if (this.clientType === 20) {
        const body = this.contactDetails
        this.fieldValidator(body, [
          'primaryContactEmployeeId',
          'email',
          'gender',
          'firstName',
          'lastName',
          'language',
          'countryCode'
        ])
        store.dispatch.clientMaintenanceAdd.SAVE_CONTACT(body)
      }
    },
    resetError(field: string): void {
      this.$delete(this.errors, field)
      store.dispatch.clientMaintenanceAdd.RESET_VALIDATION_ERROR(field)
    },
    fieldValidator(objectToValidate: any, fields: string[]) {
      fields.forEach((field: string) => {
        //if string, check if it has white spaces
        if (
          isString(objectToValidate[field]) &&
          trim(objectToValidate[field]).length === 0
        ) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired }
          }
          return
        }

        if (isEmpty(objectToValidate[field])) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired }
          }
        } else {
          const filteredErrors = pickBy(this.errors, function (value, key) {
            return !startsWith(key, field)
          })
          this.errors = filteredErrors
        }
      })
    },
    validationError(field: string): string {
      const err = this.validationErrors.find(
        (err: any) => err.PropertyName === field
      )?.ErrorCode
      if (err) {
        return dictionaryService.translate(`EmployeeContactOverview.Messages.${err}`)
      }
      return ''
    }
  },

  watch: {
    step() {
      const preselectedLanguage = this.languageOptions.find(
        (obj: any) => obj.isCurrentLanguage === true
      )
      if (preselectedLanguage) {
        this.contactDetails.language = preselectedLanguage.code
      }
      const preselectedRelationManager = this.relationManagerOptions.find(
        (obj: any) => obj.isCurrentUser === true
      )
      if (preselectedRelationManager) {
        this.contactDetails.primaryContactEmployeeId = preselectedRelationManager.id
        this.organizationDetails.primaryContactEmployeeId = preselectedRelationManager.id
      }
    },
    customerPermission(value: MazInterfaces.ClientMaintenance.CustomerPermission) {
      store.commit.clientMaintenanceAdd.SET_CUSTOMER_PERMISSION_MUTATION(value)
    }
  }
})
