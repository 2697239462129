<template>
  <div class="c-intro-text qa-intro-text">
    <div class="d-md-flex">
      <!-- Note: We are using white-space: pre-wrap to maintain line breaks, do not give extra space between tag and content, because it will also be rendered -->
      <p
        :class="['pr-md-5 flex-fill u-pre-wrap',{'is-cutoff': !isExpanded}, {'c-intro-text--is-cutoff': hasMultipleLines && !isExpanded}]"
        ref="descriptionTxt"
      >{{supply_request_description.description}}</p>
      <a
        :class="['e-link-show-more c-intro-text__toggle',{'collapsed': !isExpanded}]"
        @click="toggleDescription"
        v-if="hasMultipleLines"
      >
        <span
          class="e-link-bold"
          v-if="isExpanded"
        >{{supply_request_description.labels.showLabel}}</span>
        <span
          class="e-link-bold"
          v-else
        >{{supply_request_description.labels.hideLabel}}</span>
        <span class="e-icon-toggle" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'maz-supply-request-description',
  props: {
    supply_request_description: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isExpanded: false,
      hasMultipleLines: false
    }
  },
  methods: {
    toggleDescription() {
      this.isExpanded = !this.isExpanded
    },
    checkLineBreaksInDescription() {
      const descriptionTxt = this.$refs.descriptionTxt
      const getLineHeight = window
        .getComputedStyle(descriptionTxt)
        .getPropertyValue('line-height')

      const getScrollHeight = this.$refs.descriptionTxt.scrollHeight
      const isMobile = window.innerWidth <= 576
      let displayedLines

      const numberOfLineBreaks = getScrollHeight / parseInt(getLineHeight)

      if (isMobile) {
        displayedLines = 2
      } else {
        displayedLines = 3
      }

      if (numberOfLineBreaks > displayedLines) {
        this.hasMultipleLines = true
      } else {
        this.hasMultipleLines = false
      }
    }
  },
  updated() {
    this.$nextTick(() => {
      this.checkLineBreaksInDescription()
    })
  },
  created() {
    window.addEventListener('resize', this.checkLineBreaksInDescription)
  },
  destroyed() {
    window.removeEventListener('resize', this.checkLineBreaksInDescription)
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-intro-text {
  max-height: none;
  .c-intro-text__toggle {
    margin-top: -1px; // Because of different font-families between the description text and this toggle, we need to do this to make it align better

    &:hover {
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }
  }
  .c-intro-text--is-cutoff {
    max-height: 66px;
    @include media-breakpoint-down(xs) {
      max-height: 48px;
    }
  }
}
</style>
