<template>
  <div class="c-autocomplete">
    <slot v-bind="slotProperties">
      <input
        :value="search"
        @input="onChange"
        @keyup.down="onArrowDown"
        @keyup.enter="onEnter"
        @keyup.up="onArrowUp"
        type="text"
      />
    </slot>
    <maz-button
      v-if="showSearchButton"
      class="c-autocomplete__search c-btn--empty c-icons c-icons--search"
      @click.native="$emit('on-search-click')"
    />
    <ul class="c-autocomplete__results" v-if="filteredResults.length > 0" v-show="isOpen">
      <li v-if="isLoading">
        <maz-spinner />
      </li>
      <li
        :class="{ 'is-active': i === arrowCounter }"
        :key="i"
        @click="setResult(result)"
        class="c-autocomplete__result"
        v-else
        v-for="(result, i) in filteredResults"
        v-html="highlightSearchTerm(search, result)"
      />
    </ul>
  </div>
</template>

<script>
import mazButton from '@/components/general/mazButton/index.vue'
import mazSpinner from '@/components/general/mazSpinner/index.vue'
export default {
  name: 'maz-auto-complete-input',
  template: '.c-autocomplete',
  components: {
    mazButton,
    mazSpinner
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false
    },
    searchKeys: {
      type: Array,
      default() {
        return []
      },
      required: true,
      validator(value) {
        return !!value.length
      }
    },
    displayKey: {
      type: String,
      default: 'name'
    },
    showSearchButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      search: '',
      isLoading: '',
      arrowCounter: 0
    }
  },
  computed: {
    slotProperties() {
      return {
        onChange: this.onChange,
        onArrowDown: this.onArrowDown,
        onArrowUp: this.onArrowUp,
        onEnter: this.onEnter,
        search: this.search
      }
    },
    matchingItem() {
      return this.items.find((item) => {
        return this.searchKeys.some((key) => {
          if (item.customerNumber === null) {
            this.$emit(
              'onNumChange',
              item.name.toLowerCase() === `${this.search}`.toLowerCase()
            )
            return item.name.toLowerCase() === `${this.search}`.toLowerCase()
          } else {
            this.$emit(
              'onNumChange',
              item[key].toLowerCase() === `${this.search}`.toLowerCase()
            )
            return item[key].toLowerCase() === `${this.search}`.toLowerCase()
          }
        })
      })
    },
    filteredResults() {
      return this.items
        .filter((item) => {
          return this.searchKeys.some((key) => {
            if (item.customerNumber === null) {
              return item.name.toLowerCase().indexOf(`${this.search}`.toLowerCase()) > -1
            } else {
              return item[key].toLowerCase().indexOf(`${this.search}`.toLowerCase()) > -1
            }
          })
        })
        .map((i) => i[this.displayKey])
    }
  },
  methods: {
    onChange(event) {
      const value = event.target.value
      if (value && value.length > 2) {
        this.$emit('onChange', value)
      }

      this.search = value
      // Is the data given by an outside ajax request?
      if (this.isAsync) {
        this.isLoading = true
      } else {
        // Let's search our flat array
        if (this.search.length > 2) {
          this.isOpen = true
        } else {
          this.isOpen = false
        }
      }
    },
    setResult(result) {
      this.search = result
      this.isOpen = false
      this.$emit('onItemSelect', result)
    },
    onArrowDown() {
      if (this.arrowCounter < this.filteredResults.length) {
        this.arrowCounter = this.arrowCounter + 1
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1
      }
    },
    onEnter() {
      this.search = this.filteredResults[this.arrowCounter]
      this.isOpen = false
      this.arrowCounter = 0
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false
        this.arrowCounter = 0
      }
    },
    highlightSearchTerm(searchTerm, result) {
      const regex = new RegExp(searchTerm, 'gi')
      const response = result.replace(regex, function (str) {
        return '<strong>' + str + '</strong>'
      })
      return response
    }
  },
  watch: {
    matchingItem(newValue) {
      this.$emit('onIsItemSelected', !!newValue)

      // If there is a matching item, we want to set the arrowCounter to the index of the item in filteredResults
      if (newValue) {
        this.arrowCounter = this.filteredResults.findIndex((r) => r == newValue)
        this.$emit('setCustomerId', newValue.id)
      } else {
        this.arrowCounter = 0
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-autocomplete {
  position: relative;
  width: 100%;
  .c-autocomplete__search {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 48px;
  }
  .c-autocomplete__results {
    position: absolute;
    z-index: 1;
    width: 100%;
    max-height: 200px;
    margin: 0;
    padding: 0;
    overflow: auto;
    background-color: $white;
    border: 1px solid $gray;

    .c-autocomplete__result {
      padding: 6px 14px;
      color: $steel-blue;
      font-size: 14px;
      text-align: left;
      list-style: none;
      border-top: 1px solid $light-steel-blue;
      cursor: pointer;

      &:hover,
      &.is-active {
        color: $blue;
      }

      &:first-child {
        border-top: 0;
      }
    }
  }
}
</style>
