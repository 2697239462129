<template>
  <div :class="['c-comment', commentType]">
    <div class="c-comment__container">
      <p class="c-comment__description">{{ comment.description }}</p>
      <span class="c-comment__post-info"
        >{{ comment.postTime }} | {{ comment.title }}</span
      >
      <div class="c-comment__pointer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'maz-comment',
  props: {
    comment: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    commentType() {
      let result = 'c-comment--secondary'

      if (this.comment.mazarsComment === false) {
        result = 'c-comment--primary'
      }

      return result
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-comment {
  min-width: 40%;
  margin-right: 50px;
  padding: 25px 40px 25px 30px;
  background-color: $white;
  border-radius: 8px;

  @include media-breakpoint-down(sm) {
    margin-right: 20px;
    padding: 25px 20px;
  }

  + .c-comment {
    margin-top: 16px;
  }

  .c-comment__container {
    position: relative;
    @include media-breakpoint-down(sm) {
      z-index: 2;
    }

    .c-comment__pointer {
      position: absolute;
      top: 3px;
      left: -34px;
      width: 25px;
      height: 25px;
      background-color: $white;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);

      @include media-breakpoint-down(sm) {
        left: -24px;
        z-index: 1;
      }
    }

    .c-comment__description {
      margin-bottom: 1em;
      font-size: 16px;
      word-break: break-all;

      @include media-breakpoint-down(sm) {
        position: relative;
        z-index: 2;
      }
    }

    .c-comment__post-info {
      color: $dark-gray;
      font-size: 16px;
      font-family: 'Halyard-Medium', sans-serif;
    }
  }

  &.c-comment--primary {
    margin-right: 0;
    margin-left: 50px;
    background-color: $light-steel-blue;

    .c-comment__post-info {
      display: block;
      text-align: right;
    }

    .c-comment__pointer {
      right: -44px;
      left: auto;
      background-color: $light-steel-blue;

      @include media-breakpoint-down(sm) {
        right: -24px;
        z-index: 1;
      }
    }
  }
}
</style>
