<template>
  <maz-session-time-out-counter :is-inactive="isInactive" />
</template>
<script>
import axios from "axios";
import { INACTIVE_USER_TIME_THRESHOLD } from "./constants";
import EventBus from "@/plugins/EventBus";
import mazSessionTimeOutCounter from "../mazSessionTimeOutCounter/index.vue";

const apiEndpoints = {
  getLoginStatus: "json/ExtranetSecurityGenericJson/GetLoginStatus",
};

export default {
  name: "maz-session-auto-logout",
  components: {
    mazSessionTimeOutCounter,
  },
  data() {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load", "resize"],
      warningTimer: null,
      isInactive: false,
      isLoggedIn: false,
      isSitecoreBackendUser: false,
      isClient: false,
      isEmployee: false,
      logout_redirect_url: "/logout",
    };
  },
  mounted() {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer);
    }, this);
    this.setTimers();
    this.checkTabVisibility();
    this.verifyLogin();
    EventBus.$on("close-session-timout-modal", () => {
      this.isInactive = false;
    });
  },
  destroyed() {
    this.events.forEach(function (event) {
      window.removeEventListener(event, this.resetTimer);
    }, this);
    window.removeEventListener("visibilitychange");
    this.resetTimer();
  },
  methods: {
    setTimers() {
      this.warningTimer = setTimeout(this.warningMessage, INACTIVE_USER_TIME_THRESHOLD);
    },
    warningMessage() {
      this.isInactive = true;
    },
    resetTimer() {
      clearTimeout(this.warningTimer);
      this.setTimers();
    },
    checkTabVisibility() {
      window.addEventListener("visibilitychange", this.verifyActiveTab);
    },
    verifyActiveTab() {
      if (!document.hidden) {
        //if tab is active, validate user session
        this.verifyLogin();
      }
    },
    verifyLogin() {
      axios.get(apiEndpoints.getLoginStatus).then((response) => {
        if (response.data) {
          this.isLoggedIn = response.data.IsLoggedIn;
          this.isSitecoreBackendUser = response.data.IsSitecoreBackendUser;
          this.isClient = response.data.IsClient;
          this.isEmployee = response.data.IsEmployee;
          this.validateSession();
        }
      });
    },
    validateSession() {
      if ((!this.isLoggedIn && this.isEmployee) || (!this.isLoggedIn && this.isClient))
        window.location = this.logout_redirect_url;
    },
  },
};
</script>
