export const mutations = {
  UPDATE_CLIENT_PROFILE_ORGANIZATION_PAGE_LOADER_MUTATION(state: any, payload: object) {
    state.loader = { ...state.loader, ...payload }
  },
  UPDATE_CLIENT_PROFILE_ORGANIZATIONS(state: any, payload: any) {
    state.connectedOrganizations.rows = { ...payload.organizations }
    if (payload.organizations.length) state.connectedOrganizations.available = true
  },
  UPDATE_CLIENT_PROFILE_PERMISSIONS_INITIAL_DATA(state: any, payload: any) {
    state.organizationPermissionModal.details.organizationDetails = payload
  },
  UPDATE_CLIENT_PROFILE_PERMISSIONS(state: any, payload: any) {
    state.organizationPermissionModal.details.permissionsDetails = { ...payload.services }
  }
}
