import sortBy from 'lodash/sortBy'
import clientMaintenancePermissionsColumnOrderService from './clientMaintenancePermissionsColumnOrderService'

function orderPermissions(data) {
  const result = []

  data.forEach(permission => {
    result.push({
      ...permission,
      Order: clientMaintenancePermissionsColumnOrderService.filter(
        property => property.code === permission.Code
      )[0].order
    })
  })

  return sortBy(result, ['Order'], ['asc'])
}

function generatePermissions(data) {
  const result = []

  if (data.Services) {
    data.Services.forEach(service => {
      result.push({
        ...service,
        Permissions: orderPermissions(service.Permissions)
      })
    })

    return { ...data, Services: result }
  } else {
    return data
  }
}

const Service = {
  processPermissions(payload) {
    return generatePermissions(payload)
  }
}

export default Service
