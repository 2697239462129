<template>
  <div class="c-accordions">
    <div
      class="c-accordions__item"
      v-if="supplyRequestDelegationOverview.categories.length > 0"
    >
      <div
        aria-controls="collapseDelegationOverview"
        aria-expanded="false"
        class="c-accordions__label d-flex align-items-center collapsed"
        data-target="#collapseDelegationOverview"
        data-toggle="collapse"
        id="headingDelegationOverview"
      >
        <span class="c-accordions__title u-text-small">{{
          labels.supplyRequestDelegationOverviewHeader
        }}</span>
        <span class="span-new c-accordions__number">{{
          supplyRequestDelegationOverview.numberOfDelegations
        }}</span>
      </div>
      <div
        aria-labelledby="headingDelegationOverview"
        class="c-accordions__content collapse"
        id="collapseDelegationOverview"
      >
        <div class="c-accordions__content__inner">
          <div
            :key="'category.id.clean' + categoryIdx"
            class="c-accordions__group"
            v-for="(category, categoryIdx) in supplyRequestDelegationOverview.categories"
          >
            <h3 class="c-h3 c-accordions__heading-main">
              {{ category.index }}. {{ category.title }}
            </h3>
            <div
              :key="'subCategory.id.clean' + subCategoryIdx"
              class="c-accordions__sub-group"
              v-for="(subCategory, subCategoryIdx) in category.groupSegments"
            >
              <span class="c-accordions__heading-sub">
                <span class="c-state-indicator c-state-indicator--small" />
                {{ category.index }}.{{ subCategory.index }} {{ subCategory.title }}
              </span>
              <div
                :key="'segment.id.clean' + segmentIdx"
                class="c-flextable"
                v-for="(segment, segmentIdx) in subCategory.segments"
              >
                <div
                  :key="'delegee.email' + emailIdx"
                  class="c-flextable__row d-flex align-items-center"
                  v-for="(delegee, emailIdx) in segment.delegees"
                >
                  <a
                    :href="'#' + segment.id"
                    @click="navigateToSegment(segment.id, category.id, subCategory.id)"
                    class="c-flextable__cell"
                    >{{ segment.index }}. {{ segment.title }}</a
                  >
                  <span class="c-flextable__cell">{{ delegee.email }}</span>
                  <span class="c-flextable__cell">{{ delegee.delegationDueDate }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="c-accordions__item"
      v-if="supplyRequestUpdatedSegmentsOverview.numberOfSegments > 0"
    >
      <div
        aria-controls="collapseUpdatedSegment"
        aria-expanded="false"
        class="c-accordions__label d-flex align-items-center collapsed"
        data-target="#collapseUpdatedSegment"
        data-toggle="collapse"
        id="headingUpdatedSegment"
      >
        <span class="c-accordions__title u-text-small">{{
          labels.supplyRequestUpdatedSegmentsHeader
        }}</span>
        <span class="span-new c-accordions__number">{{
          supplyRequestUpdatedSegmentsOverview.numberOfSegments
        }}</span>
      </div>
      <div
        aria-labelledby="headingUpdatedSegment"
        class="c-accordions__content collapse"
        id="collapseUpdatedSegment"
      >
        <div
          class="c-accordions__content__inner c-accordions__content__inner--updated-segments"
        >
          <div
            :key="'segment.id.clean' + segmentIdx"
            class="c-flextable"
            v-for="(segment, segmentIdx) in supplyRequestUpdatedSegmentsOverview.segments"
          >
            <div class="c-flextable__row d-flex align-items-center">
              <a
                :href="'#' + segment.id.clean"
                @click="
                  navigateToSegment(
                    segment.id,
                    segment.categoryId,
                    segment.groupSegmentId
                  )
                "
                class="c-flextable__cell"
                >{{ segment.index }}. {{ segment.title }}</a
              >
              <span class="c-flextable__cell">{{
                segment.dateTimeMostRecentActivity
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import EventBus from '@/plugins/EventBus'

const _targetStore = 'supplyRequest2DetailStore/'

export default {
  name: 'maz-supply-request-accordions',
  props: {
    supply_request_comment_overview: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    supply_request_categories: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      clickedCategoryId: '',
      clickedSubCategoryId: '',
      clickedSegmentId: ''
    }
  },
  computed: {
    ...mapGetters({
      id: _targetStore + 'ID',
      supplyRequestSegmentId: _targetStore + 'SUPPLY_REQUEST_NAVIGATION_TARGET_SEGMENT',
      supplyRequestCategories: _targetStore + 'SUPPLY_REQUEST_CATEGORIES',
      supplyRequestDelegationOverview:
        _targetStore + 'SUPPLY_REQUEST_DELEGATION_OVERVIEW',
      supplyRequestUpdatedSegmentsOverview:
        _targetStore + 'SUPPLY_REQUEST_UPDATED_SEGMENTS_OVERVIEW',
      labels: _targetStore + 'LABELS'
    })
  },
  methods: {
    navigateToSegment(targetSegment, targetCategory, targetSubCategory) {
      const _categories = this.supplyRequestCategories.categories
      const segment = targetSegment.clean

      this.clickedCategoryId = targetCategory.original
      this.clickedSubCategoryId = targetSubCategory.original
      this.clickedSegmentId = targetSegment.original

      _categories.forEach((category) => {
        const tempTargetNavigationId = targetCategory.clean
        const tempNavigationId = category.id.clean

        if (tempTargetNavigationId === tempNavigationId) {
          // Open the tab for this category
          const notMatchingTab = document.getElementsByClassName('tab-pane')
          $(notMatchingTab).tab('dispose').removeClass('active')

          const tabPaneId = 'navigationId' + tempTargetNavigationId
          const matchingTabPane = document.getElementById(tabPaneId)
          $(matchingTabPane).tab('show').addClass('active')

          const tabId = '#navigationId' + tempTargetNavigationId
          const categoryBarTab = document.getElementById(tabId)
          $(categoryBarTab).tab('show').addClass('active')

          // Scroll to the specific segment
          this.scrollToCategoryBar(category, segment)
        }
      })
    },
    scrollToCategoryBar(category, segment) {
      const self = this
      const options = {
        container: 'body',
        easing: 'ease-in',
        cancelable: false,
        onDone() {
          self.loadCategory(category, segment)
        }
      }

      this.$nextTick(() => {
        setTimeout(() => {
          this.$scrollTo('.c-category-bar__items', 300, options)
        }, 300);
      })
    },
    loadCategory(category, segment) {
      const body = {
        SupplyRequestId: category.parentSupplyRequestId.original,
        CategoryId: category.id.original,
        SegmentId: segment
      }

      this.$store.dispatch(_targetStore + 'GET_CATEGORY_BY_ID', body)
    },
    scrollToSegment(segment) {
      const options = {
        container: 'body',
        easing: 'ease-in',
        cancelable: false
      }

      const body = {
        supplyRequestId: this.id,
        categoryId: this.clickedCategoryId,
        subCategoryId: this.clickedSubCategoryId,
        segmentId: this.clickedSegmentId
      }

      this.$store.dispatch(_targetStore + 'GET_UPDATED_SEGMENT_FILES', body)
      this.$store.dispatch(_targetStore + 'GET_UPDATED_SEGMENT_DELEGATIONS', body)
      this.$store.dispatch(_targetStore + 'GET_ATTACHMENTS', body)

      this.$nextTick(() => {
        setTimeout(() => {
          this.$scrollTo('#navigationId' + segment, 300, options)
          EventBus.$emit('scrollToSegment', segment)
        }, 300);
      })
    }
  },
  watch: {
    supplyRequestSegmentId: {
      handler(newValue, oldValue) {
        if (newValue != oldValue && newValue) {
          this.$nextTick(() => {
            this.scrollToSegment(newValue)

            this.$store.dispatch(
              _targetStore + 'RESET_SUPPLY_REQUEST_NAVIGATION_TARGET_SEGMENT'
            )
          })
        }
      },
      deep: true
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-accordions {
  display: inline-block;
  width: 100%;

  .c-accordions__item {
    + .c-accordions__item {
      margin-top: 4px;
    }

    .c-accordions__title {
      padding-right: 20px;
      color: $dark-gray;
      font-family: 'Halyard-Book', sans-serif;

      @media #{$media-query-from-tablet} {
        min-width: 300px;
      }
    }

    .c-accordions__number {
      color: $dark-blue;
      font-family: 'Halyard-Bold', sans-serif;
    }

    .c-accordions__label {
      position: relative;
      padding: 10px 24px;
      background-color: $blue;
      cursor: pointer;

      .c-accordions__title,
      .c-accordions__number {
        color: $white;
      }

      &:after {
        position: absolute;
        right: 24px;
        width: 24px;
        height: 24px;
        background-color: $white;
        transform: rotate(-180deg);
        transition: transform 0.3s ease-in-out;
        content: '';
        -webkit-mask-image: url('~@/assets/images/icon_collapse_arrow.svg');
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: 24px 24px;
        mask-image: url('~@/assets/images/icon_collapse_arrow.svg');
        mask-repeat: no-repeat;
        mask-size: 24px 24px;
      }

      &.collapsed {
        background-color: $white-smoke;

        .c-accordions__title {
          color: $dark-gray;
        }
        .c-accordions__number {
          color: $dark-blue;
        }

        &:after {
          background-color: $dark-blue;
          transform: rotate(0deg);
          -webkit-mask-image: url('~@/assets/images/icon_collapse_arrow.svg');
          mask-image: url('~@/assets/images/icon_collapse_arrow.svg');
        }
      }

      &:hover,
      &.collapse {
        color: $white;
        background-color: $blue;

        .c-accordions__title,
        .c-accordions__number {
          color: $white;
        }

        &.collapsed {
          &:after {
            background-color: $white;
            transform: rotate(0deg);
            -webkit-mask-image: url('~@/assets/images/icon_collapse_arrow.svg');
            mask-image: url('~@/assets/images/icon_collapse_arrow.svg');
          }
        }
      }
    }

    .c-accordions__content {
      padding: 24px;
      background-color: $white-smoke;

      @media #{$media-query-mobile} {
        padding: 24px 12px;
      }

      .c-accordions__content__inner {
        max-height: 500px;
        padding: 0 24px;
        overflow: auto;

        .c-flextable__row {
          font-size: 14px;
          font-family: 'Halyard-Book', sans-serif;

          .c-flextable__cell {
            width: 100%;
            padding-right: 40px;
            font-size: 14px;
            font-family: 'Halyard-Book', sans-serif;

            @include media-breakpoint-up(md) {
              width: 25%;
            }

            &:first-child {
              @include media-breakpoint-up(md) {
                width: 60%;
              }
            }

            &:last-child {
              width: 100%;
              padding-right: 0;
              text-align: right;

              @include media-breakpoint-up(md) {
                width: 15%;
              }
            }
          }
        }

        &.c-accordions__content__inner--updated-segments {
          max-height: 320px; // About 10 rows

          .c-flextable__row {
            .c-flextable__cell {
              &:first-child {
                @include media-breakpoint-up(md) {
                  width: 65%;
                }
              }

              &:last-child {
                @include media-breakpoint-up(md) {
                  width: 35%;
                }
              }
            }
          }
        }

        @media #{$media-query-mobile} {
          padding: 0 12px;
        }
      }

      .c-accordions__group {
        + .c-accordions__group {
          margin-top: 8px;
          padding-top: 24px;
          border-top: 1px solid $light-steel-blue;
        }
      }

      .c-accordions__sub-group {
        + .c-accordions__sub-group {
          margin-top: 12px;
        }
      }

      .c-accordions__heading-sub {
        display: inline-block;
        width: 100%;
        margin-bottom: 8px;
        padding: 6px 0;
        color: $dark-gray;
        font-size: 18px;
        font-family: 'Halyard-Medium', sans-serif;
        border-top: 1px solid $light-steel-blue;
        border-bottom: 1px solid $light-steel-blue;
      }
    }

    &:first-child {
      .c-accordions__label {
        border-radius: 4px 4px 0 0;
        &.collapsed {
          border-radius: 4px 4px 0 0;
        }
      }
    }

    &:last-child {
      .c-accordions__label {
        &.collapsed {
          border-radius: 0 0 4px 4px;
        }
      }

      .c-accordions__content {
        border-radius: 0 0 4px 4px;
      }
    }

    &:only-child {
      .c-accordions__label {
        border-radius: 4px 4px 0 0;
        &.collapsed {
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
