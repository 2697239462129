










































































































































































































































































































import Vue from 'vue'
import store from '@/store/store2'
import moment from 'moment'
import mazButton from '@/components/general/mazButton/index.vue'
import mazClientMaintenanceDatePicker from '@/components/custom/mazClientMaintenanceDatePicker/index.vue'
import mazDropDown from '@/components/general/mazDropDown/index.vue'
import mazTextInput from '@/components/general/mazTextInput/index.vue'
import mazSpinner from '@/components/general/mazSpinner/index.vue'
import mazValidationMessage from '@/components/general/mazValidationMessage/index.vue'

import { isString, isEmpty, pickBy, startsWith, trim } from 'lodash'
import dictionaryService from '@/services/modules/dictionaryService'

export default Vue.extend({
  name: 'mazEmployeeDetails',
  components: {
    mazButton,
    mazClientMaintenanceDatePicker,
    mazDropDown,
    mazTextInput,
    mazSpinner,
    mazValidationMessage
  },

  data() {
    return {
      errors: {} as any,
      startDate: null as any,
      endDate: null as any,
      resetCross: false
    }
  },

  computed: {
    employeeDetails() {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.EMPLOYEE_DETAILS
    },
    editedEmployeeDetails(): any {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails
        .EDITED_EMPLOYEE_DETAILS
    },
    isEditMode(): boolean {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.IS_EDIT_MODE
    },
    loader(): boolean {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.LOADER
    },
    labels(): any {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.LABELS
    },
    permissions(): MazInterfaces.EmployeeMaintenance.EmployeePermission {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.PERMISSIONS
    },
    gendersList(): readonly MazInterfaces.EmployeeMaintenance.Gender[] {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.GENDERS_LIST
    },
    languagesList(): readonly MazInterfaces.EmployeeMaintenance.Language[] {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.LANGUAGES_LIST
    },
    messages(): any {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.MESSAGES
    },
    validationErrorsList(): readonly MazInterfaces.EmployeeMaintenance.ValidationError[] {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails
        .VALIDATION_ERRORS_LIST
    }
  },
  methods: {
    getReferenceData(): void {
      store.dispatch.employeeMaintenance.employeeMaintenanceDetails.GET_REFERENCE_DATA()
    },
    updateEmployeeDetailsEditMode(body: boolean): void {
      store.dispatch.employeeMaintenance.employeeMaintenanceDetails.UPDATE_EMPLOYEE_DETAILS_EDIT_MODE(
        body
      )
    },
    updateEmployeeDetails(body: any): void {
      store.dispatch.employeeMaintenance.employeeMaintenanceDetails.UPDATE_EMPLOYEE_DETAILS(
        body
      )
    },
    resetValidationList(): void {
      store.dispatch.employeeMaintenance.employeeMaintenanceDetails.RESET_VALIDATION_ERRORS()
    },
    resetError(field: string): void {
      if (this.errors[field]) {
        this.$delete(this.errors, field)
      }
      store.dispatch.employeeMaintenance.employeeMaintenanceDetails.RESET_VALIDATION_ERROR(
        field
      )
    },
    resetErrors(): void {
      this.errors = {} // frontend errors
      this.resetValidationList() //backend errors
    },
    genderName(code: string): string {
      const gender = this.gendersList.find((obj) => obj.code === code)
      return gender?.name || ''
    },
    languageName(code: string): string {
      const lang = this.languagesList.find((obj) => obj.code === code)
      return lang?.name || ''
    },
    resetEmployeeEditDetails(): void {
      store.dispatch.employeeMaintenance.employeeMaintenanceDetails.RESET_EMPLOYEE_DETAILS()
    },
    save(details: MazInterfaces.EmployeeMaintenance.EmployeeDetails) {
      const body: MazInterfaces.EmployeeMaintenance.EmployeeDetails = details
      this.resetValidationList()
      this.fieldValidator(details, [
        'firstName',
        'lastName',
        'initials',
        'fullName',
        'salutation',
        'number',
        'email',
        'language'
      ])
      this.updateEmployeeDetails(body)
    },
    fieldValidator(objectToValidate: any, fields: string[]) {
      fields.forEach((field: string) => {
        //if string, check if it has white spaces
        if (
          isString(objectToValidate[field]) &&
          trim(objectToValidate[field]).length === 0
        ) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired }
          }
          return
        }

        if (isEmpty(objectToValidate[field])) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired }
          }
        } else {
          const filteredErrors = pickBy(this.errors, function (value, key) {
            return !startsWith(key, field)
          })
          this.errors = filteredErrors
        }
      })
    },
    validationError(field: string): string {
      const err = this.validationErrorsList.find(
        (err) => err.PropertyName === field
      )?.ErrorCode
      if (err) {
        return dictionaryService.translate(`EmployeeDetail.Messages.${err}`)
      }
      return ''
    },
    formatDate(event: string) {
      if (event === null) {
        return null
      }
      return moment(event).format('DD/MM/YYYY')
    },
    onStartDateSelected(event: Date): void {
      const startDateOffset = moment(event).utcOffset()
      this.editedEmployeeDetails.startDate = moment(event)
        .add(startDateOffset, 'minutes')
        .utc()
        .format()
    },
    onEndDateSelected(event: Date): void {
      const endDateOffset = moment(event).utcOffset()
      this.editedEmployeeDetails.endDate = moment(event)
        .add(endDateOffset, 'minutes')
        .utc()
        .format()
    },
    getEmployeesActiveDates() {
      const initialStartDate = this.editedEmployeeDetails.startDate
      const initialEndDate = this.editedEmployeeDetails.endDate

      this.startDate = moment(initialStartDate)
      this.endDate = moment(initialEndDate)
    },
    resetEndDate(value: boolean) {
      this.editedEmployeeDetails.endDate = null
      this.endDate = null
      this.resetCross = value
    }
  },

  watch: {
    isEditMode: function (value: boolean): void {
      if (value === false) {
        this.resetEmployeeEditDetails()
      }
    }
  },

  created() {
    this.getEmployeesActiveDates()
    this.getReferenceData()
  }
})
