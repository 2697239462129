





























import Vue from 'vue'
import EventBus from '@/plugins/EventBus'
import dictionaryService from '@/services/modules/dictionaryService'

import type { ClientLoginConfig } from '@/interfaces/authenticationInterfaces'
import { initiateOkta } from '@/plugins/OktaLogin'

export default Vue.extend({
  name: 'mazClientLoginOkta',

  data() {
    return {
      oktaLoaded: false,
      loginLoading: false
    }
  },

  props: {
    config: {
      type: Object as () => ClientLoginConfig,
      required: true
    }
  },

  mounted() {
    if (!this.oktaLoaded && !this.config.IsLoggedIn) {
      this.$nextTick(() => {
        initiateOkta()
        this.oktaLoaded = true
      })
    }

    EventBus.$on('start-client-login-loading', () => {
      this.loginLoading = true
    })

    EventBus.$on('stop-client-login-loading', () => {
      this.loginLoading = false
    })
  },

  computed: {
    alreadyLoggedInMessage(): string {
      return dictionaryService.translate('Login.Messages.AlreadyLoggedIn')
    }
  }
})
