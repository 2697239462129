import helpersService from '@/services/modules/helpersService'
import { localization } from './localization'

export default () => ({
  id: helpersService.getSearchId('?oid=', window.location.search) as string,
  isContact: false,
  isOrganization: false,
  isNotFound: false,
  ...localization
})
