






















































import Vue from 'vue'
import store from '@/store/store2'
import mazEmployeeDetails from '@/components/custom/mazEmployeeDetails/index.vue'
import mazEmployeeClientsOverview from '@/components/custom/mazEmployeeClientsOverview/index.vue'
import mazEmployeePermissions from '@/components/custom/mazEmployeePermissions/index.vue'
import mazSpinner from '@/components/general/mazSpinner/index.vue'
import mazTabNavigation from '@/components/general/mazTabNavigation/index.vue'
import mazTitle from '@/components/general/mazTitle/index.vue'

export default Vue.extend({
  name: 'maz-employee-page',
  components: {
    mazEmployeeClientsOverview,
    mazEmployeeDetails,
    mazEmployeePermissions,
    mazSpinner,
    mazTabNavigation,
    mazTitle
  },
  data() {
    return {
      employeeDetails: true
    }
  },
  computed: {
    /**
     * STORE GETTERS
     */
    id(): string {
      return store.getters.employeeMaintenance.ID
    },
    loader(): any {
      return store.getters.employeeMaintenance.LOADER
    },
    tabNavigation(): number {
      return store.getters.employeeMaintenance.TAB_NAVIGATION
    },
    employeeName(): any {
      return store.getters.employeeMaintenance.EMPLOYEE_NAME
    },
    permissions(): MazInterfaces.EmployeeMaintenance.EmployeePermission {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.PERMISSIONS
    },
    isNotFound(): boolean {
      return store.getters.employeeMaintenance.IS_NOT_FOUND
    }
  },
  methods: {
    getEmployeeMaintenanceInitialData(id: string): void {
      store.dispatch.employeeMaintenance.GET_EMPLOYEE_MAINTENANCE_INITIAL_DATA(id)
    },
    updateEmployeeMaintenanceTabIndex(index: number) {
      store.dispatch.employeeMaintenance.UPDATE_EMPLOYEE_MAINTENANCE_TAB_INDEX(index)
    }
  },

  created() {
    this.getEmployeeMaintenanceInitialData(this.id)
  }
})
