import clientMaintenanceApi from '@/api/modules/clientMaintenanceApi'
import config from '@/api/modules/config'
import axiosService from '@/services/modules/axiosService'
import clientMaintenanceService from '@/services/modules/clientMaintenanceService'
import helpersService from '@/services/modules/helpersService'
import { difference } from 'lodash'
import toastr from 'toastr'
import { currentActionContext as organizationActionContext } from './index'

const _portController = 'ClientMaintenanceEmployeeJson'
const _baseEndpoint = config.endpointBase + _portController + '/'
const _targetApi = clientMaintenanceApi

export const actions = {
  GET_CLIENT_MAINTENANCE_INITIAL_DATA({ dispatch }: any, payload: string) {
    dispatch('GET_CLIENT_MAINTENANCE_DETAILS', payload)
  },

  //TODO: get client details data action
  GET_CLIENT_MAINTENANCE_DETAILS(ctx: any, payload: string) {
    const { commit, state } = organizationActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getClientMaintenanceDetails,
      '{clientId}',
      payload
    )
    commit.UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION({
      global: true
    })
    axiosService.get(endpoint, (res: any) => {
      if (res) {
        const body = helpersService.convertObjectKeysToCamelCase(res)

        commit.UPDATE_CLIENT_MAINTENANCE_CLIENT_DETAILS_MUTATION(body)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION({
        global: false
      })
    })
  },

  GET_CLIENT_MAINTENANCE_PENDING_ACTIONS(
    ctx: any,
    payload: {
      clientId: string
      pageNumber: number
    }
  ) {
    const { commit, state } = organizationActionContext(ctx)

    let endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getClientMaintenancePendingActions
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{clientId}',
      payload.clientId
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{pageNumber}',
      payload.pageNumber
    )
    commit.UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION({
      pendingActions: true
    })
    axiosService.get(endpoint, (res: any) => {
      if (res) {
        commit.UPDATE_CLIENT_MAINTENANCE_PENDING_ACTIONS_TABLE_MUTATION(res)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION({
        pendingActions: false
      })
    })
  },

  GET_ORGANIZATION_CONTACT_PERMISSIONS(ctx: any, payload: { clientId: string }) {
    const { commit, state } = organizationActionContext(ctx)

    let endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getClientMaintenanceOrganizationContactsPermissions
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{organizationId}',
      payload.clientId
    )
    commit.UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION({
      organizationContactPermissions: true
    })
    axiosService.get(endpoint, (res: any) => {
      if (res) {
        const changedContacts = {
          ...res,
          Contacts: res.Contacts.map((contact: any) =>
            clientMaintenanceService.processPermissions(contact)
          )
        }
        commit.SET_ADD_CONTACT_URL(res.ConnectUrl)
        commit.UPDATE_CLIENT_MAINTENANCE_ORGANIZATION_CONTACT_PERMISSIONS_MUTATION(
          changedContacts
        )
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      state.contactsModal.selectedContactsList = [] // TODO: create mutation.
      commit.UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION({
        organizationContactPermissions: false
      })
    })
  },

  GET_CLIENT_MAINTENANCE_COMPLETED_ACTIONS(
    ctx: any,
    payload: {
      clientId: string
      pageNumber: number
    }
  ) {
    const { commit, state } = organizationActionContext(ctx)

    let endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getClientMaintenanceCompletedActions
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{clientId}',
      payload.clientId
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{pageNumber}',
      payload.pageNumber
    )
    commit.UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION({
      completedActions: true
    })

    axiosService.get(endpoint, (res: any) => {
      if (res) {
        commit.UPDATE_CLIENT_MAINTENANCE_COMPLETED_ACTIONS_TABLE_MUTATION(res)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION({
        completedActions: false
      })
    })
  },

  GET_CLIENT_MAINTENANCE_CLIENT_SERVICES(ctx: any, payload: { clientId: string }) {
    const { commit, state } = organizationActionContext(ctx)
    let endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getClientMaintenanceClientServices
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{clientId}',
      payload.clientId
    )
    commit.organizationServices.UPDATE_LOADER_MUTATION({
      organizationServices: true
    })
    axiosService.get(endpoint, (res: any) => {
      if (res) {
        commit.UPDATE_CLIENT_MAINTENANCE_CLIENT_SERVICES_TABLE_MUTATION(res)
        commit.organizationServices.UPDATE_ORGANIZATION_SERVICES_MUTATION(res)
        commit.SET_CONNECT_URL(res.ConnectUrl)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.organizationServices.UPDATE_LOADER_MUTATION({
        organizationServices: false
      })
    })
  },

  GET_CLIENT_MAINTENANCE_CLIENT_PERMISSIONS_MODAL({ commit }: any, payload: object) {
    commit('UPDATE_CLIENT_MAINTENANCE_CLIENT_PERMISSIONS_MODAL_MUTATION', payload)
  },

  UPDATE_CLIENT_MAINTENANCE_TAB_INDEX({ commit }: any, payload: number) {
    commit('UPDATE_CLIENT_MAINTENANCE_TAB_INDEX_MUTATION', payload)
  },

  UPDATE_CLIENT_MAINTENANCE_PENDING_ACTIONS_CURRENT_PAGE(
    { commit }: any,
    payload: number
  ) {
    commit('UPDATE_CLIENT_MAINTENANCE_PENDING_ACTIONS_CURRENT_PAGE_MUTATION', payload)
  },

  UPDATE_CLIENT_MAINTENANCE_COMPLETED_ACTIONS_CURRENT_PAGE(
    { commit }: any,
    payload: number
  ) {
    commit('UPDATE_CLIENT_MAINTENANCE_COMPLETED_ACTIONS_CURRENT_PAGE_MUTATION', payload)
  },

  UPDATE_CLIENT_MAINTENANCE_ACTIVE_COLLAPSIBLE_PANELS({ commit }: any, payload: object) {
    commit('UPDATE_CLIENT_MAINTENANCE_ACTIVE_COLLAPSIBLE_PANELS_MUTATION', payload)
  },

  UPDATE_CLIENT_MAINTENANCE_CONTACT_PERMISSION(
    { commit, state }: any,
    payload: MazInterfaces.ClientMaintenance.ContactPermission
  ) {
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.updateClientMaintenanceOrganizationContactsPermission
    )
    axiosService.post(endpoint, payload, (res: any) => {
      if (res) {
        commit('UPDATE_CLIENT_MAINTENANCE_CONTACT_PERMISSION_MUTATION', payload)
        toastr.success(state.messages.changesSuccessfullySaved)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
    })
  },

  UPDATE_CONTACTS_LIST(ctx: any, payload: any) {
    const { commit } = organizationActionContext(ctx)
    commit.UPDATE_CONTACTS_LIST_MUTATION(payload)
  },

  UPDATE_CLIENT_MAINTENANCE_EMPLOYEES_LIST({ commit }: any, payload: any) {
    commit('UPDATE_CLIENT_MAINTENANCE_EMPLOYEES_LIST_MUTATION', payload)
  },

  //TODO: once feature is complete define the correct types
  UPDATE_ORGANIZATION_CONTACTS(ctx: any, payload: any) {
    const { commit, dispatch, state } = organizationActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.assignContactToOrganization
    )
    axiosService.post(endpoint, payload, (res: any) => {
      if (res.HasSucceeded) {
        const tempPayload = { clientId: payload.OrganizationId }
        toastr.success(state.messages.changesSuccessfullySaved)
        dispatch.GET_ORGANIZATION_CONTACT_PERMISSIONS(tempPayload)
        commit.RESET_SELECTED_CONTACTS_MUTATION()
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
    })
  },

  //TODO: once feature is complete define the correct types
  UPDATE_CLIENT_MAINTENANCE_CONNECTED_EMPLOYEES({ dispatch, state }: any, payload: any) {
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.connectEmployeesToOrganization
    )
    axiosService.post(endpoint, payload, (res: any) => {
      if (res.HasSucceeded) {
        toastr.success(state.messages.changesSuccessfullySaved)
        dispatch('GET_CLIENT_MAINTENANCE_CONNECTABLE_EMPLOYEES')
        dispatch('GET_CLIENT_MAINTENANCE_EMPLOYEES', { clientId: state.clientDetails.id })
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
    })
  },

  DELETE_CLIENT_MAINTENANCE_ORGANIZATION_CONTACT(
    ctx: any,
    payload: MazInterfaces.ClientMaintenance.OrganizationContactRelation
  ) {
    const { state, dispatch } = organizationActionContext(ctx)
    let endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.deassignContactFromOrganization
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{ContactId}',
      payload.ContactId
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{OrganizationId}',
      payload.OrganizationId
    )
    axiosService.post(endpoint, payload, (res: any) => {
      if (res) {
        const tempPayload = { clientId: payload.OrganizationId }
        dispatch.GET_ORGANIZATION_CONTACT_PERMISSIONS(tempPayload)
        toastr.success(state.messages.changesSuccessfullySaved)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
    })
  },

  DISCONNECT_CLIENT_MAINTENANCE_EMPLOYEE_FROM_ORGANIZATION(
    { dispatch, state }: any,
    payload: any
  ) {
    let endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.disconnectEmployeeFromOrganization
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{EmployeeId}',
      payload.EmployeeId
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{OrganizationId}',
      payload.OrganizationId
    )
    axiosService.post(endpoint, payload, (res: any) => {
      if (res.HasSucceeded) {
        toastr.success(state.messages.changesSuccessfullySaved)
        dispatch('GET_CLIENT_MAINTENANCE_CONNECTABLE_EMPLOYEES')
        dispatch('GET_CLIENT_MAINTENANCE_EMPLOYEES', { clientId: state.clientDetails.id })
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
    })
  },

  //TODO: once feature is complete define the correct types
  //TODO: update client details data action
  UPDATE_CLIENT_MAINTENANCE_CLIENT_DETAILS(
    ctx: any,
    payload: MazInterfaces.ClientMaintenance.OrganizationDetails
  ) {
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.updateClientMaintenanceOrganization
    )

    const { commit, state, dispatch } = organizationActionContext(ctx)
    commit.UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION({
      editOrganizationDetails: true
    })
    const body = helpersService.convertObjectKeysToPascalCase(payload)
    axiosService.post(endpoint, body, (res: any) => {
      if (res.HasSucceeded) {
        //TODO: See if there is a better way to update the client details
        //without re-fetching the details
        dispatch.GET_CLIENT_MAINTENANCE_DETAILS(payload.id)
        //TODO: See if there is a better way to reset the edit mode
        commit.UPDATE_CLIENT_MAINTENANCE_EDIT_MODE_MUTATION(false)
        toastr.success(state.messages.changesSuccessfullySaved)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }

      commit.UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION({
        editOrganizationDetails: false
      })
    })
  },

  UPDATE_CLIENT_MAINTENANCE_EDIT_MODE({ commit }: any, payload: boolean) {
    commit('UPDATE_CLIENT_MAINTENANCE_EDIT_MODE_MUTATION', payload)
  },

  RESET_CLIENT_MAINTENANCE_EDIT_CLIENT_DETAILS({ commit }: any) {
    commit('RESET_CLIENT_MAINTENANCE_EDIT_CLIENT_DETAILS_MUTATION')
  },

  UPDATE_AVAILABLE_CONNECTABLE_CONTACTS(ctx: any, payload: string) {
    const { commit, state } = organizationActionContext(ctx)
    let filteredList
    if (payload.trim().length == 0) {
      filteredList = difference(
        state.contactsModal.originalContactsList,
        state.contactsModal.selectedContactsList
      )
      commit.UPDATE_AVAILABLE_CONNECTABLE_CONTACTS_MUTATION(filteredList)
      return
    }
    filteredList = state.contactsModal.originalContactsList.filter((contact: any) => {
      return contact.FullName.trim()
        .toLowerCase()
        .includes(payload.trim().toLowerCase())
    })
    filteredList = difference(filteredList, state.contactsModal.selectedContactsList)

    commit.UPDATE_AVAILABLE_CONNECTABLE_CONTACTS_MUTATION(filteredList)
  },

  UPDATE_CLIENT_MAINTENANCE_AVAILABLE_CONNECTABLE_EMPLOYEES(
    { commit, state }: any,
    payload: string
  ) {
    let filteredList
    if (payload.trim().length == 0) {
      filteredList = difference(
        state.employeesModal.originalEmployeesList,
        state.employeesModal.selectedEmployeesList
      )
      commit(
        'UPDATE_CLIENT_MAINTENANCE_AVAILABLE_CONNECTABLE_EMPLOYEES_MUTATION',
        filteredList
      )
      return
    }
    filteredList = state.employeesModal.originalEmployeesList.filter((employee: any) => {
      return employee.FullName.trim()
        .toLowerCase()
        .includes(payload.trim().toLowerCase())
    })
    filteredList = difference(filteredList, state.employeesModal.selectedEmployeesList)
    commit(
      'UPDATE_CLIENT_MAINTENANCE_AVAILABLE_CONNECTABLE_EMPLOYEES_MUTATION',
      filteredList
    )
  },

  GET_CONNECTABLE_CONTACTS(ctx: any) {
    const { commit, state } = organizationActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getOrganizationContacts
    )

    axiosService.get(endpoint, (res: any) => {
      if (res) {
        // TODO: the array comes back as an object
        //const body = helpersService.convertObjectKeysToCamelCase(res)

        const unconnectedContacts: object[] = res.Contacts.filter((contact: any) => {
          const isUnconnectedContact =
            state.organizationContactPermissionsTable.rows.filter(
              (connectedContact: any) => connectedContact.Id === contact.Id
            ).length == 0

          return isUnconnectedContact
        })
        commit.SET_CONNECTABLE_CONTACTS_MUTATION(unconnectedContacts)
        commit.RESET_SELECTED_CONTACTS_MUTATION()
      }
    })
  },

  GET_CLIENT_MAINTENANCE_CONNECTABLE_EMPLOYEES({ commit, state }: any) {
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getClientMaintenanceConnectableEmployees
    )

    axiosService.get(endpoint, (res: any) => {
      if (res) {
        // TODO: the array comes back as an object
        //const body = helpersService.convertObjectKeysToCamelCase(res)

        const unconnectedEmployees: object[] = res.Employees.filter((employee: any) => {
          const isUnconnectedEmployee =
            state.employeesConnectedToOrganization.filter(
              (connectedEmployee: any) => connectedEmployee.Id === employee.Id
            ).length == 0

          return isUnconnectedEmployee
        })
        commit(
          'SET_CLIENT_MAINTENANCE_CONNECTABLE_EMPLOYEES_MUTATION',
          unconnectedEmployees
        )
        commit('RESET_CLIENT_MAINTENANCE_SELECTED_EMPLOYEES_MUTATION')
      }
    })
  },

  UPDATE_CLIENT_MAINTENANCE_ORGANIZATION_SERVICE(ctx: any, payload: any) {
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.connectServicesToOrganization
    )
    const { commit, state, dispatch } = organizationActionContext(ctx)
    commit.organizationServices.UPDATE_LOADER_MUTATION({
      organizationServices: true
    })
    const body = helpersService.convertObjectKeysToPascalCase(payload)
    const getServiceBody = {
      clientId: body.OrganizationId
    }
    axiosService.post(endpoint, payload, (res: any) => {
      if (res.HasSucceeded) {
        dispatch.GET_CLIENT_MAINTENANCE_CLIENT_SERVICES(getServiceBody)
        commit.organizationServices.UPDATE_ORGANIZATION_SERVICES_EDIT_MODE_MUTATION(false)
        toastr.success(state.messages.changesSuccessfullySaved)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.organizationServices.UPDATE_LOADER_MUTATION({
        organizationServices: false
      })
    })
  },

  UPDATE_ORGANIZATION_IS_SPECIAL(ctx: any, payload: any) {
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.updateOrganizationIsSpecial
    )
    const { state } = organizationActionContext(ctx)
    axiosService.post(endpoint, payload, (res: any) => {
      if (res.HasSucceeded) {
        //TODO: See if there is a better way to update the client details
        //without re-fetching the details
        ctx.dispatch(
          'clientMaintenance/GET_CLIENT_MAINTENANCE_DETAILS', //foreign store.
          payload.Id,
          { root: true }
        )
        toastr.success(state.messages.changesSuccessfullySaved)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
    })
  },

  GET_REFERENCE_DATA(ctx: any) {
    const { commit, state } = organizationActionContext(ctx)
    if (!state.relationManagers.length) {
      const endpoint = helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getCustomerReferenceData
      )
      axiosService.get(endpoint, (res: any) => {
        if (res) {
          const body = helpersService.convertObjectKeysToCamelCase(res)
          commit.SET_RELATION_MANAGERS_MUTATION(body.relationManagers)
          commit.SET_COUNTRIES_MUTATION(body.countries)
        } else {
          toastr.error(state.messages.somethingWentWrong)
        }
      })
    }
  },

  GET_CLIENT_MAINTENANCE_EMPLOYEES(ctx: any, payload: { clientId: string }) {
    const { commit, state } = organizationActionContext(ctx)
    let endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getClientMaintenanceEmployees
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{clientId}',
      payload.clientId
    )
    commit.UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION({
      employees: true
    })
    axiosService.get(endpoint, (res: any) => {
      if (res) {
        const employees = res.Employees
        commit.UPDATE_CLIENT_MAINTENANCE_EMPLOYEES_MUTATION(employees)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION({
        employees: false
      })
    })
  }
}
