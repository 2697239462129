import dictionaryService from '@/services/modules/dictionaryService'
export const localization = {
  messages: {
    changesSuccessfullySaved: dictionaryService.translate(
      `EmployeeOrganizationOverview.Messages.ChangesSuccessfullySaved`
    ) as string,
    somethingWentWrong: dictionaryService.translate(
      `EmployeeOrganizationOverview.Messages.SomethingWentWrong`
    ) as string
  }
}
