import dictionaryService from '@/services/modules/dictionaryService'

export const localization = {
  messages: {
    somethingWentWrong: dictionaryService.translate(
      `EmployeeOrganizationOverview.Messages.SomethingWentWrong`
    ) as string,
    notFoundTitle: dictionaryService.translate(
      'EmployeeOrganizationOverview.Messages.NotFoundTitle'
    ) as string,
    notFoundText: dictionaryService.translate(
      'EmployeeOrganizationOverview.Messages.NotFoundText'
    ) as string
  }
}
