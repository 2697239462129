export const mutations = {
  UPDATE_CLIENT_MAINTENANCE_OVERVIEW_LOADER_MUTATION(state: any, payload: boolean) {
    state.loader = payload
  },
  GET_CLIENT_MAINTENANCE_OVERVIEW_CLIENT_LIST_MUTATION(state: any, payload: object[]) {
    state.clientList.rows = payload
  },
  UPDATE_CLIENT_MAINTENANCE_OVERVIEW_SEARCH_CLIENT_LIST_MUTATION(
    state: any,
    payload: object[]
  ) {
    state.searchClientList = payload
  },
  SET_CLIENT_MAINTENANCE_OVERVIEW_CURRENT_PAGE_MUTATION(state: any, payload: number) {
    state.currentPage = payload
  },
  SET_CLIENT_MAINTENANCE_OVERVIEW_MAX_PAGE_COUNTER_MUTATION(state: any, payload: number) {
    state.maxPageCounter = payload
  },
  SET_CLIENT_MAINTENANCE_CUSTOMER_PERMISSION_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.CustomerPermission
  ) {
    state.customerPermission = payload
  },
  SET_CLIENT_MAINTENANCE_ADD_URL(
    state: any,
    payload: string,
  ) {
    state.addUrl = payload
  },
}
