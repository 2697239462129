
import store from '@/store'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

//Required components
import mazSupplyRequestHeader from '@/components/custom/mazSupplyRequestHeader/index.vue'
import mazSupplyRequestDetails from '@/components/custom/mazSupplyRequestDetails/index.vue'
import mazSupplyRequestDescription from '@/components/custom/mazSupplyRequestDescription/index.vue'
import mazSupplyRequestViewCategoryManager from '@/components/custom/mazSupplyRequestViewCategoryManager/index.vue'
import mazSupplyRequestActionButtons from '@/components/custom/mazSupplyRequestActionButtons/index.vue'
import mazSpinner from '@/components/general/mazSpinner/index.vue'
import mazSupplyRequestAccordions from '@/components/custom/mazSupplyRequestAccordions/index.vue'

const detailModule = namespace('supplyRequest2DetailStore')

@Component({
  store: store,
  components: {
    mazSupplyRequestHeader,
    mazSupplyRequestDetails,
    mazSupplyRequestDescription,
    mazSupplyRequestActionButtons,
    mazSupplyRequestViewCategoryManager,
    mazSpinner,
    mazSupplyRequestAccordions
  }
})
export default class SupplyRequest2Detail extends Vue {
  @detailModule.Getter('ID') id!: string
  @detailModule.Getter('EXISTS') exists!: boolean
  @detailModule.Getter('NOT_FOUND_MESSAGE') notFoundMessage!: string
  @detailModule.Getter('LABELS') labels!: any
  @detailModule.Getter('SUPPLY_REQUEST_HEADER') supplyRequestHeader!: string
  @detailModule.Getter('SUPPLY_REQUEST_AUDIT') supplyRequestAudit!: string
  @detailModule.Getter('SUPPLY_REQUEST_DESCRIPTION')
  supplyRequestDescription!: string
  @detailModule.Getter('SUPPLY_REQUEST_PERMISSIONS')
  supplyRequestPermissions!: any
  @detailModule.Getter('SUPPLY_REQUEST_CATEGORIES')
  supplyRequestCategories!: any
  @detailModule.Getter('SUPPLY_REQUEST_DELEGATION_OVERVIEW')
  supplyRequestDelegationOverview!: any
  @detailModule.Getter('SUPPLY_REQUEST_UPDATED_SEGMENTS_OVERVIEW')
  supplyRequestUpdatedSegmentsOverview!: any
  @detailModule.Getter('SUPPLY_REQUEST_CUSTOMERS') supplyRequestCustomers!: any

  @detailModule.Action('GET_SUPPLY_REQUEST') getSupplyRequest!: (id: string) => void

  mounted() {
    this.getSupplyRequest(this.id)
  }
}
