import config from '@/api/modules/config'
import employeeMaintenanceApi from '@/api/modules/employeeMaintenanceApi'
import axiosService from '@/services/modules/axiosService'
import helpersService from '@/services/modules/helpersService'
import toastr from 'toastr'
import { currentActionContext as employeeActionContext } from './index'

const _targetApi = employeeMaintenanceApi
const _portController = 'EmployeeEmployeeJson'
const _baseEndpoint = config.endpointBase + _portController + '/'

export const actions = {
  GET_EMPLOYEE_MAINTENANCE_INITIAL_DATA({ dispatch }: any, payload: string) {
    dispatch('GET_EMPLOYEE_MAINTENANCE_DETAILS', payload)
  },
  GET_EMPLOYEE_MAINTENANCE_DETAILS(ctx: any, payload: string) {
    const { commit, state } = employeeActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getEmployeeMaintenanceDetails,
      '{employeeId}',
      payload
    )
    commit.UPDATE_EMPLOYEE_MAINTENANCE_LOADER_MUTATION({
      global: true
    })
    axiosService.get(endpoint, (res: any) => {
      if (res) {
        const body = helpersService.convertObjectKeysToCamelCase(res)
        if (body.type === 30) {
          //type 30: employee
          commit.SET_EMPLOYEE_NAME_MUTATION(body.employeeDetail.fullNameStandardized)
          commit.employeeMaintenanceDetails.UPDATE_EMPLOYEE_MAINTENANCE_DETAILS_MUTATION(
            body
          )
        } else if (body.type === 0) {
          commit.SET_EMPLOYEE_IS_NOT_FOUND_MUTATION()
        } else {
          toastr.error(state.messages.somethingWentWrong)
        }
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.UPDATE_EMPLOYEE_MAINTENANCE_LOADER_MUTATION({
        global: false
      })
    })
  },
  UPDATE_EMPLOYEE_MAINTENANCE_TAB_INDEX({ commit }: any, payload: number) {
    commit('UPDATE_EMPLOYEE_MAINTENANCE_TAB_INDEX_MUTATION', payload)
  }
}
