import dictionaryService from '@/services/modules/dictionaryService'

function generateTemplates(data) {
  const result = []

  if (data.RequestTemplates) {
    data.RequestTemplates.forEach(template => {
      result.push({
        id: template.Id,
        title: template.Title
      })
    })

    return result
  } else {
    return 0
  }
}

function generateSegmentListObjects(data) {
  const result = []

  if (data.SegmentItems === null || data.SegmentItems === undefined) {
    return []
  } else {
    data.SegmentItems.forEach(listItem => {
      result.push({
        id: listItem.Id,
        title: listItem.Title
      })
    })
  }

  return result
}

function generateSegmentsObjects(data) {
  const result = []

  if (data.Segments === null || data.Segments === undefined) {
    return []
  } else {
    data.Segments.forEach(segment => {
      result.push({
        id: segment.Id,
        title: segment.Title,
        description: segment.Description,
        segmentItems: generateSegmentListObjects(segment)
      })
    })
  }

  return result
}

function generateSubCategoriesObjects(data) {
  const result = []

  if (data.GroupSegments === null || data.GroupSegments === undefined) {
    return []
  } else {
    data.GroupSegments.forEach(subCategory => {
      result.push({
        title: subCategory.Title,
        id: subCategory.Id,
        segments: generateSegmentsObjects(subCategory)
      })
    })
  }

  return result
}

function generateCategoriesObjects(data) {
  const result = []

  data.forEach(category => {
    result.push({
      id: category.Id,
      title: category.Title,
      shortTitle: category.ShortTitle,
      groupSegments: generateSubCategoriesObjects(category)
    })
  })

  return result
}

function generateServicesObjects(data) {
  const result = []

  data.forEach(service => {
    result.push({
      id: service.Id,
      referenceId: service.ReferenceId,
      name: service.Name,
      code: service.Code
    })
  })

  return result
}

function generateCountriesObjects(data) {
  const result = []
  data.forEach(country => {
    result.push({
      value: country.IsoCode3,
      name: dictionaryService.translate(`Labels.Country.${country.IsoCode3}`)
    })
  })

  return result.sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1)) //compare functions sorts alphabetically ascending.
}

function generatePhasesObjects(data) {
  const result = []

  if (data.Phases) {
    data.Phases.forEach(phase => {
      result.push({
        id: phase.Id,
        name: phase.Name,
        code: phase.Code
      })
    })

    return result
  } else {
    return 0
  }
}

function generateLanguagesObjects(data) {
  const result = []

  if (data.Languages) {
    data.Languages.forEach(language => {
      result.push({
        name: language.Name,
        code: language.Code
      })
    })

    return result
  }
}

const Service = {
  processTemplates(payload) {
    return generateTemplates(payload)
  },

  processTemplateMaintenanceInitialData(services, payload) {
    const result = services

    result.pageTitle = payload.PageTitle
    result.services = generateServicesObjects(payload.Services)
    result.countries = generateCountriesObjects(payload.Countries) //countries which the logged in admin can add / remove to / from templates.
    result.permissions = payload.Permissions

    return result
  },

  processTemplateMaintenancePhases(payload) {
    return generatePhasesObjects(payload)
  },

  processTemplateMaintenanceLanguages(payload) {
    return generateLanguagesObjects(payload)
  },

  processCategories(payload) {
    if (payload.Categories !== null) {
      return generateCategoriesObjects(payload.Categories)
    } else {
      return []
    }
  }
}

export default Service
