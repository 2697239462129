import clientMaintenanceOverviewApi from '@/api/modules/clientMaintenanceOverviewApi'
import config from '@/api/modules/config'
import axiosService from '@/services/modules/axiosService'
import helpersService from '@/services/modules/helpersService'
import { currentActionContext } from './index' //direct-vuex helper of local module action context

const _portController = 'ClientMaintenanceEmployeeJson'
const _baseEndpoint = config.endpointBase + _portController + '/'
const _targetApi = clientMaintenanceOverviewApi

export const actions = {
  GET_CLIENT_MAINTENANCE_OVERVIEW_CLIENT_LIST(
    ctx: any,
    payload: MazInterfaces.ClientMaintenanceOverview.GetClients
  ) {
    const { commit } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getOverviewList
    )
    const body = helpersService.convertObjectKeysToPascalCase(payload)
    commit.UPDATE_CLIENT_MAINTENANCE_OVERVIEW_LOADER_MUTATION(true)

    axiosService.post(endpoint, body, (res: any) => {
      if (res) {
        const resBody = helpersService.convertObjectKeysToCamelCase(res)
        commit.SET_CLIENT_MAINTENANCE_OVERVIEW_CURRENT_PAGE_MUTATION(resBody.currentPage)
        commit.SET_CLIENT_MAINTENANCE_OVERVIEW_MAX_PAGE_COUNTER_MUTATION(
          resBody.pageCount
        )

        commit.SET_CLIENT_MAINTENANCE_ADD_URL(res.AddUrl)

        commit.GET_CLIENT_MAINTENANCE_OVERVIEW_CLIENT_LIST_MUTATION(resBody.customers)
        commit.SET_CLIENT_MAINTENANCE_CUSTOMER_PERMISSION_MUTATION(
          resBody.customerPermission
        )
      }
      commit.UPDATE_CLIENT_MAINTENANCE_OVERVIEW_LOADER_MUTATION(false)
    })
  },
  SEARCH_CLIENT_MAINTENANCE_OVERVIEW_CLIENTS(
    ctx: any,
    payload: MazInterfaces.ClientMaintenanceOverview.ClientSearchTerm
  ) {
    const { commit, dispatch } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.searchClients
    )
    const body = helpersService.convertObjectKeysToPascalCase(payload)

    axiosService.post(endpoint, body, (res: any) => {
      if (res) {
        const resBody = helpersService.convertObjectKeysToCamelCase(res)
        commit.UPDATE_CLIENT_MAINTENANCE_OVERVIEW_SEARCH_CLIENT_LIST_MUTATION(
          resBody.results
        )
        //Reset pagination
        dispatch.UPDATE_CLIENT_MAINTENANCE_OVERVIEW_CURRENT_PAGE(1)
      }
    })
  },
  UPDATE_CLIENT_MAINTENANCE_OVERVIEW_CURRENT_PAGE(ctx: any, payload: number) {
    const { commit } = currentActionContext(ctx)
    commit.SET_CLIENT_MAINTENANCE_OVERVIEW_CURRENT_PAGE_MUTATION(payload)
  }
}
