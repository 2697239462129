import '@/styling/index.scss'
import 'bootstrap'
import 'jquery'
import 'popper.js'
import toastr from 'toastr'
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import { registerGenerals } from './general'
import { registerPages } from './pages'
import { registerFontAwesome } from './plugins/FontAwesome'

toastr.options.progressBar = true

Vue.use(VueScrollTo)

registerGenerals()
registerFontAwesome()
registerPages()
