<template>
  <div>
    <maz-supply-request-edit-header
      :showEditFields="showEditFields"
      @onEditClick="onEditClick"
    />

    <div class="o-separator o-separator--medium" />

    <div class="d-lg-flex">
      <div class="text-nowrap pr-5 mb-3">
        <maz-audit-info
          :audit_due_date="supplyRequestAudit.dueDate"
          :audit_due_date_text="supplyRequestAudit.dueDateLabel"
          :audit_finacial_year="supplyRequestAudit.financialYear"
          :audit_type="supplyRequestAudit.type"
        />
      </div>
      <div class="pr-5">
        <maz-assign-contacts
          :labels="labels"
          :store="store"
          :supplyRequestAssignContacts="supplyRequestAssignContacts"
          @assignedContactsOnInitialLoad="getAssignContactsList"
          @onSubmitAssignedContacts="setAssignedContacts"
          ref="assignContacts"
        />
      </div>
    </div>

    <div class="o-separator o-separator--medium" />

    <div class="c-intro-text qa-intro-text">
      <div class="d-md-flex">
        <p
          :class="[
            'pr-md-5 flex-fill u-pre-wrap',
            { 'is-cutoff': !isExpanded },
            { 'c-intro-text--is-cutoff': hasMultipleLines && !isExpanded }
          ]"
          ref="descriptionTxt"
        >
          {{ supplyRequestDescription.description }}
        </p>
        <a
          :class="['e-link-show-more c-intro-text__toggle', { collapsed: !isExpanded }]"
          @click="toggleDescription"
          v-if="hasMultipleLines"
        >
          <span class="e-link-bold" v-if="!isExpanded">{{ labels.hideLabel }}</span>
          <span class="e-link-bold" v-else>{{ labels.showLabel }}</span>
          <span class="e-icon-toggle" />
        </a>
      </div>
    </div>

    <div v-show="showEditFields">
      <div class="o-separator o-separator--medium" />
      <div class="row">
        <div class="col-md-6 col-xl-4">
          <div class="form-group">
            <label>{{ labels.supplyRequestEditTitle }}</label>
            <input class="form-control" ref="titleInput" v-model="titleInput" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>{{ labels.supplyRequestEditDescription }}</label>
            <textarea
              class="form-control c-textarea--height"
              ref="descriptionInput"
              v-model="descriptionInput"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group mb-0">
            <label>{{ labels.supplyRequestEditFinancialYear }}</label>
            <maz-drop-down
              :label="labels.supplyRequestEditFinancialYear"
              :options="supplyRequestFinancialYears.financialYears"
              :placeholder="labels.supplyRequestEditFinancialYear"
              @onChange="onChangeFinancialYearFilter"
              prop="value"
              propvalue="value"
              ref="financialYearFilter"
              data-cy="SupplyRequestEditFinancialYear"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mb-0">
            <label>{{ labels.supplyRequestEditDueDate }}</label>
            <maz-date-picker
              :placeholder="currentDueDate"
              :value="currentDueDate"
              @input="onDateSelected"
              @onDateSelected="onDateSelected"
              required
            />
          </div>
        </div>
      </div>

      <div class="o-separator o-separator--small" />

      <div class="d-flex flex-wrap flex-sm-nowrap float-sm-right">
        <maz-button
          :label="labels.cancel"
          @click.native="onCancelEdit"
          class="c-btn--empty"
        />
        <maz-button
          :disabled="isButtonDisabled"
          :label="labels.save"
          @click.native="onSaveEdit"
          class="c-btn--blue"
        />
      </div>
    </div>

    <div class="o-separator o-separator--small" />

    <div
      class="d-flex flex-wrap flex-sm-nowrap float-sm-right"
      v-if="!showEditFields && !showSendToClientFields"
    >
      <maz-button
        :label="labels.supplyRequestSendToClientButton"
        @click.native="onShowFieldsBeforeSendToClient"
        class="c-btn--green"
      />
    </div>

    <div
      class="o-separator o-separator--small"
      v-if="!showEditFields && !showSendToClientFields"
    />

    <div v-show="showSendToClientFields && !showEditFields">
      <div class="c-alert c-alert--red" v-show="checkLengthOfSelectedContacts">
        {{ labels.supplyRequestResponsibleUsersErrorMessage }}
      </div>
      <div
        class="o-separator o-separator--small"
        v-show="checkLengthOfSelectedContacts"
      />

      <div class="row">
        <div class="col-md-6">
          <div class="form-group mb-0">
            <label>{{ labels.supplyRequestEditFinancialYear }}</label>
            <maz-drop-down
              :label="labels.supplyRequestEditFinancialYear"
              :options="supplyRequestFinancialYears.financialYears"
              :placeholder="labels.supplyRequestEditFinancialYear"
              @onChange="onChangeFinancialYearFilter"
              prop="value"
              propvalue="value"
              ref="financialYearFilterSecond"
              data-cy="SupplyRequestEditFinancialYear"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mb-0">
            <label>{{ labels.supplyRequestEditDueDate }}</label>
            <maz-date-picker
              :placeholder="currentDueDate"
              :value="currentDueDate"
              @input="onDateSelected"
              @onDateSelected="onDateSelected"
              required
            />
          </div>
        </div>
      </div>

      <div class="o-separator o-separator--small" />

      <div class="d-flex flex-wrap flex-sm-nowrap float-sm-right">
        <maz-button
          :label="labels.cancel"
          @click.native="onCancelSendToClient"
          class="c-btn--empty"
        />
        <maz-button
          :disabled="checkLengthOfSelectedContacts || sendToClientButtonDisabled"
          :label="labels.supplyRequestSendToClientButton"
          @click.native="onSendToClient"
          class="c-btn--green"
        />
      </div>

      <div class="o-separator o-separator--small" />
    </div>

    <!-- Start of modal Send to client -->
    <div
      aria-hidden="true"
      class="modal fade"
      data-backdrop="static"
      data-keyboard="false"
      id="modalSendToClient"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog--auto-width"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">
              {{ labels.supplyRequestSendToClientSucceededTitle }}
            </h3>
            <button @click="navigateToClientDetailPage" class="close" type="button" />
          </div>
          <div class="modal-body modal-body--margin-bottom">
            <div class="row">
              <div class="col-12">
                {{ labels.supplyRequestSendToClientSucceeded }}
                <div class="o-separator o-separator--small" />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="float-sm-right d-flex flex-wrap flex-sm-nowrap">
                  <maz-button
                    :label="labels.supplyRequestSendToClientSucceededButton"
                    @click.native="navigateToClientDetailPage"
                    class="c-btn--blue order-1 order-sm-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of modal Send to client -->

    <maz-supply-request-edit-category-manager />
  </div>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import { mapGetters } from 'vuex'
import Services from '@/services'
import mazButton from '../../general/mazButton'
import mazSupplyRequestEditHeader from '../../custom/mazSupplyRequestEditHeader'
import mazSupplyRequestEditCategoryManager from '../mazSupplyRequestEditCategoryManager'
import mazAuditInfo from '../../general/mazAuditInfo'
import mazDatePicker from '../../general/mazDatePicker'
import mazDropDown from '../../general/mazDropDown'
import mazAssignContacts from '../../general/mazAssignContacts'

const _targetStore = 'supplyRequest2EditStore/'

export default {
  name: 'maz-supply-request-edit-initial',
  components: {
    mazDatePicker,
    mazButton,
    mazSupplyRequestEditHeader,
    mazSupplyRequestEditCategoryManager,
    mazAuditInfo,
    mazDropDown,
    mazAssignContacts
  },
  props: {
    supply_request_audit: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    details_audit_logs: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      showEditFields: false,
      showSendToClientFields: false,
      titleInput: '',
      descriptionInput: '',
      financialYearSelectedValue: '',
      financialYearFilter: '',
      currentDueDate: '',
      isExpanded: false,
      hasMultipleLines: false,
      showAssignedUsersAlert: false,
      buttonSendToClientDisabled: true,
      sendToClientButtonDisabled: false,
      store: 'supplyRequest2EditStore/',
      currentDate: moment().format('DD-MM-YYYY')
    }
  },
  computed: {
    ...mapGetters({
      supplyRequestId: _targetStore + 'ID',
      supplyRequestEditHeader: _targetStore + 'SUPPLY_REQUEST_EDIT_HEADER',
      supplyRequestCategories: _targetStore + 'SUPPLY_REQUEST_EDIT_CATEGORIES',
      supplyRequestAudit: _targetStore + 'SUPPLY_REQUEST_AUDIT',
      supplyRequestDescription: _targetStore + 'SUPPLY_REQUEST_DESCRIPTION',
      supplyRequestFinancialYears: _targetStore + 'SUPPLY_REQUEST_FINANCIAL_YEARS',
      editDetailsSaved: _targetStore + 'SUPPLY_REQUEST_EDIT_DETAILS_SAVED',
      sendToClient: _targetStore + 'SUPPLY_REQUEST_SEND_TO_CLIENT',
      sendToClientButtonDisabledStatus:
        _targetStore + 'SUPPLY_REQUEST_SEND_TO_CLIENT_BUTTON_DISABLED_STATUS',
      sendToClientRedirect: _targetStore + 'SUPPLY_REQUEST_SEND_TO_CLIENT_REDIRECT',
      supplyRequestAssignContacts: _targetStore + 'SUPPLY_REQUEST_ASSIGN_CONTACTS',
      labels: _targetStore + 'LABELS'
    }),
    isButtonDisabled() {
      let result = true

      if (!this.titleInput == '' && !this.descriptionInput == '') {
        result = false
      }

      return result
    },
    checkLengthOfSelectedContacts() {
      let result = false

      if (this.supplyRequestAssignContacts.assignedContactsList.length > 0) {
        result = false
      } else {
        result = true
      }

      return result
    }
  },
  methods: {
    onCancelEdit() {
      this.showEditFields = false
    },
    onDateSelected(event) {
      this.currentDueDate = event
    },
    onSaveEdit() {
      const body = {
        supplyRequestId: this.supplyRequestId,
        title: this.titleInput,
        description: this.descriptionInput,
        financialYear: this.financialYearSelectedValue,
        dueDate: this.currentDueDate,
        activeCategoryId: this.supplyRequestCategories.activeCategoryId
      }

      this.$store.dispatch(_targetStore + 'POST_SUPPLY_REQUEST_DETAILS', body)
    },
    onEditClick() {
      this.showEditFields = true
      this.showSendToClientFields = false

      this.titleInput = this.supplyRequestEditHeader.title
      this.descriptionInput = this.supplyRequestDescription.description
      this.financialYearSelectedValue = this.supplyRequestFinancialYears.financialYear
      this.currentDueDate = this.supplyRequestAudit.dueDate

      const preSelectFinancialYear = this.supplyRequestFinancialYears.financialYears.find(
        item => item.value === this.supplyRequestFinancialYears.financialYear
      )
      this.$refs.financialYearFilter.selectedItem = preSelectFinancialYear
    },
    onChangeFinancialYearFilter(value) {
      this.financialYearSelectedValue = value
    },
    onChangeFinancialYearFilterSecond(value) {
      this.financialYearSelectedValue = value
    },
    toggleDescription() {
      this.isExpanded = !this.isExpanded
    },
    onShowFieldsBeforeSendToClient() {
      this.showSendToClientFields = true

      this.financialYearSelectedValue = this.supplyRequestFinancialYears.financialYear

      const preSelectFinancialYear = this.supplyRequestFinancialYears.financialYears.find(
        item => item.value === this.supplyRequestFinancialYears.financialYear
      )
      this.$refs.financialYearFilterSecond.selectedItem = preSelectFinancialYear

      //TODO: Below check for the difference between the dates causing problem with the current due date. Thefefore, setting due date as default from supply request audit

      this.currentDueDate = this.supplyRequestAudit.dueDate

      // const originalSupplyRequestDate = moment(this.supplyRequestAudit.dueDateOriginal)
      // const todaysDate = moment()

      // const differenceBetweenDates = todaysDate.diff(originalSupplyRequestDate)

      // console.log(originalSupplyRequestDate)

      // if (differenceBetweenDates <= 0) {
      //   this.currentDueDate = this.supplyRequestAudit.dueDate
      // } else {
      //   this.currentDueDate = this.currentDate
      // }
    },
    onCancelSendToClient() {
      this.showSendToClientFields = false
    },
    onSendToClient() {
      this.sendToClientButtonDisabled = true

      const body = {
        supplyRequestId: this.supplyRequestId,
        financialYear: this.financialYearSelectedValue,
        dueDate: this.currentDueDate
      }

      this.$store.dispatch(_targetStore + 'POST_SEND_SUPPLY_REQUEST_TO_CLIENT', body)
    },
    navigateToClientDetailPage() {
      window.location.href = this.sendToClientRedirect
    },
    checkLineBreaksInDescription() {
      const descriptionTxt = this.$refs.descriptionTxt
      const getLineHeight = window
        .getComputedStyle(descriptionTxt)
        .getPropertyValue('line-height')

      const getScrollHeight = this.$refs.descriptionTxt.scrollHeight
      const isMobile = window.innerWidth <= 576
      let displayedLines

      const numberOfLineBreaks = getScrollHeight / parseInt(getLineHeight)

      if (isMobile) {
        displayedLines = 2
      } else {
        displayedLines = 3
      }

      if (numberOfLineBreaks > displayedLines) {
        this.hasMultipleLines = true
      } else {
        this.hasMultipleLines = false
      }
    },
    showAssignContactsModal() {
      $('#modalAssignContacts').modal()

      this.$refs.assignContacts.createCloneOfAssignedContacts()
    },
    getAssignContactsList() {
      const body = {
        supplyRequestId: this.supplyRequestId
      }

      this.$store.dispatch(_targetStore + 'GET_ASSIGN_CONTACTS', body)
    },
    setAssignedContacts() {
      const body = {
        supplyRequestId: this.supplyRequestId,
        assignContactsIds: Services.assignContactsService.processAssignedContactsListIds(
          this.supplyRequestAssignContacts.assignedContactsList
        )
      }

      this.$store.dispatch(_targetStore + 'POST_ASSIGN_CONTACTS', body)
    }
  },
  watch: {
    editDetailsSaved: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.showEditFields = false

          this.$store.dispatch(_targetStore + 'SET_DETAILS_SAVED', false)
        }
      },
      deep: true
    },
    sendToClient: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          $('#modalSendToClient').modal()
        }
      },
      deep: true
    },
    sendToClientButtonDisabledStatus: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.sendToClientButtonDisabled = false
        }
      },
      deep: true
    }
  },
  updated() {
    this.$nextTick(() => {
      this.checkLineBreaksInDescription()
    })
  },
  created() {
    window.addEventListener('resize', this.checkLineBreaksInDescription)
  },
  destroyed() {
    window.removeEventListener('resize', this.checkLineBreaksInDescription)
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-intro-text {
  .c-intro-text--is-cutoff {
    max-height: 66px;
    @include media-breakpoint-down(xs) {
      max-height: 48px;
    }
  }
}
</style>
