<template>
  <label :class="{ disabled: disabled }" class="c-checkbox">
    <span>
      <slot>{{ label }}</slot>
    </span>
    <input :checked="value" :disabled="disabled" @change="onChange" type="checkbox" />
    <span class="c-checkbox__checkmark" />
  </label>
</template>

<script>
export default {
  name: 'maz-checkbox',
  props: {
    label: {
      type: String
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange(event) {
      this.$emit('input', event.target.checked)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styling/custom/settings/_main.scss';

.c-checkbox__checkmark {
  position: absolute;
  top: calc(50% - 11px);
  left: 0;
  width: 22px;
  height: 22px;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  transition: border 0.2s, box-shadow 0.2s;
}

.c-checkbox {
  position: relative;
  display: block;
  margin-bottom: 12px;
  padding-left: 35px;
  font-size: 16px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    left: -9999px;
  }

  &.disabled {
    cursor: default;
    opacity: 0.4;
  }
}

.c-checkbox:hover input ~ .c-checkbox__checkmark {
  border: 1px solid $dark-blue;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.c-checkbox input:checked ~ .c-checkbox__checkmark {
  border: 1px solid $dark-blue;
}

.c-checkbox__checkmark:after {
  position: absolute;
  display: none;
  content: '';
}

.c-checkbox .c-checkbox__checkmark:after {
  top: 5px;
  left: 4px;
  width: 14px;
  height: 11px;
  background-image: url('~@/assets/images/icon_forms_selected.svg');
  background-repeat: no-repeat;
}

.c-checkbox input:checked ~ .c-checkbox__checkmark:after {
  display: block;
}
</style>
