export default {
  getTemplatesByServiceId: 'gettemplatesbyserviceid?serviceId={serviceId}',
  getTemplateData: 'gettemplate?supplyrequesttemplateid={templateId}',
  getTemplateMaintenanceInitialData: 'gettemplatemaintenanceinitialdata',
  getTemplateMaintenanceReferenceData:
    'gettemplatemaintenancereferencedata?serviceId={serviceId}',
  postTemplateMaintenanceCreateTemplate: 'createtemplate',
  postTemplateMaintenanceUpdateTemplate: 'updatetemplate',
  getTemplateMaintenanceDeleteTemplate: 'deletetemplate?srtid={templateId}'
}
