import mazButton from '@/components/general/mazButton/index.vue'
import mazCheckbox from '@/components/general/mazCheckbox/index.vue'
import mazCollapse from '@/components/general/mazCollapse/index.vue'
import mazConfirmationLightbox from '@/components/general/mazConfirmationLightbox/index.vue'
import mazDelegateUsersLightbox from '@/components/general/mazDelegateUsersLightbox/index.vue'
import mazModal from '@/components/general/mazModal/index.vue'
import mazNotifyUsersLightbox from '@/components/general/mazNotifyUsersLightbox/index.vue'
import mazRejectTaskLightbox from '@/components/general/mazRejectTaskLightbox/index.vue'
import mazTaskStatusCircle from '@/components/general/mazTaskStatusCircle/index.vue'
import mazWorkflowHistoryLightbox from '@/components/general/mazWorkflowHistoryLightbox/index.vue'
import dictionaryService from '@/services/modules/dictionaryService'
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'
import toastr from 'toastr'
import dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import pkiApi from '../../../services/modules/pkiSigningApi'

function getQueryString(queryKey) {
  const searchString = window.location.search.replace('?', '').split('&')
  for (let n = 0; n < searchString.length; n++) {
    const values = searchString[n].split('=')
    if (values[0] === queryKey && values.length > 1) {
      return values[1]
    }
  }
  return null
}

const config = {
  portalType: window.__INITIAL_DATA__.portalType
}

const Enums = {
  ScanStatus: {
    Pending: 0,
    Clean: 10,
    Infected: 90,

    properties: {
      0: {
        id: 0,
        name: 'Pending',
        translation: dictionaryService.translate('Common.Messages.ScanStatusPending')
      },
      10: {
        id: 10,
        name: 'Clean',
        translation: dictionaryService.translate('Common.Messages.ScanStatusClean')
      },
      90: {
        id: 90,
        name: 'Infected',
        translation: dictionaryService.translate('Common.Messages.ScanStatusInfected')
      }
    }
  }
}

const workflowId = getQueryString('wfid')
const taskId = getQueryString('wftid')

const portalController =
  config.portalType === 'Employee'
    ? 'ShareDocumentsEmployeeJson'
    : 'ShareDocumentsClientJson'

const apiEndpoints = {
  taskDetailsEndpoint:
    '/json/' +
    portalController +
    '/GetTaskDetail?workflowId=' +
    workflowId +
    '&taskId=' +
    taskId,
  acceptEndpoint: '/json/' + portalController + '/CompleteWorkflowTask',
  uploadFilesEndpoint: '/json/' + portalController + '/UploadFiles', // POST HTTP verb. Requires workflowId, taskId and file array
  uploadSigningFilesEndpoint: '/json/' + portalController + '/UploadSignFile', // POST HTTP verb. Requires workflowId, taskId and file array
  getAllFilesEndpoint:
    '/json/' + portalController + '/GetFileOverviewByWorkflowId?workflowId=' + workflowId,
  getSigningFilesEndpoint:
    '/json/' +
    portalController +
    '/GetSignFileOverviewByWorkflowId?workflowId=' +
    workflowId +
    '&taskId=' +
    taskId,
  deleteFilesEndpoint: '/json/' + portalController + '/DeleteFile?fileId={fileId}', // DELETE HTTP verb. Requires fileId (GUID).
  deleteSignFilesEndpoint:
    '/json/' + portalController + '/DeleteSignFile?fileId={fileId}', // DELETE HTTP verb. Requires fileId (GUID).
  addCommentEndpoint: '/json/' + portalController + '/AddWorkflowComment',
  startPkiAuthenticationEndpoint: '/json/PkiSigning/StartPkiAuthentication',
  startPdfPkiSigningEndpoint: '/json/PkiSigning/StartPdfPkiSigning',
  hasSigned:
    '/json/' +
    portalController +
    '/HasSigned?' +
    'workflowId=' +
    workflowId +
    '&taskId=' +
    taskId,
  downloadSignedFiles:
    '/json/' + portalController + '/DownloadSignedFiles?' + 'workflowId=' + workflowId
}

const dropzoneOptions = {
  url: apiEndpoints.uploadFilesEndpoint,
  params: {
    workflowId: workflowId,
    taskId: taskId
  },
  paramName: 'uploadedFiles',
  useFontAwesome: true,
  autoProcessQueue: true,
  uploadMultiple: true,
  duplicateCheck: true,
  addRemoveLinks: true,
  acceptedFiles: '',
  maxFilesize: 25,
  maxFiles: 100,
  language: {
    dictDefaultMessage: dictionaryService.translate(
      'DeclarationCocUpload.Labels.ChooseFile'
    ),
    dictMaxFilesExceeded: dictionaryService.translate(
      'DeclarationCocUpload.Labels.NumFilesExceeded'
    ),
    dictRemoveFile: dictionaryService.translate('DeclarationCocUpload.Labels.Remove')
  }
}
const dropzoneSigningOptions = {
  url: apiEndpoints.uploadSigningFilesEndpoint,
  params: {
    workflowId: workflowId,
    taskId: taskId
  },
  paramName: 'uploadedSignFiles',
  useFontAwesome: true,
  autoProcessQueue: true,
  uploadMultiple: true,
  duplicateCheck: true,
  addRemoveLinks: true,
  acceptedFiles: '',
  maxFilesize: 25,
  maxFiles: 1,
  language: {
    dictDefaultMessage: dictionaryService.translate(
      'DeclarationCocUpload.Labels.ChooseFile'
    ),
    dictMaxFilesExceeded: dictionaryService.translate(
      'DeclarationCocUpload.Labels.NumFilesExceeded'
    ),
    dictRemoveFile: dictionaryService.translate('DeclarationCocUpload.Labels.Remove')
  }
}
const dropzoneDocuSigningOptions = {
  url: apiEndpoints.uploadSigningFilesEndpoint,
  params: {
    workflowId: workflowId,
    taskId: taskId
  },
  paramName: 'uploadedSignFiles',
  useFontAwesome: true,
  autoProcessQueue: true,
  uploadMultiple: true,
  duplicateCheck: true,
  addRemoveLinks: true,
  acceptedFiles: 'application/pdf',
  maxFilesize: 25,
  maxFiles: 50,
  language: {
    dictDefaultMessage: dictionaryService.translate(
      'DeclarationCocUpload.Labels.ChooseFile'
    ),
    dictMaxFilesExceeded: dictionaryService.translate(
      'DeclarationCocUpload.Labels.NumFilesExceeded'
    ),
    dictRemoveFile: 'X' //dictionaryService.translate('DeclarationCocUpload.Labels.Remove')
  }
}

const taskStatusEnum = {
  inactive: 100,
  active: 200,
  completed: 300
}

const accessTokenCookieName = 'pki.at'

function getCookie(cookieKey) {
  let cookieValue
  const cookiesList = document.cookie.split(';')
  cookiesList.forEach(function(cookie) {
    const cKeyValue = cookie.split('=')

    if (cKeyValue[0].trim() === cookieKey) cookieValue = cKeyValue[1]
  })
  return cookieValue
}

function randomChars(size) {
  size = size || 36 // defaults to 36 chars
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < size; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length))

  return text
}

function isNullOrEmpty(property) {
  return !property || property.length === 0
}

export default {
  components: {
    mazCheckbox,
    mazModal,
    mazButton,
    mazCollapse,
    mazConfirmationLightbox,
    mazTaskStatusCircle,
    mazWorkflowHistoryLightbox,
    mazNotifyUsersLightbox,
    mazRejectTaskLightbox,
    mazDelegateUsersLightbox,
    dropzone
  },

  data() {
    return {
      taskStatus: taskStatusEnum,
      savingTask: false,
      savingComment: false,

      WorkflowId: workflowId,
      TaskId: taskId,
      Name: null,
      CustomerName: null,
      CustomerUrl: null,
      Oid: null,
      Cid: null,
      ServiceId: null,

      Frequency: null,
      FilingName: null,
      PeriodValue: null,
      Year: null,
      StartDate: null,
      ServiceName: null,
      Status: null,

      MayComplete: false,
      MayDelegate: false,
      MayNotify: false,
      MayReject: false,
      MayUploadFiles: false,
      MayUploadSignFile: false,
      MayAddComment: false,
      MaySign: false,

      MayPKISign: false,

      downloadFilesUrl:
        '/json/' + portalController + '/DownloadFiles?workflowId=' + workflowId,

      downloadSignedFilesUrl:
        '/json/' + portalController + '/DownloadSignedFiles?workflowId=' + workflowId,

      // DocuSign Properties
      MayDocuSign: false,
      DocuSignOption: false,
      DocuSignPageURL: null,
      DownloadDocuSignFiles: false,
      DocuSigningPreviousURL: null,
      DocuSigningSpinner: false,
      DocuSignCompleted: false,
      DocuSignUploadComplete: false,

      uploadFileSet: false,
      showError: false,

      assignedUsers: [],
      comments: null,
      tasks: [],
      workflowFiles: [],
      workflowSigningFiles: [],
      showTaskConfirmationModal: false,

      newComment: '',
      showNotFound: false,
      showDropzone: false,
      showSigningDropzone: false,
      showNewComment: false,
      showBadFileTypeMsg: false,
      showRejectLightBox: false,
      showDelegateLightBox: false,
      showNotifyLightBox: false,
      showWorkflowHistoryLightBox: false,
      canExportWorkflowHistory: false,
      workflowHistoryExportUrl: '',
      showConfirmFileDeleteLightBox: false,

      deleteFileMessage: '',
      deleteFileCallback: null,

      isSigningWorkflow: false,
      isUploadSigningStep: false,
      isSigningStep: false,
      isSigned: false,
      isInternalComment: false,

      // Dropzone Data
      dropzoneOptions: dropzoneOptions,
      dropzoneSigningOptions: dropzoneSigningOptions,
      dropzoneDocuSigningOptions: dropzoneDocuSigningOptions,

      // PKI sign
      Pki: {
        accessToken: null,
        sessionId: null,
        encryptionKey: null,
        closeSessionUrl: null,
        isAuthenticated: false,
        showSuccessSigning: false,
        showErrorSigning: false,
        isLoading: false,
        isSigned: false
      }
    }
  },
  mounted: function() {
    dropzoneOptions.acceptedFiles = $('#AllowedFileTypes').val()
    dropzoneSigningOptions.acceptedFiles = $('#AllowedFileTypesSigning').val()
    //dropzoneDocuSigningOptions.acceptedFiles = $('#AllowedFileTypesDocuSigning').val()

    dropzoneOptions.maxFilesize = $('#UploadMaxFileSizeInMB').val()
    dropzoneSigningOptions.maxFilesize = $('#UploadMaxFileSizeInMB').val()
    dropzoneDocuSigningOptions.maxFiles = $('#UploadMaxFileSizeInMB').val()

    dropzoneOptions.maxFiles = $('#UploadMaxNumberOfFiles').val() || 100 //Purposely high value to prevent uploads from being canceled due to file limit.
    dropzoneSigningOptions.maxFiles = $('#UploadMaxNumberOfFilesSigning').val() || 1
    dropzoneDocuSigningOptions.maxFiles =
      $('#UploadMaxNumberOfFilesDocuSigning').val() || 50

    if (
      this.WorkflowId &&
      this.WorkflowId.length > 0 &&
      this.TaskId &&
      this.TaskId.length > 0
    ) {
      this.getTaskDetails()
      this.getWorkflowFiles()

      // Get PKI related status
      this.checkForPkiSigningAuthentication()
    } else {
      this.showNotFound = true
    }
  },
  computed: {
    showDownloadAllButton: function() {
      return Boolean(
        this.workflowFiles.find(obj => obj.IsDownloadable === true) ||
          this.workflowSigningFiles.find(obj => obj.IsDownloadable === true)
      )

      //TODO: Show dowload all button differently for PKI and DocuSign.
      // if (this.MayPKISign) {
      //     const downloadAllButton =
      //         this.workflowFiles.find((obj) => obj.IsDownloadable === true) ||
      //         this.workflowSigningFiles.find((obj) => obj.IsDownloadable === true)
      //     return downloadAllButton
      // } else {
      //     return Boolean(
      //         this.workflowFiles.find((obj) => obj.IsDownloadable === true)
      //     )
      // }
    },
    activeTask: function() {
      if (this.loading) {
        return null
      } else {
        const currentTaskId = this.TaskId
        let filteredTask
        this.tasks.forEach(function(task) {
          if (task.Id === currentTaskId) {
            filteredTask = task
          }
        })
        return filteredTask
      }
    },
    loading: function() {
      return (this.Status === null && this.showNotFound === false) || this.savingTask
    },
    mayCompleteActiveTask: function() {
      const isDocuSignStep = this.MayDocuSign
      const mayCompleteTask = isDocuSignStep
        ? this.DocuSignUploadComplete
        : this.MayComplete &&
          ((this.isUploadSigningStep && this.workflowSignFilesExist) ||
            !this.isUploadSigningStep)

      return mayCompleteTask
      //|| (this.MayComplete && this.isSigningStep && this.IsSigned) // Signing step
      //|| this.MayComplete && !this.isSigningStep // Every other step
    },
    showDescription: function() {
      return (
        this.activeTask.Description !== null && this.activeTask.Description.trim() !== ''
      )
    },
    showPkiSuccessSigning: function() {
      return this.Pki.showSuccessSigning || this.Pki.isSigned
    },
    signingFilesPanelVisible: function() {
      return (
        this.workflowSigningFiles.length > 0 ||
        (this.workflowSigningFiles.length === 0 && this.MayUploadSignFile === true)
      )
    },
    signingFileInfected: function() {
      return (
        this.workflowSigningFiles.filter(
          workflowSigningFile => workflowSigningFile.isInfected === true
        ).length > 0 ||
        this.workflowFiles.filter(workflowFile => workflowFile.isInfected === true)
          .length > 0
      )
    },
    signingFilePending: function() {
      return (
        this.workflowSigningFiles.filter(
          workflowSigningFile =>
            workflowSigningFile.scanStatus ===
            Enums.ScanStatus.properties[Enums.ScanStatus.Pending].translation
        ).length > 0 ||
        this.workflowFiles.filter(
          workflowFile =>
            workflowFile.scanStatus ===
            Enums.ScanStatus.properties[Enums.ScanStatus.Pending].translation
        ).length > 0
      )
    },
    uploadSignButtonOptions: function() {
      const isDocuSignOption = this.DocuSignOption
      const uploadSignDocOptions = isDocuSignOption
        ? this.MayUploadSignFile
        : this.MayUploadSignFile && this.workflowSigningFiles.length === 0
      return uploadSignDocOptions
    },
    dropZoneSignOptions: function() {
      const isDocuSignOption = this.DocuSignOption
      const dropOptionType = isDocuSignOption
        ? this.dropzoneDocuSigningOptions
        : this.dropzoneSigningOptions
      return dropOptionType
    },
    workflowSignFilesExist: function() {
      return this.workflowSigningFiles.length > 0
    },
    workflowFilesExist: function() {
      return this.workflowSignFilesExist || this.workflowFiles.length > 0
    }
  },
  methods: {
    getTaskDetails() {
      //Turn variable so that loading computed property is turned
      this.Status = null
      axios
        .get(apiEndpoints.taskDetailsEndpoint)
        .then(response => {
          if (response.data) {
            // Set additional properties for tasks
            if (response.data.Workflow.Tasks) {
              response.data.Workflow.Tasks.forEach(function(task, index) {
                task.stepIndex = index + 1

                // Status related
                task.inactive = task.Status === taskStatusEnum.inactive
                task.active = task.Status === taskStatusEnum.active
                task.open = task.Status === taskStatusEnum.active
                task.complete = task.Status === taskStatusEnum.completed

                // Accept or reject in progress
                task.acceptInProgress = false
                task.rejectMessageOpen = false
                task.rejectInProgress = false

                const taskDueDateOffset = moment(task.DueDate).utcOffset() //gets offset
                task.DueDate = moment(task.DueDate).subtract(
                  //converts utc to local by adding the offset.
                  taskDueDateOffset,
                  'minutes'
                )
                task.dueDateDescription = task.DueDate.format('DD/MM/YYYY')
              })
            }

            // Set comments
            if (response.data.Comments === null) {
              response.data.Comments = []
            }
            response.data.Comments.forEach(function(comment) {
              const translation = dictionaryService.translate(
                'TaskDetail.Labels.CommentPlacedBy'
              )
              if (translation) {
                comment.infoDescription = translation
                  .replace('{0}', comment.TaskName || '') //TODO: Remove or replace when endpoint is correctly returning task name
                  .replace('{1}', comment.CreatedBy.FullName)
              }

              comment.dateDescription = moment(comment.Date).format('DD/MM/YYYY')
              comment.presentationDate = moment(comment.CreatedDate).format('DD/MM/YYYY')
            })
            this.comments = response.data.Comments

            this.WorkflowId = response.data.WorkflowId
            this.Name = response.data.FullTitle
            this.CustomerName = response.data.CompanyName
            this.CustomerUrl = response.data.CustomerUrl
            this.Oid = response.data.Workflow.OrganizationId
            this.Cid = response.data.Workflow.ContactId
            this.ServiceId = response.data.Workflow.ServiceId

            this.Frequency = response.data.Frequency
            this.FilingName = response.data.FilingName
            this.PeriodValue = response.data.PeriodValue
            this.Year = response.data.Year
            this.ServiceName = response.data.ServiceName
            this.Status = response.data.Status

            this.MayComplete = response.data.MayComplete
            this.MayDelegate = response.data.MayDelegate
            this.MayNotify = response.data.MayNotify
            this.MayReject = response.data.MayReject
            this.MayUploadFiles = response.data.MayUploadFiles
            this.MayUploadSignFile = response.data.MayUploadSignFile
            this.MayAddComment = response.data.MayAddComment

            this.MaySign = response.data.MaySign
            this.DocuSignOption = response.data.Signing_IsDocuSignSigning
            this.isSigningWorkflow = response.data.IsSigningWorkflow
            this.isUploadSigningStep =
              response.data.IsUploadSigningStep ||
              response.data.Title.toLowerCase() === 'uploadsignfile'
            this.isSigningStep =
              response.data.IsSigningStep || response.data.Title.toLowerCase() === 'sign'
            this.isSigned = response.data.IsSigned || false
            this.canExportWorkflowHistory = response.data.MayExportAuditInfo
            this.workflowHistoryExportUrl = response.data.ExportAuditInfoUrl

            this.tasks = response.data.Workflow.Tasks
            this.assignedUsers = response.data.AssignedUsers

            this.Pki.isSigned = response.data.IsSigned

            this.LastComment = response.data.LastComment

            if (this.LastComment !== null) {
              const translation = dictionaryService.translate(
                'TaskDetail.Labels.LastCommentPlacedOnBy'
              )
              if (translation) {
                this.LastComment.placedOnBy = translation
                  .replace(
                    '{0}',
                    moment(this.LastComment.CreatedDate).format('DD/MM/YYYY')
                  )
                  .replace('{1}', this.LastComment.CreatedBy.FullName)
              }
            }

            // Get workflow signing files
            if (this.isSigningWorkflow) {
              this.getWorkflowSigningFiles(this)

              // Trigger automatic signing when arriving at this route, if:
              // 1. User is authenticated
              // 2. User may sign
              // 3. The file is not yet signed
              // 4. The query string "trigger=sign" exists

              if (
                this.Pki.isAuthenticated &&
                this.MaySign &&
                !this.Pki.isSigned &&
                getQueryString('trigger') === 'sign'
              ) {
                this.doPkiSign()
              }
            }
          } else if (response.data === null) {
            this.showNotFound = true
          } else {
            this.showError = true
          }
        })
        .catch(e => {
          toastr.error(
            dictionaryService.translate('TaskDetail.Messages.ErrorFetchingTask'),
            e,
            true
          )
        })
    },
    getWorkflowFiles() {
      axios
        .get(apiEndpoints.getAllFilesEndpoint)
        .then(response => {
          this.showError = false
          if (response.data) {
            response.data.Files.forEach(function(file) {
              if (file.MimeType === 'application/pdf') {
                file.iconClass = 'file-pdf'
              } else if (
                file.MimeType ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              ) {
                file.iconClass = 'file-word'
              } else if (
                file.MimeType ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              ) {
                file.iconClass = 'file-excel'
              } else {
                file.iconClass = 'file'
              }

              file.CreatedDateDescription = moment(file.CreatedDate).format(
                'DD-MM-YYYY HH:mm'
              )
              file.isInfected = false
              file.MayDownload = file.IsDownloadable
              file.scanStatus =
                Enums.ScanStatus.properties[Enums.ScanStatus.Pending].translation
              file.scanDateDescription = ''

              if (file.Scan !== null) {
                file.isInfected = file.Scan.Status === Enums.ScanStatus.Infected
                file.scanStatus =
                  Enums.ScanStatus.properties[file.Scan.Status].translation
                file.scanDateDescription = ''

                //Generate date description if available
                if (file.Scan.ScannedDate !== null) {
                  file.scanDateDescription = moment(file.Scan.ScannedDate).format(
                    '(DD MMMM YYYY - HH:mm)'
                  )
                }
              }
            })

            this.workflowFiles = response.data.Files
          } else {
            this.showError = true
          }
        })
        .catch(e => {
          toastr.error(
            dictionaryService.translate('TaskDetail.Messages.ErrorFetchingFiles'),
            e,
            true
          )
        })
    },
    getWorkflowSigningFiles() {
      axios
        .get(apiEndpoints.getSigningFilesEndpoint)
        .then(response => {
          this.showError = false
          if (response.data && response.data.Files) {
            //Get DocuSign Page Details
            this.MayDocuSign = response.data.IsDocuSignSigning
            this.MayPKISign = !response.data.IsDocuSignSigning
            this.DownloadDocuSignFiles = response.data.HasDownloadableFiles
            this.DocuSignPageURL = response.data.DocuSignSigning_RecipientViewUrl
            this.DocuSignUploadComplete = response.data.MayComplete

            response.data.Files.forEach(function(file) {
              if (file.MimeType === 'application/pdf') {
                file.iconClass = 'file-pdf'
              } else {
                file.iconClass = 'file'
              }

              file.CreatedDateDescription = moment(file.CreatedDate).format(
                'DD-MM-YYYY HH:mm'
              )
              file.isInfected = false
              //file.MayDownload = this.MayDocuSign ? false : file.IsDownloadable
              file.MayDownload = file.IsDownloadable
              file.scanStatus =
                Enums.ScanStatus.properties[Enums.ScanStatus.Pending].translation
              file.scanDateDescription = ''

              if (file.Scan !== null) {
                file.isInfected = file.Scan.Status === Enums.ScanStatus.Infected
                file.scanStatus =
                  Enums.ScanStatus.properties[file.Scan.Status].translation
                file.scanDateDescription = ''

                //Generate date description if available
                if (file.Scan.ScannedDate !== null) {
                  file.scanDateDescription = moment(file.Scan.ScannedDate).format(
                    '(DD MMMM YYYY - HH:mm)'
                  )
                }
              }
            })

            this.workflowSigningFiles = response.data.Files
          } else {
            this.showError = true
          }
        })
        .catch(e => {
          toastr.error(
            dictionaryService.translate('TaskDetail.Messages.ErrorFetchingSigningFiles'),
            e,
            true
          )
        })
    },

    translate: function(label) {
      return dictionaryService.translate(label)
    },
    completeTask: function() {
      this.showTaskConfirmationModal = true
    },
    cancelCompleteTask: function() {
      this.showTaskConfirmationModal = false
    },
    acceptActiveTask: function() {
      this.showTaskConfirmationModal = false
      const acceptPostObj = {
        workflowId: this.WorkflowId,
        taskId: this.TaskId
      }

      this.savingTask = true
      axios
        .post(apiEndpoints.acceptEndpoint, acceptPostObj)
        .then(response => {
          toastr.success(
            dictionaryService.translate('TaskDetail.Messages.TaskCompletedSuccessfully'),
            response,
            true
          )

          if (response.data.RedirectUrl) {
            window.location.href = response.data.RedirectUrl
          } else {
            this.getTaskDetails()
            this.savingTask = false
          }
        })
        .catch(e => {
          toastr.error(
            dictionaryService.translate('TaskDetail.Messages.ErrorCompletingTask'),
            e,
            true
          )

          this.savingTask = false
        })
    },
    addComment: function() {
      if (this.newComment.trim() !== '') {
        const data = {
          workflowId: this.WorkflowId,
          taskId: this.TaskId,
          comment: this.newComment.trim(),
          isInternal: this.isInternalComment
        }

        this.savingComment = true
        axios
          .put(apiEndpoints.addCommentEndpoint, data)
          .then(response => {
            // Get comments from response and set them
            this.getTaskDetails()
            this.newComment = ''
            this.isInternalComment = false
            this.savingComment = false
            this.toggleNewComment(false)

            toastr.success(
              dictionaryService.translate('TaskDetail.Messages.CommentAddedSuccessfully'),
              response,
              true
            )
          })
          .catch(e => {
            toastr.error(
              dictionaryService.translate('TaskDetail.Messages.ErrorAddingComment'),
              e,
              true
            )
            this.savingComment = false
          })
      }
    },
    onDelegateTaskClose: function(changed) {
      this.toggleDelegateLightBox(false)

      if (changed) {
        this.Status = null
        this.getTaskDetails()
      }
    },

    dzFileUploaded: function(filesUploaded) {
      this.getWorkflowFiles()
      for (const idx in filesUploaded) {
        this.$refs.taskUpload.removeFile(filesUploaded[idx])
      }

      //Close dropzone if all files have been processed
      if (this.$refs.taskUpload.getQueuedFiles().length === 0) {
        this.toggleDropzone(false)
      }
    },
    dzSigningFileUploaded: function() {
      this.getWorkflowSigningFiles()

      this.$refs.taskUpload.removeAllFiles()

      //Close dropzone if all files have been processed
      if (this.$refs.taskUpload.getQueuedFiles().length === 0) {
        this.toggleSigningDropzone(false)
      }
      this.uploadFileSet = true
    },
    toggleDropzone: function(show) {
      this.showDropzone = typeof show === 'boolean' ? show : !this.showDropzone
    },
    toggleSigningDropzone: function(show) {
      this.showSigningDropzone =
        typeof show === 'boolean' ? show : !this.showSigningDropzone
    },
    toggleNewComment: function(show) {
      this.showNewComment = typeof show === 'boolean' ? show : !this.showNewComment
    },
    toggleRejectLightBox: function(show, reload) {
      this.showRejectLightBox =
        typeof show === 'boolean' ? show : !this.showRejectLightBox

      if (typeof reload === 'boolean' && reload) {
        this.getTaskDetails()
      }
    },
    reloadTaskDetails: function() {
      this.getTaskDetails()
    },
    toggleDelegateLightBox: function(show) {
      this.showDelegateLightBox =
        typeof show === 'boolean' ? show : !this.showDelegateLightBox
    },
    toggleNotifyLightBox: function(show) {
      this.showNotifyLightBox =
        typeof show === 'boolean' ? show : !this.showNotifyLightBox
    },
    toggleWorkflowHistoryLightBox: function(show) {
      this.showWorkflowHistoryLightBox =
        typeof show === 'boolean' ? show : !this.showWorkflowHistoryLightBox
    },
    toggleConfirmFileDeleteLightBox: function(show) {
      this.showConfirmFileDeleteLightBox =
        typeof show === 'boolean' ? show : !this.showConfirmFileDeleteLightBox
    },

    deleteFile: function(event, file, index) {
      this.deleteFileMessage = dictionaryService
        .translate('TaskDetail.Messages.DeleteConfirm')
        .replace('{0}', file.FileName)
      this.deleteFileCallback = confirmed => {
        if (confirmed) {
          const endpoint = apiEndpoints.deleteFilesEndpoint.replace('{fileId}', file.Id)

          axios
            .delete(endpoint)
            .then(response => {
              // Remove the item at array position "index"
              this.workflowFiles.splice(index, 1)
              this.$refs.taskUpload.removeAllFiles()

              toastr.success(
                dictionaryService.translate(
                  'TaskDetail.Messages.FileDeletedSuccessfully'
                ),
                response,
                true
              )
            })
            .catch(e => {
              toastr.error(
                dictionaryService.translate('TaskDetail.Messages.ErrorDeletingFile'),
                e,
                true
              )
            })
        }
        this.toggleConfirmFileDeleteLightBox(false)
      }
      this.toggleConfirmFileDeleteLightBox(true)
    },
    deleteSignFile: function(event, file, index) {
      this.deleteFileMessage = dictionaryService
        .translate('TaskDetail.Messages.DeleteConfirm')
        .replace('{0}', file.FileName)
      this.deleteFileCallback = confirmed => {
        if (confirmed) {
          const endpoint = apiEndpoints.deleteSignFilesEndpoint.replace(
            '{fileId}',
            file.Id
          )

          axios
            .delete(endpoint)
            .then(response => {
              // Remove the item at array position "index"
              this.workflowSigningFiles.splice(index, 1)
              this.getWorkflowSigningFiles() // to check if we still have any files left to complete the current task

              toastr.success(
                dictionaryService.translate(
                  'TaskDetail.Messages.FileDeletedSuccessfully'
                ),
                response,
                true
              )
            })
            .catch(e => {
              toastr.error(
                dictionaryService.translate('TaskDetail.Messages.ErrorDeletingFile'),
                e,
                true
              )
            })
        }
        this.toggleConfirmFileDeleteLightBox(false)
      }
      this.toggleConfirmFileDeleteLightBox(true)
    },
    signDocument: function() {
      this.Pki.isLoading = true
      event.currentTarget.disabled = true

      this.checkForPkiSigningAuthentication()

      // Check for authentication.
      if (!this.Pki.isAuthenticated) {
        this.authenticatePkiSign()
      } else {
        this.doPkiSign()
      }
    },

    authenticatePkiSign: function() {
      // Create the cookie name to be used to track authentication
      const cookieName = 'pki.' + randomChars(36)

      // Get the current URL of this page for later redirect
      let originUrl = window.location.href

      // If not added yet, add a query string to the url, to trigger the signing action after the page is reached again
      if (!getQueryString('trigger')) originUrl += '&trigger=sign'

      // Create json object, stringify and encode in base64
      const cookieValueObject = btoa(JSON.stringify({ originUrl: originUrl }))

      // Add cookie with return url information
      document.cookie = cookieName + '=' + cookieValueObject + '; path=/'

      // Authentication route
      const url = apiEndpoints.startPkiAuthenticationEndpoint

      if (isNullOrEmpty(url))
        console.error('Could not get url to start authentication process')

      const data = {
        cookieId: cookieName
      }

      axios
        .post(url, data)
        .then(res => {
          if (res && res.data && res.data.AuthorizationConnectUrl) {
            window.location.href = res.data.AuthorizationConnectUrl
          }
        })
        .catch(res => {
          console.error('Error: %o', res)
        })
    },
    doPkiSign: function() {
      console.log('Starting the PKI sign @ doPkiSign')

      this.Pki.isLoading = true
      // Since doPkiSign can be called from the page load, we must use nextTick to ensure that the DOM is loaded,
      // since the url is fetched from the DOM element.

      // Get the signing route passed from the backend

      const url = apiEndpoints.startPdfPkiSigningEndpoint

      const payload = {
        accessToken: this.Pki.accessToken,
        wfid: this.WorkflowId
      }

      axios
        .post(url, payload)
        .then(res => {
          const data = res.data
          if (!isNullOrEmpty(data.Id) && !isNullOrEmpty(data.Password)) {
            // Set session id and encryption key
            this.Pki.sessionId = data.Id
            this.Pki.encryptionKey = data.Password
            this.Pki.closeSessionUrl = data.CloseSessionUrl
            this.startPkiSigningSession()
          } else {
            console.error('There is no Id (session id) and Password (key)')
          }
        })
        .catch(res => {
          console.log(res)
          this.Pki.isLoading = false
        })
    },
    // Checks for existance of a cookie called "pki.at" which holds the access token
    checkForPkiSigningAuthentication: function() {
      // PKI Signing authentication exists, if we have a cookie named "pki.at"
      const at = this.getAccessToken()
      const isAuthenticated = !isNullOrEmpty(at)

      this.Pki.isAuthenticated = isAuthenticated
      return isAuthenticated
    },

    getAccessToken: function() {
      // Either we already have the value or we fetch it from the cookie.
      // If we can't get the value, it means the authentication flow has not been run
      this.Pki.accessToken = getCookie(accessTokenCookieName)
      return this.Pki.accessToken
    },

    ////////////////////////////////////////////////////////////////////////
    // PKI SESSION FLOW
    ////////////////////////////////////////////////////////////////////////

    // Starts the PKI Signing session.
    // Requires the access token from the authentication flow,
    // the session id (id) and the encryption key (password)
    // from the backend call to PKI API
    startPkiSigningSession: function() {
      const accessToken = this.Pki.accessToken

      if (isNullOrEmpty(accessToken))
        console.error('Cannot start signing session: access token is null or empty', true)
      if (isNullOrEmpty(this.Pki.sessionId))
        console.error('Cannot start signing session: session id is null or empty', true)
      if (isNullOrEmpty(this.Pki.encryptionKey))
        console.error(
          'Cannot start signing session: encryption key is null or empty',
          true
        )

      if (
        !isNullOrEmpty(accessToken) &&
        !isNullOrEmpty(this.Pki.sessionId) &&
        !isNullOrEmpty(this.Pki.encryptionKey)
      ) {
        try {
          pkiApi
            .startSigning(accessToken, this.Pki.sessionId, this.Pki.encryptionKey, {
              pdfRequireVisibleSignature: false
            })
            .then(() => {
              this.closePkiSigningSession()
            })
            .catch((event, err) => {
              console.log(err)
              this.Pki.isLoading = false
              if (err.message === 'server_error') {
                this.Pki.showErrorSigning = true
              }
              if (err.message === 'user_cancel') {
                // Refresh page after a user cancel
                const url = window.location.href
                window.location.href = url.replace('&trigger=sign', '')
              }
            })
        } catch (e) {
          this.Pki.isLoading = false
          if (
            e.message.indexOf("Cannot read property 'startSigning' of undefined") > -1
          ) {
            const errorMsg = $('.js-pki-errors').attr('data-error-https')
            console.error(errorMsg)
          } else {
            const errorMsg = 'Unknown error calling $.PKISigning.startSigning'
            console.error(errorMsg, true) // show generic error
          }

          //hideProgressIndicator("pkisign");
          console.error('Error calling $.PKISigning.startSigning: %o', e)
        }
      }
    },

    // Closes the PKI Signing session.
    // Requires the access token from the authentication flow,
    // the session id (id) and the encryption key (password)
    // from the backend call to PKI API
    closePkiSigningSession: function() {
      // The url "closeSessionUrl" is a global property,
      // which is set on the response of start session.
      $.ajax({
        url: this.Pki.closeSessionUrl,
        method: 'POST',
        data: {
          accessToken: this.getAccessToken(),
          id: this.Pki.sessionId,
          password: this.Pki.encryptionKey,
          workflowId: this.WorkflowId
        }
      })
        .then(data => {
          if (data.isSigned) {
            this.MaySign = false
            this.MayReject = false
            this.Pki.showSuccessSigning = true
            this.Pki.showErrorSigning = false
            this.Pki.isSigned = true
            this.MayComplete = true
          } else {
            this.Pki.showSuccessSigning = false
            //TODO: Hide the error message while the PKI document is getting signed. Quickfix for now
            // this.Pki.showErrorSigning = true
          }
          this.Pki.isLoading = false
          location.reload(true)
        })
        .catch(data => {
          console.error('Session failed to close. Error: %o', data)
          this.Pki.showSuccessSigning = false
          this.Pki.showErrorSigning = true
          this.Pki.isLoading = false
        })
    },
    startDocuSignProcess: function() {
      //store the current page URL before moving to DocuSign Page Tab
      this.DocuSigningPreviousURL = window.location.href

      // //Open DocuSignPage in new tab
      // window.open(this.DocuSignPageURL)

      //Open DocuSignPage in same tab
      window.location.href = this.DocuSignPageURL

      //Disable Sign Button for both PKI and Docu Sign
      this.MayDocuSign = false
      this.MaySign = false

      // //Display Spinner while signatures are in progress
      // this.DocuSigningSpinner = true

      // // Check the DocuSign Progress
      // this.checkDocuSignProgess()
    },
    checkDocuSignProgess: function() {
      axios
        .get(apiEndpoints.hasSigned)
        .then(response => {
          if (!response.data) {
            //If document not signed, check again after 30 seconds as per Backend
            this.checkAgainHasSigned()
          } else {
            //If signed after 30 secs, stopped the spinner
            this.DocuSignCompleted = true
            this.DocuSigningSpinner = false

            //get files if they are ready for download
            this.isDocuSignFilesReadyForDownload()
          }
        })
        .catch(e => {
          toastr.error(
            dictionaryService.translate('TaskDetail.Messages.ErrorFetchingSigningFiles'),
            e,
            true
          )
        })
    },
    checkAgainHasSigned: function() {
      setTimeout(() => {
        this.checkDocuSignProgess()
      }, 31000)
    },
    isDocuSignFilesReadyForDownload: function() {
      axios
        .get(apiEndpoints.downloadSignedFiles)
        .then(() => {
          this.DownloadDocuSignFiles = true
        })
        .catch(e => {
          console.error(e)
        })
    }
  }
}
