import dictionaryService from '@/services/modules/dictionaryService'
export const localization = {
  messages: {
    changesSucessfullySaved: dictionaryService.translate(
      'EmployeeDetail.Messages.ChangesSuccessfullySaved'
    ) as string,
    somethingWentWrong: dictionaryService.translate(
      'EmployeeDetail.Messages.SomethingWentWrong'
    ) as string,
    fieldRequired: dictionaryService.translate(
      'EmployeeDetail.Messages.FieldRequired'
    ) as string
  }
}
