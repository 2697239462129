






































































































































































































































































































































import Vue from "vue";
import store from "@/store/store2";
import moment from "moment";
import mazModal from "@/components/general/mazModal/index.vue";
import mazSpinner from "@/components/general/mazSpinner/index.vue";
import mazSwitch from "@/components/general/mazSwitch/index.vue";
import mazTextInput from "@/components/general/mazTextInput/index.vue";
import mazButton from "@/components/general/mazButton/index.vue";
import mazDropDown from "@/components/general/mazDropDown/index.vue";
import mazClientMaintenanceDatePicker from "@/components/custom/mazClientMaintenanceDatePicker/index.vue";
import mazValidationMessage from "@/components/general/mazValidationMessage/index.vue";

import { isString, isEmpty, pickBy, startsWith, trim } from "lodash";
import dictionaryService from "@/services/modules/dictionaryService";

export default Vue.extend({
  name: "mazClientMaintenanceContactDetails",

  components: {
    mazModal,
    mazSwitch,
    mazSpinner,
    mazTextInput,
    mazButton,
    mazDropDown,
    mazClientMaintenanceDatePicker,
    mazValidationMessage,
  },

  data() {
    return {
      errors: {} as any,
      startDate: null as any,
      endDate: null as any,
      resetCross: false,
      showSpecialConfirmationModal: false,
    };
  },

  computed: {
    shouldRedirect(): boolean {
      return this.redirectUrl !== '' && this.redirectUrl !== undefined && this.redirectUrl !== null
    },
    redirectUrl(): string {
      return store.getters.clientMaintenance.contact.CLIENT_DETAILS.editUrl;
    },
    clientDetails(): any {
      return store.getters.clientMaintenance.contact.CLIENT_DETAILS;
    },
    editedClientDetails(): any {
      return store.getters.clientMaintenance.contact.EDITED_CLIENT_DETAILS;
    },
    loader(): any {
      return store.getters.clientMaintenance.contact.LOADER;
    },
    labels(): any {
      return store.getters.clientMaintenance.contact.LABELS;
    },
    messages(): any {
      return store.getters.clientMaintenance.contact.MESSAGES;
    },
    permissions(): MazInterfaces.ClientMaintenance.CustomerPermission {
      return store.getters.clientMaintenance.contact.PERMISSIONS;
    },
    isEditMode(): boolean {
      return store.getters.clientMaintenance.contact.IS_EDIT_MODE;
    },
    relationManagersList(): readonly MazInterfaces.ClientMaintenance.RelationManager[] {
      return store.getters.clientMaintenance.contact.RELATION_MANAGERS_LIST;
    },
    gendersList(): readonly MazInterfaces.ClientMaintenance.Gender[] {
      return store.getters.clientMaintenance.contact.GENDERS_LIST;
    },
    languagesList(): readonly MazInterfaces.ClientMaintenance.Language[] {
      return store.getters.clientMaintenance.contact.LANGUAGES_LIST;
    },
    validationErrorsList(): readonly MazInterfaces.ClientMaintenance.ValidationError[] {
      return store.getters.clientMaintenance.contact.VALIDATION_ERRORS_LIST;
    },
    countriesList(): any {
      return store.getters.clientMaintenance.contact.COUNTRIES_LIST;
    },
    countryName(): any {
      return store.getters.clientMaintenance.contact.COUNTRY_NAME;
    },
  },

  methods: {
    /**
     * Vuex actions
     */
    getReferenceData(): void {
      store.dispatch.clientMaintenance.contact.GET_REFERENCE_DATA();
    },
    updateClientMaintenanceEditMode(body: boolean): void {
      store.dispatch.clientMaintenance.contact.UPDATE_CLIENT_MAINTENANCE_EDIT_MODE(body);
    },
    resetClientMaintenanceEditClientDetails(): void {
      store.dispatch.clientMaintenance.contact.RESET_CLIENT_MAINTENANCE_EDIT_CLIENT_DETAILS();
    },
    updateContactDetails(body: any): void {
      store.dispatch.clientMaintenance.contact.UPDATE_CONTACT_DETAILS(body);
    },
    resetValidationList(): void {
      store.dispatch.clientMaintenance.contact.RESET_VALIDATION_ERRORS();
    },
    resetError(field: string): void {
      if (this.errors[field]) {
        this.$delete(this.errors, field);
      }
      store.dispatch.clientMaintenance.contact.RESET_VALIDATION_ERROR(field);
    },
    resetErrors(): void {
      this.errors = {}; // frontend errors
      this.resetValidationList(); //backend errors
    },
    updateContactSpecial(body: any): void {
      store.dispatch.clientMaintenance.contact.UPDATE_CONTACT_IS_SPECIAL(body);
    },

    /**
     * Component methods
     */

    save(details: MazInterfaces.ClientMaintenance.ContactDetails) {
      const body: MazInterfaces.ClientMaintenance.ContactDetails = details;
      this.resetValidationList();
      this.fieldValidator(details, [
        "primaryContactEmployeeId",
        "email",
        "gender",
        "firstName",
        "lastName",
        "language",
        "countryCode",
      ]);
      this.updateContactDetails(body);
    },

    fieldValidator(objectToValidate: any, fields: string[]) {
      fields.forEach((field: string) => {
        //if string, check if it has white spaces
        if (
          isString(objectToValidate[field]) &&
          trim(objectToValidate[field]).length === 0
        ) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired },
          };
          return;
        }

        if (isEmpty(objectToValidate[field])) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired },
          };
        } else {
          const filteredErrors = pickBy(this.errors, function (value, key) {
            return !startsWith(key, field);
          });
          this.errors = filteredErrors;
        }
      });
    },
    validationError(field: string): string {
      const err = this.validationErrorsList.find((err) => err.PropertyName === field)
        ?.ErrorCode;
      if (err) {
        return dictionaryService.translate(`EmployeeContactOverview.Messages.${err}`);
      }
      return "";
    },
    genderName(code: string): string {
      const gender = this.gendersList.find((obj) => obj.code === code);
      return gender?.name || "";
    },

    languageName(code: string): string {
      const lang = this.languagesList.find((obj) => obj.code === code);
      return lang?.name || "";
    },
    formatDate(event: string) {
      if (event === null) {
        return null;
      }
      return moment(event).format("DD/MM/YYYY");
    },
    onStartDateSelected(event: Date): void {
      const startDateOffset = moment(event).utcOffset();
      this.editedClientDetails.startDate = moment(event)
        .add(startDateOffset, "minutes")
        .utc()
        .format();
    },
    onEndDateSelected(event: Date): void {
      const endDateOffset = moment(event).utcOffset();
      this.editedClientDetails.endDate = moment(event)
        .add(endDateOffset, "minutes")
        .utc()
        .format();
    },
    getContactActiveDates() {
      const initialStartDate = this.editedClientDetails.startDate;
      const initialEndDate = this.editedClientDetails.endDate;

      this.startDate = moment(initialStartDate);
      this.endDate = moment(initialEndDate);
    },
    resetEndDate(value: boolean) {
      this.editedClientDetails.endDate = null;
      this.endDate = null;
      this.resetCross = value;
    },
    toggleIsSpecialConfirmModal(value: boolean) {
      this.showSpecialConfirmationModal = value;
    },
    toggleIsSpecial(details: MazInterfaces.ClientMaintenance.ContactDetails) {
      const body: MazInterfaces.ClientMaintenance.ContactDetails = details;
      const toggleData = {
        Id: body.id,
        IsSpecial: body.isSpecial,
      };
      this.updateContactSpecial(toggleData);
      this.showSpecialConfirmationModal = false;
    },
    cancelIsSpecialToggle(value: boolean) {
      this.showSpecialConfirmationModal = value;
      this.editedClientDetails.isSpecial = !this.editedClientDetails.isSpecial;
    },
    redirectToPowerApp() {
      window.open(this.redirectUrl, '_blank')?.focus()
    }
  },

  watch: {
    isEditMode: function (value: boolean): void {
      if (value === false) {
        this.resetClientMaintenanceEditClientDetails();
        this.resetErrors();
      }
    },
    "clientDetails.contact": function (): void {
      this.resetClientMaintenanceEditClientDetails();
    },
  },

  created() {
    this.getContactActiveDates();
    this.getReferenceData();
  },
});
