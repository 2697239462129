import dictionaryService from '@/services/modules/dictionaryService'
import { currentGetterContext } from './index'

export const getters = {
  CLIENT_DETAILS(...args: any): MazInterfaces.ClientMaintenance.ContactClientDetails {
    const { state } = currentGetterContext(args)
    return state.clientDetails
  },
  COUNTRY_NAME: (...args: any): MazInterfaces.ClientMaintenance.ContactClientDetails => {
    const { state } = currentGetterContext(args)
    const countryCodeNotAvailable = state.clientDetails.contact.countryCode === null
    let code
    if (!countryCodeNotAvailable) {
      code = dictionaryService.translate(
        `Labels.Country.${state.clientDetails.contact.countryCode}`
      )
    }
    return code
  },
  LABELS(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.labels
  },
  MESSAGES(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.messages
  },
  TAB_NAVIGATION(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.tabNavigation
  },
  EDITED_CLIENT_DETAILS(...args: any): MazInterfaces.ClientMaintenance.ContactDetails {
    const { state } = currentGetterContext(args)
    return state.editedClientDetails
  },
  IS_EDIT_MODE(...args: any): boolean {
    const { state } = currentGetterContext(args)
    return state.isEditMode
  },
  GENDERS_LIST(...args: any): MazInterfaces.ClientMaintenance.Gender[] {
    const { state } = currentGetterContext(args)
    return state.genders
  },
  LANGUAGES_LIST(...args: any): MazInterfaces.ClientMaintenance.Language[] {
    const { state } = currentGetterContext(args)
    return state.languages
  },
  RELATION_MANAGERS_LIST(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.relationManagers
  },
  LOADER(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.loader
  },
  PERMISSIONS(...args: any): MazInterfaces.ClientMaintenance.CustomerPermission {
    const { state } = currentGetterContext(args)
    return state.clientDetails.customerPermission
  },
  CONTACT_ORGANIZATIONS_PERMISSIONS_TABLE(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.contactOrganizationsPermissionsTable
  },
  CLIENT_SERVICES_TABLE(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.clientServicesTable
  },
  EMPLOYEES_CONNECTED_TO_CONTACT(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.employeesConnectedToContact
  },
  EMPLOYEES_MODAL(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.employeesModal
  },
  VALIDATION_ERRORS_LIST(
    ...args: any
  ): MazInterfaces.ClientMaintenance.ValidationError[] {
    const { state } = currentGetterContext(args)
    return state.validationErrors
  },
  ORGANIZATIONS_MODAL(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.organizationsModal
  },
  COUNTRIES_LIST(...args: any): MazInterfaces.ClientMaintenance.Countries[] {
    const { state } = currentGetterContext(args)
    return state.countries
  },
  CONNECT_URL(state: any): any {
    return state.connectUrl
  }
}
