import helpersService from '@/services/modules/helpersService'
import { localization } from './localization'

export default () => ({
  delegationId: helpersService.getSearchId('?', window.location.search) as string,
  delegationInitialData: {
    categoryId: '',
    categoryTitle: '',
    customerName: '',
    delegateReason: '',
    delegationIsComplete: false,
    description: '',
    dueDate: '',
    financialYear: '',
    groupSegmentId: '',
    groupSegmentTitle: '',
    languageCode: '',
    pageTitle: '',
    pageText: '',
    segment: {
      description: '',
      id: '',
      segmentItems: [] as object[],
      attachments: [] as object[],
      title: '',
      isComplete: false
    },
    status: 0,
    supplyRequestId: '',
    title: ''
  },
  supplyRequestFileUploader: {
    ...localization.supplyRequestFileUploader,
    fileDeleted: false,
    chunkSize: 0,
    maxFileSize: 0,
    fileUploadExtensionsCsv: '',
    files: [] as object[]
  },

  labels: {
    ...localization.labels
  }
})
