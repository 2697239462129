import clientMaintenanceApi from '@/api/modules/clientMaintenanceApi'
import config from '@/api/modules/config'
import axiosService from '@/services/modules/axiosService'
import helpersService from '@/services/modules/helpersService'
import { defineActions } from 'direct-vuex'
import toastr from 'toastr'
import { currentActionContext } from './index'

const _portController = 'ClientMaintenanceEmployeeJson'
const _baseEndpoint = config.endpointBase + _portController + '/'
const _targetApi = clientMaintenanceApi

export const actions = defineActions({
  GET_CLIENT_MAINTENANCE_INITIAL_DATA(ctx: any, payload: string) {
    const { dispatch } = currentActionContext(ctx)
    dispatch.GET_CLIENT_MAINTENANCE_DETAILS(payload)
  },

  //TODO: get client details data action
  GET_CLIENT_MAINTENANCE_DETAILS(ctx: any, payload: string) {
    const { commit, state } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getClientMaintenanceDetails,
      '{clientId}',
      payload
    )
    commit.organization.UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION({ global: true })
    axiosService.get(endpoint, (res: any) => {
      if (res) {
        const body = helpersService.convertObjectKeysToCamelCase(res)
        if (body.type === 20) {
          //type 20: contact
          commit.UPDATE_CLIENT_MAINTENANCE_IS_CONTACT_MUTATION(true)
          commit.contact.UPDATE_CLIENT_MAINTENANCE_CLIENT_DETAILS_MUTATION(body)
        } else if (body.type === 10) {
          // type 10: organization
          commit.UPDATE_CLIENT_MAINTENANCE_IS_ORGANIZATION_MUTATION(true)
          commit.organization.UPDATE_CLIENT_MAINTENANCE_CLIENT_DETAILS_MUTATION(body)
        } else if (body.type === 0) {
          commit.SET_CLIENT_MAINTENANCE_IS_NOT_FOUND_MUTATION()
        } else {
          toastr.error(state.messages.somethingWentWrong)
        }
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.organization.UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION({ global: false })
    })
  }
})
