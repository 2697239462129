import config from '@/api/modules/config'
import employeeMaintenanceApi from '@/api/modules/employeeMaintenanceApi'
import axiosService from '@/services/modules/axiosService'
import helpersService from '@/services/modules/helpersService'
import toastr from 'toastr'
import { currentActionContext } from './index'

const _targetApi = employeeMaintenanceApi
const _portController = 'EmployeeEmployeeJson'
const _baseEndpoint = config.endpointBase + _portController + '/'

export const actions = {
  UPDATE_EMPLOYEE_PERMISSIONS_EDIT_MODE({ commit }: any, payload: boolean) {
    commit('UPDATE_EMPLOYEE_PERMISSIONS_EDIT_MODE_MUTATION', payload)
  },
  GET_EMPLOYEE_PERMISSIONS_DETAILS(ctx: any, payload: string) {
    const { commit, state } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getEmployeePermissions,
      '{employeeId}',
      payload
    )
    commit.UPDATE_EMPLOYEE_PERMISSIONS_LOADER_MUTATION(true)
    axiosService.get(endpoint, (res: any) => {
      if (res) {
        const body = helpersService.convertObjectKeysToCamelCase(res)
        commit.UPDATE_EMPLOYEE_PERMISSION_DETAILS_MUTATION(body)
      } else {
        toastr.error(state.messagesInfo.somethingWentWrong)
      }
      commit.UPDATE_EMPLOYEE_PERMISSIONS_LOADER_MUTATION(false)
    })
  },
  SET_COUNTRIES(ctx: any, payload: MazInterfaces.EmployeeMaintenance.Countries[]) {
    const { commit } = currentActionContext(ctx)
    commit.UPDATE_ACTIVE_COUNTRIES(payload)
  },
  RESET_EMPLOYEE_PERMISSIONS_COUNTRY_LIST(ctx: any) {
    const { commit } = currentActionContext(ctx)
    commit.RESET_EMPLOYEE_PERMISSIONS_COUNTRY_LIST_MUTATION()
  },
  RESET_EMPLOYEE_PERMISSION_DETAILS(ctx: any) {
    const { commit } = currentActionContext(ctx)
    commit.RESET_EMPLOYEE_PERMISSION_DETAILS_MUTATION()
  },
  RESET_VALIDATION_ERROR(ctx: any, payload: string) {
    function capitalizeFirstLetter(string: string): string {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
    const { commit } = currentActionContext(ctx)
    commit.RESET_VALIDATION_ERROR_MUTATION(capitalizeFirstLetter(payload))
  },
  UPDATE_EMPLOYEE_PERMISSIONS(
    ctx: any,
    payload: MazInterfaces.EmployeeMaintenancePermissions.EmployeePermissions
  ) {
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.updateEmployeePermissions
    )
    const { commit, dispatch, state } = currentActionContext(ctx)
    commit.UPDATE_EMPLOYEE_PERMISSIONS_LOADER_MUTATION(true)
    const body = helpersService.convertObjectKeysToPascalCase(payload)
    axiosService.post(endpoint, body, (res: any) => {
      if (res.HasSucceeded) {
        commit.UPDATE_EMPLOYEE_PERMISSIONS_EDIT_MODE_MUTATION(false)
        toastr.success(state.messages.changesSucessfullySaved)
        dispatch.GET_EMPLOYEE_PERMISSIONS_DETAILS(state.id)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.UPDATE_EMPLOYEE_PERMISSIONS_LOADER_MUTATION(false)
    })
  }
}
