<template>
  <div class="c-audit-logs">
    <div
      :key="index"
      class="c-audit-logs__row d-md-flex"
      v-for="(log, index) in logs"
    >
      <div class="c-audit-logs__cell">{{ log.createdDate }}</div>
      <div class="c-audit-logs__cell flex-fill">
        <div>
          <span class="c-audit-logs__description">{{ log.fullDescription }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'maz-audit-logs',
  props: {
    logs: {
      required: false,
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-audit-logs {
  font-size: 16px;
  font-family: 'Halyard-Book', sans-serif;

  span {
    font-size: 16px;
    font-family: 'Halyard-Book', sans-serif;
  }

  .c-audit-logs__row {
    + .c-audit-logs__row {
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid $bg-blue;
    }

    .c-audit-logs__cell {
      &:first-child {
        min-width: 200px;
        padding-right: 40px;
      }

      .c-audit-logs__description {
        color: $dark-gray;
      }
    }
  }
}
</style>
