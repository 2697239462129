import dictionaryService from '@/services/modules/dictionaryService'

export const localization = {
  tabNavigation: {
    labels: {
      overview: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Overview'
      ) as string,
      details: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Details'
      ) as string,
      services: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Services'
      ) as string,
      permissions: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Permissions'
      ) as string,
      employees: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Employees'
      ) as string
    }
  },
  pendingActionsTable: {
    labels: {
      status: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Status'
      ) as string,
      service: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Type'
      ) as string,
      actions: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Action'
      ) as string,
      period: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Period'
      ) as string,
      dueDate: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.DueDate'
      ) as string,
      requiredBy: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.RequiredBy'
      ) as string
    }
  },
  completedActionsTable: {
    labels: {
      status: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Status'
      ) as string,
      service: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Type'
      ) as string,
      actions: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Action'
      ) as string,
      period: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Period'
      ) as string,
      dueDate: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.DueDate'
      ) as string,
      completedDate: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.CompletionDate'
      ) as string
    }
  },
  organizationContactPermissionsTable: {
    labels: {
      name: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Name'
      ) as string,
      number: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Number'
      ) as string,
      email: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Email'
      ) as string
    }
  },
  clientServicesTable: {
    labels: {
      service: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Services'
      ) as string,
      actions: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.RelationManager'
      ) as string,
      status: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Status'
      ) as string
    }
  },
  organizationContactPermissionsModal: {
    labels: {
      service: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Services'
      ) as string,
      signals: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Signals'
      ) as string,
      read: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.View'
      ) as string,
      write: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Submit'
      ) as string,
      download: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Download'
      ) as string,
      upload: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Upload'
      ) as string
    }
  },
  labels: {
    organizationNumber: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.OracleOrganizationId'
    ) as string,
    actions: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Actions'
    ) as string,
    completedActions: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.CompletedActions'
    ) as string,
    showMore: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.ShowMore'
    ) as string,
    startAction: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.StartActions'
    ) as string,
    startSupplyRequest: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.StartSupplyRequest'
    ) as string,
    uploadAnnualReport: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.UploadAnnualReport'
    ) as string,
    requestDocumentation: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.RequestDocumentation'
    ) as string,
    editContactPersons: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.EditContactPersons'
    ) as string,
    oraclePersonId: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.OraclePersonId'
    ) as string,
    nationalId: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.NationalId'
    ) as string,
    accountNumber: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Accountnumber'
    ) as string,
    relationManager: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.RelationManager'
    ) as string,
    cocNo: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.CoCNo'
    ) as string,
    wageTaxNo: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.WageTaxNo'
    ) as string,
    vatNo: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.VatNo'
    ) as string,
    corporateIncomeTaxNo: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.CorporateIncomeTaxNo'
    ) as string,
    icpNo: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.IcpNo'
    ) as string,
    dunsNo: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.DunsNo'
    ) as string,
    referenceId: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.ReferenceId'
    ) as string,
    legalName: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.LegalName'
    ) as string,
    taxNumber: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.TaxNumber'
    ) as string,
    clientDetails: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.ClientDetails'
    ) as string,
    unknown: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Unknown'
    ) as string,
    isSpecial: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.IsSpecial'
    ) as string,
    contacts: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Contacts'
    ) as string,
    servicesTitle: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.ServicesTitle'
    ) as string,
    active: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.IsServiceActive'
    ) as string,
    inactive: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.IsServiceInactive'
    ) as string,
    service: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Services'
    ) as string,
    cancel: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Cancel'
    ) as string,
    done: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Done'
    ) as string,
    personNumber: dictionaryService.translate('Labels.Person.Number') as string,
    searchForUsers: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.SearchForUsers'
    ) as string,
    searchForEmployees: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.SearchForEmployees'
    ) as string,
    selectedUsers: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.SelectedUsers'
    ) as string,
    selectedEmployees: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.SelectedEmployees'
    ) as string,
    selectCountry: dictionaryService.translate(
      'ClientSimpleOverview.Labels.SelectCountry'
    ) as string,
    addContacts: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.AddContact'
    ) as string,
    addContactUrl: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.AddContactViaUrl'
    ),
    addEmployees: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.AddEmployees'
    ) as string,
    save: dictionaryService.translate('Labels.Common.Save'),
    confirm: dictionaryService.translate('Labels.Common.Confirm'),
    searchByName: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.SearchByName'
    ),
    employeesTitle: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.EmployeesTitle'
    ) as string,
    searchRelationManager: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.SearchRelationManager'
    ) as string,
    countryLabel: dictionaryService.translate('Labels.Common.Country') as string,
    startDate: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.StartDate'
    ) as string,
    endDate: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.EndDate'
    ) as string,
    isDeleted: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.IsDeleted'
    ) as string,
    isActive: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.IsActive'
    ) as string,
    isYes: dictionaryService.translate('Labels.Common.True') as string,
    isNo: dictionaryService.translate('Labels.Common.False') as string
  },
  messages: {
    accessibleForAllEmployees: dictionaryService.translate(
      'EmployeeOrganizationOverview.Messages.AccessibleForAllEmployees'
    ) as string,
    noActionsFound: dictionaryService.translate(
      'EmployeeOrganizationOverview.Messages.NoActionsFound'
    ) as string,
    noCompletedActionsFound: dictionaryService.translate(
      'EmployeeOrganizationOverview.Messages.NoCompletedActionsFound'
    ) as string,
    noServicesFound: dictionaryService.translate(
      'EmployeeOrganizationOverview.Messages.NoServicesFound'
    ) as string,
    noContactsFound: dictionaryService.translate(
      'EmployeeOrganizationOverview.Messages.NoContactsFound'
    ) as string,
    noEmployeesConnected: dictionaryService.translate(
      'EmployeeOrganizationOverview.Messages.NoEmployeesConnected'
    ) as string,
    changesSuccessfullySaved: dictionaryService.translate(
      `EmployeeOrganizationOverview.Messages.ChangesSuccessfullySaved`
    ) as string,
    somethingWentWrong: dictionaryService.translate(
      `EmployeeOrganizationOverview.Messages.SomethingWentWrong`
    ) as string,
    fieldRequired: dictionaryService.translate(
      `EmployeeOrganizationOverview.Messages.FieldRequired`
    ) as string,
    disconnectEmployeeFromContact: dictionaryService.translate(
      `EmployeeOrganizationOverview.Messages.DisconnectEmployeeFromContact`
    ) as string,
    disconnectContactFromOrganization: dictionaryService.translate(
      `EmployeeOrganizationOverview.Messages.DisconnectContactFromOrganization`
    ) as string,
      updatePrivacySettings: dictionaryService.translate(
          `EmployeeOrganizationOverview.Messages.UpdatePrivacySettingsOrganization`
      ) as string
  }
}
