<template>
  <maz-lightbox-container :show="show" @close="onClose">
    <div class="c-notify-users-lightbox">
      <div v-show="!loading">
        <h3 class="c-lightbox__title c-notify-users-title">
          {{ translations.NotifyUser_s }}
        </h3>
        <table class="c-notify-users-list">
          <tbody>
            <tr>
              <td>
                <p class="c-notify-users-column-label">
                  {{ translations.User }}
                </p>
              </td>
            </tr>
            <tr v-for="(user, index) in assignedUsers" v-bind:key="index">
              <td>
                <div class="col-sm-1 c-notify-users-checkbox">
                  <div
                    class="c-old-checkbox custom-checkbox"
                    v-bind:class="{ checked: selectedUsers.indexOf(user.Id) !== -1 }"
                  >
                    <input type="checkbox" :value="user.Id" v-model="selectedUsers" />
                    <div class="c-checkbox__box" />
                  </div>
                </div>
              </td>
              <td>
                <p class="c-notify-users-user-name">
                  {{ user.Name }}
                </p>
                <p class="c-notify-users-user-email">
                  {{ user.Email }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <textarea
          class="c-notify-users-notification"
          v-model="notificationDescription"
          v-bind:placeholder="translations.TypeANotification"
          type="text"
        />
        <mazButton
          class="c-notify-users-button"
          @click.native="sendNotification"
          :disabled="cannotSend"
        >
          {{ translations.NotifyUsers }}
        </mazButton>
      </div>
      <div class="loading-container" v-show="loading">
        <fa icon="spinner" spin size="1x" />
      </div>
    </div>
  </maz-lightbox-container>
</template>

<script>
import config from '@/api/modules/config'
import toastr from 'toastr'
import dictionaryService from '@/services/modules/dictionaryService'
import mazLightboxContainer from '@/components/general/mazLightboxContainer/index.vue'
import mazButton from '@/components/general/mazButton/index.vue'
import axios from 'axios'

const portalController =
  config.portalType === 'Employee'
    ? 'ShareDocumentsEmployeeJson'
    : 'ShareDocumentsClientJson'

const url = '/json/' + portalController + '/SendTaskMessage'

export default {
  name: 'mazNotifyUsersLightbox',
  props: {
    show: {
      required: true,
      type: Boolean
    },
    workflowId: {
      required: true,
      type: String
    },
    taskId: {
      required: true,
      type: String
    },
    assignedUsers: {
      type: Array,
      required: true
    },
    onClose: {
      type: Function
    }
  },
  components: {
    mazLightboxContainer,
    mazButton
  },
  data() {
    return {
      saving: false,
      translations: {
        NotifyUser_s: dictionaryService.translate(
          'NotifyUsersLightbox.Labels.NotifyUser_s'
        ),
        User: dictionaryService.translate('NotifyUsersLightbox.Labels.User'),
        Type: dictionaryService.translate('NotifyUsersLightbox.Labels.Type'),
        TypeANotification: dictionaryService.translate(
          'NotifyUsersLightbox.Labels.TypeANotification'
        ),
        NotifyUsers: dictionaryService.translate('NotifyUsersLightbox.Labels.NotifyUsers')
      },
      selectedUsers: [],
      notificationDescription: ''
    }
  },
  watch: {
    show: function(value, oldValue) {
      if (!oldValue && value) {
        this.selectedUsers = []
        this.notificationDescription = ''
      }
    }
  },
  methods: {
    sendNotification: function() {
      const data = {
        WorkflowId: this.workflowId,
        TaskId: this.taskId,
        Message: this.notificationDescription,
        Users: this.selectedUsers.map(function(userId) {
          return {
            Id: userId
          }
        })
      }

      this.saving = true
      axios
        .post(url, data)
        .then(() => {
          this.saving = false
          this.onClose()

          toastr.success(
            dictionaryService.translate(
              'NotifyUsersLightbox.Messages.SignalSentSuccessfully'
            ),
            data,
            true
          )
        })
        .catch(error => {
          this.saving = false
          toastr.error(
            dictionaryService.translate(
              'NotifyUsersLightbox.Messages.ErrorSendingSignal'
            ),
            error,
            true
          )
        })
    }
  },
  computed: {
    loading: function() {
      return this.saving || this.assignedUsers === null
    },
    cannotSend: function() {
      return this.notificationDescription.trim() === '' || this.selectedUsers.length === 0
    }
  }
}
</script>

<style lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/styles/_checkboxes.scss';

.c-notify-users-lightbox {
  min-width: 500px;

  .c-notify-users-title {
    margin-bottom: 20px;
    color: $dark-gray;
    text-transform: none;
  }

  .c-notify-users-list {
    max-height: 400px;
    margin-bottom: 20px;
    padding: 0 15px;
    overflow-y: auto;

    .c-notify-users-column-label {
      @include halyard-medium;
      margin-bottom: 1em;
      color: $dark-gray;
    }

    tr {
      margin-bottom: 10px;

      td {
        border-top: none;

        &:first-child {
          width: 50px;
          max-width: 50px;
        }
      }

      .custom-checkbox {
        position: relative;
        margin-top: 0;
        input[type='checkbox'] {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }

      .c-notify-users-user-name,
      .c-notify-users-user-email,
      .c-notify-users-user-type {
        position: relative;
        top: -10px;
        font-size: 16px;
      }

      .c-notify-users-user-email {
        font-style: italic;
      }
    }
  }

  .c-notify-users-notification {
    min-height: 120px;
    margin-bottom: 16px;
    border: 1px solid $dark-blue;
  }

  .c-notify-users-button {
    margin-bottom: 14px;
  }
}

.c-lightbox.c-notify-users-lightbox .c-card {
  padding: 15px 20px;

  .c-notify-users-close-button {
    margin: -8px 0px 6px;
    cursor: pointer;
  }

  .c-container-fluid {
    padding-top: 30px;

    .c-notify-users-title {
      margin-bottom: 20px;
      text-transform: none;
    }

    .c-notify-users-list {
      max-height: 400px;
      margin-bottom: 20px;
      padding: 0 15px;
      overflow-y: auto;

      .c-notify-users-column-label {
        color: #546480;
        font-weight: 700;
        font-size: 20px;
      }

      tr {
        margin-bottom: 10px;

        td {
          border-top: none;

          &:first-child {
            width: 50px;
            max-width: 50px;
          }
        }

        .custom-checkbox {
          position: relative;
          &:before {
            position: absolute;
            z-index: 10;
            display: block;
            width: 20px;
            height: 20px;
            background-color: #fff;
            border-radius: 3px;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
            content: '';
            pointer-events: none;
          }
          &.checked:after {
            position: absolute;
            top: 2px;
            left: 4px;
            z-index: 11;
            width: 20px;
            height: 20px;
            color: #546480;
            background-image: url('~@/assets/images/icon_forms_selected.svg');
            pointer-events: none;
          }
          input[type='checkbox'] {
            width: 20px;
            height: 20px;
            cursor: pointer;
            opacity: 0;
          }
        }

        .c-notify-users-user-name,
        .c-notify-users-user-email,
        .c-notify-users-user-type {
          font-size: 16px;
        }

        .c-notify-users-user-email {
          font-style: italic;
        }
      }
    }

    .c-notify-users-notification {
      margin-bottom: 16px;
      border-color: #8998c2;
      border-radius: 3px;
    }

    .c-notify-users-button {
      margin-bottom: 14px;
      padding: 10px;
      color: white;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      text-transform: uppercase;
      background-color: $color2;
      border: 1px solid $color1;
      border-bottom-color: $color2;
      border-radius: 4px;

      &:disabled {
        opacity: 0.2;
      }
    }
  }
}
</style>
