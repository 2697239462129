<template>
  <div
    class="c-worflow-history-lightbox c-lightbox js-lightbox"
    v-show="show"
    @close="onClose"
  >
    <div
      class="
        c-lightbox__outer
        container-fluid
        d-flex
        align-items-center
        justify-content-center
      "
    >
      <div class="c-lightbox__inner js-lightbox-inner row flex-center" v-on:click.stop>
        <div class="c-card c-card--big">
          <div class="container-fluid">
            <div class="c-workflow-history-close-button float-right" v-on:click="onClose">
              <div class="row">
                <i><fa icon="times" size="2x" /></i>
              </div>
            </div>
          </div>
          <div class="c-container-fluid">
            <div class="row" v-for="(entry, index) in history" v-bind:key="index">
              <div class="col-sm-3">
                <p class="c-workflow-history-date">{{ entry.DateDescription }}</p>
              </div>
              <div class="col-sm-9">
                <p class="c-workflow-history-description">{{ entry.FullDescription }}</p>
                <p class="c-workflow-history-created-by">{{ entry.CreatedBy }}</p>
              </div>
            </div>
            <div
              class="no-entries-container"
              v-show="history !== null && history.length === 0"
            >
              <p>
                {{
                  dictionaryService.translate('WorkflowDetail.Messages.NoHistoryEntries')
                }}
              </p>
            </div>
            <div class="loading-container d-flex justify-content-center" v-if="!history">
              <fa icon="spinner" spin size="2x" />
            </div>
          </div>
          <div class="d-flex justify-content-end" v-if="hasExportUrl">
            <a :href="url" class="c-btn mt-3">{{
              dictionaryService.translate(
                'WorkflowDetail.Messages.HistoryExportButtonText'
              )
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import moment from 'moment'
import dictionaryService from '@/services/modules/dictionaryService'
// import mazButton from '@/components/general/mazButton/index.vue'

let workflowHistoryEndpoint = null

export default {
  name: 'mazWorkflowHistoryLightbox',
  props: {
    show: {
      required: true,
      type: Boolean
    },
    workflowId: {
      required: true,
      type: String
    },
    onClose: {
      type: Function
    },
    canExport: {
      type: Boolean,
      default: false
    },
    exportUrl: {
      type: String
    }
  },
  data() {
    return {
      history: null,
      dictionaryService: dictionaryService
    }
  },
  computed: {
    hasExportUrl() {
      return this.exportUrl && this.exportUrl.length > 0
    },
    url() {
      return this.exportUrl
    }
  },
  mounted() {
    const apiBase = $('.js-api').data('api-route')
    workflowHistoryEndpoint = apiBase + '/GetWorkflowAuditInfo?workflowId={workflowId}'
  },
  watch: {
    show(value, oldValue) {
      if (!oldValue && value && this.history === null) {
        const endpoint = workflowHistoryEndpoint.replace('{workflowId}', this.workflowId)

        axios
          .get(endpoint)
          .then(response => {
            this.history = response.data.map(function(entry) {
              return {
                DateDescription: moment(entry.CreatedDate).format('D MMMM YYYY H:mm'),
                Description: entry.Description,
                FullDescription: entry.FullDescription,
                CreatedBy: entry.Actor
              }
            })
          })
          .catch(error => {
            console.log(error)
            this.onClose()
          })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styling/custom/settings/__main.scss';
.c-lightbox.c-worflow-history-lightbox .c-card {
  padding: 15px 20px;

  .c-workflow-history-close-button {
    margin: -8px 0px 6px;
    color: $dark-blue;
    cursor: pointer;
  }

  .c-container-fluid {
    max-height: 500px;
    margin-top: 30px;
    padding: 15px 20px;
    overflow: auto;

    //set scrollbar properties for firefix
    overflow-y: scroll;
    background-color: $light-steel-blue;
    border-radius: 8px;
    scrollbar-color: #081f8f #dbe7f0 !important;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: $dark-blue;
      border-radius: 10px;
    }

    .row {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $bg-blue;
    }

    .c-workflow-history-date,
    .c-workflow-history-created-by,
    .c-workflow-history-description {
      font-size: 18px;
    }

    .no-entries-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      font-size: 18px;
    }
  }

  p + p {
    margin-top: 0; //reset of strange behaviour.
  }
}
</style>