<template>
  <div :class="{ empty: items.length == 0 && !loading }" class="timeline">
    <div class="actions">
      <div class="container-fluid">
        <div class="entity-filter">
          <span
            :class="{ active: items.length === 0 }"
            class="empty-timeline"
            v-if="!loading && (!customers || customers.length == 0)"
          />
          <div
            class="timeline-select"
            data-target="#filterList"
            data-toggle="collapse"
            v-if="customers && customers.length > 0"
          >
            <span class="selectlabel">{{ selectedFilter }}</span>
            <ul id="filterList">
              <li>
                <a
                  :data-filter-val="showAll"
                  @click="selectFilter(showAll)"
                  class="filter-select showall"
                  >{{ showAll }}</a
                >
              </li>
              <li :key="'cstmr' + index" v-for="(customer, index) in customers">
                <a
                  :data-filter-val="customer"
                  @click="selectFilter(customer)"
                  class="filter-select"
                  >{{ customer }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="timeline-wrapper">
      <div class="timeline-loader" v-show="loading" />
      <div class="container-fluid">
        <div class="timeline-items" v-if="!loading && items && items.length > 0">
          <a
            :class="[item.type, item.currentClass]"
            :data-filter="item.entity"
            :data-year="item.year"
            :href="item.url"
            :key="'itm' + index"
            class="item"
            style="visibility: hidden"
            v-for="(item, index) in items"
          >
            <div class="date">
              <span class="year" style="display: inline">{{ item.year }}</span>
              <span class="day">{{ item.day }}</span>
              <span class="month">{{ item.month }}</span>
            </div>
            <div :class="[item.statusClass, item.dateClass]" class="info">
              <p class="action">{{ item.title }}</p>
              <p class="text">{{ item.text }}</p>
              <p class="text">{{ item.subType }}</p>
              <span class="entity">{{ item.entity }}</span>
              <span class="c-state-indicator__dot--small" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import 'slick-carousel/slick/slick.min'

const slickerReady = false
let newData = false
const localKey = 'currentTimelineFilter'

//get stored value
const currentTimelineFilterValue = localStorage.getItem('currentTimelineFilter')

export default {
  mounted() {
    this.getData()
  },
  updated() {
    //needs to be done here because after the collection is populated does not guarantee they have been rendered
    if ($('.timeline-items > a').length > 0) {
      if (!slickerReady) {
        this.slickerPrepare()
        //set the filter selected based on the local storage
        $.each(this.customers, (i, elem) => {
          if (elem === currentTimelineFilterValue) {
            this.selectFilter(elem)
            //need to go to the correct slide if any are selected
            if ($('.item.active').length) {
              //will set a small timeout before action
              const indexActiveItem = $('.item.active').index('.item') - 2
              setTimeout(() => {
                $('.timeline-items').slick('slickGoTo', indexActiveItem)
              }, 500)
            }
            return false
          }
        })
      } else if (newData) {
        const slickCurrentSlide = $('.timeline-items').slick('slickCurrentSlide')
        $('.timeline-items').slick('unslick')
        this.slickerPrepare()
        $('.timeline-items').slick('slickGoTo', slickCurrentSlide)
        newData = false
      }
    }
  },
  data() {
    return {
      items: [],
      customers: [],
      selectedFilter: '',
      showAll: '',
      loading: false
    }
  },
  methods: {
    getData() {
      const qs = window.location.search.substring(1)
      const url = '/json/timeline/getasjson' + '?' + qs
      this.loading = true
      $.ajax({
        method: 'GET',
        url: url,
        async: true,
        cache: false
      }).done(res => {
        this.prepareDataForComponent(res)
        newData = this.items && this.items.length > 0
        this.loading = false
      })
    },
    prepareDataForComponent(res) {
      if (this.selectedFilter == '') {
        this.selectedFilter = res.FilterLabelSelect
      }
      this.showAll = res.FilterLabelShowAll
      if (res.CustomerNames) {
        this.customers = res.CustomerNames
      }
      if (res.Items) {
        this.items = []
        res.Items.forEach((elem, i) => {
          const includeYear =
            i === 0 ||
            res.Items[i - 1].ActionDateTimeYear !== res.Items[i].ActionDateTimeYear
          this.items.push({
            url: elem.TargetUrl,
            type: this.calculateType(elem.Type),
            currentClass: elem.IsCurrentDocument ? ' active' : '',
            entity: elem.CustomerName,
            year: includeYear ? elem.ActionDateTimeYear : '',
            statusClass: this.calculateStatus(elem.Status),
            dateClass: elem.ActionIsRequired ? ' overdue' : '',
            day: elem.ActionDateTimeDay,
            month: elem.ActionDateTimeMonthString,
            title: elem.TypeLocalized,
            text: elem.StatusLocalized,
            subType: elem.SubType
          })
        })
      }
    },
    slickerPrepare() {
      let indexActiveItem = 0
      if ($('.item.active').length) {
        indexActiveItem = $('.item.active').index('.item') - 2
      }
      $('.timeline-items').slick({
        initialSlide: indexActiveItem,
        infinite: false,
        centerMode: false,
        swipeToSlide: true,
        mobileFirst: true,
        centerPadding: 50,
        speed: 75,
        responsive: [
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 475,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 670,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 1030,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 1250,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 5
            }
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 6
            }
          },
          {
            breakpoint: 1850,
            settings: {
              slidesToShow: 7
            }
          },
          {
            breakpoint: 2100,
            settings: {
              slidesToShow: 8
            }
          },
          {
            breakpoint: 2350,
            settings: {
              slidesToShow: 9
            }
          }
        ]
      })
    },

    calculateType(type) {
      switch (type) {
        case 100:
          return 'declaration'
        case 200:
          return 'supplyrequest'
        case 202:
          return 'supplyrequest'
        default:
          return ''
      }
    },
    calculateStatus(status) {
      switch (status) {
        case 100:
          return 'neutral action-required'
        case 150:
          return 'inactive'
        case 200:
          return 'partly-accepted'
        case 300:
          return 'declined action-required'
        case 500:
          return 'accepted'
        default:
          return ''
      }
    },

    selectFilter(value) {
      if (value !== this.showAll) {
        this.selectedFilter = value
        $('.timeline-items').slick('slickUnfilter')
        $('.timeline-items').slick(
          'slickFilter',
          'a[data-filter=' + "'" + value + "'" + ']'
        )
        localStorage.setItem(localKey, value)
      } else {
        this.selectedFilter = value
        localStorage.removeItem(localKey)
        $('.timeline-items').slick('slickUnfilter')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styling/custom/settings/__main.scss';

.timeline-select {
	ul {
    width: 18px;
    max-height: 25vh;
		overflow: hidden;
		overflow-y: scroll;
    scrollbar-color: $blue $light-steel-blue !important;
    scrollbar-width: thin;
        
		&::-webkit-scrollbar {
			width: 8px;
		}
		&::-webkit-scrollbar-track {
			background-color: rgba(255, 255, 255, 0.1);
			border-radius: 2em;
		}
		&::-webkit-scrollbar-thumb {
			background-color: $blue;
			border-radius: 2em;
		}
	}
}
</style>
