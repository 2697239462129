



































import Vue from 'vue'

export default Vue.extend({
  name: 'maz-alert',

  props: {
    message: {
      type: String,
      default: ''
    },
    linkUrl: {
      type: String,
      default: ''
    },
    linkName: {
      type: String,
      default: ''
    },
    isDark: {
      type: Boolean,
      default: false
    },
    isMedium: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: false
    },
    isDanger: {
      type: Boolean,
      default: false
    },
    isInfo: {
      type: Boolean,
      default: false
    }
  }
})
