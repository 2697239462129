<template>
  <div class="d-md-flex justify-content-between qa-main-heading">
    <div class="o-separator o-separator--medium d-sm-none" />

    <maz-title
      :title_client_name="supplyRequestHeader.client.name"
      :title_client_url="supplyRequestHeader.client.url"
      :title_main="supplyRequestHeader.title"
    />
    <div class="o-separator o-separator--small d-md-none d-none d-sm-block" />
    <div class="o-separator o-separator--extra-small d-sm-none" />

    <div
      class="mb-0 u-pt-7px d-flex justify-content-between justify-content-sm-start flex-nowrap"
    >
      <div class="c-status u-mr-40 float-left float-right-md">
        <maz-state-indicator
          :state_code="supplyRequestHeader.state.code"
          :state_label="supplyRequestHeader.state.label"
          class="c-state-indicator--big float-left"
        />
      </div>
      <div class="float-right c-status-icons">
        <div class="c-icons c-icons--header">
          <a
            @click="onUploadClick"
            class="c-icons__item c-icons__item--manual-upload"
            v-if="supplyRequestPermissions.maySendToDMS"
          />
          <a
            :href="supplyRequestHeader.download.downloadZipUrl"
            @click="onDownloadClick"
            class="c-icons__item c-icons__item--download"
            v-if="supplyRequestPermissions.mayDownloadZip"
          />
          <maz-action-icon-clone
            :action_label_clone_button="
              supplyRequestHeader.actionIcons.labels.cloneSupplyRequestButton
            "
            :action_label_clone_intro="
              supplyRequestHeader.actionIcons.labels.cloneSupplyRequestIntro
            "
            :action_label_clone_rollover_button="
              supplyRequestHeader.actionIcons.labels.cloneSupplyRequestRolloverButton
            "
            :action_label_clone_rollover_intro="
              supplyRequestHeader.actionIcons.labels.cloneSupplyRequestRolloverIntro
            "
            :action_label_clone_rollover_title="
              supplyRequestHeader.actionIcons.labels.cloneSupplyRequestRolloverTitle
            "
            :action_label_clone_search_hint="
              supplyRequestHeader.actionIcons.labels.cloneSupplyRequestSearchHint
            "
            :action_label_clone_title="
              supplyRequestHeader.actionIcons.labels.cloneSupplyRequestTitle
            "
            :store="store"
            :supplyRequestCustomers="supplyRequestCustomers"
            :supplyRequestId="supplyRequestId"
            :supplyRequestPermissions="supplyRequestPermissions"
          />
          <a
            class="c-icons__item c-icons__item--trash"
            data-target="#modalDeleteSupplyConfirmation"
            data-toggle="modal"
            v-if="supplyRequestPermissions.mayDelete"
          />
        </div>

        <!-- Start of modal confirmation deleting supply request -->
        <div
          aria-hidden="true"
          class="modal fade"
          id="modalDeleteSupplyConfirmation"
          role="dialog"
          tabindex="-1"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-dialog--auto-width"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title">
                  {{ labels.supplyRequestConfirmationDeleteSupplyRequestTitle }}
                </h3>
                <button
                  aria-label="Close"
                  class="close"
                  data-dismiss="modal"
                  type="button"
                />
              </div>
              <div class="modal-body modal-body--margin-bottom">
                <p>{{ labels.supplyRequestConfirmationDeleteSupplyRequest }}</p>
                <div class="o-separator o-separator--small" />
              </div>
              <div class="modal-footer d-flex flex-wrap flex-sm-nowrap">
                <maz-button
                  :label="labels.cancel"
                  aria-label="Close"
                  class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
                  data-dismiss="modal"
                />
                <maz-button
                  :label="labels.confirm"
                  @click.native="onDeleteClick"
                  aria-label="Close"
                  class="c-btn--green order-1 order-sm-2"
                  data-dismiss="modal"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- End of modal confirmation deleting supply request -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import mazTitle from '../../general/mazTitle'
import mazButton from '../../general/mazButton'
import mazStateIndicator from '../../general/mazStateIndicator'
import mazActionIconClone from '../../general/mazActionIconClone'

const _targetStore = 'supplyRequest2DetailStore/'

export default {
  name: 'maz-supply-request-header',
  components: {
    'maz-button': mazButton,
    'maz-title': mazTitle,
    'maz-state-indicator': mazStateIndicator,
    'maz-action-icon-clone': mazActionIconClone
  },
  props: {
    supply_request_header: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      store: 'supplyRequest2DetailStore/'
    }
  },
  computed: {
    ...mapGetters({
      supplyRequestId: _targetStore + 'ID',
      supplyRequestHeader: _targetStore + 'SUPPLY_REQUEST_HEADER',
      supplyRequestCustomers: _targetStore + 'SUPPLY_REQUEST_CUSTOMERS',
      supplyRequestPermissions: _targetStore + 'SUPPLY_REQUEST_PERMISSIONS',
      labels: _targetStore + 'LABELS'
    })
  },
  methods: {
    onDownloadClick() {
      this.$store.dispatch(_targetStore + 'SET_CLICKED_STATUS_OF_DOWNLOAD')
    },
    onUploadClick() {
      this.$store.dispatch(_targetStore + 'POST_MANUAL_UPLOAD', this.supplyRequestId)
    },
    onDeleteClick() {
      const body = {
        supplyRequestId: this.supplyRequestId
      }

      this.$store.dispatch(_targetStore + 'DELETE_SUPPLY_REQUEST', body)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-status-icons {
  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 20px;
    right: 12px;
  }
}
</style>
