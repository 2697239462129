import HelpersService from '@/services/modules/helpersService'
import Vue from 'vue'
import feedbackDialog from './modules/feedbackDialog.vue'
import timelineGeneral from './modules/timelineGeneral'

export function registerGenerals() {
  const modules = [timelineGeneral]

  modules.forEach((general: any) => {
    const generalId = general.el.replace('#', '')
    const activeGeneral = document.getElementById(generalId)

    if (HelpersService.isNotNullOrUndefined(activeGeneral)) {
      new Vue(general)
    }
  })

  const tsModules = [
    {
      component: feedbackDialog,
      el: 'feedback-dialog'
    }
  ]

  tsModules.forEach(module => {
    if (document.getElementsByClassName(module.el)) {
      new module.component({ el: `.${module.el}` })
    }
  })
}
