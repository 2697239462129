import { localization } from './localization'

export default () => ({
  clientList: {
    labels: { ...localization.clientsTable.labels },
    rows: [] as object[]
  },
  searchClientList: [] as object[],
  currentPage: 1 as number,
  maxPageCounter: 1 as number,
  loader: true as boolean,
  messages: { ...localization.messages },
  labels: { ...localization.labels },
  customerPermission: {} as MazInterfaces.ClientMaintenance.CustomerPermission,
  addUrl: '' as string
})
