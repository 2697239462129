
import store from '@/store'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

//Required components
import mazWizardSRHeader from '@/components/custom/mazWizardSRHeader/mazWizardSRHeader.vue'
import mazWizardSRFilters from '@/components/custom/mazWizardSRFilters/index.vue'
import mazWizardSRTemplatePreview from '@/components/custom/mazWizardSRTemplatePreview/index.vue'

const addStoreModule = namespace('supplyRequest2AddStore')

@Component({
  store: store,
  components: {
    'maz-wizard-sr-header': mazWizardSRHeader,
    'maz-wizard-sr-filters': mazWizardSRFilters,
    'maz-wizard-sr-template-preview': mazWizardSRTemplatePreview
  }
})
export default class SupplyRequest2Add extends Vue {
  @addStoreModule.Action('GET_SUPPLY_REQUEST_WIZARD_INITIAL_DATA')
  GET_SUPPLY_REQUEST_WIZARD_INITIAL_DATA!: (id: string) => void

  @addStoreModule.Getter('ID') id!: string
  @addStoreModule.Getter('SHOW_PREVIEW') showPreview!: boolean

  mounted() {
    this.GET_SUPPLY_REQUEST_WIZARD_INITIAL_DATA(this.id)
  }
}
