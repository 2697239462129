import { camelCase, isArray, isEmpty, isObject, upperFirst } from 'lodash'
export default {
  momentDateFormat: 'DD-MM-YYYY',
  momentDateAndTimeFormat: 'DD-MM-YYYY HH:mm',
  isNotNullOrUndefined(param) {
    let result = false
    const tempParam = param || null

    if (tempParam !== null && tempParam !== undefined) {
      result = true
    }

    return result
  },
  buildEndpointRequest(uri, expressionToBeReplaced, param) {
    let result = null

    if (this.isNotNullOrUndefined(uri)) {
      result = uri

      if (
        this.isNotNullOrUndefined(expressionToBeReplaced) &&
        this.isNotNullOrUndefined(param)
      ) {
        result = result.replace(expressionToBeReplaced, param)
      }
    }

    return result
  },
  downloadFile(param) {
    const downloadFileURL = window.location.origin + param

    let tempDownloadContainer = document.createElement('a')
    tempDownloadContainer.setAttribute('id', param)
    tempDownloadContainer.setAttribute('download', true)
    tempDownloadContainer.setAttribute('href', downloadFileURL)
    tempDownloadContainer.click()
    tempDownloadContainer = null
  },
  getSearchId(expressionToBeReplaced, param) {
    let result = null

    if (this.isNotNullOrUndefined(param)) {
      result = param

      if (
        this.isNotNullOrUndefined(expressionToBeReplaced) &&
        this.isNotNullOrUndefined(param)
      ) {
        result = result.replace(expressionToBeReplaced, '')
      }
    }

    return result
  },
  convertObjectKeysToCamelCase(data) {
    let result = {}

    if (!isEmpty(data)) {
      Object.entries(data).map(entry => {
        const key = camelCase(entry[0])
        const value = entry[1]

        if (isArray(value)) {
          const convertedArray = value.map(item => {
            if (isObject(item)) {
              return this.convertObjectKeysToCamelCase(item)
            }
          })
          result = { ...result, [key]: convertedArray }
          return result
        }

        if (isObject(value)) {
          result = { ...result, [key]: this.convertObjectKeysToCamelCase(value) }
          return result
        }

        result = { ...result, [key]: value }
      })
      return result
    } else {
      return data
    }
  },
  convertObjectKeysToPascalCase(data) {
    let result = {}

    if (!isEmpty(data)) {
      Object.entries(data).map(entry => {
        const key = upperFirst(camelCase(entry[0]))
        const value = entry[1]

        if (isArray(value)) {
          const convertedArray = value.map(item => {
            if (isObject(item)) {
              return this.convertObjectKeysToCamelCase(item)
            }
          })
          result = { ...result, [key]: convertedArray }
          return result
        }

        if (isObject(value)) {
          result = { ...result, [key]: this.convertObjectKeysToPascalCase(value) }
          return result
        }

        result = { ...result, [key]: value }
      })
      return result
    } else {
      return data
    }
  },
  cloneObject(object) {
    return JSON.parse(JSON.stringify(object))
  }
}

export function generateGUID() {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
}

export function getActionStatusCode(statusCode) {
  //TODO: These codes were implemented as there was no way to better implement this logic
  // without reworking the API endpoint from which we fetch the value.
  // This should be refactored as well as the API endpoint to fit with the new project setup

  let returnedStatusCode = '0'
  switch (statusCode) {
    case 5:
      returnedStatusCode = '0'
      break
    case 10:
      returnedStatusCode = '1'
      break
    case 20:
      returnedStatusCode = '2'
      break
    case 30:
      returnedStatusCode = '3'
      break
    default:
      break
  }
  return returnedStatusCode
}
