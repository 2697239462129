<template>
  <div class="c-task-detail-container my-5">
    <div class="c-task-details-content" v-if="!loading && !showNotFound">
      <div class="c-page-header row">
        <div class="col-xs-12 col-sm-8">
          <h1 class="c-task-detail-name">{{ Name }}</h1>
          <h2>
            <a class="c-task-detail-customer my-1 u-mb-md-0" v-bind:href="CustomerUrl">
              {{ CustomerName }}
            </a>
          </h2>
        </div>
        <div class="col-xs-12 col-sm-4">
          <div class="workflow-task-due-date-container">
            <p>
              <b>{{ translate("WorkflowDetail.Labels.DueDate") }}: </b>
              <span class="inline">{{ activeTask.dueDateDescription }}</span>
            </p>

            <maz-task-status-circle
              v-bind:progress-status="activeTask.Status"
              v-bind:overdue-status="activeTask.DueDateStatus"
            />
          </div>
          <maz-button
            class="c-btn--ghost c-notify-btn__align-right mt-4"
            @click.native="toggleNotifyLightBox"
            v-if="MayNotify"
          >
            {{ translate("TaskDetail.Labels.Notify") }}
          </maz-button>
        </div>
      </div>

      <!--Task Details-->
      <div class="row">
        <div class="col-sm-6">
          <p class="workflow-task-description" v-if="showDescription">
            {{ activeTask.Description }}
          </p>
          <div
            class="workflow-task-last-comment js-lastcomment mb-2 u-mb-md-0"
            v-if="LastComment !== null"
          >
            <div class="d-flex align-items-center">
              <p
                class="workflow-task-last-comment-placed-by"
                v-if="LastComment.CreatedBy"
              >
                {{ LastComment.placedOnBy }}
              </p>
              <span class="c-comment__internal" v-if="LastComment.IsInternal">
                {{ translate("TaskDetail.Labels.Internal") }}
              </span>
            </div>

            <p class="workflow-task-last-comment-description">
              {{ LastComment.Description }}
            </p>
          </div>
        </div>
        <div class="col-sm-6">
          <h5 class="workflow-task-assigned-users-title">
            {{ translate("TaskDetail.Labels.ResponsibleUsers") }}:
          </h5>
          <p
            class="workflow-task-assigned-user"
            v-for="(user, index) in assignedUsers"
            v-bind:key="index"
          >
            {{ user.Name }}
            <span v-if="user.IsDelegated">{{
              "(" + translate("TaskDetail.Labels.IsDelegated") + ")"
            }}</span>
          </p>
        </div>
      </div>

      <!--Task Actions-->
      <div class="step-actions-container">
        <maz-button
          class="c-btn--ghost mb-1"
          @click.native="toggleDelegateLightBox"
          v-if="MayDelegate && !isSigningStep"
        >
          {{ translate("TaskDetail.Labels.Delegate") }}
        </maz-button>
        <maz-button
          class="c-btn--red mb-1"
          @click.native="toggleRejectLightBox"
          v-if="MayReject"
        >
          {{ translate("TaskDetail.Labels.Reject") }}
        </maz-button>
        <maz-button
          class="c-btn--green"
          @click.native="completeTask()"
          v-if="mayCompleteActiveTask"
          :disabled="signingFileInfected"
        >
          {{ translate("TaskDetail.Labels.Complete") }}
        </maz-button>
      </div>

      <!-- Documents to sign -->
      <div class="c-files is-pki-page" v-if="signingFilesPanelVisible">
        <h2 class="section-header">
          {{ translate("TaskDetail.Labels.DocumentToSign") }}
        </h2>
        <div
          class="section c-signingfiles-wrapper"
          v-bind:class="{ 'is-signed': Pki.isSigned }"
        >
          <div class="c-files-upload-files-container mb-1" v-if="uploadSignButtonOptions">
            <div class="d-flex">
              <maz-button class="c-btn mb-1" @click.native="toggleSigningDropzone">
                {{ translate("TaskDetail.Labels.UploadDocuments") }}
              </maz-button>
            </div>
            <maz-collapse class="c-comments">
              <dropzone
                v-show="showSigningDropzone"
                id="documentsSigningDropzone"
                class="vue-dropzone mt-3 mb-1"
                ref="taskUpload"
                v-on:vdropzone-success-multiple="dzSigningFileUploaded"
                v-bind:options="dropZoneSignOptions"
              >
                <input type="hidden" name="workflowId" v-bind:value="WorkflowId" />
                <input type="hidden" name="taskId" v-bind:value="TaskId" />
              </dropzone>
            </maz-collapse>
          </div>
          <div class="d-flex flex-wrap mb-1 ml-0">
            <a
              v-if="DownloadDocuSignFiles"
              class="c-btn c-download-all-button"
              :href="downloadSignedFilesUrl"
            >
              {{ translate("TaskDetail.Labels.DownloadAll") }}
            </a>
          </div>
          <div class="row mt-3 justify-content-md-between">
            <div
              class="col-sm-6 row mb-4"
              v-for="(file, index) in workflowSigningFiles"
              v-bind:key="index"
            >
              <div class="col-sm-2">
                <fa class="c-file-icon" :icon="['fal', file.iconClass]" />
              </div>
              <div class="col-sm-8">
                <a
                  class="c-file-name"
                  v-bind:class="{ 'c-file-name--blocked': !file.MayDownload }"
                  v-bind:href="file.DownloadUrl"
                >
                  {{ file.FileName }}
                </a>
                <p class="c-file-uploaded-date">
                  <b class="font-weight-bold"
                    >{{ translate("TaskDetail.Labels.DateUploaded") }}:</b
                  >
                  {{ file.CreatedDateDescription }}
                </p>
                <p class="c-file-uploader">
                  <b class="font-weight-bold"
                    >{{ translate("TaskDetail.Labels.UploadedBy") }}:</b
                  >
                  {{ file.Uploader !== null ? file.Uploader.FullName : "N/A" }}
                </p>
                <p class="c-file-scan-status">
                  <b class="font-weight-bold"
                    >{{ translate("TaskDetail.Labels.ScanStatus") }}:
                  </b>
                  <span :class="{ 'c-file-scan-status--infected': file.isInfected }"
                    >{{ file.scanStatus }} {{ file.scanDateDescription }}</span
                  >
                </p>
                <p class="mt-3">
                  <maz-button
                    v-if="MaySign && MayPKISign"
                    @click.native="signDocument"
                    data-pki-auth-url="pkiAuth"
                    data-pki-sign-url="pkiSign"
                    v-bind:class="{ 'has-spinner': Pki.isLoading }"
                    class="c-btn c-btn--green c-signing-btn"
                  >
                    {{ translate("TaskDetail.Label.SignDocument") }}
                    <span class="ml-1 c-signing-btn__spinner">
                      <fa icon="spinner" spin size="1x"
                    /></span>
                  </maz-button>
                </p>
              </div>
              <div class="col-sm-2">
                <button
                  class="c-file-trash-button"
                  @click="deleteSignFile($event, file, index)"
                  v-if="file.IsDeletable"
                >
                  <i><fa :icon="['fal', 'trash-alt']" /></i>
                </button>
              </div>
            </div>
            <div class="col-sm-12 mt-1">
              <button
                v-if="MayDocuSign && DocuSignPageURL"
                @click="startDocuSignProcess"
                class="c-btn c-btn--green order-1 order-sm-2"
              >
                {{ translate("TaskDetail.Label.SignDocument") }}
                <!-- <span class="ml-1 c-signing-btn__spinner">
                  <fa icon="spinner" spin size="1x"
                /></span> -->
              </button>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="c-msg-wrapper">
              <!-- Success message -->
              <div
                class="c-msg c-msg--success d-flex align-items-center"
                v-if="showPkiSuccessSigning"
              >
                <div class="col-sm-2 text-center">
                  <div class="c-msg__icon-rosette" />
                </div>
                <div class="col-sm-12 c-msg__message">
                  <span>
                    {{ translate("TaskDetail.Messages.SuccessSigning") }}
                  </span>
                </div>
              </div>
              <!-- Error message -->
              <div
                class="c-msg c-msg--error d-flex align-items-center"
                v-if="Pki.showErrorSigning"
              >
                <div class="col-sm-2 text-center">
                  <i><fa :icon="['fas', 'times-circle']" /></i>
                </div>
                <div class="col-sm-10 c-msg__message">
                  {{ translate("TaskDetail.Messages.SuccessSigning") }}
                </div>
              </div>
            </div>
          </div>
          <p
            class="c-no-content-message"
            v-if="!workflowSigningFiles || workflowSigningFiles.length === 0"
          >
            {{ translate("TaskDetail.Messages.NoDocumentsToShow") }}
          </p>
        </div>
      </div>

      <!--Documents-->
      <div class="c-files">
        <h2 class="section-header">
          {{ translate("WorkflowDetail.Labels.Documents") }}
        </h2>
        <div class="section">
          <div class="c-files-upload-files-container" v-if="MayUploadFiles">
            <div class="d-flex">
              <maz-button
                class="c-btn mb-1 u-mb-md-0 u-mr-md-1"
                @click.native="toggleDropzone"
              >
                {{ translate("TaskDetail.Labels.UploadDocuments") }}
              </maz-button>
              <a
                v-if="showDownloadAllButton"
                class="c-btn download-all-button"
                :href="downloadFilesUrl"
              >
                {{ translate("WorkflowDetail.Labels.DownloadAll") }}
              </a>
            </div>
            <maz-collapse class="c-comments scrollbars">
              <dropzone
                v-show="showDropzone"
                id="documentsDropzone"
                class="vue-dropzone mt-1"
                ref="taskUpload"
                v-on:vdropzone-success-multiple="dzFileUploaded"
                v-bind:options="dropzoneOptions"
              >
                <input type="hidden" name="workflowId" v-bind:value="WorkflowId" />
                <input type="hidden" name="taskId" v-bind:value="TaskId" />
              </dropzone>
            </maz-collapse>
          </div>
          <div class="row justify-content-md-between">
            <div
              class="col-sm-6 row mb-4"
              v-for="(file, index) in workflowFiles"
              v-bind:key="index"
            >
              <div class="col-sm-2">
                <fa class="c-file-icon" :icon="['fal', file.iconClass]" />
              </div>
              <div class="col-sm-8">
                <a
                  class="c-file-name"
                  v-bind:class="{ 'c-file-name--blocked': !file.MayDownload }"
                  v-bind:href="file.DownloadUrl"
                >
                  {{ file.FileName }}
                </a>
                <p class="c-file-uploaded-date">
                  <b class="font-weight-bold"
                    >{{ translate("TaskDetail.Labels.DateUploaded") }}:</b
                  >
                  {{ file.CreatedDateDescription }}
                </p>
                <p class="c-file-uploader">
                  <b class="font-weight-bold"
                    >{{ translate("TaskDetail.Labels.UploadedBy") }}:</b
                  >
                  {{ file.Uploader !== null ? file.Uploader.FullName : "N/A" }}
                </p>
                <p class="c-file-scan-status">
                  <b class="font-weight-bold"
                    >{{ translate("TaskDetail.Labels.ScanStatus") }}:
                  </b>
                  {{ file.scanStatus }} {{ file.scanDateDescription }}
                </p>
              </div>
              <div class="col-sm-2">
                <button
                  class="c-file-trash-button"
                  @click="deleteFile($event, file, index)"
                  v-if="file.IsDeletable"
                >
                  <i><fa :icon="['fal', 'trash-alt']" /></i>
                </button>
              </div>
            </div>
          </div>
          <p
            class="c-no-content-message"
            v-if="!workflowFiles || workflowFiles.length === 0"
          >
            {{ translate("TaskDetail.Messages.NoDocumentsToShow") }}
          </p>
        </div>
      </div>

      <!--Comments-->
      <div class="c-comments">
        <h2 class="section-header">
          {{ translate("TaskDetail.Labels.Comments") }}
        </h2>

        <div class="section">
          <div class="loading-container" v-if="savingComment">
            <fa icon="spinner" spin size="1x" />
          </div>
          <div
            class="c-comments-new-comment-container"
            v-if="MayAddComment && !savingComment"
          >
            <maz-button class="c-btn u-mb-1em" @click.native="toggleNewComment">
              {{ translate("TaskDetail.Labels.AddComments") }}
            </maz-button>
            <maz-collapse class="c-comments scrollbars mt-2">
              <div v-show="showNewComment">
                <div class="c-comments-new-comment-textarea mt-2">
                  <textarea
                    class="c-comments-new-comment-text-area-input"
                    v-model="newComment"
                    v-bind:placeholder="
                      translate('TaskDetail.Labels.WriteCommentsPlaceholder')
                    "
                  />
                </div>
                <div class="submit-button-container">
                  <maz-checkbox v-model="isInternalComment">
                    <p>{{ translate("TaskDetail.Labels.SubmitInternalComment") }}</p>
                  </maz-checkbox>
                  <span class="flex-fill" />
                  <maz-button
                    class="c-btn submit-comment-button"
                    v-bind:disabled="newComment.trim() === ''"
                    @click.native="addComment()"
                  >
                    {{ translate("TaskDetail.Labels.Submit") }}
                  </maz-button>
                </div>
              </div>
            </maz-collapse>
          </div>
          <transition-group name="fade">
            <div class="row" v-for="(comment, index) in comments" :key="index">
              <div class="col-sm-2">
                <p class="c-comment-date u-fixed-line-height text-nowrap">
                  {{ comment.presentationDate }}
                </p>
              </div>
              <div class="col-sm-10 pl-sm-2">
                <p class="c-comment-description u-fixed-line-height">
                  {{ comment.Description }}
                </p>
                <p
                  class="c-comment-created-by d-flex align-items-center"
                  v-if="comment.CreatedBy"
                >
                  {{ comment.infoDescription }}
                  <span class="c-comment__internal" v-if="comment.IsInternal">
                    {{ translate("TaskDetail.Labels.Internal") }}
                  </span>
                </p>
              </div>
            </div>
          </transition-group>
          <p class="c-no-content-message" v-if="!comments || comments.length === 0">
            {{ translate("TaskDetail.Messages.NoCommentsToShow") }}
          </p>
        </div>
      </div>

      <h2 class="section-header">
        {{ translate("TaskDetail.Labels.Workflow") }}
      </h2>
      <div class="workflow-metro-navigation">
        <div
          class="workflow-metro-navigation-task"
          v-for="(task, index) in tasks"
          v-bind:key="index"
        >
          <div class="workflow-metro-navigation-link pr-1">
            <p class="workflow-metro-navigation-due-date">
              {{ task.dueDateDescription }}
            </p>
            <a class="workflow-metro-navigation-name" v-bind:href="task.Url">
              {{ task.Name }}
            </a>
            <a class="workflow-metro-navigation-circle u-mt-05em" v-bind:href="task.Url">
              <maz-task-status-circle
                :progress-status="task.Status"
                :overdue-status="task.DueDateStatus"
              />
            </a>
          </div>
        </div>
      </div>

      <!--Workflow History Button-->
      <a
        class="c-workflow-history-button"
        v-on:click="toggleWorkflowHistoryLightBox(true)"
      >
        {{ translate("TaskDetail.Labels.ShowWorkflowHistory") }}
      </a>
      <!--Error Card-->
      <maz-alert
        v-if="showError"
        class="mb-2"
        :message="errorMessage"
        :is-danger="true"
      />
    </div>

    <div class="loading-container" v-show="loading">
      <fa icon="spinner" spin size="2x" />
    </div>

    <div class="not-found-container" v-if="showNotFound">
      <h3>
        {{ translate("TaskDetail.Messages.NotFoundTitle") }}
      </h3>
      <p>
        {{ translate("TaskDetail.Messages.NotFoundText") }}
      </p>
    </div>

    <!--Reject Light Box-->
    <maz-reject-task-lightbox
      :show="showRejectLightBox"
      :workflow-id="WorkflowId"
      :task-id="TaskId"
      :on-close="toggleRejectLightBox"
      v-if="WorkflowId !== null && TaskId !== null"
      @confirm="reloadTaskDetails"
    />
    <!--Delegate Light Box-->
    <maz-delegate-users-lightbox
      :show="showDelegateLightBox"
      :workflow-id="WorkflowId"
      :task-id="TaskId"
      :oid="Oid"
      :cid="Cid"
      :serviceid="ServiceId"
      :assigned-users.sync="assignedUsers"
      :on-close="onDelegateTaskClose"
      v-if="WorkflowId !== null && TaskId !== null"
    />
    <!--Notify Users-->
    <maz-notify-users-lightbox
      :show="showNotifyLightBox"
      :workflow-id="WorkflowId"
      :task-id="TaskId"
      :assigned-users="assignedUsers"
      :on-close="toggleNotifyLightBox"
      v-if="WorkflowId !== null && TaskId !== null"
    />
    <!--Workflow History-->
    <maz-workflow-history-lightbox
      :show="showWorkflowHistoryLightBox"
      :workflow-id="WorkflowId"
      :on-close="toggleWorkflowHistoryLightBox"
      :can-export="canExportWorkflowHistory"
      :export-url="workflowHistoryExportUrl"
      v-if="WorkflowId !== null && TaskId !== null"
    />
    <!--Confirmation lightbox-->
    <maz-confirmation-lightbox
      :show="showConfirmFileDeleteLightBox"
      :message="deleteFileMessage"
      @confirm="deleteFileCallback(true)"
      @cancel="deleteFileCallback(false)"
    />
    <!--Complete TaskConfirmation lightbox-->
    <maz-modal
      v-if="showTaskConfirmationModal"
      v-on:on-close="cancelCompleteTask()"
      size="modal-md"
    >
      <div>
        <p>{{ translate("TaskDetail.Messages.CompleteConfirm") }}</p>
      </div>
      <template slot="modal-footer">
        <div class="d-flex flex-row align-items-end">
          <maz-button v-on:click.native="cancelCompleteTask()" class="c-btn c-btn--ghost">
            {{ translate("Labels.Common.Cancel") }}</maz-button
          >
          <maz-button v-on:click.native="acceptActiveTask()">{{
            translate("Labels.Common.Confirm")
          }}</maz-button>
        </div>
      </template>
    </maz-modal>
  </div>
</template>
<script>
import shared from "./shared";

const pkiAuth = window.__PKI_URLS__?.pKiAuthURL;
const pkiSign = window.__PKI_URLS__?.pKiSignURL;

export default {
  name: "mazTaskDetailsEmployee",
  mixins: [shared],
  data() {
    return {
      pkiAuth,
      pkiSign,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styling/custom/settings/__main.scss";
@import "./_taskdetail.scss";
@import "./_dropzone.scss";
</style>

<style lang="scss">
@import "./_pkisigning.scss";
</style>
