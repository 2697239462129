<template>
  <div v-if="supplyRequestPermissions.mayClone">
    <div
      @click="onCloneClick"
      class="c-icons__item c-icons__item--clone c-icons__item--clone-click-area"
      data-target="#modalCloneSupplyRequest"
      data-toggle="modal"
    />

    <!-- Modal clone the supply request -->
    <div
      aria-hidden="true"
      class="modal fade"
      id="modalCloneSupplyRequest"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{{ action_label_clone_title }}</h3>
            <button
              @click="onCancel"
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            />
          </div>
          <div class="modal-body modal-body--margin-bottom">
            <p class="mb-2">{{ action_label_clone_intro }}</p>
            <div class="row">
              <div class="col-md-6">
                <!-- TODO delete example items and replaced them with real data -->
                <maz-auto-complete
                  :items="supplyRequestCustomers"
                  :search-keys="['name', 'customerNumber']"
                  @onChange="(val) => retrieveAutocompleteItems(val)"
                  @onIsItemSelected="(val) => (isDisabled = !val)"
                  @setCustomerId="getCustomerId"
                  display-key="name"
                >
                  <template slot-scope="slotProps">
                    <input
                      :placeholder="action_label_clone_search_hint"
                      :value="slotProps.search"
                      @input="slotProps.onChange"
                      @keyup.down="slotProps.onArrowDown"
                      @keyup.enter="slotProps.onEnter"
                      @keyup.up="slotProps.onArrowUp"
                      class="form-control c-forms__input c-forms__input--search"
                      ref="searchInput"
                      type="text"
                    />
                  </template>
                </maz-auto-complete>
              </div>
            </div>
            <div class="o-separator o-separator--extra-small" />
            <div class="text-right">
              <maz-button
                :disabled="isDisabled"
                :label="action_label_clone_button"
                @click.native="onCopySupplyRequest"
                aria-label="Close"
                data-dismiss="modal"
              />
            </div>
            <div class="o-separator o-separator--extra-small" />
            <div class="o-separator o-separator--line" />
            <div class="o-separator o-separator--small" />
            <h3 class="modal-title">{{ action_label_clone_rollover_title }}</h3>
            <p class="mt-3">{{ action_label_clone_rollover_intro }}</p>
          </div>
          <div class="modal-footer">
            <maz-button
              :label="action_label_clone_rollover_button"
              @click.native="onCloneSupplyRequest"
              aria-label="Close"
              data-dismiss="modal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mazButton from '../mazButton'
import mazAutoCompleteInput from '@/components/general/mazAutoCompleteInput'

export default {
  name: 'maz-action-icon-clone',
  components: {
    'maz-button': mazButton,
    'maz-auto-complete': mazAutoCompleteInput
  },
  props: {
    action_show_icon_trash: {
      required: false,
      type: Boolean,
      default() {
        return false
      }
    },
    action_show_icon_clone: {
      required: false,
      type: Boolean,
      default() {
        return false
      }
    },
    action_show_icon_download: {
      required: false,
      type: Boolean,
      default() {
        return false
      }
    },
    action_label_clone_title: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    action_label_clone_intro: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    action_label_clone_button: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    action_label_clone_search_hint: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    action_label_clone_rollover_title: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    action_label_clone_rollover_intro: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    action_label_clone_rollover_button: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    placeholder: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    supplyRequestPermissions: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    supplyRequestCustomers: {
      required: false,
      type: Array,
      default() {
        return []
      }
    },
    supplyRequestId: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    store: {
      required: false,
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      searchField: '',
      isDisabled: true,
      searchTerm: '',
      customerId: ''
    }
  },
  computed: {
    slotProperties() {
      return {
        onChange: this.onChange,
        onArrowDown: this.onArrowDown,
        onArrowUp: this.onArrowUp,
        onEnter: this.onEnter,
        search: this.search
      }
    }
  },
  methods: {
    retrieveAutocompleteItems(value) {
      this.$store.dispatch(this.store + 'GET_CUSTOMERS', value)

      this.searchTerm = value
    },
    onCloneClick(event) {
      this.clearContent()
      this.$emit('onCloneClick', event)
    },
    onDownloadClick(event) {
      this.$emit('onDownloadClick', event)
    },
    onTrashClick(event) {
      this.$emit('onTrashClick', event)
    },
    onCancel(event) {
      this.clearContent()
      this.$emit('onCancel', event)
    },
    clearContent() {
      this.$refs.searchInput.value = ''
    },
    onCopySupplyRequest(event) {
      const body = {
        SupplyRequestId: this.supplyRequestId,
        CustomerId: this.customerId
      }

      this.$store.dispatch(this.store + 'POST_COPY_SUPPLY_REQUEST', body)
      this.$emit('onCopySupplyRequest', event)
      this.clearContent()
    },
    onCloneSupplyRequest(event) {
      this.$store.dispatch(this.store + 'POST_CLONE_SUPPLY_REQUEST', this.supplyRequestId)
      this.$emit('onCloneSupplyRequest', event)
      this.clearContent()
    },
    getCustomerId(value) {
      this.customerId = value
      return value
    }
  }
}
</script>
