<template>
  <div class="c-assign-contacts">
    <div>
      <div v-if="showAssignContactsLink">
        <span
          :key="index + contact.name"
          v-for="(contact, index) in supplyRequestAssignContacts.assignedContactsList"
        >
          {{ contact.name }}
          <span
            v-if="index != supplyRequestAssignContacts.assignedContactsList.length - 1"
            >,</span
          >
        </span>
      </div>
      <div v-if="isEmployeePortal">
        <div v-if="showAssignContactsLink">
          <a
            @click="createCloneOfAssignedContacts"
            class="e-link-bold"
            data-target="#modalAssignContacts"
            data-toggle="modal"
            >{{ labels.supplyRequestEditResponsibleContacts }}</a
          >
        </div>
        <div v-else>
          <maz-button
            @click.native="createCloneOfAssignedContacts"
            class="c-btn--assign-contacts w-auto"
            data-target="#modalAssignContacts"
            data-toggle="modal"
          />
        </div>
      </div>
    </div>

    <!-- Start of modal assign contacts -->
    <div
      aria-hidden="true"
      class="modal fade"
      id="modalAssignContacts"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{{ labels.supplyRequestEditResponsibleContacts }}</h3>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button" />
          </div>
          <div class="modal-body modal-body--margin-bottom">
            <div class="row">
              <div class="col-12">
                <div
                  :key="index"
                  class="e-select-list"
                  v-for="(contact, index) in cloneOfContactsList"
                >
                  <span @click="selectContact(contact, index)">{{ contact.name }}</span>
                </div>
                <div
                  class="o-separator o-separator--extra-small"
                  v-if="cloneOfContactsList.length > 0"
                />
                <div v-if="cloneOfAssignedContactsList.length > 0">
                  <label>{{ labels.supplyRequestSelectedResponsibleContacts }}</label>
                  <div
                    :key="index"
                    v-for="(contact, index) in cloneOfAssignedContactsList"
                  >
                    <maz-button
                      @click.native="removeContact(contact, index)"
                      class="w-100 d-flex flex-row justify-content-between mb-2"
                    >
                      <div class="is-cutoff">{{ contact.name }}</div>
                      <i class="close close--white u-flex-none" />
                    </maz-button>
                  </div>
                  <div class="o-separator o-separator--small" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="form-group float-sm-right mb-0 d-flex flex-wrap flex-sm-no-wrap"
                >
                  <maz-button
                    :label="labels.cancel"
                    aria-label="Close"
                    class="c-btn--empty order-2 order-sm-1"
                    data-dismiss="modal"
                  />
                  <maz-button
                    :label="labels.save"
                    @click.native="onSubmitAssignedContacts"
                    aria-label="Close"
                    class="c-btn--blue order-1 order-sm-2"
                    data-dismiss="modal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of modal assign contacts -->
  </div>
</template>

<script>
import _ from 'lodash'
import mazButton from '../mazButton'
import Api from '@/api'

const _isEmployeePortal = Api.config.portalType === 'Employee' ? true : false

export default {
  name: 'maz-assign-contacts',
  components: {
    'maz-button': mazButton
  },
  props: {
    labels: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    supplyRequestAssignContacts: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    store: {
      required: false,
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      cloneOfContactsList: '',
      cloneOfAssignedContactsList: '',
      showAssignContactsLink: false,
      removeContactTrigger: false,
      selectContactTrigger: false,
      isEmployeePortal: _isEmployeePortal
    }
  },
  created() {
    this.$emit('assignedContactsOnInitialLoad', this.cloneOfAssignedContactsList)
  },
  methods: {
    createCloneOfAssignedContacts() {
      this.cloneOfContactsList = JSON.parse(
        JSON.stringify(this.supplyRequestAssignContacts.contactsList)
      )
      this.cloneOfAssignedContactsList = JSON.parse(
        JSON.stringify(this.supplyRequestAssignContacts.assignedContactsList)
      )
    },
    selectContact(contact, index) {
      this.cloneOfContactsList.splice(index, 1)
      this.cloneOfAssignedContactsList.push(contact)

      this.selectContactTrigger = !this.selectContactTrigger
    },
    removeContact(contact, index) {
      this.cloneOfAssignedContactsList.splice(index, 1)
      this.cloneOfContactsList.push(contact)

      this.removeContactTrigger = !this.removeContactTrigger
    },
    onSubmitAssignedContacts() {
      const body = {
        contactsList: this.cloneOfContactsList,
        assignedContactsList: this.cloneOfAssignedContactsList
      }

      this.$store.dispatch(this.store + 'UPDATE_ASSIGN_CONTACTS', body)

      this.$emit(
        'onSubmitAssignedContacts',
        this.supplyRequestAssignContacts.assignedContactsList
      )
    }
  },
  watch: {
    selectContactTrigger: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.cloneOfAssignedContactsList = _.sortBy(
            this.cloneOfAssignedContactsList,
            o => o.name
          )
        }
      },
      deep: true
    },
    removeContactTrigger: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.cloneOfContactsList = _.sortBy(this.cloneOfContactsList, o => o.name)
        }
      },
      deep: true
    },
    supplyRequestAssignContacts: {
      handler(data) {
        if (data.assignedContactsList.length > 0) {
          this.showAssignContactsLink = true
        } else {
          this.showAssignContactsLink = false
        }
      },
      deep: true
    }
  }
}
</script>