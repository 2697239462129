export const mutations = {
  UPDATE_CLIENT_PROFILE_PAGE_TAB_INDEX_MUTATION(state: any, payload: number) {
    state.tabNavigation.activeTabIndex = payload
  },
  UPDATE_CLIENT_PROFILE_PAGE_LOADER_MUTATION(state: any, payload: boolean) {
    state.loader = payload
  },
  SET_CLIENT_PROFILE_TITLE_MUTATION(state: any, payload: any) {
    state.clientTitle = payload
  },
  SET_CLIENT_PROFILE_NUMBER_MUTATION(state: any, payload: any) {
    state.clientNumber = payload
  }
}
