import { localization } from './localization'

export default () => ({
  clientDetails: {} as MazInterfaces.ClientMaintenance.ContactClientDetails,
  editedClientDetails: {} as MazInterfaces.ClientMaintenance.ContactDetails,
  tabNavigation: {
    activeTabIndex: 0
  },
  validationErrors: [] as MazInterfaces.ClientMaintenance.ValidationError[],
  isEditMode: false,
  relationManagers: [] as MazInterfaces.ClientMaintenance.RelationManager[],
  genders: [] as MazInterfaces.ClientMaintenance.Gender[],
  languages: [] as MazInterfaces.ClientMaintenance.Language[],
  countries: [] as MazInterfaces.ClientMaintenance.Countries[],
  loader: {
    editContactDetails: false,
    permissions: false,
    clientServices: false,
    employees: false
  },
  contactOrganizationsPermissionsTable: {
    labels: { ...localization.labels.contactOrganizationsPermissionsTable },
    rows: [] as object[],
    type: undefined
  },
  clientServicesTable: {
    labels: { ...localization.labels.clientServicesTable },
    rows: [] as object[]
  },
  employeesConnectedToContact: [] as object[],
  employeesModal: {
    originalEmployeesList: [] as object[],
    selectedEmployeesList: [] as object[],
    availableEmployeesList: [] as object[]
  },
  organizationsModal: {
    originalOrganizationsList: [] as object[],
    selectedOrganizationsList: [] as object[],
    availableOrganizationsList: [] as object[]
  },
  ...localization,
  connectUrl: '' as string
})
