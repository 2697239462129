import { localization } from './localization'

export default () => ({
  loader: false as boolean,
  tabNavigation: {
    ...localization.tabNavigation,
    activeTabIndex: 0
  },
  clientTitle: '',
  clientNumber: '',
  labels: localization.labels,
  messages: { ...localization.messages }
})
