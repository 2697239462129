import { currentGetterContext } from './index'
export const getters = {
  LOADER: (state: any): boolean => state.loader,
  LABELS: (state: any) => state.labels,
  CONNECTED_ORGANIZATIONS: (state: any): object[] => state.connectedOrganizations,
  CONNECTED_PERMISSIONS: (state: any): object[] => state.organizationPermissionModal,
  MESSAGES(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.messages
  }
}
