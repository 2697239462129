export const mutations = {
  UPDATE_CONTACT_SERVICES_EDIT_MODE_MUTATION(state: any, payload: boolean) {
    state.isEditMode = payload
  },
  UPDATE_CLIENT_SERVICES_MUTATION(state: any, payload: { Services: [] }) {
    state.editedServiceDetails = payload.Services
  },
  UPDATE_LOADER_MUTATION(state: any, payload: any) {
    state.loader = { ...state.loader, ...payload }
  }
}
