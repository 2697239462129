export const mutations = {
  SET_LANGUAGES_MUTATION(
    state: any,
    payload: MazInterfaces.EmployeeMaintenance.Language[]
  ): void {
    state.languages = payload
  },
  SET_GENDERS_MUTATION(
    state: any,
    payload: MazInterfaces.EmployeeMaintenance.Gender[]
  ): void {
    state.genders = payload
  },
  SET_EMPLOYEE_PERMISSION_MUTATION(
    state: any,
    payload: MazInterfaces.EmployeeMaintenance.EmployeePermission
  ): void {
    state.employeePermission = payload
  },
  SET_SHOULD_REFRESH_ON_CLOSE_MUTATION(state: any) {
    state.shouldRefreshOnClose = true
  },
  RESET_SHOULD_REFRESH_ON_CLOSE_MUTATION(state: any) {
    state.shouldRefreshOnClose = false
  },
  SET_EMPLOYEE_URL_MUTATION(state: any, payload: string) {
    state.employeeUrl = payload
  },
  RESET_EMPLOYEE_URL_MUTATION(state: any) {
    state.employeeUrl = ''
  },
  RESET_VALIDATION_ERROR_MUTATION(state: any, payload: string) {
    const idx = state.validationErrors.findIndex(
      (obj: any) => obj.PropertyName === payload
    )
    if (idx > -1) {
      state.validationErrors.splice(idx, 1)
    }
  },
  SET_VALIDATION_ERRORS_MUTATION(state: any, payload: any) {
    state.validationErrors = payload
  },
  RESET_VALIDATION_ERRORS_MUTATION(state: any) {
    state.validationErrors = []
  }
}
