<template>
  <div :class="{ 'c-segment--edit': editState }" class="c-template-segment qa-segment">
    <div class="d-md-flex align-items-center position-relative">
      <div class="flex-fill">
        <div class="d-flex align-items-center flex-wrap flex-sm-nowrap" v-if="!editState">
          <maz-state-indicator
            :state_code="segment.isCompleteCode"
            :state_number="indexPosition"
            show_state_number
          />
          <div class="flex-fill order-3 order-sm-2">
            <h5 class="c-segment__title u-pt-14px u-pt-sm-0 float-left">
              {{ segment.title }}
            </h5>
          </div>
          <div class="c-icons c-icons--categories order-2 order-sm-3">
            <div class="c-icons__item c-icons__item--move" />
            <div
              @click="onEditSegment(segment.id)"
              class="c-icons__item c-icons__item--edit"
              ref="editIcon"
            />
            <div
              @click="onDeleteSegment(category, subCategory, segment)"
              class="c-icons__item c-icons__item--trash"
            />
          </div>
        </div>

        <!-- EDIT STATE -->
        <div class="d-flex align-items-center flex-wrap flex-sm-nowrap" v-if="editState">
          <maz-state-indicator
            :state_code="segment.isCompleteCode"
            :state_number="indexPosition"
            class="order-1"
            show_state_number
          />
          <div class="flex-fill order-3 order-sm-2">
            <div class="c-segment--edit__title-input">
              <div class="form-group mb-0">
                <input class="form-control" v-model="cloneOfSegmentItem.title" />
              </div>
            </div>
          </div>
          <div class="c-icons c-icons--categories order-2 order-sm-3">
            <div
              class="c-icons__item c-icons__item--edit c-icons__item--edit-highlighted"
            />
            <div
              class="c-icons__item c-icons__item--trash c-icons__item--trash-disabled"
            />
          </div>
        </div>
        <!-- END OF EDIT STATE -->
      </div>
    </div>
    <div class="c-segment__content" v-if="!editState">
      <div :id="segment.id" class="c-segment__body">
        <div class="o-separator o-separator--extra-small" />
        <div>{{ segment.description }}</div>

        <div class="c-segment-list" v-if="segment.segmentItems.length > 0">
          <ul class="c-segment-list__list">
            <li
              :key="listItem.title"
              class="c-segment-list__item"
              v-for="listItem in segment.segmentItems"
            >
              {{ listItem.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- EDIT STATE -->
    <div class="c-segment--edit__content" v-if="editState">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>
              {{ labels.supplyRequestTemplateMaintenanceDescription }}
            </label>
            <textarea
              class="form-control c-textarea--height"
              v-model="cloneOfSegmentItem.description"
            />
          </div>
        </div>
      </div>
      <div class="c-segment-list" v-if="cloneOfSegmentItem.segmentItems.length > 0">
        <ul class="c-segment-list__list">
          <li
            :key="listItem.index"
            class="c-segment-list__item d-flex align-items-center"
            v-for="(listItem, index) in cloneOfSegmentItem.segmentItems"
          >
            <input class="form-control" v-model="listItem.title" />
            <div @click="onDeleteListItem(index)">
              <div class="c-segment-list__delete" />
            </div>
          </li>
        </ul>
      </div>
      <div class="e-add-item">
        <div class="e-icon-add" />
        <a @click="onAddListItem(category, subCategory, segment)" class="e-link-bold">{{
          labels.supplyRequestTemplateMaintenanceCreateListItem
        }}</a>
      </div>
      <div class="o-separator o-separator--small" />
      <div class="d-flex">
        <div class="flex-sm-fill">
          <!--<maz-button class="c-btn--blue" label="Todo Add template"></maz-button> Will be done in a later sprint -->
        </div>
        <div class="d-flex flex-wrap flex-sm-nowrap flex-fill flex-sm-grow-0">
          <maz-button
            :label="labels.cancel"
            @click.native="onCancel(category, subCategory, segment)"
            class="c-btn--empty order-2 order-sm-1"
          />
          <maz-button
            :disabled="isButtonDisabled"
            :label="labels.confirm"
            @click.native="onSubmitEditSegment(category, subCategory, segment)"
            class="c-btn--blue order-1 order-sm-2"
          />
        </div>
      </div>
    </div>
    <!-- END OF EDIT STATE -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import mazButton from '../../general/mazButton'
import mazStateIndicator from '../../general/mazStateIndicator'

const _targetStore = 'supplyRequest2TemplateMaintenanceStore/'

export default {
  name: 'maz-template-maintenance-segment',
  components: {
    'maz-button': mazButton,
    'maz-state-indicator': mazStateIndicator
  },
  props: {
    indexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    segment: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    subCategory: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      editSegmentTitleInput: this.segment.title,
      editSegmentDescriptionInput: this.segment.description,
      editSegmentListItemInput: this.segment.segmentItems,
      editState: false,
      segmentItem: this.segment,
      cloneOfSegmentItem: ''
    }
  },
  computed: {
    ...mapGetters({
      labels: _targetStore + 'LABELS',
      templateMaintenance: _targetStore + 'TEMPLATES'
    }),
    isButtonDisabled() {
      let result = true

      let emptyListItem = false

      this.cloneOfSegmentItem.segmentItems.forEach(item => {
        if (item.title === '') {
          emptyListItem = true
        }
        return true
      })

      if (this.cloneOfSegmentItem.title.length > 0 && emptyListItem != true) {
        result = false
      }

      return result
    }
  },
  methods: {
    onEditSegment() {
      this.cloneOfSegmentItem = JSON.parse(JSON.stringify(this.segmentItem))

      this.editState = true
    },
    onSubmitEditSegment(category, subCategory, segment) {
      const body = {
        categoryId: category.id,
        subCategoryId: subCategory.id,
        segmentId: segment.id,
        segmentItems: segment.segmentItems,
        segmentData: {
          title: this.editSegmentTitleInput,
          description: this.editSegmentDescriptionInput,
          segmentItems: this.cloneOfSegmentItem.segmentItems
        },
        clone: this.cloneOfSegmentItem
      }

      this.$store.dispatch(_targetStore + 'EDIT_SEGMENT', body)

      this.$emit('clicked', true)

      this.editState = false
    },
    onAddListItem() {
      this.cloneOfSegmentItem.segmentItems.push({
        id: this.getGuidId(),
        title: ''
      })
    },
    onDeleteListItem(index) {
      this.cloneOfSegmentItem.segmentItems.splice(index, 1)
    },
    onCancel(category, subCategory, segment) {
      const body = {
        categoryId: category.id,
        subCategoryId: subCategory.id,
        segmentId: this.segmentItem.id,
        segment: segment,
        clone: this.cloneOfSegmentItem
      }

      this.$store.dispatch(_targetStore + 'SET_CANCELLED', body)

      this.$emit('clicked', true)

      this.editState = false
    },
    getGuidId() {
      let uuidValue = '',
        k,
        randomValue
      for (k = 0; k < 32; k++) {
        randomValue = (Math.random() * 16) | 0

        if (k == 8 || k == 12 || k == 16 || k == 20) {
          uuidValue += '-'
        }
        uuidValue += (k == 12
          ? 4
          : k == 16
          ? (randomValue & 3) | 8
          : randomValue
        ).toString(16)
      }
      return uuidValue
    },
    onDeleteSegment(category, subCategory, segment) {
      const body = {
        category: category,
        subCategory: subCategory,
        segment: segment
      }

      this.$store.dispatch(_targetStore + 'DELETE_SEGMENT', body)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/__main.scss';

.c-template-segment {
  @include segment;

  + .c-template-segment {
    margin-top: 8px;
  }
}
</style>
