<template>
  <div class="c-category qa-category">
    <div class="d-flex align-items-center row">
      <div class="flex-fill col-12 col-sm-auto">
        <h3 class="float-left">{{ indexPosition }} {{ category.title }}</h3>
      </div>
      <div
        class="c-icons c-icons--categories mt-4 mt-sm-0 col-12 col-sm-auto justify-content-center"
      >
        <div
          :data-target="'#modalEditCategory' + index"
          @click="showCurrentInput(category)"
          class="c-icons__item c-icons__item--edit"
          data-toggle="modal"
        />
        <div
          :data-target="'#modalDeleteCategory' + index"
          class="c-icons__item c-icons__item--trash"
          data-toggle="modal"
        />
      </div>
    </div>

    <div class="o-separator o-separator--small" />

    <draggable
      :id="category.id.clean"
      :options="{ group: 'category.subCategories' }"
      @end="onCompleteDragAndDropEvent($event, category)"
      ghost-class="ghost"
      handle=".c-icons__item--move"
      v-model="changeSubCategoryOrder"
    >
      <maz-supply-request-edit-sub-category
        :category="category"
        :id="subCategory.id.clean"
        :index="index"
        :indexPosition="(index + 1).toString()"
        :key="subCategory.id.clean"
        :parentCategoryIndexPosition="indexPosition"
        :subCategory="subCategory"
        v-for="(subCategory, index) in category.subCategories"
      />
    </draggable>

    <!-- Start of adding a subcategory -->
    <div class="e-add-item mt-2 mb-2 mb-sm-0">
      <div class="e-icon-add" />
      <a
        :data-target="'#modalAddSubCategory' + index"
        @click="clearInput"
        class="e-link-bold"
        data-toggle="modal"
        >{{ labels.supplyRequestEditAddSubCategory }}</a
      >
    </div>
    <!-- End of adding a subcategory -->

    <!-- Start of modal Add subcategory -->
    <div
      :id="'modalAddSubCategory' + index"
      aria-hidden="true"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{{ labels.supplyRequestEditAddSubCategory }}</h3>
            <button
              @click="onCancelSubCategory"
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            />
          </div>
          <div class="modal-body modal-body--margin-bottom">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>{{ labels.supplyRequestEditTitle }}</label>
                  <input class="form-control" v-model="subCategoryTitleInput" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="form-group float-sm-right mb-0 d-flex flex-wrap flex-sm-nowrap"
                >
                  <maz-button
                    :label="labels.cancel"
                    @click.native="onCancelSubCategory"
                    aria-label="Close"
                    class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
                    data-dismiss="modal"
                  />
                  <maz-button
                    :disabled="isAddSubCategoryDisabled"
                    :label="labels.confirm"
                    @click.native="onSubmitSubCategory(category)"
                    aria-label="Close"
                    class="c-btn--green order-1 order-sm-2"
                    data-dismiss="modal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of modal add subcategory -->

    <!-- Start of modal Edit category -->
    <div
      :id="'modalEditCategory' + index"
      aria-hidden="true"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{{ labels.supplyRequestEditCategoryEditTitle }}</h3>
            <button
              @click="onCancelEditCategory"
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            />
          </div>
          <div class="modal-body modal-body--margin-bottom">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>{{ labels.supplyRequestEditCategoryShortTitle }}</label>
                  <input class="form-control" v-model="editCategoryShortTitleInput" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>{{ labels.supplyRequestEditCategoryTitle }}</label>
                  <input class="form-control" v-model="editCategoryTitleInput" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="form-group float-sm-right mb-0 d-flex flex-wrap flex-sm-nowrap"
                >
                  <maz-button
                    :label="labels.cancel"
                    @click.native="onCancelEditCategory"
                    aria-label="Close"
                    class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
                    data-dismiss="modal"
                  />
                  <maz-button
                    :label="labels.confirm"
                    @click.native="onEditCategory(category)"
                    aria-label="Close"
                    class="c-btn--green order-1 order-sm-2"
                    data-dismiss="modal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of modal edit category -->

    <!-- Modal delete category -->
    <div
      :id="'modalDeleteCategory' + index"
      aria-hidden="true"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog--auto-width"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">
              {{ labels.supplyRequestConfirmLostChangesCategoryTitle }}
            </h3>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button" />
          </div>
          <div class="modal-body modal-body--margin-bottom">
            <p>{{ labels.supplyRequestConfirmLostChangesCategory }}</p>
            <div class="o-separator o-separator--small" />
          </div>
          <div class="modal-footer d-flex flex-wrap flex-sm-nowrap">
            <maz-button
              :label="labels.cancel"
              aria-label="Close"
              class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
              data-dismiss="modal"
            />
            <maz-button
              :label="labels.confirm"
              @click.native="onDeleteCategory(category)"
              aria-label="Close"
              class="c-btn--green order-1 order-sm-2"
              data-dismiss="modal"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- End of modal delete category -->
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import mazButton from '../../general/mazButton'
import mazSupplyRequestEditSubCategory from '../mazSupplyRequestEditSubCategory'
import draggable from 'vuedraggable'

const _targetStore = 'supplyRequest2EditStore/'

export default {
  name: 'maz-supply-request-edit-category',
  components: {
    mazButton,
    mazSupplyRequestEditSubCategory,
    draggable
  },
  props: {
    indexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    index: {
      required: false,
      type: Number,
      default() {
        return ''
      }
    },
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      subCategoryTitleInput: '',
      editCategoryTitleInput: '',
      editCategoryShortTitleInput: ''
    }
  },
  computed: {
    ...mapGetters({
      categoryDeleted: _targetStore + 'SUPPLY_REQUEST_CATEGORY_DELETED',
      id: _targetStore + 'ID',
      supplyRequestCategories: _targetStore + 'SUPPLY_REQUEST_EDIT_CATEGORIES',
      labels: _targetStore + 'LABELS'
    }),
    isAddSubCategoryDisabled() {
      let result = true

      if (this.subCategoryTitleInput.length > 0) {
        result = false
      }

      return result
    },
    changeSubCategoryOrder: {
      get() {
        return this.category.subCategories
      },
      set(value) {
        const body = {
          category: this.category,
          subCategories: value
        }

        this.$store.dispatch(_targetStore + 'SET_ORDERED_SUBCATEGORIES', body)
      }
    }
  },
  methods: {
    onSubmitSubCategory(category) {
      const body = {
        supplyRequestId: this.id,
        categoryId: category.id.original,
        subCategoryId: this.getGuidId(),
        title: this.subCategoryTitleInput,
        position: this.category.subCategories.length
      }

      this.$store.dispatch(_targetStore + 'POST_SUBCATEGORY', body)
    },
    onCancelSubCategory() {
      this.cleanNewSubCategoryInputs()
    },
    onCancelEditCategory() {
      this.cleanEditSubCategoryInputs()
    },
    cleanNewSubCategoryInputs() {
      this.subCategoryTitleInput = ''
    },
    cleanEditSubCategoryInputs() {
      this.editCategoryTitleInput = ''
      this.editCategoryShortTitleInput = ''
    },
    onEditCategory(category) {
      const body = {
        supplyRequestId: this.id,
        categoryId: category.id.original,
        title: this.editCategoryTitleInput,
        shortTitle: this.editCategoryShortTitleInput
      }

      this.$store.dispatch(_targetStore + 'EDIT_CATEGORY', body)

      this.cleanEditSubCategoryInputs()
    },
    clearInput() {
      this.cleanNewSubCategoryInputs()
    },
    showCurrentInput(category) {
      this.editCategoryTitleInput = category.title
      this.editCategoryShortTitleInput = category.shortTitle
    },
    onDeleteCategory(category) {
      const body = {
        supplyRequestId: this.id,
        categoryId: category.id.original
      }

      this.$store.dispatch(_targetStore + 'DELETE_CATEGORY', body)
    },
    getGuidId() {
      let uuidValue = '',
        k,
        randomValue
      for (k = 0; k < 32; k++) {
        randomValue = (Math.random() * 16) | 0

        if (k == 8 || k == 12 || k == 16 || k == 20) {
          uuidValue += '-'
        }
        uuidValue += (k == 12
          ? 4
          : k == 16
            ? (randomValue & 3) | 8
            : randomValue
        ).toString(16)
      }
      return uuidValue
    },
    onCompleteDragAndDropEvent(evt, category) {
      const body = {
        supplyRequestId: this.id,
        categoryId: category.id.original,
        groupSegmentId: evt.item.id,
        toPosition: evt.newIndex
      }

      this.$store.dispatch(_targetStore + 'SET_ORDER_OF_SUBCATEGORIES', body)
    }
  },
  watch: {
    categoryDeleted: {
      handler(newValue) {
        if (newValue == true) {
          $('.c-category-bar__items .c-category-bar__item').removeClass('active')
          $('.tab-content .tab-pane').removeClass('active')

          $('.c-category-bar__items .c-category-bar__item:first-child').addClass('active')
          $('.tab-content .tab-pane:first-child').addClass('active')
        }

        this.$store.dispatch(_targetStore + 'RESET_CATEGORY_DELETED', false)
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/__main.scss';

.c-category {
  @include category;
}
</style>
