import $ from 'jquery'
import Moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/nl'

const currentLang = $('html').attr('lang')

Moment.locale(currentLang)
Moment.updateLocale(currentLang, {
  week: { dow: 1 } // Set monday as the first day of the week
})

export default Moment
