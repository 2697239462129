<template>
  <div class="c-category qa-category">
    <maz-spinner class="c-spinner--category" v-if="!category.isLoaded" />
    <div class="d-lg-flex align-items-center" v-if="category.isLoaded">
      <div class="flex-fill">
        <h3>{{ indexPosition }} {{ category.title }}</h3>
      </div>
      <a
        :href="category.downloadZipUrl"
        class="e-link-bold c-category__download-link"
        v-if="totalNumberOfFilesCategory"
        >{{ labels.supplyRequestDownloadAllCategoryFilesButton }}</a
      >
    </div>
    <div class="o-separator o-separator--small" />
    <maz-supply-request-view-sub-category
      :category="category"
      :id="subCategory.id.original"
      :index="index"
      :indexPosition="(index + 1).toString()"
      :key="subCategory.id.original"
      :parentCategoryIndexPosition="indexPosition"
      :subCategory="subCategory"
      v-for="(subCategory, index) in category.subCategories"
    />

    <!-- Start of adding a subcategory -->
    <div
      class="e-add-item mt-2 mb-2 mb-sm-0"
      v-if="supplyRequestPermissions.mayAddSubItems"
    >
      <div class="e-icon-add" />
      <a
        :data-target="'#modalAddSubCategoryConfirmation' + index"
        @click="clearInput"
        class="e-link-bold"
        data-toggle="modal"
        >{{ labels.supplyRequestEditAddSubCategory }}</a
      >
    </div>
    <!-- End of adding a subcategory -->

    <!-- Start of modal Add subcategory -->
    <div
      :id="'modalAddSubCategory' + index"
      aria-hidden="true"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{{ labels.supplyRequestEditAddSubCategory }}</h3>
            <button
              @click="onCancelSubCategory"
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            />
          </div>
          <div class="modal-body modal-body--margin-bottom">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>{{ labels.supplyRequestEditTitle }}</label>
                  <input class="form-control" v-model="subCategoryTitleInput" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="form-group float-sm-right mb-0 d-flex flex-wrap flex-sm-nowrap"
                >
                  <maz-button
                    :label="labels.cancel"
                    @click.native="onCancelSubCategory"
                    aria-label="Close"
                    class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
                    data-dismiss="modal"
                  />
                  <maz-button
                    :disabled="isAddSubCategoryDisabled"
                    :label="labels.confirm"
                    @click.native="onSubmitSubCategory(category)"
                    aria-label="Close"
                    class="c-btn--green order-1 order-sm-2"
                    data-dismiss="modal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of modal add subcategory -->

    <!-- Modal confirmation add subcategory -->
    <div
      :id="'modalAddSubCategoryConfirmation' + index"
      aria-hidden="true"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog--auto-width"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">
              {{ labels.supplyRequestConfirmationAddNewItemTitle }}
            </h3>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button" />
          </div>
          <div class="modal-body modal-body--margin-bottom">
            <p>{{ labels.supplyRequestConfirmationAddNewItem }}</p>
            <div class="o-separator o-separator--small" />
          </div>
          <div class="modal-footer d-flex flex-wrap flex-sm-nowrap">
            <maz-button
              :label="labels.cancel"
              aria-label="Close"
              class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
              data-dismiss="modal"
            />
            <maz-button
              :data-target="'#modalAddSubCategory' + index"
              :label="labels.confirm"
              @click.native="onShowAddSubCategoryDialog"
              aria-label="Close"
              class="c-btn--green order-1 order-sm-2"
              data-dismiss="modal"
              data-toggle="modal"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- End of modal confirmation add subcategory -->
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import mazButton from '../../general/mazButton'
import mazSupplyRequestViewSubCategory from '../mazSupplyRequestViewSubCategory'
import mazSpinner from '../../general/mazSpinner'

const _targetStore = 'supplyRequest2DetailStore/'

export default {
  name: 'mazSupplyRequestViewCategory',
  components: {
    mazButton,
    mazSupplyRequestViewSubCategory,
    mazSpinner
  },
  props: {
    indexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      required: false,
      type: Number,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      subCategoryTitleInput: ''
    }
  },
  computed: {
    ...mapGetters({
      id: _targetStore + 'ID',
      supplyRequestPermissions: _targetStore + 'SUPPLY_REQUEST_PERMISSIONS',
      labels: _targetStore + 'LABELS'
    }),
    isAddSubCategoryDisabled() {
      let result = true

      if (this.subCategoryTitleInput.length > 0) {
        result = false
      }

      return result
    },
    totalNumberOfFilesCategory() {
      let result = false

      if (
        this.category.totalNumberOfFilesCategory != null ||
        this.category.totalNumberOfFilesCategory != undefined
      ) {
        if (
          this.category.totalNumberOfFilesCategory > 0 &&
          this.category.downloadZipUrl != '' &&
          this.category.mayDownloadZip
        ) {
          result = true
        } else {
          result = false
        }
      } else {
        result = false
      }

      return result
    }
  },
  methods: {
    onSubmitSubCategory(category) {
      const body = {
        supplyRequestId: this.id,
        categoryId: category.id.original,
        subCategoryId: this.getGuidId(),
        title: this.subCategoryTitleInput,
        position: this.category.subCategories.length
      }

      this.$store.dispatch(_targetStore + 'POST_SUBCATEGORY', body)

      $('html').removeClass('modal-open') // Due to Bootstrap bug with class modal-open not alway attaching to the body properly
    },
    onCancelSubCategory() {
      $('html').removeClass('modal-open') // Due to Bootstrap bug with class modal-open not alway attaching to the body properly
      this.cleanNewSubCategoryInputs()
    },
    clearInput() {
      this.cleanNewSubCategoryInputs()
    },
    onShowAddSubCategoryDialog() {
      $('html').addClass('modal-open') // Due to Bootstrap bug with class modal-open not alway attaching to the body properly
    },
    cleanNewSubCategoryInputs() {
      this.subCategoryTitleInput = ''
    },
    getGuidId() {
      let uuidValue = '',
        k,
        randomValue
      for (k = 0; k < 32; k++) {
        randomValue = (Math.random() * 16) | 0

        if (k == 8 || k == 12 || k == 16 || k == 20) {
          uuidValue += '-'
        }
        uuidValue += (k == 12
          ? 4
          : k == 16
            ? (randomValue & 3) | 8
            : randomValue
        ).toString(16)
      }
      return uuidValue
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/__main.scss';

.c-category {
  @include category;
}
</style>
