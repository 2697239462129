import dictionaryService from '@/services/modules/dictionaryService'

export const localization = {
  labels: {
    title: dictionaryService.translate('EmployeeOverview.Labels.Title') as string,
    downloadExcel: dictionaryService.translate('EmployeeOverview.Labels.DownloadExcel') as string,
    pagePrevious: dictionaryService.translate('Labels.Common.PagingPrevious') as string,
    pageNext: dictionaryService.translate('Labels.Common.PagingNext') as string,
    pageOf: dictionaryService.translate('Labels.Common.PageXOfY') as string,
    searchPlaceholder: dictionaryService.translate(
      'EmployeeOverview.Labels.Search'
    ) as string,
    showInactiveEmployees: dictionaryService.translate(
      'EmployeeOverview.Labels.ShowInactiveEmployees'
    ) as string
  },
  employeesTable: {
    labels: {
      employeeNumber: dictionaryService.translate(
        'EmployeeDetail.Labels.CustomerNumber'
      ) as string,
      employeeName: dictionaryService.translate(
        'EmployeeDetail.Labels.CustomerName'
      ) as string,
      employeeEmail: dictionaryService.translate(
        'EmployeeDetail.Labels.CustomerEmail'
      ) as string
    }
  },
  messages: {
    noResults: dictionaryService.translate(
      'EmployeeOverview.Messages.NoResults'
    ) as string
  }
}
