<template>
  <div class="c-delegated-segment qa-delegated-segment">
    <h1>{{ delegationInitialData.pageTitle }}</h1>

    <div>
      <span v-html="delegationInitialData.pageText" />
    </div>
    <div>
      <span>
        {{ labels.supplyRequestDelegatedSegment }}: {{ delegationInitialData.dueDate }}
      </span>
    </div>
    <div>
      <span>
        {{ labels.supplyRequestPersonalNotesLabel }}:
        {{ delegationInitialData.delegateReason }}
      </span>
    </div>

    <div class="o-separator o-separator--medium" />

    <h2>{{ delegationInitialData.title }}</h2>
    <span class="u-subheading u-subheading--not-clickable mt-3">{{
      delegationInitialData.customerName
    }}</span>

    <div class="o-separator o-separator--small" />
    <h3>{{ delegationInitialData.categoryTitle }}</h3>
    <h4>{{ delegationInitialData.groupSegmentTitle }}</h4>

    <div class="o-separator o-separator--extra-small" />

    <div v-if="delegationInitialData.segment.isComplete">
      <div class="c-alert c-alert--error">
        {{ labels.supplyRequestDelegatedSegmentIsComplete }}
      </div>
    </div>
    <div
      v-if="
        delegationInitialData.delegationIsComplete &&
        !delegationInitialData.segment.isComplete
      "
    >
      <div class="c-alert c-alert--success">{{ labels.delegationMarkedAsComplete }}</div>
    </div>
    <div
      v-if="
        !delegationInitialData.segment.isComplete &&
        !delegationInitialData.delegationIsComplete
      "
    >
      <div class="c-segment qa-segment">
        <div class="d-md-flex align-items-center position-relative">
          <div class="flex-fill">
            <div class="d-flex align-items-center flex-wrap flex-sm-nowrap">
              <maz-state-indicator class="u-w-xs-100" show_state_number />
              <div>
                <h5 class="c-segment__title u-pt-14px u-pt-sm-0 float-left">
                  {{ delegationInitialData.segment.title }}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="c-segment__content">
          <div class="c-segment__body">
            <div
              class="o-separator o-separator--extra-small"
              v-if="delegationInitialData.segment.description"
            />
            {{ delegationInitialData.segment.description }}
            <div
              class="c-segment-list"
              v-if="delegationInitialData.segment.segmentItems.length > 0"
            >
              <ul class="c-segment-list__list">
                <li
                  :key="'list-item-' + index"
                  v-for="(listItem, index) in delegationInitialData.segment.segmentItems"
                >
                  {{ listItem.title }}
                </li>
              </ul>
            </div>

            <div
              class="o-separator o-separator--extra-small"
              v-if="
                delegationInitialData.segment.segmentItems.length > 0 ||
                delegationInitialData.segment.description
              "
            />

            <maz-attachment-list
              :segment="delegationInitialData.segment"
              class="c-attachments--plain c-attachments--view-state"
              v-if="delegationInitialData.segment.attachments.length > 0"
            />

            <div
              class="o-separator o-separator--extra-small"
              v-if="delegationInitialData.segment.attachments.length > 0"
            />
          </div>

          <div class="float-right">
            <maz-button
              :disabled="isButtonDisabled"
              :label="labels.done"
              @click.native="onMarkDelegationAsComplete"
              class="c-btn--green"
              data-target="#modalLeavingPageConfirmation"
              data-toggle="modal"
            />
          </div>

          <div class="o-separator o-separator--extra-small" />
          <maz-file-upload
            :delegationId="delegationId"
            :store2="store"
            :supplyRequestFileUploader="supplyRequestFileUploader"
            :type="type"
            :uploadUrl="uploadUrl"
            @onFinish="() => onFinishUpload()"
          />

          <div class="o-separator o-separator--extra-small" />

          <maz-delegated-segment-file-list
            v-if="supplyRequestFileUploader.files.length > 0"
          />
        </div>
      </div>
    </div>

    <!-- Start of modal confirmation leaving the page -->
    <div
      aria-hidden="true"
      class="modal fade"
      id="modalLeavingPageConfirmation"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog--auto-width"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{{ labels.doneConfirmationTitle }}</h3>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button" />
          </div>
          <div class="modal-body modal-body--margin-bottom">
            <p>{{ labels.doneConfirmation }}</p>
            <div class="o-separator o-separator--extra-small" />
          </div>
          <div class="modal-footer d-flex flex-wrap flex-sm-nowrap">
            <maz-button
              :label="labels.closeMessage"
              aria-label="Close"
              class="c-btn--blue"
              data-dismiss="modal"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- End of modal confirmation deleting supply request -->
  </div>
</template>

<script>
import mazButton from '../../general/mazButton'
import mazStateIndicator from '../../general/mazStateIndicator'
import mazFileUpload from '../../general/mazFileUpload'
import mazDelegatedSegmentFileList from '../mazDelegatedSegmentFileList'
import mazAttachmentList from '../../general/mazAttachmentList'

import store from '@/store/store2'

export default {
  name: 'maz-delegated-segment-initial',
  components: {
    'maz-button': mazButton,
    'maz-state-indicator': mazStateIndicator,
    'maz-file-upload': mazFileUpload,
    'maz-delegated-segment-file-list': mazDelegatedSegmentFileList,
    'maz-attachment-list': mazAttachmentList
  },
  props: {
    segment: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    subCategory: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      store: 'supplyRequest2DelegatedSegmentStore/',
      uploadUrl: 'uploadfile',
      type: 'delegationUploader'
    }
  },
  computed: {
    delegationId() {
      return store.state.supplyRequest2DelegatedSegmentStore.delegationId
    },
    delegationInitialData() {
      return store.state.supplyRequest2DelegatedSegmentStore.delegationInitialData
      // return store.getters.supplyRequest2DelegatedSegmentStore.delegationInitialData
    },
    supplyRequestFileUploader() {
      return store.state.supplyRequest2DelegatedSegmentStore.supplyRequestFileUploader
    },
    labels() {
      return store.state.supplyRequest2DelegatedSegmentStore.labels
    },
    isButtonDisabled() {
      let result = true

      if (this.supplyRequestFileUploader.files.length > 0) {
        result = false
      }

      return result
    }
  },
  methods: {
    onMarkDelegationAsComplete() {
      store.dispatch.supplyRequest2DelegatedSegmentStore.SET_MARK_DELEGATION_AS_COMPLETE(this.delegationId)
    },
    onFinishUpload() {
      store.dispatch.supplyRequest2DelegatedSegmentStore.GET_UPDATED_SEGMENT_FILES({ delegationId: this.delegationId })
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-segment {
  padding: 26px 12px;
  background-color: $white-smoke;
  border-radius: 4px;

  @media #{$media-query-mobile} {
    padding: 15px 12px;
  }

  @media #{$media-query-from-tablet} {
    padding: 35px 48px 35px 48px;
  }

  + .c-segment {
    margin-top: 8px;
  }

  .c-segment__title {
    padding: 0 0 0 20px;
    color: $dark-gray;

    @include media-breakpoint-down(xs) {
      padding-left: 0;
    }
  }

  .c-segment__content {
    padding: 0 0 0 48px;

    @media #{$media-query-mobile} {
      padding-left: 0;
    }

    .c-segment__body {
      width: 100%;
    }
  }

  .c-segment-list {
    margin-top: 26px;

    .c-segment-list__list {
      margin-bottom: 0;
      padding-left: 0;
      list-style: none;
      counter-reset: listStyle;

      li {
        counter-increment: listStyle;

        &:before {
          margin-right: 16px;
          content: counter(listStyle, lower-alpha) ')';
        }
      }
    }
  }
}
</style>
