const PermissionsColumOrder = [
  {
    order: 0,
    code: 'Read'
  },
  {
    order: 1,
    code: 'Write'
  },
  {
    order: 2,
    code: 'Download'
  },
  {
    order: 3,
    code: 'Upload'
  }
]

export default PermissionsColumOrder
