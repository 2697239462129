<template>
  <input
    type="text"
    class="c-forms__input"
    :class="{
      'c-forms__input--danger': isDangerous,
      'c-forms__input--success': isSuccessful
    }"
    :value="value"
    :placeholder="placeholder"
    @input="$emit('input', $event.target.value)"
    @focus="resetValidation"
  />
</template>

<script>
/**
 *Can be used in pair with <maz-validation-message />
 */
export default {
  name: "maz-text-input",

  props: {
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    isDanger: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isDangerous: this.isDanger,
      isSuccessful: this.isSuccess
    }
  },

  methods: {
    resetValidation() {
      this.isDangerous = false
      this.isSuccessful = false
    }
  },

  watch: {
    isDanger(val) {
      this.isDangerous = val
    },
    isSuccess(val) {
      this.isSuccessful = val
    }
  }
}
</script>
