import { localization } from './localization'

export default () => ({
  labels: { ...localization.labels },
  searchEmployeeList: [] as object[],
  currentPage: 1 as number,
  maxPageCounter: 1 as number,
  loader: true as boolean,
  employeeList: {
    labels: { ...localization.employeesTable.labels },
    rows: [] as object[]
  },
  messages: { ...localization.messages },
  employeePermission: {} as MazInterfaces.EmployeeMaintenance.EmployeePermission
})
