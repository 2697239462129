<template>
  <div class="c-wizard-segment qa-wizard-segment">
    <div class="d-md-flex align-items-center position-relative">
      <div class="flex-fill">
        <div class="d-flex align-items-center flex-wrap flex-sm-nowrap">
          <maz-state-indicator
            :state_number="indexPosition"
            class="u-w-xs-100"
            show_state_number
          />
          <div class="w-100">
            <h5
              :data-target="segmentNavigationId"
              class="c-segment__title u-pt-14px u-pt-sm-0 float-left collapsed"
              data-toggle="collapse"
            >{{ segment.title }}</h5>
          </div>
        </div>
      </div>
      <div class="c-segment__icons-container d-flex text-nowrap">
        <div
          :data-target="segmentNavigationId"
          class="c-segment__collapsable-icon collapsed"
          data-toggle="collapse"
        />
      </div>
    </div>
    <div class="c-segment__content">
      <div
        :id="'collapseContent_' + segment.id"
        class="c-segment__body collapse"
      >
        <div class="o-separator o-separator--extra-small" />
        <div>{{ segment.description }}</div>

        <div
          class="c-segment-list"
          v-if="segment.segmentItems.length > 0"
        >
          <ul class="c-segment-list__list">
            <li
              :key="listItem.title"
              class="c-segment-list__item"
              v-for="listItem in segment.segmentItems"
            >{{ listItem.title }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mazStateIndicator from '../../general/mazStateIndicator'

export default {
  name: 'maz-wizard-sr-segment',
  components: {
    'maz-state-indicator': mazStateIndicator
  },
  props: {
    indexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    segment: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    subCategory: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    segmentNavigationId() {
      return '#collapseContent_' + this.segment.id
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/__main.scss';

.c-wizard-segment {
  @include segment;

  + .c-wizard-segment {
    margin-top: 8px;
  }
}
</style>
