import clientMaintenanceApi from '@/api/modules/clientMaintenanceApi'
import config from '@/api/modules/config'
import axiosService from '@/services/modules/axiosService'
import clientMaintenanceService from '@/services/modules/clientMaintenanceService'
import dictionaryService from '@/services/modules/dictionaryService'
import helpersService from '@/services/modules/helpersService'
import { defineActions } from 'direct-vuex'
import { difference } from 'lodash'
import toastr from 'toastr'
import { currentActionContext } from './index'

const _portController = 'ClientMaintenanceEmployeeJson'
const _baseEndpoint = config.endpointBase + _portController + '/'
const _targetApi = clientMaintenanceApi

export const actions = defineActions({
  UPDATE_CLIENT_MAINTENANCE_TAB_INDEX({ commit }: any, payload: number) {
    commit('UPDATE_CLIENT_MAINTENANCE_TAB_INDEX_MUTATION', payload)
  },

  GET_REFERENCE_DATA(ctx: any) {
    const { commit, state } = currentActionContext(ctx)
    if (
      !state.genders.length &&
      !state.languages.length &&
      !state.relationManagers.length
    ) {
      const endpoint = helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getCustomerReferenceData
      )
      axiosService.get(endpoint, (res: any) => {
        if (res) {
          const body = helpersService.convertObjectKeysToCamelCase(res)
          commit.SET_GENDERS_MUTATION(body.genders)
          commit.SET_LANGUAGES_MUTATION(body.languages)
          commit.SET_RELATION_MANAGERS_MUTATION(body.relationManagers)
          commit.SET_COUNTRIES_MUTATION(body.countries)
        } else {
          toastr.error(state.messages.somethingWentWrong)
        }
      })
    }
  },

  GET_CONTACT_ORGANIZATIONS_PERMISSIONS(ctx: any, payload: { clientId: string }) {
    const { commit, state } = currentActionContext(ctx)

    let endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getClientMaintenanceContactOrganizationsPermissions
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{organizationId}',
      payload.clientId
    )
    commit.UPDATE_LOADER_MUTATION({
      permissions: true
    })
    axiosService.get(endpoint, (res: any) => {
      if (res.Organizations) {
        const changedOrganizations = {
          ...res,
          Organizations: res.Organizations.map((organization: any) =>
            clientMaintenanceService.processPermissions(organization)
          )
        }
        commit.UPDATE_CONTACT_ORGANIZATIONS_PERMISSIONS_MUTATION(changedOrganizations)
        commit.SET_CONNECT_URL(res.ConnectUrl)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      // state.contactsModal.selectedContactsList = [] // TODO: create mutation.
      commit.UPDATE_LOADER_MUTATION({
        permissions: false
      })
    })
  },

  GET_CONNECTABLE_ORGANIZATIONS(ctx: any) {
    const { commit, state } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getContactOrganizations
    )

    axiosService.get(endpoint, (res: any) => {
      if (res) {
        // TODO: the array comes back as an object
        //const body = helpersService.convertObjectKeysToCamelCase(res)

        const unconnectedOrganizations: object[] = res.Organizations.filter(
          (organization: any) => {
            const isUnconnectedOrganization =
              state.contactOrganizationsPermissionsTable.rows.filter(
                (connectedOrganization: any) =>
                  connectedOrganization.Id === organization.Id
              ).length == 0

            return isUnconnectedOrganization
          }
        )
        commit.SET_CONNECTABLE_ORGANIZATIONS_MUTATION(unconnectedOrganizations)
        commit.RESET_SELECTED_ORGANIZATIONS_MUTATION()
      }
    })
  },

  GET_CLIENT_MAINTENANCE_CLIENT_SERVICES(ctx: any, payload: { clientId: string }) {
    const { commit, state } = currentActionContext(ctx)
    let endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getClientMaintenanceClientServices
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{clientId}',
      payload.clientId
    )
    commit.contactServices.UPDATE_LOADER_MUTATION({
      clientServices: true
    })
    axiosService.get(endpoint, (res: any) => {
      if (res) {
        commit.UPDATE_CLIENT_SERVICES_TABLE_MUTATION(res)
        commit.contactServices.UPDATE_CLIENT_SERVICES_MUTATION(res)
        commit.SET_CONNECT_URL(res.ConnectUrl)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.contactServices.UPDATE_LOADER_MUTATION({
        clientServices: false
      })
    })
  },

  UPDATE_CLIENT_MAINTENANCE_EDIT_MODE(ctx: any, payload: boolean) {
    const { commit } = currentActionContext(ctx)
    commit.UPDATE_CLIENT_MAINTENANCE_EDIT_MODE_MUTATION(payload)
  },

  RESET_CLIENT_MAINTENANCE_EDIT_CLIENT_DETAILS(ctx: any) {
    const { commit } = currentActionContext(ctx)
    commit.RESET_CLIENT_MAINTENANCE_EDIT_CLIENT_DETAILS_MUTATION()
  },

  UPDATE_CLIENT_MAINTENANCE_CONTACT_SERVICE(ctx: any, payload: any) {
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.connectServicesToContact
    )
    const { commit, state, dispatch } = currentActionContext(ctx)
    commit.contactServices.UPDATE_LOADER_MUTATION({
      clientServices: true
    })
    const body = helpersService.convertObjectKeysToPascalCase(payload)
    const getServiceBody = {
      clientId: body.ContactId
    }
    axiosService.post(endpoint, payload, (res: any) => {
      if (res.HasSucceeded) {
        dispatch.GET_CLIENT_MAINTENANCE_CLIENT_SERVICES(getServiceBody)
        commit.contactServices.UPDATE_CONTACT_SERVICES_EDIT_MODE_MUTATION(false)
        toastr.success(state.messages.changesSuccessfullySaved)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.contactServices.UPDATE_LOADER_MUTATION({
        clientServices: false
      })
    })
  },

  GET_CLIENT_MAINTENANCE_EMPLOYEES(ctx: any, payload: { clientId: string }) {
    const { commit, state } = currentActionContext(ctx)
    let endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getClientMaintenanceEmployees
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{clientId}',
      payload.clientId
    )
    commit.UPDATE_LOADER_MUTATION({
      employees: true
    })
    axiosService.get(endpoint, (res: any) => {
      if (res) {
        const employees = res.Employees
        commit.UPDATE_CLIENT_MAINTENANCE_EMPLOYEES_MUTATION(employees)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.UPDATE_LOADER_MUTATION({
        employees: false
      })
    })
  },

  GET_CLIENT_MAINTENANCE_CONNECTABLE_EMPLOYEES(ctx: any) {
    const { commit, state } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getClientMaintenanceConnectableEmployees
    )

    axiosService.get(endpoint, (res: any) => {
      if (res) {
        // TODO: the array comes back as an object
        //const body = helpersService.convertObjectKeysToCamelCase(res)

        const unconnectedEmployees: object[] = res.Employees.filter((employee: any) => {
          const isUnconnectedEmployee =
            state.employeesConnectedToContact.filter(
              (connectedEmployee: any) => connectedEmployee.Id === employee.Id
            ).length == 0

          return isUnconnectedEmployee
        })
        commit.SET_CLIENT_MAINTENANCE_CONNECTABLE_EMPLOYEES_MUTATION(unconnectedEmployees)
        commit.RESET_CLIENT_MAINTENANCE_SELECTED_EMPLOYEES_MUTATION()
      }
    })
  },

  UPDATE_CLIENT_MAINTENANCE_AVAILABLE_CONNECTABLE_EMPLOYEES(ctx: any, payload: string) {
    const { commit, state } = currentActionContext(ctx)
    let filteredList
    if (payload.trim().length == 0) {
      filteredList = difference(
        state.employeesModal.originalEmployeesList,
        state.employeesModal.selectedEmployeesList
      )
      commit.UPDATE_CLIENT_MAINTENANCE_AVAILABLE_CONNECTABLE_EMPLOYEES_MUTATION(
        filteredList
      )
      return
    }
    filteredList = state.employeesModal.originalEmployeesList.filter((employee: any) => {
      return employee.FullName.trim()
        .toLowerCase()
        .includes(payload.trim().toLowerCase())
    })
    filteredList = difference(filteredList, state.employeesModal.selectedEmployeesList)
    commit.UPDATE_CLIENT_MAINTENANCE_AVAILABLE_CONNECTABLE_EMPLOYEES_MUTATION(
      filteredList
    )
  },

  //TODO: once feature is complete define the correct types
  UPDATE_CLIENT_MAINTENANCE_CONNECTED_EMPLOYEES(ctx: any, payload: any) {
    const { state, dispatch } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.connectEmployeesToContact
    )
    axiosService.post(endpoint, payload, (res: any) => {
      if (res.HasSucceeded) {
        toastr.success(state.messages.changesSuccessfullySaved)
        dispatch.GET_CLIENT_MAINTENANCE_CONNECTABLE_EMPLOYEES()
        dispatch.GET_CLIENT_MAINTENANCE_EMPLOYEES({ clientId: state.clientDetails.id })
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
    })
  },

  UPDATE_CLIENT_MAINTENANCE_EMPLOYEES_LIST(ctx: any, payload: any) {
    const { commit } = currentActionContext(ctx)
    commit.UPDATE_CLIENT_MAINTENANCE_EMPLOYEES_LIST_MUTATION(payload)
  },

  DISCONNECT_CLIENT_MAINTENANCE_EMPLOYEE_FROM_CONTACT(ctx: any, payload: any) {
    const { state, dispatch } = currentActionContext(ctx)
    let endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.disconnectEmployeeFromContact
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{EmployeeId}',
      payload.EmployeeId
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{ContactId}',
      payload.ContactId
    )
    axiosService.post(endpoint, payload, (res: any) => {
      if (res.HasSucceeded) {
        toastr.success(state.messages.changesSuccessfullySaved)
        dispatch.GET_CLIENT_MAINTENANCE_CONNECTABLE_EMPLOYEES()
        dispatch.GET_CLIENT_MAINTENANCE_EMPLOYEES({ clientId: state.clientDetails.id })
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
    })
  },

  //TODO: update client details data action
  UPDATE_CONTACT_DETAILS(
    ctx: any,
    payload: MazInterfaces.ClientMaintenance.ContactDetails
  ) {
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.updateClientMaintenanceContact
    )

    const { commit, state } = currentActionContext(ctx)
    commit.UPDATE_LOADER_MUTATION({
      editContactDetails: true
    })
    const body = helpersService.convertObjectKeysToPascalCase(payload)
    axiosService.post(endpoint, body, (res: any) => {
      if (res.HasSucceeded) {
        //TODO: See if there is a better way to update the client details
        //without re-fetching the details
        ctx.dispatch(
          'clientMaintenance/GET_CLIENT_MAINTENANCE_DETAILS', //foreign store.
          payload.id,
          { root: true }
        )
        //TODO: See if there is a better way to reset the edit mode
        commit.UPDATE_CLIENT_MAINTENANCE_EDIT_MODE_MUTATION(false)
        toastr.success(state.messages.changesSuccessfullySaved)
      } else {
        if (res.Errors) {
          commit.SET_VALIDATION_ERRORS_MUTATION(res.Errors)
        }
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.UPDATE_LOADER_MUTATION({
        editContactDetails: false
      })
    })
  },
  RESET_VALIDATION_ERRORS(ctx: any) {
    const { commit } = currentActionContext(ctx)
    commit.RESET_VALIDATION_ERRORS_MUTATION()
  },
  RESET_VALIDATION_ERROR(ctx: any, payload: string) {
    function capitalizeFirstLetter(string: string): string {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const { commit } = currentActionContext(ctx)
    commit.RESET_VALIDATION_ERROR_MUTATION(capitalizeFirstLetter(payload))
  },
  DELETE_CLIENT_MAINTENANCE_ORGANIZATION_CONTACT(
    ctx: any,
    payload: MazInterfaces.ClientMaintenance.OrganizationContactRelation
  ) {
    const { state, dispatch } = currentActionContext(ctx)
    let endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.deassignContactFromOrganization
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{ContactId}',
      payload.ContactId
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{OrganizationId}',
      payload.OrganizationId
    )
    axiosService.post(endpoint, payload, (res: any) => {
      if (res.HasSucceeded) {
        toastr.success(state.messages.changesSuccessfullySaved)
        const tempPayload = { clientId: payload.ContactId }
        dispatch.GET_CONTACT_ORGANIZATIONS_PERMISSIONS(tempPayload)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
    })
  },
  UPDATE_AVAILABLE_CONNECTABLE_ORGANIZATIONS(ctx: any, payload: string) {
    const { commit, state } = currentActionContext(ctx)
    let filteredList
    if (payload.trim().length == 0) {
      filteredList = difference(
        state.organizationsModal.originalOrganizationsList,
        state.organizationsModal.selectedOrganizationsList
      )
      commit.UPDATE_AVAILABLE_CONNECTABLE_ORGANIZATIONS_MUTATION(filteredList)
      return
    }
    filteredList = state.organizationsModal.originalOrganizationsList.filter(
      (organization: any) => {
        return organization.Name.trim()
          .toLowerCase()
          .includes(payload.trim().toLowerCase())
      }
    )
    filteredList = difference(
      filteredList,
      state.organizationsModal.selectedOrganizationsList
    )

    commit.UPDATE_AVAILABLE_CONNECTABLE_ORGANIZATIONS_MUTATION(filteredList)
  },

  UPDATE_ORGANIZATIONS_LIST(ctx: any, payload: any) {
    const { commit } = currentActionContext(ctx)
    commit.UPDATE_ORGANIZATIONS_LIST_MUTATION(payload)
  },

  UPDATE_CONTACT_ORGANIZATIONS(ctx: any, payload: any) {
    const { commit, dispatch, state } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.assignOrganizationToContact
    )
    axiosService.post(endpoint, payload, (res: any) => {
      if (res.HasSucceeded) {
        const tempPayload = { clientId: payload.ContactId }
        toastr.success(state.messages.changesSuccessfullySaved)
        dispatch.GET_CONTACT_ORGANIZATIONS_PERMISSIONS(tempPayload)
        commit.RESET_SELECTED_ORGANIZATIONS_MUTATION()
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
    })
  },

  UPDATE_CONTACT_IS_SPECIAL(ctx: any, payload: any) {
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.updateContactIsSpecial
    )
    const { state } = currentActionContext(ctx)
    axiosService.post(endpoint, payload, (res: any) => {
      if (res.HasSucceeded) {
        //TODO: See if there is a better way to update the client details
        //without re-fetching the details
        ctx.dispatch(
          'clientMaintenance/GET_CLIENT_MAINTENANCE_DETAILS', //foreign store.
          payload.Id,
          { root: true }
        )
        toastr.success(state.messages.changesSuccessfullySaved)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
    })
  },

  RESET_PASSWORD(ctx: any) {
    const { state } = currentActionContext(ctx)
    const contactId = state.clientDetails.id
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.resetPassword + `?contactid=${contactId}`
    )

    axiosService.post(endpoint, null, (res: any) => {
      if (res.HasSucceeded) {
        toastr.success(state.messages.passwordResetSuccess)
      } else {
        const translatedMessage = dictionaryService
          .translate(`EmployeeOrganizationOverview.Messages.Okta.${res.MessageCode}`)
          .replace('{0}', res.OktaMessage)
        toastr.error(translatedMessage)
      }
    })
  },

  RESET_MFA(ctx: any) {
    const { state } = currentActionContext(ctx)
    const contactId = state.clientDetails.id
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.resetMFA + `?contactid=${contactId}`
    )

    axiosService.post(endpoint, null, (res: any) => {
      if (res.HasSucceeded) {
        toastr.success(state.messages.mfaResetSuccess)
      } else {
        const translatedMessage = dictionaryService
          .translate(`EmployeeOrganizationOverview.Messages.Okta.${res.MessageCode}`)
          .replace('{0}', res.OktaMessage)
        toastr.error(translatedMessage)
      }
    })
  },

  REACTIVATE_ACCOUNT(ctx: any) {
    const { state } = currentActionContext(ctx)

    const contactId = state.clientDetails.id

    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.reactivate + `?contactid=${contactId}`
    )

    axiosService.post(endpoint, null, (res: any) => {
      if (res.HasSucceeded) {
        toastr.success(state.messages.reactivationSuccess)
      } else {
        const translatedMessage = dictionaryService
          .translate(`EmployeeOrganizationOverview.Messages.Okta.${res.MessageCode}`)
          .replace('{0}', res.OktaMessage)
        toastr.error(translatedMessage)
      }
    })
  }
})
