<template>
  <div class="c-title">
    <h1>{{title_main}}</h1>
    <span
      class="u-subheading u-subheading--not-clickable mt-3"
      v-if="!title_client_url && title_client_name"
    >{{title_client_name}}</span>
    <a
      :href="title_client_url"
      class="u-subheading qa-sub-heading mb-0 mt-3"
      v-if="title_client_url && title_client_name"
    >{{title_client_name}}</a>
  </div>
</template>

<script>
export default {
  name: 'maz-title',
  props: {
    title_main: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    title_client_name: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    title_client_url: {
      required: false,
      type: String,
      default() {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-title {
  h1 {
    @media #{$media-query-mobile} {
      line-height: 1.1;
    }
  }
}
</style>
