import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faCloudUploadAlt,
  faPlus,
  faSpinner,
  faTimes,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import {
  faArrows,
  faCalendarAlt,
  faClone,
  faCog,
  faExclamationTriangle,
  faFile,
  faFileExcel,
  faFilePdf,
  faFileWord,
  faPen,
  faPlayCircle,
  faTrashAlt
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue from 'vue'

export function registerFontAwesome() {
  library.add(
    faCheck,
    faClone,
    faCalendarAlt,
    faCloudUploadAlt,
    faPen,
    faPlayCircle,
    faPlus,
    faTrashAlt,
    faFilePdf,
    faFileExcel,
    faFileWord,
    faFile,
    faArrows,
    faExclamationTriangle,
    faSpinner,
    faTimes,
    faTimesCircle,
    faCog
  )
  Vue.component('fa', FontAwesomeIcon)
}
