<template>
  <div class="d-md-flex justify-content-between qa-main-edit-heading">
    <maz-title
      :title_client_name="supplyRequestEditHeader.client.name"
      :title_client_url="supplyRequestEditHeader.client.url"
      :title_main="supplyRequestEditHeader.title"
    />

    <div class="o-separator o-separator--small d-md-none d-none d-sm-block" />
    <div class="o-separator o-separator--extra-small d-sm-none" />

    <div
      class="mb-0 u-pt-7px d-flex justify-content-between justify-content-sm-start flex-nowrap"
    >
      <div class="c-status u-mr-40 float-left float-right-md">
        <maz-state-indicator
          :state_code="supplyRequestEditHeader.state.code"
          :state_label="supplyRequestEditHeader.state.label"
          class="c-state-indicator--big float-left"
        />
      </div>
      <div class="c-icons c-icons--header float-right">
        <maz-action-icon-clone
          :action_label_clone_button="
            supplyRequestEditHeader.actionIcons.labels.cloneSupplyRequestButton
          "
          :action_label_clone_intro="
            supplyRequestEditHeader.actionIcons.labels.cloneSupplyRequestIntro
          "
          :action_label_clone_rollover_button="
            supplyRequestEditHeader.actionIcons.labels.cloneSupplyRequestRolloverButton
          "
          :action_label_clone_rollover_intro="
            supplyRequestEditHeader.actionIcons.labels.cloneSupplyRequestRolloverIntro
          "
          :action_label_clone_rollover_title="
            supplyRequestEditHeader.actionIcons.labels.cloneSupplyRequestRolloverTitle
          "
          :action_label_clone_search_hint="
            supplyRequestEditHeader.actionIcons.labels.cloneSupplyRequestSearchHint
          "
          :action_label_clone_title="
            supplyRequestEditHeader.actionIcons.labels.cloneSupplyRequestTitle
          "
          :store="store"
          :supplyRequestCustomers="supplyRequestCustomers"
          :supplyRequestId="supplyRequestId"
          :supplyRequestPermissions="supplyRequestPermissions"
        />
        <div
          :class="{ 'c-icons__item--edit-highlighted': setHighLighted && showEditFields }"
          @click="onEditClick"
          class="c-icons__item c-icons__item--edit"
        />
        <a
          class="c-icons__item c-icons__item--trash"
          data-target="#modalDeleteSupplyConfirmation"
          data-toggle="modal"
          v-if="supplyRequestPermissions.mayDelete"
        />
      </div>

      <!-- Start of modal confirmation deleting supply request -->
      <div
        aria-hidden="true"
        class="modal fade"
        id="modalDeleteSupplyConfirmation"
        role="dialog"
        tabindex="-1"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog--auto-width"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title">
                {{ labels.supplyRequestConfirmationDeleteSupplyRequestTitle }}
              </h3>
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              />
            </div>
            <div class="modal-body modal-body--margin-bottom">
              <p>{{ labels.supplyRequestConfirmationDeleteSupplyRequest }}</p>
              <div class="o-separator o-separator--small" />
            </div>
            <div class="modal-footer d-flex flex-wrap flex-sm-nowrap">
              <maz-button
                :label="labels.cancel"
                aria-label="Close"
                class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
                data-dismiss="modal"
              />
              <maz-button
                :label="labels.confirm"
                @click.native="onDeleteClick"
                aria-label="Close"
                class="c-btn--green order-1 order-sm-2"
                data-dismiss="modal"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- End of modal confirmation deleting supply request -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import mazTitle from '../../general/mazTitle'
import mazButton from '../../general/mazButton'
import mazStateIndicator from '../../general/mazStateIndicator'
import mazActionIconClone from '../../general/mazActionIconClone'

const _targetStore = 'supplyRequest2EditStore/'

export default {
  name: 'maz-supply-request-edit-header',
  components: {
    mazButton,
    mazTitle,
    mazStateIndicator,
    mazActionIconClone
  },
  props: {
    supply_request_audit: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    details_audit_logs: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    showEditFields: {
      required: false,
      type: Boolean,
      default() {
        return false
      }
    },
    supply_request_header: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      setHighLighted: false,
      store: 'supplyRequest2EditStore/'
    }
  },
  computed: {
    ...mapGetters({
      supplyRequestId: _targetStore + 'ID',
      supplyRequestEditHeader: _targetStore + 'SUPPLY_REQUEST_EDIT_HEADER',
      supplyRequestCustomers: _targetStore + 'SUPPLY_REQUEST_CUSTOMERS',
      supplyRequestPermissions: _targetStore + 'SUPPLY_REQUEST_PERMISSIONS',
      labels: _targetStore + 'LABELS'
    })
  },
  methods: {
    onEditClick(event) {
      this.setHighLighted = true
      this.$emit('onEditClick', event)
    },
    onDeleteClick() {
      const body = {
        supplyRequestId: this.supplyRequestId
      }

      this.$store.dispatch(_targetStore + 'DELETE_SUPPLY_REQUEST', body)
    }
  }
}
</script>
