<template>
  <div :class="['c-state-indicator',stateClass,'qa-state-indicator d-flex']">
    <div class="c-state-indicator__dot float-left">
      <p
        class="c-state-indicator__number"
        v-if="show_state_number"
      >{{state_number}}</p>
    </div>
    <span
      class="c-status__label float-left qa-c-state-indicator-label text-nowrap"
      v-if="state_label"
    >{{state_label}}</span>
  </div>
</template>

<script>
const _state_code_classes = {
  9999: {
    style_classes: 'c-state-indicator--grey'
  },
  // 100 = Concept
  100: {
    style_classes: 'c-state-indicator--grey'
  },
  // 200 = Pending
  200: {
    style_classes: 'c-state-indicator--grey'
  },
  // 200 = Reopened
  250: {
    style_classes: 'c-state-indicator--grey'
  },
  // 260 = Submitted Pending
  260: {
    style_classes: 'c-state-indicator--green'
  },
  // 280 = Submitted By Employee Pending
  280: {
    style_classes: 'c-state-indicator--green'
  },
  // 300 = Submitted
  300: {
    style_classes: 'c-state-indicator--green'
  },
  // 320 = Submitted By Employee
  320: {
    style_classes: 'c-state-indicator--green'
  },
  // 700 = Approved
  700: {
    style_classes: 'c-state-indicator--checked c-state-indicator--green'
  },
  // 1010 is used for the subcategories and categories
  1010: {
    style_classes: 'c-state-indicator--checked c-state-indicator--green'
  },
  // 1020 is used for the segments
  1020: {
    style_classes: 'c-state-indicator--green'
  }
}

export default {
  name: 'maz-state-indicator',
  props: {
    state_label: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    state_code: {
      required: false,
      type: String,
      default() {
        return '9999'
      }
    },
    state_number: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    show_state_number: {
      required: false,
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    stateClass() {
      let result = ''

      if (this.state_code !== null && this.state_code !== undefined) {
        result += _state_code_classes[this.state_code].style_classes
      }

      return result
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-state-indicator {
  .c-state-indicator__dot {
    width: 28px;
    height: 28px;
    text-align: center;
    background-color: $gray;
    border-radius: 50%;
  }

  .c-state-indicator__number {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    // line-height: normal;
    color: $white;
    font-size: 16px;
    font-family: 'Halyard-Medium', sans-serif;
    text-align: center;
  }

  .c-status__label {
    position: relative;
    margin: 0;
    padding-left: 13px;
    color: $dark-gray;
    font-size: 24px;
    font-family: 'Halyard-Medium', sans-serif;
    line-height: 28px;

    @media #{$media-query-from-desktop} {
      margin-top: 6px;
    }
  }

  &.c-state-indicator--checked {
    .c-state-indicator__dot {
      position: relative;

      &:before {
        position: absolute;
        top: 7px;
        left: 7px;
        width: 14px;
        height: 15px;
        background-image: url('~@/assets/images/icon_checkmark.svg');
        content: '';
      }
    }
  }

  &.c-state-indicator--small {
    .c-state-indicator__dot {
      position: relative;
      display: inline-block;
      width: 13px;
      height: 13px;
      background-color: $gray;
      border-radius: 50%;
    }

    &.c-state-indicator--checked {
      .c-state-indicator__dot {
        &:before {
          top: 3px;
          left: 3px;
          width: 7px;
          height: 7px;
          line-height: 0;
        }
      }
    }
  }

  &.c-state-indicator--grey {
    .c-state-indicator__dot {
      background-color: $gray;

      .c-state-indicator__number {
        color: $white;
      }
    }

    &.c-state-indicator--yellow-border {
      .c-state-indicator__dot {
        border: 3px solid $status-yellow;

        .c-state-indicator__number {
          padding-top: 0;
        }
      }
    }

    &.c-state-indicator--red-border {
      .c-state-indicator__dot {
        border: 3px solid $status-red;

        .c-state-indicator__number {
          padding-top: 0;
        }
      }
    }
  }

  &.c-state-indicator--big {
    .c-state-indicator__dot {
      width: 28px;
      height: 28px;

      @media #{$media-query-from-desktop} {
        width: 38px;
        height: 38px;
      }

      .c-state-indicator__number {
        padding-top: 4px;
      }
    }

    &.c-state-indicator--checked {
      .c-state-indicator__dot {
        &:before {
          top: 7px;
          left: 8px;
          width: 13px;
          height: 13px;
          font-size: 21px;

          @media #{$media-query-from-desktop} {
            top: 10px;
            left: 10px;
            width: 19px;
            height: 20px;
          }
        }
      }
    }

    &.c-state-indicator--yellow-border {
      .c-state-indicator__dot {
        border: 4px solid $status-yellow;
      }
    }

    &.c-state-indicator--red-border {
      .c-state-indicator__dot {
        border: 4px solid $status-red;
      }
    }
  }

  &.c-state-indicator--green {
    .c-state-indicator__dot {
      background-color: $status-green;

      .c-state-indicator__number {
        color: $white;
      }
    }
  }

  &.c-state-indicator--yellow {
    .c-state-indicator__dot {
      background-color: $status-yellow;

      .c-state-indicator__number {
        color: $white;
      }
    }
  }

  &.c-state-indicator--red {
    .c-state-indicator__dot {
      background-color: $status-red;

      .c-state-indicator__number {
        color: $white;
      }
    }
  }
}
</style>
