


















































import Vue from 'vue'
import AxiosService from '@/services/modules/axiosService'
import mazAlert from '@/components/general/mazAlert/index.vue'
// import mazButton from '@/components/general/mazButton/index.vue'

type tile = {
  Title: string
  Subtitle: string
  ActionUrl: string
  SetupUrl: string
  AppId: string
  IconUrl: string
  invalidUrl: boolean
}

const endpoint = '/json/securelogin/getoverview'

export default Vue.extend({
  name: 'mazNextTiles',

  components: {
    // mazButton,
    mazAlert
  },

  data() {
    return {
      number: 10,
      loaded: false,
      title: '',
      tiles: [] as tile[],
      message: '',
      error: null,
      canSeeTiles: false
    }
  },

  methods: {
    getFirstLetters(title: string): string {
      const matches = title.match(/\b(\w)/g)
      if (matches?.length) {
        return matches.join('').toUpperCase().slice(0, 3)
      }
      return title.toUpperCase().slice(0, 3)
    },
    verifyTileUrls() {
      this.tiles.forEach((element, index) => {
        const img = new Image()
        img.src = element.IconUrl
        img.onerror = () => {
          const item = this.tiles[index]
          item.invalidUrl = true
          this.$set(this.tiles, index, item)
        }
        img.onload = () => {
          const item = this.tiles[index]
          item.invalidUrl = false
          this.$set(this.tiles, index, item)
        }
      })
    },
    hasFields(tile: tile): boolean {
      return Boolean(tile.Title) || Boolean(tile.Subtitle) || Boolean(tile.ActionUrl)
    }
  },

  mounted() {
    AxiosService.get(endpoint, (res: any) => {
      this.canSeeTiles = res.HasAccessToRibbon
      this.title = res.Title
      this.message = res.Announcement
      this.error = res.SecureLoginResult?.Error
      this.tiles = res.SecureLoginResult?.SecureLoginApps
      if (res.SecureLoginResult?.SecureLoginApps?.length) {
        this.verifyTileUrls()
      }
      this.loaded = true
    })
  }
})
