import dictionaryService from '@/services/modules/dictionaryService'

export const localization = {
  supplyRequestFileUploader: {
    labels: {
      dropFiles: dictionaryService.translate('FileUploader.Labels.DropFiles'),
      addTemplate: dictionaryService.translate('FileUploader.Labels.AddTemplate'),
      fileProcessing: dictionaryService.translate('FileUploader.Labels.FileProcessing'),
      fileNotAllowed: dictionaryService.translate('FileUploader.Labels.FileNotAllowed'),
      fileProcessingComplete: dictionaryService.translate(
        'FileUploader.Labels.FileProcessingComplete'
      ),
      fileProcessingError: dictionaryService.translate(
        'FileUploader.Labels.FileProcessingError'
      ),
      fileLoadError: dictionaryService.translate('FileUploader.Labels.FileLoadError'),
      fileLoading: dictionaryService.translate('FileUploader.Labels.FileLoading'),
      fileProcessingAborted: dictionaryService.translate(
        'FileUploader.Labels.FileProcessingAborted'
      ),
      fileProcessingRevertError: dictionaryService.translate(
        'FileUploader.Labels.FileProcessingRevertError'
      ),
      fileRemoveError: dictionaryService.translate('FileUploader.Labels.FileRemoveError'),
      fileSizeNotAvailable: dictionaryService.translate(
        'FileUploader.Labels.FileSizeNotAvailable'
      ),
      invalidField: dictionaryService.translate('FileUploader.Labels.InvalidField'),
      fileWaitingForSize: dictionaryService.translate(
        'FileUploader.Labels.FileWaitingForSize'
      ),
      maxFileSizeExceeded: dictionaryService.translate(
        'FileUploader.Labels.MaxFileSizeExceeded'
      )
    }
  },

  labels: {
    done: dictionaryService.translate('Labels.Common.Done'),
    cancel: dictionaryService.translate('Labels.Common.Cancel'),
    confirm: dictionaryService.translate('Labels.Common.Confirm'),
    closeMessage: dictionaryService.translate('Labels.Common.CloseMessage'),

    supplyRequestPersonalNotesLabel: dictionaryService.translate(
      'SupplyRequestView.Labels.PersonalNotes'
    ),
    supplyRequestDelegatedSegment: dictionaryService.translate(
      'SupplyRequestDelegatedSegment.Labels.DelegationDueDate'
    ),
    supplyRequestDelegatedSegmentIsComplete: dictionaryService.translate(
      'SupplyRequestDelegatedSegment.Messages.IsComplete'
    ),
    supplyRequestNotExistsTitle: dictionaryService.translate(
      'Messages.SupplyRequest.NotFoundTitle'
    ),
    supplyRequestNotExistsText: dictionaryService.translate(
      'Messages.SupplyRequest.NotFoundText'
    ),
    leavePageConfirmation: dictionaryService.translate(
      'Labels.Common.LeavePageConfirmation'
    ),
    doneConfirmationTitle: dictionaryService.translate(
      'Messages.SupplyRequestDelegate.DoneConfirmationTitle'
    ),
    doneConfirmation: dictionaryService.translate(
      'Messages.SupplyRequestDelegate.DoneConfirmation'
    ),
    delegationMarkedAsComplete: dictionaryService.translate(
      'SupplyRequestDelegatedSegment.Messages.DelegationMarkedAsComplete'
    )
  }
}
