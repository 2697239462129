export const mutations = {
  SET_EMPLOYEE_MAINTENANCE_OVERVIEW_CURRENT_PAGE_MUTATION(state: any, payload: number) {
    state.currentPage = payload
  },
  GET_EMPLOYEE_OVERVIEW_LIST_MUTATION(state: any, payload: object[]) {
    state.employeeList.rows = payload
  },
  SET_EMPLOYEE_OVERVIEW_CURRENT_PAGE_MUTATION(state: any, payload: number) {
    state.currentPage = payload
  },
  SET_EMPLOYEE_OVERVIEW_MAX_PAGE_COUNTER_MUTATION(state: any, payload: number) {
    state.maxPageCounter = payload
  },
  SET_EMPLOYEE_PERMISSION_MUTATION(
    state: any,
    payload: MazInterfaces.EmployeeMaintenance.EmployeePermission
  ) {
    state.employeePermission = payload
  },
  UPDATE_EMPLOYEE_OVERVIEW_SEARCH_EMPLOYEE_LIST_MUTATION(state: any, payload: object[]) {
    state.searchEmployeeList = payload
  },
  UPDATE_EMPLOYEE_OVERVIEW_LOADER_MUTATION(state: any, payload: boolean) {
    state.loader = payload
  }
}
