import config from '@/api/modules/config'
import supplyRequest2DelegatedSegmentApi from '@/api/modules/supplyRequest2DelegatedSegmentApi'
import axiosService from '@/services/modules/axiosService'
import helpersService from '@/services/modules/helpersService'
import { currentActionContext } from './index' //direct-vuex helper of local module action context

const _portController = 'SupplyRequest2DelegateJson'
const _baseEndpoint = config.endpointBase + _portController + '/'
const _targetApi = supplyRequest2DelegatedSegmentApi

export const actions = {
  GET_INITIAL_PAGE_DATA(ctx: any, delegationId: string): void {
    const { commit, dispatch } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getSupplyRequestSegment,
      '{delegationId}',
      delegationId
    )

    axiosService.get(endpoint, (res: any): void => {
      commit.SET_DELEGATION_INITIAL_DATA(res)
      dispatch.GET_UPDATED_SEGMENT_FILES({ delegationId })
    })
  },

  GET_UPDATED_SEGMENT_FILES(ctx: any, payload: { delegationId: string }) {
    const { commit } = currentActionContext(ctx)
    let endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getFileOverview
    )
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{delegationId}',
      payload.delegationId
    )

    axiosService.get(endpoint, (res: any) => {
      commit.UPDATE_SUPPLY_REQUEST_SEGMENT_FILES(res.Files)
      commit.RESET_DELETED_FILE()
    })
  },

  DELETE_FILE(ctx: any, payload: { fileId: string; delegationId: string }) {
    const { commit } = currentActionContext(ctx)
    let endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.deleteFile
    )
    endpoint = helpersService.buildEndpointRequest(endpoint, '{fileId}', payload.fileId)
    endpoint = helpersService.buildEndpointRequest(
      endpoint,
      '{delegationId}',
      payload.delegationId
    )

    axiosService.delete(endpoint, () => {
      commit.UPDATE_DELETED_FILE()
    })
  },

  SET_MARK_DELEGATION_AS_COMPLETE(ctx: any, delegationId: string) {
    const { dispatch } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.markDelegationIsComplete,
      '{delegationId}',
      delegationId
    )

    const body = {}

    axiosService.put(endpoint, body, (res: any) => {
      if (res.ActionResult === true) {
        dispatch.GET_INITIAL_PAGE_DATA(delegationId)
      }
    })
  }
}
