













































































































































































































































































































import Vue from 'vue'

import store from '@/store/store2'
import moment from 'moment'
import mazModal from '@/components/general/mazModal/index.vue'
import mazSwitch from '@/components/general/mazSwitch/index.vue'
import mazClientMaintenanceDatePicker from '@/components/custom/mazClientMaintenanceDatePicker/index.vue'
import mazSpinner from '@/components/general/mazSpinner/index.vue'
import mazTextInput from '@/components/general/mazTextInput/index.vue'
import mazValidationMessage from '@/components/general/mazValidationMessage/index.vue'
import mazButton from '@/components/general/mazButton/index.vue'
import mazDropDown from '@/components/general/mazDropDown/index.vue'

import { isString, isEmpty, pickBy, startsWith, trim } from 'lodash'

export default Vue.extend({
  name: 'mazClientMaintenanceOrganizationDetails',

  components: {
    // mazCheckbox,
    mazModal,
    mazSwitch,
    mazSpinner,
    mazTextInput,
    mazButton,
    mazDropDown,
    mazClientMaintenanceDatePicker,
    mazValidationMessage
  },

  data() {
    return {
      errors: {} as any,
      startDate: null as any,
      endDate: null as any,
      resetCross: false,
      showSpecialConfirmationModal: false
    }
  },

  computed: {
    shouldRedirect(): boolean {
      return this.redirectUrl !== '' && this.redirectUrl !== undefined && this.redirectUrl !== null
    },
    redirectUrl(): string {
      return store.getters.clientMaintenance.organization.CLIENT_DETAILS.editUrl;
    },
    clientDetails() {
      return store.getters.clientMaintenance.organization.CLIENT_DETAILS
    },
    editedClientDetails(): any {
      return store.getters.clientMaintenance.organization.EDITED_CLIENT_DETAILS
    },
    loader(): any {
      return store.getters.clientMaintenance.organization.LOADER
    },
    labels(): any {
      return store.getters.clientMaintenance.organization.LABELS
    },
    messages(): any {
      return store.getters.clientMaintenance.organization.MESSAGES
    },

    permissions(): MazInterfaces.ClientMaintenance.CustomerPermission {
      return store.getters.clientMaintenance.organization.PERMISSIONS
    },
    isEditMode(): boolean {
      return store.getters.clientMaintenance.organization.IS_EDIT_MODE
    },
    relationManagersList(): any {
      return store.getters.clientMaintenance.organization.RELATION_MANAGERS_LIST
    },
    countriesList(): any {
      return store.getters.clientMaintenance.organization.COUNTRIES_LIST
    },
    countryName(): string {
      return store.getters.clientMaintenance.organization.COUNTRY_NAME
    }
  },

  methods: {
    getReferenceData(): void {
      store.dispatch.clientMaintenance.organization.GET_REFERENCE_DATA()
    },
    updateClientMaintenanceEditMode(body: boolean): void {
      store.dispatch.clientMaintenance.organization.UPDATE_CLIENT_MAINTENANCE_EDIT_MODE(
        body
      )
    },
    resetClientMaintenanceEditClientDetails(): void {
      store.dispatch.clientMaintenance.organization.RESET_CLIENT_MAINTENANCE_EDIT_CLIENT_DETAILS()
    },
    updateOrganizationSpecial(body: any): void {
      store.dispatch.clientMaintenance.organization.UPDATE_ORGANIZATION_IS_SPECIAL(body)
    },
    save(details: any) {
      const body: MazInterfaces.ClientMaintenance.OrganizationDetails = details
      this.fieldValidator(details, ['legalName', 'countryCode'])
      this.updateClientMaintenanceClientDetails(body)
    },
    updateClientMaintenanceClientDetails(body: any): void {
      store.dispatch.clientMaintenance.organization.UPDATE_CLIENT_MAINTENANCE_CLIENT_DETAILS(
        body
      )
    },

    fieldValidator(objectToValidate: any, fields: string[]) {
      fields.forEach((field: string) => {
        //if string, check if it has white spaces
        if (
          isString(objectToValidate[field]) &&
          trim(objectToValidate[field]).length === 0
        ) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired }
          }
          return
        }

        if (isEmpty(objectToValidate[field])) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired }
          }
        } else {
          const filteredErrors = pickBy(this.errors, function (value, key) {
            return !startsWith(key, field)
          })
          this.errors = filteredErrors
        }
      })
    },
    resetError(field: string): void {
      if (this.errors[field]) {
        this.$delete(this.errors, field)
      }
    },
    resetErrors(): void {
      this.errors = {}
    },
    formatDate(event: string) {
      if (event === null) {
        return null
      }
      return moment(event).format('DD/MM/YYYY')
    },
    onStartDateSelected(event: Date): void {
      const startDateOffset = moment(event).utcOffset()
      this.editedClientDetails.startDate = moment(event)
        .add(startDateOffset, 'minutes')
        .utc()
        .format()
    },
    onEndDateSelected(event: Date): void {
      const endDateOffset = moment(event).utcOffset()
      this.editedClientDetails.endDate = moment(event)
        .add(endDateOffset, 'minutes')
        .utc()
        .format()
    },
    getOrganizationActiveDates() {
      const initialStartDate = this.editedClientDetails.startDate
      const initialEndDate = this.editedClientDetails.endDate

      this.startDate = moment(initialStartDate)
      this.endDate = moment(initialEndDate)
    },
    resetEndDate(value: boolean) {
      this.editedClientDetails.endDate = null
      this.endDate = null
      this.resetCross = value
    },
    toggleIsSpecialConfirmModal(value: boolean) {
      this.showSpecialConfirmationModal = value
    },
    toggleIsSpecial(details: MazInterfaces.ClientMaintenance.OrganizationDetails) {
      const body: MazInterfaces.ClientMaintenance.OrganizationDetails = details
      const toggleData = {
        Id: body.id,
        IsSpecial: body.isSpecial
      }
      this.updateOrganizationSpecial(toggleData)
      this.showSpecialConfirmationModal = false
    },
    cancelIsSpecialToggle(value: boolean) {
      this.showSpecialConfirmationModal = value
      this.editedClientDetails.isSpecial = !this.editedClientDetails.isSpecial
    },
    redirectToPowerApp() {
      window.open(this.redirectUrl, '_blank')?.focus()
    }
  },

  watch: {
    isEditMode: function (value: boolean): void {
      if (value === false) {
        this.resetClientMaintenanceEditClientDetails()
        this.resetErrors()
      }
    },
    'clientDetails.organization': function (): void {
      this.resetClientMaintenanceEditClientDetails()
    }
  },

  created() {
    this.getOrganizationActiveDates()
    if (this.permissions.mayEditOrganization) {
      this.getReferenceData()
    }
  }
})
