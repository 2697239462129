<template>
  <div class="c-subcategory">
    <div class="d-lg-flex align-items-center">
      <div class="flex-fill">
        <div class="d-flex align-items-center">
          <maz-state-indicator :state_code="subCategory.isCompleteCode" />

          <h4
            class="c-subcategory__title"
          >{{parentCategoryIndexPosition}}{{indexPosition}} {{subCategory.title}}</h4>
        </div>
      </div>
      <a
        :href="subCategory.downloadZipUrl"
        class="e-link-bold c-subcategory__link"
        v-if="subCategory.mayDownloadZip && subCategory.downloadZipUrl && totalNumberOfFilesSubCategory"
      >{{ labels.supplyRequestDownloadAllSubCategoryFilesButton }}</a>
    </div>
    <div class="o-separator o-separator--extra-small" />
    <div
      class="c-subcategory__content"
      v-if="subCategory.segments.length"
    >
      <maz-supply-request-view-segment
        :category="category"
        :id="'navigationId'+segment.id.clean"
        :index="index"
        :indexPosition="(index + 1).toString()"
        :key="segment.id.clean"
        :segment="segment"
        :subCategory="subCategory"
        ref="segment"
        v-for="(segment, index) in subCategory.segments"
      />
    </div>
    <!-- Start of adding a segment -->
    <div
      class="e-add-item e-add-item--segment mt-2"
      v-if="supplyRequestPermissions.mayAddSubItems"
    >
      <div class="e-icon-add" />
      <a
        @click="onAddSegment(category, subCategory)"
        class="e-link-bold"
      >{{ labels.supplyRequestEditAddSegment }}</a>
    </div>
    <!-- End of adding a segment -->
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import mazStateIndicator from '../../general/mazStateIndicator'
import mazSupplyRequestViewSegment from '../mazSupplyRequestViewSegment'

const _targetStore = 'supplyRequest2DetailStore/'

export default {
  name: 'maz-supply-request-view-sub-category',
  components: {
    mazStateIndicator,
    mazSupplyRequestViewSegment
  },
  props: {
    parentCategoryIndexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    indexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    index: {
      required: false,
      type: Number,
      default() {
        return ''
      }
    },
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    subCategory: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters({
      id: _targetStore + 'ID',
      labels: _targetStore + 'LABELS',
      supplyRequestPermissions: _targetStore + 'SUPPLY_REQUEST_PERMISSIONS'
    }),
    totalNumberOfFilesSubCategory() {
      let result = false

      if (
        this.subCategory.totalNumberOfFilesSubCategory != null ||
        this.subCategory.totalNumberOfFilesSubCategory != undefined
      ) {
        if (this.subCategory.totalNumberOfFilesSubCategory > 0) {
          result = true
        } else {
          result = false
        }
      } else {
        result = false
      }

      return result
    }
  },
  methods: {
    onAddSegment(category, subCategory) {
      const body = {
        category: category,
        subCategory: subCategory,
        segmentData: {
          id: this.getGuidId(),
          title: '',
          description: '',
          segmentItems: [],
          files: [],
          comments: [],
          delegations: [],
          attachments: []
        }
      }

      this.$store.dispatch(_targetStore + 'ADD_SEGMENT', body)

      const indexOfLastSegment = this.subCategory.segments.length - 1

      // TODO REFACTOR - AVOID USING TIMEOUTS //
      setTimeout(() => {
        this.onEditSegment(indexOfLastSegment, this.segmentAddedId)

        $('.e-add-item--segment')
          .addClass('disabled')
          .prop('disabled', true)
      }, 100)
    },
    onEditSegment(index, segmentId) {
      this.$refs.segment[index].onEditSegment(segmentId)
    },
    getGuidId() {
      let uuidValue = '',
        k,
        randomValue
      for (k = 0; k < 32; k++) {
        randomValue = (Math.random() * 16) | 0

        if (k == 8 || k == 12 || k == 16 || k == 20) {
          uuidValue += '-'
        }
        uuidValue += (k == 12
          ? 4
          : k == 16
          ? (randomValue & 3) | 8
          : randomValue
        ).toString(16)
      }
      return uuidValue
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/__main.scss';

.c-subcategory {
  @include subcategory;
}
</style>
