import dictionaryService from '@/services/modules/dictionaryService'
import { currentGetterContext } from './index'

export const getters = {
  ID: (state: any): string => state.id,
  LOADER: (state: any) => state.loader,
  CLIENT_DETAILS: (state: any) => state.clientDetails,
  COUNTRY_NAME: (state: any) => {
    const countryCodeNotAvailable = state.clientDetails.organization.countryCode === null
    let code
    if (!countryCodeNotAvailable) {
      code = dictionaryService.translate(
        `Labels.Country.${state.clientDetails.organization.countryCode}`
      )
    }
    return code
  },
  IS_EDIT_MODE: (state: any) => state.isEditMode,
  EDITED_CLIENT_DETAILS: (state: any) => state.editedClientDetails,
  ACTIVE_COLLAPSIBLE_PANELS: (state: any) => state.activeCollapsiblePanels,
  TAB_NAVIGATION: (state: any) => state.tabNavigation,
  PENDING_ACTIONS_TABLE: (state: any) => state.pendingActionsTable,
  COMPLETED_ACTIONS_TABLE: (state: any) => state.completedActionsTable,
  CLIENT_SERVICES_TABLE: (state: any) => state.clientServicesTable,
  ORGANIZATION_CONTACT_PERMISSION_MODAL: (state: any) =>
    state.organizationContactPermissionsModal,
  CONTACTS_MODAL: (state: any) => state.contactsModal,
  EMPLOYEES_MODAL: (state: any) => state.employeesModal,
  ORGANIZATION_CONTACT_PERMISSION_TABLE: (state: any) =>
    state.organizationContactPermissionsTable,
  EMPLOYEES_CONNECTED_TO_ORGANIZATION: (state: any) =>
    state.employeesConnectedToOrganization,
  PERMISSIONS(...args: any): MazInterfaces.ClientMaintenance.CustomerPermission {
    const { state } = currentGetterContext(args)
    return state.clientDetails.customerPermission
  },
  RELATION_MANAGERS_LIST(
    ...args: any
  ): MazInterfaces.ClientMaintenance.RelationManager[] {
    const { state } = currentGetterContext(args)
    return state.relationManagers
  },
  COUNTRIES_LIST(...args: any): MazInterfaces.ClientMaintenance.Countries[] {
    const { state } = currentGetterContext(args)
    return state.countries
  },
  LABELS: (state: any) => state.labels,
  MESSAGES: (state: any) => state.messages,
  ADD_CONTACT_URL: (state: any) => state.addContactUrl,
  CONNECT_URL: (state: any) => state.connectUrl
}
