import mazWorkflowDetailsDatePicker from '@/components/custom/mazWorkflowDetailsDatePicker/index.vue'
import mazAlert from '@/components/general/mazAlert/index.vue'
import mazAssignedUsersLightbox from '@/components/general/mazAssignedUsersLightbox/index.vue'
import mazButton from '@/components/general/mazButton/index.vue'
import mazCollapse from '@/components/general/mazCollapse/index.vue'
import mazConfirmationLightbox from '@/components/general/mazConfirmationLightbox/index.vue'
import mazDropDown from '@/components/general/mazDropDown/index.vue'
import mazTaskStatusCircle from '@/components/general/mazTaskStatusCircle/index.vue'
import mazTextInput from '@/components/general/mazTextInput/index.vue'
import mazWorkflowHistoryLightbox from '@/components/general/mazWorkflowHistoryLightbox/index.vue'
import dictionaryService from '@/services/modules/dictionaryService'
import { generateGUID as generateGuid } from '@/services/modules/helpersService'
import axios from 'axios'
import moment from 'moment'
import toastr from 'toastr'
import Vue from 'vue'

function getQueryString(queryKey) {
  const searchString = window.location.search.replace('?', '').split('&')
  for (let n = 0; n < searchString.length; n++) {
    const values = searchString[n].split('=')
    if (values[0] === queryKey && values.length > 1) {
      return values[1]
    }
  }
  return null
}

const config = {
  portalType: window.__INITIAL_DATA__.portalType
}

const Enums = {
  ScanStatus: {
    Pending: 0,
    Clean: 10,
    Infected: 90,

    properties: {
      0: {
        id: 0,
        name: 'Pending',
        translation: dictionaryService.translate('Common.Messages.ScanStatusPending')
      },
      10: {
        id: 10,
        name: 'Clean',
        translation: dictionaryService.translate('Common.Messages.ScanStatusClean')
      },
      90: {
        id: 90,
        name: 'Infected',
        translation: dictionaryService.translate('Common.Messages.ScanStatusInfected')
      }
    }
  }
}
const taskId = getQueryString('wftid')
const portalController =
  config.portalType === 'Employee'
    ? 'ShareDocumentsEmployeeJson'
    : 'ShareDocumentsClientJson'
const apiEndpoints = {
  workflowDetailsEndpoint:
    '/json/' + portalController + '/GetWorkflowDetail?workflowId={workflowId}',
  acceptEndpoint: '/json/' + portalController + '/CompleteWorkflowTask',
  rejectEndpoint: '/json/' + portalController + '/RejectWorkflowTask',
  uploadFilesEndpoint: '/json/' + portalController + '/UploadFiles', // POST HTTP verb. Requires workflowId, taskId and file array
  getAllFilesEndpoint:
    '/json/' +
    portalController +
    '/GetFileOverviewByWorkflowId?workflowId={workflowId}' +
    '&taskId=' +
    taskId,
  getSigningFilesEndpoint:
    '/json/' +
    portalController +
    '/GetSignFileOverviewByWorkflowId?workflowId={workflowId}' +
    '&taskId=' +
    taskId,
  deleteFilesEndpoint: '/json/' + portalController + '/DeleteFile?fileId={fileId}', // DELETE HTTP verb. Requires fileId (GUID).
  deleteWorkflowEndpoint:
    '/json/' + portalController + '/DeleteWorkflow?workflowId={workflowId}',
  updateTasksEndpoint: '/json/' + portalController + '/UpdateTasks',
  getEmployeesEndpoint:
    '/json/' +
    portalController +
    '/GetEmployees?organizationId={organizationId}&contactId={contactId}',
  getContactsEndpoint:
    '/json/' +
    portalController +
    '/GetContactsForWorkflow?oid={organizationId}&cid={contactId}&serviceid={serviceId}',
  saveAssignedUsersEndpoint:
    '/json/' +
    portalController +
    '/SetAssignedUsers?workflowId={workflowId}&taskId={taskId}',
  rollOverWorkflowEndpoint:
    '/json/' + portalController + '/RollOverWorkflow?workflowId={workflowId}',
  getInitialData: '/json/' + portalController + '/GetInitialData?oid={oid}&cid={cid}'
}

const taskStatusEnum = {
  inactive: 100,
  active: 200,
  completed: 300
}

let originalTasks
let originalData

export default {
  components: {
    mazCollapse,
    mazTextInput,
    mazAlert,
    mazDropDown,
    mazWorkflowDetailsDatePicker,
    mazTaskStatusCircle,
    mazButton,
    mazWorkflowHistoryLightbox,
    mazConfirmationLightbox,
    mazAssignedUsersLightbox
  },

  data() {
    return {
      WorkflowId: getQueryString('wfid'),
      workflowDefinitionId: null,
      Name: null,
      FullName: null,
      CustomerName: null,
      CustomerUrl: null,
      Frequency: null,
      FilingName: null,
      PeriodValue: null,
      Year: null,
      StartDate: null,
      ActualStartDate: null,
      ServiceName: null,
      Status: null,
      MayDelete: false,
      ServiceId: '',
      CustomerId: false,
      CustomerIsOrganization: false,

      deletingWorkflow: false,
      showWorkflowHistoryLightBox: false,
      canExportWorkflowHistory: false,
      workflowHistoryExportUrl: '',
      showError: false,
      errorMessage: '',
      showNotFound: false,
      showConfirmDelete: false,
      showConfirmRollOver: false,
      isEmptyEditTitle: false,

      downloadFilesUrl:
        '/json/' +
        portalController +
        '/DownloadFiles?workflowId=' +
        getQueryString('wfid'),

      downloadSignedFilesUrl:
        '/json/' +
        portalController +
        '/DownloadSignedFiles?workflowId=' +
        getQueryString('wfid'),

      //DocuSign properties
      DocuSignWorkflow: false,
      DownloadDocuSignFiles: false,
      IsSigningWorkflow: false,

      comments: null,
      tasks: [], // might include new tasks updated tasks, order changes. Can be reset to originalTasks.
      originalTasks: [], // List of unaltered tasks. Is only set once.
      workflowFiles: null,
      workflowSigningFiles: [],
      isTaskEditMode: false,
      workflowDetailsLoaded: false,
      usersLightbox: {
        open: false
      },
      currentTask: {
        selectedUsers: [],
        selectedEmployees: [],
        users: [],
        employees: []
      },
      currentTaskIndex: 0,
      users: [],
      signingUsers: [],
      employees: [],
      signingEmployees: [],
      baseSteps: [],
      currentBaseStep: null,
      hasDeletedTasks: false,
      periods: [],
      mayChangeYear: false,
      draggedIndex: null,
      draggedOverIndex: null
    }
  },
  mounted: function() {
    if (this.WorkflowId && this.WorkflowId.length > 0) {
      this.getWorkflowDetails()
      this.getWorkflowFiles()
      this.getWorkflowSigningFiles()

      this.isTaskEditMode = this.getLocalStorageIsRolledOver()
    } else {
      this.showNotFound = true
    }
  },
  computed: {
    canEditTasks: function() {
      return (
        this.tasks
          .map(task => {
            return task.MayBeChanged
          })
          .indexOf(true) > -1 ||
        this.hasDeletedTasks ||
        false
      )
    },
    loading: function() {
      return (
        (this.Status === null && this.showNotFound === false) || this.deletingWorkflow
      )
    },
    taskAssignedUsers: function() {
      return (
        this.tasks.map(task => {
          return {
            TaskId: task.Id,
            AssignedUsers: task.AssignedUsers
          }
        }) || []
      )
    },
    taskEditAssignedUsers: function() {
      return (
        this.tasks.map(task => {
          return {
            TaskId: task.Id,
            AssignedUsers: task.AssignedUsers
          }
        }) || []
      )
    },
    signingFilesPanelVisible: function() {
      return (
        this.workflowSigningFiles.length > 0 ||
        (this.workflowSigningFiles.length === 0 && this.MayUploadSignFile === true)
      )
    },
    saveAllowed: function() {
      return (
        this.tasks.filter(task => task.AssignedUsers && task.AssignedUsers.length === 0) >
        -1
      )
    },
    addStepDisabled: function() {
      return this.currentBaseStep === null
    },
    hasSigningStep: function() {
      return this.tasks.find(obj => obj.IsSigningTask === true)
    },
    hasUploadStep: function() {
      return this.tasks.find(obj => obj.IsUploadTask === true)
    },
    validatedSignStepAfterUploadStep: function() {
      if (this.hasSigningStep && this.hasUploadStep) {
        const signIndex = this.tasks.findIndex(obj => obj.IsSigningTask === true)
        const uploadIndex = this.tasks.findIndex(obj => obj.IsUploadTask === true)
        return signIndex > uploadIndex
      }
      return true
    },
    showMoveButton() {
      return this.tasks.filter(task => task.MayBeChanged).length > 1
    },
    showDownloadAllButton() {
      return Boolean(
        this.workflowFiles.find(obj => obj.IsDownloadable === true) ||
          this.workflowSigningFiles.find(obj => obj.IsDownloadable === true)
      )
    },
    documentHasSigned() {
      return this.workflowSigningFiles.find(file => file.IsSigned === true)
    }
  },
  methods: {
    closeLightbox: function() {
      this.usersLightbox.open = false
      this.getEmployees()
      this.getContacts()
    },
    deleteFile: function(file, index) {
      const deleteFilesFullEndpoint = apiEndpoints.deleteFilesEndpoint.replace(
        '{fileId}',
        file.Id
      )

      axios
        .delete(deleteFilesFullEndpoint)
        .then(response => {
          toastr.success(
            dictionaryService.translate(
              'WorkflowDetail.Messages.FileDeletedSuccessfully'
            ),
            response,
            true
          )
          this.workflowFiles.splice(index, 1)
        })
        .catch(function(e) {
          toastr.error(
            dictionaryService.translate('WorkflowDetail.Messages.ErrorDeletingFile'),
            e,
            true
          )
        })
    },
    deleteWorkflow: function() {
      const deleteWorkflowFullEndpoint = apiEndpoints.deleteWorkflowEndpoint.replace(
        '{workflowId}',
        this.WorkflowId
      )

      this.deletingWorkflow = true
      axios
        .delete(deleteWorkflowFullEndpoint)
        .then(response => {
          if (response.data.CustomerUrl) {
            toastr.success(
              dictionaryService.translate(
                'WorkflowDetail.Messages.WorkflowDeletedSuccessfully'
              ),
              response,
              true
            )
            window.location.replace(response.data.CustomerUrl)
          }
        })
        .catch(e => {
          toastr.error(
            dictionaryService.translate('WorkflowDetail.Messages.ErrorDeletingWorkflow'),
            e,
            true
          )
          this.deletingWorkflow = false
        })

      this.toggleConfirmDelete(false)
    },
    rollOverWorkflow: function() {
      const rollOverWorkflowFullEndpoint = apiEndpoints.rollOverWorkflowEndpoint.replace(
        '{workflowId}',
        this.WorkflowId
      )

      this.rollingOverWorkflow = true

      axios
        .put(rollOverWorkflowFullEndpoint)
        .then(response => {
          if (response.data.WorkflowUrl) {
            toastr.success(
              dictionaryService.translate(
                'WorkflowDetail.Messages.WorkflowRolledOverSuccessfully'
              ),
              response,
              true
            )
            document.location.assign(response.data.WorkflowUrl)

            this.setLocalStorageIsRolledOver(true)
          }
        })
        .catch(e => {
          toastr.error(
            dictionaryService.translate(
              'WorkflowDetail.Messages.ErrorRollingOverWorkflow'
            ),
            e,
            true
          )
          this.rollingOverWorkflow = false
        })

      this.toggleConfirmRollOver(false)
    },
    setLocalStorageIsRolledOver: function(value) {
      const storageKey = 'isRolledOver'
      localStorage.setItem(storageKey, value)
    },
    getLocalStorageIsRolledOver: function() {
      const storageKey = 'isRolledOver'
      let res = false

      if (localStorage.getItem(storageKey)) {
        res = localStorage.getItem('isRolledOver') == 'true'
        localStorage.setItem(storageKey, false)
      }

      return res
    },
    getEmployees: function() {
      let getEmployeesFullEndpoint = apiEndpoints.getEmployeesEndpoint
      if (this.CustomerIsOrganization) {
        getEmployeesFullEndpoint = getEmployeesFullEndpoint
          .replace('{organizationId}', this.CustomerId)
          .replace('{contactId}', '00000000-0000-0000-0000-000000000000')
      } else {
        getEmployeesFullEndpoint = getEmployeesFullEndpoint
          .replace('{organizationId}', '00000000-0000-0000-0000-000000000000')
          .replace('{contactId}', this.CustomerId)
      }
      axios
        .get(getEmployeesFullEndpoint)
        .then(response => {
          if (response && response.data) {
            this.employees = response.data
            if (!this.IsSigningWorkflow) {
              this.signingEmployees = response.data.filter(e => e.MaySign)
            } else {
              this.signingEmployees = response.data
            }
          }
        })
        .catch(e => {
          toastr.error(
            dictionaryService.translate('WorkflowDetail.Messages.ErrorFetchingEmployees'),
            e,
            true
          )
        })
    },
    getContacts: function() {
      let getContactsFullEndpoint = apiEndpoints.getContactsEndpoint.replace(
        '{serviceId}',
        this.ServiceId
      )
      if (this.CustomerIsOrganization) {
        getContactsFullEndpoint = getContactsFullEndpoint
          .replace('{organizationId}', this.CustomerId)
          .replace('{contactId}', '00000000-0000-0000-0000-000000000000')
      } else {
        getContactsFullEndpoint = getContactsFullEndpoint
          .replace('{organizationId}', '00000000-0000-0000-0000-000000000000')
          .replace('{contactId}', this.CustomerId)
      }

      axios
        .get(getContactsFullEndpoint)
        .then(response => {
          if (response.data) {
            this.users = response.data
            if (!this.IsSigningWorkflow) {
              this.signingUsers = response.data.filter(function(u) {
                return u.MaySign
              })
            } else {
              this.signingUsers = response.data
            }
          }
        })
        .catch(function(e) {
          toastr.error(
            dictionaryService.translate('WorkflowDetail.Messages.ErrorFetchingContacts'),
            e,
            true
          )
        })
    },
    setAdditionalTasksProperties(tasks, data) {
      return tasks.map((task, i) => {
        const dueDateOffset = moment(task.DueDate).utcOffset()
        if (i === 0) {
          task.StartDate = moment(data.StartDate)
          task.MayChangeStartDate = data.MayChangeStartDate
        }
        task.DueDate = moment(task.DueDate).subtract(dueDateOffset, 'minutes')
        task.inactive = task.Status === taskStatusEnum.inactive
        task.active = task.Status === taskStatusEnum.active
        task.open = task.Status === taskStatusEnum.active
        task.complete = task.Status === taskStatusEnum.completed

        task.dueDateDescription = moment(task.DueDate).format('DD/MM/YYYY')

        const expectedStartDateOffset = task.ExpectedStartDate
          ? moment(task.ExpectedStartDate).utcOffset()
          : null
        task.ExpectedStartDate = task.ExpectedStartDate
          ? moment(task.ExpectedStartDate).subtract(expectedStartDateOffset, 'minutes')
          : null
        task.expectedStartDateDescription = task.ExpectedStartDate
          ? task.ExpectedStartDate.format('DD/MM/YYYY')
          : null

        const actualStartDateOffset = task.ActualStartDate
          ? moment(task.ActualStartDate).utcOffset()
          : null
        task.ActualStartDate = task.ActualStartDate
          ? moment(task.ActualStartDate).subtract(actualStartDateOffset, 'minutes')
          : null
        task.actualStartDateDescription = task.ActualStartDate
          ? moment(task.ActualStartDate).format('DD/MM/YYYY')
          : null

        if (task.CompletedDate !== null && task.StatusChangedBy !== null) {
          task.completedDescription =
            moment(task.CompletedDate).format('DD/MM/YYYY') +
            ' by ' +
            task.StatusChangedBy.Name
        } else {
          task.completedDescription = null
        }
        //TODO: Remove backend bugfix
        task.AssignedUsers = task.AssignedUsers.map(assignedUser => {
          assignedUser.Id = assignedUser.Id || assignedUser.UserId
          assignedUser.UserId = assignedUser.Id || assignedUser.UserId
          return assignedUser
        })
        //TODO: Remove backend bugfix
        task.IsUploadTask = task.IsUploadTask || task.IsUploadSignFileTask

        return task
      })
    },
    //convert to fresh array or object in order to prevent nested data pointing to same memory
    cleanWithJson(input) {
      return JSON.parse(JSON.stringify(input))
    },
    getWorkflowDetails: function() {
      const workflowDetailsFullEndpoint = apiEndpoints.workflowDetailsEndpoint.replace(
        '{workflowId}',
        this.WorkflowId
      )

      axios
        .get(workflowDetailsFullEndpoint)
        .then(response => {
          if (response.data) {
            const tasks = this.cleanWithJson(response.data.Tasks)
            originalTasks = this.cleanWithJson(response.data.Tasks)
            originalData = this.cleanWithJson(response.data)
            this.tasks = this.setAdditionalTasksProperties(tasks, originalData)
            this.originalTasks = this.setAdditionalTasksProperties(
              originalTasks,
              originalData
            )

            // Set comments with server side comments
            response.data.Comments.forEach(function(comment) {
              comment.dateDescription = moment(comment.Date).format('DD/MM/YYYY')
              comment.presentationDate = moment(comment.CreatedDate).format('DD/MM/YYYY')
            })
            this.comments = response.data.Comments
            this.workflowDefinitionId = response.data.WorkflowDefinitionId
            this.Name = response.data.Name
            this.FullName = response.data.FullName
            this.CustomerIsOrganization = response.data.CustomerIsOrganization
            this.CustomerName = response.data.CustomerName
            this.CustomerUrl = response.data.CustomerUrl
            this.CustomerId = response.data.CustomerId
            this.Frequency = response.data.Frequency
            this.FilingName = response.data.FilingName
            this.PeriodValue = response.data.PeriodValue
            this.Year = response.data.Year
            this.StartDate = moment(response.data.StartDate).utc()
            this.ActualStartDate = moment(response.data.StartDate).utc()
            this.ServiceId = response.data.ServiceId
            this.ServiceName = response.data.ServiceName
            this.Status = response.data.Status
            this.MayDelete = response.data.MayDelete
            this.canExportWorkflowHistory = response.data.MayExportAuditInfo
            this.workflowHistoryExportUrl = response.data.ExportAuditInfoUrl
            this.mayChangeYear = response.data.MayChangeYear
            this.workflowDetailsLoaded = true
            this.IsSigningWorkflow = response.data.IsSigningWorkflow

            if (config.portalType === 'Employee') {
              this.getEmployees()
              this.getContacts()
            }
          } else if (response.data === null) {
            this.showNotFound = true
          }
        })
        .catch(e => {
          toastr.error(
            dictionaryService.translate('WorkflowDetail.Messages.ErrorFetchingWorkflow'),
            e,
            true
          )
        })
    },
    getWorkflowFiles: function() {
      const getAllFilesFullEndpoint = apiEndpoints.getAllFilesEndpoint.replace(
        '{workflowId}',
        this.WorkflowId
      )

      this.workflowFiles = null
      this.showError = false
      axios
        .get(getAllFilesFullEndpoint)
        .then(response => {
          if (response.data) {
            response.data.Files.forEach(function(file) {
              if (file.MimeType === 'application/pdf') {
                file.iconClass = 'file-pdf'
              } else if (
                file.MimeType ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              ) {
                file.iconClass = 'file-word'
              } else if (
                file.MimeType ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              ) {
                file.iconClass = 'file-excel'
              } else {
                file.iconClass = 'file'
              }

              file.CreatedDateDescription = moment(file.CreatedDate).format(
                'DD-MM-YYYY HH:mm'
              )
              file.isInfected = false
              file.MayDownload = file.IsDownloadable
              file.scanStatus =
                Enums.ScanStatus.properties[Enums.ScanStatus.Pending].translation
              file.scanDateDescription = ''

              if (file.Scan !== null) {
                file.isInfected = file.Scan.Status === Enums.ScanStatus.Infected
                file.scanStatus =
                  Enums.ScanStatus.properties[file.Scan.Status].translation
                file.scanDateDescription = ''

                //Generate date description if available
                if (file.Scan.ScannedDate !== null) {
                  file.scanDateDescription = moment(file.Scan.ScannedDate).format(
                    '(DD MMMM YYYY - HH:mm)'
                  )
                }
              }
            })

            this.workflowFiles = response.data.Files
          } else {
            toastr.error(
              dictionaryService.translate(
                'WorkflowDetail.Messages.ErrorFetchingWorkflowFiles'
              ),
              true
            )
          }
        })
        .catch(function(e) {
          toastr.error(
            dictionaryService.translate(
              'WorkflowDetail.Messages.ErrorFetchingWorkflowFiles'
            ),
            e,
            true
          )
        })
    },
    getWorkflowSigningFiles: function() {
      const getSigningFilesFullEndpoint = apiEndpoints.getSigningFilesEndpoint.replace(
        '{workflowId}',
        this.WorkflowId
      )

      this.showError = false
      axios
        .get(getSigningFilesFullEndpoint)
        .then(response => {
          if (response.data && response.data.Files) {
            //to docusign download button validation
            if (response.data.IsDocuSignSigning) {
              this.DownloadDocuSignFiles = response.data.HasDownloadableFiles
              this.DocuSignWorkflow = true // condition to check if we need to disable the download link for each files for the docuSign
            }
            response.data.Files.forEach(function(file) {
              if (file.MimeType === 'application/pdf') {
                file.iconClass = 'file-pdf'
              } else {
                file.iconClass = 'file'
              }

              file.CreatedDateDescription = moment(file.CreatedDate).format(
                'DD-MM-YYYY HH:mm'
              )
              file.MayDownload = file.IsDownloadable
              file.scanStatus =
                Enums.ScanStatus.properties[Enums.ScanStatus.Pending].translation
              file.scanDateDescription = ''

              if (file.Scan !== null) {
                file.isInfected = file.Scan.Status === Enums.ScanStatus.Infected
                file.scanStatus =
                  Enums.ScanStatus.properties[file.Scan.Status].translation
                file.scanDateDescription = ''

                //Generate date description if available
                if (file.Scan.ScannedDate !== null) {
                  file.scanDateDescription = moment(file.Scan.ScannedDate).format(
                    '(DD MMMM YYYY - HH:mm)'
                  )
                }
              }
            })

            this.workflowSigningFiles = response.data.Files
            //TODO: Hotfix to disable download link for each individual file for DocuSign
            if (this.DocuSignWorkflow) {
              this.workflowSigningFiles.forEach(function(file) {
                file.isInfected = true // It disable the download link as per the CSS Class
              })
            }
          } else {
            toastr.error(
              dictionaryService.translate(
                'WorkflowDetail.Messages.ErrorFetchingWorkflowSigningFiles'
              ),
              true
            )
          }
        })
        .catch(function(e) {
          toastr.error(
            dictionaryService.translate(
              'WorkflowDetail.Messages.ErrorFetchingWorkflowSigningFiles'
            ),
            e,
            true
          )
        })
    },
    onStartDateChanged: function() {
      // Update Due Dates
      const currentStartDate = moment(this.StartDate)
      this.tasks[0].StartDate = currentStartDate
      
      for (let i = 0; i < this.tasks.length; i++) {
        this.tasks[i].DueDate = currentStartDate.isAfter(this.tasks[i].DueDate) ?  currentStartDate : this.tasks[i].DueDate
      }
    },
    translate: function(key) {
      return dictionaryService.translate(key)
    },
    toggleWorkflowHistoryLightBox: function(show) {
      this.showWorkflowHistoryLightBox =
        typeof show === 'boolean' ? show : !this.showWorkflowHistoryLightBox
    },
    toggleEditMode() {
      this.isTaskEditMode = true
      //Prevent reset of due dates due to timezone change
      for (let i = 0; i < this.tasks.length; i++) {
        this.tasks[i].DueDate = this.originalTasks[i].DueDate
      }
    },
    toggleTaskCollapse: function(task, show) {
      task.open = typeof show === 'boolean' ? show : !task.open
    },
    toggleConfirmDelete: function(show) {
      if (!this.isTaskEditMode) {
        this.showConfirmDelete = show
      }
    },
    toggleConfirmRollOver: function(show) {
      if (!this.isTaskEditMode) {
        this.showConfirmRollOver = show
      }
    },
    updateWorkflow: function() {
      if (this.FilingName.length === 0) {
        this.isEmptyEditTitle = true
        toastr.error(dictionaryService.translate('WorkflowDetail.Messages.SaveError'))
        return
      }

      const updatedWorkflowData = {
        WorkflowId: this.WorkflowId,
        StartDate:  moment(this.StartDate).format('YYYY-MM-DD') + 'T00:00:00Z',
        Year: this.Year,
        FilingName: this.FilingName,
        Tasks: this.tasks.map(task => {
          return {
            StepId: task.StepId,
            TaskId: task.Id,
            Description: task.Description,
            DueDate: task.DueDate
                        .add(task.DueDate.utcOffset(), 'minutes')  //add offset to UTC of that moment at that day.
                        .utc() //converts to UTC and resets the offset to 0.
                        .format(), //formats in UTC date time format when moment is in .utc mode.
            AssignedUsers: task.AssignedUsers.map(user => {
              return {
                Id: user.Id || user.UserId,
                UserType: user.Type || user.UserType,
                IsDelegated: user.IsDelegated
              }
            })
          }
        })
      }
      axios
        .put(apiEndpoints.updateTasksEndpoint, updatedWorkflowData)

        .then(response => {
          if (response.data) {
            this.showError = !response.data
            this.getWorkflowDetails()
            this.getWorkflowFiles()
            this.getWorkflowSigningFiles()
            this.isTaskEditMode = false
            this.workflowDetailsLoaded = false
            this.isEmptyEditTitle = false
            toastr.success(
              dictionaryService.translate('WorkflowDetail.Messages.SaveSuccessful'),
              response,
              true
            )
          } else {
            toastr.error(
              dictionaryService.translate('WorkflowDetail.Messages.SaveError'),
              true
            )
          }
        })
        .catch(e => {
          this.errorMessage = null
          if (e.response.status === 400) {
            this.errorMessage = e.response.data.ExceptionMessage
          }
          this.showError = true
          toastr.error(
            dictionaryService.translate('WorkflowDetail.Messages.SaveError'),
            e,
            true
          )
        })
        
    },
    cancelEditWorkFlow() {
      this.isTaskEditMode = false
      this.showError = false
      this.workflowDetailsLoaded = false

      // If edit is canceled we remove the additional tasks that might have been added
      this.tasks = this.setAdditionalTasksProperties(
        this.cleanWithJson([...originalTasks]),
        this.cleanWithJson(originalData)
      )

      //Format due date to avoid timezone updates
      const originalTaskLength = originalData.Tasks.length
      for( let i = 0; i < originalTaskLength; i++ ) {
          this.tasks[i].dueDateDescription =  moment(originalData.Tasks[i].DueDate).utc().format('DD/MM/YYYY')
          this.tasks[i].expectedStartDateDescription = moment(originalData.Tasks[i].ExpectedStartDate).utc().format('DD/MM/YYYY')
          this.tasks[i].actualStartDateDescription = moment(originalData.Tasks[i].ActualStartDate).utc().format('DD/MM/YYYY')
      }
      this.StartDate = this.ActualStartDate // reset start date to onload start date
    },
    deleteExistingTask: function(stepIndex) {
      this.hasDeletedTasks = true
      Vue.delete(this.tasks, stepIndex)
    },
    setUsers: function(index) {
      const tempSelectedUsers = []
      const tempSelectedEmployees = []

      const currentTask = this.tasks[index]

      currentTask.AssignedUsers.forEach(tempSelectedUser => {
        if (
          (tempSelectedUser.Type && tempSelectedUser.Type.toString() === '200') ||
          (tempSelectedUser.UserType && tempSelectedUser.UserType.toString() === '200')
        ) {
          tempSelectedUsers.push(tempSelectedUser)
          this.filterUsersArrays(this.currentTask.users, tempSelectedUser)
        } else {
          tempSelectedEmployees.push(tempSelectedUser)
          this.filterUsersArrays(this.currentTask.employees, tempSelectedUser)
        }
      })

      // The following logic is copied from Signals_Src\src\pages\add-workflow.js.
      this.currentTask.users =
        this.tasks[index].Code.toLowerCase() === 'sign' || this.tasks[index].Code.toLowerCase() === 'docusignsigningemployee'
          ? this.users.filter(user => user.MaySign === true)
          : this.users

      // The following logic is copied from Signals_Src\src\pages\add-workflow.js.
      this.currentTask.employees =
        this.tasks[index].Code.toLowerCase() === 'sign' || this.tasks[index].Code.toLowerCase() === 'docusignsigningclient'
          ? this.employees.filter(employee => employee.MaySign === true)
          : this.employees

      this.currentTask.selectedUsers = tempSelectedUsers
      this.currentTask.selectedEmployees = tempSelectedEmployees

      // Set the current step based on the passed index
      this.currentTaskIndex = index

      this.usersLightbox.open = true
    },
    submitLightbox: function(e) {
      // get the list of users
      this.currentTask.selectedUsers = e.selectedContacts
      this.currentTask.selectedEmployees = e.selectedEmployees
      const newAssignedUsersFullList = e.selectedContacts.concat(e.selectedEmployees)
      const originalTaskAssignedUsers = this.tasks[this.currentTaskIndex].AssignedUsers

      newAssignedUsersFullList.forEach(newUser => {
        const newUserId = this.getUserIdValue(newUser)

        originalTaskAssignedUsers.forEach(originUser => {
          const originUserId = this.getUserIdValue(originUser)

          if (newUserId.toString() !== originUserId.toString()) {
            newUser.IsDelegated = false
          }
        })
      })

      Vue.set(
        this.tasks[this.currentTaskIndex],
        'AssignedUsers',
        newAssignedUsersFullList
      )
      this.closeLightbox()
    },
    filterUsersArrays: function(targetArray, currentSelectedUser) {
      targetArray.forEach(function(tempUser, i) {
        if (
          (currentSelectedUser.Id &&
            currentSelectedUser.Id.toString() === tempUser.UserId.toString()) ||
          (currentSelectedUser.UserId &&
            currentSelectedUser.UserId.toString() === tempUser.UserId.toString())
        ) {
          targetArray.splice(i, 1)
        }
      })
    },
    getUserIdValue: function(userObject) {
      let result = null

      if (userObject) {
        if (userObject.Id) {
          result = userObject.Id
        } else {
          result = userObject.UserId
        }
      }
      return result
    },
    getInitialData: function() {
      const endpoint = apiEndpoints.getInitialData
        .replace('{oid}', this.CustomerId)
        .replace('{cid}', this.CustomerId)
      axios.get(endpoint).then(({ data }) => {
        this.baseSteps = data.BaseSteps
        this.periods = data.Periods
      })
    },
    setCurrentBaseStep: function(baseStepId) {
      this.currentBaseStep = baseStepId
    },
    /**
     * AddWorkflowStep (AddTask) is a function that will add a new item to tasks array
     * Info: originalTask is the unaltered tasks array that is fetched *before* edit mode is turned on initially.
     */
    addWorkflowStep: function() {
      this.resetErrors()
      const baseStep = this.baseSteps.find(obj => {
        return obj.BaseWorkflowStepId === this.currentBaseStep
      })

      const lastCurrentTask = [...this.tasks].slice(-1)[0]
      const now = moment()
      const last = moment(lastCurrentTask.DueDate)

      const dueDate = now.isAfter(last) ? now : last

      this.tasks.push({
        ActualStartDate: null,
        AssignedUsers: [],
        CompletedDate: null,
        Description: '',
        DueDate: dueDate,
        DueDateStatus: 5, //start as inactive
        StepId: baseStep.BaseWorkflowStepId,
        IsSigningTask: baseStep.IsSigningStep,
        IsUploadTask: baseStep.IsUploadSignFileStep,
        SortIndex: this.tasks.length,
        Name: baseStep.StepName,
        originalIndex: this.tasks.length,
        selectedEmployees: [],
        selectedUsers: [],
        MayBeChanged: true,
        Id: this.generateGuid(),
        Code: baseStep.Code,
        Status: 100 //not started
      })
      this.currentBaseStep = null
    },
    generateGuid: generateGuid,
    resetErrors: function() {
      this.showError = false
      this.errorMessage = ''
    },
    //Drag-drop
    onDragStart: function(event, draggedIndex) {
      event.dataTransfer.setData(
        'text',
        JSON.stringify({
          draggedIndex: draggedIndex
        })
      )

      //Add image if method available
      if (event.dataTransfer.setDragImage) {
        const stepContainer = this.$refs['task-container--' + draggedIndex][0]
        event.dataTransfer.setDragImage(
          stepContainer,
          event.currentTarget.offsetLeft,
          event.currentTarget.offsetTop
        )
      }

      this.draggedIndex = draggedIndex
    },
    onDragOver(e, index) {
      if (this.mayDragOverTarget(index)) {
        e.preventDefault()
      }
    },
    onDragEnd: function() {
      this.draggedIndex = null
      this.draggedOverIndex = null
    },
    onDragDrop: function(event, index) {
      //Prevents issue of invalid url in Firefox
      event.preventDefault()
      if (
        this.draggedIndex !== this.draggedOverIndex &&
        this.mayDragOverTarget(this.draggedOverIndex)
      ) {
        const draggedTask = this.tasks[this.draggedIndex]
        this.tasks.splice(this.draggedIndex, 1)
        this.tasks.splice(this.draggedOverIndex, 0, draggedTask)

        //Check all steps due date
        this.updateCurrentDueDate(index)
      }
    },
    onDragEnter: function(event, draggedOverIndex) {
      event.preventDefault()
      if (
        draggedOverIndex !== null && // no initial self assign.
        this.mayDragOverTarget(draggedOverIndex)
      ) {
        this.draggedOverIndex = draggedOverIndex
      }
    },
    mayDragOverTarget(index) {
      if (typeof index === 'number' && this.tasks[index].Status === 100) {
        return true
      }
      return false
    },
    canDragOverCurrent(index) {
      if (this.mayDragOverTarget(index) && this.draggedOverIndex === index) {
        return true
      }
      return false
    },
    updateCurrentDueDate(index) {
      this.tasks[index].DueDate = this.tasks[this.tasks.length - 1].DueDate
    },
    //end drag-drop
    isWeekendDay(date) {
      return moment(date).day() === 0 || moment(date).day() === 6
    },
    minDate(task, index) {
      if (index === 0) {
        return moment(task.StartDate).startOf('date')
      }
      return moment(this.tasks[index - 1].DueDate).startOf('date')
    }
  },
  watch: {
    workflowDetailsLoaded: function(isLoaded) {
      if (isLoaded && portalController === 'ShareDocumentsEmployeeJson') {
        this.getInitialData()
      }
    }
  }
}
