
import store from '@/store'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const editModule = namespace('supplyRequest2EditStore')

//Required components
import mazSupplyRequestEditHeader from '@/components/custom/mazSupplyRequestEditHeader/index.vue'
import mazSupplyRequestEditInitial from '@/components/custom/mazSupplyRequestEditInitial/index.vue'
import mazSpinner from '@/components/general/mazSpinner/index.vue'

@Component({
  store: store,
  components: {
    mazSupplyRequestEditHeader,
    mazSupplyRequestEditInitial,
    mazSpinner
  }
})
export default class SupplyRequest2Edit extends Vue {
  @editModule.Getter('ID') id!: string
  @editModule.Getter('EXISTS') exists!: boolean
  @editModule.Getter('NOT_FOUND_MESSAGE') notFoundMessage!: boolean
  @editModule.Getter('LABELS') labels!: any
  @editModule.Action('GET_SUPPLY_REQUEST') getSupplyRequest!: (id: string) => void

  mounted() {
    this.getSupplyRequest(this.id)
  }
}
