<template>
  <maz-modal @on-close="onCloseModal" v-if="isInactive">
    <template slot="modal-header">
      <h3 class="mb-2">{{ translate("SessionTimeOut.Labels.Title") }}</h3>
      <div class="ml-3 close" v-on:click="onCloseModal" />
    </template>
    <p>
      {{ translate("SessionTimeOut.Labels.TimeToLogout") }}
      <strong>{{ countDown }}</strong>
      {{ translate("SessionTimeOut.Labels.CounterSeconds") }}
    </p>
    <p>{{ translate("SessionTimeOut.Labels.WantToStaySigned") }}</p>
    <template slot="modal-footer">
      <maz-button
        class="c-btn--green order-2 order-sm-1 mt-2 mt-sm-0"
        v-on:click.native="onKeepLoggedIn"
      >
        {{ translate("SessionTimeOut.Labels.YesStaySigned") }}</maz-button
      >
      <maz-button
        class="c-btn--red order-1 order-sm-2"
        v-on:click.native="onContinueLogout"
        >{{ translate("SessionTimeOut.Labels.NoLogout") }}
      </maz-button>
    </template>
  </maz-modal>
</template>

<script>
import EventBus from "@/plugins/EventBus";
import mazModal from "@/components/general/mazModal/index.vue";
import mazButton from "@/components/general/mazButton/index.vue";
import dictionaryService from "@/services/modules/dictionaryService";

export default {
  name: "maz-session-timout-counter",
  components: {
    mazButton,
    mazModal,
  },
  props: {
    isInactive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countDown: 60,
      stopTimer: false,
      logout_redirect_url: "/logout",
    };
  },
  methods: {
    translate: function (label) {
      return dictionaryService.translate(label);
    },
    countDownTimer() {
      if (this.countDown > 0 && !this.stopTimer) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }

      if (this.countDown === 0) {
        window.location = this.logout_redirect_url;
      }
    },
    onCloseModal() {
      EventBus.$emit("close-session-timout-modal");
      this.countDown = 60;
      this.stopTimer = true;
    },
    onKeepLoggedIn() {
      EventBus.$emit("close-session-timout-modal");
      this.onCloseModal();
    },
    onContinueLogout() {
      window.location = this.logout_redirect_url;
    },
  },
  watch: {
    isInactive: function (value) {
      if (value) {
        this.stopTimer = false;
        this.countDownTimer();
      }
    },
  },
};
</script>
