export const getters = {
  CLIENT_LIST: (state: any): object[] => state.clientList,
  SEARCH_CLIENT_LIST: (state: any): object[] => state.searchClientList,
  CURRENT_PAGE: (state: any): number => state.currentPage,
  MAX_PAGE_COUNTER: (state: any): number => state.maxPageCounter,
  LOADER: (state: any): boolean => state.loader,
  LABELS: (state: any) => state.labels,
  MESSAGES: (state: any) => state.messages,
  CUSTOMER_PERMISSION: (state: any) => state.customerPermission,
  ADD_URL: (state: any) => state.addUrl,
}
