/* 
    - These endpoints can only be used with POST
    - In each endpoint the body of the request should have the following body:
        {
            path: "path to file that is calling the endpoint"
            id: "identifier chosen by front-ender to identify location inside script"
            message: "message describing what needs to be logged"
            state: "relevant state on client side, serialized as json, without line-breaks" (optional)
        }
*/

export default {
    logError: "ClientLogging/LogError",
    logInfo: "ClientLogging/LogInfo",
    logWarn: "ClientLogging/LogWarn",
    logDebug: "ClientLogging/LogDebug",
    logFatal: "ClientLogging/LogFatal"
}