<template>
  <div :class="{ 'c-segment--edit': editState }" class="c-segment qa-segment">
    <div class="d-md-flex align-items-center position-relative">
      <div class="flex-fill">
        <div class="d-flex align-items-center flex-wrap flex-sm-nowrap" v-if="!editState">
          <maz-state-indicator
            :state_code="segment.isCompleteCode"
            :state_number="indexPosition"
            class="u-w-xs-100"
            show_state_number
          />
          <div class="w-100">
            <div class="u-pt-14px u-pt-sm-0 float-left d-flex align-items-center">
              <h5
                @click="toggleSegmentOpen"
                class="c-segment__title"
                :class="{ collapsed: !isSegmentOpen }"
              >
                {{ segment.title }}
              </h5>
              <div class="e-updated-label ml-3" v-if="showUpdatedTag">
                {{ labels.supplyRequestUpdatedTag }}
              </div>
            </div>
          </div>
        </div>

        <!-- EDIT STATE -->
        <div class="d-flex align-items-center flex-wrap flex-sm-nowrap" v-if="editState">
          <maz-state-indicator
            :state_code="segment.isCompleteCode"
            :state_number="indexPosition"
            class="order-1"
            show_state_number
          />
          <div class="flex-fill order-3 order-sm-2">
            <div class="c-segment--edit__title-input">
              <div class="form-group mb-0">
                <input class="form-control" v-model="cloneOfSegmentItem.title" />
              </div>
            </div>
          </div>
        </div>
        <!-- END OF EDIT STATE -->
      </div>
      <div class="c-segment__icons-container d-flex text-nowrap" v-if="!editState">
        <div class="c-segment__icons d-flex align-items-center">
          <!-- start not applicable icon -->
          <div
            class="c-segment__container d-flex text-nowrap align-items-center"
            v-if="segment.isNotApplicable"
          >
            <div class="c-segment__icon c-segment__icon--not-applicable" />
            <div class="c-segment__counter">&nbsp;</div>
          </div>
          <!-- end not applicable icon -->
          <div
            class="c-segment__container d-flex text-nowrap align-items-center"
            v-if="delegeeCounter > 0"
          >
            <div class="c-segment__icon c-segment__icon--delegate" />
            <div class="c-segment__counter">{{ delegeeCounter }}</div>
          </div>
          <div
            class="c-segment__container d-flex text-nowrap align-items-center"
            v-if="fileCounter > 0"
          >
            <div class="c-segment__icon c-segment__icon--document" />
            <div class="c-segment__counter">{{ fileCounter }}</div>
          </div>
          <!-- start comment icon -->
          <div
            class="c-segment__container d-flex text-nowrap align-items-center"
            v-if="segment.comments.length > 0"
          >
            <div class="c-segment__icon c-segment__icon--comment" />
            <div class="c-segment__counter">{{ segment.comments.length }}</div>
          </div>
          <!-- end comment icon -->
        </div>

        <div
          @click="toggleSegmentOpen"
          class="c-segment__collapsable-icon"
          :class="{ collapsed: !isSegmentOpen }"
        />
      </div>
    </div>
    <div class="c-segment__content" v-if="!editState">
      <div
        :id="'collapseContent_' + segment.id.clean"
        class="c-segment__body"
        v-show="isSegmentOpen"
      >
        <div class="o-separator o-separator--extra-small" v-if="segment.description" />
        <div>{{ segment.description }}</div>

        <div class="c-segment-list" v-if="segment.segmentItems.length > 0">
          <ul class="c-segment-list__list">
            <li :key="'view-' + index" v-for="(listItem, index) in segment.segmentItems">
              {{ listItem.title }}
            </li>
          </ul>
        </div>

        <div
          class="o-separator o-separator--extra-small"
          v-if="segment.attachments.length > 0"
        />

        <transition name="smooth">
          <maz-attachment-list
            :segment="segment"
            class="c-attachments--plain c-attachments--view-state"
            v-if="segment.attachments.length > 0"
          />
        </transition>

        <!-- Only show this separator when either there is a segment list showing or one of the buttons in c-segment-buttons is showing -->
        <div
          class="o-separator o-separator--small"
          v-if="
            segment.segmentItems.length > 0 ||
            segment.mayAddComment ||
            segment.mayDelegate ||
            segment.mayMarkAsNotApplicableOrCompleted
          "
        />

        <div class="c-segment-buttons">
          <div class="c-segment-buttons__container d-lg-flex justify-content-between">
            <div class="d-flex float-left">
              <maz-button
                :disabled="enableDelegate"
                :href="'#' + segment.commentEditor.id"
                :label="labels.supplyRequestCommentButton"
                @click.native="showCommentEditor"
                class="c-btn c-btn--ghost c-segment-buttons__image--comment"
                v-if="segment.mayAddComment"
              />
              <maz-button
                :disabled="enableComments"
                :href="'#' + segment.delegateEditor.id"
                :label="labels.supplyRequestDelegateButton"
                @click.native="showDelegateEditor"
                class="c-btn c-btn--ghost c-segment-buttons__image--delegate mt-0"
                v-if="segment.mayDelegate"
              />
            </div>

            <div class="o-separator o-separator--small d-none" />

            <transition name="smooth">
              <div
                class="d-flex float-right"
                v-if="segment.mayMarkAsNotApplicableOrCompleted"
              >
                <maz-button
                  :disabled="enableComments || enableDelegate"
                  :label="labels.supplyRequestNotApplicableLabel"
                  @click.native="toggleApplicableState"
                  class="c-btn--blue c-segment-buttons__image--not-applicable"
                  v-if="!segment.isNotApplicable && !segment.files.length > 0"
                />

                <maz-button
                  :disabled="enableComments || enableDelegate"
                  :label="labels.supplyRequestNotApplicableUndoLabel"
                  @click.native="toggleApplicableState"
                  class="c-btn--blue"
                  v-if="segment.isNotApplicable"
                />

                <maz-button
                  :disabled="enableComments || enableDelegate"
                  :label="labels.supplyRequestSetToIsComplete"
                  @click.native="toggleMarkAsCompleteState"
                  class="c-btn--green c-segment-buttons__image--checkmark"
                  v-if="
                    !segment.isNotApplicable &&
                    !segment.isComplete &&
                    segment.files.length > 0
                  "
                />

                <maz-button
                  :disabled="enableComments || enableDelegate"
                  :label="labels.supplyRequestSetToIsNotComplete"
                  @click.native="toggleMarkAsCompleteState"
                  class="c-btn--blue"
                  v-if="
                    !segment.isNotApplicable &&
                    segment.isComplete &&
                    segment.files.length > 0
                  "
                />

                <maz-button
                  :disabled="enableComments || enableDelegate"
                  :label="supplyRequestFileUploader.labels.addFiles"
                  @click.native="toggleFileUploader"
                  class="c-btn c-btn--blue c-segment-buttons__image--add-files"
                  v-if="segment.mayAddFiles"
                />
              </div>
            </transition>
          </div>
        </div>

        <div v-if="activeFileUploader && !segment.isNotApplicable && segment.mayAddFiles">
          <div class="o-separator o-separator--small" />

          <maz-file-upload
            :category="category"
            :segment="segment"
            :store="store"
            :subCategory="subCategory"
            :supplyRequestFileUploader="supplyRequestFileUploader"
            :uploadUrl="uploadUrl"
          />
        </div>

        <maz-comment-editor
          :commentEditor="segment.commentEditor"
          @onCancel="onCancelComments"
          @onSubmit="onSubmitComments"
        />

        <maz-delegate-editor
          :category="category"
          :delegateEditor="segment.delegateEditor"
          :segment="segment"
          :subCategory="subCategory"
          @onCancelDelegate="onCancelDelegate"
          @onSubmitDelegate="onSubmitDelegate"
        />

        <transition name="smooth" mode="out-in">
          <div
            v-if="segment.files.length > 0 && showFileList"
            :key="'files' + segment.id.clean"
          >
            <!-- Only show this separator when there are files -->
            <div class="o-separator o-separator--small" />

            <a
              :href="segment.downloadZipUrl"
              class="e-link-bold c-segment__download-link float-right"
              v-if="segment.mayDownloadZip"
              >{{ labels.supplyRequestDownloadAllSegmentFilesButton }}</a
            >

            <maz-file-list
              :segment="segment"
              v-if="segment.files.length > 0 && showFileList"
            />
          </div>
        </transition>

        <!-- Only show this separator when there are delegations -->
        <div
          class="o-separator o-separator--small"
          v-if="segment.delegations.length > 0 && showDelegationsList"
        />
        <transition name="smooth">
          <maz-delegations-list
            :segment="segment"
            v-if="segment.delegations.length && showDelegationsList"
          />
        </transition>

        <!-- Only show this separator when there are comments -->
        <div
          class="o-separator o-separator--small"
          v-if="segment.comments.length > 0 && showCommentList"
        />

        <transition name="smooth">
          <div v-if="segment.comments && showCommentList">
            <maz-comment
              :comment="comment"
              :key="'comment-' + index"
              v-for="(comment, index) in segment.comments"
            />
          </div>
        </transition>
      </div>
    </div>

    <!-- EDIT STATE -->
    <div class="c-segment--edit__content" v-if="editState">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>{{ labels.supplyRequestDescription }}</label>
            <textarea
              class="form-control c-textarea--height"
              v-model="cloneOfSegmentItem.description"
            />
          </div>
        </div>
      </div>
      <div class="c-segment-list" v-if="cloneOfSegmentItem.segmentItems.length > 0">
        <ul class="c-segment-list__list">
          <li
            :key="'segment-' + index"
            class="c-segment-list__item d-flex align-items-center"
            v-for="(listItem, index) in cloneOfSegmentItem.segmentItems"
          >
            <input class="form-control" v-model="listItem.title" />
            <div @click="onDeleteListItem(index)">
              <div class="c-segment-list__delete" />
            </div>
          </li>
        </ul>
      </div>
      <div class="e-add-item">
        <div class="e-icon-add" />
        <a @click="onAddListItem(category, subCategory, segment)" class="e-link-bold">{{
          labels.supplyRequestAddListItem
        }}</a>
      </div>
      <div class="o-separator o-separator--small" />
      <div class="d-flex">
        <div class="flex-sm-fill">
          <!--<maz-button class="c-btn--blue" label="Todo Add template"></maz-button> Will be done in a later sprint -->
        </div>
        <div class="d-flex flex-wrap flex-sm-nowrap flex-fill flex-sm-grow-0">
          <maz-button
            :label="labels.cancel"
            @click.native="onCancelAddSegment(category, subCategory, segment)"
            class="c-btn--empty order-2 order-sm-1"
          />
          <maz-button
            :disabled="isButtonDisabled"
            :label="labels.confirm"
            @click.native="onSubmitEditSegment(category, subCategory, segment, index)"
            class="c-btn--blue order-1 order-sm-2"
          />
        </div>
      </div>
    </div>
    <!-- END OF EDIT STATE -->
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import mazButton from '../../general/mazButton'
import mazStateIndicator from '../../general/mazStateIndicator'
import mazCommentEditor from '../../custom/mazCommentEditor'
import mazDelegateEditor from '../../custom/mazDelegateEditor'
import mazComment from '../../custom/mazComment'
import mazFileUpload from '../../general/mazFileUpload'
import mazFileList from '../../general/mazFileList'
import mazAttachmentList from '../../general/mazAttachmentList'
import mazDelegationsList from '../../custom/mazDelegationsList'
import EventBus from '@/plugins/EventBus'

const _targetStore = 'supplyRequest2DetailStore/'

export default {
  name: 'maz-supply-request-view-segment',
  components: {
    mazButton,
    mazStateIndicator,
    mazCommentEditor,
    mazDelegateEditor,
    mazComment,
    mazFileUpload,
    mazFileList,
    mazAttachmentList,
    mazDelegationsList
  },
  props: {
    indexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    index: {
      required: false,
      type: Number,
      default() {
        return ''
      }
    },
    segment: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    subCategory: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isSegmentOpen: false,
      isNotApplicable: this.segment.isNotApplicable,
      isComplete: this.segment.isComplete,
      activeFileUploader: false,
      enableComments: false,
      enableFiles: false,
      enableDelegate: false,
      editState: false,
      showFileList: true,
      showCommentList: true,
      showDelegationsList: true,
      cloneOfSegmentItem: '',
      segmentItem: this.segment,
      store: 'supplyRequest2DetailStore/',
      uploadUrl: 'uploadfile'
    }
  },
  mounted() {
    EventBus.$on('onSubmitSupplyRequest', () => {
      this.isSegmentOpen = false
    })

    EventBus.$on('scrollToSegment', id => {
      if (id === this.segment.id.clean) {
        this.openSegment()
      }
    })
  },
  computed: {
    ...mapGetters({
      id: _targetStore + 'ID',
      supplyRequestFileUploader: _targetStore + 'SUPPLY_REQUEST_FILE_UPLOADER',
      supplyRequestUpdatedSegmentsOverview:
        _targetStore + 'SUPPLY_REQUEST_UPDATED_SEGMENTS_OVERVIEW',
      labels: _targetStore + 'LABELS'
    }),
    segmentNavigationId() {
      return '#collapseContent_' + this.segment.id.clean
    },
    fileCounter() {
      if (this.segment.files.length === 0) {
        return this.segment.numberOfFiles
      } else {
        return this.segment.files.length
      }
    },
    delegeeCounter() {
      if (this.segment.delegations.length === 0) {
        return this.segment.numberOfDelegations
      } else {
        return this.segment.delegations.length
      }
    },
    isButtonDisabled() {
      let result = true

      let emptyListItem = false

      this.cloneOfSegmentItem.segmentItems.forEach(item => {
        if (item.title === '') {
          emptyListItem = true
        }
        return true
      })

      if (this.cloneOfSegmentItem.title.length > 0 && emptyListItem != true) {
        result = false
      }

      return result
    },
    showUpdatedTag() {
      const showTag = this.supplyRequestUpdatedSegmentsOverview.segments.find(
        segment => segment.id.clean === this.segmentItem.id.clean
      )

      if (showTag != undefined) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    onEditSegment(segmentId, segment) {
      if (segment == undefined) {
        // In case the segment does not exist yet
        this.cloneOfSegmentItem = JSON.parse(JSON.stringify(this.segmentItem))
      } else {
        // In case the segment exists
        this.cloneOfSegmentItem = JSON.parse(JSON.stringify(segment))
      }

      this.editState = true
    },
    onSubmitEditSegment(category, subCategory, segment, index) {
      const body = {
        supplyRequestId: this.id,
        categoryId: category.id.original,
        groupSegmentId: subCategory.id.original,
        segmentId: segment.id,
        segmentData: {
          title: this.editSegmentTitleInput,
          description: this.editSegmentDescriptionInput,
          segmentItems: this.cloneOfSegmentItem.segmentItems
        },
        clone: this.cloneOfSegmentItem,
        helpText: '',
        segmentItems: segment.segmentItems,
        position: index
      }

      this.$store.dispatch(_targetStore + 'POST_ADDED_SEGMENT', body)

      this.$emit('clicked', true)

      $('.e-add-item--segment')
        .removeClass('disabled')
        .prop('disabled', false)

      this.editState = false
    },
    openSegment() {
      this.getFilesDelegationsAttachments()
      this.isSegmentOpen = true
    },
    toggleSegmentOpen() {
      if (!this.isSegmentOpen) {
        this.openSegment()
      } else {
        this.isSegmentOpen = false
      }
    },
    getFilesDelegationsAttachments() {
      const segmentId = this.segment.id.original
      const body = {
        supplyRequestId: this.segment.parentSupplyRequestId.original,
        categoryId: this.segment.parentCategoryId.original,
        subCategoryId: this.segment.parentSubCategoryId.original,
        segmentId: segmentId
      }

      this.$store.dispatch(_targetStore + 'GET_UPDATED_SEGMENT_FILES', body)
      this.$store.dispatch(_targetStore + 'GET_UPDATED_SEGMENT_DELEGATIONS', body)
      this.$store.dispatch(_targetStore + 'GET_ATTACHMENTS', body)
    },
    toggleApplicableState(event) {
      this.isNotApplicable = !this.isNotApplicable

      const isNotApplicableData = {
        supplyRequestId: this.segment.parentSupplyRequestId.original,
        categoryId: this.segment.parentCategoryId.original,
        subcategoryId: this.segment.parentSubCategoryId.original,
        segmentId: this.segment.id.original,
        IsNotApplicable: this.isNotApplicable
      }

      this.$store.dispatch(
        _targetStore + 'POST_SEGMENT_MARKED_OR_NOT_AS_APPLICABLE',
        isNotApplicableData
      )
      if (this.isNotApplicable) {
        setTimeout(() => {
          this.isSegmentOpen = false
        }, 750);
      }
      this.$emit('onSubmit', event)
    },
    showCommentEditor(event) {
      const editorId = '#' + this.segment.commentEditor.id
      const targetId = event.target.getAttribute('href')

      if (editorId === targetId) {
        $(editorId).collapse('show')

        this.activeFileUploader = false
        this.enableComments = true
        this.enableDelegate = false
        this.showFileList = false
        this.showDelegationsList = false
      }
    },
    showDelegateEditor(event) {
      const editorId = '#' + this.segment.delegateEditor.id
      const targetId = event.target.getAttribute('href')

      if (editorId === targetId) {
        $(editorId).collapse('show')

        this.activeFileUploader = false
        this.enableComments = false
        this.enableDelegate = true
        this.showFileList = false
        this.showCommentList = false
      }
    },
    toggleFileUploader() {
      this.activeFileUploader = !this.activeFileUploader

      if (this.activeFileUploader) {
        this.enableFiles = true
      } else {
        this.showCommentList = true
        this.showDelegationsList = true
        this.activeFileUploader = false
        this.enableFiles = false
      }
    },
    toggleMarkAsCompleteState() {
      this.isComplete = !this.isComplete
      this.activeFileUploader = false

      const isMarkedAsCompleteData = {
        supplyRequestId: this.segment.parentSupplyRequestId.original,
        categoryId: this.segment.parentCategoryId.original,
        subcategoryId: this.segment.parentSubCategoryId.original,
        segmentId: this.segment.id.original,
        isComplete: this.isComplete
      }

      this.$store.dispatch(
        _targetStore + 'POST_SEGMENT_MARKED_AS_COMPLETE',
        isMarkedAsCompleteData
      ).then(succeeded => {
        if (succeeded) {
          if (this.isComplete) {
            setTimeout(() => {
              this.isSegmentOpen = false
            }, 500);
          } else {
            this.getFilesDelegationsAttachments()
          }
        }
      })
    },
    onCancelComments() {
      this.showSegmentLists()
      this.enableComments = false
    },
    onSubmitComments() {
      this.showSegmentLists()
      this.enableComments = false
    },
    onCancelDelegate() {
      this.showSegmentLists()
      this.enableDelegate = false
    },
    onSubmitDelegate() {
      this.showSegmentLists()
      this.enableDelegate = false
    },
    onCancelFileUpload() {
      this.showSegmentLists()

      this.activeFileUploader = false
      this.enableFiles = false
    },
    onSubmitFileUpload() {
      this.showSegmentLists()

      this.activeFileUploader = false
      this.enableFiles = false
    },
    onAddListItem() {
      this.cloneOfSegmentItem.segmentItems.push({
        id: this.getGuidId(),
        title: ''
      })
    },
    onDeleteListItem(index) {
      this.cloneOfSegmentItem.segmentItems.splice(index, 1)
    },
    onCancelAddSegment(category, subCategory, segment) {
      const body = {
        categoryId: category.id,
        subCategoryId: subCategory.id,
        segmentId: this.segmentItem.id,
        segment: segment,
        clone: this.cloneOfSegmentItem
      }

      this.$store.dispatch(_targetStore + 'SET_CANCELLED', body)

      this.$emit('clicked', true)

      $('.e-add-item--segment')
        .removeClass('disabled')
        .prop('disabled', false)

      this.editState = false
    },
    showSegmentLists() {
      this.showFileList = true
      this.showDelegationsList = true
      this.showCommentList = true
    },
    getGuidId() {
      let uuidValue = '',
        k,
        randomValue
      for (k = 0; k < 32; k++) {
        randomValue = (Math.random() * 16) | 0

        if (k == 8 || k == 12 || k == 16 || k == 20) {
          uuidValue += '-'
        }
        uuidValue += (k == 12
          ? 4
          : k == 16
            ? (randomValue & 3) | 8
            : randomValue
        ).toString(16)
      }
      return uuidValue
    }
  },

  watch: {
    'segment.files': function (oldarr) {
      if (this.isSegmentOpen) {
        if (oldarr.length !== this.segment.numberOfFiles) {
          this.getFilesDelegationsAttachments()
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/__main.scss';

.c-segment {
  @include segment;
}

.smooth-enter-active,
.smooth-leave-active {
  transition: opacity 0.5s;
}
.smooth-enter,
.smooth-leave-to {
  opacity: 0;
}
</style>
