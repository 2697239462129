import clientMaintenanceApi from '@/api/modules/clientMaintenanceApi'
import config from '@/api/modules/config'
import axiosService from '@/services/modules/axiosService'
import helpersService from '@/services/modules/helpersService'
import toastr from 'toastr'
import { currentActionContext } from './index'

const _targetApi = clientMaintenanceApi
const _baseEndpoint = config.endpointBase

export const actions = {
  GET_MY_PROFILE_ORGANIZATIONS(ctx: any) {
    const { commit, state } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.myProfileClientOrganizations
    )
    commit.UPDATE_CLIENT_PROFILE_ORGANIZATION_PAGE_LOADER_MUTATION({
      viewOrganizationTable: true
    })
    axiosService.get(endpoint, (res: any) => {
      if (res) {
        const body = helpersService.convertObjectKeysToCamelCase(res)
        commit.UPDATE_CLIENT_PROFILE_ORGANIZATIONS(body)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.UPDATE_CLIENT_PROFILE_ORGANIZATION_PAGE_LOADER_MUTATION({
        viewOrganizationTable: false
      })
    })
  },
  GET_MY_PROFILE_PERMISSIONS_MODAL(ctx: any, payload: any) {
    const { commit, state } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.myProfileClientPermissions,
      '{organizationId}',
      payload.id
    )
    commit.UPDATE_CLIENT_PROFILE_ORGANIZATION_PAGE_LOADER_MUTATION({
      viewPermissionModal: true
    })
    commit.UPDATE_CLIENT_PROFILE_PERMISSIONS_INITIAL_DATA(payload)
    axiosService.get(endpoint, (res: any) => {
      if (res) {
        const body = helpersService.convertObjectKeysToCamelCase(res)
        commit.UPDATE_CLIENT_PROFILE_PERMISSIONS(body)
      } else {
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.UPDATE_CLIENT_PROFILE_ORGANIZATION_PAGE_LOADER_MUTATION({
        viewPermissionModal: false
      })
    })
  }
}
