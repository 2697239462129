<template>
  <div class="d-flex flex-wrap flex-sm-nowrap justify-content-sm-end">
    <maz-button
      :label="audit_logs.labels.createSignalLabel"
      class="c-btn--blue"
      data-target="#modalCreateSignal"
      data-toggle="modal"
      v-if="supply_request_permissions.maySendSignal"
    />

    <maz-button
      :label="audit_logs.labels.showAuditLogsLabel"
      class="c-btn--blue"
      data-target="#modalAuditLogs"
      data-toggle="modal"
    />

    <!-- Modal create signal -->
    <div
      aria-hidden="true"
      class="modal fade"
      id="modalCreateSignal"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{{ audit_logs.labels.titleSendSignalLabel }}</h3>
            <button
              @click="onCancel"
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            />
          </div>
          <div class="modal-body modal-body--margin-bottom">
            <p>{{ audit_logs.labels.subTitleSendSignalLabel }}</p>
            <div class="o-separator o-separator--extra-small" />
            <div class="form-group">
              <label class="c-forms__label">{{
                audit_logs.labels.emailFieldLabel
              }}</label>
              <input
                class="form-control c-forms__input c-forms__input--email"
                v-model="emailField"
              />
            </div>
            <div class="form-group">
              <label class="c-forms__label">{{
                audit_logs.labels.emailTextFieldLabel
              }}</label>
              <textarea
                class="form-control c-textarea--height c-forms__input--email-text"
                v-model="emailTextField"
              />
            </div>
          </div>
          <div class="modal-footer">
            <maz-button
              :label="labels.cancelLabel"
              @click.native="onCancel"
              aria-label="Close"
              class="c-btn--empty"
              data-dismiss="modal"
            />
            <maz-button
              :disabled="!isComplete"
              :label="audit_logs.labels.createSignalLabel"
              @click.native="onSubmitCreateSignal"
              aria-label="Close"
              class="c-btn--blue"
              data-dismiss="modal"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal audit logs -->
    <div
      aria-hidden="true"
      class="modal fade"
      id="modalAuditLogs"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{{ audit_logs.labels.showAuditLogsLabel }}</h3>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button" />
          </div>
          <div class="modal-body modal-body--colored modal-body--margin-bottom">
            <maz-spinner v-if="!audit_logs.exists" />
            <maz-audit-logs :logs="audit_logs.logs" />
          </div>
          <div class="modal-footer" v-if="audit_logs.exportInfo.mayExport">
            <maz-button
              :label="audit_logs.labels.exportAuditLogsLabel"
              @click.native="onExport"
              class="c-btn--blue"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'

import mazButton from '../../general/mazButton'
import mazAuditLogs from '../../custom/mazAuditLogs'
import mazSpinner from '../../general/mazSpinner'

const _targetStore = 'supplyRequest2DetailStore/'

export default {
  name: 'maz-details-action-buttons',
  components: {
    'maz-button': mazButton,
    'maz-audit-logs': mazAuditLogs,
    'maz-spinner': mazSpinner
  },
  props: {
    audit_logs: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    supply_request_permissions: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      modalId: 'modalAuditLogs',
      emailField: '',
      emailTextField: ''
    }
  },
  computed: {
    ...mapGetters({
      supplyRequestId: _targetStore + 'ID',
      labels: _targetStore + 'LABELS'
    }),
    isDisabled() {
      let result = 'disabled'

      if (this.audit_logs.logs.length > 0) {
        result = ''
      }

      return result
    },
    inputEmailField() {
      return this.$el.getElementsByClassName('c-forms__input--email')[0]
    },
    inputEmailTextField() {
      return this.$el.getElementsByClassName('c-forms__input--email-text')[0]
    },
    isComplete() {
      return this.emailField && this.emailTextField
    }
  },
  methods: {
    onExport(event) {
      this.$store.dispatch(
        _targetStore + 'DOWNLOAD_FILE_AUDIT_LOGS',
        this.audit_logs.exportInfo.exportUrl
      )
      this.$emit('onExport', event)
    },
    onSubmitCreateSignal(event) {
      const submitData = {
        supplyRequestId: this.supplyRequestId,
        inputEmailFieldText: '',
        inputEmailTextFieldText: ''
      }

      if (this.inputEmailField && this.inputEmailTextField) {
        submitData.inputEmailFieldText = this.inputEmailField.value
        submitData.inputEmailTextFieldText = this.inputEmailTextField.value
      }

      this.clearContent()
      $('#modalCreateSignal').modal('hide')

      this.$store.dispatch(_targetStore + 'POST_SUBMIT_SIGNAL', submitData)
      this.$emit('onSubmitCreateSignal', event)
    },
    onToggleModal() {
      this.$store.dispatch(_targetStore + 'GET_AUDIT_LOGS', this.supplyRequestId)
    },
    onCancel(event) {
      this.clearContent()
      this.$emit('onCancel', event)
    },
    clearContent(param) {
      const targetEmailField = param || this.inputEmailField
      const targetEmailTextField = param || this.inputEmailTextField

      this.emailField = ''
      this.emailTextField = ''
      targetEmailField.value = this.emailField
      targetEmailTextField.value = this.emailTextField
    }
  },
  mounted() {
    $('#' + this.modalId).on('shown.bs.modal', this.onToggleModal)
  }
}
</script>
