<template>
  <div class="c-files c-files--delegations">
    <div class="c-files__list">
      <div
        :key="file.id"
        class="c-files__item d-flex flex-wrap flex-md-nowrap align-items-center"
        v-for="(file, index) in supplyRequestFileUploader.files"
      >
        <span :title="file.fileName" class="c-files__cell c-files__cell--name">
          <a :href="file.downloadUrl" v-if="file.mayDownload">{{ file.fileName }}</a>
          <span v-else>{{ file.fileName }}</span>
        </span>
        <span class="c-files__cell c-files__cell--email">{{ file.ownerEmail }}</span>
        <span class="c-files__cell c-files__cell--time">{{ file.createdDate }}</span>
        <span class="c-files__cell c-files__cell--delete" v-show="file.mayDelete">
          <maz-spinner
            class="c-spinner--small float-right"
            v-if="showDeleteSpinner && indexClicked === index"
          />
          <span @click="deleteFile(index, file.id)">
            <span class="c-files__icon" v-if="!showDeleteSpinner" />
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import mazSpinner from '../../general/mazSpinner'
import store from '@/store/store2'
export default {
  name: 'maz-delegated-segment-file-list',
  components: {
    'maz-spinner': mazSpinner
  },
  data() {
    return {
      showDeleteSpinner: false,
      indexClicked: null
    }
  },
  computed: {
    supplyRequestFileUploader() {
      return store.state.supplyRequest2DelegatedSegmentStore.supplyRequestFileUploader
    }
  },
  methods: {
    deleteFile(index, fileId) {
      this.indexClicked = index

      const body = {
        fileId: fileId,
        delegationId: store.state.supplyRequest2DelegatedSegmentStore.delegationId
      }

      this.showDeleteSpinner = true
      store.dispatch.supplyRequest2DelegatedSegmentStore.DELETE_FILE(body)
    }
  },
  watch: {
    supplyRequestFileUploader: {
      handler() {
        if (this.supplyRequestFileUploader.fileDeleted === true) {
          this.showDeleteSpinner = false
          store.dispatch.supplyRequest2DelegatedSegmentStore.GET_UPDATED_SEGMENT_FILES({ delegationId: store.state.supplyRequest2DelegatedSegmentStore.delegationId })
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-files {
  .c-files__list {
    display: inline-block;
    width: 100%;
    background-color: $white;
    border-radius: 6px;

    .c-files__item {
      padding: 6px 32px 6px 32px;

      @include media-breakpoint-down(sm) {
        position: relative;
        margin-bottom: 12px;
        padding: 16px;
        border-radius: 4px;
      }

      &:first-child {
        padding-top: 24px;
      }

      &:last-child {
        padding-bottom: 24px;
      }

      .c-files__cell {
        display: flex;
        align-items: center;
        padding-right: 20px;
        font-size: 16px;
        line-height: 24px;

        span {
          font-size: 16px;
        }

        @include media-breakpoint-down(sm) {
          justify-content: center;
          width: 100%;
          padding-right: 0;
          padding-bottom: 12px;
          text-align: center;

          &:last-child {
            padding-bottom: 0;
          }
        }

        &:last-child {
          padding-right: 0;
        }

        &.c-files__cell--name {
          display: inline;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          @include media-breakpoint-up(md) {
            width: 35%;
            min-width: 35%;
          }
        }

        &.c-files__cell--email {
          @include media-breakpoint-up(md) {
            width: 40%;
            min-width: 40%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        &.c-files__cell--time {
          @include media-breakpoint-up(md) {
            display: inline;
            width: 20%;
            min-width: 20%;
            text-align: right;
          }

          @include media-breakpoint-down(sm) {
            padding-bottom: 0;
          }
        }

        &.c-files__cell--delete {
          @include media-breakpoint-up(md) {
            display: inline;
            width: 5%;
            min-width: 5%;
            text-align: right;
          }

          @include media-breakpoint-down(sm) {
            position: absolute;
            top: 12px;
            right: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: auto;
          }

          .c-files__icon {
            display: inline-block;
            width: 13px;
            height: 16px;
            background-color: $dark-blue;
            cursor: pointer;
            -webkit-mask-image: url('~@/assets/images/icon_delete_small.svg');
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: 13px 16px;
            mask-image: url('~@/assets/images/icon_delete_small.svg');
            mask-repeat: no-repeat;
            mask-size: 13px 16px;

            @include media-breakpoint-down(sm) {
              width: 18px;
              height: 24px;
              background-size: contain;
            }

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}
</style>
