import EventBus from '@/plugins/EventBus'
import axiosService from '@/services/modules/axiosService'
import dictionaryService from '@/services/modules/dictionaryService'
import OktaSignIn from '@okta/okta-signin-widget'
import $ from 'jquery'
import toastr from 'toastr'

export function initiateOkta() {
  const oktaContainerId = 'okta-login-container'
  const $oktaContainer = $('#' + oktaContainerId)
  const $oktaSubmitBtn = $oktaContainer.find("input[type='submit']")

  String.prototype.toBool = function() {
    return this.valueOf().toLowerCase() == 'true'
  }

  function getQueryString(key) {
    let value
    const qs = window.location.search.substring(1)

    qs.split('&').forEach(function(pair) {
      const kv = pair.split('=')
      if (kv.length === 2 && kv[0] === key) value = kv[1]
    })
    return value || ''
  }

  const getOktaWidgetLabels = function() {
    // Checks for the existance of dictionary keys starting with
    // "Login.OktaWidget.Labels" and returns only the ones with non-empty strings
    const returnObj = {}
    const divider = 'Login.OktaWidget.Labels.' // Prefix used to group dictionaryService entries on Sitecore
    for (const entry in dictionaryService.values) {
      const key = entry.split(divider)

      if (
        key.length > 1 && // There must be a second half of the split key
        dictionaryService.values[entry] && // There must be a non-null entry
        dictionaryService.values[entry].length > 0
      ) {
        // There must be a non-empty string entry

        returnObj[key[1]] = dictionaryService.values[entry] // Add this key and value to the return object
      }
    }
    return returnObj
  }

  if ($oktaContainer.length > 0) {
    // Retrieve data-* attribute values
    const lang = $oktaContainer.data('lang') ? $oktaContainer.data('lang') : 'nl-NL'
    const orgUrl = atob($oktaContainer.data('orgurl'))
    const clientId = atob($oktaContainer.data('clientid'))

    // Okta widget features
    const router = $oktaContainer.data('router').toBool()
    const rememberMe = $oktaContainer.data('rememberme').toBool()
    const autoPush = $oktaContainer.data('autopush').toBool()
    const smsRecovery = $oktaContainer.data('smsrecovery').toBool()
    const callRecovery = $oktaContainer.data('callrecovery').toBool()
    const windowsVerify = $oktaContainer.data('windowsverify').toBool()
    const selfServiceUnlock = $oktaContainer.data('selfserviceunlock').toBool()
    const multiOptionalFactorEnroll = $oktaContainer
      .data('multioptionalfactorenroll')
      .toBool()
    const hideSignOutLinkInMFA = $oktaContainer.data('hidesignoutlinkinmfa').toBool()

    // Remove help link
    const pollForHelpLink = setInterval(function() {
      if ($('.js-help-link').length > 0) {
        $('.js-help-link')
          .parent()
          .remove()
        clearInterval(pollForHelpLink)
      }
    }, 100)

    // Bind action to Okta's submit button
    $(document).on('click', $oktaSubmitBtn, function() {
      // Disabled for now
      //$oktaContainer.addClass("loading");

      // HACK:
      // This hack is being used as we do not have a way to check for a 401 or other error
      // on the OKTA widget. The failure callback is not used for login failures.
      // Please check: https://github.com/okta/okta-signin-widget/issues/261.
      // This starts an interval (every 100ms) which will poll for the existance of the class
      // .o-form-has-errors, which is introduced by OKTA widget on a failed login situation.
      const pollForError = setInterval(function() {
        if ($('.o-form-has-errors').length > 0 || $('.mfa-verify').length > 0) {
          $oktaContainer.removeClass('loading')
          clearInterval(pollForError)
        }
      }, 100)
    })

    // Sets up the translations object to use on Okta Widget below
    const translations = {}
    translations[lang] = getOktaWidgetLabels()

    const redirectUrl =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '') // will redirect to the page we specified next to this one

    // After okta login and server-side check, redirect to this url
    const returnUrl = getQueryString('returnUrl')

    const oktaSignIn = new OktaSignIn({
      baseUrl: orgUrl,
      clientId: clientId,
      redirectUri: redirectUrl,
      authParams: {
        responseType: ['id_token', 'token'], //same as default.
        scopes: ['openid', 'email', 'profile'], // 'groups' has been removed since not valid.
        pkce: false // check https://developer.okta.com/docs/concepts/oauth-openid/#authorization-code-flow-with-pkce
      },

      features: {
        router: router,
        rememberMe: rememberMe,
        autoPush: autoPush,
        smsRecovery: smsRecovery,
        callRecovery: callRecovery,
        windowsVerify: windowsVerify,
        selfServiceUnlock: selfServiceUnlock,
        multiOptionalFactorEnroll: multiOptionalFactorEnroll,
        hideSignOutLinkInMFA: hideSignOutLinkInMFA
      },

      language: lang,

      i18n: translations // coming from Sitecore dictionary entries

      //helpLinks Note:
      //Help link is hidden with CSS: see inside component mazClientLoginOkta MAZLOC-520.
    })

    oktaSignIn.renderEl({ el: $oktaContainer }).then(res => {
      if (res.status === 'FORGOT_PASSWORD_EMAIL_SENT') {
        $oktaContainer.removeClass('loading')
      } else if (res.status === 'SUCCESS') {
        EventBus.$emit('start-client-login-loading')
        const token = $('#csrf > input').val()
        const formData = new FormData()
        formData.append('__RequestVerificationToken', token)
        formData.append('AccessToken', res.tokens.accessToken.accessToken)
        formData.append('returnUrl', returnUrl)
        formData.append('AuthServerId', 'default')

        axiosService.post(
          '/ajax-signals/ExtranetSecurity/LoginFromOktaWidget/',
          formData,
          data => {
            if (data.Status === 'OK') {
              if (data.Redirect) {
                window.location = data.Redirect
              } else {
                window.location = '/'
              }
            } else {
              EventBus.$emit('stop-client-login-loading')
            }
          },
          () => {
            EventBus.$emit('stop-client-login-loading')
            //catch error
            toastr.error(
              dictionaryService.translate('Login.OktaWidget.Labels.errors.E0000006')
            )
          }
        )
      }
    })
  }
}
