<template>
  <maz-lightbox-container :show="show" @close="onClose">
    <div class="row">
      <maz-user-selection
        class="col-12 col-md-6"
        :title="translate('WorkflowDefinitionAdd.Labels.SelectUsers')"
        :searchPlaceholder="translate('WorkflowDefinitionAdd.Labels.SearchUsers')"
        :selectedTitle="translate('WorkflowDefinitionAdd.Labels.SelectedUsers')"
        :list="contactsList"
        :user-id-key="'UserId'"
        :selected-list.sync="selectedContacts"
        :is-user-delegated-on-add="isUserDelegatedOnAdd"
      />
      <maz-user-selection
        class="col-12 col-md-6"
        :list="employeesList"
        :user-id-key="'UserId'"
        :selected-list.sync="selectedEmployees"
        :is-user-delegated-on-add="isUserDelegatedOnAdd"
      />
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <maz-button class="c-btn c-btn--green float-right" @click.native="submit">
          {{ translate('WorkflowDefinitionAdd.Labels.Done') }}
        </maz-button>
      </div>
    </div>
  </maz-lightbox-container>
</template>

<script>
import dictionaryService from '@/services/modules/dictionaryService'
import mazLightboxContainer from '@/components/general/mazLightboxContainer/index.vue'
import mazUserSelection from '@/components/general/mazUserSelection/index.vue'
import mazButton from '@/components/general/mazButton/index.vue'

export default {
  name: "mazAssignedUsersLightbox",
  props: {
    show: {
      required: true,
      type: Boolean
    },

    contactsList: {
      required: true,
      type: Array
    },
    employeesList: {
      required: true,
      type: Array
    },
    selectedContactsList: {
      required: true,
      type: Array
    },
    selectedEmployeesList: {
      required: true,
      type: Array
    },
    onClose: {
      required: true,
      type: Function
    },
    onSubmit: {
      required: true,
      type: Function
    },
    isUserDelegatedOnAdd: {
      /**
       * prop to set default IsDelegated flag to false (i.e. workflow-details)
       */
      type: Boolean,
      default: true
    }
  },
  components: {
    mazUserSelection,
    mazLightboxContainer,
    mazButton
  },
  data() {
    return {
      selectedContacts: [],
      selectedEmployees: []
    };
  },
  methods: {
    translate: function (label) {
      return dictionaryService.translate(label);
    },
    submit: function () {
      this.onSubmit({
        selectedEmployees: this.selectedEmployees,
        selectedContacts: this.selectedContacts
      });
    }
  },
  watch: {
    selectedEmployeesList: function (v) {
      this.selectedEmployees = v;
    },
    selectedContactsList: function (v) {
      this.selectedContacts = v;
    }
  }
}
</script>
