<template>
  <div
    :data-cy="dataCy"
    :class="{
      'is-open': menuOpen,
      'is-empty-menu': emptyMenu,
      'c-dropdown--danger': isDanger
    }"
    :data-id="selectedValue"
    class="c-dropdown c-input"
    @click="toggleMenu"
    v-click-outside="onClickOutside"
  >
    <span class="selectlabel">{{ selectedLabel }}</span>
    <ul class="c-dropdown__options">
      <li @click.stop class="c-select__filter" v-if="Boolean(filter) === true">
        <input
          ref="filter"
          @input="onFilterInput($event.target.value)"
          class="c-select__filter-input"
          type="text"
          :placeholder="filterPlaceholderLabel"
          v-model="filterValue"
        />
      </li>
      <li
        :class="{
          'is-disabled': isDisabled
        }"
        :disabled="isDisabled"
        :key="'o-' + index"
        :value="option[propvalue]"
        @click="selectItem(option, $event)"
        @input="$emit('input', $event.target.value)"
        class="filter-select c-option"
        v-for="(option, index) in filteredOptions"
      >
        <span v-if="!propvalue">{{ option }}</span>
        <span v-if="propvalue">{{ option[prop] }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import dictionaryService from '@/services/modules/dictionaryService'

export default {
  name: 'mazDropDown',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    dataCy: {
      type: String,
      required: true
    },
    value: { //For use with v-model. (i.e. preselected option)
      type: [String, Number],
      default: null
    },
    label: {
      type: [String, Number],
      default: ''
    },
    /**
     * prop is a required property and is always used as
     * the key of the visible label in the dropdown option.
     * example: options: [{id: 1, name: 'Peter'}]
     * prop is "name" in this case.
     */
    prop: {
      type: String,
      required: true
    },
    /**
     * propvalue is a required property and is always used 
     * as the key of a value (in an array of objects).
     * example: options: [{id: 1, name: 'Peter'}]
     * propvalue is "id" in this case.
     */
    propvalue: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    filterPlaceholder: { //fallback is filterPlaceholderLabel.
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isDanger: {
      type: Boolean,
      default: false
    }
  }
  ,
  data() {
    return {
      menuOpen: false,
      emptyMenu: true,
      selectedItem: {},
      filterValue: ''
    }
  },
  computed: {
    selectedLabel() {
      return this.selectedItem ? this.selectedItem[this.prop] ? this.selectedItem[this.prop] : this.label : this.label
    },
    selectedValue() {
      return this.selectedItem ? this.selectedItem[this.propvalue] : ''
    },
    filteredOptions() {
      if (this.options) {
        return this.options.filter(obj => String(obj[this.prop]).toLowerCase().includes(String(this.filterValue).toLowerCase()))
      } else {
        return []
      }
    }
    ,
    filter() {
      return this.options.length > 5
    },
    filterPlaceholderLabel() {
      return this.filterPlaceholder || dictionaryService.translate('Labels.Common.Search') + '...'
    }
  },

  mounted() {
    if (this.value) {
      this.syncValue(this.value)
    }

    this.emptyMenu = this.options && this.options.length === 0
  },

  watch: {
    options(value) {
      this.selectedItem = {}
      this.emptyMenu = value.length === 0
    },
    value(value) { //v-model version.
      this.syncValue(value)
    }
  },

  methods: {
    toggleMenu() {
      if (this.menuOpen) {
        this.close()
      } else {
        this.open()
      }
    },
    close() {
      this.filterValue = ''
      this.menuOpen = false
    },
    open() {
      this.menuOpen = true
      if (this.filter) {
        setTimeout(() => {
          this.$refs.filter.focus()
        }, 50)
      }
    },
    selectItem(value, $event) {
      this.selectedItem = value
      const v = this.propvalue ? value[this.propvalue] : value
      this.$emit('onChange', v, $event)
      this.$emit('input', v)
    },
    onClickOutside() {
      this.close()
    },
    syncValue(value) {
      if (this.prop) {
        this.selectedItem = this.options.find(obj => obj[this.propvalue] === value)
      } else {
        this.selectedItem = value
      }
    },
    onFilterInput(filterValue) {
      this.$emit('filtering', filterValue)
      this.filterValue = filterValue
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 8px 14px 12px 14px;
  color: $dark-gray;
  font-size: 18px;
  font-family: 'Halyard-Medium', sans-serif;
  background-color: $white;
  border: 1px solid $mid-gray;
  cursor: pointer;
  user-select: none;

  &::before {
    position: absolute;
    top: 0;
    right: 14px;
    display: flex;
    align-items: center;
    width: 24px;
    height: 100%;
    background-image: url('~@/assets/images/icon_arrow_down_select.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 19px;
    content: '';
    pointer-events: none;
  }

  &:hover {
    border: 1px solid $dark-gray;
    transition: border 0.2s, box-shadow 0.2s;
  }
  &:focus {
    border: 1px solid $dark-gray;
    border-radius: 0;
    outline: none;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    &:active {
      box-shadow: none;
    }
  }

  .selectlabel.selectlabel {
    display: block;
    width: 100%;
    padding-right: 22px;
    overflow: hidden;
    font-size: 14px;
    font-family: 'Halyard-Book', sans-serif;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .c-dropdown__options {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    display: none;
    width: auto;
    max-height: 200px;
    margin: 0 -1px;
    padding: 0;
    padding-top: 47px;
    overflow: auto;
    border: 1px solid $dark-gray;
    border-top: 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

    li {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 4px 14px;
      font-family: 'Halyard-Book', sans-serif;
      line-height: 1;
      text-align: left;
      list-style: none;
      background-color: $white;
      border-top: 1px solid $light-steel-blue;
      cursor: pointer;

      span {
        color: $dark-gray;
        font-size: 14px;
        font-family: 'Halyard-Book', sans-serif;
      }

      &:hover {
        color: $steel-blue;
        background-color: $light-steel-blue;

        span {
          color: $blue;
        }
      }

      &.c-select__filter {
        padding: 0;
      }

      &.is-disabled {
        cursor: not-allowed;
        pointer-events: none;
      }

      &:first-child {
        border-top: 0;
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: $white-smoke;
    }

    &::-webkit-scrollbar-track {
      z-index: 2;
      background-color: $white-smoke;
      border-radius: 2em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $blue;
      border-radius: 2em;
    }
  }

  &.is-open {
    border: 1px solid $dark-gray;
    &:before {
      transform: rotate(180deg);
    }
    .c-dropdown__options {
      display: block;
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.is-empty-menu {
    background-color: $white;
    cursor: not-allowed;
    opacity: 0.4;
    pointer-events: none;
  }
  .c-select__filter {
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      right: 14px;
      display: flex;
      align-items: center;
      width: 24px;
      height: 100%;
      color: $dark-gray;
      background-image: url('~@/assets/images/icon_input_filter.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 19px;
      content: '';
      pointer-events: none;
    }
  }
  .c-select__filter-input {
    width: 100%;
    height: 100%;
    padding: 14px;
    color: $dark-gray;
    font-size: 14px;
    font-family: 'Halyard-Book', sans-serif;
    border: none;
    outline: none;
    outline-style: none;
    outline-offset: 0;
    &::placeholder {
      padding-left: 2px;
    }
  }
  &.c-dropdown--danger {
    span {
      color: $status-red;
    }
    border: solid 2px $status-red;
    transition: none;
  }
}
</style>
