export const mutations = {
  UPDATE_EMPLOYEE_MAINTENANCE_TAB_INDEX_MUTATION(state: any, payload: number) {
    state.tabNavigation.activeTabIndex = payload
  },
  UPDATE_EMPLOYEE_MAINTENANCE_LOADER_MUTATION(state: any, payload: object) {
    state.loader = { ...state.loader, ...payload }
  },
  SET_EMPLOYEE_NAME_MUTATION(state: any, payload: any) {
    state.employeeName = payload
  },
  SET_EMPLOYEE_IS_NOT_FOUND_MUTATION(state: any) {
    state.isNotFound = true
  }
}
