<template>
  <maz-lightbox-container class="c-confirmation-lightbox" :show="show" @close="onCancel">
    <div class="d-flex flex-column">
      <h3 class="c-confirmation-lightbox__title" v-show="title !== ''">
        {{ title }}
      </h3>
      <p class="c-confirmation-lightbox__message mb-4">
        {{ message }}
      </p>
      <div class="d-flex align-items-center justify-content-end">
        <maz-button class="c-btn c-btn--ghost" @click.native="onCancel">
          {{ cancelLabel }}
        </maz-button>
        <maz-button class="c-btn c-btn--green ml-3" @click.native="onConfirm">
          {{ confirmLabel }}
        </maz-button>
      </div>
    </div>
  </maz-lightbox-container>
</template>

<script>
import dictionaryService from '@/services/modules/dictionaryService'

import mazLightboxContainer from '@/components/general/mazLightboxContainer/index.vue'
import mazButton from '@/components/general/mazButton/index.vue'

export default {
  name: "mazConfirmationLightbox",
  components: {
    mazLightboxContainer,
    mazButton
  },
  props: {
    show: {
      required: true,
      type: Boolean
    },
    title: {
      default: '',
      type: String
    },
    message: {
      required: true,
      type: String
    },
    confirmLabel: {
      default: dictionaryService.translate('Labels.Common.Confirm'),
      type: String
    },
    cancelLabel: {
      default: dictionaryService.translate('Labels.Common.Cancel'),
      type: String
    }
  },

  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onConfirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss">
.c-confirmation-lightbox {
  .c-lightbox-container__content-container {
    max-width: 400px;
  }

  .c-confirmation-lightbox__title {
    margin-bottom: 16px;
    font-size: 32px !important;
    text-transform: none;
  }

  .c-confirmation-lightbox__message {
    margin-left: 0 !important;
  }

  .c-button {
    margin-bottom: 0 !important;
  }
}
</style>