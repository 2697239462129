import helpersService from '@/services/modules/helpersService'
import { localization } from './localization'

export default () => ({
  id: helpersService.getSearchId('?eid=', window.location.search) as string,
  isNotFound: false,
  loader: {
    global: false
  },
  tabNavigation: {
    ...localization.tabNavigation,
    activeTabIndex: 0
  },
  messages: {
    somethingWentWrong: ''
  },
  employeeName: ''
})
