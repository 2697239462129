import dictionaryService from '@/services/modules/dictionaryService'

export const localization = {
  labels: {
    employeeDetails: dictionaryService.translate(
      'EmployeeDetail.Labels.EmployeeDetails'
    ) as string,
    connectedClients: dictionaryService.translate(
      'EmployeeDetail.Labels.ConnectedClients'
    ) as string,
    employeePermissions: dictionaryService.translate(
      'EmployeeDetail.Labels.EmployeePermissionsTitle'
    ) as string,
    firstName: dictionaryService.translate('Labels.Person.FirstName') as string,
    lastName: dictionaryService.translate('Labels.Person.LastName') as string,
    initials: dictionaryService.translate('Labels.Person.Initials') as string,
    fullName: dictionaryService.translate('Labels.Person.FullName') as string,
    gender: dictionaryService.translate('Labels.Person.Gender') as string,
    salutation: dictionaryService.translate('Labels.Person.Salutation') as string,
    referenceId: dictionaryService.translate('Labels.Person.RefId') as string,
    number: dictionaryService.translate('Labels.Person.Number') as string,
    username: dictionaryService.translate('Labels.Person.Email') as string,
    phone: dictionaryService.translate('Labels.Person.Phone') as string,
    language: dictionaryService.translate('Labels.Person.LanguageSelected') as string,
    picture: dictionaryService.translate('Labels.Person.Picture') as string,
    isFunctionalAdmin: dictionaryService.translate(
      'Labels.Person.IsFunctionalAdmin'
    ) as string,
    isSuperUser: dictionaryService.translate('Labels.Person.IsSuperUser') as string,
    isLocalAdmin: dictionaryService.translate('Labels.Person.IsLocalAdmin') as string,
    isSigningAccountant: dictionaryService.translate(
      'Labels.Person.IsSigningAccountant'
    ) as string,
    countries: dictionaryService.translate('Labels.Person.Countries') as string,
    countryField: dictionaryService.translate(
      'ClientSimpleOverview.Labels.SelectCountry'
    ) as string,
    selectedCountries: dictionaryService.translate(
      'EmployeeDetail.Labels.SelectedCountries'
    ) as string,
    unknown: dictionaryService.translate('EmployeeDetail.Labels.Unknown') as string,
    cancel: dictionaryService.translate('Labels.Common.Cancel') as string,
    save: dictionaryService.translate('Labels.Common.Save') as string,
    isYes: dictionaryService.translate('Labels.Common.True') as string,
    isNo: dictionaryService.translate('Labels.Common.False') as string,
    startDate: dictionaryService.translate('EmployeeDetail.Labels.StartDate') as string,
    endDate: dictionaryService.translate('EmployeeDetail.Labels.EndDate') as string,
    isActive: dictionaryService.translate('EmployeeDetail.Labels.IsActive') as string
  },
  connectedClientsTable: {
    labels: {
      customerNumber: dictionaryService.translate(
        'EmployeeDetail.Labels.CustomerNumber'
      ) as string,
      customerName: dictionaryService.translate(
        'EmployeeDetail.Labels.CustomerName'
      ) as string,
      customerEmail: dictionaryService.translate(
        'EmployeeDetail.Labels.CustomerEmail'
      ) as string,
      roleName: dictionaryService.translate(
        'EmployeeDetail.Labels.Role'
      ) as string
    }
  },
  messages: {
    noLinkedClients: dictionaryService.translate(
      'EmployeeDetail.Messages.NoLinkedClients'
    ) as string,
    changesSucessfullySaved: dictionaryService.translate(
      'EmployeeDetail.Messages.ChangesSuccessfullySaved'
    ) as string,
    somethingWentWrong: dictionaryService.translate(
      'EmployeeDetail.Messages.SomethingWentWrong'
    ) as string,
    fieldRequired: dictionaryService.translate(
      'EmployeeDetail.Messages.FieldRequired'
    ) as string,
    emailAlreadyExists: dictionaryService.translate(
      `EmployeeDetail.Messages.EmailAlreadyExists`
    ) as string
  }
}
