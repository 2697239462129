var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"maz-alert",class:[
    {
      'maz-alert--is-light': !_vm.isDark,
      'maz-alert--is-medium': _vm.isMedium,
      'maz-alert--is-success': _vm.isSuccess,
      'maz-alert--is-danger': _vm.isDanger,
      'maz-alert--is-info': _vm.isInfo
    }
  ]},[_c('div',{staticClass:"maz-alert__icon"},[_c('i'),_vm._t("icon")],2),[_c('div',{staticClass:"maz-alert__message",class:{ 'maz-alert--is-light': !_vm.isDark }},[_vm._t("message",[_vm._v(" "+_vm._s(_vm.message)+" ")])],2),_vm._t("link",[_c('a',{staticClass:"maz-alert__link",class:{ 'maz-alert--is-light': !_vm.isDark },attrs:{"href":_vm.linkUrl}},[_vm._v(" "+_vm._s(_vm.linkName)+" ")])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }