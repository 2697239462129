


















































































































































































































































import Vue from "vue";

import mazButton from "@/components/general/mazButton/index.vue";
import mazModal from "@/components/general/mazModal/index.vue";
import mazSpinner from "@/components/general/mazSpinner/index.vue";
// import mazSwitch from '@/components/general/mazSwitch/index.vue'
import mazList from "@/components/general/mazList/index.vue";
import mazTextInput from "@/components/general/mazTextInput/index.vue";

import store from "@/store/store2";

export default Vue.extend({
  name: "maz-client-maintenance-contact-permissions",

  components: {
    mazButton,
    mazSpinner,
    mazModal,
    // mazSwitch,
    mazTextInput,
    mazList,
  },

  data() {
    return {
      showOrganizationsModal: false,
      showPermissionsModal: false,
      showUnlinkOrganizationConfirmationModal: false,
      organizationId: "",
    };
  },

  created() {
    this.getContactOrganizationsPermissions();
  },

  computed: {
    shouldRedirect(): boolean {
      return (
        this.connectUrl !== undefined &&
        this.connectUrl !== "" &&
        this.connectUrl !== null
      );
    },
    connectUrl(): string {
      return store.getters.clientMaintenance.contact.CONNECT_URL;
    },
    id(): string {
      return store.getters.clientMaintenance.ID;
    },
    loader(): any {
      return store.getters.clientMaintenance.contact.LOADER;
    },
    labels(): any {
      return store.getters.clientMaintenance.contact.LABELS;
    },
    messages(): any {
      return store.getters.clientMaintenance.contact.MESSAGES;
    },
    permissions(): any {
      return store.getters.clientMaintenance.contact.PERMISSIONS;
    },
    permissionsTable(): any {
      return store.getters.clientMaintenance.contact
        .CONTACT_ORGANIZATIONS_PERMISSIONS_TABLE;
    },
    organizationContactPermissionsModal(): any {
      return store.getters.clientMaintenance.organization
        .ORGANIZATION_CONTACT_PERMISSION_MODAL;
    },
    organizationsModal(): any {
      return store.getters.clientMaintenance.contact.ORGANIZATIONS_MODAL;
    },
  },

  methods: {
    redirectToPowerApp(): void {
      window.open(this.connectUrl, "_blank")?.focus();
    },
    /**
     * VUEX
     */
    // getClientMaintenanceClientPermissionsModal(contact: any): void {
    //   store.dispatch.clientMaintenance.organization.GET_CLIENT_MAINTENANCE_CLIENT_PERMISSIONS_MODAL(
    //     contact
    //   )
    // },
    getConnectableOrganizations(): void {
      store.dispatch.clientMaintenance.contact.GET_CONNECTABLE_ORGANIZATIONS();
    },
    updateAvailableConnectableOrganizations(body: any): void {
      store.dispatch.clientMaintenance.contact.UPDATE_AVAILABLE_CONNECTABLE_ORGANIZATIONS(
        body
      );
    },
    updateOrganizationsList(body: any): void {
      //TODO: remove prefix in organization store as well.
      store.dispatch.clientMaintenance.contact.UPDATE_ORGANIZATIONS_LIST(body);
    },
    updateContactOrganizations(body: any): void {
      //TODO: remove prefix in organization store as well.
      store.dispatch.clientMaintenance.contact.UPDATE_CONTACT_ORGANIZATIONS(body);
    },
    // updateClientMaintenanceContactPermission(body: any): void {
    //   store.dispatch.clientMaintenance.organization.UPDATE_CLIENT_MAINTENANCE_CONTACT_PERMISSION(
    //     body
    //   )
    // },
    /**
     * COMPONENT METHODS
     */
    getContactOrganizationsPermissions() {
      const body = {
        clientId: this.id,
      };
      store.dispatch.clientMaintenance.contact.GET_CONTACT_ORGANIZATIONS_PERMISSIONS(
        body
      );
    },
    // openClientPermissionsModal(contact: object) {
    //   this.getClientMaintenanceClientPermissionsModal(contact)
    //   this.showPermissionsModal = true
    // },
    saveUpdatedContactOrganizations(organizations: any) {
      const tempIds = organizations.map((x: any) => x.Id);
      //TODO: Refactor object keys using the PascalCase helper service
      const body = {
        ContactId: this.id,
        OrganizationIds: tempIds.length > 0 ? tempIds : null,
      };
      if (body.OrganizationIds !== null) {
        this.updateContactOrganizations(body);
        this.showOrganizationsModal = false;
      }
    },
    // updatePermission(
    //   serviceIndex: number,
    //   permissionIndex: number,
    //   permissionIsSet: boolean
    // ) {
    //   //TODO: Refactor object keys using the PascalCase helper service
    //   const body = {
    //     OrganizationId: this.id,
    //     ContactId: this.organizationContactPermissionsModal.details.Id,
    //     ServiceId: this.organizationContactPermissionsModal.details.Services[serviceIndex]
    //       .Id,
    //     PermissionId: this.organizationContactPermissionsModal.details.Services[
    //       serviceIndex
    //     ].Permissions[permissionIndex].Id,
    //     IsSet: !permissionIsSet
    //   }

    //   this.updateClientMaintenanceContactPermission(body)
    // },
    unlinkOrganization(organization: any) {
      this.showUnlinkOrganizationConfirmationModal = true;
      this.organizationId = organization.Id;
    },
    cancelUnlinkOrganization(value: boolean) {
      this.showUnlinkOrganizationConfirmationModal = value;
    },
    confirmUnlinkOrganization() {
      this.showUnlinkOrganizationConfirmationModal = false;
      if (this.organizationId) {
        this.deleteOrganizationfromContacts(this.organizationId);
      }
    },
    deleteOrganizationfromContacts(organizationId: string) {
      const body = {
        OrganizationId: organizationId,
        ContactId: this.id,
      };
      store.dispatch.clientMaintenance.contact.DELETE_CLIENT_MAINTENANCE_ORGANIZATION_CONTACT(
        body
      );
    },
  },

  watch: {
    showOrganizationsModal: function (value: boolean): void {
      if (value) {
        this.getConnectableOrganizations();
      }
    },
  },
});
