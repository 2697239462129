
import store from '@/store'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

//Required components
import mazTemplateMaintenanceFilters from '@/components/custom/mazTemplateMaintenanceFilters/index.vue'

const templateModule = namespace('supplyRequest2TemplateMaintenanceStore')

@Component({
  store: store,
  components: {
    'maz-template-maintenance-filters': mazTemplateMaintenanceFilters
  }
})
export default class SupplyRequest2TemplateMaintenance extends Vue {
  @templateModule.Getter('TEMPLATES') templateMaintenance!: any
  @templateModule.Getter('TEMPLATE_DATA') templateData!: any
  @templateModule.Getter('TEMPLATE_MAINTENANCE_INITIAL_DATA')
  templateMaintenanceInitialData!: any
  @templateModule.Getter('TEMPLATE_MAINTENANCE_REFERENCE_DATA')
  templateMaintenanceReferenceData!: any
  @templateModule.Getter('LABELS') labels!: any

  @templateModule.Action('GET_TEMPLATE_MAINTENANCE_INITIAL_DATA')
  getInitialData!: () => void

  mounted() {
    this.getInitialData()
  }
}
