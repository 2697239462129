import dictionaryService from '@/services/modules/dictionaryService'
import { localization } from './localization'

export default () => ({
  step: 0,
  clientType: 0,
  messages: { ...localization.messages },
  labels: { ...localization.labels },
  clientOptions: [
    {
      name: dictionaryService.translate('ClientSimpleOverview.Labels.Contact') as string,
      value: 20
    },
    {
      name: dictionaryService.translate(
        'ClientSimpleOverview.Labels.Organization'
      ) as string,
      value: 10
    }
  ],
  genders: [] as MazInterfaces.ClientMaintenance.Gender[],
  languages: [] as MazInterfaces.ClientMaintenance.Language[],
  relationManagers: [] as MazInterfaces.ClientMaintenance.RelationManager[],
  countries: [] as MazInterfaces.ClientMaintenance.Countries[],
  validationErrors: [],
  clientUrl: '',
  customerPermission: {} as MazInterfaces.ClientMaintenance.CustomerPermission,
  shouldRefreshOnClose: false
})
