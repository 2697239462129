<template>
  <div
    :class="{active: value, disabled: disabled}"
    @click="toggleSwitch()"
    class="c-switch"
  >
    <label class="c-switch__label">{{labels.left}}</label>
    <div class="c-switch__inner-container">
      <span class="c-switch__circle" />
    </div>
    <label class="c-switch__label">{{labels.right}}</label>
  </div>
</template>

<script>
export default {
  name: 'maz-switch',
  props: {
    labels: {
      type: Object,
      default: () => {
        return {
          left: '',
          right: ''
        }
      }
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleSwitch() {
      this.$emit('input', !this.value)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 200px;
  min-height: 24px;
  overflow: hidden;
  color: $white;
  cursor: pointer;

  .c-switch__inner-container {
    display: flex;
    align-items: center;
    width: 48px;
    height: 24px;
    padding: 0 5px;
    overflow: hidden;
    color: #726e6e;
    background-color: #eaedee;
    border-radius: 12px;
  }

  .c-switch__circle {
    flex: initial;
    width: 20px;
    height: 20px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
    transform: translate(0px, 0);
    transition: transform 0.4s, color 0.4s;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
    pointer-events: none;
  }

  &.active .c-switch__inner-container {
    color: $white;
    background-color: $status-green;
  }

  &.active .c-switch__circle {
    transform: translate(100%, 0);
  }

  .c-switch__label {
    margin: 0;
  }
}
</style>
