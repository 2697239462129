import { currentGetterContext } from './index'
export const getters = {
  ID: (state: any): string => state.id,
  IS_EDIT_MODE: (state: any) => state.isEditMode,
  LOADER: (state: any) => state.loader,
  LABELS: (state: any) => state.labels,
  PERMISSIONS(...args: any): MazInterfaces.EmployeeMaintenance.EmployeePermission {
    const { state } = currentGetterContext(args)
    return state.employeeDetails.employeePermission
  },
  EMPLOYEE_DETAILS: (state: any) => state.employeeDetails,
  EDITED_EMPLOYEE_DETAILS: (state: any) => state.editedEmployeeDetails,
  GENDERS_LIST(...args: any): MazInterfaces.EmployeeMaintenance.Gender[] {
    const { state } = currentGetterContext(args)
    return state.genders
  },
  LANGUAGES_LIST(...args: any): MazInterfaces.EmployeeMaintenance.Language[] {
    const { state } = currentGetterContext(args)
    return state.languages
  },
  COUNTRIES_LIST(...args: any): MazInterfaces.EmployeeMaintenance.Countries[] {
    const { state } = currentGetterContext(args)
    return state.countries
  },
  CONNECTED_CLIENTS: (state: any): object[] => state.connectedClients,
  MESSAGES(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.messages
  },
  VALIDATION_ERRORS_LIST(
    ...args: any
  ): MazInterfaces.EmployeeMaintenance.ValidationError[] {
    const { state } = currentGetterContext(args)
    return state.validationErrors
  }
}
