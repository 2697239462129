import Dictionary from './dictionaryService'

const ProcessStatus = {
  Concept: 100,
  Pending: 200,
  Reopened: 250,
  SubmittedPending: 260,
  SubmittedByEmployeePending: 280,
  Submitted: 300,
  SubmittedByEmployee: 320,
  Approved: 700,
  Properties: {
    100: {
      id: 100,
      name: 'Concept',
      translation: Dictionary.translate('Labels.SupplyRequest.Status.Concept')
    },
    200: {
      id: 200,
      name: 'Pending',
      translation: Dictionary.translate('Labels.SupplyRequest.Status.Pending')
    },
    250: {
      id: 250,
      name: 'Reopened',
      translation: Dictionary.translate('Labels.SupplyRequest.Status.Reopened')
    },
    260: {
      id: 260,
      name: 'SubmittedPending',
      translation: Dictionary.translate('Labels.SupplyRequest.Status.SubmittedPending')
    },
    280: {
      id: 280,
      name: 'SubmittedByEmployeePending',
      translation: Dictionary.translate(
        'Labels.SupplyRequest.Status.SubmittedByEmployeePending'
      )
    },
    300: {
      id: 300,
      name: 'Submitted',
      translation: Dictionary.translate('Labels.SupplyRequest.Status.Submitted')
    },
    320: {
      id: 320,
      name: 'SubmittedByEmployee',
      translation: Dictionary.translate('Labels.SupplyRequest.Status.SubmittedByEmployee')
    },
    700: {
      id: 700,
      name: 'Approved',
      translation: Dictionary.translate('Labels.SupplyRequest.Status.Approved')
    }
  }
}

export default ProcessStatus
