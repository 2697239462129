<template>
  <div>
    <maz-task-details-employee v-if="isEmployeePortal" />
    <maz-task-details-client v-if="isClientPortal" />
  </div>
</template>

<script>
import Vue from 'vue'
import mazTaskDetailsEmployee from '@/components/custom/mazTaskDetails/employee.vue'
import mazTaskDetailsClient from '@/components/custom/mazTaskDetails/client.vue'

export default Vue.extend({
  name: 'taskDetailPage',

  components: {
    mazTaskDetailsEmployee,
    mazTaskDetailsClient
  },

  computed: {
    isClientPortal() {
      return window.__INITIAL_DATA__?.portalType === 'Client'
    },
    isEmployeePortal() {
      return window.__INITIAL_DATA__?.portalType === 'Employee'
    }
  }
})
</script>