import dictionaryService from '@/services/modules/dictionaryService'

export const localization = {
  labels: {
    close: dictionaryService.translate('Labels.Common.Close') as string,
    cancel: dictionaryService.translate('Labels.Common.Cancel') as string,
    save: dictionaryService.translate('ClientSimpleOverview.Labels.Save') as string,
    addClient: dictionaryService.translate(
      'ClientSimpleOverview.Labels.AddClient'
    ) as string,
    addClientViaUrl: dictionaryService.translate(
      'ClientSimpleOverview.Labels.AddClientViaUrl'
    ) as string,
    createContact: dictionaryService.translate(
      'ClientSimpleOverview.Labels.CreateContact'
    ) as string,
    createOrganization: dictionaryService.translate(
      'ClientSimpleOverview.Labels.CreateOrganization'
    ) as string,
    email: dictionaryService.translate('ClientSimpleOverview.Labels.Email') as string,
    firstName: dictionaryService.translate(
      'ClientSimpleOverview.Labels.FirstName'
    ) as string,
    lastName: dictionaryService.translate(
      'ClientSimpleOverview.Labels.LastName'
    ) as string,
    gender: dictionaryService.translate('ClientSimpleOverview.Labels.Gender') as string,
    language: dictionaryService.translate(
      'ClientSimpleOverview.Labels.Language'
    ) as string,
    relationManager: dictionaryService.translate(
      'ClientSimpleOverview.Labels.RelationManagerName'
    ) as string,
    selectClientType: dictionaryService.translate(
      'ClientSimpleOverview.Labels.SelectClientType'
    ) as string,
    selectGender: dictionaryService.translate(
      'ClientSimpleOverview.Labels.SelectGender'
    ) as string,
    selectCountry: dictionaryService.translate(
      'ClientSimpleOverview.Labels.SelectCountry'
    ) as string,
    addOtherClient: dictionaryService.translate(
      'ClientSimpleOverview.Labels.AddOtherClient'
    ) as string,
    goToClient: dictionaryService.translate(
      'ClientSimpleOverview.Labels.GoToClient'
    ) as string,
    legalName: dictionaryService.translate(
      'ClientSimpleOverview.Labels.LegalName'
    ) as string,
    countryLabel: dictionaryService.translate('Labels.Common.Country') as string
  },
  messages: {
    clientSuccessfullyCreated: dictionaryService.translate(
      'ClientSimpleOverview.Messages.ClientSuccessfullyCreated'
    ) as string,
    somethingWentWrong: dictionaryService.translate(
      `ClientSimpleOverview.Messages.SomethingWentWrong`
    ) as string,
    fieldRequired: dictionaryService.translate(
      `ClientSimpleOverview.Messages.FieldRequired`
    ) as string,
    emailExistsManagedBy: dictionaryService.translate(
      `ClientSimpleOverview.Messages.EmailExistsManagedBy`
    ) as string,
    invalidEmail: dictionaryService.translate(`Messages.Person.InvalidEmail`) as string
  }
}
