







































import store from '@/store/store2'
import Vue from 'vue'
import mazClientMyProfilePageDetails from '@/components/custom/mazClientMyProfilePageDetails/index.vue'
import mazClientMyProfilePageOrganizations from '@/components/custom/mazClientMyProfilePageOrganizations/index.vue'
import mazSpinner from '@/components/general/mazSpinner/index.vue'
import mazTabNavigation from '@/components/general/mazTabNavigation/index.vue'
import mazTitle from '@/components/general/mazTitle/index.vue'

export default Vue.extend({
  name: 'clientMyProfilePage',

  components: {
    mazClientMyProfilePageDetails,
    mazClientMyProfilePageOrganizations,
    mazSpinner,
    mazTabNavigation,
    mazTitle
  },

  computed: {
    /**
     * STORE GETTERS
     */
    loader(): any {
      return store.getters.clientMyProfilePage.LOADER
    },
    tabNavigation(): any {
      return store.getters.clientMyProfilePage.TAB_NAVIGATION
    },
    clientTitle(): any {
      return store.getters.clientMyProfilePage.CLIENT_TITLE
    },
    clientNumber(): any {
      return store.getters.clientMyProfilePage.CLIENT_NUMBER
    },
    labels(): object {
      return store.getters.clientMyProfilePage.LABELS
    }
  },

  methods: {
    getMyProfileClientDetails(): void {
      store.dispatch.clientMyProfilePage.GET_MY_PROFILE_CLIENT_DETAILS()
    },
    updateClientProfilePageTabIndex(index: number) {
      store.dispatch.clientMyProfilePage.UPDATE_CLIENT_PROFILE_PAGE_TAB_INDEX(index)
    }
  },

  created() {
    this.getMyProfileClientDetails()
  }
})
