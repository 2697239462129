export default {
  getSupplyRequest: 'getsupplyrequest?srid={supplyRequestId}',
  getSupplyRequestCategories: 'getcategories?srid={supplyRequestId}',
  getSupplyRequestCategoryById:
    'getcategorycontent?srid={supplyRequestId}&ctid={categoryId}',
  postSupplyRequestComment: 'addcommenttosegment',
  getSegmentUpdatedComments: 'getsegmentcommentsinfo',
  postSegmentAsNotApplicable: 'marksegmentisnotapplicable',
  getAuditLogs: 'getauditlog?srid={supplyRequestId}',
  getCustomers: 'getcustomers?searchterm={searchTerm}',
  postCopySupplyRequest: 'copysupplyrequest?srid={supplyRequestId}&crmid={customerId}',
  postCloneSupplyRequest: 'clonesupplyrequest?srid={supplyRequestId}',
  postSubmitSupplyRequest: 'submitsupplyrequest?srid={supplyRequestId}',
  postSubmitSupplyRequestOnClientBehalf: 'submitsupplyrequestonclientbehalf',
  postApproveSupplyRequest: 'approvesupplyrequest?srid={supplyRequestId}',
  postRejectSupplyRequest: 'rejectsupplyrequest',
  postSubmitSignal: 'sendcustomreminder',
  getDelegationsOverview: 'getdelegationsoverview?srid={supplyRequestId}',
  getUpdatedSegmentsOverview: 'getsegmentsoverview?srid={supplyRequestId}',
  postUploadFile: 'uploadfile',
  getFileOverviewBySegmentId:
    'getfileoverviewbysegmentid?supplyrequestid={supplyRequestId}&categoryid={categoryId}&groupsegmentid={groupSegmentId}&segmentid={segmentId}',
  getDelegationOverviewBySegmentId:
    'getdelegationsforsegment?srid={supplyRequestId}&ctid={categoryId}&gsid={groupSegmentId}&sgid={segmentId}',
  getAttachmentOverviewBySegmentId:
    'getattachmentoverviewbysegmentid?supplyrequestid={supplyRequestId}&categoryid={categoryId}&groupsegmentid={groupSegmentId}&segmentid={segmentId}',
  deleteFile: 'deletefile?srfid={supplyRequestFileId}',
  postMarkSegmentIsComplete: 'marksegmentiscomplete',
  addCategory: 'addcategory',
  addSubCategory: 'addgroupsegment',
  addSegment: 'addsegment',
  addDelegation: 'adddelegation',
  deleteDelegation: 'deletedelegation?dlid={supplyRequestDelegationId}',
  downloadZipForSupplyRequest: 'downloadzipforsupplyrequest?srid={supplyRequestId}',
  deleteSupplyRequest: 'deletesupplyrequest?srid={supplyRequestId}',
  manualUploadSupplyRequest: 'synctodms?srid={supplyRequestId}' // Check context of endpoint as the name may not match
}
