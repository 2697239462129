<template>
  <div>
    <p>
      <span>{{audit_type}}</span>
      <span>{{audit_finacial_year}}</span>
    </p>
    <p class="mt-0">
      <span>{{audit_due_date_text}}</span>
      <span>{{audit_due_date}}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'maz-audit-info',
  props: {
    audit_type: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    audit_finacial_year: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    audit_due_date_text: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    audit_due_date: {
      required: false,
      type: String,
      default() {
        return ''
      }
    }
  }
}
</script>
