



























































































































































import Vue from 'vue'
import store from '@/store/store2'
import mazButton from '@/components/general/mazButton/index.vue'
import mazMultiDropdown from '@/components/general/mazMultiDropdown/index.vue'
import mazSpinner from '@/components/general/mazSpinner/index.vue'
import mazSwitch from '@/components/general/mazSwitch/index.vue'
import mazValidationMessage from '@/components/general/mazValidationMessage/index.vue'

import { isString, isEmpty, pickBy, startsWith, trim } from 'lodash'

export default Vue.extend({
  name: 'mazEmployeePermissions',
  components: {
    mazButton,
    mazMultiDropdown,
    mazSpinner,
    mazSwitch,
    mazValidationMessage
  },

  data() {
    return {
      errors: {}
    }
  },
  computed: {
    id(): string {
      return store.getters.employeeMaintenance.employeeMaintenancePermissions.ID
    },
    isEditMode(): boolean {
      return store.getters.employeeMaintenance.employeeMaintenancePermissions.IS_EDIT_MODE
    },
    labels(): any {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.LABELS
    },
    permissions(): MazInterfaces.EmployeeMaintenance.EmployeePermission {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.PERMISSIONS
    },
    loader(): boolean {
      return store.getters.employeeMaintenance.employeeMaintenancePermissions.LOADER
    },
    employeePermissions() {
      return store.getters.employeeMaintenance.employeeMaintenancePermissions
        .EMPLOYEE_PERMISSIONS_DETAILS
    },
    editedEmployeePermissions() {
      return store.getters.employeeMaintenance.employeeMaintenancePermissions
        .EDITED_EMPLOYEE_PERMISSIONS_DETAILS
    },
    countryList(): any {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.COUNTRIES_LIST
    },
    activeCountries(): any {
      return store.getters.employeeMaintenance.employeeMaintenancePermissions
        .ACTIVE_COUNTRIES
    },
    activeCountriesNames(): any {
      return store.getters.employeeMaintenance.employeeMaintenancePermissions
        .ACTIVE_COUNTRIES_NAMES
    },
    messages(): any {
      return store.getters.employeeMaintenance.employeeMaintenancePermissions.MESSAGES
    }
  },
  methods: {
    resetEmployeePermissionCountryList(): void {
      store.dispatch.employeeMaintenance.employeeMaintenancePermissions.RESET_EMPLOYEE_PERMISSIONS_COUNTRY_LIST()
    },
    resetEmployeePermissionsDetails(): void {
      store.dispatch.employeeMaintenance.employeeMaintenancePermissions.RESET_EMPLOYEE_PERMISSION_DETAILS()
    },
    getEmployeePermissionsDetails(id: string): void {
      store.dispatch.employeeMaintenance.employeeMaintenancePermissions.GET_EMPLOYEE_PERMISSIONS_DETAILS(
        id
      )
    },
    getSelectedCountries(countries: any) {
      if (countries && countries.length) {
        return countries
          .slice()
          .sort((a: number, b: number) => (a === b ? 0 : a < b ? -1 : 1)) //compare function sorts alphabetically ascending.
          .reduce((acc: string, curr: string) => acc + ', ' + curr)
      } else return ''
    },
    getAvailableCountries(countries: any) {
      return countries
        .slice()
        .sort((a: { name: number }, b: { name: number }) => (a.name < b.name ? -1 : 1))
    },
    updateEmployeePermissionsEditMode(body: boolean): void {
      store.dispatch.employeeMaintenance.employeeMaintenancePermissions.UPDATE_EMPLOYEE_PERMISSIONS_EDIT_MODE(
        body
      )
    },
    save(details: MazInterfaces.EmployeeMaintenancePermissions.EmployeePermissions) {
      const body = {
        Id: this.id,
        ...details
      }
      this.fieldValidator(details, ['countries'])
      this.updateEmployeePermissionsDetails(body)
    },
    fieldValidator(objectToValidate: any, fields: string[]) {
      fields.forEach((field: string) => {
        //if string, check if it has white spaces
        if (
          isString(objectToValidate[field]) &&
          trim(objectToValidate[field]).length === 0
        ) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired }
          }
          return
        }

        if (isEmpty(objectToValidate[field])) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired }
          }
        } else {
          const filteredErrors = pickBy(this.errors, function (value, key) {
            return !startsWith(key, field)
          })
          this.errors = filteredErrors
        }
      })
    },
    resetError(field: string): void {
      this.$delete(this.errors, field)
      store.dispatch.employeeMaintenance.employeeMaintenancePermissions.RESET_VALIDATION_ERROR(
        field
      )
    },
    onInputCountries(countries: any): void {
      store.dispatch.employeeMaintenance.employeeMaintenancePermissions.SET_COUNTRIES(
        countries
      )
    },
    updateEmployeePermissionsDetails(body: any): void {
      store.dispatch.employeeMaintenance.employeeMaintenancePermissions.UPDATE_EMPLOYEE_PERMISSIONS(
        body
      )
    }
  },

  watch: {
    'editedEmployeePermissions.isLocalAdmin': function (value: boolean): void {
      if (value === false) {
        this.errors = {} // reset countries validation errors
        this.resetEmployeePermissionCountryList()
      }
    },
    isEditMode: function (value: boolean): void {
      if (value === false) {
        this.errors = {}
        this.resetEmployeePermissionsDetails()
      }
    }
  },

  created() {
    this.getEmployeePermissionsDetails(this.id)
  }
})
