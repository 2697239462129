import dictionaryService from '@/services/modules/dictionaryService'
export const localization = {
  labels: {
    tabNavigation: {
      overview: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Overview'
      ) as string,
      details: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Details'
      ) as string,
      services: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Services'
      ) as string,
      organizations: dictionaryService.translate(
        'EmployeeContactOverview.Labels.Organizations'
      ) as string,
      employees: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Employees'
      ) as string
    },
    contactOrganizationsPermissionsTable: {
      legalName: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.LegalName'
      ) as string,
      organizationNumber: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.OracleOrganizationId'
      ) as string
    },
    employeesConnectedToContact: {
      name: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Name'
      ) as string,
      number: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Number'
      ) as string,
      email: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Email'
      ) as string
    },
    clientServicesTable: {
      service: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Services'
      ) as string,
      actions: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.RelationManager'
      ) as string,
      status: dictionaryService.translate(
        'EmployeeOrganizationOverview.Labels.Status'
      ) as string
    },
    organizationsForThisClient: dictionaryService.translate(
      'EmployeeContactOverview.Labels.OrganizationsForThisClient'
    ) as string,
    organizationNumber: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.OracleOrganizationId'
    ) as string,
    actions: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Actions'
    ) as string,
    completedActions: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.CompletedActions'
    ) as string,
    showMore: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.ShowMore'
    ) as string,
    startAction: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.StartActions'
    ) as string,
    startSupplyRequest: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.StartSupplyRequest'
    ) as string,
    uploadAnnualReport: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.UploadAnnualReport'
    ) as string,
    requestDocumentation: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.RequestDocumentation'
    ) as string,
    editContactPersons: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.EditContactPersons'
    ) as string,
    oraclePersonId: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.OraclePersonId'
    ) as string,
    nationalId: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.NationalId'
    ) as string,
    accountNumber: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Accountnumber'
    ) as string,
    role: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Role'
    ) as string,
    relationManager: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.RelationManager'
    ) as string,
    cocNo: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.CoCNo'
    ) as string,
    wageTaxNo: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.WageTaxNo'
    ) as string,
    vatNo: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.VatNo'
    ) as string,
    corporateIncomeTaxNo: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.CorporateIncomeTaxNo'
    ) as string,
    icpNo: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.IcpNo'
    ) as string,
    dunsNo: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.DunsNo'
    ) as string,
    referenceId: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.ReferenceId'
    ) as string,
    legalName: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.LegalName'
    ) as string,
    taxNumber: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.TaxNumber'
    ) as string,
    countryLabel: dictionaryService.translate('Labels.Common.Country') as string,
    clientDetails: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.ClientDetails'
    ) as string,
    unknown: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Unknown'
    ) as string,
    isSpecial: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.IsSpecial'
    ) as string,
    isVip: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.IsVIP'
    ) as string,
    contacts: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Contacts'
    ) as string,
    servicesTitle: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.ServicesTitle'
    ) as string,
    active: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.IsServiceActive'
    ) as string,
    inactive: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.IsServiceInactive'
    ) as string,
    service: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Services'
    ) as string,
    cancel: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Cancel'
    ) as string,
    done: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.Done'
    ) as string,
    personNumber: dictionaryService.translate('Labels.Person.Number') as string,
    searchForUsers: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.SearchForUsers'
    ) as string,
    searchForOrganizations: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.SearchForOrganizations'
    ) as string,
    searchForEmployees: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.SearchForEmployees'
    ) as string,
    selectedUsers: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.SelectedUsers'
    ) as string,
    selectedEmployees: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.SelectedEmployees'
    ) as string,
    addOrganization: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.AddOrganization'
    ) as string,
    addOrganizationViaUrl: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.AddOrganizationViaUrl'
    ) as string,
    addEmployees: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.AddEmployees'
    ) as string,
    selectCountry: dictionaryService.translate(
      'ClientSimpleOverview.Labels.SelectCountry'
    ) as string,
    save: dictionaryService.translate('Labels.Common.Save'),
    confirm: dictionaryService.translate('Labels.Common.Confirm'),
    searchByName: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.SearchByName'
    ),
    employeesTitle: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.EmployeesTitle'
    ) as string,
    userName: dictionaryService.translate(
      'EmployeeContactOverview.Labels.UserName'
    ) as string,
    firstName: dictionaryService.translate(
      'EmployeeContactOverview.Labels.FirstName'
    ) as string,
    lastName: dictionaryService.translate(
      'EmployeeContactOverview.Labels.LastName'
    ) as string,
    gender: dictionaryService.translate(
      'EmployeeContactOverview.Labels.Gender'
    ) as string,
    title: dictionaryService.translate('EmployeeContactOverview.Labels.Title') as string,
    initials: dictionaryService.translate(
      'EmployeeContactOverview.Labels.Initials'
    ) as string,
    prefix: dictionaryService.translate(
      'EmployeeContactOverview.Labels.Prefix'
    ) as string,
    suffix: dictionaryService.translate(
      'EmployeeContactOverview.Labels.Suffix'
    ) as string,
    salutation: dictionaryService.translate(
      'EmployeeContactOverview.Labels.Salutation'
    ) as string,
    academicTitle: dictionaryService.translate(
      'EmployeeContactOverview.Labels.AcademicTitle'
    ) as string,
    language: dictionaryService.translate(
      'EmployeeContactOverview.Labels.Language'
    ) as string,
    oktaId: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.OktaId'
    ) as string,
    oktaEmail: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.OktaEmail'
    ) as string,
    searchRelationManager: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.SearchRelationManager'
    ) as string,
    startDate: dictionaryService.translate(
      'EmployeeContactOverview.Labels.StartDate'
    ) as string,
    endDate: dictionaryService.translate(
      'EmployeeContactOverview.Labels.EndDate'
    ) as string,
    isDeleted: dictionaryService.translate(
      'EmployeeContactOverview.Labels.IsDeleted'
    ) as string,
    isActive: dictionaryService.translate(
      'EmployeeOrganizationOverview.Labels.IsActive'
    ) as string,
    isYes: dictionaryService.translate('Labels.Common.True') as string,
    isNo: dictionaryService.translate('Labels.Common.False') as string,
    resetPassword: dictionaryService.translate(
      'EmployeeContactOverview.Labels.ResetPassword'
    ) as string,
    resetMFA: dictionaryService.translate(
      'EmployeeContactOverview.Labels.ResetMFA'
    ) as string,
    reactivateAccount: dictionaryService.translate(
      'EmployeeContactOverview.Labels.ReactivateAccount'
    ) as string
  },
  messages: {
    accessibleForAllEmployees: dictionaryService.translate(
      'EmployeeOrganizationOverview.Messages.AccessibleForAllEmployees'
    ) as string,
    noActionsFound: dictionaryService.translate(
      'EmployeeOrganizationOverview.Messages.NoActionsFound'
    ) as string,
    noCompletedActionsFound: dictionaryService.translate(
      'EmployeeOrganizationOverview.Messages.NoCompletedActionsFound'
    ) as string,
    noServicesFound: dictionaryService.translate(
      'EmployeeOrganizationOverview.Messages.NoServicesFound'
    ) as string,
    noOrganizationsFound: dictionaryService.translate(
      'EmployeeContactOverview.Messages.NoOrganizationsFound'
    ) as string,
    noEmployeesConnected: dictionaryService.translate(
      'EmployeeContactOverview.Messages.NoEmployeesConnectedToContact'
    ) as string,
    changesSuccessfullySaved: dictionaryService.translate(
      `EmployeeOrganizationOverview.Messages.ChangesSuccessfullySaved`
    ) as string,
    somethingWentWrong: dictionaryService.translate(
      `EmployeeOrganizationOverview.Messages.SomethingWentWrong`
    ) as string,
    fieldRequired: dictionaryService.translate(
      `EmployeeOrganizationOverview.Messages.FieldRequired`
    ) as string,
    passwordResetSuccess: dictionaryService.translate(
      `EmployeeContactOverview.Messages.PasswordResetSuccess`
    ) as string,
    mfaResetSuccess: dictionaryService.translate(
      `EmployeeContactOverview.Messages.MFAResetSuccess`
    ) as string,
    reactivationSuccess: dictionaryService.translate(
      `EmployeeContactOverview.Messages.ReactivationSuccess`
    ) as string,
    disconnectOrganizationFromContact: dictionaryService.translate(
      `EmployeeOrganizationOverview.Messages.DisconnectOrganizationFromContact`
    ) as string,
    disconnectEmployeeFromContact: dictionaryService.translate(
      `EmployeeOrganizationOverview.Messages.DisconnectEmployeeFromContact`
    ) as string,
      updatePrivacySettings: dictionaryService.translate(
          `EmployeeOrganizationOverview.Messages.UpdatePrivacySettingsContact`
      ) as string  }
}
