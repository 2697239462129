

























































































































































import mazButton from '@/components/general/mazButton/index.vue'
import mazModal from '@/components/general/mazModal/index.vue'
import mazList from '@/components/general/mazList/index.vue'
import mazTextInput from '@/components/general/mazTextInput/index.vue'
import mazSpinner from '@/components/general/mazSpinner/index.vue'

import store from '@/store/store2'

import Vue from 'vue'
export default Vue.extend({
  name: 'mazClientMaintenanceOrganizationEmployees',

  components: {
    mazButton,
    mazModal,
    mazList,
    mazTextInput,
    mazSpinner
  },

  data() {
    return {
      showEmployeesModal: false,
      showDisconnectConfirmationModal: false,
      employeeId: ''
    }
  },

  computed: {
    /**
     * Store getters
     */
    id(): string {
      return store.getters.clientMaintenance.ID
    },
    loader(): any {
      return store.getters.clientMaintenance.organization.LOADER
    },
    clientDetails(): any {
      return store.getters.clientMaintenance.organization.CLIENT_DETAILS
    },
    permissions(): any {
      return store.getters.clientMaintenance.organization.PERMISSIONS
    },
    organizationContactPermissionsTable(): any {
      return store.getters.clientMaintenance.organization
        .ORGANIZATION_CONTACT_PERMISSION_TABLE
    },
    employeesModal(): any {
      return store.getters.clientMaintenance.organization.EMPLOYEES_MODAL
    },
    labels(): any {
      return store.getters.clientMaintenance.organization.LABELS
    },
    messages(): any {
      return store.getters.clientMaintenance.organization.MESSAGES
    },
    employeesConnectedToOrganization(): any {
      return store.getters.clientMaintenance.organization
        .EMPLOYEES_CONNECTED_TO_ORGANIZATION
    }
  },

  methods: {
    /**
     * VUEX Actions
     */
    getClientMaintenanceEmployees(body: any): void {
      store.dispatch.clientMaintenance.organization.GET_CLIENT_MAINTENANCE_EMPLOYEES(body)
    },
    updateClientMaintenanceEmployeesList(body: any): void {
      store.dispatch.clientMaintenance.organization.UPDATE_CLIENT_MAINTENANCE_EMPLOYEES_LIST(
        body
      )
    },
    updateClientMaintenanceAvailableConnectableEmployees(body: string): void {
      store.dispatch.clientMaintenance.organization.UPDATE_CLIENT_MAINTENANCE_AVAILABLE_CONNECTABLE_EMPLOYEES(
        body
      )
    },
    updateClientMaintenanceConnectedEmployees(body: any): void {
      store.dispatch.clientMaintenance.organization.UPDATE_CLIENT_MAINTENANCE_CONNECTED_EMPLOYEES(
        body
      )
    },
    disconnectClientMaintenanceEmployeeFromOrganization(body: any): void {
      store.dispatch.clientMaintenance.organization.DISCONNECT_CLIENT_MAINTENANCE_EMPLOYEE_FROM_ORGANIZATION(
        body
      )
    },
    getClientMaintenanceConnectableEmployees(): void {
      store.dispatch.clientMaintenance.organization.GET_CLIENT_MAINTENANCE_CONNECTABLE_EMPLOYEES()
    },
    /**
     * Component methods
     */
    getEmployees() {
      const body = {
        clientId: this.id
      }

      this.getClientMaintenanceEmployees(body)
    },
    disconnectEmployee(employee: any) {
      this.showDisconnectConfirmationModal = true
      this.employeeId = employee.Id
    },
    cancelDisconnectEmployee(value: boolean) {
      this.showDisconnectConfirmationModal = value
    },
    confirmDisconnectEmployee() {
      this.showDisconnectConfirmationModal = false
      if (this.employeeId) this.deleteEmployee(this.employeeId)
    },
    deleteEmployee(employeeId: string) {
      //TODO: Refactor object keys using the PascalCase helper service
      const body = {
        OrganizationId: this.id,
        EmployeeId: employeeId
      }
      this.disconnectClientMaintenanceEmployeeFromOrganization(body)
    },
    connectSelectedEmployees(employees: any) {
      const tempIds = employees.map((x: any) => x.Id)
      const body = {
        OrganizationId: this.id,
        EmployeeIds: tempIds.length > 0 ? tempIds : null
      }
      if (body.EmployeeIds !== null) {
        this.updateClientMaintenanceConnectedEmployees(body)
        this.showEmployeesModal = false
      }
    }
  },

  created() {
    this.getEmployees()
  },

  watch: {
    showEmployeesModal: function (value: boolean): void {
      if (value) {
        this.getClientMaintenanceConnectableEmployees()
      }
    }
  }
})
