import { defineModule, localActionContext, localGetterContext } from 'direct-vuex'
import { actions } from './actions'
import { contactServices } from './contactServices'
import { getters } from './getters'
import { mutations } from './mutations'
import state from './state'

export const contact = defineModule({
  namespaced: true as true,
  modules: { contactServices },
  state,
  mutations,
  getters,
  actions
})

//direct-vuex helper of current module action context
//https://github.com/paroi-tech/direct-vuex#alternatively-use-localgettercontext-and-localactioncontext
export const currentActionContext = (context: any) => localActionContext(context, contact)
export const currentGetterContext = (args: [any, any, any, any]) =>
  localGetterContext(args, contact)
