import dictionaryService from '@/services/modules/dictionaryService'

export const localization = {
  labels: {
    clientDetails: dictionaryService.translate('MyProfile.Labels.Title') as string,
    nationalId: dictionaryService.translate('Labels.Person.NationalId') as string,
    initials: dictionaryService.translate('Labels.Person.Initials') as string,
    prefix: dictionaryService.translate('Labels.Person.Prefix') as string,
    suffix: dictionaryService.translate('Labels.Person.Suffix') as string,
    title: dictionaryService.translate('Labels.Person.Title') as string,
    academicTitle: dictionaryService.translate('Labels.Person.AcademicTitle') as string,
    salutation: dictionaryService.translate('Labels.Person.Salutation') as string,
    firstName: dictionaryService.translate('Labels.Person.FirstName') as string,
    lastName: dictionaryService.translate('Labels.Person.LastName') as string,
    gender: dictionaryService.translate('Labels.Person.Gender') as string,
    email: dictionaryService.translate('Labels.Person.Email') as string,
    password: dictionaryService.translate('Labels.Person.Password') as string,
    accountNumber: dictionaryService.translate('Labels.Person.AccountNumber') as string,
    VATno: dictionaryService.translate('Labels.Person.ReferenceType.VATNo') as string,
    fullName: dictionaryService.translate('Labels.Person.FullName') as string,
    primaryContactEmployeeFullName: dictionaryService.translate(
      'Labels.Person.RelationManager'
    ) as string,
    unknown: dictionaryService.translate('MyProfile.Labels.Unknown') as string,
    changePasswordText: dictionaryService.translate(
      'MyProfile.Labels.ChangeOKTAPasswordText'
    ) as string
  }
}
