<template>
  <div class="c-attachments">
    <div class="c-attachments__list">
      <div
        :key="attachment.id"
        class="c-attachments__item d-flex flex-wrap flex-md-nowrap align-items-center"
        v-for="(attachment, index) in segment.attachments"
      >
        <span
          :title="attachment.fileName"
          class="c-attachments__cell c-attachments__cell--name"
        >
          <a
            :href="attachment.downloadUrl"
            v-if="attachment.mayDownload"
          >{{ attachment.fileName }}</a>
          <span v-else>{{ attachment.fileName }}</span>
        </span>
        <span
          class="c-attachments__cell c-attachments__cell--email"
        >{{ attachment.ownerEmail }}</span>
        <span
          class="c-attachments__cell c-attachments__cell--time"
        >{{ attachment.createdDate }}</span>
        <span
          class="c-attachments__cell c-attachments__cell--delete"
          v-show="attachment.mayDelete"
        >
          <maz-spinner
            class="c-spinner--small float-right"
            v-if="showDeleteSpinner && indexClicked === index"
          />
          <span @click="deleteAttachment(index, attachment.id)">
            <span
              class="c-attachments__icon"
              v-if="!showDeleteSpinner"
            />
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import mazSpinner from '../mazSpinner'

const _targetStore = 'supplyRequest2EditStore/'

export default {
  name: 'maz-attachment-list',
  components: {
    'maz-spinner': mazSpinner
  },
  props: {
    segment: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      showDeleteSpinner: false
    }
  },
  computed: {
    ...mapGetters({
      supplyRequestFileUploader: _targetStore + 'SUPPLY_REQUEST_FILE_UPLOADER'
    })
  },
  methods: {
    deleteAttachment(index, attachmentId) {
      this.indexClicked = index

      const body = {
        attachmentId: attachmentId,
        supplyRequestId: this.segment.parentSupplyRequestId.original,
        categoryId: this.segment.parentCategoryId.original
      }

      this.showDeleteSpinner = true

      this.$store.dispatch(_targetStore + 'DELETE_FILE', body)
    }
  },
  watch: {
    supplyRequestFileUploader: {
      handler() {
        if (this.supplyRequestFileUploader.fileDeleted === true) {
          this.showDeleteSpinner = false

          const body = {
            supplyRequestId: this.segment.parentSupplyRequestId.original,
            categoryId: this.segment.parentCategoryId.original,
            subCategoryId: this.segment.parentSubCategoryId.original,
            segmentId: this.segment.id.original
          }

          this.$store.dispatch(_targetStore + 'GET_UPDATED_SEGMENT_FILES', body)
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-attachments {
  .c-attachments__item {
    .c-attachments__cell {
      &.c-attachments__cell--name {
        margin-right: 10px;
        font-size: 16px;

        span {
          font-size: 16px;
        }
      }

      &.c-attachments__cell--delete {
        .c-attachments__icon {
          position: relative;
          top: 3px;
          display: inline-block;
          width: 13px;
          height: 16px;
          background-image: url('~@/assets/images/icon_delete_small.svg');
          background-repeat: no-repeat;
          cursor: pointer;

          &:hover {
            background-image: url('~@/assets/images/icon_delete_small(hover).svg');
          }
        }

        .c-spinner {
          margin-top: 6px;
        }
      }
    }
  }

  &.c-attachments--plain {
    .c-attachments__cell--email,
    .c-attachments__cell--time {
      display: none;
    }
  }

  &.c-attachments--view-state {
    .c-attachments__cell--delete {
      display: none;
    }
  }
}
</style>
