<template>
  <div class="c-category qa-category">
    <div class="d-flex align-items-center">
      <div class="flex-fill">
        <h3 class="float-left">{{indexPosition}} {{category.title}}</h3>
      </div>
    </div>

    <div class="o-separator o-separator--small" />

    <maz-wizard-sr-sub-category
      :category="category"
      :id="subCategory.id"
      :index="index"
      :indexPosition="(index + 1).toString()"
      :key="subCategory.id.clean"
      :parentCategoryIndexPosition="indexPosition"
      :subCategory="subCategory"
      v-for="( subCategory, index ) in category.groupSegments"
    />
  </div>
</template>

<script>
import mazWizardSRSubCategory from '../mazWizardSRSubCategory'

export default {
  name: 'maz-wizard-sr-category',
  components: {
    'maz-wizard-sr-sub-category': mazWizardSRSubCategory
  },
  props: {
    indexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    index: {
      required: false,
      type: Number,
      default() {
        return ''
      }
    },
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/__main.scss';

.c-category {
  @include category;
}
</style>
