<template>
  <div :id="delegateEditor.id" class="form-group c-delegate-editor collapse">
    <div class="o-separator o-separator--small" />

    <div class="row">
      <div class="col-sm-6">
        <label class="c-forms__label">{{ labels.supplyRequestEmailLabel }}</label>
        <input class="form-control c-forms__input" v-model="emailInput" />
      </div>
      <div class="col-sm-6">
        <label class="c-forms__label">{{ labels.supplyRequestDelegateDueDate }}</label>
        <maz-date-picker
          :currentDate="dueDate"
          :placeholder="dueDate"
          :value="dueDate"
          @input="onDateSelected"
          @onDateSelected="onDateSelected"
          required
        />
      </div>
    </div>

    <div class="o-separator o-separator--small" />

    <label class="c-forms__label">{{ labels.supplyRequestPersonalNotesLabel }}</label>

    <textarea class="form-control c-textarea--height" v-model="delegateReason" />

    <div class="c-delegate-editor__buttons text-right">
      <div class="o-separator o-separator--small" />

      <div class="d-flex float-right u-w-xs-100 flex-wrap flex-sm-nowrap">
        <maz-button
          :label="labels.cancel"
          @click.native="onCancel"
          class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
        />

        <maz-button
          :disabled="isButtonDisabled"
          :label="labels.confirm"
          @click.native="onSubmit"
          class="c-btn--blue order-1 order-sm-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import moment from 'moment'
import mazButton from '@/components/general/mazButton'
import mazDatePicker from '@/components/general/mazDatePicker'

const _targetStore = 'supplyRequest2DetailStore/'

export default {
  name: 'maz-delegate-editor',
  components: {
    'maz-button': mazButton,
    'maz-date-picker': mazDatePicker
  },
  props: {
    delegateEditor: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    segment: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    subCategory: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      emailInput: '',
      delegateReason: '',
      dueDate: moment()
        .add(30, 'days')
        .format('DD-MM-YYYY')
    }
  },
  computed: {
    ...mapGetters({
      id: _targetStore + 'ID',
      labels: _targetStore + 'LABELS'
    }),
    isButtonDisabled() {
      let result = true
      const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

      if (this.emailInput == null || this.emailInput == '') {
        result = true
      } else if (!emailReg.test(this.emailInput)) {
        result = true
      } else {
        result = false
      }

      return result
    }
  },
  methods: {
    onCancel(event) {
      this.clearContent()
      this.closeEditor()

      this.$emit('onCancelDelegate', event)
    },
    onSubmit(event) {
      const parentSegment = this.$parent.segment

      const body = {
        supplyRequestId: parentSegment.parentSupplyRequestId.original,
        categoryId: parentSegment.parentCategoryId.original,
        groupSegmentId: parentSegment.parentSubCategoryId.original,
        segmentId: parentSegment.id.original,
        email: this.emailInput,
        reason: this.delegateReason,
        dueDate: this.dueDate
      }

      this.clearContent()
      this.closeEditor()
      this.dueDate = moment()
        .add(30, 'days')
        .format('DD-MM-YYYY')

      this.$store.dispatch(_targetStore + 'POST_DELEGEE', body)
      this.$emit('onSubmitDelegate', event)
    },
    clearContent() {
      this.emailInput = ''
      this.delegateReason = ''
    },
    closeEditor() {
      $('.c-delegate-editor').removeClass('show')
    },
    onDateSelected(event) {
      this.dueDate = event
    }
  }
}
</script>
