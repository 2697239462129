import Axios from 'axios'

function processResponse(res, callback) {
  let result = null

  if (res.status === 200) {
    result = res.data
  }

  if (result !== null && typeof callback == 'function') {
    callback(result)
  }
}

const service = {
  get(endpoint, callback) {
    Axios.get(endpoint)
      .then(res => processResponse(res, callback))
      .catch(err => {
        throw err
      })
  },
  post(endpoint, data, callback, error) {
    Axios.post(endpoint, data)
      .then(res => processResponse(res, callback))
      .catch(err => {
        error(err)
        throw err
      })
  },
  put(endpoint, data, callback) {
    Axios.put(endpoint, data)
      .then(res => processResponse(res, callback))
      .catch(err => {
        throw err
      })
  },
  delete(endpoint, callback) {
    Axios.delete(endpoint)
      .then(res => processResponse(res, callback))
      .catch(err => {
        throw err
      })
  }
}

export default service
