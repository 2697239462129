export const mutations = {
  UPDATE_CLIENT_MAINTENANCE_IS_ORGANIZATION_MUTATION(state: any, payload: boolean) {
    state.isOrganization = payload
  },
  UPDATE_CLIENT_MAINTENANCE_IS_CONTACT_MUTATION(state: any, payload: boolean) {
    state.isContact = payload
  },
  SET_CLIENT_MAINTENANCE_IS_NOT_FOUND_MUTATION(state: any) {
    state.isNotFound = true
  }
}
