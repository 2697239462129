export default {
  getEmployeeMaintenanceDetails: 'getemployee?employeeId={employeeId}',
  getEmployeeConnectedClients: 'GetConnectedClients?employeeId={employeeId}',
  getOverview: 'GetOverview',
  searchEmployees: 'SearchEmployees',
  exportOverview: 'ExportEmployees?ShowInactiveEmployees={showInactiveEmployees}',
  getEmployeeReferenceData: 'GetReferenceData',
  updateEmployee: 'UpdateEmployee',
  getEmployeePermissions: 'GetEmployeePermissions?employeeId={employeeId}',
  updateEmployeePermissions: 'UpdateEmployeePermissions',
  addEmployee: 'AddEmployee'
}
