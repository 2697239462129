






















































































































import mazButton from "@/components/general/mazButton/index.vue";
import mazSpinner from "@/components/general/mazSpinner/index.vue";
import mazDropDown from "@/components/general/mazDropDown/index.vue";
import mazSwitch from "@/components/general/mazSwitch/index.vue";
import store from "@/store/store2";
import toastr from "toastr";
import dictionaryService from "@/services/modules/dictionaryService";
import { isString, isEmpty, pickBy, startsWith, trim } from "lodash";

import Vue from "vue";
export default Vue.extend({
  name: "mazClientMaintenanceContactServices",

  components: {
    mazButton,
    mazSpinner,
    mazSwitch,
    mazDropDown,
  },

  data() {
    return {
      errors: {} as any,
    };
  },

  computed: {
    shouldRedirect(): boolean {
      return this.connectUrl !== undefined && this.connectUrl !== '' && this.connectUrl !== null;
    },
    connectUrl(): string {
      return store.getters.clientMaintenance.contact.CONNECT_URL;
    },
    id(): string {
      return store.getters.clientMaintenance.ID;
    },
    loader(): any {
      return store.getters.clientMaintenance.contact.contactServices.LOADER;
    },
    labels(): any {
      return store.getters.clientMaintenance.contact.LABELS;
    },
    messages(): any {
      return store.getters.clientMaintenance.contact.MESSAGES;
    },
    clientServicesTable(): any {
      return store.getters.clientMaintenance.contact.CLIENT_SERVICES_TABLE;
    },
    editedClientServices(): any {
      return store.getters.clientMaintenance.contact.contactServices
        .EDITED_SERVICE_DETAILS;
    },
    permissions(): any {
      return store.getters.clientMaintenance.contact.PERMISSIONS;
    },
    isEditMode(): boolean {
      return store.getters.clientMaintenance.contact.contactServices.IS_EDIT_MODE;
    },
    relationManagersList(): readonly MazInterfaces.ClientMaintenance.RelationManager[] {
      return store.getters.clientMaintenance.contact.RELATION_MANAGERS_LIST;
    },
  },

  methods: {
    redirectToPowerApp() {
      window.open(this.connectUrl, '_blank')?.focus()
    },
    /**
     * VUEX
     */
    getClientMaintenanceClientServices(body: any): void {
      store.dispatch.clientMaintenance.contact.GET_CLIENT_MAINTENANCE_CLIENT_SERVICES(
        body
      );
    },
    updateClientMaintenanceOrganizationService(body: any): void {
      store.dispatch.clientMaintenance.contact.UPDATE_CLIENT_MAINTENANCE_CONTACT_SERVICE(
        body
      );
    },
    resetErrors(): void {
      this.errors = {}; // frontend errors
    },
    /**
     * component methods
     */
    getReferenceData(): void {
      store.dispatch.clientMaintenance.contact.GET_REFERENCE_DATA();
    },
    getClientServices() {
      const body = {
        clientId: this.id,
      };

      this.getClientMaintenanceClientServices(body);
    },
    updateContactServicesEditMode(body: boolean): void {
      store.dispatch.clientMaintenance.contact.contactServices.UPDATE_CONTACT_SERVICES_EDIT_MODE(
        body
      );
      //reset edit form
      this.resetErrors();
      if (body === false) {
        this.getClientServices();
      }
    },
    save(editedClientServices: any) {
      this.fieldValidator(editedClientServices, ["Employees"]);

      const list: {
        ServiceId: string;
        EmployeeId: string;
        IsActive: boolean;
      }[] = [];

      editedClientServices.forEach(
        (service: {
          Id: string;
          EmployeeId: string;
          IsActive: boolean;
          Employees: string;
        }) => {
          list.push({
            ServiceId: service.Id,
            EmployeeId: service.EmployeeId,
            IsActive: service.IsActive ? service.IsActive : false,
          });
        }
      );

      //remove undefined services before sending request

      const getActiveServices = list.filter((el) => el.IsActive === true);

      if (
        !getActiveServices.some(
          (list) => list.EmployeeId === "00000000-0000-0000-0000-000000000000"
        )
      ) {
        const body = {
          ContactId: this.id,
          Services: getActiveServices,
        };
        this.updateClientMaintenanceOrganizationService(body);
      } else {
        toastr.error(
          dictionaryService.translate(
            "EmployeeOrganizationOverview.Messages.SomethingWentWrong"
          )
        );
      }
    },
    fieldValidator(objectToValidate: any, fields: string[]) {
      fields.forEach((field: string) => {
        //if string, check if it has white spaces
        if (
          isString(objectToValidate[field]) &&
          trim(objectToValidate[field]).length === 0
        ) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired },
          };
          return;
        }

        if (isEmpty(objectToValidate[field])) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired },
          };
        } else {
          const filteredErrors = pickBy(this.errors, function (value, key) {
            return !startsWith(key, field);
          });
          this.errors = filteredErrors;
        }
      });
    },
  },

  created() {
    if (this.permissions.mayConnectServicesToContact) {
      this.getReferenceData();
    }
    this.getClientServices();
  },
});
