<template>
  <div
    class="c-reject-task-lightbox c-lightbox js-lightbox"
    v-show="show"
    @close="onClose"
  >
    <div
      class="
        c-lightbox__outer
        container-fluid
        d-flex
        align-items-center
        justify-content-center
      "
    >
      <div class="c-lightbox__inner js-lightbox-inner row flex-center" v-on:click.stop="">
        <div class="c-card c-card--big">
          <div class="c-reject-task-close-button pull-right" v-on:click="onClose">
            <fa :icon="['fas', 'times']" style="font-size: 2rem" />
          </div>
          <div class="c-container-fluid">
            <div v-show="!saving">
              <h3 class="c-lightbox__title c-reject-task-title">
                {{ translations.ReasonForRejection }}
              </h3>
              <div class="row m-0">
                <textarea
                  class="form-control c-textarea--height c-reject-text-area-input"
                  v-model="rejectMessage"
                />
              </div>
              <div class="row mt-1">
                <div class="col-xs-12 c-reject-task-button">
                  <maz-button
                    class="c-btn--red c-btn--smaller pull-right"
                    v-bind:disabled="rejectMessage.trim() === ''"
                    @click.native="confirmReject"
                  >
                    {{ translations.Reject }}
                  </maz-button>
                </div>
              </div>
            </div>
            <div class="loading-container" v-show="saving">
              <fa icon="spinner" spin size="1x" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import dictionaryService from '@/services/modules/dictionaryService'
import toastr from 'toastr'
import axios from 'axios'
import mazButton from '@/components/general/mazButton/index.vue'

let rejectEndpoint = null

export default {
  name: 'mazRejectTaskLightbox',
  props: {
    show: {
      required: true,
      type: Boolean
    },
    workflowId: {
      required: true,
      type: String
    },
    taskId: {
      required: true,
      type: String
    },
    onClose: {
      type: Function
    }
  },
  components: {
    mazButton
  },
  mounted() {
    const apiBase = $('.js-api').data('api-route')
    rejectEndpoint = apiBase + '/RejectWorkflowTask'
  },
  data() {
    return {
      saving: false,
      translations: {
        ReasonForRejection: dictionaryService.translate(
          'TaskDetail.Labels.ReasonForRejection'
        ),
        Reject: dictionaryService.translate('TaskDetail.Labels.Reject')
      },
      rejectMessage: ''
    }
  },
  watch: {
    show: function(value, oldValue) {
      if (!oldValue && value) {
        this.rejectMessage = ''
      }
    }
  },
  methods: {
    confirmReject: function() {
      const rejectPostObj = {
        workflowId: this.workflowId,
        taskId: this.taskId,
        comment: this.rejectMessage
      }

      this.saving = true
      axios
        .post(rejectEndpoint, rejectPostObj)
        .then(() => {
          this.saving = false
          this.onClose()
          toastr.success(
            dictionaryService.translate('TaskDetail.Messages.TaskRejectedSuccessfully'),
            rejectPostObj,
            true
          )
          this.$emit('confirm')
        })
        .catch(e => {
          this.saving = false
          toastr.error(
            dictionaryService.translate('TaskDetail.Messages.ErrorRejectingTask'),
            e,
            true
          )
        })
    }
  }
}
</script>

<style lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-lightbox.c-reject-task-lightbox .c-card {
  padding: 15px 20px;

  .c-reject-text-area-input {
    margin-bottom: 1em;
    border: 1px solid $dark-blue;
  }

  .c-reject-task-close-button {
    float: right;
    margin: -8px 0px 6px;
    cursor: pointer;
  }

  .c-container-fluid {
    padding-top: 30px;

    .c-reject-task-title {
      margin-bottom: 20px;
      text-transform: none;
    }

    .c-reject-task-button {
      margin-left: 0.8rem !important;
    }
  }
}
</style>