<template>
  <div :id="commentEditor.id" class="form-group c-comment-editor collapse mb-5">
    <div class="o-separator o-separator--small" />

    <textarea
      :maxlength="commentEditor.textAreaMaxCharacters || 1000"
      :placeholder="commentEditor.textAreaPlaceholder"
      class="form-control"
      v-model="commentEditorContent"
    />

    <div class="c-comment-editor__buttons text-right">
      <div class="o-separator o-separator--small" />

      <div class="d-flex float-right u-w-xs-100 flex-wrap flex-sm-nowrap">
        <maz-button
          :label="labels.cancel"
          @click.native="onCancel"
          class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
        />

        <maz-button
          :disabled="isDisabled"
          :label="labels.confirm"
          @click.native="onSubmit"
          class="c-btn--blue order-1 order-sm-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import mazButton from '../../general/mazButton'

const _targetStore = 'supplyRequest2DetailStore/'

export default {
  name: 'maz-comment-editor',
  components: {
    'maz-button': mazButton
  },
  props: {
    commentEditor: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isDisabled: true,
      commentEditorContent: ''
    }
  },
  computed: {
    ...mapGetters({
      supplyRequestSegmentCommentPost:
        _targetStore + 'SUPPLY_REQUEST_SEGMENT_COMMENT_POST',
      labels: _targetStore + 'LABELS'
    }),
    textArea() {
      return this.$el.getElementsByTagName('textarea')[0]
    }
  },
  methods: {
    onCancel(event) {
      this.clearContent()
      this.closeEditor()

      this.$emit('onCancel', event)
    },
    onSubmit(event) {
      const parentSegment = this.$parent.segment

      const commentData = {
        supplyRequestId: parentSegment.parentSupplyRequestId.original,
        categoryId: parentSegment.parentCategoryId.original,
        subcategoryId: parentSegment.parentSubCategoryId.original,
        segmentId: parentSegment.id.original,
        commentEditorId: this.commentEditor.id,
        textArea: this.textArea,
        comment: this.textArea.value
      }

      this.$store.dispatch(_targetStore + 'POST_COMMENT_TO_SUPPLY_REQUEST', commentData)
      this.closeEditor()
      this.$emit('onSubmit', event)
    },
    clearContent(param) {
      const targetTextArea = param || this.textArea

      this.commentEditorContent = ''
      targetTextArea.value = this.commentEditorContent
    },
    disableCancelAndSubmitButtons(valueContent) {
      let result = true

      if (valueContent.length > 0) {
        result = false
      }

      this.isDisabled = result
    },
    closeEditor() {
      $(`#${this.commentEditor.id}.c-comment-editor`).removeClass('show')
    }
  },
  watch: {
    supplyRequestSegmentCommentPost: {
      handler(newValue) {
        if (newValue.withSuccess === true) {
          this.clearContent(newValue.textArea)
          this.closeEditor(newValue.commentEditorId)

          this.$store.dispatch(_targetStore + 'RESET_POST_ACTIONS_COMMENT', {
            PostedWithSuccess: false,
            CommentEditorId: null,
            TextArea: null
          })
        }
      },
      deep: true
    },
    commentEditorContent(newValue) {
      this.disableCancelAndSubmitButtons(newValue)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-comment-editor {
  textarea {
    min-height: 140px;
  }

  button {
    @media #{$media-query-mobile} {
      margin-top: 0;
    }
  }
}
</style>
