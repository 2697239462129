var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qa-wizard-template-preview"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"},[_c('h1',{staticClass:"order-3 order-sm-1"},[_vm._v(_vm._s(_vm.supplyRequestWizardTemplatePreview.title))]),_c('maz-button',{staticClass:"c-btn--green ml-sm-5 mt-0 order-1 order-sm-2",attrs:{"label":_vm.labels.supplyRequestAddLabelsSendTemplate},nativeOn:{"click":function($event){return _vm.createConcept($event)}}}),_c('div',{staticClass:"o-separator o-separator--extra-small order-2 d-sm-none"})],1),_c('div',{staticClass:"o-separator o-separator--extra-small"}),_c('div',{staticClass:"c-wizard-intro-text qa-wizard-intro-text c-intro-text"},[_c('div',{staticClass:"d-md-flex"},[_c('p',{ref:"descriptionTxt",class:[
          'pr-md-5 flex-fill u-pre-wrap',
          { 'is-cutoff': !_vm.isExpanded },
          { 'c-intro-text--is-cutoff': _vm.hasMultipleLines && !_vm.isExpanded }
        ]},[_vm._v(" "+_vm._s(_vm.supplyRequestWizardTemplatePreview.description)+" ")]),(_vm.hasMultipleLines)?_c('a',{class:[
          'e-link-show-more c-wizard-intro-text__toggle',
          { collapsed: !_vm.isExpanded }
        ],on:{"click":_vm.toggleDescription}},[(!_vm.isExpanded)?_c('span',{staticClass:"e-link-bold"},[_vm._v(_vm._s(_vm.labels.showMore))]):_c('span',{staticClass:"e-link-bold"},[_vm._v(_vm._s(_vm.labels.showLess))]),_c('span',{staticClass:"e-icon-toggle"})]):_vm._e()])]),_c('div',{staticClass:"o-separator o-separator--small"}),_c('maz-wizard-sr-category-manager')],1)}
var staticRenderFns = []

export { render, staticRenderFns }