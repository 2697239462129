function generateContactsList(data) {
  const result = []

  if (data.AvailableContacts) {
    data.AvailableContacts.forEach(contact => {
      result.push({
        id: contact.UserId,
        code: contact.UserType,
        name: contact.Fullname,
        email: contact.Email
      })
    })
  }

  return result
}

function generateAssignedContactsList(data) {
  const result = []

  if (data.AssignContacts) {
    data.AssignContacts.forEach(assignContact => {
      result.push({
        id: assignContact.UserId,
        code: assignContact.UserType,
        name: assignContact.Fullname,
        email: assignContact.Email
      })
    })
  }

  return result
}

function generateAssignedContactsListIds(data) {
  const result = []

  if (data) {
    data.forEach(assignContact => {
      result.push(assignContact.id)
    })
  }

  return result
}

const Service = {
  processContactsList: function(payload) {
    return generateContactsList(payload)
  },

  processAssignedContactsList: function(payload) {
    return generateAssignedContactsList(payload)
  },

  processAssignedContactsListIds: function(payload) {
    return generateAssignedContactsListIds(payload)
  }
}

export default Service
