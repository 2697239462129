export const getters = {
  LABELS: (state: any) => state.labels,
  MESSAGES: (state: any) => state.messages,
  GENDER_OPTIONS: (state: any) => state.genders,
  LANGUAGE_OPTIONS: (state: any) => state.languages,
  SHOULD_REFRESH_ON_CLOSE: (state: any) => {
    return state.shouldRefreshOnClose
  },
  EMPLOYEE_URL: (state: any) => state.employeeUrl,
  MAY_SHOW_MODAL: (state: any) => state.employeePermission.mayEditEmployee,
  MODAL_HEADER_TEXT: (state: any) => {
    if (state.shouldRefreshOnClose) {
      return state.messages.employeeSuccessfullyCreated
    } else {
      return state.labels.addEmployee
    }
  },
  VALIDATION_ERRORS: (state: any) => state.validationErrors,
  EXISTING_EMAIL_DETAILS: (state: any) => {
    const validationError = state.validationErrors.find(
      (obj: any) => obj.ErrorCode === 'EmailAlreadyExists'
    )
    if (validationError) {
      return {
        message: state.messages.emailAlreadyExists
      }
    }
    return false
  },
  INVALID_EMAIL_DETAILS: (state: any) => {
    const validationError = state.validationErrors.find(
      (obj: any) => obj.ErrorCode === 'InvalidEmail'
    )
    if (validationError) {
      return {
        message: state.messages.invalidEmail
      }
    }
    return false
  }
}
