





















































































































import mazButton from "@/components/general/mazButton/index.vue";
import mazDropDown from "@/components/general/mazDropDown/index.vue";
import mazSpinner from "@/components/general/mazSpinner/index.vue";
import mazSwitch from "@/components/general/mazSwitch/index.vue";
import store from "@/store/store2";
import toastr from "toastr";
import dictionaryService from "@/services/modules/dictionaryService";
import { isString, isEmpty, pickBy, startsWith, trim } from "lodash";

import Vue from "vue";
export default Vue.extend({
  name: "mazClientMaintenanceOrganizationServices",

  components: {
    mazButton,
    mazDropDown,
    mazSpinner,
    mazSwitch,
  },

  data() {
    return {
      errors: {} as any,
    };
  },

  computed: {
    shouldRedirect(): boolean {
      return this.connectUrl !== undefined && this.connectUrl !== '' && this.connectUrl !== null;
    },
    connectUrl(): string {
      return store.getters.clientMaintenance.organization.CONNECT_URL;
    },
    id(): string {
      return store.getters.clientMaintenance.ID;
    },
    loader(): any {
      return store.getters.clientMaintenance.organization.organizationServices.LOADER;
    },
    labels(): any {
      return store.getters.clientMaintenance.organization.LABELS;
    },
    messages(): any {
      return store.getters.clientMaintenance.organization.MESSAGES;
    },
    clientServicesTable(): any {
      return store.getters.clientMaintenance.organization.CLIENT_SERVICES_TABLE;
    },
    permissions(): any {
      return store.getters.clientMaintenance.organization.PERMISSIONS;
    },
    isEditMode(): boolean {
      return store.getters.clientMaintenance.organization.organizationServices
        .IS_EDIT_MODE;
    },
    relationManagersList(): any {
      return store.getters.clientMaintenance.organization.RELATION_MANAGERS_LIST;
    },
    editedOrganizationServices(): any {
      return store.getters.clientMaintenance.organization.organizationServices
        .EDITED_SERVICE_DETAILS;
    },
  },

  methods: {
    redirectToPowerApp(): void {
      window.open(this.connectUrl, '_blank')?.focus();
    },
    /**
     * VUEX
     */

    getClientMaintenanceClientServices(body: any): void {
      store.dispatch.clientMaintenance.organization.GET_CLIENT_MAINTENANCE_CLIENT_SERVICES(
        body
      );
    },
    updateClientMaintenanceOrganizationService(body: any): void {
      store.dispatch.clientMaintenance.organization.UPDATE_CLIENT_MAINTENANCE_ORGANIZATION_SERVICE(
        body
      );
    },
    resetErrors(): void {
      this.errors = {}; // frontend errors
    },
    /**
     * component methods
     */
    getReferenceData(): void {
      store.dispatch.clientMaintenance.organization.GET_REFERENCE_DATA();
    },
    getClientServices() {
      const body = {
        clientId: this.id,
      };

      this.getClientMaintenanceClientServices(body);
    },
    updateOrganizationServicesEditMode(body: boolean): void {
      store.dispatch.clientMaintenance.organization.organizationServices.UPDATE_ORGANIZATION_SERVICES_EDIT_MODE(
        body
      );
      //reset edit form
      this.resetErrors();
      if (body === false) {
        this.getClientServices();
      }
    },
    save(editedOrganizationServices: any) {
      this.fieldValidator(editedOrganizationServices, ["Employees"]);

      const list: { ServiceId: string; EmployeeId: string; IsActive: boolean }[] = [];

      editedOrganizationServices.forEach(
        (service: { Id: string; EmployeeId: string; IsActive: boolean }) => {
          list.push({
            ServiceId: service.Id,
            EmployeeId: service.EmployeeId,
            IsActive: service.IsActive ? service.IsActive : false,
          });
        }
      );

      // //remove undefined services before sending request

      const getActiveServices = list.filter(
        (el: { IsActive: boolean }) => el.IsActive === true
      );

      if (
        !getActiveServices.some(
          (list) => list.EmployeeId === "00000000-0000-0000-0000-000000000000"
        )
      ) {
        const body = {
          OrganizationId: this.id,
          Services: getActiveServices,
        };
        this.updateClientMaintenanceOrganizationService(body);
      } else {
        toastr.error(
          dictionaryService.translate(
            "EmployeeOrganizationOverview.Messages.SomethingWentWrong"
          )
        );
      }
    },
    fieldValidator(objectToValidate: any, fields: string[]) {
      fields.forEach((field: string) => {
        //if string, check if it has white spaces
        if (
          isString(objectToValidate[field]) &&
          trim(objectToValidate[field]).length === 0
        ) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired },
          };
          return;
        }

        if (isEmpty(objectToValidate[field])) {
          this.errors = {
            ...this.errors,
            [field]: { message: this.messages.fieldRequired },
          };
        } else {
          const filteredErrors = pickBy(this.errors, function (value, key) {
            return !startsWith(key, field);
          });
          this.errors = filteredErrors;
        }
      });
    },
  },

  created() {
    this.getClientServices();
    if (this.permissions.mayConnectServicesToOrganization) {
      this.getReferenceData();
    }
  },
});
