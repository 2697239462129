






















/**
 * README:
 *
 * During the split up of the clientMaintenancePage into contacts and organizations,
 * all vuex store logic was moved to the store/clientMaintenance/organization module initially.
 * Later, some logic was extracted to a shared store: clientMaintenance module (i.e. id)
 * I tried to split it out completely, but some contact components are still referring
 * to the organization store / components since there was insufficient refactoring time.
 * Examples: initial loader, organization overview component etc.
 */
import store from '@/store/store2'

import mazClientMaintenanceOrganization from '@/components/custom/mazClientMaintenanceOrganization/index.vue'
import mazClientMaintenanceContact from '@/components/custom/mazClientMaintenanceContact/index.vue'

import mazSpinner from '@/components/general/mazSpinner/index.vue'

import Vue from 'vue'

const clientMaintenancePage = Vue.extend({
  name: 'maz-client-maintenance-page',

  components: {
    mazClientMaintenanceOrganization,
    mazClientMaintenanceContact,
    mazSpinner
  },

  data() {
    return {
      showActionsOverlay: false
    }
  },

  methods: {
    /**
     * ACTIONS
     */
    getClientMaintenanceInitialData(id: string): void {
      store.dispatch.clientMaintenance.GET_CLIENT_MAINTENANCE_INITIAL_DATA(id)
    }

    /**
     * METHODS
     */
  },

  computed: {
    /**
     * STORE GETTERS
     */
    id(): string {
      return store.getters.clientMaintenance.ID
    },
    loader(): any {
      return store.getters.clientMaintenance.organization.LOADER
    },
    isContact(): boolean {
      return store.getters.clientMaintenance.IS_CONTACT
    },
    isOrganization(): boolean {
      return store.getters.clientMaintenance.IS_ORGANIZATION
    },
    isNotFound(): boolean {
      return store.getters.clientMaintenance.IS_NOT_FOUND
    },
    messages(): any {
      return store.getters.clientMaintenance.MESSAGES
    }
  },

  created() {
    this.getClientMaintenanceInitialData(this.id)
  }
})

export default clientMaintenancePage
