











import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  name: 'maz-wizard-sr-header',
  computed: {
    ...mapGetters({
      supplyRequestWizardHeader: 'supplyRequest2AddStore/SUPPLY_REQUEST_WIZARD_HEADER'
    })
  }
})
export default class MazWizardSRHeader extends Vue {}
