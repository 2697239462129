<template>
  <div class="c-main is-workflowdetail-page js-documentrequest-detail">
    <div class="c-card c-card--noshadow my-3">
      <div v-if="!loading && !showNotFound">
        <div class="c-page-header row">
          <div class="col-12 col-sm-9 mb-1 u-mb-md-0">
            <div class="d-flex align-items-center">
              <h1>
                {{ FullName }}
                <fa icon="check" class="c-completed-check" v-if="Status === 300" />
              </h1>
            </div>
            <h4 class="mt-1">
              <span
                class="c-page-header__customer-name cursor-disabled"
                v-bind:href="CustomerUrl"
              >
                {{ CustomerName }}
              </span>
            </h4>
          </div>
          <div class="col-12 col-lg-3 col-md-4 col-sm-5 d-flex flex-column">
            <maz-button class="c-btn c-btn--ghost hide">
              {{ translate("WorkflowDetail.Labels.ShareDossier") }}
            </maz-button>
            <maz-button
              class="c-btn c-btn--ghost ml-0"
              @click.native="toggleWorkflowHistoryLightBox"
            >
              {{ translate("WorkflowDetail.Labels.ShowWorkflowHistory") }}
            </maz-button>
          </div>
        </div>

        <!-- Documents to sign -->
        <div class="c-files is-pki-page" v-if="signingFilesPanelVisible">
          <h2 class="section-header">
            {{ translate("TaskDetail.Labels.DocumentToSign") }}
          </h2>
          <div
            class="section c-signingfiles-wrapper"
            v-bind:class="{ 'is-signed': documentHasSigned }"
          >
            <div class="d-flex">
              <a
                v-if="DownloadDocuSignFiles"
                class="c-btn mb-2 c-download-all-button"
                :href="downloadSignedFilesUrl"
              >
                {{ translate("WorkflowDetail.Labels.DownloadAll") }}</a
              >
            </div>
            <div class="row justify-content-md-between">
              <div
                class="col-sm-6 row mb-4"
                v-for="(file, index) in workflowSigningFiles"
                v-bind:key="index"
              >
                <div class="col-sm-2">
                  <fa class="c-file-icon" :icon="['fal', file.iconClass]" />
                </div>
                <div class="col-sm-8">
                  <a
                    class="c-file-name"
                    v-bind:class="{ 'c-file-name--blocked': !file.MayDownload }"
                    v-bind:href="file.DownloadUrl"
                  >
                    {{ file.FileName }}
                  </a>
                  <p class="c-file-uploaded-date">
                    <b>{{ translate("WorkflowDetail.Labels.DateUploaded") }}:</b>
                    {{ file.CreatedDateDescription }}
                  </p>
                  <p class="c-file-uploader">
                    <b>{{ translate("WorkflowDetail.Labels.UploadedBy") }}:</b>
                    {{ file.Uploader !== null ? file.Uploader.FullName : "N/A" }}
                  </p>
                  <p class="c-file-scan-status">
                    <b>{{ translate("WorkflowDetail.Labels.ScanStatus") }}:</b>
                    {{ file.scanStatus }} {{ file.scanDateDescription }}
                  </p>
                </div>
                <div class="col-sm-12">
                  <div class="c-msg-wrapper">
                    <!-- Success message -->
                    <div
                      class="c-msg c-msg--success d-flex align-items-center"
                      v-if="file.IsSigned"
                    >
                      <div class="col-sm-2 text-center">
                        <div class="c-msg__icon-rosette" />
                      </div>
                      <div class="col-sm-12 c-msg__message">
                        <span>
                          {{ translate("TaskDetail.Messages.SuccessSigning") }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <p
                  class="c-no-content-message"
                  v-if="!workflowSigningFiles || workflowSigningFiles.length === 0"
                >
                  {{ translate("TaskDetail.Messages.NoDocumentsToShow") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--Documents-->
        <div class="c-files" v-if="workflowFiles && workflowFiles.length">
          <h2 class="section-header">
            {{ translate("WorkflowDetail.Labels.Documents") }}
          </h2>
          <div class="section">
            <div class="d-flex">
              <a
                v-if="showDownloadAllButton"
                class="c-btn mb-2 c-download-all-button"
                :href="downloadFilesUrl"
              >
                {{ translate("WorkflowDetail.Labels.DownloadAll") }}</a
              >
            </div>
            <div class="row justify-content-md-between">
              <div
                class="col-sm-6 row mb-4"
                v-for="(file, index) in workflowFiles"
                v-bind:key="index"
              >
                <div class="col-sm-2">
                  <fa class="c-file-icon" :icon="['fal', file.iconClass]" />
                </div>
                <div class="col-sm-8">
                  <a
                    class="c-file-name"
                    v-bind:class="{ 'c-file-name--blocked': !file.MayDownload }"
                    v-bind:href="file.DownloadUrl"
                  >
                    {{ file.FileName }}
                  </a>
                  <p class="c-file-uploaded-date">
                    <b>{{ translate("WorkflowDetail.Labels.DateUploaded") }}:</b>
                    {{ file.CreatedDateDescription }}
                  </p>
                  <p class="c-file-uploader">
                    <b>{{ translate("WorkflowDetail.Labels.UploadedBy") }}:</b>
                    {{ file.Uploader !== null ? file.Uploader.FullName : "N/A" }}
                  </p>
                  <p class="c-file-scan-status">
                    <b>{{ translate("WorkflowDetail.Labels.ScanStatus") }}:</b>
                    {{ file.scanStatus }} {{ file.scanDateDescription }}
                  </p>
                </div>
                <div class="col-sm-2">
                  <a
                    class="c-file-trash-button"
                    @click="deleteFile(file, index)"
                    v-if="file.IsDeletable"
                  >
                    <fa :icon="['fal', 'trash-alt']" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Comments-->
        <div class="c-comments" v-if="comments && comments.length">
          <h2 class="section-header">
            {{ translate("WorkflowDetail.Labels.Comments") }}
          </h2>
          <div class="section">
            <div class="row" v-for="(comment, index) in comments" v-bind:key="index">
              <div class="col-sm-2">
                <p class="c-comment-date u-fixed-line-height text-nowrap">
                  {{ comment.presentationDate }}
                </p>
              </div>
              <div class="col-sm-10 pl-sm-2">
                <p class="c-comment-description u-fixed-line-height">
                  {{ comment.Description }}
                </p>
                <p class="c-comment-created-by">
                  <b>{{ translate("WorkflowDetail.Labels.CommentPlacedBy") }}:</b>
                  {{ comment.CreatedBy !== null ? comment.CreatedBy.FullName : "n/a" }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!--Workflow-->
        <h2 class="section-header">{{ translate("WorkflowDetail.Labels.Workflow") }}</h2>
        <div class="c-task" v-for="(task, index) in tasks" v-bind:key="index">
          <div class="c-task-header-container row flex-wrap flex-sm-nowrap">
            <div class="col-6 col-sm-1 c-task-header-item">
              <maz-task-status-circle
                v-bind:progress-status="task.Status"
                v-bind:overdue-status="task.DueDateStatus"
              />
            </div>
            <div class="col-12 col-sm-3 c-task-header-item u-alignment-padding">
              <h5>
                <a class="c-task-name" v-bind:href="task.Url">{{ task.Name }}</a>
              </h5>
            </div>
            <div class="col-12 col-sm-3 c-task-header-item u-alignment-padding">
              <p class="c-task-due-date">
                <b>{{ translate("WorkflowDetail.Labels.DueDate") }}:</b>
                {{ task.dueDateDescription }}
              </p>
            </div>
            <div class="col-12 col-sm-4 c-task-header-item u-alignment-padding">
              <p class="c-task-completed-by" v-if="task.completedDescription !== null">
                <b>{{ translate("WorkflowDetail.Labels.Completed") }}: </b>
                {{ task.completedDescription }}
              </p>
            </div>
            <div
              class="col-6 col-sm-1 d-flex c-task-header-item justify-content-end align-items-baseline"
            >
              <a v-on:click="toggleTaskCollapse(task)" style="cursor: pointer">
                <div
                  class="c-arrow-img"
                  v-bind:class="{ isVisible: task.open }"
                  aria-hidden="true"
                  alt="arrow"
                />
              </a>
            </div>
          </div>

          <maz-collapse :id="'task_' + index">
            <div class="row" v-show="task.open">
              <div class="col-sm-1" />
              <div class="col-sm-6 mb-2 u-mb-md-0">
                <p class="c-task-description">{{ task.Description }}</p>
                <div class="c-card__userslist d-flex" v-if="taskAssignedUsers.length > 0">
                  <div class="c-userslist__wrapper mt-1 mr-1">
                    <div
                      v-for="(taskData, index) in taskAssignedUsers"
                      :key="'taskdata-' + index"
                    >
                      <template v-if="taskData.TaskId === task.Id">
                        <span
                          class="c-card__usersitem"
                          v-for="user in taskData.AssignedUsers"
                          :key="user.Id"
                        >
                          {{ user.Name }}
                        </span>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="row">
                  <div class="col-sm-6">
                    <p class="c-task-details-label">
                      {{ translate("WorkflowDetail.Labels.ExpectedStartDate") }}:
                    </p>
                  </div>
                  <div class="col-sm-6">
                    <p class="c-task-details-value">
                      {{ task.expectedStartDateDescription }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <p class="c-task-details-label" v-if="task.ActualStartDate !== null">
                      {{ translate("WorkflowDetail.Labels.ActualStartDate") }}:
                    </p>
                  </div>
                  <div class="col-sm-6">
                    <p class="c-task-details-value" v-if="task.ActualStartDate !== null">
                      {{ task.actualStartDateDescription }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </maz-collapse>
        </div>
        <maz-alert
          v-if="showError"
          :is-danger="true"
          :message="translate('WorkflowDetail.Messages.Error')"
          class="mb-2"
        />
      </div>

      <div class="loading-container" v-show="loading">
        <fa icon="spinner" spin size="2x" />
      </div>

      <div class="not-found-container" v-show="showNotFound">
        <h3>{{ translate("WorkflowDetail.Messages.NotFoundTitle") }}</h3>
        <p>{{ translate("WorkflowDetail.Messages.NotFoundText") }}</p>
      </div>

      <!--Workflow History-->
      <maz-workflow-history-lightbox
        :show="showWorkflowHistoryLightBox"
        :workflow-id="WorkflowId"
        :on-close="toggleWorkflowHistoryLightBox"
        v-if="WorkflowId !== null"
      />
    </div>
  </div>
</template>

<script>
import shared from "./shared";

export default {
  name: "mazWorkflowDetailsClient",
  mixins: [shared],
};
</script>

<style lang="scss" scoped>
@import "./legacy-utilities.scss";
</style>

<style lang="scss">
@import "@/styling/custom/settings/__main.scss";
@import "./js-documentrequest.scss";
@import "./js-documentrequest-detail.scss";

.cursor-disabled {
  padding-bottom: 8px;
  color: $dark-blue;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  cursor: text;
}
</style>
