//Check readme.md
import Vue from 'vue'
import Vuex from 'vuex'
import supplyRequest2AddStore from './modules/supplyRequest2AddStore'
import supplyRequest2DetailStore from './modules/supplyRequest2DetailStore'
import supplyRequest2EditStore from './modules/supplyRequest2EditStore'
import supplyRequest2TemplateMaintenanceStore from './modules/supplyRequest2TemplateMaintenanceStore'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    supplyRequest2AddStore,
    supplyRequest2DetailStore,
    supplyRequest2EditStore,
    supplyRequest2TemplateMaintenanceStore
  }
})

export default store
