
















import Vue from 'vue'
import mazDelegatedSegmentInitial from '@/components/custom/mazDelegatedSegmentInitial/index.vue'

import store from '@/store/store2'

const supplyRequest2DelegatedSegmentPage = Vue.extend({
  name: 'supply-request-2-delegated-segment-page',
  components: {
    mazDelegatedSegmentInitial
  },

  computed: {
    delegationId() {
      return store.state.supplyRequest2DelegatedSegmentStore.delegationId
    },
    labels() {
      return store.state.supplyRequest2DelegatedSegmentStore.labels
    }
  },

  created() {
    store.dispatch.supplyRequest2DelegatedSegmentStore.GET_INITIAL_PAGE_DATA(
      this.delegationId
    )
  }
})

export default supplyRequest2DelegatedSegmentPage
