<template>
  <div class="c-wizard-subcategory">
    <div class="d-flex align-items-center">
      <div>
        <div class="d-flex align-items-center">
          <maz-state-indicator />
          <h4
            class="c-subcategory__title"
          >{{parentCategoryIndexPosition}}{{indexPosition}} {{subCategory.title}}</h4>
        </div>
      </div>
    </div>
    <div class="o-separator o-separator--extra-small" />
    <div class="c-subcategory__content">
      <maz-wizard-sr-segment
        :category="category"
        :id="segment.id"
        :index="index"
        :indexPosition="(index + 1).toString()"
        :key="segment.id.clean"
        :segment="segment"
        :subCategory="subCategory"
        ref="segment"
        v-for="(segment, index) in subCategory.segments"
      />
    </div>
  </div>
</template>

<script>
import mazStateIndicator from '../../general/mazStateIndicator'
import mazWizardSRSegment from '../mazWizardSRSegment'

export default {
  name: 'maz-template-maintenance-sub-category',
  components: {
    'maz-state-indicator': mazStateIndicator,
    'maz-wizard-sr-segment': mazWizardSRSegment
  },
  props: {
    parentCategoryIndexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    indexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    index: {
      required: false,
      type: Number,
      default() {
        return ''
      }
    },
    subCategory: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/__main.scss';

.c-wizard-subcategory {
  @include subcategory;

  + .c-wizard-subcategory {
    margin-top: 40px;
  }
}
</style>
