import dictionaryService from '@/services/modules/dictionaryService'

export const localization = {
  tabNavigation: {
    labels: {
      details: dictionaryService.translate('EmployeeDetail.Labels.Details') as string,
      client: dictionaryService.translate('EmployeeDetail.Labels.Clients') as string,
      permissions: dictionaryService.translate('EmployeeDetail.Labels.Permissions') as string
    },
    labelsWithoutPermissions: {
      details: dictionaryService.translate('EmployeeDetail.Labels.Details') as string,
      client: dictionaryService.translate('EmployeeDetail.Labels.Clients') as string
    },
    messages: {
      notFoundTitle: dictionaryService.translate(
        'EmployeeDetail.Messages.NotFoundTitle'
      ) as string,
      notFoundText: dictionaryService.translate(
        'EmployeeDetail.Messages.NotFoundText'
      ) as string
    }
  }
}
