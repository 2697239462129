<template>
  <div class="maz-supply-request-action-buttons">
    <!-- client only -->
    <div
      class="float-md-right d-flex justify-content-sm-end"
      v-if="
        !supply_request_permissions.isEmployeePortal &&
        supply_request_permissions.showSubmit
      "
    >
      <!-- Todo show the button like this if NOT all questions/segments are marked as completed or non applicable -->

      <maz-button
        :data-content="supply_request_permissions.labels.categoriesNotClose"
        :label="supply_request_permissions.labels.submitAll"
        class="c-btn c-btn--green disabled"
        data-placement="bottom"
        data-toggle="popover"
        data-trigger="hover"
        role="button"
        v-if="!supply_request_permissions.maySubmit"
      />

      <!-- Todo show the button like this if all questions/segments are marked as completed or non applicable -->
      <maz-button
        :label="supply_request_permissions.labels.submitAll"
        @click.native="confirmSubmitSupplyRequest"
        class="c-btn c-btn--green"
        v-if="supply_request_permissions.maySubmit"
      />
    </div>
    <!-- End of client only -->

    <!-- Assign users is already implemented on frontend-side, but will be comment out for now and build further when we will implement the backend also
    <div class="c-alert c-alert--red" v-show="checkLengthOfSelectedContacts && checkLengthOfContactsAfterSubmitClick">
            {{ labels.supplyRequestResponsibleUsersErrorMessage }} <a @click="showAssignUsersModal">{{ labels.supplyRequestResponsibleUsersErrorLink }}</a>
    </div>
    <div class="o-separator o-separator--small" v-show="checkLengthOfSelectedContacts && checkLengthOfContactsAfterSubmitClick"></div>    
    -->

    <!-- Employee only -->
    <div
      class="float-md-right d-flex justify-content-sm-end"
      v-if="supply_request_permissions.isEmployeePortal"
    >
      <maz-button
        :label="supply_request_permissions.labels.submitSupply"
        @click.native="openSubmitOnBehalfOfClientModal"
        class="c-btn c-btn--green"
        v-if="supply_request_permissions.maySubmit"
      />

      <!-- Modal comment -->
      <div
        aria-hidden="true"
        class="modal fade"
        id="modalCommentSubmitOnBehalfOfClient"
        role="dialog"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title">
                {{ supply_request_permissions.labels.submitMotiveTitle }}
              </h3>
              <button
                @click="onCancel"
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              />
            </div>
            <div class="modal-body modal-body--margin-bottom">
              <textarea
                :placeholder="supply_request_permissions.labels.submitMotivePlaceholder"
                class="form-control c-textarea--height"
                v-model="commentSubmitOnBehalfOfClient"
              />
            </div>
            <div class="modal-footer">
              <maz-button
                :label="labels.cancel"
                @click.native="onCancel"
                aria-label="Close"
                class="c-btn--empty"
                data-dismiss="modal"
              />
              <maz-button
                :disabled="isDisabled"
                :label="labels.confirm"
                @click.native="onSubmitSupplyRequest"
                aria-label="Close"
                class="c-btn--green"
                data-dismiss="modal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of employee only -->

    <div class="float-sm-right d-flex justify-content-sm-end flex-wrap flex-sm-nowrap">
      <!-- This wrapper around the button is needed because multiple 'data-toggle' attributes on one element is not possible in Bootstrap -->
      <div
        :data-content="supply_request_permissions.labels.rejectHover"
        class="
          d-block d-sm-inline-block
          u-mr-sm-6px
          order-2 order-sm-1
          mt-2 mt-sm-0
          w-100
        "
        data-placement="bottom"
        data-toggle="popover"
        data-trigger="hover"
      >
        <maz-button
          :label="supply_request_permissions.labels.reject"
          @click.native="onOpenRejectDialog"
          class="c-btn c-btn--red"
          data-target="#showDueDate"
          data-toggle="collapse"
          v-if="supply_request_permissions.mayReject"
        />
      </div>

      <maz-button
        :data-content="supply_request_permissions.labels.approveHover"
        :label="supply_request_permissions.labels.approve"
        @click.native="onApproveSupplyRequest"
        class="c-btn c-btn--green order-1 order-sm-2"
        data-placement="bottom"
        data-toggle="popover"
        data-trigger="hover"
        v-if="supply_request_permissions.mayApprove"
      />
    </div>

    <div class="d-block w-100" v-if="supply_request_permissions.mayReject">
      <div class="collapse float-right w-100" id="showDueDate">
        <div class="o-separator o-separator--small" />
        <div class="row">
          <div class="col-xs-12 col-sm-6 offset-sm-6 col-md-4 offset-md-8">
            <div class="form-group">
              <label class="c-forms__label">
                {{ supply_request_permissions.labels.rejectDateFieldLabel }}
              </label>
              <maz-date-picker
                :currentDate="dueDate"
                :id="'datePicker_' + supply_request_id"
                @onDateSelected="onDateSelected"
                required
              />
            </div>
            <div class="float-sm-right d-flex flex-wrap flex-md-nowrap">
              <maz-button
                :label="labels.cancel"
                @click.native="onCancelRejectSupplyRequest"
                class="c-btn c-btn--ghost order-2 order-sm-1 mt-2 mt-sm-0"
              />

              <maz-button
                :data-content="supply_request_permissions.labels.rejectHover"
                :label="supply_request_permissions.labels.reject"
                @click.native="onRejectSupplyRequest"
                class="c-btn c-btn--red order-1 order-sm-2"
                data-placement="bottom"
                data-toggle="popover"
                data-trigger="hover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <maz-modal
      v-if="showSubmitConfirmationModal"
      v-on:on-close="cancelSubmitSupplyRequest()"
      size="modal-md"
    >
      <div>
        <p>{{ translate('Messages.SupplyRequest.SubmitConfirmation') }}</p>
      </div>
      <template slot="modal-footer">
        <div class="d-flex flex-row align-items-end">
          <maz-button
            v-on:click.native="cancelSubmitSupplyRequest()"
            :label="labels.cancel"
            class="c-btn c-btn--ghost"
          />
          <maz-button
            :label="labels.confirm"
            v-on:click.native="onSubmitSupplyRequest()"
          />
        </div>
      </template>
    </maz-modal>
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import moment from 'moment'
import mazButton from '../../general/mazButton'
import mazDatePicker from '../../general/mazDatePicker'
import mazModal from '@/components/general/mazModal/index.vue'
import EventBus from '@/plugins/EventBus'
import dictionaryService from '@/services/modules/dictionaryService'

const _targetStore = 'supplyRequest2DetailStore/'

export default {
  name: 'maz-supply-request-action-buttons',
  props: {
    supply_request_id: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    supply_request_audit: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    supply_request_permissions: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      newDueDate: '',
      currentDate: moment().format('DD-MM-YYYY'),
      isDisabled: true,
      showAssignedUsersAlert: false,
      checkLengthOfContactsAfterSubmitClick: false,
      commentSubmitOnBehalfOfClient: '',
      showSubmitConfirmationModal: false
    }
  },
  components: {
    mazButton,
    mazDatePicker,
    mazModal
  },
  computed: {
    textArea() {
      return this.$el.getElementsByTagName('textarea')[0]
    },
    ...mapGetters({
      id: _targetStore + 'ID',
      supplyRequestAudit: _targetStore + 'SUPPLY_REQUEST_AUDIT',
      // supplyRequestAssignUsers: _targetStore + 'SUPPLY_REQUEST_ASSIGN_USERS',
      labels: _targetStore + 'LABELS'
    }),
    checkLengthOfSelectedContacts() {
      let result = false

      if (this.supplyRequestAssignUsers.selectedTemporaryContactsList.length > 0) {
        result = false
      } else {
        result = true
      }

      return result
    },
    dueDate() {
      return this.$store.state.supplyRequest2DetailStore.supplyRequestAudit.dueDate
    }
  },
  methods: {
    openSubmitOnBehalfOfClientModal() {
      // TODO Comment out when we build further on the assign users stories
      /*if (this.supplyRequestAssignUsers.selectedTemporaryContactsList.length > 0) {
                this.checkLengthOfContactsAfterSubmitClick = false;
                $('#modalCommentSubmitOnBehalfOfClient').modal('show');
            } else {
                this.checkLengthOfContactsAfterSubmitClick = true;
            }*/

      $('#modalCommentSubmitOnBehalfOfClient').modal('show')
    },
    onDateSelected(event) {
      this.newDueDate = event
    },
    onOpenRejectDialog() {
      const currentDueDate = moment(this.supplyRequestAudit.dueDate, 'DD-MM-YYYY')
      const todaysDate = moment()

      //check if current due date is older or same than todays date
      const isOlderDueDate = currentDueDate.isSameOrBefore(todaysDate)

      if (isOlderDueDate) {
        this.newDueDate = this.currentDate
      } else {
        this.newDueDate = this.supplyRequestAudit.dueDate
      }

      /**
       * Old logic to calculate the difference between dates
       * could be removed when the new logic tested enough at above
       *
       */
      // this.newDueDate = this.supplyRequestAudit.dueDate

      // const originalSupplyRequestDate = moment(this.supplyRequestAudit.dueDateOriginal)
      // const todaysDate = moment()

      // const differenceBetweenDates = todaysDate.diff(originalSupplyRequestDate)

      // if (differenceBetweenDates <= 0) {
      //   this.newDueDate = this.supplyRequestAudit.dueDate
      // } else {
      //   this.newDueDate = this.currentDate
      // }
    },
    confirmSubmitSupplyRequest() {
      this.showSubmitConfirmationModal = true
    },
    cancelSubmitSupplyRequest() {
      this.showSubmitConfirmationModal = false
    },
    onSubmitSupplyRequest() {
      this.showSubmitConfirmationModal = false
      const submitData = {
        supplyRequestId: this.supply_request_id,
        comment: ''
      }

      if (this.textArea) {
        submitData.comment = this.textArea.value
      }

      this.$store.dispatch(_targetStore + 'POST_SUBMIT_THE_SUPPLY_REQUEST', submitData)
      EventBus.$emit('onSubmitSupplyRequest')
    },
    onApproveSupplyRequest(event) {
      this.$store.dispatch(
        _targetStore + 'POST_APPROVE_SUPPLY_REQUEST',
        this.supply_request_id
      )
      this.$emit('onApproveSupplyRequest', event)
    },
    onRejectSupplyRequest(event) {
      const supplyRequestRejectData = {
        supplyRequestId: this.supply_request_id,
        dueDate: $.trim(this.newDueDate)
      }

      this.$store.dispatch(
        _targetStore + 'POST_REJECT_SUPPLY_REQUEST',
        supplyRequestRejectData
      )
      this.$emit('onRejectSupplyRequest', event)
      this.closeShowDueDate()
      this.clearContent()
    },
    onCancelRejectSupplyRequest() {
      this.newDueDate = ''
      this.closeShowDueDate()
      const dueDate = this.$store.state.supplyRequest2DetailStore.supplyRequestAudit
        .dueDate

      $('.datepicker').val(dueDate)
    },
    showPopOver() {
      $('[data-toggle="popover"]').popover()
    },
    closeShowDueDate() {
      $('#showDueDate').collapse('hide')
    },
    disableConfirmButton(valueContent) {
      let result = true

      if (valueContent.length > 0) {
        result = false
      }

      this.isDisabled = result
    },
    onCancel(event) {
      this.clearContent()
      this.$emit('onCancel', event)
    },
    clearContent(param) {
      const targetTextArea = param || this.textArea

      this.commentSubmitOnBehalfOfClient = ''
      targetTextArea.value = this.commentSubmitOnBehalfOfClient
    },
    showAssignUsersModal() {
      $('#modalAssignUsers').modal()

      // Not the nicest solution, but works for now. Can be solved in a different way
      this.$parent.$refs.supplyRequestDetails.$refs.assignUsers.createCloneOfAssignedUsers()
    },
    translate: function(label) {
      return dictionaryService.translate(label)
    }
  },
  mounted() {
    this.showPopOver()
  },
  watch: {
    commentSubmitOnBehalfOfClient(newValue) {
      this.disableConfirmButton(newValue)
    }
  }
}
</script>
