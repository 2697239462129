//TODO: add the request body object modal for the api endpoints which have no parameters
export default {
  getClientMaintenanceDetails: 'GetClient?clientid={clientId}',
  getClientMaintenancePendingActions:
    'GetPendingActions?clientid={clientId}&pagenumber={pageNumber}',
  getClientMaintenanceCompletedActions:
    'GetCompletedActions?clientid={clientId}&pagenumber={pageNumber}',
  getClientMaintenanceOrganizationContactsPermissions:
    'GetOrganizationContactPermissions?organizationId={organizationId}',
  getClientMaintenanceClientServices: 'GetClientServices?clientid={clientId}',
  getClientMaintenanceEmployees: 'GetClientEmployees?clientid={clientId}',
  updateClientMaintenanceOrganizationContactsPermission: 'UpdatePermission',
  deassignContactFromOrganization: 'DisconnectContactFromOrganization',
  assignContactToOrganization: 'ConnectContactsToOrganization',
  assignOrganizationToContact: 'ConnectOrganizationsToContact',
  getOrganizationContacts: 'GetContactsForConnectToOrganization',
  getContactOrganizations: 'GetOrganizationsForConnectToContact',
  connectServiceFromOrganization: 'ConnectServiceToOrganization',
  connectServiceFromContact: 'ConnectServiceToContact',
  connectServicesToContact: 'ConnectServicesToContact',
  connectServicesToOrganization: 'ConnectServicesToOrganization',
  updateClientMaintenanceOrganization: 'UpdateOrganization',
  updateClientMaintenanceContact: 'UpdateContact',
  getClientMaintenanceConnectableEmployees: 'GetEmployeesForConnectToClient',
  connectEmployeesToOrganization: 'ConnectEmployeesToOrganization',
  connectEmployeesToContact: 'ConnectEmployeesToContact',
  disconnectEmployeeFromOrganization: 'DisconnectEmployeeFromOrganization',
  disconnectEmployeeFromContact: 'DisconnectEmployeeFromContact',
  getClientMaintenanceContactOrganizationsPermissions:
    'GetContactOrganizationPermissions?contactId={organizationId}',
  getCustomerReferenceData: 'GetReferenceData',
  addClientMaintenanceContact: 'AddContact',
  addClientMaintenanceOrganization: 'AddOrganization',
  myProfileClientJson: 'MyProfileClientJson/get',
  myProfileClientOrganizations: 'MyProfileClientJson/GetOrganizations',
  myProfileClientPermissions:
    'MyProfileClientJson/GetPermissions?organizationId={organizationId}',
  resetPassword: 'ResetPassword',
  resetMFA: 'ResetMFA',
  reactivate: 'Reactivate',
  updateContactIsSpecial: 'UpdateContactIsSpecial',
  updateOrganizationIsSpecial: 'UpdateOrganizationIsSpecial'
}
