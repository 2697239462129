
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class FeedbackDialog extends Vue {
  isOpened = false

  toggle() {
    this.isOpened = !this.isOpened
  }
}
