










































































































import mazTitle from '@/components/general/mazTitle/index.vue'
import mazFadeOverlay from '@/components/general/mazFadeOverlay/index.vue'
import mazPopover from '@/components/general/mazPopover/index.vue'
import mazButton from '@/components/general/mazButton/index.vue'
import mazTabNavigation from '@/components/general/mazTabNavigation/index.vue'

/*
 * mazClientMaintenanceOrganizationOverview is compatible with contacts.
 */
import mazClientMaintenanceOrganizationOverview from '@/components/custom/mazClientMaintenanceOrganizationOverview/index.vue'
import mazClientMaintenanceContactDetails from '@/components/custom/mazClientMaintenanceContactDetails/index.vue'
import mazClientMaintenanceContactPermissions from '@/components/custom/mazClientMaintenanceContactPermissions/index.vue'
import mazClientMaintenanceContactServices from '@/components/custom/mazClientMaintenanceContactServices/index.vue'
import mazClientMaintenanceContactEmployees from '@/components/custom/mazClientMaintenanceContactEmployees/index.vue'

import Vue from 'vue'
import store from '@/store/store2'
import MazButton from '@/components/general/mazButton/index.vue'
export default Vue.extend({
  name: 'mazClientMaintenanceContact',

  components: {
    mazTitle,
    mazFadeOverlay,
    mazPopover,
    mazButton,
    mazTabNavigation,
    mazClientMaintenanceOrganizationOverview,
    mazClientMaintenanceContactDetails,
    mazClientMaintenanceContactPermissions,
    mazClientMaintenanceContactServices,
    mazClientMaintenanceContactEmployees,
    MazButton
  },

  data() {
    return {
      showActionsOverlay: false
    }
  },

  computed: {
    tabNavigation(): number {
      return store.getters.clientMaintenance.contact.TAB_NAVIGATION
    },

    clientDetails(): object {
      return store.getters.clientMaintenance.contact.CLIENT_DETAILS
    },
    labels(): object {
      return store.getters.clientMaintenance.contact.LABELS
    },
    permissions(): object {
      return store.getters.clientMaintenance.contact.PERMISSIONS
    }
  },

  methods: {
    redirectToPage(url: string) {
      window.location.href = url
    },

    updateClientMaintenanceTabIndex(index: number) {
      store.dispatch.clientMaintenance.contact.UPDATE_CLIENT_MAINTENANCE_TAB_INDEX(index)
    },

    onPasswordReset(): void {
      store.dispatch.clientMaintenance.contact.RESET_PASSWORD()
    },
    onMFAReset(): void {
      store.dispatch.clientMaintenance.contact.RESET_MFA()
    },
    onAccountreactivation(): void {
      store.dispatch.clientMaintenance.contact.REACTIVATE_ACCOUNT()
    }
  }
})
