import dictionaryService from '@/services/modules/dictionaryService'
export const localization = {
  tabNavigation: {
    labels: {
      details: dictionaryService.translate('MyProfile.Labels.Details') as string,
      organization: dictionaryService.translate(
        'MyProfile.Labels.Organizations'
      ) as string
    }
  },
  labels: {
    personNumber: dictionaryService.translate('Labels.Person.Number') as string
  },
  messages: {
    somethingWentWrong: 'Something went wrong'
  }
}
