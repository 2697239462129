















































































































import Vue from 'vue'
import store from '@/store/store2'
import mazSpinner from '@/components/general/mazSpinner/index.vue'
import mazSwitch from '@/components/general/mazSwitch/index.vue'
import mazModal from '@/components/general/mazModal/index.vue'

export default Vue.extend({
  name: 'mazClientMyProfilePageOrganizations',

  components: {
    mazSpinner,
    mazSwitch,
    mazModal
  },

  data() {
    return {
      showPermissionsModal: false,
      isSwitchDisabled: true
    }
  },
  computed: {
    loader(): boolean {
      return store.getters.clientMyProfilePage.clientMyProfilePageOrganizations.LOADER
    },
    labels(): any {
      return store.getters.clientMyProfilePage.clientMyProfilePageOrganizations.LABELS
    },
    connectedOrganizations(): any {
      return store.getters.clientMyProfilePage.clientMyProfilePageOrganizations
        .CONNECTED_ORGANIZATIONS
    },
    connectedPermissions(): any {
      return store.getters.clientMyProfilePage.clientMyProfilePageOrganizations
        .CONNECTED_PERMISSIONS
    },
    messages(): any {
      return store.getters.clientMyProfilePage.clientMyProfilePageOrganizations.MESSAGES
    }
  },

  methods: {
    getMyProfileClientOrganizations(): void {
      store.dispatch.clientMyProfilePage.clientMyProfilePageOrganizations.GET_MY_PROFILE_ORGANIZATIONS()
    },
    getMyProfileClientPermissions(organization: any): void {
      store.dispatch.clientMyProfilePage.clientMyProfilePageOrganizations.GET_MY_PROFILE_PERMISSIONS_MODAL(
        organization
      )
    },
    openMyProfilePermissionsModal(organization: any): void {
      this.getMyProfileClientPermissions(organization)
      this.showPermissionsModal = true
    }
  },

  created() {
    this.getMyProfileClientOrganizations()
  }
})
