<template>
  <FilePond
    :chunkSize="supplyRequestFileUploader.chunkSize"
    :fileValidateTypeDetectType="detectorFunction"
    :label-file-load-error="supplyRequestFileUploader.labels.fileLoadError"
    :label-file-loading="supplyRequestFileUploader.labels.fileLoading"
    :label-file-processing="supplyRequestFileUploader.labels.fileProcessing"
    :label-file-processing-aborted="
      supplyRequestFileUploader.labels.fileProcessingAborted
    "
    :label-file-processing-complete="
      supplyRequestFileUploader.labels.fileProcessingComplete
    "
    :label-file-processing-error="supplyRequestFileUploader.labels.fileProcessingError"
    :label-file-processing-revert-error="
      supplyRequestFileUploader.labels.fileProcessingRevertError
    "
    :label-file-remove-error="supplyRequestFileUploader.labels.fileRemoveError"
    :label-file-size-not-available="supplyRequestFileUploader.labels.fileSizeNotAvailable"
    :label-file-type-not-allowed="supplyRequestFileUploader.labels.fileNotAllowed"
    :label-file-waiting-for-size="supplyRequestFileUploader.labels.fileWaitingForSize"
    :label-idle="supplyRequestFileUploader.labels.dropFiles"
    :label-invalid-field="supplyRequestFileUploader.labels.invalidField"
    :label-max-file-size-exceeded="supplyRequestFileUploader.labels.maxFileSizeExceeded"
    :maxFileSize="supplyRequestFileUploader.maxFileSize"
    :server="serverOptions"
    @processfile="finishedUploadingFile(segment)"
    @addfile="onAddFile"
    accepted-file-types="*/*"
    allow-multiple="true"
    chunk-uploads="true"
    class-name="c-filepond"
    ref="filePond"
    @error="onErrorEmit"
  />
</template>

<script>
import vueFilePond from 'vue-filepond'
import 'filepond-polyfill/dist/filepond-polyfill.min'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import Api from '@/api'
import axiosService from '@/services/modules/axiosService'

import store from '@/store/store2'

const _isEmployeePortal = Api.config.portalType === 'Employee' ? true : false
const _portController = _isEmployeePortal
  ? 'SupplyRequest2EmployeeJson'
  : 'SupplyRequest2ClientJson'
const _baseEndpoint = Api.config.endpointBase + _portController + '/'

export default {
  name: 'maz-file-upload',
  components: {
    FilePond: vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize)
  },
  props: {
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    subCategory: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    segment: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    delegationId: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    supplyRequestFileUploader: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    /**
     * 
     * README:
     * 
     * The property store2 is a workaround due to having split the store into two stores:
     * - one using typescript, and the other one not.
     * - in the future, this should be just one, where we are using just typescript.
     * When you use a new store with typescript, use the store2 prop instead of store.
     * store.original will dispatch an action in the "untyped" way, even in the new ts-store.
     * See direct-vuex package documentation.
     */
    store: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    store2: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    type: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    uploadUrl: {
      required: false,
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      serverOptions: {
        url: this.getUploadEndpointUrl(),
        process: {
          method: 'POST',
          withCredentials: false,
          headers: {},
          timeout: 120000,
          onload: null,
          onerror: (error) => {
            const payload = {
              path: '/src/components/general/mazFileUpload/index.vue',
              id: this.$options.name, //Component name
              message: 'Failed to upload file in supply request',
              state: JSON.stringify({
                error: error,
                additionalLogData: {
                  errorType: 'server-error',
                  SupplyRequestId: this.segment.parentSubCategoryId ? this.segment.parentSupplyRequestId.original : '',
                  CategoryId: this.segment.parentCategoryId ? this.segment.parentCategoryId.original : '',
                  GroupSegmentId: this.segment.parentSubCategoryId ? this.segment.parentSubCategoryId.original : '',
                  DelegationId: this.delegationId,
                  file: this.currentFile,
                  chunk: this.currentChunk
                }
              })
            }
            console.log('server-onerror-payload: ', payload)
            axiosService.post(Api.config.endpointBase + Api.serverSideLoggingApi.logError, payload);
          },
          ondata: (formData) => {
            if (this.type != 'delegationUploader') {
              formData.append(
                'SupplyRequestId',
                this.segment.parentSupplyRequestId.original
              )
              formData.append('CategoryId', this.segment.parentCategoryId.original)
              formData.append('GroupSegmentId', this.segment.parentSubCategoryId.original)
              formData.append('SegmentId', this.segment.id.original)
            } else {
              formData.append('DelegationId', this.delegationId)
            }

            return formData
          }
        },
        patch: {
          ondata: (blobFile) => {
            const formData = new FormData()
            formData.set('blob', blobFile)
            return formData
          },
          headers: (chunk) => {
            this.currentChunk = chunk
            return {
              'Upload-Length': chunk.file.size,
              'Upload-Name': chunk.file.name,
              'Upload-Offset': chunk.offset
            }
          }
        }
      },
      detectorFunction: (source) => {
        return new Promise((resolve, reject) => {
          const acceptedFileExtensions = this.supplyRequestFileUploader
            .fileUploadExtensionsCsv
          const ext = source.name.substr(source.name.lastIndexOf('.') + 1).toLowerCase()
          const isValidExtension = acceptedFileExtensions.includes(ext)

          if (isValidExtension) {
            resolve('*/*')
          } else {
            reject()
          }
        })
      },
      currentFile: { //only used for debugging / logging.
        name: '',
        size: null
      },
      currentChunk: null //only used for debugging / logging.
    }
  },
  methods: {
    onAddFile(payload, config) {
      this.currentFile.name = config.file.name
      this.currentFile.size = config.file.size
    },

    onErrorEmit(error, obj, message) {
      const payload = {
        path: '/src/components/general/mazFileUpload/index.vue',
        id: this.$options.name, //Component name
        message: message.main,
        state: JSON.stringify({
          error: error,
          additionalLogData: {
            errorType: 'component-error',
            SupplyRequestId: this.segment.parentSubCategoryId ? this.segment.parentSupplyRequestId.original : '',
            CategoryId: this.segment.parentCategoryId ? this.segment.parentCategoryId.original : '',
            GroupSegmentId: this.segment.parentSubCategoryId ? this.segment.parentSubCategoryId.original : '',
            DelegationId: this.delegationId,
            message: message,
            file: this.currentFile,
            chunk: this.currentChunk
          }
        })
      }
      console.log('component-onerror-payload', payload)
      axiosService.post(Api.config.endpointBase + Api.serverSideLoggingApi.logError, payload);

    },
    getUploadEndpointUrl() {
      if (this.type != 'delegationUploader') {
        return _baseEndpoint + this.uploadUrl
      } else {
        return (
          Api.config.endpointBase +
          'SupplyRequest2DelegateJson/' +
          this.uploadUrl +
          '?delegationid=' +
          this.delegationId
        )
      }
    },
    finishedUploadingFile(segment) {
      if (this.type != 'delegationUploader') {
        this.body = {
          supplyRequestId: segment.parentSupplyRequestId.original,
          categoryId: segment.parentCategoryId.original,
          subCategoryId: segment.parentSubCategoryId.original,
          segmentId: segment.id.original
        }
      } else {
        this.body = {
          delegationId: this.delegationId
        }
      }
      /**
       * 
       * README:
       * 
       * The property store2 is a workaround due to having split the store into two stores:
       * - one using typescript, and the other one not.
       * - in the future, this should be just one, where we are using just typescript.
       * When you use a new store with typescript, use the store2 prop instead of store.
       * store.original will dispatch an action in the "untyped" way, even in the new ts-store.
       * See direct-vuex package documentation.
       */
      if (this.store) {
        this.$store.dispatch(this.store + 'GET_UPDATED_SEGMENT_FILES', this.body)
      } else if (this.store2) {
        store.original.dispatch(this.store2 + 'GET_UPDATED_SEGMENT_FILES', this.body)
      }
    }
  }
}
</script>