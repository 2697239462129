import helpersService from '@/services/modules/helpersService'
import { localization } from './localization'

export default () => ({
  id: helpersService.getSearchId('?eid=', window.location.search) as string,
  loader: false,
  isEditMode: false,
  messagesInfo: {
    somethingWentWrong: '',
    changesSucessfullySaved: ''
  },
  messages: { ...localization.messages },
  employeePermissions: {} as MazInterfaces.EmployeeMaintenancePermissions.EmployeePermissions,
  editedEmployeePermissions: {} as MazInterfaces.EmployeeMaintenancePermissions.EmployeePermissions,
  activeCountriesValues: [],
  activeCountriesNames: [],
  validationErrors: [] as MazInterfaces.EmployeeMaintenance.ValidationError[]
})
