export const mutations = {
  SET_CLIENT_TYPE_MUTATION(state: any, payload: number): void {
    state.clientType = payload
  },
  RESET_CLIENT_TYPE_MUTATION(state: any): void {
    state.clientType = 0
  },
  SET_STEP_MUTATION(state: any, payload: number): void {
    state.step = payload
  },
  RESET_STEP_MUTATION(state: any): void {
    state.step = 0
  },
  SET_CUSTOMER_PERMISSION_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.CustomerPermission
  ): void {
    state.customerPermission = payload
  },
  SET_LANGUAGES_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.Language[]
  ): void {
    state.languages = payload
  },
  SET_GENDERS_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.Gender[]
  ): void {
    state.genders = payload
  },
  SET_RELATION_MANAGERS_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.RelationManager[]
  ): void {
    state.relationManagers = payload
  },
  SET_COUNTRIES_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.Countries[]
  ) {
    state.countries = payload
  },
  SET_VALIDATION_ERRORS_MUTATION(state: any, payload: any) {
    state.validationErrors = payload
  },
  RESET_VALIDATION_ERROR_MUTATION(state: any, payload: string) {
    const idx = state.validationErrors.findIndex(
      (obj: any) => obj.PropertyName === payload
    )
    if (idx > -1) {
      state.validationErrors.splice(idx, 1)
    }
  },
  RESET_VALIDATION_ERRORS_MUTATION(state: any) {
    state.validationErrors = []
  },
  SET_CLIENT_URL_MUTATION(state: any, payload: string) {
    state.clientUrl = payload
  },
  RESET_CLIENT_URL_MUTATION(state: any) {
    state.clientUrl = ''
  },
  SET_SHOULD_REFRESH_ON_CLOSE_MUTATION(state: any) {
    state.shouldRefreshOnClose = true
  },
  RESET_SHOULD_REFRESH_ON_CLOSE_MUTATION(state: any) {
    state.shouldRefreshOnClose = false
  }
}
