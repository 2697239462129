







































































































































import mazCollapsiblePanel from '@/components/general/mazCollapsiblePanel/index.vue'
import mazStatusIndicator from '@/components/general/mazStatusIndicator/index.vue'
import mazButton from '@/components/general/mazButton/index.vue'
import mazSpinner from '@/components/general/mazSpinner/index.vue'

import MomentService from '@/services/modules/momentService'
import HelpersService from '@/services/modules/helpersService'

import store from '@/store/store2'

import Vue from 'vue'
export default Vue.extend({
  name: 'mazClientMaintenanceOrganizationOverview',

  components: {
    mazCollapsiblePanel,
    mazStatusIndicator,
    mazButton,
    mazSpinner
  },

  computed: {
    id(): string {
      return store.getters.clientMaintenance.ID
    },
    labels(): any {
      return store.getters.clientMaintenance.organization.LABELS
    },
    messages(): any {
      return store.getters.clientMaintenance.organization.MESSAGES
    },
    loader(): any {
      return store.getters.clientMaintenance.organization.LOADER
    },
    activeCollapsiblePanels(): any {
      return store.getters.clientMaintenance.organization.ACTIVE_COLLAPSIBLE_PANELS
    },
    pendingActionsTable(): any {
      return store.getters.clientMaintenance.organization.PENDING_ACTIONS_TABLE
    },
    completedActionsTable(): any {
      return store.getters.clientMaintenance.organization.COMPLETED_ACTIONS_TABLE
    },
    /**
     * COMPONENT COMPUTED PROPERTIES
     */
    activeCollapsiblePanelNames(): any {
      return Object.keys(this.activeCollapsiblePanels)
    }
  },

  methods: {
    /**actions */
    updateClientMaintenanceCompletedActionsCurrentPage(page: number): void {
      store.dispatch.clientMaintenance.organization.UPDATE_CLIENT_MAINTENANCE_COMPLETED_ACTIONS_CURRENT_PAGE(
        page
      )
    },
    getClientMaintenancePendingAction(body: any): void {
      store.dispatch.clientMaintenance.organization.GET_CLIENT_MAINTENANCE_PENDING_ACTIONS(
        body
      )
    },

    getClientMaintenanceCompletedActions(body: any): void {
      store.dispatch.clientMaintenance.organization.GET_CLIENT_MAINTENANCE_COMPLETED_ACTIONS(
        body
      )
    },

    updateClientMaintenanceActiveCollapsiblePanels(panelInfo: object): void {
      store.dispatch.clientMaintenance.organization.UPDATE_CLIENT_MAINTENANCE_ACTIVE_COLLAPSIBLE_PANELS(
        panelInfo
      )
    },
    updateClientMaintenancePendingActionsCurrentPage(page: number): void {
      store.dispatch.clientMaintenance.organization.UPDATE_CLIENT_MAINTENANCE_PENDING_ACTIONS_CURRENT_PAGE(
        page
      )
    },

    /**functions */
    //TODO: add types to date
    formatDate(date: any) {
      return MomentService(date).format(HelpersService.momentDateFormat).toString()
    },
    updateActiveCollapsiblePanel(value: any, panelName: any) {
      this.updateClientMaintenanceActiveCollapsiblePanels({ [panelName]: value })
      switch (panelName) {
        case 'pendingActions':
          this.getPendingActions()
          break
        case 'completedActions':
          this.getCompletedActions()
          break
      }
    },

    getPendingActions() {
      const body = {
        clientId: this.id,
        pageNumber: this.pendingActionsTable.currentPage
      }

      if (
        this.activeCollapsiblePanels.pendingActions &&
        this.pendingActionsTable.rows.length == 0
      ) {
        this.getClientMaintenancePendingAction(body)
      }
    },

    getMorePendingActions() {
      this.updateClientMaintenancePendingActionsCurrentPage(
        this.pendingActionsTable.currentPage + 1
      )

      const body = {
        clientId: this.id,
        pageNumber: this.pendingActionsTable.currentPage
      }

      this.getClientMaintenancePendingAction(body)
    },

    getCompletedActions() {
      const body = {
        clientId: this.id,
        pageNumber: this.completedActionsTable.currentPage
      }

      if (
        this.activeCollapsiblePanels.completedActions &&
        this.completedActionsTable.rows.length == 0
      ) {
        this.getClientMaintenanceCompletedActions(body)
      }
    },

    getMoreCompletedActions() {
      this.updateClientMaintenanceCompletedActionsCurrentPage(
        this.completedActionsTable.currentPage + 1
      )

      const body = {
        clientId: this.id,
        pageNumber: this.completedActionsTable.currentPage
      }

      this.getClientMaintenanceCompletedActions(body)
    },
    getActionStatusCode(statusCode: number) {
      //TODO: These codes were implemented as there was no way to better implement this logic
      // without reworking the API endpoint from which we fetch the value.
      // This should be refactored as well as the API endpoint to fit with the new project setup

      let returnedStatusCode = '0'
      switch (statusCode) {
        case 5:
          returnedStatusCode = '0'
          break
        case 10:
          returnedStatusCode = '1'
          break
        case 20:
          returnedStatusCode = '2'
          break
        case 30:
          returnedStatusCode = '3'
          break
        default:
          break
      }
      return returnedStatusCode
    }
  },

  created() {
    this.getPendingActions()
    this.getCompletedActions()
  }
})
