


















































import Vue from 'vue'
import store from '@/store/store2'
import mazSpinner from '@/components/general/mazSpinner/index.vue'

export default Vue.extend({
  name: 'mazEmployeeClientsOverview',
  components: {
    mazSpinner
  },
  computed: {
    id(): string {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.ID
    },
    loader(): boolean {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.LOADER
    },
    labels(): any {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.LABELS
    },
    connectedClients(): any {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails
        .CONNECTED_CLIENTS
    },
    messages(): any {
      return store.getters.employeeMaintenance.employeeMaintenanceDetails.MESSAGES
    }
  },

  methods: {
    getEmployeeConnectedClients(id: string): void {
      store.dispatch.employeeMaintenance.employeeMaintenanceDetails.GET_EMPLOYEE_CONNECTED_CLIENTS(
        id
      )
    },
  },
  created() {
    this.getEmployeeConnectedClients(this.id)
  },
})
