import HelpersService from './helpersService'
import MomentService from './momentService'
import ProcessUserTypeService from './processUserTypeService'

//PRIVATE FUNCTIONS
function cleanPropertyValue(propertyValue, expressionsToBeRemoved) {
  let result = null
  const emptyStr = ''

  if (HelpersService.isNotNullOrUndefined(propertyValue)) {
    result = propertyValue

    if (HelpersService.isNotNullOrUndefined(expressionsToBeRemoved)) {
      expressionsToBeRemoved.forEach(expression => {
        result = result.replace(expression, emptyStr)
      })
    }
  }

  return result
}

function processId(value) {
  const result = {
    original: value,
    clean: cleanPropertyValue(value, ['{', '}'])
  }

  return result
}

function generateAuditLogsObjects(data) {
  const result = []

  data.forEach(auditLog => {
    result.push({
      createdDate: MomentService(auditLog.CreatedDate)
        .format(HelpersService.momentDateAndTimeFormat)
        .toString(),
      actorName: auditLog.ActorName,
      fullDescription: auditLog.FullDescription
    })
  })

  return result
}

function generateCustomersObjects(data) {
  const result = []

  if (data === null || data === undefined) {
    return []
  } else {
    data.forEach(customer => {
      result.push({
        id: customer.Id,
        employeeId: customer.EmployeeId,
        customerNumber: customer.CustomerNumber,
        name: customer.Name,
        selected: customer.Selected,
        type: customer.Type
      })
    })
  }

  return result
}

function generateDelegationOverviewDelegeeObject(data) {
  const result = []

  if (data === null || data === undefined) {
    return []
  } else {
    data.forEach(delegee => {
      result.push({
        delegationDueDate: MomentService(delegee.DelegationDueDate)
          .format(HelpersService.momentDateFormat)
          .toString(),
        email: delegee.Email
      })
    })
  }
  return result
}

function generateDelegationOverviewSegmentObject(data) {
  const result = []

  data.Segments.forEach(segment => {
    result.push({
      index: segment.Index,
      title: segment.Title,
      id: processId(segment.Id),
      delegees: generateDelegationOverviewDelegeeObject(segment.Delegees)
    })
  })

  return result
}

function generateDelegationOverviewSubCategoryObject(data) {
  const result = []

  data.GroupSegments.forEach(subCategory => {
    result.push({
      index: subCategory.Index,
      title: subCategory.Title,
      id: processId(subCategory.Id),
      segments: generateDelegationOverviewSegmentObject(subCategory)
    })
  })

  return result
}

function generateDelegationOverviewCategoriesObject(data) {
  const result = []

  if (data === null || data === undefined) {
    return []
  } else {
    data.forEach(category => {
      result.push({
        index: category.Index,
        title: category.Title,
        id: processId(category.Id),
        groupSegments: generateDelegationOverviewSubCategoryObject(category)
      })
    })
  }

  return result
}

function generateUpdatedSegmentsObject(data) {
  const result = []

  if (data === null || data === undefined) {
    return []
  } else {
    data.Segments.forEach(segment => {
      result.push({
        index: segment.Index,
        title: segment.Title,
        id: processId(segment.SegmentId),
        categoryId: processId(segment.CategoryId),
        groupSegmentId: processId(segment.GroupSegmentId),
        dateTimeMostRecentActivity: MomentService(segment.LastActivityDate)
          .format(HelpersService.momentDateAndTimeFormat)
          .toString()
      })
    })
  }

  return result
}

function findCategory(categories, targetCategoryId, callback) {
  categories.forEach(category => {
    if (category.id.original === targetCategoryId) {
      callback(category)
    }
  })
}

function findCategoryBySegment(categories, targetSegment, callback) {
  categories.forEach(category => {
    if (category.id.original === targetSegment.CategoryId) {
      callback(category)
    }
  })
}

function findSubCategory(categories, targetSegment, callback) {
  categories.forEach(category => {
    if (category.id.original === targetSegment.CategoryId) {
      category.subCategories.forEach(subCategory => {
        if (subCategory.id.original === targetSegment.GroupSegmentId) {
          callback(subCategory)
        }
      })
    }
  })
}

function findSegmentInOverview(categories, targetSegment, callback) {
  categories.categories.forEach(category => {
    if (category.id === targetSegment.CategoryId) {
      category.groupSegments.forEach(subCategory => {
        if (subCategory.id === targetSegment.GroupSegmentId) {
          subCategory.segments.forEach(segment => {
            if (segment.id === targetSegment.SegmentId) {
              callback(segment)
            }
          })
        }
      })
    }
  })
}

function findSegment(categories, targetSegment, callback) {
  categories.forEach(category => {
    if (category.id.original === targetSegment.CategoryId) {
      category.subCategories.forEach(subCategory => {
        if (subCategory.id.original === targetSegment.GroupSegmentId) {
          subCategory.segments.forEach(segment => {
            if (segment.id.original === targetSegment.SegmentId) {
              callback(segment)
            }
          })
        }
      })
    }
  })
}

function isMazarsComment(userType) {
  let result = false
  const user = ProcessUserTypeService[userType]

  if (HelpersService.isNotNullOrUndefined(user)) {
    if (user.userType === 'Employee') {
      result = true
    }
  }

  return result
}

function processIsComplete(value) {
  let result = 9999

  if (value) {
    result = 1010
  }

  return result.toString()
}

function generateCategoriesObjects(parentSupplyRequestId, data) {
  const result = []

  data.Categories.forEach(category => {
    result.push({
      parentSupplyRequestId: processId(parentSupplyRequestId),
      parentCategoryId: processId(category.ParentCategoryId),
      navigationId: 'navigationId' + cleanPropertyValue(category.Id, ['{', '}']), //only for navigation and nothing else
      id: processId(category.Id),
      title: category.Title,
      shortTitle: category.ShortTitle,
      isComplete: category.IsComplete,
      isCompleteCode: processIsComplete(category.IsComplete),
      percentageOfSegmentsCompleted: category.PercentageOfSegmentsCompleted,
      order: category.Order,
      totalNumberOfFilesCategory: 1, // Initial set to 1 (not proper, but give us the right result)
      subCategories: []
    })
  })

  return result
}

function processSegmentIsComplete(value) {
  let result = 9999

  if (value) {
    result = 1020
  }

  return result.toString()
}

function generateSegmentListObjects(data) {
  const result = []

  data.forEach(listItem => {
    let resultId = cleanPropertyValue(listItem.Id, ['{', '}'])

    result.push({
      id: resultId,
      title: listItem.Title
    })

    resultId = null
  })

  return result
}

function generateDelegationsObjects(data) {
  const result = []

  if (data === null || data === undefined) {
    return []
  } else {
    data.forEach(delegee => {
      result.push({
        id: delegee.Id,
        categoryId: delegee.CategoryId,
        reason: delegee.DelegateReason,
        delegationIsComplete: delegee.DelegationIsComplete,
        email: delegee.Email,
        url: delegee.DelegationUrl,
        dueDate: MomentService(delegee.DueDate)
          .format(HelpersService.momentDateFormat)
          .toString(),
        groupSegmentId: delegee.GroupSegmentId,
        segmentId: delegee.SegmentId,
        sender: {
          email: delegee.Sender.Email,
          fullname: delegee.Sender.Fullname,
          userId: delegee.Sender.UserId,
          userType: delegee.Sender.UserType
        }
      })
    })
  }

  return result
}

function generateFilesObjects(data) {
  const result = []

  if (data === null || data === undefined) {
    return []
  } else {
    data.forEach(file => {
      result.push({
        id: file.Id,
        fileName: file.FileName,
        mimeType: file.MimeType,
        ownerEmail: file.OwnerEmail,
        createdDate: MomentService(file.CreatedDate)
          .format(HelpersService.momentDateAndTimeFormat)
          .toString(),
        mayDownload: file.MayDownload,
        mayDelete: file.MayDelete,
        downloadUrl: file.DownloadUrl
      })
    })
  }

  return result
}

function generateSegmentCommentsObjects(data) {
  const result = []

  data.forEach(comment => {
    let resultId = cleanPropertyValue(comment.Id, ['{', '}'])

    result.push({
      id: resultId,
      title: comment.Actor.Email,
      acceptedDate: comment.AcceptedDate,
      isAccepted: comment.IsAccepted,
      postTime: MomentService(comment.LastModifiedDate)
        .format(HelpersService.momentDateAndTimeFormat)
        .toString(),
      description: comment.UserComment,
      mazarsComment: isMazarsComment(comment.Actor.UserType),
      user: {
        id: comment.Actor.UserId,
        fullName: comment.Actor.Fullname,
        email: comment.Actor.Email,
        type: ProcessUserTypeService[comment.Actor.UserType]
          ? ProcessUserTypeService[comment.Actor.UserType].userType
          : null
      }
    })

    resultId = null
  })

  return result
}

function generateAttachmentsObjects(data) {
  const result = []

  if (data === null || data === undefined) {
    return []
  } else {
    data.forEach(attachment => {
      result.push({
        id: attachment.Id,
        fileName: attachment.FileName,
        mimeType: attachment.MimeType,
        ownerEmail: attachment.OwnerEmail,
        createdDate: MomentService(attachment.CreatedDate)
          .format(HelpersService.momentDateAndTimeFormat)
          .toString(),
        mayDownload: attachment.MayDownload,
        mayDelete: attachment.MayDelete,
        downloadUrl: attachment.DownloadUrl
      })
    })
  }

  return result
}

function generateSegmentObjects(
  parentSupplyRequestId,
  parentCategoryId,
  parentSubCategoryId,
  data
) {
  const result = []

  data.forEach(segment => {
    result.push({
      parentSupplyRequestId: parentSupplyRequestId,
      parentCategoryId: parentCategoryId,
      parentSubCategoryId: parentSubCategoryId,
      id: processId(segment.Id),
      isNotApplicable: segment.IsNotApplicable,
      priority: segment.Priority,
      state: {
        code: null
      },
      title: segment.Title,
      helpText: segment.HelpText,
      description: segment.Description,
      mayAddComment: segment.MayAddComment,
      mayMarkAsNotApplicableOrCompleted: segment.MayMarkAsNotApplicableOrCompleted,
      mayAddFiles: segment.MayAddFiles,
      mayDelegate: segment.MayDelegate,
      mayDownload: segment.MayDownload,
      mayDownloadZip: segment.MayDownloadZip,
      downloadZipUrl: segment.DownloadZipUrl,
      isComplete: segment.IsComplete,
      isCompleteCode: processSegmentIsComplete(segment.IsComplete),
      segmentItems: generateSegmentListObjects(segment.SegmentItems),
      delegations: generateDelegationsObjects(segment.Delegations),
      numberOfDelegations: segment.NumberOfDelegations,
      showInitialDelegationsNumber: true,
      numberOfFiles: segment.NumberOfFiles,
      showInitialFilesNumber: true,
      files: generateFilesObjects(segment.Files),
      comments: generateSegmentCommentsObjects(segment.AllComments),
      attachments: generateAttachmentsObjects(segment.Attachments),
      commentEditor: {
        id: 'commentEditor' + cleanPropertyValue(segment.Id, ['{', '}'])
      },
      delegateEditor: {
        id: 'delegateEditor' + cleanPropertyValue(segment.Id, ['{', '}'])
      }
    })
  })

  return result
}

function generateSubCategoriesObjects(data) {
  const result = []
  const dataSet = data.GroupSegments

  dataSet.forEach(subCategory => {
    result.push({
      parentSupplyRequestId: processId(data.SupplyRequestId),
      parentCategoryId: processId(data.CategoryId),
      id: processId(subCategory.Id),
      title: subCategory.Title,
      isComplete: subCategory.IsComplete,
      isCompleteCode: processIsComplete(subCategory.IsComplete),
      order: subCategory.Order,
      state: {
        code: null
      },
      mayDownload: subCategory.MayDownload,
      mayDownloadZip: subCategory.MayDownloadZip,
      downloadZipUrl: subCategory.DownloadZipUrl,
      totalNumberOfFilesSubCategory: 1, // Initial set to 1 (not proper, but give us the right result)
      segments: generateSegmentObjects(
        processId(data.SupplyRequestId),
        processId(data.CategoryId),
        processId(subCategory.Id),
        subCategory.Segments
      )
    })
  })

  return result
}

const Service = {
  processCategories(srid, payload) {
    return generateCategoriesObjects(srid, payload)
  },

  processAuditLogs(auditLogs, payload) {
    const result = auditLogs

    result.exportInfo.mayExport = payload.MayExportAuditLog
    result.exportInfo.exportUrl = payload.ExportAuditLogUrl
    result.logs = generateAuditLogsObjects(payload.AuditLogEntries)

    return result
  },

  processCustomers(customers, payload) {
    let result = customers

    result = generateCustomersObjects(payload)

    return result
  },

  processDelegationOverview(delegations, payload) {
    const result = delegations

    result.numberOfDelegations = payload.NumberOfDelegations
    result.categories = generateDelegationOverviewCategoriesObject(payload.Categories)
    return result
  },

  processUpdatedSegmentsOverview(recentUpdatedSegments, payload) {
    const result = recentUpdatedSegments

    result.numberOfSegments = payload.NumberOfSegments
    result.segments = generateUpdatedSegmentsObject(payload)

    return result
  },

  updateCategory(categories, payload) {
    const result = categories

    findCategory(categories, payload.CategoryId, category => {
      category.isComplete = payload.IsComplete
      category.mayDownload = payload.MayDownload
      category.mayDownloadZip = payload.MayDownloadZip
      category.downloadZipUrl = payload.DownloadZipUrl
      category.subCategories = generateSubCategoriesObjects(payload)
      category.isLoaded = true
    })

    return result
  },

  updateSegmentComments(categories, payload) {
    const result = categories

    findSegment(categories, payload, segment => {
      segment.comments = generateSegmentCommentsObjects(payload.Comments)
    })

    return result
  },

  updateSegmentFiles(categories, payload) {
    const result = categories

    findSegment(categories, payload, segment => {
      segment.mayDownloadZip = payload.MayDownloadZip
      segment.downloadZipUrl = payload.DownloadZipUrl
      segment.files = generateFilesObjects(payload.Files)
    })

    return result
  },

  updateSegmentAttachments(categories, payload) {
    const result = categories

    findSegment(categories, payload, segment => {
      segment.attachments = generateAttachmentsObjects(payload.Attachments)
    })

    return result
  },

  updateSegmentDelegations(categories, payload) {
    const result = categories

    findSegment(categories, payload, segment => {
      segment.delegations = generateDelegationsObjects(payload.Delegations)
    })

    return result
  },

  updateCategoryDownloadFiles(categories, payload) {
    const result = categories

    findCategory(categories, payload.CategoryId, category => {
      category.totalNumberOfFilesCategory = payload.TotalNumberOfFilesCategory
      category.downloadZipUrl = payload.CategoryDownloadZipUrl
      category.mayDownloadZip = payload.CategoryMayDownloadZip
    })

    findSubCategory(categories, payload, subCategory => {
      subCategory.totalNumberOfFilesSubCategory = payload.TotalNumberOfFilesSubCategory
      subCategory.downloadZipUrl = payload.GroupSegmentDownloadZipUrl
      subCategory.mayDownloadZip = payload.GroupSegmentMayDownloadZip
    })

    return result
  },

  updatedSegmentIsNotApplicableStatus(categories, payload) {
    const result = categories

    findSegment(categories, payload, segment => {
      segment.isNotApplicable = payload.IsNotApplicable
      segment.mayMarkAsNotApplicableOrCompleted =
        payload.MayMarkAsNotApplicableOrCompleted
      segment.mayAddFiles = payload.MayAddFiles
      segment.mayDelegate = payload.MayDelegate
      segment.mayAddComment = payload.MayAddComment
      segment.isComplete = payload.IsComplete
      segment.groupSegmentIsComplete = payload.GroupSegmentIsComplete
    })

    return result
  },

  updatedSegmentStatus(categories, payload) {
    const result = categories

    findSegment(categories, payload, segment => {
      segment.isComplete = payload.IsComplete
      segment.isCompleteCode = processSegmentIsComplete(payload.IsComplete)
    })

    return result
  },

  updatedSubCategoryStatus(categories, payload) {
    const result = categories

    findSubCategory(categories, payload, subCategory => {
      subCategory.isComplete = payload.IsComplete
      subCategory.isCompleteCode = processIsComplete(payload.IsComplete)
    })

    return result
  },

  updatedCategoryStatus(categories, payload) {
    const result = categories

    findCategoryBySegment(categories, payload, category => {
      category.isComplete = payload.IsComplete
      category.isCompleteCode = processIsComplete(payload.IsComplete)
      category.percentageOfSegmentsCompleted = payload.PercentageOfSegmentsCompleted
    })

    return result
  },

  updatedCommentsOverview(categories, payload) {
    const result = categories

    findSegmentInOverview(categories, payload, segment => {
      segment.dateTimeMostRecentComment = MomentService()
        .format(HelpersService.momentDateAndTimeFormat)
        .toString()
    })

    return result
  }
}

export default Service
