






















































































































































































































import store from '@/store/store2'
import config from '@/api/modules/config'
import helpersService from '@/services/modules/helpersService'
import clientMaintenanceOverviewApi from '@/api/modules/clientMaintenanceOverviewApi'

import mazPopover from '@/components/general/mazPopover/index.vue'
import mazFadeOverlay from '@/components/general/mazFadeOverlay/index.vue'
import mazTitle from '@/components/general/mazTitle/index.vue'
import mazCheckbox from '@/components/general/mazCheckbox/index.vue'
import mazAutoCompleteInput from '@/components/general/mazAutoCompleteInput/index.vue'
import mazSpinner from '@/components/general/mazSpinner/index.vue'

import mazClientMaintenanceOverviewAddClientModal from '@/components/custom/mazClientMaintenanceOverviewAddClientModal/index.vue'

import Vue from 'vue'

const _baseEndpoint = config.endpointBaseCsv + 'ClientMaintenanceEmployee/'
const _targetApi = clientMaintenanceOverviewApi

const clientMaintenanceOverviewPage = Vue.extend({
  name: 'maz-client-maintenance-overview-page',
  components: {
    mazTitle,
    mazCheckbox,
    mazAutoCompleteInput,
    mazPopover,
    mazFadeOverlay,
    mazSpinner,
    mazClientMaintenanceOverviewAddClientModal
  },
  data() {
    return {
      searchTerm: '' as string,
      showInactiveClients: false as boolean,
      isDisabled: true as boolean,
      showDownloadOverlay: false as boolean
    }
  },
  computed: {
    /**
     * STORE GETTERS
     */
    clientList(): any {
      return store.getters.clientMaintenanceOverviewStore.CLIENT_LIST
    },
    searchClientsList(): any {
      return store.getters.clientMaintenanceOverviewStore.SEARCH_CLIENT_LIST
    },
    currentPage(): number {
      return store.getters.clientMaintenanceOverviewStore.CURRENT_PAGE
    },
    maxPageCounter(): number {
      return store.getters.clientMaintenanceOverviewStore.MAX_PAGE_COUNTER
    },
    loader(): boolean {
      return store.getters.clientMaintenanceOverviewStore.LOADER
    },
    labels(): any {
      return store.getters.clientMaintenanceOverviewStore.LABELS
    },
    messages(): any {
      return store.getters.clientMaintenanceOverviewStore.MESSAGES
    },
    customerPermission(): MazInterfaces.ClientMaintenance.CustomerPermission {
      return store.getters.clientMaintenanceOverviewStore.CUSTOMER_PERMISSION
    },

    csvExportUrl(): string {
      let endpoint = helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.exportClientOverview
      )
      // Because we are trying to replace a boolean value, the "helpersService.buildEndpointRequest" will not work
      // Because of that we are doing it manually
      endpoint = endpoint.replace('{showInactiveClients}', this.showInactiveClients)
      return endpoint
    },
    csvExportOrganizationUrl(): string {
      let endpoint = helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.exportOrganizations
      )
      endpoint = endpoint.replace('{showInactiveClients}', this.showInactiveClients)
      return endpoint
    },
    csvExportContactsUrl(): string {
      let endpoint = helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.exportContacts
      )
      endpoint = endpoint.replace('{showInactiveClients}', this.showInactiveClients)
      return endpoint
    }
  },
  methods: {
    /**
     * ACTIONS
     */
    getClientMaintenanceOverviewClientList(): void {
      const getClientsBody: MazInterfaces.ClientMaintenanceOverview.GetClients = {
        searchTerm: this.searchTerm,
        showInactiveCustomers: this.showInactiveClients,
        pageNumber: this.currentPage
      }
      store.dispatch.clientMaintenanceOverviewStore.GET_CLIENT_MAINTENANCE_OVERVIEW_CLIENT_LIST(
        getClientsBody
      )
    },
    updateClientMaintenanceCurrentPage(payload: number): void {
      store.dispatch.clientMaintenanceOverviewStore.UPDATE_CLIENT_MAINTENANCE_OVERVIEW_CURRENT_PAGE(
        payload
      )
      this.getClientMaintenanceOverviewClientList()
    },
    searchClientMaintenanceClients(value: any) {
      this.searchTerm = value
      const searchClientsBody: MazInterfaces.ClientMaintenanceOverview.ClientSearchTerm =
        {
          searchTerm: this.searchTerm,
          showInactiveCustomers: this.showInactiveClients
        }
      store.dispatch.clientMaintenanceOverviewStore.SEARCH_CLIENT_MAINTENANCE_OVERVIEW_CLIENTS(
        searchClientsBody
      )
    },

    redirectPage(url: string): void {
      window.location.href = url
    },
    handleClick(e: any, url: string): void {
      if (e.ctrlKey) {
        window.open(url)
      } else {
        this.redirectPage(url)
      }
    },
    onItemSelect(val: string): void {
      this.searchTerm = val
      this.getClientMaintenanceOverviewClientList()
    },
    onAddClientModalClose(shouldRefreshList: boolean): void {
      if (shouldRefreshList) {
        this.getClientMaintenanceOverviewClientList()
      }
    }
  },
  created() {
    this.getClientMaintenanceOverviewClientList()
  },
  watch: {
    showInactiveClients() {
      //Clear the search term before getting data
      this.searchTerm = ''
      this.getClientMaintenanceOverviewClientList()
    },
    searchTerm(val) {
      if (val.length === 0) {
        this.getClientMaintenanceOverviewClientList()
      }
    }
  }
})

export default clientMaintenanceOverviewPage
