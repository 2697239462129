<template>
  <div class="c-spinner">
    <div />
    <div />
    <div />
    <div />
  </div>
</template>

<script>
export default {
  name: 'mazSpinner',
  props: {
    audit_type: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    audit_finacial_year: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    audit_due_date_text: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    audit_due_date: {
      required: false,
      type: String,
      default() {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-spinner {
  position: relative;
  display: flex;
  width: 60px;
  height: 60px;
  margin: 0 auto;

  & div {
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 44px;
    height: 44px;
    margin: 8px;
    border: 6px solid #fff;
    border-color: $steel-blue transparent transparent transparent;
    border-radius: 50%;
    animation: spinner 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &:nth-child(1) {
      animation-delay: 0.1s;
    }

    &:nth-child(2) {
      animation-delay: -0.9s;
    }

    &:nth-child(3) {
      animation-delay: -1.75s;
    }
  }

  &.c-spinner--category {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &.c-spinner--small {
    width: 18px;
    height: 18px;
    margin: 0;

    & div {
      width: 18px;
      height: 18px;
      margin: 0;
      border-width: 2px;
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
