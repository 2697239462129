export const mutations = {
  UPDATE_CLIENT_MAINTENANCE_CLIENT_DETAILS_MUTATION(state: any, payload: any) {
    state.clientDetails = payload
    state.editedClientDetails = { ...payload.contact } //Create a copy of the organization object
  },
  UPDATE_CLIENT_MAINTENANCE_TAB_INDEX_MUTATION(state: any, payload: number) {
    state.tabNavigation.activeTabIndex = payload
  },
  SET_LANGUAGES_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.Language[]
  ): void {
    state.languages = payload
  },
  SET_GENDERS_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.Gender[]
  ): void {
    state.genders = payload
  },
  SET_RELATION_MANAGERS_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.RelationManager[]
  ): void {
    state.relationManagers = payload
  },
  UPDATE_LOADER_MUTATION(state: any, payload: any) {
    state.loader = { ...state.loader, ...payload }
  },
  UPDATE_CLIENT_MAINTENANCE_EDIT_MODE_MUTATION(state: any, payload: boolean) {
    state.isEditMode = payload
  },
  RESET_CLIENT_MAINTENANCE_EDIT_CLIENT_DETAILS_MUTATION(state: any) {
    state.editedClientDetails = {
      ...state.editedClientDetails,
      ...state.clientDetails.contact
    }
  },
  UPDATE_CONTACT_ORGANIZATIONS_PERMISSIONS_MUTATION(
    state: any,
    payload: {
      Organizations: any
      Type: any
    }
  ) {
    state.contactOrganizationsPermissionsTable.rows = payload.Organizations
    state.contactOrganizationsPermissionsTable.type = payload.Type
  },

  UPDATE_CLIENT_SERVICES_TABLE_MUTATION(state: any, payload: { Services: [] }) {
    state.clientServicesTable.rows = payload.Services
  },
  UPDATE_CLIENT_MAINTENANCE_EMPLOYEES_MUTATION(state: any, payload: any) {
    state.employeesConnectedToContact = payload
  },
  SET_CLIENT_MAINTENANCE_CONNECTABLE_EMPLOYEES_MUTATION(state: any, payload: any) {
    state.employeesModal.originalEmployeesList = payload
    state.employeesModal.availableEmployeesList = payload
  },
  RESET_CLIENT_MAINTENANCE_SELECTED_EMPLOYEES_MUTATION(state: any) {
    state.employeesModal.selectedEmployeesList = []
  },
  UPDATE_CLIENT_MAINTENANCE_EMPLOYEES_LIST_MUTATION(state: any, payload: any) {
    if (state.employeesModal.availableEmployeesList.indexOf(payload) > -1) {
      state.employeesModal.availableEmployeesList = state.employeesModal.availableEmployeesList.filter(
        (item: any) => item !== payload
      )
    } else {
      state.employeesModal.availableEmployeesList.push(payload)
    }
    if (state.employeesModal.selectedEmployeesList.indexOf(payload) > -1) {
      state.employeesModal.selectedEmployeesList = state.employeesModal.selectedEmployeesList.filter(
        (item: any) => item !== payload
      )
    } else {
      state.employeesModal.selectedEmployeesList.push(payload)
    }
  },
  UPDATE_CLIENT_MAINTENANCE_AVAILABLE_CONNECTABLE_EMPLOYEES_MUTATION(
    state: any,
    payload: object[]
  ) {
    state.employeesModal.availableEmployeesList = payload
  },
  SET_VALIDATION_ERRORS_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.ValidationError[]
  ) {
    state.validationErrors = payload
  },
  RESET_VALIDATION_ERRORS_MUTATION(state: any) {
    state.validationErrors = []
  },
  RESET_VALIDATION_ERROR_MUTATION(state: any, payload: string) {
    const idx = state.validationErrors.findIndex(
      (obj: any) => obj.PropertyName === payload
    )
    if (idx > -1) {
      state.validationErrors.splice(idx, 1)
    }
  },
  SET_CONNECTABLE_ORGANIZATIONS_MUTATION(state: any, payload: any) {
    state.organizationsModal.originalOrganizationsList = payload
    state.organizationsModal.availableOrganizationsList = payload
  },
  RESET_SELECTED_ORGANIZATIONS_MUTATION(state: any) {
    state.organizationsModal.selectedOrganizationsList = []
  },
  UPDATE_AVAILABLE_CONNECTABLE_ORGANIZATIONS_MUTATION(state: any, payload: object[]) {
    state.organizationsModal.availableOrganizationsList = payload
  },
  UPDATE_ORGANIZATIONS_LIST_MUTATION(state: any, payload: any) {
    if (state.organizationsModal.availableOrganizationsList.indexOf(payload) > -1) {
      state.organizationsModal.availableOrganizationsList = state.organizationsModal.availableOrganizationsList.filter(
        (item: any) => item !== payload
      )
    } else {
      state.organizationsModal.availableOrganizationsList.push(payload)
    }
    if (state.organizationsModal.selectedOrganizationsList.indexOf(payload) > -1) {
      state.organizationsModal.selectedOrganizationsList = state.organizationsModal.selectedOrganizationsList.filter(
        (item: any) => item !== payload
      )
    } else {
      state.organizationsModal.selectedOrganizationsList.push(payload)
    }
  },
  SET_COUNTRIES_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.Countries[]
  ) {
    state.countries = payload
  },
  SET_CONNECT_URL(state: any, payload: string) {
    state.connectUrl = payload;
  }
}
