<template>
  <ul class="c-list p-2" :class="{ 'c-list--is-interactive': interactive }">
    <template v-if="interactive">
      <li
        class="c-list__item p-2"
        v-for="(item, index) in items"
        :key="index"
        @click="itemClick(item)"
      >
        {{ item[property] }}
      </li>
    </template>
    <template v-else>
      <li class="c-list__item p-2" v-for="(item, index) in items" :key="index">
        {{ item[property] }}
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  name: 'maz-list',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    interactive: {
      type: Boolean,
      default: false
    },
    property: {
      type: String,
      default: 'FullName'
    }
  },
  methods: {
    itemClick(item) {
      this.$emit('item-click', item)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/index.scss';
.c-list {
  max-height: 270px;
  overflow: auto;
  .c-list__item {
    list-style: none;
  }
  .c-list__item:not(:first-child) {
    border-top: 1px solid $mid-steel-blue;
  }
}
.c-list--is-interactive {
  @extend .c-list;
  .c-list__item {
    cursor: pointer;
    transition: color 0.5s, background-color 0.51s;
    &:hover {
      color: $blue;
      background-color: $light-steel-blue;
    }
  }
}
</style>
