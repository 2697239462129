<template>
  <div>
    <maz-workflow-details-employee v-if="isEmployeePortal" />
    <maz-workflow-details-client v-if="isClientPortal" />
  </div>
</template>

<script>
import Vue from 'vue'
import mazWorkflowDetailsEmployee from '@/components/custom/mazWorkflowDetails/employee.vue'
import mazWorkflowDetailsClient from '@/components/custom/mazWorkflowDetails/client.vue'

export default Vue.extend({
  name: "workflowDetailPage",

  components: {
    mazWorkflowDetailsEmployee,
    mazWorkflowDetailsClient
  },

  computed: {
    isClientPortal() {
      return window.__INITIAL_DATA__?.portalType === 'Client'
    },
    isEmployeePortal() {
      return window.__INITIAL_DATA__?.portalType === 'Employee'
    }
  }
})
</script>
