import Api from '../../api'
import Services from '../../services'

const _isEmployeePortal = Api.config.portalType === 'Employee' ? true : false
const _portController = _isEmployeePortal
  ? 'SupplyRequest2EmployeeJson'
  : 'SupplyRequest2ClientJson'
const _baseEndpoint = Api.config.endpointBase + _portController + '/'
const _targetApi = Api.supplyRequest2EditApi

export default {
  namespaced: true,
  state: {
    id: Services.helpersService.getSearchId('?srid=', window.location.search),
    exists: false,
    notFoundMessage: false,
    supplyRequestEditHeader: {
      title: '',
      client: {
        name: '',
        url: ''
      },
      state: {
        code: null,
        label: ''
      },
      actionIcons: {
        labels: {
          cloneSupplyRequestTitle: Services.dictionaryService.translate(
            'Labels.SupplyRequest.Clone.SelectCustomer.Title'
          ),
          cloneSupplyRequestIntro: Services.dictionaryService.translate(
            'Labels.SupplyRequest.Clone.SelectCustomer.Intro'
          ),
          cloneSupplyRequestButton: Services.dictionaryService.translate(
            'Labels.SupplyRequest.Clone.SelectCustomer.Button.Clone'
          ),
          cloneSupplyRequestSearchHint: Services.dictionaryService.translate(
            'Labels.SupplyRequest.Clone.SelectCustomer.SearchHint'
          ),
          cloneSupplyRequestRolloverTitle: Services.dictionaryService.translate(
            'Labels.SupplyRequest.Clone.RollOver.Title'
          ),
          cloneSupplyRequestRolloverIntro: Services.dictionaryService.translate(
            'Labels.SupplyRequest.Clone.RollOver.Intro'
          ),
          cloneSupplyRequestRolloverButton: Services.dictionaryService.translate(
            'Labels.SupplyRequest.Clone.Button.Clone'
          )
        }
      }
    },
    supplyRequestAudit: {
      type: '',
      financialYear: '',
      dueDateLabel: '',
      dueDate: '',
      dueDateOriginal: '',
      labels: {
        showAuditLogsLabel: Services.dictionaryService.translate(
          'RequestAuditTrailViewerLightbox.Labels.ButtonShowAll'
        ),
        exportAuditLogsLabel: Services.dictionaryService.translate(
          'RequestAuditTrailViewerDropdown.Labels.Button.Export'
        ),
        createSignalLabel: Services.dictionaryService.translate('Reminder.Labels.Create'),
        titleSendSignalLabel: Services.dictionaryService.translate(
          'Reminder.Labels.Title'
        ),
        subTitleSendSignalLabel: Services.dictionaryService.translate(
          'Reminder.Labels.SubTitle'
        ),
        emailFieldLabel: Services.dictionaryService.translate('Reminder.Labels.Subject'),
        emailTextFieldLabel: Services.dictionaryService.translate(
          'Reminder.Labels.EmailText'
        ),
        cancelLabel: Services.dictionaryService.translate('Labels.Common.Cancel')
      },
      exportInfo: {
        mayExport: false,
        exportUrl: ''
      },
      logs: [],
      exists: false
    },
    supplyRequestDescription: {
      description: '',
      labels: {
        showLabel: Services.dictionaryService.translate('Labels.Common.ShowLess'),
        hideLabel: Services.dictionaryService.translate('Labels.Common.ShowMore')
      }
    },
    supplyRequestFinancialYears: {
      financialYear: '',
      financialYears: []
    },
    supplyRequestCategories: {
      categories: [],
      categoryDeleted: false,
      categoryAddedId: '',
      segmentAddedId: '',
      activeCategoryId: ''
    },
    supplyRequestPermissions: {
      isEmployeePortal: _isEmployeePortal,
      labels: {
        submitSupply: Services.dictionaryService.translate(
          'SupplyRequestView.Labels.SubmitSupply'
        ),
        submitAll: Services.dictionaryService.translate(
          'SupplyRequestDetail.Labels.SubmitAll'
        ),
        approve: Services.dictionaryService.translate(
          'SupplyRequestView.Labels.ApproveButton'
        ),
        approveHover: Services.dictionaryService.translate(
          'SupplyRequestView.Labels.ApproveButtonHover'
        ),
        reject: Services.dictionaryService.translate(
          'SupplyRequestView.Labels.RejectButton'
        ),
        rejectHover: Services.dictionaryService.translate(
          'SupplyRequestView.Labels.RejectButtonHover'
        ),
        rejectDateFieldLabel: Services.dictionaryService.translate(
          'SupplyRequestView.Labels.SelectDate'
        ),
        submitMotiveTitle: Services.dictionaryService.translate(
          'SupplyRequestEdit.Labels.SubmitMotiveTitle'
        ),
        submitMotivePlaceholder: Services.dictionaryService.translate(
          'SupplyRequestEdit.Labels.SubmitMotive'
        ),
        categoriesNotClose: Services.dictionaryService.translate(
          'Messages.SupplyRequest.CategoriesNotClose'
        )
      },
      mayClone: false,
      mayDelete: false
    },
    editDetailsSaved: false,
    sendToClient: false,
    sendToClientButtonDisabled: true,
    sendToClientRedirect: '',
    labels: {
      cancel: Services.dictionaryService.translate('Labels.Common.Cancel'),
      save: Services.dictionaryService.translate('Labels.Common.Save'),
      confirm: Services.dictionaryService.translate('Labels.Common.Confirm'),
      showLabel: Services.dictionaryService.translate('Labels.Common.ShowLess'),
      hideLabel: Services.dictionaryService.translate('Labels.Common.ShowMore'),

      supplyRequestNotExistsTitle: Services.dictionaryService.translate(
        'Messages.SupplyRequest.NotFoundTitle'
      ),
      supplyRequestNotExistsText: Services.dictionaryService.translate(
        'Messages.SupplyRequest.NotFoundText'
      ),
      supplyRequestEditTitle: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.Title'
      ),
      supplyRequestEditDescription: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.Description'
      ),
      supplyRequestEditFinancialYear: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.FinancialYear'
      ),
      supplyRequestEditDueDate: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.DueDate'
      ),
      supplyRequestEditCategoryTitle: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.Category.Title'
      ),
      supplyRequestEditCategoryShortTitle: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.Category.ShortTitle'
      ),
      supplyRequestEditAddCategory: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.AddCategory.Title'
      ),
      supplyRequestConfirmLostChangesCategoryTitle: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.DeleteSupplyRequestCategory.Title'
      ),
      supplyRequestConfirmLostChangesCategory: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.DeleteSupplyRequestCategory.Message'
      ),
      supplyRequestConfirmLostChangesSubCategoryTitle: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.DeleteSupplyRequestSubCategory.Title'
      ),
      supplyRequestConfirmLostChangesSubCategory: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.DeleteSupplyRequestSubCategory.Message'
      ),
      supplyRequestEditCategoryEditTitle: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.EditCategory.Button'
      ),
      supplyRequestEditAddSubCategory: Services.dictionaryService.translate(
        'SuppyRequestEdit.Labels.AddSubCategory'
      ),
      supplyRequestEditSubCategoryEditTitle: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.EditSubCategory.Button'
      ),
      supplyRequestEditSegmentDescription: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.Segment.Description'
      ),
      supplyRequestEditAddSegment: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.AddSegment.Title'
      ),
      supplyRequestEditAddListItem: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.AddSegmentItem.Button'
      ),
      supplyRequestSendToClientButton: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.SendSupplyRequest.Button'
      ),
      supplyRequestSendToClientSucceeded: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.SendToClientSucceeded'
      ),
      supplyRequestSendToClientSucceededTitle: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.SendToClientSucceededTitle'
      ),
      supplyRequestSendToClientSucceededButton: Services.dictionaryService.translate(
        'SupplyRequestEdit.Labels.SendToClientSucceededButton'
      ),
      supplyRequestEditResponsibleContacts: Services.dictionaryService.translate(
        'Labels.SupplyRequest.EditResponsibleUsers'
      ),
      supplyRequestSelectedResponsibleContacts: Services.dictionaryService.translate(
        'Labels.SupplyRequest.SelectedResponsibleUsers'
      ),
      supplyRequestResponsibleUsersErrorMessage: Services.dictionaryService.translate(
        'Labels.SupplyRequest.ResponsibleUsersErrorMessage'
      ),
      supplyRequestResponsibleUsersErrorLink: Services.dictionaryService.translate(
        'Labels.SupplyRequest.ResponsibleUsersErrorLink'
      ),
      supplyRequestDownloadAllFilesInProgress: Services.dictionaryService.translate(
        'SupplyRequestView.Messages.DownloadAllFilesInProgress'
      ),
      supplyRequestConfirmationDeleteSupplyRequestTitle: Services.dictionaryService.translate(
        'SupplyRequestView.ConfirmationDeleteSupplyRequestTitle'
      ),
      supplyRequestConfirmationDeleteSupplyRequest: Services.dictionaryService.translate(
        'SupplyRequestView.ConfirmationDeleteSupplyRequest'
      )
    },
    supplyRequestCustomers: [],
    supplyRequestAssignContacts: {
      contactsList: [],
      assignedContactsList: []
    },
    supplyRequestFileUploader: {
      labels: {
        dropFiles: Services.dictionaryService.translate('FileUploader.Labels.DropFiles'),
        addTemplate: Services.dictionaryService.translate(
          'FileUploader.Labels.AddTemplate'
        ),
        fileProcessing: Services.dictionaryService.translate(
          'FileUploader.Labels.FileProcessing'
        ),
        fileNotAllowed: Services.dictionaryService.translate(
          'FileUploader.Labels.FileNotAllowed'
        ),
        fileProcessingComplete: Services.dictionaryService.translate(
          'FileUploader.Labels.FileProcessingComplete'
        ),
        fileProcessingError: Services.dictionaryService.translate(
          'FileUploader.Labels.FileProcessingError'
        ),
        fileLoadError: Services.dictionaryService.translate(
          'FileUploader.Labels.FileLoadError'
        ),
        fileLoading: Services.dictionaryService.translate(
          'FileUploader.Labels.FileLoading'
        ),
        fileProcessingAborted: Services.dictionaryService.translate(
          'FileUploader.Labels.FileProcessingAborted'
        ),
        fileProcessingRevertError: Services.dictionaryService.translate(
          'FileUploader.Labels.FileProcessingRevertError'
        ),
        fileRemoveError: Services.dictionaryService.translate(
          'FileUploader.Labels.FileRemoveError'
        ),
        fileSizeNotAvailable: Services.dictionaryService.translate(
          'FileUploader.Labels.FileSizeNotAvailable'
        ),
        invalidField: Services.dictionaryService.translate(
          'FileUploader.Labels.InvalidField'
        ),
        fileWaitingForSize: Services.dictionaryService.translate(
          'FileUploader.Labels.FileWaitingForSize'
        ),
        maxFileSizeExceeded: Services.dictionaryService.translate(
          'FileUploader.Labels.MaxFileSizeExceeded'
        )
      },
      fileDeleted: false,
      chunkSize: 0,
      maxFileSize: 0,
      fileUploadExtensionsCsv: ''
    }
  },
  getters: {
    ID: state => {
      return state.id
    },
    EXISTS: state => {
      return state.exists
    },
    NOT_FOUND_MESSAGE: state => {
      return state.notFoundMessage
    },
    SUPPLY_REQUEST_PERMISSIONS: state => {
      return state.supplyRequestPermissions
    },
    SUPPLY_REQUEST_AUDIT: state => {
      return state.supplyRequestAudit
    },
    SUPPLY_REQUEST_EDIT_HEADER: state => {
      return state.supplyRequestEditHeader
    },
    SUPPLY_REQUEST_DESCRIPTION: state => {
      return state.supplyRequestDescription
    },
    SUPPLY_REQUEST_FINANCIAL_YEARS: state => {
      return state.supplyRequestFinancialYears
    },
    SUPPLY_REQUEST_EDIT_DETAILS_SAVED: state => {
      return state.editDetailsSaved
    },
    SUPPLY_REQUEST_EDIT_CATEGORIES: state => {
      return state.supplyRequestCategories
    },
    SUPPLY_REQUEST_CATEGORY_DELETED: state => {
      return state.supplyRequestCategories.categoryDeleted
    },
    SUPPLY_REQUEST_EDIT_SEGMENT_ADDED_ID: state => {
      return state.supplyRequestCategories.segmentAddedId
    },
    SUPPLY_REQUEST_SEND_TO_CLIENT: state => {
      return state.sendToClient
    },
    SUPPLY_REQUEST_SEND_TO_CLIENT_REDIRECT: state => {
      return state.sendToClientRedirect
    },
    SUPPLY_REQUEST_SEND_TO_CLIENT_BUTTON_DISABLED_STATUS: state => {
      return state.sendToClientButtonDisabled
    },
    SUPPLY_REQUEST_FILE_UPLOADER: state => {
      return state.supplyRequestFileUploader
    },
    SUPPLY_REQUEST_ASSIGN_CONTACTS: state => {
      return state.supplyRequestAssignContacts
    },
    SUPPLY_REQUEST_CATEGORY_ADDED_ID: state => {
      return state.supplyRequestCategories.categoryAddedId
    },
    SUPPLY_REQUEST_CUSTOMERS: state => {
      return state.supplyRequestCustomers
    },
    LABELS: state => {
      return state.labels
    }
  },
  actions: {
    GET_SUPPLY_REQUEST({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getEditSupplyRequestInitialData,
        '{supplyRequestId}',
        payload
      )

      Services.axiosService.get(endpoint, res => {
        if (res.SupplyRequestExists === true) {
          if (res.RedirectUrl === '' || res.RedirectUrl === null) {
            commit('SET_SUPPLY_REQUEST_PERMISSIONS', res)
            commit('SET_SUPPLY_REQUEST_HEADER', res)
            commit('SET_SUPPLY_REQUEST_AUDIT', res)
            commit('SET_SUPPLY_REQUEST_DESCRIPTION', res)
            commit('SET_SUPPLY_REQUEST_FINANCIAL_YEARS', res)
            commit('SET_SUPPLY_REQUEST_FILE_SETTINGS', res)
            commit('SET_EXISTS', true)

            dispatch('GET_CATEGORIES_OF_SUPPLY_REQUEST', res.Id)
          } else {
            window.location.href = res.RedirectUrl
          }
        } else {
          commit('SET_NOT_FOUND_MESSAGE', true)
        }
      })
    },
    GET_CATEGORIES_OF_SUPPLY_REQUEST({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getSupplyRequestCategories,
        '{supplyRequestId}',
        payload
      )

      const body = {
        SupplyRequestId: '',
        CategoryId: ''
      }

      Services.axiosService.get(endpoint, res => {
        commit('SET_SUPPLY_REQUEST_CATEGORIES', res)

        if (res.Categories.length > 0) {
          body.SupplyRequestId = res.SupplyRequestId
          body.CategoryId = res.Categories[0].Id

          dispatch('GET_CATEGORY_BY_ID', body)
        }
      })
    },
    GET_CATEGORIES_OF_SUPPLY_REQUEST_AFTER_EDIT({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getSupplyRequestCategories,
        '{supplyRequestId}',
        payload.SupplyRequestId
      )

      const body = {
        SupplyRequestId: payload.SupplyRequestId,
        CategoryId: payload.CategoryId
      }

      Services.axiosService.get(endpoint, res => {
        commit('SET_SUPPLY_REQUEST_CATEGORIES', res)

        dispatch('GET_CATEGORY_BY_ID', body)
      })
    },
    GET_CATEGORIES_OF_SUPPLY_REQUEST_ONLY({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getSupplyRequestCategories,
        '{supplyRequestId}',
        payload.SupplyRequestId
      )

      const body = {
        SupplyRequestId: payload.SupplyRequestId,
        CategoryId: payload.CategoryId,
        CategoryAdded: true
      }

      Services.axiosService.get(endpoint, res => {
        commit('SET_SUPPLY_REQUEST_CATEGORIES', res)

        // Navigate to the category that was added new
        dispatch('GET_CATEGORY_BY_ID', body)
      })
    },
    GET_CATEGORY_BY_ID({ commit }, payload) {
      let endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getSupplyRequestCategoryById
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{supplyRequestId}',
        payload.SupplyRequestId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{categoryId}',
        payload.CategoryId
      )

      Services.axiosService.get(endpoint, res => {
        commit('UPDATE_SUPPLY_REQUEST_CATEGORY', res)
        commit('UPDATE_SUPPLY_REQUEST_CATEGORY_SET_SELECTED', res.CategoryId)

        if (payload.CategoryAdded === true) {
          // Only execute if a new category is added
          commit('SET_CATEGORY_ADDED_ID', payload)
        }
      })
    },
    POST_SUPPLY_REQUEST_DETAILS({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.updateSupplyRequest
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        Title: payload.title,
        Description: payload.description,
        FinancialYear: payload.financialYear,
        DueDate: payload.dueDate
      }

      Services.axiosService.put(endpoint, body, () => {
        commit('SET_DETAILS_SAVED', true)

        const body = {
          SupplyRequestId: payload.supplyRequestId,
          CategoryId: payload.activeCategoryId
        }

        dispatch('GET_SUPPLY_REQUEST', body.SupplyRequestId)
        dispatch('GET_CATEGORIES_OF_SUPPLY_REQUEST_AFTER_EDIT', body)
      })
    },
    SET_DETAILS_SAVED({ commit }, payload) {
      commit('SET_DETAILS_SAVED', payload)
    },
    GET_CUSTOMERS({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getCustomers,
        '{searchTerm}',
        payload
      )

      Services.axiosService.get(endpoint, res => {
        commit('UPDATE_SUPPLY_REQUEST_CUSTOMERS', res)
      })
    },
    POST_COPY_SUPPLY_REQUEST(_, payload) {
      let endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.postCopySupplyRequest
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{supplyRequestId}',
        payload.SupplyRequestId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{customerId}',
        payload.CustomerId
      )

      Services.axiosService.post(endpoint, null, res => {
        window.location.href = res.RedirectUrl
      })
    },
    POST_CLONE_SUPPLY_REQUEST(_, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.postCloneSupplyRequest,
        '{supplyRequestId}',
        payload
      )

      Services.axiosService.post(endpoint, null, res => {
        window.location.href = res.RedirectUrl
      })
    },
    POST_CATEGORY({ dispatch }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.addCategory
      )

      const body = {
        SupplyRequestId: payload.id,
        CategoryId: payload.categoryId,
        Title: payload.title,
        ShortTitle: payload.shortTitle,
        Position: payload.position
      }

      Services.axiosService.post(endpoint, body, res => {
        dispatch('GET_CATEGORIES_OF_SUPPLY_REQUEST_ONLY', res)
      })
    },
    DELETE_CATEGORY({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.deleteCategory
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId
      }

      Services.axiosService.put(endpoint, body, res => {
        commit('UPDATE_CATEGORY_DELETED', true)

        dispatch('GET_CATEGORIES_OF_SUPPLY_REQUEST', res.SupplyRequestId)
      })
    },
    EDIT_CATEGORY({ dispatch }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.updateCategory
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        Title: payload.title,
        ShortTitle: payload.shortTitle
      }

      Services.axiosService.put(endpoint, body, () => {
        dispatch('GET_CATEGORIES_OF_SUPPLY_REQUEST_AFTER_EDIT', body)
      })
    },
    POST_SUBCATEGORY({ dispatch }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.addSubCategory
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.subCategoryId,
        Title: payload.title,
        Position: payload.position
      }

      Services.axiosService.post(endpoint, body, res => {
        const body = {
          SupplyRequestId: res.SupplyRequestId,
          CategoryId: res.CategoryId
        }

        dispatch('GET_CATEGORY_BY_ID', body)
      })
    },
    EDIT_SUBCATEGORY({ dispatch }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.updateSubCategory
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.groupSegmentId,
        Title: payload.title
      }

      Services.axiosService.put(endpoint, body, res => {
        const body = {
          SupplyRequestId: res.SupplyRequestId,
          CategoryId: res.CategoryId
        }

        dispatch('GET_CATEGORY_BY_ID', body)
      })
    },
    DELETE_SUBCATEGORY({ dispatch }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.deleteSubCategory
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.groupSegmentId
      }

      Services.axiosService.put(endpoint, body, res => {
        const body = {
          SupplyRequestId: res.SupplyRequestId,
          CategoryId: res.CategoryId
        }

        dispatch('GET_CATEGORY_BY_ID', body)
      })
    },
    SET_ORDERED_SEGMENTS({ commit }, payload) {
      commit('UPDATE_ORDERED_SEGMENTS', payload)
    },
    SET_ORDERED_SUBCATEGORIES({ commit }, payload) {
      commit('UPDATE_ORDERED_SUBCATEGORIES', payload)
    },
    ADD_SEGMENT({ commit }, payload) {
      commit('UPDATE_SEGMENTS', payload)
    },
    ADD_SEGMENT_IN_BETWEEN({ commit }, payload) {
      commit('UPDATE_SEGMENTS_IN_BETWEEN', payload)
    },
    POST_ADDED_SEGMENT({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.addSegment
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.groupSegmentId,
        SegmentId: payload.segmentId,
        Title: payload.clone.title,
        Description: payload.clone.description,
        HelpText: payload.helpText,
        SegmentItems: payload.clone.segmentItems,
        Position: payload.position
      }

      Services.axiosService.post(endpoint, body, res => {
        const body = {
          SupplyRequestId: res.SupplyRequestId,
          CategoryId: res.CategoryId
        }

        const segmentData = {
          segmentData: payload,
          segmentId: res.SegmentId
        }

        dispatch('GET_CATEGORY_BY_ID', body)
        commit('SET_DATA_ADDED_SEGMENT', segmentData)
      })
    },
    UPDATE_THE_SEGMENT({ dispatch, commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.updateSegment
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.groupSegmentId,
        SegmentId: payload.segmentId,
        Title: payload.clone.title,
        Description: payload.clone.description,
        HelpText: payload.helpText,
        SegmentItems: payload.clone.segmentItems
      }

      Services.axiosService.put(endpoint, body, res => {
        const body = {
          SupplyRequestId: res.SupplyRequestId,
          CategoryId: res.CategoryId
        }

        const segmentData = {
          segmentData: payload,
          segmentId: res.SegmentId
        }

        dispatch('GET_CATEGORY_BY_ID', body)
        commit('SET_DATA_UPDATED_SEGMENT', segmentData)
      })
    },
    DELETE_SEGMENT({ dispatch }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.deleteSegment
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.groupSegmentId,
        SegmentId: payload.segmentId
      }

      Services.axiosService.put(endpoint, body, res => {
        const body = {
          SupplyRequestId: res.SupplyRequestId,
          CategoryId: res.CategoryId
        }

        dispatch('GET_CATEGORY_BY_ID', body)
      })
    },
    RESET_CATEGORY_DELETED({ commit }, payload) {
      commit('UPDATE_CATEGORY_DELETED', payload)
    },
    SET_CANCELLED({ commit }, payload) {
      commit('UPDATE_CANCELLED', payload)
    },
    POST_SEND_SUPPLY_REQUEST_TO_CLIENT({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.activateSupplyRequest
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        FinancialYear: payload.financialYear,
        DueDate: payload.dueDate
      }

      Services.axiosService.put(endpoint, body, res => {
        if (res) {
          commit('UPDATE_SEND_TO_CLIENT_STATUS', true)
          commit('UPDATE_SEND_TO_CLIENT_BUTTON', false)
          commit('SET_SEND_TO_CLIENT_REDIRECT', res.RedirectUrl)
        }
      })
    },
    RESET_SEGMENT_ADDED_ID({ commit }, payload) {
      commit('RESET_SEGMENT_ADDED_ID', payload)
    },
    GET_UPDATED_SEGMENT_FILES({ commit }, payload) {
      let endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getAttachmentOverviewBySegmentId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{supplyRequestId}',
        payload.supplyRequestId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{categoryId}',
        payload.categoryId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{groupSegmentId}',
        payload.subCategoryId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{segmentId}',
        payload.segmentId
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        GroupSegmentId: payload.subCategoryId,
        SegmentId: payload.segmentId
      }

      const segmentData = body

      Services.axiosService.post(endpoint, body, res => {
        segmentData.Attachments = res.Attachments
        commit('UPDATE_SUPPLY_REQUEST_SEGMENT_FILES', segmentData)
        commit('RESET_DELETED_FILE', segmentData)
        commit('UPDATE_INITIAL_FILES_NUMBER', segmentData)
      })
    },
    DELETE_FILE({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.deleteAttachment,
        '{supplyRequestFileId}',
        payload.attachmentId
      )

      Services.axiosService.delete(endpoint, () => {
        commit('UPDATE_DELETED_FILE')
      })
    },
    UPDATE_ASSIGN_CONTACTS({ commit }, payload) {
      commit('UPDATE_ASSIGN_CONTACTS', payload)
    },
    POST_ASSIGN_CONTACTS({ dispatch }, payload) {
      //Due to the API endpoint being used on other places we are not adding the API endpoint to the "_targetApi" in order to reduce duplicated code
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + Api.assignContactsApi.updateAssignedContacts
      )

      const body = {
        supplyRequestId: payload.supplyRequestId,
        assignContactsIds: payload.assignContactsIds
      }

      Services.axiosService.post(endpoint, body, res => {
        if (res) {
          dispatch('GET_ASSIGN_CONTACTS', payload)
        } else {
          //TODO: show toastmessage
        }
      })
    },
    GET_ASSIGN_CONTACTS({ commit }, payload) {
      //Due to the API endpoint being used on other places we are not adding the API endpoint to the "_targetApi" in order to reduce duplicated code
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + Api.assignContactsApi.getContacts,
        '{supplyRequestId}',
        payload.supplyRequestId
      )

      Services.axiosService.get(endpoint, res => {
        if (res) {
          const body = {
            contactsList: Services.assignContactsService.processContactsList(res),
            assignedContactsList: Services.assignContactsService.processAssignedContactsList(
              res
            )
          }

          commit('UPDATE_ASSIGN_CONTACTS', body)
        } else {
          //TODO: show toastmessage
        }
      })
    },
    SET_ORDER_OF_SUBCATEGORIES(_, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.moveGroupSegment
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        ToPosition: payload.toPosition,
        GroupSegmentId: payload.groupSegmentId
      }

      Services.axiosService.put(endpoint, body)
    },
    SET_ORDER_OF_SEGMENTS(_, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.moveSegment
      )

      const body = {
        SupplyRequestId: payload.supplyRequestId,
        CategoryId: payload.categoryId,
        ToPosition: payload.toPosition,
        GroupSegmentId: payload.groupSegmentId,
        ToGroupSegmentId: payload.toGroupSegmentId,
        SegmentId: payload.segmentId
      }

      Services.axiosService.put(endpoint, body)
    },
    DELETE_SUPPLY_REQUEST(_, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.deleteSupplyRequest,
        '{supplyRequestId}',
        payload.supplyRequestId
      )

      Services.axiosService.delete(endpoint, res => {
        if (res.ActionResult === true) {
          window.location.href = res.RedirectUrl
        }
      })
    }
  },
  mutations: {
    SET_EXISTS: (state, payload) => {
      state.exists = payload
    },
    SET_NOT_FOUND_MESSAGE: (state, payload) => {
      state.notFoundMessage = payload
    },
    SET_SUPPLY_REQUEST_PERMISSIONS: (state, payload) => {
      state.supplyRequestPermissions.mayClone = payload.MayClone
      state.supplyRequestPermissions.mayDelete = payload.MayDelete
    },
    SET_SUPPLY_REQUEST_HEADER: (state, payload) => {
      state.supplyRequestEditHeader.title = payload.Title
      state.supplyRequestEditHeader.client.name = payload.Customer.Name
      state.supplyRequestEditHeader.client.url = payload.Customer.Url
      state.supplyRequestEditHeader.state.code = payload.Status.toString()
      state.supplyRequestEditHeader.state.label =
        Services.processStatusService.Properties[payload.Status].translation
    },
    SET_SUPPLY_REQUEST_AUDIT: (state, payload) => {
      state.supplyRequestAudit.type = payload.Phases[0].Name + ' - '
      state.supplyRequestAudit.financialYear = payload.FinancialYear.toString()
      state.supplyRequestAudit.dueDateLabel =
        Services.dictionaryService.translate('SupplyRequestView.Labels.RequestDueOn') +
        ': '
      const dueDateOffset = Services.momentService(payload.DueDate).utcOffset()
      const localDueDate = Services.momentService(payload.DueDate)
        .subtract(dueDateOffset, 'minutes')
        .format(Services.helpersService.momentDateFormat)
      state.supplyRequestAudit.dueDateOriginal = localDueDate
      state.supplyRequestAudit.dueDate = localDueDate
    },
    SET_SUPPLY_REQUEST_DESCRIPTION: (state, payload) => {
      state.supplyRequestDescription.description = payload.Description
    },
    SET_SUPPLY_REQUEST_FINANCIAL_YEARS: (state, payload) => {
      state.supplyRequestFinancialYears.financialYear = payload.FinancialYear
      state.supplyRequestFinancialYears.financialYears = Services.supplyRequest2EditService.processFinancialYears(
        payload
      )
    },
    SET_SUPPLY_REQUEST_FILE_SETTINGS: (state, payload) => {
      state.supplyRequestFileUploader.chunkSize = payload.ChunkSize
      state.supplyRequestFileUploader.maxFileSize = payload.MaxFileSize
      state.supplyRequestFileUploader.fileUploadExtensionsCsv =
        payload.FileUploadExtensionsCsv
    },
    SET_DETAILS_SAVED: (state, payload) => {
      state.editDetailsSaved = payload
    },
    SET_SUPPLY_REQUEST_CATEGORIES: (state, payload) => {
      state.supplyRequestCategories.categories = Services.supplyRequest2EditService.processCategories(
        state.id,
        payload
      )
    },
    UPDATE_SUPPLY_REQUEST_CATEGORY: (state, payload) => {
      Services.supplyRequest2EditService.updateCategory(
        state.supplyRequestCategories.categories,
        payload
      )
    },
    UPDATE_SUPPLY_REQUEST_CATEGORY_WITH_ATTACHMENTS: (state, payload) => {
      Services.supplyRequest2EditService.updateCategoryWithAttachments(
        state.supplyRequestCategories.categories,
        payload
      )
    },
    UPDATE_SUPPLY_REQUEST_NAVIGATION_TARGET_SEGMENT: (state, payload) => {
      state.supplyRequestNavigation.targetSegment = payload
    },
    UPDATE_CATEGORY_DELETED: (state, payload) => {
      state.supplyRequestCategories.categoryDeleted = payload
    },
    UPDATE_SEND_TO_CLIENT_STATUS: (state, payload) => {
      state.sendToClient = payload
    },
    UPDATE_SEND_TO_CLIENT_BUTTON: (state, payload) => {
      state.sendToClientButtonDisabled = payload
    },
    SET_SEND_TO_CLIENT_REDIRECT: (state, payload) => {
      state.sendToClientRedirect = payload
    },
    UPDATE_SEGMENTS: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.category.id.original) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories.findIndex(
        subCategory => subCategory.id.original === payload.subCategory.id.original
      )

      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments.push(payload.segmentData)
      state.supplyRequestCategories.segmentAddedId = payload.segmentData.id

      return
    },
    UPDATE_SEGMENTS_IN_BETWEEN: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.category.id.original) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories.findIndex(
        subCategory => subCategory.id.original === payload.subCategory.id.original
      )

      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments.splice(payload.indexPosition, 0, payload.segmentData)
      state.supplyRequestCategories.segmentAddedId = payload.segmentData.id

      return
    },
    SET_DATA_UPDATED_SEGMENT: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.segmentData.categoryId) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories.findIndex(
        subCategory => subCategory.id.original === payload.segmentData.groupSegmentId
      )
      const segmentIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories[subCategoryIndex].segments.findIndex(
        segment => segment.id.original === payload.segmentId
      )

      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].title = payload.segmentData.clone.title
      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].description = payload.segmentData.clone.description
      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].segmentItems = payload.segmentData.clone.segmentItems

      return
    },
    SET_DATA_ADDED_SEGMENT: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.segmentData.categoryId) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories.findIndex(
        subCategory => subCategory.id.original === payload.segmentData.groupSegmentId
      )
      const segmentIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories[subCategoryIndex].segments.findIndex(
        segment => segment.id === payload.segmentId
      )

      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].title = payload.segmentData.clone.title
      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].description = payload.segmentData.clone.description
      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].segmentItems = payload.segmentData.clone.segmentItems

      return
    },
    UPDATE_CANCELLED: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.categoryId.original) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories.findIndex(
        subCategory => subCategory.id.original === payload.subCategoryId.original
      )
      const segmentIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories[subCategoryIndex].segments.findIndex(
        segment => segment.id.original === payload.segment.id.original
      )

      if (
        state.supplyRequestCategories.categories[categoryIndex].subCategories[
          subCategoryIndex
        ].segments[segmentIndex].title === '' &&
        state.supplyRequestCategories.categories[categoryIndex].subCategories[
          subCategoryIndex
        ].segments[segmentIndex].description === '' &&
        state.supplyRequestCategories.categories[categoryIndex].subCategories[
          subCategoryIndex
        ].segments[segmentIndex].segmentItems.length === 0
      ) {
        const index = state.supplyRequestCategories.categories[
          categoryIndex
        ].subCategories[subCategoryIndex].segments.findIndex(
          segment => segment.id === payload.segmentId
        )
        state.supplyRequestCategories.categories[categoryIndex].subCategories[
          subCategoryIndex
        ].segments.splice(index, 1)
      }
    },
    RESET_SEGMENT_ADDED_ID: state => {
      state.supplyRequestCategories.segmentAddedId = ''
    },
    UPDATE_ASSIGN_CONTACTS: (state, payload) => {
      state.supplyRequestAssignContacts.contactsList = payload.contactsList
      state.supplyRequestAssignContacts.assignedContactsList =
        payload.assignedContactsList
    },
    UPDATE_SUPPLY_REQUEST_CUSTOMERS: (state, payload) => {
      const dataSet = state.supplyRequestCustomers
      const result = Services.supplyRequest2DetailService.processCustomers(
        dataSet,
        payload
      )

      state.supplyRequestCustomers = result
    },
    UPDATE_DELETED_FILE: state => {
      state.supplyRequestFileUploader.fileDeleted = true
    },
    RESET_DELETED_FILE: state => {
      state.supplyRequestFileUploader.fileDeleted = false
    },
    UPDATE_SUPPLY_REQUEST_SEGMENT_FILES: (state, payload) => {
      Services.supplyRequest2EditService.updateSegmentAttachments(
        state.supplyRequestCategories.categories,
        payload
      )
    },
    UPDATE_INITIAL_FILES_NUMBER: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.CategoryId) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories.findIndex(
        subCategory => subCategory.id.original === payload.GroupSegmentId
      )

      const segmentIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories[subCategoryIndex].segments.findIndex(
        segment => segment.id.original === payload.SegmentId
      )

      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].showInitialFilesNumber = false
      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments[segmentIndex].numberOfFiles =
        state.supplyRequestCategories.categories[categoryIndex].subCategories[
          subCategoryIndex
        ].segments[segmentIndex].attachments.length // Otherwise number isn't correct
    },
    UPDATE_SUPPLY_REQUEST_CATEGORY_SET_SELECTED: (state, payload) => {
      let activeCategoryId = ''

      state.supplyRequestCategories.categories.forEach(value => {
        if (value.id.original === payload) {
          activeCategoryId = value.id.original
        }

        return value.id.original
      })

      state.supplyRequestCategories.activeCategoryId = activeCategoryId
    },
    SET_CATEGORY_ADDED_ID: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.CategoryId) {
          categoryIndex = index
        }

        return index
      })

      // When a new category is added it should be immediately set to true
      state.supplyRequestCategories.categories[categoryIndex].isLoaded = true

      state.supplyRequestCategories.categoryAddedId = payload.CategoryId
    },
    UPDATE_ORDERED_SEGMENTS: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.category.id.original) {
          categoryIndex = index
        }

        return index
      })

      const subCategoryIndex = state.supplyRequestCategories.categories[
        categoryIndex
      ].subCategories.findIndex(
        subCategory => subCategory.id.original === payload.subCategory.id.original
      )

      state.supplyRequestCategories.categories[categoryIndex].subCategories[
        subCategoryIndex
      ].segments = payload.segments
    },
    UPDATE_ORDERED_SUBCATEGORIES: (state, payload) => {
      let categoryIndex = 0

      state.supplyRequestCategories.categories.forEach((value, index) => {
        if (value.id.original === payload.category.id.original) {
          categoryIndex = index
        }

        return index
      })

      state.supplyRequestCategories.categories[categoryIndex].subCategories =
        payload.subCategories
    }
  }
}
