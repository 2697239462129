const UserType = {
  10: {
    userType: 'Employee'
  },
  20: {
    userType: 'Client'
  },
  30: {
    userType: 'SharedUser'
  }
}

export default UserType
