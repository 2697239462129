<template>
  <div class="qa-wizard-template-preview">
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <h1 class="order-3 order-sm-1">{{ supplyRequestWizardTemplatePreview.title }}</h1>
      <maz-button
        :label="labels.supplyRequestAddLabelsSendTemplate"
        @click.native="createConcept"
        class="c-btn--green ml-sm-5 mt-0 order-1 order-sm-2"
      />
      <div class="o-separator o-separator--extra-small order-2 d-sm-none" />
    </div>

    <div class="o-separator o-separator--extra-small" />

    <div class="c-wizard-intro-text qa-wizard-intro-text c-intro-text">
      <div class="d-md-flex">
        <p
          :class="[
            'pr-md-5 flex-fill u-pre-wrap',
            { 'is-cutoff': !isExpanded },
            { 'c-intro-text--is-cutoff': hasMultipleLines && !isExpanded }
          ]"
          ref="descriptionTxt"
        >
          {{ supplyRequestWizardTemplatePreview.description }}
        </p>
        <a
          :class="[
            'e-link-show-more c-wizard-intro-text__toggle',
            { collapsed: !isExpanded }
          ]"
          @click="toggleDescription"
          v-if="hasMultipleLines"
        >
          <span class="e-link-bold" v-if="!isExpanded">{{ labels.showMore }}</span>
          <span class="e-link-bold" v-else>{{ labels.showLess }}</span>
          <span class="e-icon-toggle" />
        </a>
      </div>
    </div>

    <div class="o-separator o-separator--small" />

    <maz-wizard-sr-category-manager />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Services from '@/services'
import mazButton from '../../general/mazButton'
import mazWizardSRCategoryManager from '../mazWizardSRCategoryManager'

const _targetStore = 'supplyRequest2AddStore/'

export default {
  name: 'maz-wizard-sr-template-preview',
  components: {
    'maz-button': mazButton,
    'maz-wizard-sr-category-manager': mazWizardSRCategoryManager
  },
  data() {
    return {
      isActive: false,
      isExpanded: false,
      hasMultipleLines: false
    }
  },
  computed: {
    ...mapGetters({
      supplyRequestWizardTemplatePreview:
        _targetStore + 'SUPPLY_REQUEST_WIZARD_TEMPLATE_PREVIEW',
      supplyRequestWizardHeader: _targetStore + 'SUPPLY_REQUEST_WIZARD_HEADER',
      supplyRequestWizardDueDate: _targetStore + 'SUPPLY_REQUEST_WIZARD_DUE_DATE',
      supplyRequestWizardInitialFilters: _targetStore + 'SUPPLY_REQUEST_WIZARD_FILTERS',
      supplyRequestAssignContacts: _targetStore + 'SUPPLY_REQUEST_ASSIGN_CONTACTS',
      labels: _targetStore + 'LABELS'
    })
  },
  methods: {
    toggleDescription() {
      this.isExpanded = !this.isExpanded
    },
    checkLineBreaksInDescription() {
      const descriptionTxt = this.$refs.descriptionTxt
      const getLineHeight = window
        .getComputedStyle(descriptionTxt)
        .getPropertyValue('line-height')

      const getScrollHeight = this.$refs.descriptionTxt.scrollHeight
      const isMobile = window.innerWidth <= 576
      let displayedLines

      const numberOfLineBreaks = getScrollHeight / parseInt(getLineHeight)

      if (isMobile) {
        displayedLines = 2
      } else {
        displayedLines = 3
      }

      if (numberOfLineBreaks > displayedLines) {
        this.hasMultipleLines = true
      } else {
        this.hasMultipleLines = false
      }
    },
    createConcept() {
      const body = {
        customerId: this.supplyRequestWizardHeader.customerId,
        templateId: this.supplyRequestWizardTemplatePreview.id,
        dueDate: this.supplyRequestWizardDueDate.dueDate,
        financialYear: this.supplyRequestWizardInitialFilters.financialYearSelectedValue,
        assignedUserIds: Services.assignContactsService.processAssignedContactsListIds(
          this.supplyRequestAssignContacts.assignedContactsList
        )
      }

      this.$store.dispatch(
        _targetStore + 'POST_SUPPLY_REQUEST_WIZARD_CREATE_CONCEPT',
        body
      )
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.checkLineBreaksInDescription()
    })
  },
  updated() {
    this.$nextTick(() => {
      this.checkLineBreaksInDescription()
    })
  },
  created() {
    window.addEventListener('resize', this.checkLineBreaksInDescription)
  },
  destroyed() {
    window.removeEventListener('resize', this.checkLineBreaksInDescription)
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-intro-text {
  .c-intro-text--is-cutoff {
    max-height: 66px;
    @include media-breakpoint-down(xs) {
      max-height: 48px;
    }
  }
}
</style>
