<template>
  <div class="c-date-picker">
    <input
      :id="id"
      class="c-forms__input visible-input"
      type="text"
      @input="updateValue"
      readonly
    />
    <fa :icon="['fal', 'calendar-alt']" class="calendar" />
  </div>
</template>

<script>
import $ from 'jquery'
import 'eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min'
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css'

import momentService from '@/services/modules/momentService'

export default {
  name: 'mazWorkflowDetailsDatePicker',
  props: {
    value: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      previousValue: this.value
    }
  },
  watch: {
    value: function(newValue) {
      $(this.$el)
        .find('.visible-input')
        .data('DateTimePicker')
        .date(newValue)
      this.previousValue = newValue
    },
    options: function(newValue, oldValue) {
      const datePickerInstance = $(this.$el)
        .find('.visible-input')
        .data('DateTimePicker')

      //Manual property setter to prevent infinite cycles, if any properties are missing, add them as needed
      if (newValue.minDate !== oldValue.minDate) {
        datePickerInstance.minDate(newValue.minDate)
      }
      if (newValue.maxDate !== oldValue.maxDate) {
        datePickerInstance.minDate(newValue.maxDate)
      }
    }
  },
  mounted: function() {
    this.initializeDatepicker()
  },
  methods: {
    initializeDatepicker: function() {
      const elem = $(this.$el).find('.visible-input')
      const optionsClone = $.extend({}, this.options)

      //Clear previous datepicker if there is one
      if (elem.data('DateTimePicker')) elem.data('DateTimePicker').destroy()

      optionsClone.date = this.previousValue
      optionsClone.locale = $('html').attr('lang')
      optionsClone.ignoreReadonly = true

      elem.datetimepicker(optionsClone)
      elem.on('dp.change', data => {
        this.updateValue(data.date)
      })
    },
    updateValue: function(value) {
      if (value.valueOf() !== this.previousValue.valueOf()) {
        this.previousValue = value
        this.$emit('input', momentService(value))
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-date-picker {
  position: relative;

  .calendar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    margin-right: 12px;
    font-size: 1.4rem;
    pointer-events: none;
  }

  .visible-input {
    padding: 0 15px;
    color: $dark-gray;
    font-size: 14px;
    cursor: pointer;

    &::-ms-clear {
      display: none;
    }
  }

  .bootstrap-datetimepicker-widget.dropdown-menu {
    padding: 5px 15px;
    text-transform: capitalize;
    visibility: visible;
    opacity: 1;

    table > tbody > tr > td {
      height: 35px;
      padding: 5px;
      border-top: none;
      &:hover {
        color: $white;
        background: #ddd;
        cursor: pointer;
      }
    }

    th {
      padding: 10px;
      font-weight: bold !important;
      border-bottom: 2px solid #ddd;
      &:hover {
        color: $white;
        background: #ddd;
        cursor: pointer;
      }
    }

    table td.disabled,
    table td.disabled:hover {
      color: $light-gray;
      background: #fff;
      cursor: not-allowed;
    }

    .today:before {
      display: none;
    }
  }

  .bootstrap-datetimepicker-widget table td span.active {
    color: #fff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
    background-color: $dark-blue;
    cursor: pointer;
  }

  .bootstrap-datetimepicker-widget table td span {
    display: inline-block;
    width: 54px;
    height: 54px;
    margin: 2px 1.5px;
    line-height: 54px;
    border-radius: 4px;
    cursor: pointer;
  }

  .bootstrap-datetimepicker-widget table td span.disabled,
  .bootstrap-datetimepicker-widget table td span.disabled:hover {
    color: #777;
    background: none;
    cursor: not-allowed;
  }

  .bootstrap-datetimepicker-widget table td,
  .bootstrap-datetimepicker-widget table th {
    text-align: center;
    border-radius: 4px;
  }

  .bootstrap-datetimepicker-widget table thead tr:first-child th {
    text-align: center;
  }

  .glyphicon-chevron-left:before,
  .glyphicon-chevron-right:before {
    display: none;
  }

  .prev,
  .prev:hover {
    padding: 5px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDkgMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04LjgzNDk0IDIuMzg4MzRMNC4yMjIwMSA3TDguODM0OTQgMTEuNjExN0M4Ljk0NDk4IDExLjcyMTcgOSAxMS44NTE5IDkgMTIuMDAyNUM5IDEyLjE1MyA4Ljk0NDk4IDEyLjI4MzMgOC44MzQ5NCAxMi4zOTMzTDcuMzkyODYgMTMuODM1QzcuMjgyODIgMTMuOTQ1IDcuMTUyNTEgMTQgNy4wMDE5MyAxNEM2Ljg1MTM1IDE0IDYuNzIxMDQgMTMuOTQ1IDYuNjExIDEzLjgzNUwwLjE2NTA1OCA3LjM5MDgyQzAuMDU1MDE5MiA3LjI4MDgxIDAgNy4xNTA1NCAwIDdDMCA2Ljg0OTQ2IDAuMDU1MDE5MiA2LjcxOTE5IDAuMTY1MDU4IDYuNjA5MThMNi42MTEgMC4xNjUwMTJDNi43MjEwNCAwLjA1NTAwNCA2Ljg1MTM1IDAgNy4wMDE5MyAwQzcuMTUyNTEgMCA3LjI4MjgyIDAuMDU1MDA0IDcuMzkyODYgMC4xNjUwMTJMOC44MzQ5NCAxLjYwNjdDOC45NDQ5OCAxLjcxNjcxIDkgMS44NDY5OCA5IDEuOTk3NTJDOSAyLjE0ODA2IDguOTQ0OTggMi4yNzgzMyA4LjgzNDk0IDIuMzg4MzRaIiBmaWxsPSIjMDgxRjhGIi8+Cjwvc3ZnPgo=') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 10px !important;
  }

  .next,
  .next:hover {
    padding: 5px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDkgMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjE2NTA1OCAxMS42MTE3TDQuNzc3OTkgN0wwLjE2NTA1OCAyLjM4ODM0QzAuMDU1MDE5NCAyLjI3ODMzIDAgMi4xNDgwNiAwIDEuOTk3NTJDMCAxLjg0Njk4IDAuMDU1MDE5NCAxLjcxNjcxIDAuMTY1MDU4IDEuNjA2N0wxLjYwNzE0IDAuMTY1MDEyQzEuNzE3MTggMC4wNTUwMDQxIDEuODQ3NDkgMCAxLjk5ODA3IDBDMi4xNDg2NSAwIDIuMjc4OTYgMC4wNTUwMDQxIDIuMzg5IDAuMTY1MDEyTDguODM0OTQgNi42MDkxOEM4Ljk0NDk4IDYuNzE5MTkgOSA2Ljg0OTQ2IDkgN0M5IDcuMTUwNTQgOC45NDQ5OCA3LjI4MDgxIDguODM0OTQgNy4zOTA4MkwyLjM4OSAxMy44MzVDMi4yNzg5NiAxMy45NDUgMi4xNDg2NSAxNCAxLjk5ODA3IDE0QzEuODQ3NDkgMTQgMS43MTcxOCAxMy45NDUgMS42MDcxNCAxMy44MzVMMC4xNjUwNTggMTIuMzkzM0MwLjA1NTAxOTQgMTIuMjgzMyAwIDEyLjE1MyAwIDEyLjAwMjVDMCAxMS44NTE5IDAuMDU1MDE5NCAxMS43MjE3IDAuMTY1MDU4IDExLjYxMTdaIiBmaWxsPSIjMDgxRjhGIi8+Cjwvc3ZnPgo=') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 10px !important;
  }

  .bootstrap-datetimepicker-widget table td.day,
  .bootstrap-datetimepicker-widget table td.old,
  .bootstrap-datetimepicker-widget table td.new {
    color: $dark-gray;
  }

  .bootstrap-datetimepicker-widget table td.active,
  .bootstrap-datetimepicker-widget table td.active:hover,
  .bootstrap-datetimepicker-widget table td:hover {
    color: $white;
    background-color: $dark-blue;
    border-radius: 4px;
  }
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
}
</style>