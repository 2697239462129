export const mutations = {
  UPDATE_CLIENT_MAINTENANCE_TAB_INDEX_MUTATION(state: any, payload: number) {
    state.tabNavigation.activeTabIndex = payload
  },
  UPDATE_CLIENT_MAINTENANCE_LOADER_MUTATION(state: any, payload: object) {
    state.loader = { ...state.loader, ...payload }
  },
  UPDATE_CLIENT_MAINTENANCE_CLIENT_DETAILS_MUTATION(state: any, payload: any) {
    state.clientDetails = payload
    state.editedClientDetails = { ...payload.organization } //Create a copy of the organization object
  },
  RESET_CLIENT_MAINTENANCE_EDIT_CLIENT_DETAILS_MUTATION(state: any) {
    state.editedClientDetails = {
      ...state.editedClientDetails,
      ...state.clientDetails.organization
    }
  },
  UPDATE_CLIENT_MAINTENANCE_EDIT_MODE_MUTATION(state: any, payload: boolean) {
    state.isEditMode = payload
  },
  UPDATE_CLIENT_MAINTENANCE_PENDING_ACTIONS_TABLE_MUTATION(
    state: any,
    payload: {
      PendingActions: []
      CurrentPage: number
      PageCount: number
    }
  ) {
    state.pendingActionsTable.rows.push(...payload.PendingActions)
    state.pendingActionsTable.currentPage = payload.CurrentPage
    state.pendingActionsTable.pageCount = payload.PageCount
  },
  UPDATE_CLIENT_MAINTENANCE_PENDING_ACTIONS_CURRENT_PAGE_MUTATION(
    state: any,
    payload: number
  ) {
    state.pendingActionsTable.currentPage = payload
  },
  UPDATE_CLIENT_MAINTENANCE_COMPLETED_ACTIONS_TABLE_MUTATION(
    state: any,
    payload: {
      CompletedActions: []
      CurrentPage: number
      PageCount: number
    }
  ) {
    state.completedActionsTable.rows.push(...payload.CompletedActions)
    state.completedActionsTable.currentPage = payload.CurrentPage
    state.completedActionsTable.pageCount = payload.PageCount
  },
  UPDATE_CLIENT_MAINTENANCE_COMPLETED_ACTIONS_CURRENT_PAGE_MUTATION(
    state: any,
    payload: number
  ) {
    state.completedActionsTable.currentPage = payload
  },
  UPDATE_CLIENT_MAINTENANCE_CLIENT_SERVICES_TABLE_MUTATION(
    state: any,
    payload: { Services: [] }
  ) {
    state.clientServicesTable.rows = payload.Services
  },
  UPDATE_CLIENT_MAINTENANCE_ACTIVE_COLLAPSIBLE_PANELS_MUTATION(
    state: any,
    payload: object
  ) {
    state.activeCollapsiblePanels = {
      ...state.activeCollapsiblePanels,
      ...payload
    }
  },
  UPDATE_CLIENT_MAINTENANCE_ORGANIZATION_CONTACT_PERMISSIONS_MUTATION(
    state: any,
    payload: {
      Contacts: any
      Type: any
    }
  ) {
    state.organizationContactPermissionsTable.rows = payload.Contacts
    state.organizationContactPermissionsTable.type = payload.Type
  },
  UPDATE_CLIENT_MAINTENANCE_CLIENT_PERMISSIONS_MODAL_MUTATION(
    state: any,
    payload: object
  ) {
    state.organizationContactPermissionsModal.details = payload
  },
  //TODO: once feature is complete define the correct types
  UPDATE_CONTACTS_LIST_MUTATION(state: any, payload: any) {
    if (state.contactsModal.availableContactList.indexOf(payload) > -1) {
      state.contactsModal.availableContactList = state.contactsModal.availableContactList.filter(
        (item: any) => item !== payload
      )
    } else {
      state.contactsModal.availableContactList.push(payload)
    }
    if (state.contactsModal.selectedContactsList.indexOf(payload) > -1) {
      state.contactsModal.selectedContactsList = state.contactsModal.selectedContactsList.filter(
        (item: any) => item !== payload
      )
    } else {
      state.contactsModal.selectedContactsList.push(payload)
    }
  },

  UPDATE_CLIENT_MAINTENANCE_EMPLOYEES_LIST_MUTATION(state: any, payload: any) {
    if (state.employeesModal.availableEmployeesList.indexOf(payload) > -1) {
      state.employeesModal.availableEmployeesList = state.employeesModal.availableEmployeesList.filter(
        (item: any) => item !== payload
      )
    } else {
      state.employeesModal.availableEmployeesList.push(payload)
    }
    if (state.employeesModal.selectedEmployeesList.indexOf(payload) > -1) {
      state.employeesModal.selectedEmployeesList = state.employeesModal.selectedEmployeesList.filter(
        (item: any) => item !== payload
      )
    } else {
      state.employeesModal.selectedEmployeesList.push(payload)
    }
  },

  UPDATE_CLIENT_MAINTENANCE_CONTACT_PERMISSION_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.ContactPermission
  ) {
    state.organizationContactPermissionsTable.rows.map((row: any) => {
      if (row.Id === payload.ContactId) {
        row.Services.filter(
          (service: any) => service.Id == payload.ServiceId
        )[0].Permissions.filter(
          (permission: any) => permission.Id == payload.PermissionId
        )[0].IsSet = payload.IsSet
      }
    })
  },
  SET_CONNECTABLE_CONTACTS_MUTATION(state: any, payload: any) {
    state.contactsModal.originalContactsList = payload
    state.contactsModal.availableContactList = payload
  },
  SET_CLIENT_MAINTENANCE_CONNECTABLE_EMPLOYEES_MUTATION(state: any, payload: any) {
    state.employeesModal.originalEmployeesList = payload
    state.employeesModal.availableEmployeesList = payload
  },
  RESET_CLIENT_MAINTENANCE_SELECTED_EMPLOYEES_MUTATION(state: any) {
    state.employeesModal.selectedEmployeesList = []
  },
  RESET_SELECTED_CONTACTS_MUTATION(state: any) {
    state.contactsModal.selectedContactsList = []
  },
  UPDATE_CLIENT_MAINTENANCE_AVAILABLE_CONNECTABLE_EMPLOYEES_MUTATION(
    state: any,
    payload: object[]
  ) {
    state.employeesModal.availableEmployeesList = payload
  },
  UPDATE_AVAILABLE_CONNECTABLE_CONTACTS_MUTATION(state: any, payload: object[]) {
    state.contactsModal.availableContactList = payload
  },
  SET_RELATION_MANAGERS_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.RelationManager[]
  ): void {
    state.relationManagers = payload
  },
  SET_COUNTRIES_MUTATION(
    state: any,
    payload: MazInterfaces.ClientMaintenance.Countries[]
  ) {
    state.countries = payload
  },
  UPDATE_CLIENT_MAINTENANCE_EMPLOYEES_MUTATION(state: any, payload: any) {
    state.employeesConnectedToOrganization = payload
  },
  SET_ADD_CONTACT_URL(state: any, payload: string) {
    state.addContactUrl = payload
  },
  SET_CONNECT_URL(state: any, payload: string) {
    state.connectUrl = payload
  }
}
