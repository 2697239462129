import { localization } from './localization'

export default () => ({
  labels: { ...localization.labels },
  genders: [] as MazInterfaces.EmployeeMaintenance.Gender[],
  languages: [] as MazInterfaces.EmployeeMaintenance.Language[],
  messages: { ...localization.messages },
  shouldRefreshOnClose: false,
  employeeUrl: '',
  validationErrors: [],
  employeePermission: {} as MazInterfaces.EmployeeMaintenance.EmployeePermission
})
