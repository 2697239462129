import config from '@/api/modules/config'
import employeeMaintenanceApi from '@/api/modules/employeeMaintenanceApi'
import axiosService from '@/services/modules/axiosService'
import helpersService from '@/services/modules/helpersService'
import toastr from 'toastr'
import { currentActionContext } from './index'

const _targetApi = employeeMaintenanceApi
const _portController = 'EmployeeEmployeeJson'
const _baseEndpoint = config.endpointBase + _portController + '/'

export const actions = {
  UPDATE_EMPLOYEE_DETAILS_EDIT_MODE({ commit }: any, payload: boolean) {
    commit('UPDATE_EMPLOYEE_DETAILS_EDIT_MODE_MUTATION', payload)
  },
  GET_EMPLOYEE_CONNECTED_CLIENTS(ctx: any, payload: string) {
    const { commit, state } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.getEmployeeConnectedClients,
      '{employeeId}',
      payload
    )
    commit.UPDATE_EMPLOYEE_CONNECTED_CLIENTS_LOADER_MUTATION({
      editEmployeeClientsOverview: true
    })
    axiosService.get(endpoint, (res: any) => {
      if (res) {
        const body = helpersService.convertObjectKeysToCamelCase(res)
        commit.UPDATE_CONNECTED_CLIENTS_DETAILS_MUTATION(body)
      } else {
        toastr.error(state.messagesInfo.somethingWentWrong)
      }
      commit.UPDATE_EMPLOYEE_CONNECTED_CLIENTS_LOADER_MUTATION({
        editEmployeeClientsOverview: false
      })
    })
  },
  GET_REFERENCE_DATA(ctx: any) {
    const { commit, state } = currentActionContext(ctx)
    if (!state.genders.length && !state.languages.length && !state.countries.length) {
      const endpoint = helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getEmployeeReferenceData
      )
      axiosService.get(endpoint, (res: any) => {
        if (res) {
          const body = helpersService.convertObjectKeysToCamelCase(res)
          commit.SET_GENDERS_MUTATION(body.genders)
          commit.SET_LANGUAGES_MUTATION(body.languages)
          commit.SET_COUNTRIES_MUTATION(body.countries)
        } else {
          toastr.error(state.messagesInfo.somethingWentWrong)
        }
      })
    }
  },
  RESET_VALIDATION_ERRORS(ctx: any) {
    const { commit } = currentActionContext(ctx)
    commit.RESET_VALIDATION_ERRORS_MUTATION()
  },
  RESET_VALIDATION_ERROR(ctx: any, payload: string) {
    function capitalizeFirstLetter(string: string): string {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const { commit } = currentActionContext(ctx)
    commit.RESET_VALIDATION_ERROR_MUTATION(capitalizeFirstLetter(payload))
  },
  RESET_EMPLOYEE_DETAILS(ctx: any) {
    const { commit } = currentActionContext(ctx)
    commit.RESET_EMPLOYEE_DETAILS_MUTATION()
  },
  UPDATE_EMPLOYEE_DETAILS(
    ctx: any,
    payload: MazInterfaces.EmployeeMaintenance.EmployeeDetails
  ) {
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.updateEmployee
    )
    const { commit, state } = currentActionContext(ctx)
    commit.UPDATE_LOADER_MUTATION({
      editEmployeeDetails: true
    })
    const body = helpersService.convertObjectKeysToPascalCase(payload)
    axiosService.post(endpoint, body, (res: any) => {
      if (res.HasSucceeded) {
        ctx.dispatch('employeeMaintenance/GET_EMPLOYEE_MAINTENANCE_DETAILS', payload.id, {
          root: true
        })
        commit.UPDATE_EMPLOYEE_DETAILS_EDIT_MODE_MUTATION(false)
        toastr.success(state.messages.changesSucessfullySaved)
      } else {
        if (res.Errors) {
          commit.SET_VALIDATION_ERRORS_MUTATION(res.Errors)
        }
        toastr.error(state.messages.somethingWentWrong)
      }
      commit.UPDATE_LOADER_MUTATION({
        editEmployeeDetails: false
      })
    })
  }
}
