import HelpersService from './helpersService'
import MomentService from './momentService'

function generateServices(data) {
  const result = []

  if (data.Services) {
    data.Services.forEach(service => {
      result.push({
        id: service.Id,
        code: service.Code,
        name: service.Name
      })
    })

    return result
  } else {
    return 0
  }
}

function generatePhases(data) {
  const result = []

  if (data.Phases) {
    data.Phases.forEach(phase => {
      result.push({
        id: phase.Id,
        code: phase.Code,
        name: phase.Name
      })
    })

    return result
  } else {
    return 0
  }
}

function generateLanguages(data) {
  const result = []

  if (data.Languages) {
    data.Languages.forEach(language => {
      result.push({
        code: language.Code,
        name: language.Name
      })
    })

    return result
  } else {
    return 0
  }
}

function generateTemplates(data) {
  const result = []

  if (data.RequestTemplates) {
    data.RequestTemplates.forEach(template => {
      result.push({
        id: template.Id,
        title: template.Title
      })
    })

    return result
  } else {
    return 0
  }
}

function generateFinancialYears(data) {
  const result = []

  if (data.FinancialYears) {
    data.FinancialYears.forEach(year => {
      result.push({
        value: year
      })
    })

    return result
  } else {
    return 0
  }
}

function generateSegmentListObjects(data) {
  const result = []

  data.forEach(listItem => {
    result.push({
      id: listItem.Id,
      title: listItem.Title
    })
  })

  return result
}

function generateSegmentsObjects(data) {
  const result = []

  data.forEach(segment => {
    if (segment.SegmentItems === null) {
      result.push({
        id: segment.Id,
        title: segment.Title,
        description: segment.Description,
        segmentItems: []
      })
    } else {
      result.push({
        id: segment.Id,
        title: segment.Title,
        description: segment.Description,
        segmentItems: generateSegmentListObjects(segment.SegmentItems)
      })
    }
  })

  return result
}

function generateSubCategoriesObjects(data) {
  const result = []

  data.forEach(subCategory => {
    if (subCategory.Segments === null) {
      result.push({
        title: subCategory.Title,
        id: subCategory.Id,
        segments: []
      })
    } else {
      result.push({
        title: subCategory.Title,
        id: subCategory.Id,
        segments: generateSegmentsObjects(subCategory.Segments)
      })
    }
  })

  return result
}

function generateCategoriesObjects(data) {
  const result = []

  data.forEach(category => {
    if (category.GroupSegments === null) {
      result.push({
        id: category.Id,
        title: category.Title,
        shortTitle: category.ShortTitle,
        groupSegments: []
      })
    } else {
      result.push({
        id: category.Id,
        title: category.Title,
        shortTitle: category.ShortTitle,
        groupSegments: generateSubCategoriesObjects(category.GroupSegments)
      })
    }
  })

  return result
}

const Service = {
  processServices(payload) {
    return generateServices(payload)
  },

  processPhases(payload) {
    return generatePhases(payload)
  },

  processLanguages(payload) {
    return generateLanguages(payload)
  },

  processTemplates(payload) {
    return generateTemplates(payload)
  },

  processFinancialYears(payload) {
    return generateFinancialYears(payload)
  },

  processCategories(payload) {
    if (payload.Categories !== null) {
      return generateCategoriesObjects(payload.Categories)
    } else {
      return []
    }
  },

  setDefaultDueDate() {
    return MomentService()
      .format(HelpersService.momentDateFormat)
      .toString()
  }
}

// PRIVATE FUNCTIONS

export default Service
