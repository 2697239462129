import $ from 'jquery'
import ConfigService from './configService'

const clientEndpoint = '/temp/dictionary.ClientDictionary.'
const employeeEndpoint = '/temp/dictionary.EmployeeDictionary.'

function Dictionary() {
  this.values = {}
}

Dictionary._instance = null

Dictionary.prototype.translate = function(key) {
  return this.values[key] || key
}

Dictionary.prototype.getContextLanguage = function() {
  return $('html').attr('lang')
}

Dictionary.prototype.loadValues = function() {
  const language = this.getContextLanguage()
  const endpoint =
    ConfigService.getPortalType() === 'Employee' ? employeeEndpoint : clientEndpoint

  const valuesUrl = endpoint + language + '.json'

  // We disable browser's cache to ensure translations are up to date
  $.ajax({ cache: false, async: false, url: valuesUrl })
    .done(
      function(data) {
        this.values = Object.freeze(data)
      }.bind(this)
    )
    .fail(() => {
      console.error("Couldn't load dictionary values")
    })
}

Dictionary.getInstance = function() {
  if (Dictionary._instance == null) {
    const dictionary = new Dictionary()
    dictionary.loadValues()
    Dictionary._instance = dictionary
  }
  return Dictionary._instance
}

export default Dictionary.getInstance()
