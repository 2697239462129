<template>
  <div class="c-edit-subcategory">
    <div
      class="row d-flex align-items-center justify-content-between justify-content-sm-start"
    >
      <div class="col-12 col-sm-auto">
        <div class="d-flex align-items-center">
          <maz-state-indicator />
          <h4 class="c-subcategory__title">
            {{ parentCategoryIndexPosition }}{{ indexPosition }} {{ subCategory.title }}
          </h4>
        </div>
      </div>
      <div
        class="c-icons c-icons--categories mt-4 mt-sm-0 col-12 col-sm-auto justify-content-center"
      >
        <div class="c-icons__item c-icons__item--move" />
        <div
          :data-target="'#modalEditSubCategory' + subCategory.id.clean"
          @click="showCurrentInput(subCategory)"
          class="c-icons__item c-icons__item--edit"
          data-toggle="modal"
        />
        <div
          :data-target="'#modalDeleteSubCategory' + subCategory.id.clean"
          class="c-icons__item c-icons__item--trash"
          data-toggle="modal"
        />
      </div>
    </div>
    <div
      class="o-separator o-separator--extra-small"
      v-if="subCategory.segments.length"
    />
    <div class="c-subcategory__content">
      <draggable
        :id="subCategory.id.original"
        :options="{ group: 'subCategory.segments' }"
        @end="onCompleteDragAndDropEvent($event, category, subCategory)"
        ghost-class="ghost"
        handle=".c-icons__item--move"
        v-model="changeSegmentOrder"
      >
        <!-- Commented for now due to hiding the adding question and its bug-->
        <!-- <div v-for="(segment, index) in subCategory.segments" :id="segment.id.original" :key="segment.id.original"> -->
        <!-- Start of adding a new question -->
        <!-- TODO: Commented for now due to bug -->
        <!-- <div class="e-not-allowed">
                        <div class="e-new-question" @click="onAddSegmentInBetween(category, subCategory, index)">
                            <div class="e-new-question__container"></div>
                        </div>
        </div>-->
        <!-- End of adding a new question -->

        <maz-supply-request-edit-segment
          :category="category"
          :id="segment.id.original"
          :index="index"
          :indexPosition="(index + 1).toString()"
          :key="segment.id.original"
          :segment="segment"
          :subCategory="subCategory"
          ref="segment"
          v-for="(segment, index) in subCategory.segments"
        />
        <!--  </div> -->
      </draggable>

      <!-- Start of adding a segment -->
      <div class="e-add-item e-add-item--segment mt-2">
        <div class="e-icon-add" />
        <a @click="onAddSegment(category, subCategory)" class="e-link-bold">{{
          labels.supplyRequestEditAddSegment
        }}</a>
      </div>
      <!-- End of adding a segment -->

      <!-- Start of modal edit subcategory -->
      <div
        :id="'modalEditSubCategory' + subCategory.id.clean"
        aria-hidden="true"
        class="modal fade"
        role="dialog"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title">
                {{ labels.supplyRequestEditSubCategoryEditTitle }}
              </h3>
              <button
                @click="onCancelEditSubCategory"
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              />
            </div>
            <div class="modal-body modal-body--margin-bottom">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ labels.supplyRequestEditTitle }}</label>
                    <input class="form-control" v-model="editSubCategoryTitleInput" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div
                    class="form-group float-sm-right mb-0 d-flex flex-wrap flex-sm-no-wrap"
                  >
                    <maz-button
                      :label="labels.cancel"
                      @click.native="onCancelEditSubCategory"
                      aria-label="Close"
                      class="c-btn--empty order-2 order-sm-1"
                      data-dismiss="modal"
                    />
                    <maz-button
                      :label="labels.confirm"
                      @click.native="onEditSubCategory(category, subCategory)"
                      aria-label="Close"
                      class="c-btn--green order-1 order-sm-2"
                      data-dismiss="modal"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End of modal edit subcategory -->

      <!-- Modal delete subcategory -->
      <div
        :id="'modalDeleteSubCategory' + subCategory.id.clean"
        aria-hidden="true"
        class="modal fade"
        role="dialog"
        tabindex="-1"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog--auto-width"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title">
                {{ labels.supplyRequestConfirmLostChangesSubCategoryTitle }}
              </h3>
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              />
            </div>
            <div class="modal-body modal-body--margin-bottom">
              <p>{{ labels.supplyRequestConfirmLostChangesSubCategory }}</p>
              <div class="o-separator o-separator--small" />
            </div>
            <div class="modal-footer d-flex flex-wrap flex-sm-nowrap">
              <maz-button
                :label="labels.cancel"
                aria-label="Close"
                class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
                data-dismiss="modal"
              />
              <maz-button
                :label="labels.confirm"
                @click.native="onDeleteSubCategory(category, subCategory)"
                aria-label="Close"
                class="c-btn--green order-1 order-sm-2"
                data-dismiss="modal"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- End of modal delete subcategory -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import mazButton from '../../general/mazButton'
import mazStateIndicator from '../../general/mazStateIndicator'
import mazSupplyRequestEditSegment from '../mazSupplyRequestEditSegment'
import draggable from 'vuedraggable'

const _targetStore = 'supplyRequest2EditStore/'

export default {
  name: 'maz-supply-request-edit-sub-category',
  components: {
    'maz-button': mazButton,
    'maz-state-indicator': mazStateIndicator,
    'maz-supply-request-edit-segment': mazSupplyRequestEditSegment,
    draggable: draggable
  },
  props: {
    parentCategoryIndexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    indexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    index: {
      required: false,
      type: Number,
      default() {
        return ''
      }
    },
    subCategory: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      segmentTitleInput: '',
      editSubCategoryTitleInput: ''
    }
  },
  computed: {
    ...mapGetters({
      id: _targetStore + 'ID',
      supplyRequestCategories: _targetStore + 'SUPPLY_REQUEST_EDIT_CATEGORIES',
      segmentAddedId: _targetStore + 'SUPPLY_REQUEST_EDIT_SEGMENT_ADDED_ID',
      labels: _targetStore + 'LABELS'
    }),
    changeSegmentOrder: {
      get() {
        return this.subCategory.segments
      },
      set(value) {
        const body = {
          category: this.category,
          subCategory: this.subCategory,
          segments: value
        }
        this.$store.dispatch(_targetStore + 'SET_ORDERED_SEGMENTS', body)
      }
    }
  },
  methods: {
    onCancelSegment() {
      this.cleanNewSegmentInputs()
    },
    cleanNewSegmentInputs() {
      this.segmentTitleInput = ''
    },
    onCancelEditSubCategory() {
      this.cleanEditSubCategoryInputs()
    },
    cleanEditSubCategoryInputs() {
      this.editSubCategoryTitleInput = ''
    },
    onEditSubCategory(category, subCategory) {
      const body = {
        supplyRequestId: this.id,
        categoryId: category.id.original,
        groupSegmentId: subCategory.id.original,
        title: this.editSubCategoryTitleInput
      }

      this.$store.dispatch(_targetStore + 'EDIT_SUBCATEGORY', body)

      this.cleanEditSubCategoryInputs()
    },
    onDeleteSubCategory(category, subCategory) {
      const body = {
        supplyRequestId: this.id,
        categoryId: category.id.original,
        groupSegmentId: subCategory.id.original
      }

      this.$store.dispatch(_targetStore + 'DELETE_SUBCATEGORY', body)
    },
    onAddSegment(category, subCategory) {
      const body = {
        category: category,
        subCategory: subCategory,
        segmentData: {
          id: this.getGuidId(),
          title: '',
          description: '',
          segmentItems: [],
          attachments: []
        }
      }

      this.$store.dispatch(_targetStore + 'ADD_SEGMENT', body)

      const indexOfLastSegment = this.subCategory.segments.length - 1

      // TODO REFACTOR - AVOID USING TIMEOUTS //
      setTimeout(() => {
        this.onEditSegment(indexOfLastSegment, this.segmentAddedId)

        $('.e-add-item--segment')
          .addClass('disabled')
          .prop('disabled', true)
        $('.e-new-question')
          .addClass('disabled')
          .prop('disabled', true)
      }, 100)
    },
    onAddSegmentInBetween(category, subCategory, index) {
      const body = {
        category: category,
        subCategory: subCategory,
        indexPosition: index,
        segmentData: {
          id: this.getGuidId(),
          title: '',
          description: '',
          segmentItems: [],
          attachments: []
        }
      }

      this.$store.dispatch(_targetStore + 'ADD_SEGMENT_IN_BETWEEN', body)

      const indexOfNewSegment = index

      // TODO REFACTOR - AVOID USING TIMEOUTS //
      setTimeout(() => {
        this.onEditSegment(indexOfNewSegment, this.segmentAddedId)

        $('.e-add-item--segment')
          .addClass('disabled')
          .prop('disabled', true)
        $('.e-new-question')
          .addClass('disabled')
          .prop('disabled', true)
      }, 100)
    },
    onEditSegment(index, segmentId) {
      this.$refs.segment[index].onEditSegment(segmentId)
    },
    showCurrentInput(subCategory) {
      this.editSubCategoryTitleInput = subCategory.title
    },
    getGuidId() {
      let uuidValue = '',
        k,
        randomValue
      for (k = 0; k < 32; k++) {
        randomValue = (Math.random() * 16) | 0

        if (k == 8 || k == 12 || k == 16 || k == 20) {
          uuidValue += '-'
        }
        uuidValue += (k == 12
          ? 4
          : k == 16
            ? (randomValue & 3) | 8
            : randomValue
        ).toString(16)
      }
      return uuidValue
    },
    onCompleteDragAndDropEvent(event, category) {
      const body = {
        supplyRequestId: this.id,
        categoryId: category.id.original,
        toPosition: event.newIndex,
        groupSegmentId: event.from.id,
        toGroupSegmentId: event.to.id,
        segmentId: event.item.id
      }

      this.$store.dispatch(_targetStore + 'SET_ORDER_OF_SEGMENTS', body)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/__main.scss';

.c-edit-subcategory {
  @include subcategory;

  + .c-edit-subcategory {
    margin-top: 40px;
  }
}

.e-new-question {
  position: relative;
  top: 4px;
  width: 100%;
  height: 22px;
  margin-top: -13px;
  cursor: pointer;

  .e-new-question__container {
    position: relative;
    float: right;
    width: 24px;
    height: 26px;
    background-color: $light-steel-blue;

    &:before,
    &:after {
      position: absolute;
      top: 12px;
      left: 6px;
      display: inline-block;
      width: 14px;
      height: 2px;
      background-color: $dark-blue;
      transition: all 0.3s ease-out;
      content: '';
    }

    &:after {
      transform: rotate(90deg);
    }
  }

  &.disabled {
    pointer-events: none;

    .e-new-question__container {
      text-decoration: none;
      pointer-events: none;

      &:before,
      &:after {
        opacity: 0.5;
      }
    }
  }
}
</style>
