




import Vue from "vue";
import mazSessionAutoLogout from "@/components/custom/mazSessionAutoLogout/index.vue";

export default Vue.extend({
  name: "maz-session-timeout",
  components: {
    mazSessionAutoLogout,
  },
});
