<template>
  <div
    class="c-task-status-circle"
    :class="[statusClasses, smallCircle === true ? 'c-task-status-circle--small' : '']"
  >
    <i v-if="progressStatus === 300" />
    <!--<div class="c-task-status-circle__popup">
            <div class="c-task-status-circle__popup-content">{{popupDescription}}</div>
        </div>-->
  </div>
</template>

<script>
import dictionaryService from '@/services/modules/dictionaryService'

export default {
  name: "mazTaskStatusCircle",
  props: {
    progressStatus: {
      //required: true, removed until we have taskStatus
      type: Number,
      default: 200
    },
    overdueStatus: {
      required: true,
      type: Number
    },
    smallCircle: {
      type: Boolean
    }
  },
  methods: {
    progressStatusClass(progressStatus) {
      if (progressStatus === 100) return 'not-started';
      if (progressStatus === 200) return 'in-progress';
      if (progressStatus === 300) return 'done';
    },
    overdueStatusClass(overdueStatus) {
      if (overdueStatus === 5) return 'status-inactive';
      if (overdueStatus === 10) return 'status-inTime';
      if (overdueStatus === 20) return 'status-soonOverdue';
      if (overdueStatus === 30) return 'status-overdue';
    }
  },
  computed: {
    statusClasses: function () {
      return [
        this.progressStatusClass(this.progressStatus),
        this.overdueStatusClass(this.overdueStatus)
      ]
    },
    popupDescription: function () {
      if (this.progressStatus === 300) return dictionaryService.translate('TaskStatusCircle.Messages.TaskComplete');
      if (this.progressStatus === 200) {
        if (this.overdueStatus === 10) return dictionaryService.translate('TaskStatusCircle.Messages.TaskOnTime');
        if (this.overdueStatus === 20) return dictionaryService.translate('TaskStatusCircle.Messages.TaskSoonOverdue');
        if (this.overdueStatus === 30) return dictionaryService.translate('TaskStatusCircle.Messages.TaskOverdue');
      }
      if (this.progressStatus === 100) {
        if (this.overdueStatus === 5 || this.overdueStatus === 10) return dictionaryService.translate('TaskStatusCircle.Messages.TaskNotStarted');
        if (this.overdueStatus === 20) return dictionaryService.translate('TaskStatusCircle.Messages.TaskNotStartedSoonOverdue');
        if (this.overdueStatus === 30) return dictionaryService.translate('TaskStatusCircle.Messages.TaskNotStartedOverdue');
      }
      return dictionaryService.translate('TaskStatusCircle.Messages.TaskOverdue')
    }
  }
}
</script>

<style lang="scss">
@import '@/styling/custom/settings/__main.scss';
.c-task-status-circle {
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 14px;

  &:hover .c-task-status-circle__popup {
    opacity: 0.8;
  }

  &.status-inactive {
    background: $mid-steel-blue;

    &:hover {
      background: $steel-blue;
    }
  }

  &.status-inTime {
    background: $status-green;

    &:hover {
      //   background: darken($status-green, 5%);
    }
  }

  &.status-soonOverdue {
    background: $status-yellow;

    &:hover {
      //   background: darken($status-yellow, 5%);
    }
  }

  &.status-overdue {
    background: $status-red;

    &:hover {
      //   background: darken($status-red, 5%);
    }
  }

  i {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    z-index: 10;
    color: white;
    font-size: 18px;
    background-image: url('~~@/assets/images/icon_checkmark.svg');
    background-repeat: no-repeat;
  }

  .c-task-status-circle__popup {
    position: absolute;
    top: 0;
    left: 34px;
    z-index: 10;
    width: 100px;
    opacity: 0;
    transition: opacity 0.4s;
    pointer-events: none;

    .c-task-status-circle__popup-content {
      display: inline-block;
      padding: 4px;
      color: white;
      background-color: black;
      border-radius: 4px;
    }
  }
}
</style>
