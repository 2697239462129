import clientMaintenanceApi from '@/api/modules/clientMaintenanceApi'
import config from '@/api/modules/config'
import axiosService from '@/services/modules/axiosService'
import helpersService from '@/services/modules/helpersService'
import { defineActions } from 'direct-vuex'
import toastr from 'toastr'
import { currentActionContext } from './index'

const _portController = 'ClientMaintenanceEmployeeJson'
const _baseEndpoint = config.endpointBase + _portController + '/'
const _targetApi = clientMaintenanceApi

export const actions = defineActions({
  SETUP_MODAL(ctx: any): void {
    const { dispatch, commit, state } = currentActionContext(ctx)
    commit.RESET_STEP_MUTATION()
    commit.RESET_SHOULD_REFRESH_ON_CLOSE_MUTATION()
    commit.RESET_CLIENT_TYPE_MUTATION()
    commit.RESET_CLIENT_URL_MUTATION()
    commit.RESET_VALIDATION_ERRORS_MUTATION()
    if (
      !state.genders.length &&
      !state.languages.length &&
      !state.relationManagers.length
    ) {
      const endpoint = helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getCustomerReferenceData
      )
      axiosService.get(endpoint, (res: any) => {
        if (res) {
          const body = helpersService.convertObjectKeysToCamelCase(res)
          commit.SET_GENDERS_MUTATION(body.genders)
          commit.SET_LANGUAGES_MUTATION(body.languages)
          commit.SET_RELATION_MANAGERS_MUTATION(body.relationManagers)
          commit.SET_COUNTRIES_MUTATION(body.countries)
          dispatch.SET_INITIAL_STEP()
        } else {
          toastr.error(state.messages.somethingWentWrong)
        }
      })
    } else {
      dispatch.SET_INITIAL_STEP()
    }
  },

  SET_INITIAL_STEP(ctx: any): void {
    const { commit, state } = currentActionContext(ctx)
    if (
      state.customerPermission.mayEditContact === false &&
      state.customerPermission.mayEditOrganization === true
    ) {
      commit.SET_CLIENT_TYPE_MUTATION(10)
      commit.SET_STEP_MUTATION(2)
      return
    }
    if (
      state.customerPermission.mayEditContact === true &&
      state.customerPermission.mayEditOrganization === false
    ) {
      commit.SET_CLIENT_TYPE_MUTATION(20)
      commit.SET_STEP_MUTATION(2)
      return
    }
    commit.SET_STEP_MUTATION(1)
  },

  SET_CLIENT_TYPE(ctx: any, payload: number): void {
    const { commit } = currentActionContext(ctx)
    commit.SET_CLIENT_TYPE_MUTATION(payload)
    commit.SET_STEP_MUTATION(2)
  },

  SAVE_CONTACT(
    ctx: any,
    payload: MazInterfaces.ClientMaintenance.CreateContactDetails
  ): void {
    const { commit, state } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.addClientMaintenanceContact
    )
    commit.RESET_VALIDATION_ERRORS_MUTATION()
    const body = helpersService.convertObjectKeysToPascalCase(payload)
    axiosService.post(endpoint, body, (res: any) => {
      if (res.HasSucceeded) {
        commit.SET_STEP_MUTATION(3)
        commit.SET_CLIENT_URL_MUTATION(res.Url)
        commit.SET_SHOULD_REFRESH_ON_CLOSE_MUTATION()
      } else {
        if (res.Errors) {
          commit.SET_VALIDATION_ERRORS_MUTATION(res.Errors)
        }
        toastr.error(state.messages.somethingWentWrong)
      }
    })
  },

  SAVE_ORGANIZATION(
    ctx: any,
    payload: MazInterfaces.ClientMaintenance.CreateOrganizationDetails
  ): void {
    const { commit, state } = currentActionContext(ctx)
    const endpoint = helpersService.buildEndpointRequest(
      _baseEndpoint + _targetApi.addClientMaintenanceOrganization
    )
    commit.RESET_VALIDATION_ERRORS_MUTATION()
    const body = helpersService.convertObjectKeysToPascalCase(payload)
    axiosService.post(endpoint, body, (res: any) => {
      if (res.HasSucceeded) {
        commit.SET_STEP_MUTATION(3)
        commit.SET_CLIENT_URL_MUTATION(res.Url)
        commit.SET_SHOULD_REFRESH_ON_CLOSE_MUTATION()
      } else {
        if (res.Errors?.length) {
          commit.SET_VALIDATION_ERRORS_MUTATION(res.Errors)
        }
        toastr.error(state.messages.somethingWentWrong)
      }
    })
  },

  RESET_VALIDATION_ERROR(ctx: any, payload: string) {
    function capitalizeFirstLetter(string: string): string {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
    const { commit } = currentActionContext(ctx)
    commit.RESET_VALIDATION_ERROR_MUTATION(capitalizeFirstLetter(payload))
  }
})
