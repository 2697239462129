import dictionaryService from '@/services/modules/dictionaryService'

export const mutations = {
  UPDATE_EMPLOYEE_PERMISSIONS_LOADER_MUTATION(state: any, payload: boolean) {
    state.loader = payload
  },
  UPDATE_EMPLOYEE_PERMISSIONS_EDIT_MODE_MUTATION(state: any, payload: boolean) {
    state.isEditMode = payload
  },
  UPDATE_EMPLOYEE_PERMISSION_DETAILS_MUTATION(state: any, payload: any) {
    state.employeePermissions = payload
    state.editedEmployeePermissions = { ...payload.permissions }

    const activeCountries = payload.permissions.countries
    activeCountries.forEach((country: { isoCode3: any; name: any }) => {
      if (!state.activeCountriesValues.includes(country.isoCode3)) {
        state.activeCountriesValues.push(country.isoCode3)
      }
      if (!state.activeCountriesNames.includes(country.name)) {
        state.activeCountriesNames.push(country.name)
      }
    })
  },
  UPDATE_ACTIVE_COUNTRIES(state: any, payload: any) {
    state.activeCountriesValues = payload
    const body: { isActive: boolean; isoCode3: any; name: any }[] = []
    const countryNames: any[] = []
    payload.forEach((abbr: any) => {
      body.push({
        isActive: true,
        isoCode3: abbr,
        name: dictionaryService.translate(`Labels.Country.${abbr}`)
      })
      countryNames.push(dictionaryService.translate(`Labels.Country.${abbr}`))
    })
    state.editedEmployeePermissions.countries = body
    state.activeCountriesNames = countryNames
  },
  RESET_EMPLOYEE_PERMISSIONS_COUNTRY_LIST_MUTATION(state: any) {
    state.activeCountriesValues = []
    state.activeCountriesNames = []
    state.editedEmployeePermissions.countries = []
  },
  RESET_EMPLOYEE_PERMISSION_DETAILS_MUTATION(state: any) {
    state.editedEmployeePermissions = { ...state.employeePermissions.permissions }

    state.activeCountriesValues = []
    state.activeCountriesNames = []

    const activeCountries = state.employeePermissions.permissions.countries
    activeCountries.forEach((country: { isoCode3: any; name: any }) => {
      if (!state.activeCountriesValues.includes(country.isoCode3)) {
        state.activeCountriesValues.push(country.isoCode3)
      }
      if (!state.activeCountriesNames.includes(country.name)) {
        state.activeCountriesNames.push(country.name)
      }
    })
  },
  RESET_VALIDATION_ERROR_MUTATION(state: any, payload: string) {
    const idx = state.validationErrors.findIndex(
      (obj: any) => obj.PropertyName === payload
    )
    if (idx > -1) {
      state.validationErrors.splice(idx, 1)
    }
  }
}
