import helpersService from '@/services/modules/helpersService'
import { localization } from './localization'

export default () => ({
  id: helpersService.getSearchId('?eid=', window.location.search) as string,
  isEditMode: false,
  loader: {
    editEmployeeDetails: false,
    editEmployeeClientsOverview: false
  },
  labels: { ...localization.labels },
  employeeDetails: {} as MazInterfaces.EmployeeMaintenance.EmployeeClientDetails,
  editedEmployeeDetails: {} as MazInterfaces.EmployeeMaintenance.EmployeeDetails,
  genders: [] as MazInterfaces.EmployeeMaintenance.Gender[],
  languages: [] as MazInterfaces.EmployeeMaintenance.Language[],
  countries: [] as MazInterfaces.EmployeeMaintenance.Countries[],
  connectedClients: {
    labels: { ...localization.connectedClientsTable.labels },
    rows: [] as object[],
    available: false
  },
  messagesInfo: {
    somethingWentWrong: ''
  },
  messages: { ...localization.messages },
  validationErrors: [] as MazInterfaces.EmployeeMaintenance.ValidationError[]
})
