<template>
  <div class="maz-category-manager">
    <div>
      <!-- Nav tabs -->
      <ul
        class="nav nav-tabs c-category-bar mt-0 mb-2 mb-sm-0"
        v-if="templateData.categories"
      >
        <li class="c-category-bar__items">
          <a
            :class="['c-category-bar__item', { active: index == 0 }]"
            :href="'#navigationId' + category.id"
            :id="'#navigationId' + category.id"
            :key="`nav-${category.id}`"
            @click="initTabsNavigation"
            v-for="(category, index) in templateData.categories"
            >{{ index + 1 }}. {{ category.shortTitle }}</a
          >
        </li>
        <!--<div v-show="templateData.categories.length > 0" class="c-category-bar__item-new" data-toggle="modal" data-target="#modalAddCategory"><div class="c-category-bar__plus-icon"></div></div>-->
      </ul>
    </div>

    <!-- Start of adding a new category -->
    <div class="e-add-item">
      <div class="e-icon-add" />
      <a class="e-link-bold" data-target="#modalAddCategory" data-toggle="modal">{{
        labels.supplyRequestTemplateMaintenanceCreateCategory
      }}</a>
    </div>
    <!-- End of adding a new category -->

    <div class="o-separator o-separator--small" />

    <!-- Tab panes -->
    <div class="tab-content">
      <div
        :class="['tab-pane', { active: index == 0 }, { fade: index != 0 }]"
        :id="'navigationId' + category.id"
        :key="`tab-${category.id}`"
        v-for="(category, index) in templateData.categories"
      >
        <maz-template-maintenance-category
          :category="category"
          :id="category.id"
          :index="index"
          :indexPosition="(index + 1).toString() + '.'"
          :key="category.id.clean"
        />
      </div>
    </div>

    <!-- Start of modal add category -->
    <div
      aria-hidden="true"
      class="modal fade"
      id="modalAddCategory"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">
              {{ labels.supplyRequestTemplateMaintenanceCreateCategory }}
            </h3>
            <button
              @click="onCancelCategory"
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            />
          </div>
          <div class="modal-body modal-body--margin-bottom">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>{{
                    labels.supplyRequestTemplateMaintenanceCategoryShorttitle
                  }}</label>
                  <input class="form-control" v-model="categoryShortTitleInput" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>{{
                    labels.supplyRequestTemplateMaintenanceCategoryTitle
                  }}</label>
                  <input class="form-control" v-model="categoryTitleInput" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="form-group float-sm-right mb-0 d-flex flex-wrap flex-sm-nowrap"
                >
                  <maz-button
                    :label="labels.cancel"
                    @click.native="onCancelCategory"
                    aria-label="Close"
                    class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
                    data-dismiss="modal"
                  />
                  <maz-button
                    :disabled="isAddCategoryDisabled"
                    :label="labels.confirm"
                    @click.native="onSubmitCategory"
                    aria-label="Close"
                    class="c-btn--green order-1 order-sm-2"
                    data-dismiss="modal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of modal add category -->
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import mazButton from '../../general/mazButton'
import mazTemplateMaintenanceCategory from '../mazTemplateMaintenanceCategory'

const _targetStore = 'supplyRequest2TemplateMaintenanceStore/'

export default {
  name: 'maz-template-maintenance-category-manager',
  components: {
    'maz-button': mazButton,
    'maz-template-maintenance-category': mazTemplateMaintenanceCategory
  },
  props: {
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      categoryTitleInput: '',
      categoryShortTitleInput: ''
    }
  },
  computed: {
    ...mapGetters({
      templateData: _targetStore + 'TEMPLATE_DATA',
      templateMaintenanceCategoriesLoaded:
        _targetStore + 'TEMPLATE_MAINTENANCE_CATEGORIES_LOADED',
      templateMaintenanceCategoriesAddedId:
        _targetStore + 'TEMPLATE_MAINTENANCE_CATEGORY_ADDED_ID',
      labels: _targetStore + 'LABELS'
    }),
    navTabs() {
      return $('.nav-tabs a')
    },
    isAddCategoryDisabled() {
      let result = true

      if (this.categoryTitleInput.length > 0 && this.categoryShortTitleInput.length > 0) {
        result = false
      }

      return result
    }
  },
  methods: {
    onSubmitCategory() {
      const body = {
        id: this.getGuidId(),
        title: this.categoryTitleInput,
        shortTitle: this.categoryShortTitleInput,
        groupSegments: []
      }

      this.$store.dispatch(_targetStore + 'GET_CATEGORIES', body)

      $('#modalAddCategory').modal('hide')

      this.cleanNewCategoryInputs()
    },
    onCancelCategory() {
      this.cleanNewCategoryInputs()
    },
    getGuidId() {
      let uuidValue = '',
        k,
        randomValue
      for (k = 0; k < 32; k++) {
        randomValue = (Math.random() * 16) | 0

        if (k == 8 || k == 12 || k == 16 || k == 20) {
          uuidValue += '-'
        }
        uuidValue += (k == 12
          ? 4
          : k == 16
            ? (randomValue & 3) | 8
            : randomValue
        ).toString(16)
      }
      return uuidValue
    },
    cleanNewCategoryInputs() {
      this.categoryTitleInput = ''
      this.categoryShortTitleInput = ''
    },
    initTabsNavigation(event) {
      event.preventDefault() // prevents browser's default anchor behavior
      this.loadCategory(event.target)
    },
    loadCategory(targetCategory) {
      $(targetCategory).tab('show')
    }
  },
  watch: {
    templateMaintenanceCategoriesAddedId: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.$nextTick(() => {
            // Open the tab for this category
            const notMatchingTab = document.getElementsByClassName('tab-pane')
            $(notMatchingTab).tab('dispose').removeClass('active')

            const tabPaneId = 'navigationId' + this.templateMaintenanceCategoriesAddedId
            const matchingTabPane = document.getElementById(tabPaneId)
            $(matchingTabPane).tab('show').addClass('active')

            const tabId = '#navigationId' + this.templateMaintenanceCategoriesAddedId
            const categoryBarTab = document.getElementById(tabId)
            $(categoryBarTab).tab('show').addClass('active')
          })
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/__main.scss';

.c-category-bar {
  @include category-bar;
}
</style>
