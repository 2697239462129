<template>
  <div class="maz-category-manager o-block">
    <div>
      <!-- Nav tabs -->
      <ul
        class="nav nav-tabs c-category-bar mt-0"
        v-if="supplyRequestWizardTemplatePreview.categories"
      >
        <li class="c-category-bar__items">
          <a
            :class="['c-category-bar__item',{ active: index == 0}]"
            :href="'#navigationId'+index"
            :id="'#navigationId'+index"
            :key="`nav-${category.id}`"
            @click="initTabsNavigation"
            v-for="( category, index ) in supplyRequestWizardTemplatePreview.categories"
          >{{(index + 1)}}. {{category.shortTitle}}</a>
        </li>
      </ul>
    </div>

    <div class="o-separator o-separator--small" />

    <!-- Tab panes -->
    <div class="tab-content">
      <div
        :class="['tab-pane',{ active: index == 0},{ fade : index != 0}]"
        :id="'navigationId'+index"
        :key="`tab-${category.id}`"
        v-for="(category, index) in supplyRequestWizardTemplatePreview.categories"
      >
        <maz-wizard-sr-category
          :category="category"
          :id="category.id"
          :index="index"
          :indexPosition="(index+1).toString()+'.'"
          :key="category.id.clean"
        />
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import mazWizardSRCategory from '../mazWizardSRCategory'

const _targetStore = 'supplyRequest2AddStore/'

export default {
  name: 'maz-wizard-sr-category-manager',
  components: {
    'maz-wizard-sr-category': mazWizardSRCategory
  },
  props: {
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters({
      supplyRequestWizardTemplatePreview:
        _targetStore + 'SUPPLY_REQUEST_WIZARD_TEMPLATE_PREVIEW'
    }),
    navTabs() {
      return $('.nav-tabs a')
    }
  },
  methods: {
    initTabsNavigation(event) {
      event.preventDefault() // prevents browser's default anchor behavior
      this.loadCategory(event.target)
    },
    loadCategory(targetCategory) {
      $(targetCategory).tab('show')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/__main.scss';

.c-category-bar {
  @include category-bar;
}
</style>
