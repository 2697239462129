import HelpersService from './helpersService'
import MomentService from './momentService'

function generateFilesObjects(data) {
  const result = []

  if (data === null || data === undefined) {
    return []
  } else {
    data.forEach(file => {
      result.push({
        id: file.Id,
        fileName: file.FileName,
        mimeType: file.MimeType,
        ownerEmail: file.OwnerEmail,
        createdDate: MomentService(file.CreatedDate)
          .format(HelpersService.momentDateAndTimeFormat)
          .toString(),
        mayDownload: file.MayDownload,
        mayDelete: file.MayDelete,
        downloadUrl: file.DownloadUrl
      })
    })
  }

  return result
}

function generateAttachmentsObjects(data) {
  const result = []

  if (data === null || data === undefined) {
    return []
  } else {
    data.forEach(attachment => {
      result.push({
        id: attachment.Id,
        fileName: attachment.FileName,
        mimeType: attachment.MimeType,
        ownerEmail: attachment.OwnerEmail,
        createdDate: MomentService(attachment.CreatedDate)
          .format(HelpersService.momentDateAndTimeFormat)
          .toString(),
        mayDownload: attachment.MayDownload,
        mayDelete: attachment.MayDelete,
        downloadUrl: attachment.DownloadUrl
      })
    })
  }

  return result
}

function cleanPropertyValue(propertyValue, expressionsToBeRemoved) {
  let result = null
  const emptyStr = ''

  if (HelpersService.isNotNullOrUndefined(propertyValue)) {
    result = propertyValue

    if (HelpersService.isNotNullOrUndefined(expressionsToBeRemoved)) {
      expressionsToBeRemoved.forEach(expression => {
        result = result.replace(expression, emptyStr)
      })
    }
  }

  return result
}

function generateSegmentItemsObjects(data) {
  const result = []

  if (data === null || data === undefined) {
    return []
  } else {
    data.forEach(listItem => {
      let resultId = cleanPropertyValue(listItem.Id, ['{', '}'])

      result.push({
        id: resultId,
        title: listItem.Title
      })

      resultId = null
    })
  }

  return result
}

const Service = {
  updateFiles(payload) {
    let result = null

    result = generateFilesObjects(payload)

    return result
  },

  updateAttachments(payload) {
    let result = null

    result = generateAttachmentsObjects(payload)

    return result
  },

  updateSegmentItems(payload) {
    let result = null

    result = generateSegmentItemsObjects(payload)

    return result
  }
}

export default Service
