import Api from '../../api'
import Services from '../../services'

const _isEmployeePortal = Api.config.portalType === 'Employee' ? true : false
const _portController = _isEmployeePortal
  ? 'SupplyRequest2EmployeeJson'
  : 'SupplyRequest2ClientJson'
const _baseEndpoint = Api.config.endpointBase + _portController + '/'
const _targetApi = Api.supplyRequest2AddApi

export default {
  namespaced: true,
  state: {
    id: Services.helpersService.getSearchId('?oid=', window.location.search),
    showPreview: false,
    supplyRequestWizardHeader: {
      title: '',
      customerId: '',
      customerName: '',
      customerUrl: ''
    },
    supplyRequestWizardInitialFilters: {
      services: [],
      financialYears: [],
      financialYearsLoaded: false,
      financialYearSelectedValue: ''
    },
    supplyRequestWizardPhases: {
      phases: [],
      phasesLoaded: false
    },
    supplyRequestWizardLanguages: {
      languages: [],
      languagesLoaded: false
    },
    supplyRequestWizardTemplates: {
      templates: [],
      templatesLoaded: false
    },
    supplyRequestWizardDueDate: {
      dueDate: ''
    },
    supplyRequestWizardTemplatePreview: {
      phaseId: '',
      id: '',
      serviceId: '',
      languageCode: '',
      title: '',
      description: '',
      categories: []
    },
    supplyRequestAssignContacts: {
      contactsList: [],
      assignedContactsList: []
    },
    labels: {
      supplyRequestAddLabelsSelectService: Services.dictionaryService.translate(
        'SupplyRequestAdd.Labels.SelectService'
      ),
      supplyRequestAddLabelsSelectLanguage: Services.dictionaryService.translate(
        'SupplyRequestAdd.Labels.SelectLanguage'
      ),
      supplyRequestAddLabelsSelectTemplate: Services.dictionaryService.translate(
        'SupplyRequestAdd.Labels.SelectTemplate'
      ),
      supplyRequestAddLabelsSelectPhase: Services.dictionaryService.translate(
        'SupplyRequestAdd.Labels.SelectPhase'
      ),
      supplyRequestAddLabelsSelectFinancialYear: Services.dictionaryService.translate(
        'SupplyRequestAdd.Labels.SelectFinancialYear'
      ),
      supplyRequestAddLabelsSelectDueDate: Services.dictionaryService.translate(
        'SupplyRequestAdd.Labels.SelectDueDate'
      ),
      supplyRequestAddLabelsSendTemplate: Services.dictionaryService.translate(
        'SupplyRequestAdd.Labels.SendTemplate'
      ),
      supplyRequestEditResponsibleContacts: Services.dictionaryService.translate(
        'Labels.SupplyRequest.EditResponsibleUsers'
      ),
      supplyRequestSelectedResponsibleContacts: Services.dictionaryService.translate(
        'Labels.SupplyRequest.SelectedResponsibleUsers'
      ),
      showMore: Services.dictionaryService.translate('Labels.Common.ShowMore'),
      showLess: Services.dictionaryService.translate('Labels.Common.ShowLess'),
      cancel: Services.dictionaryService.translate('Labels.Common.Cancel'),
      confirm: Services.dictionaryService.translate('Labels.Common.Confirm'),
      edit: Services.dictionaryService.translate('Labels.Common.Edit'),
      save: Services.dictionaryService.translate('Labels.Common.Save')
    }
  },
  getters: {
    ID: state => {
      return state.id
    },
    SUPPLY_REQUEST_WIZARD_HEADER: state => {
      return state.supplyRequestWizardHeader
    },
    SUPPLY_REQUEST_WIZARD_FILTERS: state => {
      return state.supplyRequestWizardInitialFilters
    },
    SUPPLY_REQUEST_WIZARD_PHASES: state => {
      return state.supplyRequestWizardPhases
    },
    SUPPLY_REQUEST_WIZARD_PHASES_LOADED: state => {
      return state.supplyRequestWizardPhases.phasesLoaded
    },
    SUPPLY_REQUEST_WIZARD_LANGUAGES: state => {
      return state.supplyRequestWizardLanguages
    },
    SUPPLY_REQUEST_WIZARD_LANGUAGES_LOADED: state => {
      return state.supplyRequestWizardLanguages.languagesLoaded
    },
    SUPPLY_REQUEST_WIZARD_TEMPLATES: state => {
      return state.supplyRequestWizardTemplates
    },
    SUPPLY_REQUEST_WIZARD_TEMPLATES_LOADED: state => {
      return state.supplyRequestWizardTemplates.templatesLoaded
    },
    SUPPLY_REQUEST_WIZARD_FINANCIAL_YEARS: state => {
      return state.supplyRequestWizardInitialFilters.financialYears
    },
    SUPPLY_REQUEST_WIZARD_FINANCIAL_YEARS_LOADED: state => {
      return state.supplyRequestWizardInitialFilters.financialYearsLoaded
    },
    SUPPLY_REQUEST_WIZARD_TEMPLATE_PREVIEW: state => {
      return state.supplyRequestWizardTemplatePreview
    },
    SUPPLY_REQUEST_WIZARD_DUE_DATE: state => {
      return state.supplyRequestWizardDueDate
    },
    SHOW_PREVIEW: state => {
      return state.showPreview
    },
    SUPPLY_REQUEST_ASSIGN_CONTACTS: state => {
      return state.supplyRequestAssignContacts
    },
    LABELS: state => {
      return state.labels
    }
  },
  actions: {
    GET_SUPPLY_REQUEST_WIZARD_INITIAL_DATA({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getAddSupplyRequestInitialData,
        '{customerId}',
        payload
      )

      Services.axiosService.get(endpoint, res => {
        if (res) {
          commit('SET_SUPPLY_REQUEST_WIZARD_HEADER', res)
          commit('SET_SUPPLY_REQUEST_WIZARD_FILTERS', res)
          commit('SET_SUPPLY_REQUEST_WIZARD_DUEDATE', res)
        }
      })
    },
    GET_PHASES_BY_SERVICE_ID({ commit }, payload) {
      let endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getAddSupplyRequestPhases
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{serviceId}',
        payload.serviceId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{customerId}',
        payload.customerId
      )
      Services.axiosService.get(endpoint, res => {
        if (res) {
          commit('SET_SUPPLY_REQUEST_WIZARD_PHASES', res)
          commit('SUPPLY_REQUEST_WIZARD_PHASES_LOADED', res)
        }
      })
    },
    GET_LANGUAGES_BY_SERVICE_AND_PHASE_ID({ commit }, payload) {
      let endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getAddSupplyRequestLanguages
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{serviceId}',
        payload.serviceId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{phaseId}',
        payload.phaseId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{customerId}',
        payload.customerId
      )

      Services.axiosService.get(endpoint, res => {
        if (res) {
          commit('SET_SUPPLY_REQUEST_WIZARD_LANGUAGES', res)
          commit('SUPPLY_REQUEST_WIZARD_LANGUAGES_LOADED', res)
        }
      })
    },
    GET_TEMPLATES_BY_SERVICE_PHASE_LANGUAGE_ID({ commit }, payload) {
      let endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getTemplatesByServiceIdPhaseIdLanguageCode
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{serviceId}',
        payload.serviceId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{phaseId}',
        payload.phaseId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{languageCode}',
        payload.languageCode
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{customerId}',
        payload.customerId
      )

      Services.axiosService.get(endpoint, res => {
        if (res) {
          commit('SET_SUPPLY_REQUEST_WIZARD_TEMPLATES', res)
          commit('SUPPLY_REQUEST_WIZARD_TEMPLATES_LOADED', res)
        }
      })
    },
    GET_FINANCIAL_YEAR_BY_SERVICE_ID({ commit }, payload) {
      commit('SUPPLY_REQUEST_WIZARD_FINANCIAL_YEARS_LOADED', payload)
    },
    GET_TEMPLATE_PREVIEW({ commit }, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.getTemplatePreview,
        '{templateId}',
        payload
      )

      Services.axiosService.get(endpoint, res => {
        if (res) {
          commit('SET_SUPPLY_REQUEST_WIZARD_TEMPLATE_PREVIEW', res)
        }
      })
    },
    RESET_TEMPLATE_PREVIEW({ commit }) {
      commit('UPDATE_SUPPLY_REQUEST_WIZARD_TEMPLATE_PREVIEW')
    },
    RESET_SUPPLY_REQUEST_WIZARD_PHASES_LOADED({ commit }, payload) {
      commit('UPDATE_SUPPLY_REQUEST_WIZARD_PHASES_LOADED', payload)
    },
    RESET_SUPPLY_REQUEST_WIZARD_LANGUAGES_LOADED({ commit }, payload) {
      commit('UPDATE_SUPPLY_REQUEST_WIZARD_LANGUAGES_LOADED', payload)
    },
    RESET_SUPPLY_REQUEST_WIZARD_TEMPLATES_LOADED({ commit }, payload) {
      commit('UPDATE_SUPPLY_REQUEST_WIZARD_TEMPLATES_LOADED', payload)
    },
    RESET_SUPPLY_REQUEST_WIZARD_FINANCIAL_YEARS_LOADED({ commit }, payload) {
      commit('UPDATE_SUPPLY_REQUEST_WIZARD_FINANCIAL_YEARS_LOADED', payload)
    },
    UPDATE_SELECTED_FINANCIAL_YEAR({ commit }, payload) {
      commit('UPDATE_SELECTED_FINANCIAL_YEAR', payload)
    },
    UPDATE_SELECTED_DUE_DATE({ commit }, payload) {
      commit('UPDATE_SELECTED_DUE_DATE', payload)
    },
    POST_SUPPLY_REQUEST_WIZARD_CREATE_CONCEPT(_, payload) {
      const endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + _targetApi.addSupplyRequest
      )

      const body = {
        SupplyRequestTemplateId: payload.templateId,
        CustomerId: payload.customerId,
        FinancialYear: payload.financialYear,
        Duedate: payload.dueDate,
        AssignedUserIds: payload.assignedUserIds
      }

      Services.axiosService.post(endpoint, body, res => {
        if (res) {
          window.location.href = res.RedirectUrl
        }
      })
    },
    UPDATE_ASSIGN_CONTACTS({ commit }, payload) {
      commit('UPDATE_ASSIGN_CONTACTS', payload)
    },
    GET_ASSIGN_CONTACTS({ commit }, payload) {
      //Due to the API endpoint being used on other places we are not adding the API endpoint to the "_targetApi" in order to reduce duplicated code
      let endpoint = Services.helpersService.buildEndpointRequest(
        _baseEndpoint + Api.assignContactsApi.getContactsByCustomerAndService,
        '{customerId}',
        payload.customerId
      )
      endpoint = Services.helpersService.buildEndpointRequest(
        endpoint,
        '{serviceId}',
        payload.serviceId
      )

      Services.axiosService.get(endpoint, res => {
        if (res) {
          const body = {
            contactsList: Services.assignContactsService.processContactsList(res),
            assignedContactsList: Services.assignContactsService.processAssignedContactsList(
              res
            )
          }

          commit('UPDATE_ASSIGN_CONTACTS', body)
        } else {
          //TODO: show toastmessage
        }
      })
    }
  },
  mutations: {
    SET_SUPPLY_REQUEST_WIZARD_HEADER: (state, payload) => {
      state.supplyRequestWizardHeader.title = payload.PageTitle
      state.supplyRequestWizardHeader.customerId = payload.CustomerId
      state.supplyRequestWizardHeader.customerName = payload.CustomerName
      state.supplyRequestWizardHeader.customerUrl = payload.CustomerUrl
    },
    SET_SUPPLY_REQUEST_WIZARD_FILTERS: (state, payload) => {
      state.supplyRequestWizardInitialFilters.services = Services.supplyRequest2AddService.processServices(
        payload
      )
      state.supplyRequestWizardInitialFilters.financialYears = Services.supplyRequest2AddService.processFinancialYears(
        payload
      )
    },
    SET_SUPPLY_REQUEST_WIZARD_DUEDATE: state => {
      state.supplyRequestWizardDueDate.dueDate = Services.supplyRequest2AddService.setDefaultDueDate()
    },
    SET_SUPPLY_REQUEST_WIZARD_PHASES: (state, payload) => {
      state.supplyRequestWizardPhases.phases = Services.supplyRequest2AddService.processPhases(
        payload
      )
    },
    SET_SUPPLY_REQUEST_WIZARD_LANGUAGES: (state, payload) => {
      state.supplyRequestWizardLanguages.languages = Services.supplyRequest2AddService.processLanguages(
        payload
      )
    },
    SET_SUPPLY_REQUEST_WIZARD_TEMPLATES: (state, payload) => {
      state.supplyRequestWizardTemplates.templates = Services.supplyRequest2AddService.processTemplates(
        payload
      )
    },
    SET_SUPPLY_REQUEST_WIZARD_TEMPLATE_PREVIEW: (state, payload) => {
      state.showPreview = true
      state.supplyRequestWizardTemplatePreview.phaseId = payload.PhaseId
      state.supplyRequestWizardTemplatePreview.id = payload.Id
      state.supplyRequestWizardTemplatePreview.serviceId = payload.ServiceId
      state.supplyRequestWizardTemplatePreview.languageCode = payload.LanguageCode
      state.supplyRequestWizardTemplatePreview.title = payload.Title
      state.supplyRequestWizardTemplatePreview.description = payload.Description
      state.supplyRequestWizardTemplatePreview.categories = Services.supplyRequest2AddService.processCategories(
        payload
      )
    },
    SUPPLY_REQUEST_WIZARD_PHASES_LOADED: state => {
      state.supplyRequestWizardPhases.phasesLoaded = true
    },
    UPDATE_SUPPLY_REQUEST_WIZARD_PHASES_LOADED: state => {
      state.supplyRequestWizardPhases.phasesLoaded = false
    },
    SUPPLY_REQUEST_WIZARD_LANGUAGES_LOADED: state => {
      state.supplyRequestWizardLanguages.languagesLoaded = true
    },
    UPDATE_SUPPLY_REQUEST_WIZARD_LANGUAGES_LOADED: state => {
      state.supplyRequestWizardLanguages.languagesLoaded = false
    },
    SUPPLY_REQUEST_WIZARD_TEMPLATES_LOADED: state => {
      state.supplyRequestWizardTemplates.templatesLoaded = true
    },
    UPDATE_SUPPLY_REQUEST_WIZARD_TEMPLATES_LOADED: state => {
      state.supplyRequestWizardTemplates.templatesLoaded = false
    },
    SUPPLY_REQUEST_WIZARD_FINANCIAL_YEARS_LOADED: state => {
      state.supplyRequestWizardInitialFilters.financialYearsLoaded = true
    },
    UPDATE_SUPPLY_REQUEST_WIZARD_FINANCIAL_YEARS_LOADED: state => {
      state.supplyRequestWizardInitialFilters.financialYearsLoaded = false
    },
    UPDATE_SUPPLY_REQUEST_WIZARD_TEMPLATE_PREVIEW: state => {
      state.showPreview = false
    },
    UPDATE_SELECTED_FINANCIAL_YEAR: (state, payload) => {
      state.supplyRequestWizardInitialFilters.financialYearSelectedValue = payload
    },
    UPDATE_SELECTED_DUE_DATE: (state, payload) => {
      state.supplyRequestWizardDueDate.dueDate = payload
    },
    UPDATE_ASSIGN_CONTACTS: (state, payload) => {
      state.supplyRequestAssignContacts.contactsList = payload.contactsList
      state.supplyRequestAssignContacts.assignedContactsList =
        payload.assignedContactsList
    }
  }
}
