import dictionaryService from '@/services/modules/dictionaryService'

export const localization = {
  labels: {
    firstName: dictionaryService.translate('Labels.Person.FirstName') as string,
    lastName: dictionaryService.translate('Labels.Person.LastName') as string,
    initials: dictionaryService.translate('Labels.Person.Initials') as string,
    fullName: dictionaryService.translate('Labels.Person.FullName') as string,
    salutation: dictionaryService.translate('Labels.Person.Salutation') as string,
    number: dictionaryService.translate('Labels.Person.Number') as string,
    username: dictionaryService.translate('Labels.Person.Email') as string,
    language: dictionaryService.translate('Labels.Person.LanguageSelected') as string,
    gender: dictionaryService.translate('Labels.Person.Gender') as string,
    goToEmployee: dictionaryService.translate(
      'EmployeeOverview.Labels.GoToEmployee'
    ) as string,
    close: dictionaryService.translate('Labels.Common.Close') as string,
    cancel: dictionaryService.translate('Labels.Common.Cancel') as string,
    save: dictionaryService.translate('Labels.Common.Save') as string,
    addOtherEmployee: dictionaryService.translate(
      'EmployeeOverview.Labels.AddOtherEmployee'
    ) as string,
    addEmployee: dictionaryService.translate(
      'EmployeeOverview.Labels.AddEmployee'
    ) as string
  },
  messages: {
    changesSucessfullySaved: dictionaryService.translate(
      'EmployeeDetail.Messages.ChangesSuccessfullySaved'
    ) as string,
    somethingWentWrong: dictionaryService.translate(
      'EmployeeOverview.Messages.SomethingWentWrong'
    ) as string,
    employeeSuccessfullyCreated: dictionaryService.translate(
      'EmployeeOverview.Messages.EmployeeSuccessfullyCreated'
    ) as string,
    fieldRequired: dictionaryService.translate(
      'EmployeeDetail.Messages.FieldRequired'
    ) as string,
    emailAlreadyExists: dictionaryService.translate(
      `EmployeeDetail.Messages.EmailAlreadyExists`
    ) as string,
    invalidEmail: dictionaryService.translate(
      `EmployeeDetail.Messages.InvalidEmail`
    ) as string
  }
}
