<template>
  <div class="maz-category-manager">
    <div>
      <!-- Nav tabs -->
      <ul
        class="nav nav-tabs c-category-bar mt-0"
        v-if="supplyRequestCategories.categories"
      >
        <li class="c-category-bar__items">
          <a
            :class="['c-category-bar__item', { active: index == 0 }]"
            :href="'#' + category.navigationId"
            :id="'#' + category.navigationId"
            :key="`nav-${category.id.clean}`"
            @click="initTabsNavigation"
            v-for="(category, index) in supplyRequestCategories.categories"
          >
            {{ index + 1 }}. {{ category.shortTitle }}
            <!-- Only show if its complete -->
            <maz-state-indicator
              :state_code="category.isCompleteCode"
              class="c-state-indicator--small float-right"
              v-if="category.isCompleteCode !== '9999'"
            />
            <div
              :class="{ 'is-complete': category.isComplete }"
              :style="{ width: category.percentageOfSegmentsCompleted + '%' }"
              class="c-category-bar__progress-bar"
            />
          </a>
        </li>
      </ul>
    </div>

    <!-- Start of adding a new category -->
    <div class="e-add-item" v-if="supplyRequestPermissions.mayAddSubItems">
      <div class="e-icon-add" />
      <a
        class="e-link-bold"
        data-target="#modalAddCategoryConfirmation"
        data-toggle="modal"
        >{{ labels.supplyRequestEditAddCategory }}</a
      >
    </div>
    <!-- End of adding a new category -->

    <div class="o-separator o-separator--small" />

    <!-- Tab panes -->
    <div class="tab-content">
      <div
        :class="['tab-pane', { active: index == 0 }, { fade: index != 0 }]"
        :id="category.navigationId"
        :key="`tab-${category.id.clean}`"
        v-for="(category, index) in supplyRequestCategories.categories"
      >
        <maz-supply-request-view-category
          :category="category"
          :id="category.id.original"
          :index="index"
          :indexPosition="(index + 1).toString() + '.'"
        />
      </div>
    </div>

    <!-- Start of modal add category -->
    <div
      aria-hidden="true"
      class="modal fade"
      id="modalAddCategory"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{{ labels.supplyRequestEditAddCategory }}</h3>
            <button
              @click="onCancelCategory"
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            />
          </div>
          <div class="modal-body modal-body--margin-bottom">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>{{ labels.supplyRequestEditCategoryShortTitle }}</label>
                  <input class="form-control" v-model="categoryShortTitleInput" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>{{ labels.supplyRequestEditCategoryTitle }}</label>
                  <input class="form-control" v-model="categoryTitleInput" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="form-group float-sm-right mb-0 d-flex flex-wrap flex-sm-nowrap"
                >
                  <maz-button
                    :label="labels.cancel"
                    @click.native="onCancelCategory"
                    aria-label="Close"
                    class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
                    data-dismiss="modal"
                  />
                  <maz-button
                    :disabled="isAddCategoryDisabled"
                    :label="labels.confirm"
                    @click.native="onSubmitCategory(category)"
                    aria-label="Close"
                    class="c-btn--green order-1 order-sm-2"
                    data-dismiss="modal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End of modal add category -->

    <!-- Modal confirmation add category -->
    <div
      aria-hidden="true"
      class="modal fade"
      id="modalAddCategoryConfirmation"
      role="dialog"
      tabindex="-1"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog--auto-width"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">
              {{ labels.supplyRequestConfirmationAddNewItemTitle }}
            </h3>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button" />
          </div>
          <div class="modal-body modal-body--margin-bottom">
            <p>{{ labels.supplyRequestConfirmationAddNewItem }}</p>
            <div class="o-separator o-separator--small" />
          </div>
          <div class="modal-footer d-flex flex-wrap flex-sm-nowrap">
            <maz-button
              :label="labels.cancel"
              aria-label="Close"
              class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
              data-dismiss="modal"
            />
            <maz-button
              :label="labels.confirm"
              @click.native="onShowAddCategoryDialog"
              aria-label="Close"
              class="c-btn--green order-1 order-sm-2"
              data-dismiss="modal"
              data-target="#modalAddCategory"
              data-toggle="modal"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- End of modal confirmation add category -->

    <!-- @click.native="onSubmitCategory(category)" -->
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import mazButton from '../../general/mazButton'
import mazSupplyRequestViewCategory from '../mazSupplyRequestViewCategory'
import mazStateIndicator from '../../general/mazStateIndicator'

const _targetStore = 'supplyRequest2DetailStore/'

export default {
  name: 'maz-supply-request-view-category-manager',
  components: {
    mazButton,
    mazSupplyRequestViewCategory,
    mazStateIndicator
  },
  props: {
    supply_request_categories: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      progress: 0,
      categoryTitleInput: '',
      categoryShortTitleInput: ''
    }
  },
  computed: {
    ...mapGetters({
      id: _targetStore + 'ID',
      supplyRequestCategories: _targetStore + 'SUPPLY_REQUEST_CATEGORIES',
      supplyRequestCategoryAddedId: _targetStore + 'SUPPLY_REQUEST_CATEGORY_ADDED_ID',
      supplyRequestPermissions: _targetStore + 'SUPPLY_REQUEST_PERMISSIONS',
      labels: _targetStore + 'LABELS'
    }),
    navTabs() {
      return $('.nav-tabs a')
    },
    isAddCategoryDisabled() {
      let result = true

      if (this.categoryTitleInput.length > 0 && this.categoryShortTitleInput.length > 0) {
        result = false
      }

      return result
    }
  },
  methods: {
    onSubmitCategory() {
      const body = {
        id: this.id,
        categoryId: this.getGuidId(),
        title: this.categoryTitleInput,
        shortTitle: this.categoryShortTitleInput,
        position: this.supplyRequestCategories.categories.length
      }

      this.$store.dispatch(_targetStore + 'POST_CATEGORY', body)

      $('#modalAddCategory').modal('hide')
      $('html').removeClass('modal-open') // Due to Bootstrap bug with class modal-open not alway attaching to the body properly

      this.cleanNewCategoryInputs()
    },
    onCancelCategory() {
      $('html').removeClass('modal-open') // Due to Bootstrap bug with class modal-open not alway attaching to the body properly
      this.cleanNewCategoryInputs()
    },
    onShowAddCategoryDialog() {
      $('html').addClass('modal-open') // Due to Bootstrap bug with class modal-open not alway attaching to the body properly
    },
    cleanNewCategoryInputs() {
      this.categoryTitleInput = ''
      this.categoryShortTitleInput = ''
    },
    initTabsNavigation(event) {
      this.centerBar(event)

      event.preventDefault() // prevents browser's default anchor behavior
      this.loadCategory(event.target)
    },
    loadCategory(targetCategory) {
      const _categories = this.supplyRequestCategories.categories

      _categories.forEach((category) => {
        const tempTargetNavigationId = targetCategory.getAttribute('href')
        const tempNavigationId = '#' + category.navigationId

        if (tempTargetNavigationId === tempNavigationId) {
          const body = {
            SupplyRequestId: category.parentSupplyRequestId.original,
            CategoryId: category.id.original
          }

          this.$store.dispatch(_targetStore + 'GET_CATEGORY_BY_ID', body)
        }
      })

      $(targetCategory).tab('show')
    },
    getGuidId() {
      let uuidValue = '',
        k,
        randomValue
      for (k = 0; k < 32; k++) {
        randomValue = (Math.random() * 16) | 0

        if (k == 8 || k == 12 || k == 16 || k == 20) {
          uuidValue += '-'
        }
        uuidValue += (k == 12
          ? 4
          : k == 16
            ? (randomValue & 3) | 8
            : randomValue
        ).toString(16)
      }
      return uuidValue
    },
    centerBar(event) {
      const parent = event.target.parentElement
      const child = event.target

      parent.scrollLeft =
        child.offsetLeft -
        (parent.offsetWidth - child.offsetWidth) +
        (parent.offsetWidth - child.offsetWidth) / 2 -
        parent.offsetLeft
    }
  },
  watch: {
    supplyRequestCategoryAddedId: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          // Open the tab for this category
          const notMatchingTab = document.getElementsByClassName('tab-pane')
          $(notMatchingTab).tab('dispose').removeClass('active')

          const tabPaneId = 'navigationId' + this.supplyRequestCategoryAddedId
          const matchingTabPane = document.getElementById(tabPaneId)
          $(matchingTabPane).tab('show').addClass('active')

          const tabId = '#navigationId' + this.supplyRequestCategoryAddedId
          const categoryBarTab = document.getElementById(tabId)
          $(categoryBarTab).tab('show').addClass('active')
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/__main.scss';

.c-category-bar {
  @include category-bar;
}
</style>
