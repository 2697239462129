export const getters = {
  LABELS: (state: any) => state.labels,
  SEARCH_EMPLOYEE_LIST: (state: any): object[] => state.searchEmployeeList,
  CURRENT_PAGE: (state: any): number => state.currentPage,
  LOADER: (state: any): boolean => state.loader,
  EMPLOYEE_LIST: (state: any): object[] => state.employeeList,
  MAX_PAGE_COUNTER: (state: any): number => state.maxPageCounter,
  MESSAGES: (state: any) => state.messages,
  EMPLOYEE_PERMISSION: (state: any) => state.employeePermission
}
