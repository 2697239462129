<template>
  <div class="c-fade-overlay">
    <div class="c-fade-overlay__content">
      <slot />
    </div>
    <transition name="fade">
      <div class="c-fade-overlay__background" v-if="showOverlay" />
    </transition>
  </div>
</template>

<script>
export default {
  name: "maz-fade-overlay",
  props: {
    "show-overlay": {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.c-fade-overlay {
  z-index: 1;
  .c-fade-overlay__content {
    position: relative;
    z-index: 2;
  }

  .c-fade-overlay__background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
</style>
