import { currentGetterContext } from './index'

export const getters = {
  EDITED_SERVICE_DETAILS(
    ...args: any
  ): MazInterfaces.ClientMaintenance.OrganizationService {
    const { state } = currentGetterContext(args)
    return state.editedServiceDetails
  },
  IS_EDIT_MODE(...args: any): boolean {
    const { state } = currentGetterContext(args)
    return state.isEditMode
  },
  LOADER(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.loader
  }
}
