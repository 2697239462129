export const getters = {
  STEP: (state: any) => state.step,
  LABELS: (state: any) => state.labels,
  MESSAGES: (state: any) => state.messages,
  CLIENT_OPTIONS: (state: any) => state.clientOptions,
  GENDER_OPTIONS: (state: any) => state.genders,
  LANGUAGE_OPTIONS: (state: any) => state.languages,
  RELATION_MANAGER_OPTIONS: (state: any) => state.relationManagers,
  CLIENT_TYPE: (state: any) => state.clientType,
  MODAL_HEADER_TEXT: (state: any) => {
    if (state.step === 1) {
      return state.labels.addClient
    } else if (state.step === 2) {
      return state.clientType === 10
        ? state.labels.createOrganization
        : state.labels.createContact
    } else if (state.step === 3) {
      return state.messages.clientSuccessfullyCreated
    } else {
      return ''
    }
  },
  VALIDATION_ERRORS: (state: any) => state.validationErrors,
  CLIENT_URL: (state: any) => state.clientUrl,
  MAY_SHOW_MODAL: (state: any) =>
    state.customerPermission.mayEditContact ||
    state.customerPermission.mayEditOrganization,
  SHOW_SELECT_CLIENT_TYPE: (state: any) => {
    if (state.step === 1 || state.step === 2) {
      if (
        state.customerPermission.mayEditContact &&
        state.customerPermission.mayEditOrganization
      ) {
        return true
      }
    }
    return false
  },
  EXISTING_RELATION_MANAGER_DETAILS: (state: any) => {
    const validationError = state.validationErrors.find(
      (obj: any) => obj.ErrorCode === 'EmailAlreadyExists'
    )
    if (validationError?.AdditionalData.length) {
      return {
        name: validationError.AdditionalData.find(
          (obj: any) => obj.Key === 'RelationManager'
        )?.Value,
        email: validationError.AdditionalData.find(
          (obj: any) => obj.Key === 'RelationManagerEmail'
        )?.Value,
        message: state.messages.emailExistsManagedBy
      }
    }
    return false
  },
  INVALID_EMAIL_DETAILS: (state: any) => {
    const validationError = state.validationErrors.find(
      (obj: any) => obj.ErrorCode === 'InvalidEmail'
    )
    if (validationError) {
      return {
        message: state.messages.invalidEmail
      }
    }
    return false
  },
  SHOULD_REFRESH_ON_CLOSE: (state: any) => {
    return state.shouldRefreshOnClose
  },
  COUNTRIES_LIST(state: any): MazInterfaces.ClientMaintenance.Countries[] {
    return state.countries
  }
}
