<template>
  <div class="qa-wizard-filters">
    <div class="row">
      <!-- Start of service line dropdown -->
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ labels.supplyRequestAddLabelsSelectService }}</label>
          <maz-drop-down
            :label="labels.supplyRequestAddLabelsSelectService"
            :options="supplyRequestWizardInitialFilters.services"
            :placeholder="labels.supplyRequestAddLabelsSelectService"
            @onChange="onChangeServiceFilter"
            prop="name"
            propvalue="id"
          />
        </div>
      </div>
      <!-- End of service line dropdown -->

      <!-- Start of phase dropdown -->
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ labels.supplyRequestAddLabelsSelectPhase }}</label>
          <maz-drop-down
            :class="{ disabled: isPhaseDisabled }"
            :label="labels.supplyRequestAddLabelsSelectPhase"
            :options="supplyRequestWizardPhases.phases"
            :placeholder="labels.supplyRequestAddLabelsSelectPhase"
            @onChange="onChangePhaseFilter"
            prop="name"
            propvalue="id"
          />
        </div>
      </div>
      <!-- End of phase dropdown -->

      <!-- Start of language dropdown -->
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ labels.supplyRequestAddLabelsSelectLanguage }}</label>
          <maz-drop-down
            :class="{ disabled: isLanguageDisabled }"
            :label="labels.supplyRequestAddLabelsSelectLanguage"
            :options="supplyRequestWizardLanguages.languages"
            :placeholder="labels.supplyRequestAddLabelsSelectLanguage"
            @onChange="onChangeLanguageFilter"
            prop="name"
            propvalue="code"
          />
        </div>
      </div>
      <!-- End of language dropdown -->
    </div>

    <div class="row">
      <!-- Start of template dropdown -->
      <div class="col-md-4">
        <div class="form-group mb-md-0">
          <label>{{ labels.supplyRequestAddLabelsSelectTemplate }}</label>
          <maz-drop-down
            :class="{ disabled: isTemplateDisabled }"
            :label="labels.supplyRequestAddLabelsSelectTemplate"
            :options="supplyRequestWizardTemplates.templates"
            :placeholder="labels.supplyRequestAddLabelsSelectTemplate"
            @onChange="onChangeTemplateFilter"
            prop="title"
            propvalue="id"
          />
        </div>
      </div>
      <!-- End of template dropdown -->

      <!-- Start of financial year dropdown -->
      <div class="col-md-4">
        <div class="form-group md-0">
          <label>{{ labels.supplyRequestAddLabelsSelectFinancialYear }}</label>
          <maz-drop-down
            :class="{ disabled: isFinancialYearDisabled }"
            :label="labels.supplyRequestAddLabelsSelectFinancialYear"
            :options="supplyRequestWizardInitialFilters.financialYears"
            :placeholder="labels.supplyRequestAddLabelsSelectFinancialYear"
            @onChange="onChangeFinancialYearFilter"
            prop="value"
            propvalue="value"
          />
        </div>
      </div>
      <!-- End of financial year dropdown -->

      <!-- Start of due date field -->
      <div class="col-md-4">
        <div class="form-group md-0">
          <label>{{ labels.supplyRequestAddLabelsSelectDueDate }}</label>
          <maz-date-picker
            :class="{ disabled: isDueDateDisabled }"
            :placeholder="currentDate"
            @input="onDateSelected"
            @onDateSelected="onDateSelected"
            required
          />
        </div>
      </div>
      <!-- End of due date field -->
    </div>

    <div class="o-separator o-separator--small" />
    <maz-assign-contacts
      :labels="labels"
      :store="store"
      :supplyRequestAssignContacts="supplyRequestAssignContacts"
      @assignedContactsOnInitialLoad="getAssignContactsList"
      v-if="showAssignContactsButton"
    />
  </div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import mazDatePicker from '../../general/mazDatePicker'
import mazDropDown from '../../general/mazDropDown'
import mazAssignContacts from '../../general/mazAssignContacts'

const _targetStore = 'supplyRequest2AddStore/'

export default {
  name: 'maz-wizard-sr-filters',
  components: {
    mazDatePicker,
    mazDropDown,
    mazAssignContacts
  },
  props: {
    placeholder: {
      required: false,
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      phaseSelectedValue: '',
      templateSelectedValue: '',
      languageSelectedValue: '',
      financialYearSelectedValue: '',
      serviceSelectedValue: '',
      customerId: '',
      isPhaseDisabled: true,
      isLanguageDisabled: true,
      isTemplateDisabled: true,
      isFinancialYearDisabled: true,
      isDueDateDisabled: true,
      newDueDate: '',
      currentDate: moment().format('DD-MM-YYYY'),
      showAssignContactsButton: false,
      store: 'supplyRequest2AddStore/'
    }
  },
  computed: {
    ...mapGetters({
      id: _targetStore + 'ID',
      supplyRequestWizardInitialFilters: _targetStore + 'SUPPLY_REQUEST_WIZARD_FILTERS',
      supplyRequestWizardPhases: _targetStore + 'SUPPLY_REQUEST_WIZARD_PHASES',
      supplyRequestWizardPhasesLoaded:
        _targetStore + 'SUPPLY_REQUEST_WIZARD_PHASES_LOADED',
      supplyRequestWizardLanguages: _targetStore + 'SUPPLY_REQUEST_WIZARD_LANGUAGES',
      supplyRequestWizardLanguagesLoaded:
        _targetStore + 'SUPPLY_REQUEST_WIZARD_LANGUAGES_LOADED',
      supplyRequestWizardTemplates: _targetStore + 'SUPPLY_REQUEST_WIZARD_TEMPLATES',
      supplyRequestWizardTemplatesLoaded:
        _targetStore + 'SUPPLY_REQUEST_WIZARD_TEMPLATES_LOADED',
      supplyRequestWizardFinancialYears:
        _targetStore + 'SUPPLY_REQUEST_WIZARD_FINANCIAL_YEARS',
      supplyRequestWizardFinancialYearsLoaded:
        _targetStore + 'SUPPLY_REQUEST_WIZARD_FINANCIAL_YEARS_LOADED',
      supplyRequestAssignContacts: _targetStore + 'SUPPLY_REQUEST_ASSIGN_CONTACTS',
      labels: _targetStore + 'LABELS'
    })
  },
  methods: {
    onChangeServiceFilter(value) {
      this.serviceSelectedValue = value

      this.setInitialDataIfChangingService()

      const body = {
        serviceId: this.serviceSelectedValue,
        customerId: this.id
      }

      this.$store.dispatch(_targetStore + 'GET_PHASES_BY_SERVICE_ID', body)
    },
    onChangePhaseFilter(value) {
      this.phaseSelectedValue = value

      const body = {
        serviceId: this.serviceSelectedValue,
        phaseId: this.phaseSelectedValue,
        customerId: this.id
      }

      this.setInitialDataIfChangingPhase()

      this.$store.dispatch(_targetStore + 'GET_LANGUAGES_BY_SERVICE_AND_PHASE_ID', body)
    },
    onChangeLanguageFilter(value) {
      this.languageSelectedValue = value

      const body = {
        serviceId: this.serviceSelectedValue,
        phaseId: this.phaseSelectedValue,
        languageCode: this.languageSelectedValue,
        customerId: this.id
      }

      this.setInitialDataIfChangingLanguage()

      this.$store.dispatch(
        _targetStore + 'GET_TEMPLATES_BY_SERVICE_PHASE_LANGUAGE_ID',
        body
      )
    },
    onChangeTemplateFilter(value) {
      this.templateSelectedValue = value

      const body = {
        serviceId: this.serviceSelectedValue,
        templateId: this.templateSelectedValue
      }

      if (this.financialYearSelectedValue != '') {
        this.$store.dispatch(
          _targetStore + 'GET_TEMPLATE_PREVIEW',
          this.templateSelectedValue
        )
      }

      this.$store.dispatch(_targetStore + 'GET_FINANCIAL_YEAR_BY_SERVICE_ID', body)
    },
    onChangeFinancialYearFilter(value) {
      this.financialYearSelectedValue = value

      this.showAssignContactsButton = true
      this.setInitialOrder = true

      this.$store.dispatch(
        _targetStore + 'UPDATE_SELECTED_FINANCIAL_YEAR',
        this.financialYearSelectedValue
      )
    },
    onDateSelected(event) {
      this.newDueDate = event

      this.$store.dispatch(_targetStore + 'UPDATE_SELECTED_DUE_DATE', this.newDueDate)
    },
    setInitialDataIfChangingService() {
      this.phaseSelectedValue = ''
      this.languageSelectedValue = ''
      this.templateSelectedValue = ''
      this.financialYearSelectedValue = ''

      this.isLanguageDisabled = true
      this.isTemplateDisabled = true
      this.isFinancialYearDisabled = true
      this.isDueDateDisabled = true

      this.$store.dispatch(_targetStore + 'RESET_TEMPLATE_PREVIEW')
    },
    setInitialDataIfChangingPhase() {
      this.languageSelectedValue = ''
      this.templateSelectedValue = ''
      this.financialYearSelectedValue = ''

      this.isLanguageDisabled = true
      this.isTemplateDisabled = true
      this.isFinancialYearDisabled = true
      this.isDueDateDisabled = true
      this.$store.dispatch(_targetStore + 'RESET_TEMPLATE_PREVIEW')
    },
    setInitialDataIfChangingLanguage() {
      this.templateSelectedValue = ''
      this.financialYearSelectedValue = ''

      this.isFinancialYearDisabled = true
      this.isDueDateDisabled = true

      this.$store.dispatch(_targetStore + 'RESET_TEMPLATE_PREVIEW')
    },
    getAssignContactsList() {
      const body = {
        customerId: this.id,
        serviceId: this.serviceSelectedValue
      }
      this.$store.dispatch(_targetStore + 'GET_ASSIGN_CONTACTS', body)
    }
  },
  watch: {
    supplyRequestWizardPhasesLoaded: {
      handler(newValue) {
        if (newValue == true) {
          if (!this.supplyRequestWizardPhases.phases.length > 0) {
            this.isPhaseDisabled = true
          } else {
            this.isPhaseDisabled = false
          }

          this.$store.dispatch(_targetStore + 'RESET_SUPPLY_REQUEST_WIZARD_PHASES_LOADED')
        }
      },
      deep: true
    },
    supplyRequestWizardLanguagesLoaded: {
      handler(newValue) {
        if (newValue == true) {
          if (!this.supplyRequestWizardLanguages.languages.length > 0) {
            this.isLanguageDisabled = true
          } else {
            this.isLanguageDisabled = false
          }

          this.$store.dispatch(
            _targetStore + 'RESET_SUPPLY_REQUEST_WIZARD_LANGUAGES_LOADED'
          )
        }
      },
      deep: true
    },
    supplyRequestWizardTemplatesLoaded: {
      handler(newValue) {
        if (newValue == true) {
          if (!this.supplyRequestWizardTemplates.templates.length > 0) {
            this.isTemplateDisabled = true
          } else {
            this.isTemplateDisabled = false
          }

          this.$store.dispatch(
            _targetStore + 'RESET_SUPPLY_REQUEST_WIZARD_TEMPLATES_LOADED'
          )
        }
      },
      deep: true
    },
    supplyRequestWizardFinancialYearsLoaded: {
      handler(newValue) {
        if (newValue == true) {
          if (!this.supplyRequestWizardInitialFilters.financialYears.length > 0) {
            this.isFinancialYearDisabled = true
          } else {
            this.isFinancialYearDisabled = false
          }

          this.$store.dispatch(
            _targetStore + 'RESET_SUPPLY_REQUEST_WIZARD_FINANCIAL_YEARS_LOADED'
          )
        }
      },
      deep: true
    },
    financialYearSelectedValue: {
      handler(value) {
        if (value != '') {
          this.isDueDateDisabled = false
          this.$store.dispatch(
            _targetStore + 'GET_TEMPLATE_PREVIEW',
            this.templateSelectedValue
          )
        }
      },
      deep: true
    }
  }
}
</script>
