<template>
  <div class="c-card c-card--noshadow my-3 pb-4 js-documentrequest-detail">
    <div v-if="!loading && !showNotFound" class="mb-2">
      <div class="c-page-header row mb-2 u-mb-sm-0">
        <div class="col-12 col-lg-9 col-md-8 col-sm-7">
          <div class="d-flex align-items-center">
            <h1>
              {{ FullName }}
              <fa icon="check" class="c-completed-check" v-if="Status === 300" />
            </h1>
          </div>
          <h4 class="mt-1">
            <a class="c-page-header__customer-name" v-bind:href="CustomerUrl">
              {{ CustomerName }}
            </a>
          </h4>
          <div
            class="row mt-3 c-period-edit-area__title"
            v-if="canEditTasks && isTaskEditMode"
          >
            <div class="c-period-edit-area__period-label">
              {{ translate("WorkflowDefinitionAdd.Labels.Title") }}
            </div>
            <maz-text-input :is-danger="isEmptyEditTitle" v-model="FilingName" />
          </div>
          <!-- Update year and start date for inactive workflow -->
          <div
            class="row mt-1 c-period-edit-area"
            v-if="canEditTasks && isTaskEditMode && mayChangeYear"
          >
            <div class="col-12 col-md-4">
              <div class="c-period-edit-area__period-label">
                {{ translate("WorkflowDefinitionAdd.Labels.Period") }}
              </div>
              <maz-drop-down
                data-cy="yearDropdown"
                :label="Year"
                :options="periods"
                prop="Year"
                propvalue="Year"
                v-model="Year"
              />
            </div>
            <div class="col-12 col-md-4">
              <div class="c-period-edit-area__period-label">
                {{ translate("WorkflowDefinitionAdd.Labels.StartDate") }}
              </div>
              <maz-workflow-details-date-picker
                v-model="StartDate"
                :options="{ format: 'DD/MM/YYYY' }"
                v-on:input="onStartDateChanged()"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 col-md-4 col-sm-5 d-flex flex-column">
          <div class="workflow-detail__top-icons">
            <div class="float-right">
              <a
                class="c-button c-button--link"
                v-on:click="toggleConfirmRollOver(true)"
                v-bind:class="[{ 'is-disabled': isTaskEditMode }]"
              >
                <fa
                  :icon="['fal', 'clone']"
                  size="2x"
                  :title="translate('WorkflowDetail.Labels.RollOverWorkflow')"
                />
              </a>
              <a
                class="c-button c-button--link c-button__edit"
                :title="translate('WorkflowDetail.Labels.Edit')"
                v-on:click="toggleEditMode"
                v-if="canEditTasks"
                v-bind:class="[{ 'is-active': isTaskEditMode }]"
              >
                <fa
                  :icon="['fal', 'pen']"
                  size="2x"
                  :title="translate('WorkflowDetail.Labels.Edit')"
                />
              </a>
              <a
                class="c-button c-button--link"
                @click="toggleConfirmDelete(true)"
                v-if="MayDelete"
                v-bind:class="[{ 'is-disabled': isTaskEditMode }]"
              >
                <fa
                  :icon="['fal', 'trash-alt']"
                  size="2x"
                  :title="translate('WorkflowDetail.Labels.DeleteWorkflow')"
                />
              </a>
            </div>
          </div>
          <maz-button class="c-btn c-btn--ghost hide">
            {{ translate("WorkflowDetail.Labels.ShareDossier") }}
          </maz-button>
          <maz-button
            class="c-btn c-btn--ghost ml-0"
            @click.native="toggleWorkflowHistoryLightBox"
          >
            {{ translate("WorkflowDetail.Labels.ShowWorkflowHistory") }}
          </maz-button>
        </div>
      </div>

      <!-- Documents to sign -->
      <div class="c-files is-pki-page" v-if="signingFilesPanelVisible">
        <h2 class="section-header">
          {{ translate("TaskDetail.Labels.DocumentToSign") }}
        </h2>
        <div
          class="section c-signingfiles-wrapper"
          v-bind:class="{ 'is-signed': documentHasSigned }"
        >
          <div class="d-flex">
            <a
              v-if="DownloadDocuSignFiles"
              class="c-btn mb-2 c-download-all-button"
              :href="downloadSignedFilesUrl"
            >
              {{ translate("WorkflowDetail.Labels.DownloadAll") }}</a
            >
          </div>
          <div class="row justify-content-md-between">
            <div
              class="col-sm-6 row mb-4"
              v-for="(file, index) in workflowSigningFiles"
              v-bind:key="index"
            >
              <div class="col-sm-2">
                <fa class="c-file-icon" :icon="['fal', file.iconClass]" />
              </div>
              <div class="col-sm-8">
                <a
                  class="c-file-name"
                  v-bind:class="{ 'c-file-name--blocked': file.isInfected }"
                  v-bind:href="file.DownloadUrl"
                >
                  {{ file.FileName }}
                </a>
                <p class="c-file-uploaded-date">
                  <b>{{ translate("WorkflowDetail.Labels.DateUploaded") }}:</b>
                  {{ file.CreatedDateDescription }}
                </p>
                <p class="c-file-uploader">
                  <b>{{ translate("WorkflowDetail.Labels.UploadedBy") }}:</b>
                  {{ file.Uploader !== null ? file.Uploader.FullName : "N/A" }}
                </p>
                <p class="c-file-scan-status">
                  <b>{{ translate("WorkflowDetail.Labels.ScanStatus") }}:</b>
                  {{ file.scanStatus }} {{ file.scanDateDescription }}
                </p>
              </div>
              <div class="col-sm-12">
                <div class="c-msg-wrapper">
                  <!-- Success message -->
                  <div
                    class="c-msg c-msg--success d-flex align-items-center"
                    v-if="file.IsSigned"
                  >
                    <div class="col-sm-2 text-center">
                      <div class="c-msg__icon-rosette" />
                    </div>
                    <div class="col-sm-12 c-msg__message">
                      <span>
                        {{ translate("TaskDetail.Messages.SuccessSigning") }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p
              class="c-no-content-message"
              v-if="!workflowSigningFiles || workflowSigningFiles.length === 0"
            >
              {{ translate("TaskDetail.Messages.NoDocumentsToShow") }}
            </p>
          </div>
        </div>
      </div>

      <!--Documents-->
      <div class="c-files" v-if="workflowFiles && workflowFiles.length">
        <h2 class="section-header">
          {{ translate("WorkflowDetail.Labels.Documents") }}
        </h2>
        <div class="section">
          <div class="d-flex">
            <a
              v-if="showDownloadAllButton"
              class="c-btn mb-2 c-download-all-button"
              :href="downloadFilesUrl"
            >
              {{ translate("WorkflowDetail.Labels.DownloadAll") }}</a
            >
          </div>
          <div class="row justify-content-md-between">
            <div
              class="col-sm-6 row mb-4"
              v-for="(file, index) in workflowFiles"
              v-bind:key="index"
            >
              <div class="col-sm-2">
                <fa class="c-file-icon" :icon="['fal', file.iconClass]" />
              </div>
              <div class="col-sm-8">
                <a
                  class="c-file-name"
                  v-bind:class="{ 'c-file-name--blocked': file.isInfected }"
                  v-bind:href="file.DownloadUrl"
                >
                  {{ file.FileName }}
                </a>
                <p class="c-file-uploaded-date">
                  <b>{{ translate("WorkflowDetail.Labels.DateUploaded") }}:</b>
                  {{ file.CreatedDateDescription }}
                </p>
                <p class="c-file-uploader">
                  <b>{{ translate("WorkflowDetail.Labels.UploadedBy") }}:</b>
                  {{ file.Uploader !== null ? file.Uploader.FullName : "N/A" }}
                </p>
                <p class="c-file-scan-status">
                  <b>{{ translate("WorkflowDetail.Labels.ScanStatus") }}:</b>
                  {{ file.scanStatus }} {{ file.scanDateDescription }}
                </p>
              </div>
              <div class="col-sm-2">
                <a
                  class="c-file-trash-button"
                  v-on:click="deleteFile(file, index)"
                  v-if="file.IsDeletable"
                >
                  <i><fa :icon="['fal', 'trash-alt']" /></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Comments-->
      <div class="c-comments" v-if="comments && comments.length">
        <h2 class="section-header">
          {{ translate("WorkflowDetail.Labels.Comments") }}
        </h2>
        <div class="section">
          <div class="row" v-for="(comment, index) in comments" v-bind:key="index">
            <div class="col-sm-2">
              <p class="c-comment-date u-fixed-line-height text-nowrap">
                {{ comment.presentationDate }}
              </p>
            </div>
            <div class="col-sm-10 pl-sm-2">
              <p class="c-comment-description u-fixed-line-height">
                {{ comment.Description }}
              </p>
              <p class="c-comment-created-by d-flex align-items-center">
                <b> {{ translate("WorkflowDetail.Labels.CommentPlacedBy") }}: </b>
                {{ comment.CreatedBy != null ? comment.CreatedBy.FullName : "" }}
                <span class="c-comment__internal" v-show="comment.IsInternal">
                  {{ translate("TaskDetail.Labels.Internal") }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!--Workflow-->
      <div class="c-task-section-header-container d-flex justify-content-between">
        <h2 class="section-header">
          {{ translate("WorkflowDetail.Labels.Workflow") }}
        </h2>
      </div>
      <transition-group name="fade">
        <div
          class="c-task"
          v-for="(task, index) in tasks"
          v-bind:key="'task' + index"
          :class="{ 'dragging-over': canDragOverCurrent(index) }"
          :ref="`task-container--${index}`"
          v-on="{
            drop: (e) => onDragDrop(e, index),
            dragover: (e) => onDragOver(e, index),
            dragenter: (e) => onDragEnter(e, index),
          }"
        >
          <!-- when edit mode is on-->
          <div v-if="task.MayBeChanged && isTaskEditMode">
            <div class="c-task-header-container row">
              <!-- delete button -->
              <div
                class="c-edit-workflow__delete-button"
                v-on:click="deleteExistingTask(index)"
                v-if="task.Status == 100"
              >
                <fa :icon="['fal', 'trash-alt']" />
              </div>
              <!-- drag drop move button -->
              <div
                class="c-add-workflow__drag-button"
                draggable="true"
                v-show="showMoveButton"
                v-on="{
                  dragstart: (e) => onDragStart(e, index),
                  dragover: (e) => e.preventDefault(),
                  dragend: (e) => onDragEnd(),
                }"
              >
                <fa :icon="['fal', 'arrows']" />
              </div>
              <div class="col-sm-1">
                <maz-task-status-circle
                  v-bind:progress-status="task.Status"
                  v-bind:overdue-status="task.DueDateStatus"
                />
              </div>
              <div class="col-sm-11">
                <div>
                  <div class="row">
                    <div class="col-12">
                      <h5 class="c-card__label c-label c-label--larger">
                        {{ task.Name }}
                      </h5>
                    </div>
                  </div>
                  <div class="row mt-1 mb-1 c-edit-workflow__input-area">
                    <div class="col-12 col-sm-6">
                      <label>{{
                        translate("WorkflowDetail.Labels.DescriptionPlaceholder")
                      }}</label>
                      <maz-text-input v-model="task.Description" />
                    </div>
                    <div class="col-12 col-sm-2">
                      <label style="opacity: 0 !important">.</label>
                      <maz-button
                        v-if="task.AssignedUsers.length === 0"
                        class="c-btn c-btn--assign-contacts c-btn--smaller js-add-user c-edit-workflow__assign-task-btn mx-auto"
                        @click.native="setUsers(index, $event)"
                      />
                    </div>
                    <div class="col-12 col-sm-4">
                      <label :for="task.Id">{{
                        translate("WorkflowDefinitionAdd.Labels.DueDate")
                      }}</label>
                      <maz-workflow-details-date-picker
                        :id="task.Id"
                        v-model="task.DueDate"
                        :options="{
                          format: 'DD/MM/YYYY',
                          minDate: minDate(task, index),
                        }"
                      />
                      <p v-show="isWeekendDay(task.DueDate)" class="w-full">
                        <fa
                          :icon="['fal', 'exclamation-triangle']"
                          size="sm"
                          class="c-date__weekend-warning mr-1"
                        />
                        {{ translate("WorkflowDetail.Messages.WeekendDayWarning") }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6 mt-1">
                      <div
                        class="c-card__userslist d-flex"
                        v-if="taskEditAssignedUsers.length > 0"
                      >
                        <div class="c-userslist__wrapper pl-0 pr-0 mr-1 col-12">
                          <template v-for="taskData in taskEditAssignedUsers">
                            <template v-if="taskData.TaskId === task.Id">
                              <span
                                class="c-card__usersitem"
                                v-for="(user, index) in taskData.AssignedUsers"
                                :key="index + `${user.Id}`"
                                >{{ user.Name }}</span
                              >
                            </template>
                          </template>
                        </div>
                        <div
                          v-if="task.AssignedUsers.length > 0"
                          class="col-12 pl-0 pr-0 pt-1 c-users-edit"
                          v-on:click="setUsers(index, $event)"
                        >
                          <span>
                            {{ translate("WorkflowDefinitionAdd.Labels.EditUsers") }}
                          </span>
                          <fa :icon="['fal', 'pen']" class="ml-1" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- when edit mode is off-->
          <div v-else>
            <div class="c-task-header-container row flex-wrap flex-sm-nowrap">
              <div class="col-6 col-sm-1 c-task-header-item">
                <maz-task-status-circle
                  v-bind:progress-status="task.Status"
                  v-bind:overdue-status="task.DueDateStatus"
                />
              </div>
              <div class="col-12 col-sm-3 c-task-header-item u-alignment-padding">
                <h5>
                  <a class="c-task-name" v-bind:href="task.Url">{{ task.Name }}</a>
                </h5>
              </div>
              <div class="col-12 col-sm-3 c-task-header-item u-alignment-padding">
                <p class="c-task-due-date">
                  <b> {{ translate("WorkflowDetail.Labels.DueDate") }}: </b>
                  {{ task.dueDateDescription }}
                </p>
              </div>
              <div class="col-12 col-sm-4 c-task-header-item u-alignment-padding">
                <p class="c-task-completed-by" v-if="task.completedDescription !== null">
                  <b> {{ translate("WorkflowDetail.Labels.Completed") }}: </b>
                  {{ task.completedDescription }}
                </p>
              </div>
              <div
                class="col-6 col-sm-1 d-flex c-task-header-item justify-content-end align-items-baseline"
              >
                <a
                  v-on:click="toggleTaskCollapse(task)"
                  style="cursor: pointer; user-select: none"
                >
                  <div
                    class="c-arrow-img"
                    v-bind:class="{ isVisible: task.open }"
                    aria-hidden="true"
                    alt="arrow"
                  />
                </a>
              </div>
            </div>

            <maz-collapse :id="'task_' + index">
              <div class="row" v-show="task.open">
                <div class="col-sm-1" />
                <div class="col-sm-6 mb-2 u-mb-md-0">
                  <p class="c-task-description">{{ task.Description }}</p>
                  <div
                    class="c-card__userslist d-flex"
                    v-if="taskAssignedUsers.length > 0"
                  >
                    <div class="c-userslist__wrapper mt-1 mr-1">
                      <template v-for="taskData in taskAssignedUsers">
                        <template v-if="taskData.TaskId === task.Id">
                          <span
                            class="c-card__usersitem"
                            v-for="(user, index) in taskData.AssignedUsers"
                            :key="index + `${user.Id}`"
                            >{{ user.Name }}</span
                          >
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="col-sm-5">
                  <div class="row">
                    <div class="col-sm-6">
                      <p class="c-task-details-label">
                        {{ translate("WorkflowDetail.Labels.ExpectedStartDate") }}:
                      </p>
                    </div>
                    <div class="col-sm-6">
                      <p class="c-task-details-value">
                        {{ task.expectedStartDateDescription }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <p
                        class="c-task-details-label"
                        v-if="task.ActualStartDate !== null"
                      >
                        {{ translate("WorkflowDetail.Labels.ActualStartDate") }}:
                      </p>
                    </div>
                    <div class="col-sm-6">
                      <p
                        class="c-task-details-value"
                        v-if="task.ActualStartDate !== null"
                      >
                        {{ task.actualStartDateDescription }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </maz-collapse>
          </div>
        </div>
      </transition-group>
      <maz-alert
        v-if="showError"
        class="mb-2"
        :message="errorMessage"
        :is-danger="true"
      />
    </div>

    <div class="row" v-if="canEditTasks && isTaskEditMode">
      <div class="col-md-6">
        <!-- Base Steps dropdown -->
        <div class="float-left d-flex c-edit-workflow__add-step" v-if="baseSteps.length">
          <div class="c-add-step-dropdown" style="min-width: 300px">
            <maz-drop-down
              data-cy="baseStepsDropdown"
              ref="selectedStepDropdown"
              :label="translate('WorkflowDefinitionAdd.Labels.SelectBaseTemplate')"
              :options="baseSteps"
              prop="StepName"
              propvalue="BaseWorkflowStepId"
              v-model="currentBaseStep"
            />
          </div>
          <!-- Add step button -->
          <maz-button
            id="addStepButton"
            class="c-btn c-btn--smaller c-addstep ml-2 c-edit-workflow__add-step-btn"
            @click.native="addWorkflowStep()"
            v-bind:disabled="addStepDisabled"
          >
            {{ translate("WorkflowDefinitionAdd.Labels.AddStep") }}
          </maz-button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="float-right d-flex c-edit-workflow__save-step">
          <!-- cancel button -->
          <maz-button
            class="c-btn c-btn--empty c-btn--smaller c-start js-save ml-auto mb-0"
            :title="translate('WorkflowDetail.Labels.Cancel')"
            @click.native="cancelEditWorkFlow"
          >
            {{ translate("WorkflowDetail.Labels.Cancel") }}
          </maz-button>
          <!-- save button -->
          <maz-button
            class="c-btn c-btn--green c-btn--smaller c-start js-save ml-auto mb-0 float-right"
            :title="translate('WorkflowDetail.Labels.Save')"
            :disabled="!saveAllowed"
            @click.native="updateWorkflow"
          >
            {{ translate("WorkflowDetail.Labels.Save") }}
          </maz-button>
        </div>
      </div>
    </div>

    <div class="loading-container" v-show="loading">
      <fa icon="spinner" spin size="2x" />
    </div>

    <div class="not-found-container" v-show="showNotFound">
      <h3>
        {{ translate("WorkflowDetail.Messages.NotFoundTitle") }}
      </h3>
      <p>
        {{ translate("WorkflowDetail.Messages.NotFoundText") }}
      </p>
    </div>

    <!--Workflow History-->
    <maz-workflow-history-lightbox
      :show="showWorkflowHistoryLightBox"
      :workflow-id="WorkflowId"
      :on-close="toggleWorkflowHistoryLightBox"
      :can-export="canExportWorkflowHistory"
      :export-url="workflowHistoryExportUrl"
      v-if="WorkflowId !== null"
    />

    <maz-confirmation-lightbox
      :show="showConfirmDelete"
      :message="translate('WorkflowDetail.Labels.ConfirmDeleteWorkflow')"
      @confirm="deleteWorkflow"
      @cancel="toggleConfirmDelete(false)"
    />

    <maz-confirmation-lightbox
      :show="showConfirmRollOver"
      :message="translate('WorkflowDetail.Message.ConfirmRollOverWorkflow')"
      @confirm="rollOverWorkflow"
      @cancel="toggleConfirmRollOver(false)"
    />

    <maz-assigned-users-lightbox
      class="c-assignedusers-lightbox"
      v-if="currentTask"
      v-bind:contacts-list="currentTask.users"
      v-bind:employees-list="currentTask.employees"
      v-bind:selected-contacts-list="currentTask.selectedUsers"
      v-bind:selected-employees-list="currentTask.selectedEmployees"
      v-bind:show="usersLightbox.open"
      v-bind:on-submit="submitLightbox"
      v-bind:is-user-delegated-on-add="false"
      v-bind:on-close="closeLightbox"
    />
  </div>
</template>

<script>
import shared from "./shared";

export default {
  name: "mazWorkflowDetailsEmployee",
  mixins: [shared],
};
</script>
<style lang="scss" scoped>
@import "./legacy-utilities.scss";
</style>

<style lang="scss">
@import "@/styling/custom/settings/__main.scss";
@import "./js-documentrequest.scss";
@import "./js-documentrequest-detail.scss";
</style>
