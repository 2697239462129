import dictionaryService from '@/services/modules/dictionaryService'

export const localization = {
  labels: {
    connectedOrganizationsTitle: dictionaryService.translate(
      'MyProfile.Labels.connectedOrganizationsTitle'
    ) as string,
    accountNumber: dictionaryService.translate('Labels.Person.AccountNumber') as string
  },
  connectedOrganizationsTable: {
    labels: {
      legalName: dictionaryService.translate('MyProfile.Labels.LegalName') as string,
      organizationNumber: dictionaryService.translate(
        'MyProfile.Labels.OracleOrganizationId'
      ) as string
    }
  },
  organizationPermissionModal: {
    labels: {
      service: dictionaryService.translate('MyProfile.Labels.Services') as string,
      read: dictionaryService.translate('Labels.Permissions.Read') as string,
      write: dictionaryService.translate('Labels.Permissions.Write') as string,
      download: dictionaryService.translate('Labels.Permissions.Download') as string,
      upload: dictionaryService.translate('Labels.Permissions.Upload') as string
      // signals: 'Notifications',
    }
  },
  messages: {
    somethingWentWrong: dictionaryService.translate(
      'MyProfile.Messages.SomethingWentWrong'
    ) as string,
    noLinkedOrganizations: dictionaryService.translate(
      'MyProfile.Messages.NoLinkedOrganizations'
    ) as string
  }
}
