import clientMyProfilePermissionsColumnOrderService from '@/services/modules/clientMyProfilePermissionsColumnOrderService'
import { localization } from './localization'
export default () => ({
  loader: {
    viewOrganizationTable: false,
    viewPermissionModal: false
  },
  messages: { ...localization.messages },
  labels: { ...localization.labels },
  connectedOrganizations: {
    labels: { ...localization.connectedOrganizationsTable.labels },
    rows: [] as object[],
    available: false
  },
  organizationPermissionModal: {
    columns: clientMyProfilePermissionsColumnOrderService,
    details: {
      organizationDetails: {},
      permissionsDetails: {}
    },
    labels: { ...localization.organizationPermissionModal.labels }
  }
})
