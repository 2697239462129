<template>
  <div :class="{ 'c-segment--edit': editState }" class="c-edit-segment qa-segment">
    <div class="d-md-flex align-items-center position-relative">
      <div class="flex-fill">
        <div class="d-flex align-items-center flex-wrap flex-sm-nowrap" v-if="!editState">
          <maz-state-indicator
            :state_code="segment.isCompleteCode"
            :state_number="indexPosition"
            show_state_number
          />
          <div class="flex-fill order-3 order-sm-2">
            <h5 class="c-segment__title float-left u-pt-14px u-pt-sm-0">
              {{ segment.title }}
            </h5>
          </div>
          <div class="c-icons c-icons--categories order-2 order-sm-3">
            <div
              @click="onEditSegment(segment.id, segment)"
              class="c-icons__item c-icons__item--move"
            />
            <div
              @click="onEditSegment(segment.id, segment)"
              class="c-icons__item c-icons__item--edit"
              ref="editIcon"
            />
            <div
              @click="onDeleteSegment(category, subCategory, segment)"
              class="c-icons__item c-icons__item--trash"
            />
          </div>
        </div>

        <!-- EDIT STATE -->
        <div class="d-flex align-items-center flex-wrap flex-sm-nowrap" v-if="editState">
          <maz-state-indicator
            :state_code="segment.isCompleteCode"
            :state_number="indexPosition"
            class="order-1"
            show_state_number
          />
          <div class="flex-fill order-3 order-sm-2">
            <div class="c-segment--edit__title-input">
              <div class="form-group mb-0">
                <input class="form-control" v-model="cloneOfSegmentItem.title" />
              </div>
            </div>
          </div>
          <div class="c-icons c-icons--categories order-2 order-sm-3">
            <div
              class="c-icons__item c-icons__item--edit c-icons__item--edit-highlighted"
            />
            <div
              class="c-icons__item c-icons__item--trash c-icons__item--trash-disabled"
            />
          </div>
        </div>
        <!-- END OF EDIT STATE -->
      </div>
    </div>
    <div class="c-segment__content" v-if="!editState">
      <div :id="segment.id" class="c-segment__body">
        <div class="o-separator o-separator--extra-small" v-if="segment.description" />
        <div class="u-pre-wrap">{{ segment.description }}</div>

        <div class="c-segment-list" v-if="segment.segmentItems.length > 0">
          <ul class="c-segment-list__list">
            <li
              :key="'list-item-' + index"
              class="c-segment-list__item"
              v-for="(listItem, index) in segment.segmentItems"
            >
              {{ listItem.title }}
            </li>
          </ul>
        </div>

        <div
          class="o-separator o-separator--extra-small"
          v-if="segment.attachments.length > 0"
        />

        <maz-attachment-list
          :segment="segment"
          class="c-attachments--plain c-attachments--view-state"
          v-if="segment.attachments.length > 0"
        />
      </div>
    </div>

    <!-- EDIT STATE -->
    <div class="c-segment--edit__content" v-if="editState">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>{{ labels.supplyRequestEditSegmentDescription }}</label>
            <textarea
              class="form-control c-textarea--height"
              v-model="cloneOfSegmentItem.description"
            />
          </div>
        </div>
      </div>
      <div class="c-segment-list" v-if="cloneOfSegmentItem.segmentItems.length > 0">
        <ul class="c-segment-list__list">
          <li
            :key="'list-item-2-' + index"
            class="c-segment-list__item d-flex align-items-center"
            v-for="(listItem, index) in cloneOfSegmentItem.segmentItems"
          >
            <input class="form-control" v-model="listItem.title" />
            <div @click="onDeleteListItem(index)">
              <div class="c-segment-list__delete" />
            </div>
          </li>
        </ul>
      </div>
      <div class="e-add-item">
        <div class="e-icon-add" />
        <a @click="onAddListItem(category, subCategory, segment)" class="e-link-bold">{{
          labels.supplyRequestEditAddListItem
        }}</a>
      </div>
      <div class="o-separator o-separator--small" />

      <maz-attachment-list
        :segment="segment"
        class="c-attachments--plain"
        v-if="segment.attachments.length > 0"
      />

      <div class="o-separator o-separator--small" v-if="segment.attachments.length > 0" />

      <div class="d-sm-flex justify-content-between" v-if="!activeFileUploader">
        <div class="d-sm-flex">
          <maz-button
            :label="supplyRequestFileUploader.labels.addTemplate"
            @click.native="toggleFileUploader"
            class="c-btn--blue"
            v-if="segment.id.original"
          />
        </div>

        <div class="o-separator o-separator--small d-md-none" />

        <div class="d-sm-flex">
          <maz-button
            :label="labels.cancel"
            @click.native="onCancel(category, subCategory, segment)"
            class="c-btn--empty order-1 order-sm-1"
          />
          <maz-button
            :disabled="isButtonDisabled"
            :label="labels.confirm"
            @click.native="onSubmitEditSegment(category, subCategory, segment, index)"
            class="c-btn--blue order-2 order-sm-2"
          />
        </div>
      </div>

      <div v-if="activeFileUploader">
        <div>
          <maz-button
            :label="supplyRequestFileUploader.labels.addTemplate"
            @click.native="toggleFileUploader"
            class="c-btn--blue"
            v-if="segment.id.original"
          />
        </div>

        <div class="o-separator o-separator--small" />

        <maz-file-upload
          :category="category"
          :segment="segment"
          :store="store"
          :subCategory="subCategory"
          :supplyRequestFileUploader="supplyRequestFileUploader"
          :uploadUrl="uploadUrl"
        />

        <div class="o-separator o-separator--small" />

        <div class="d-inline-block w-100 text-right">
          <div class="d-inline-block">
            <maz-button
              :label="labels.cancel"
              @click.native="onCancel(category, subCategory, segment)"
              class="c-btn--empty float-left"
            />
            <maz-button
              :disabled="isButtonDisabled"
              :label="labels.confirm"
              @click.native="onSubmitEditSegment(category, subCategory, segment, index)"
              class="c-btn--blue float-left"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- END OF EDIT STATE -->
  </div>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import mazButton from '../../general/mazButton'
import mazStateIndicator from '../../general/mazStateIndicator'
import mazFileUpload from '../../general/mazFileUpload'
import mazAttachmentList from '../../general/mazAttachmentList'

const _targetStore = 'supplyRequest2EditStore/'

export default {
  name: 'maz-supply-request-edit-segment',
  components: {
    'maz-button': mazButton,
    'maz-state-indicator': mazStateIndicator,
    'maz-file-upload': mazFileUpload,
    'maz-attachment-list': mazAttachmentList
  },
  props: {
    indexPosition: {
      required: false,
      type: String,
      default() {
        return ''
      }
    },
    index: {
      required: false,
      type: Number,
      default() {
        return ''
      }
    },
    segment: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    subCategory: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    },
    category: {
      required: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      editSegmentTitleInput: this.segment.title,
      editSegmentDescriptionInput: this.segment.description,
      editSegmentListItemInput: this.segment.segmentItems,
      editState: false,
      segmentItem: this.segment,
      cloneOfSegmentItem: '',
      activeFileUploader: false,
      showFileList: true,
      store: 'supplyRequest2EditStore/',
      uploadUrl: 'uploadattachment'
    }
  },
  computed: {
    ...mapGetters({
      id: _targetStore + 'ID',
      labels: _targetStore + 'LABELS',
      supplyRequestFileUploader: _targetStore + 'SUPPLY_REQUEST_FILE_UPLOADER'
    }),
    isButtonDisabled() {
      let result = true

      let emptyListItem = false

      this.cloneOfSegmentItem.segmentItems.forEach(item => {
        if (item.title === '') {
          emptyListItem = true
        }
        return true
      })

      if (this.cloneOfSegmentItem.title.length > 0 && emptyListItem != true) {
        result = false
      }

      return result
    }
  },
  methods: {
    onEditSegment(segmentId, segment) {
      if (segment == undefined) {
        // In case the segment does not exist yet
        this.cloneOfSegmentItem = JSON.parse(JSON.stringify(this.segmentItem))
      } else {
        // In case the segment exists
        this.cloneOfSegmentItem = JSON.parse(JSON.stringify(segment))
      }

      this.editState = true
    },
    onSubmitEditSegment(category, subCategory, segment, index) {
      this.activeFileUploader = false

      if (segment.id.original != undefined) {
        // In case the segment does not exist yet

        const body = {
          supplyRequestId: this.id,
          categoryId: category.id.original,
          groupSegmentId: subCategory.id.original,
          segmentId: segment.id.original,
          segmentData: {
            title: this.editSegmentTitleInput,
            description: this.editSegmentDescriptionInput,
            segmentItems: this.cloneOfSegmentItem.segmentItems
          },
          clone: this.cloneOfSegmentItem,
          helpText: '',
          segmentItems: segment.segmentItems,
          position: index
        }

        this.$store.dispatch(_targetStore + 'UPDATE_THE_SEGMENT', body)
      } else {
        // In case the segment does exists

        const body = {
          supplyRequestId: this.id,
          categoryId: category.id.original,
          groupSegmentId: subCategory.id.original,
          segmentId: segment.id,
          segmentData: {
            title: this.editSegmentTitleInput,
            description: this.editSegmentDescriptionInput,
            segmentItems: this.cloneOfSegmentItem.segmentItems
          },
          clone: this.cloneOfSegmentItem,
          helpText: '',
          segmentItems: segment.segmentItems,
          position: index
        }

        this.$store.dispatch(_targetStore + 'POST_ADDED_SEGMENT', body)
      }

      this.$emit('clicked', true)

      $('.e-add-item--segment')
        .removeClass('disabled')
        .prop('disabled', false)
      $('.e-new-question')
        .removeClass('disabled')
        .prop('disabled', false)

      this.editState = false
    },
    onAddListItem() {
      this.cloneOfSegmentItem.segmentItems.push({
        id: this.getGuidId(),
        title: ''
      })
    },
    onDeleteListItem(index) {
      this.cloneOfSegmentItem.segmentItems.splice(index, 1)
    },
    onCancel(category, subCategory, segment) {
      this.activeFileUploader = false

      const body = {
        categoryId: category.id,
        subCategoryId: subCategory.id,
        segmentId: this.segmentItem.id,
        segment: segment,
        clone: this.cloneOfSegmentItem
      }

      this.$store.dispatch(_targetStore + 'SET_CANCELLED', body)

      this.$emit('clicked', true)

      $('.e-add-item--segment')
        .removeClass('disabled')
        .prop('disabled', false)
      $('.e-new-question')
        .removeClass('disabled')
        .prop('disabled', false)

      this.editState = false
    },
    onDeleteSegment(category, subCategory, segment) {
      const body = {
        supplyRequestId: this.id,
        categoryId: category.id.original,
        groupSegmentId: subCategory.id.original,
        segmentId: segment.id.original
      }

      this.$store.dispatch(_targetStore + 'DELETE_SEGMENT', body)
    },
    toggleFileUploader() {
      this.activeFileUploader = !this.activeFileUploader

      if (this.activeFileUploader) {
        //this.enableFiles = true;
        //this.showCommentList = false;
      } else {
        //this.showCommentList = true;
        this.activeFileUploader = false
        //this.enableFiles = false;
      }
    },
    getGuidId() {
      let uuidValue = '',
        k,
        randomValue
      for (k = 0; k < 32; k++) {
        randomValue = (Math.random() * 16) | 0

        if (k == 8 || k == 12 || k == 16 || k == 20) {
          uuidValue += '-'
        }
        uuidValue += (k == 12
          ? 4
          : k == 16
            ? (randomValue & 3) | 8
            : randomValue
        ).toString(16)
      }
      return uuidValue
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/__main.scss';

.c-edit-segment {
  @include segment;

  + .c-edit-segment {
    margin-top: 8px;
  }
}
</style>
