import { currentGetterContext } from './index'
export const getters = {
  ID: (state: any): string => state.id,
  LOADER: (state: any): boolean => state.loader,
  IS_EDIT_MODE: (state: any) => state.isEditMode,
  EMPLOYEE_PERMISSIONS_DETAILS: (state: any) => state.employeePermissions,
  EDITED_EMPLOYEE_PERMISSIONS_DETAILS: (state: any) => state.editedEmployeePermissions,
  ACTIVE_COUNTRIES: (state: any) => state.activeCountriesValues,
  ACTIVE_COUNTRIES_NAMES: (state: any) => state.activeCountriesNames,
  MESSAGES(...args: any): any {
    const { state } = currentGetterContext(args)
    return state.messages
  }
}
