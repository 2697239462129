






import Vue from 'vue'
import mazNextTiles from '@/components/custom/mazNextTiles/index.vue'
export default Vue.extend({
  name: 'clientDashboardPage',

  components: {
    mazNextTiles
  }
})
