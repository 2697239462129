


















































import mazButton from '@/components/general/mazButton/index.vue'
import mazClientLoginOkta from '@/components/custom/mazClientLoginOkta/index.vue'
import mazAlert from '@/components/general/mazAlert/index.vue'
import type { ClientLoginConfig } from '@/interfaces/authenticationInterfaces'
import screenDetectionMixin from '@/plugins/ScreenDetectionMixin'

export default screenDetectionMixin.extend({
  name: 'mazClientLoginHeader',

  components: {
    mazButton,
    mazClientLoginOkta,
    mazAlert
  },

  props: {
    config: {
      type: Object as () => ClientLoginConfig
    }
  }
})
