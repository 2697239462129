<template>
  <section class="c-collapsible-panel">
    <div class="container-fluid">
      <div
        @click="toggleExpand"
        class="d-flex justify-content-between"
      >
        <h2 class="c-collapsible-panel__title">{{ title }}</h2>
        <div
          :class="{'c-collapsible-panel__collapsible-icon--is-collapsed' : !show}"
          class="c-collapsible-panel__collapsible-icon"
        />
      </div>

      <div class="c-collapsible-panel__body">
        <transition-group
          @after-enter="animationAfterEnter"
          @enter="animationEnter"
          @leave="animationLeave"
          name="collapse"
          tag="div"
        >
          <div
            key="collapse"
            v-if="show"
          >
            <div class="o-separator o-separator--extra-small" />
            <slot />
          </div>
        </transition-group>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'maz-collapsible-panel',
  props: {
    title: {
      type: String
    },
    'is-expanded': {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggleExpand() {
      this.show = !this.show
      this.$emit('on-toggle', this.show)
    },
    animationEnter(el, done) {
      const height = getComputedStyle(el).height
      el.style.height = 0
      el.style.transitionDuration = '0.5s'

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(el).height

      requestAnimationFrame(() => {
        el.style.height = height
      })

      setTimeout(() => {
        // We need the "done" fallback in order to move to the "after-enter" lifecycle
        done()
      }, 500)
    },
    animationAfterEnter(el) {
      // We can't effectively set the height of the component dynamically through CSS so we need to do it though JS
      // At the end of the animation the component is set with inline style height meaning it can't expand if needed
      // because of that we set the height to "auto" once the animation is done
      el.style.height = 'auto'
    },
    animationLeave(el) {
      const height = getComputedStyle(el).height
      el.style.height = height
      el.style.transitionDuration = '0.5s'

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(el).height

      requestAnimationFrame(() => {
        el.style.height = 0
      })
    }
  },
  created() {
    this.show = this.isExpanded
  },
  watch: {
    isExpanded(state) {
      this.show = state
    }
  }
}
</script>

<style scoped lang="scss">
// Documentation into how vue animations work
// https://vuejs.org/v2/guide/transitions.html#Transitioning-Single-Elements-Components

// How to name the animation classes
// https://vuejs.org/v2/guide/transitions.html#Transition-Classes

@import '@/styling/custom/settings/__main.scss';
@import '@/styling/custom/mixins/_animations.scss';

.c-collapsible-panel {
  margin-bottom: 8px;
  padding: 32px 0;
  background-color: $light-steel-blue;

  @include media-breakpoint-up(lg) {
    padding: 48px 0;
  }

  .c-collapsible-panel__title {
    color: $dark-gray;
    cursor: pointer;

    @include media-breakpoint-down(xs) {
      padding-left: 0;
    }

    &:hover {
      color: $gray;
    }
  }
  .c-collapsible-panel__collapsible-icon {
    position: relative;
    width: 24px;
    height: 28px;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      display: inline-block;
      width: 24px;
      height: 24px;
      margin: auto;
      background-color: $dark-blue;
      transform: rotate(180deg);
      transition: all 0.3s ease-out;
      content: '';
      -webkit-mask-image: url('~@/assets/images/icon_collapse_arrow.svg');
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-size: 24px 24px;
      mask-image: url('~@/assets/images/icon_collapse_arrow.svg');
      mask-repeat: no-repeat;
      mask-size: 24px 24px;
    }
  }
  .c-collapsible-panel__collapsible-icon--is-collapsed {
    &:before {
      transform: rotate(0deg);
    }
  }
  .c-collapsible-panel__body {
    transition: all 0.3s ease-out;
  }
}

// Collapse
.collapse-enter-active,
.collapse-leave-active {
  // In order to avoid having two separate values for the
  // duration between the component and the styling, the duration property
  // was set in the component. Because of that most likely any value added
  // to the duration attribute will be overwritten.
  @include animation-mixin(height, '', ease-in-out);
  overflow: hidden;
}
</style>
