<template>
  <div>
    <maz-title :title_main="templateMaintenanceInitialData.pageTitle" />

    <div class="o-separator o-separator--small" />

    <div class="row">
      <!-- Start of service line dropdown -->
      <div class="col-md-6 col-xl-4">
        <div class="form-group">
          <label>{{ labels.supplyRequestTemplateMaintenanceLabelService }}</label>
          <maz-drop-down
            :label="labels.supplyRequestTemplateMaintenanceSelectService"
            :options="templateMaintenanceInitialData.services"
            :placeholder="labels.supplyRequestTemplateMaintenanceSelectService"
            @onChange="onChangeServiceFilter"
            prop="name"
            propvalue="id"
            ref="serviceFilter"
            dataCy="select-service"
          />
        </div>
      </div>
      <!-- End of service line dropdown -->

      <!-- Start of template dropdown -->
      <div class="col-md-6 col-xl-4">
        <div class="form-group">
          <label>{{ labels.supplyRequestTemplateMaintenanceLabelTemplate }}</label>
          <maz-drop-down
            :class="{ disabled: isTemplateDisabled }"
            :label="labels.supplyRequestTemplateMaintenanceSelectTemplate"
            :options="templateMaintenance.templates"
            :placeholder="labels.supplyRequestTemplateMaintenanceSelectTemplate"
            @onChange="onChangeTemplateFilter"
            prop="title"
            propvalue="id"
            ref="templateFilter"
            dataCy="select-template"
          />
        </div>
      </div>
      <!-- End of template dropdown -->

      <div class="col-xs-12 col-xl-4">
        <label class="d-sm-inline-block d-none">
          <!-- Empty label for alignment purposes -->
        </label>
        <div
          class="
            d-flex
            align-items-center
            flex-sm-nowrap flex-wrap
            justify-content-sm-end justify-content-xl-start
          "
        >
          <span class="mx-auto u-mr-sm-20px">{{
            labels.supplyRequestTemplateMaintenanceLabelOr
          }}</span>
          <maz-button
            :disabled="isDeleteButtonDisabled"
            :label="labels.supplyRequestTemplateMaintenanceLabelsDeleteTemplate"
            class="c-btn--red mt-2 mt-sm-0 order-2 order-sm-1"
            @click.native="modals.showDeleteConfirmationMessage = true"
          />
          <maz-button
            :disabled="isButtonDisabled"
            :label="labels.supplyRequestTemplateMaintenanceCreateTemplate"
            @click.native="createNewTemplate"
            class="order-1 order-sm-2"
          />
        </div>
      </div>
    </div>

    <div class="o-separator o-separator--small" />

    <div v-show="showAdditionalFilters">
      <div class="row">
        <!-- Start of phase dropdown -->
        <div class="col-md-6 col-xl-4">
          <div class="form-group">
            <label>{{ labels.supplyRequestTemplateMaintenanceLabelPhase }}</label>
            <maz-drop-down
              :label="labels.supplyRequestTemplateMaintenanceSelectPhase"
              :options="templateMaintenanceReferenceData.phases || []"
              :placeholder="labels.supplyRequestTemplateMaintenanceSelectPhase"
              @onChange="onChangePhaseFilter"
              prop="name"
              propvalue="id"
              ref="phaseFilter"
              dataCy="select-phase"
            />
          </div>
        </div>
        <!-- End of phase dropdown -->

        <!-- Start of language dropdown -->
        <div class="col-md-6 col-xl-4">
          <div class="form-group">
            <label>{{ labels.supplyRequestTemplateMaintenanceLabelLanguage }}</label>
            <maz-drop-down
              :label="labels.supplyRequestTemplateMaintenanceSelectLanguage"
              :options="templateMaintenanceReferenceData.languages"
              :placeholder="labels.supplyRequestTemplateMaintenanceSelectLanguage"
              @onChange="onChangeLanguageFilter"
              prop="name"
              propvalue="code"
              ref="languageFilter"
              dataCy="select-language"
            />
          </div>
        </div>
        <!-- End of language dropdown -->
      </div>
      <div class="o-separator o-separator--small" />

      <div class="row">
        <!-- Start of countries dropdown -->
        <div class="col-md-6 col-xl-4">
          <div class="form-group">
            <label>{{ labels.supplyRequestTemplateMaintenanceLabelCountries }}</label>
            <maz-multi-dropdown
              v-if="templateData.availableCountries"
              data-cy="countriesDropdown"
              v-model="countriesSelectedValues"
              :label="labels.supplyRequestTemplateMaintenanceSelectCountries"
              :options="templateData.availableCountries"
              label-field="name"
              value-field="value"
              disabled-field="disabled"
              :placeholder="labels.supplyRequestTemplateMaintenanceSelectCountries"
              ref="countriesFilter"
              @input="onInputCountries"
            />
          </div>
        </div>
        <div class="col-12">
          <!-- selected countries -->
          <p class="d-flex align-items-center h-100 u-font-size-14">
            <template v-if="countriesSelectedValues.length > 0">
              {{ labels.supplyRequestTemplateMaintenanceCountriesSelected }}:
            </template>
            <template v-else>
              {{ labels.supplyRequestTemplateMaintenanceNoCountriesSelected }}
            </template>
            {{ getSelectedCountriesList(countriesSelectedValues) }}
          </p>
        </div>
        <!-- End of countries dropdown -->
      </div>

      <div class="o-separator o-separator--small" />

      <div class="row" v-show="showAdditionalFields">
        <div class="col-md-6 col-xl-4">
          <div class="form-group">
            <label>{{ labels.supplyRequestTemplateMaintenanceTitle }}</label>
            <input
              @input="onInputTitle"
              class="form-control"
              ref="titleInput"
              v-model="titleInput"
            />
          </div>
        </div>
      </div>
      <div class="row" v-show="showAdditionalFields">
        <div class="col-12">
          <div class="form-group">
            <label>{{ labels.supplyRequestTemplateMaintenanceDescription }}</label>
            <textarea
              @input="onInputDescription"
              class="form-control c-textarea--height"
              ref="descriptionInput"
              v-model="descriptionInput"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="o-separator o-separator--small" />

    <div class="o-block" v-if="showAdditionalFields">
      <maz-template-maintenance-category-manager v-if="showAdditionalFields" />

      <maz-button
        :disabled="isSaveButtonDisabled"
        :label="labels.save"
        @click.native="saveTemplate"
        class="float-right"
      />
    </div>

    <!-- Modal create new template -->
    <maz-modal
      size="modal-md"
      @on-close="modals.showCreateTemplateConfirmationMessage = false"
      v-if="modals.showCreateTemplateConfirmationMessage"
    >
      <template>
        <p>{{ labels.supplyRequestTemplateMaintenanceConfirmLostChanges }}</p>
      </template>
      <template slot="modal-footer">
        <maz-button
          :label="labels.cancel"
          @click.native="modals.showCreateTemplateConfirmationMessage = false"
          class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
        />
        <maz-button
          :label="labels.confirm"
          @click.native="onReCreateNewTemplate"
          class="c-btn--green order-1 order-sm-2"
        />
      </template>
    </maz-modal>

    <!-- Modal delete template -->
    <maz-modal
      size="modal-md"
      v-if="modals.showDeleteConfirmationMessage"
      @on-close="modals.showDeleteConfirmationMessage = false"
    >
      <template v-if="countriesSelectedValues.length > 1">
        <p>
          {{
            labels.supplyRequestTemplateMaintenanceConfirmDeleteTemplateMultipleCountries
          }}
        </p>
      </template>
      <template v-else>
        <p>{{ labels.supplyRequestTemplateMaintenanceConfirmDeleteTemplate }}</p>
      </template>
      <template slot="modal-footer">
        <maz-button
          :label="labels.cancel"
          class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
          @click.native="modals.showDeleteConfirmationMessage = false"
        />
        <maz-button
          :label="labels.confirm"
          @click.native="onDeleteTemplate"
          class="c-btn--green order-1 order-sm-2"
        />
      </template>
    </maz-modal>

    <!-- Modal edit and save template -->
    <maz-modal
      size="modal-md"
      v-if="modals.showSaveConfirmationMessage"
      @on-close="modals.showSaveConfirmationMessage = false"
    >
      <p>
        {{ labels.supplyRequestTemplateMaintenanceConfirmSaveTemplateMultipleCountries }}
      </p>
      <template slot="modal-footer">
        <maz-button
          :label="labels.cancel"
          class="c-btn--empty order-2 order-sm-1 mt-2 mt-sm-0"
          @click.native="modals.showSaveConfirmationMessage = false"
        />
        <maz-button
          :label="labels.confirm"
          @click.native="onConfirmSave"
          class="c-btn--green order-1 order-sm-2"
        />
      </template>
    </maz-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import mazTitle from '../../general/mazTitle'
import mazButton from '../../general/mazButton'
import mazTemplateMaintenanceCategoryManager from '../mazTemplateMaintenanceCategoryManager'
import mazDropDown from '../../general/mazDropDown'
import mazMultiDropdown from './../../general/mazMultiDropdown'
import mazModal from '../../general/mazModal'

const _targetStore = 'supplyRequest2TemplateMaintenanceStore/'

export default {
  name: 'mazTemplateMaintenanceFilters',
  components: {
    mazTitle,
    mazButton,
    mazTemplateMaintenanceCategoryManager,
    mazDropDown,
    mazMultiDropdown,
    mazModal
  },
  props: {
    header_title: {
      required: false,
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      phaseSelectedValue: '',
      serviceSelectedValue: '',
      languageSelectedValue: '',
      templateSelectedValue: '',
      countriesSelectedValues: [],
      isPhaseDisabled: true,
      isLanguageDisabled: true,
      isTemplateDisabled: true,
      isDueDateDisabled: true,
      showAdditionalFilters: false,
      titleInput: '',
      descriptionInput: '',
      isButtonDisabled: true,
      isDeleteButtonDisabled: true,
      serviceId: '',
      selectedATemplate: 0,
      createdANewTemplate: 0,
      modals: {
        showSaveConfirmationMessage: false,
        showDeleteConfirmationMessage: false,
        showCreateTemplateConfirmationMessage: false
      }
    }
  },
  computed: {
    ...mapGetters({
      templateData: _targetStore + 'TEMPLATE_DATA',
      templateMaintenance: _targetStore + 'TEMPLATES',
      templateMaintenanceInitialData: _targetStore + 'TEMPLATE_MAINTENANCE_INITIAL_DATA',
      templateMaintenanceReferenceData:
        _targetStore + 'TEMPLATE_MAINTENANCE_REFERENCE_DATA',
      templateMaintenanceTemplatesLoaded:
        _targetStore + 'TEMPLATE_MAINTENANCE_TEMPLATES_LOADED',
      templateDataTemplateDeleted: _targetStore + 'TEMPLATE_DATA_TEMPLATE_DELETED',
      templateDataTemplateCreated: _targetStore + 'TEMPLATE_DATA_TEMPLATE_CREATED',
      templateDataTemplateCreatedCheck:
        _targetStore + 'TEMPLATE_DATA_TEMPLATE_CREATED_CHECK',
      templateDataTemplateUpdated: _targetStore + 'TEMPLATE_DATA_TEMPLATE_UPDATED',
      templateDataTemplateUpdatedCheck:
        _targetStore + 'TEMPLATE_DATA_TEMPLATE_UPDATED_CHECK',
      labels: _targetStore + 'LABELS'
    }),
    showAdditionalFields() {
      if (
        this.phaseSelectedValue != '' &&
        this.languageSelectedValue != '' &&
        (this.countriesSelectedValues.length ||
          this.templateMaintenanceInitialData.permissions.MayCreateWithoutCountry ===
            true)
      ) {
        return true
      } else {
        return false
      }
    },
    isSaveButtonDisabled() {
      let result = false

      if (this.titleInput === '' || this.descriptionInput === '') {
        result = true
      }

      return result
    }
  },
  methods: {
    onChangeServiceFilter(value) {
      this.serviceSelectedValue = value

      this.templateSelectedValue = '' // reset
      this.phaseSelectedValue = '' // reset
      this.languageSelectedValue = '' // reset

      this.showAdditionalFilters = false
      this.isButtonDisabled = false
      this.isTemplateDisabled = false

      this.$store.dispatch(
        _targetStore + 'GET_TEMPLATES_BY_SERVICE_ID',
        this.serviceSelectedValue
      )
      this.$store.dispatch(
        _targetStore + 'GET_TEMPLATE_MAINTENANCE_REFERENCE_DATA',
        this.serviceSelectedValue
      )
    },
    onChangeTemplateFilter(value) {
      this.templateSelectedValue = value
      this.selectedATemplate += 1
    },
    onChangePhaseFilter(value) {
      this.phaseSelectedValue = value

      const body = {
        phaseId: this.phaseSelectedValue
      }

      this.$store.dispatch(_targetStore + 'SET_PHASE_FILTER', body)
    },
    onChangeLanguageFilter(value) {
      this.languageSelectedValue = value

      const body = {
        languageCode: this.languageSelectedValue
      }

      this.$store.dispatch(_targetStore + 'SET_LANGUAGE_FILTER', body)
    },
    onInputCountries(countries) {
      const body = {
        countries: countries
      }
      this.$store.dispatch(_targetStore + 'SET_COUNTRIES', body)
    },
    onInputTitle(event) {
      const body = {
        title: event.target.value
      }

      this.$store.dispatch(_targetStore + 'SET_TITLE', body)
    },
    onInputDescription(event) {
      const body = {
        description: event.target.value
      }

      this.$store.dispatch(_targetStore + 'SET_DESCRIPTION', body)
    },
    createNewTemplate() {
      this.createdANewTemplate += 1

      if (this.templateSelectedValue !== '') {
        this.modals.showCreateTemplateConfirmationMessage = true
      } else {
        this.onReCreateNewTemplate()
      }
    },
    saveTemplate() {
      this.phaseCode = this.getPhaseCode()

      const body = {
        templateDataInfo: this.templateData,
        serviceId: this.serviceSelectedValue,
        phaseCode: this.phaseCode
      }

      if (this.templateSelectedValue == '') {
        this.$store.dispatch(_targetStore + 'POST_TEMPLATE_MAINTENANCE_CREATE', body)
      } else {
        // check if multiple countries affected
        if (this.countriesSelectedValues.length > 1) {
          this.modals.showSaveConfirmationMessage = true
        } else {
          this.$store.dispatch(_targetStore + 'POST_TEMPLATE_MAINTENANCE_UPDATE', body)
        }
      }
    },
    onConfirmSave() {
      this.modals.showSaveConfirmationMessage = false
      this.phaseCode = this.getPhaseCode()

      const body = {
        templateDataInfo: this.templateData,
        serviceId: this.serviceSelectedValue,
        phaseCode: this.phaseCode
      }
      this.$store.dispatch(_targetStore + 'POST_TEMPLATE_MAINTENANCE_UPDATE', body)
    },

    getPhaseCode() {
      let phaseIndex = 0

      this.templateMaintenanceReferenceData.phases.forEach((value, index) => {
        if (value.id === this.templateData.phaseId) {
          phaseIndex = index
        }

        return index
      })

      return this.templateMaintenanceReferenceData.phases[phaseIndex].code
    },
    onReCreateNewTemplate() {
      this.modals.showCreateTemplateConfirmationMessage = false
      this.showAdditionalFilters = true
      this.templateSelectedValue = ''
      this.languageSelectedValue = ''

      this.$refs.templateFilter.selectedItem = {}
      this.$refs.phaseFilter.selectedItem = {}
      this.$refs.languageFilter.selectedItem = {}

      this.$store.dispatch(_targetStore + 'RESET_TEMPLATE_DATA')
    },
    onDeleteTemplate() {
      this.modals.showDeleteConfirmationMessage = false
      this.$store.dispatch(_targetStore + 'DELETE_TEMPLATE', this.templateSelectedValue)
    },
    getSelectedCountriesList(arr) {
      const countries = this.templateData.availableCountries
      if (arr && arr.length) {
        return arr
          .map(abbr => countries.find(countryObj => countryObj.value === abbr).name)
          .sort((a, b) => (a === b ? 0 : a < b ? -1 : 1)) //compare function sorts alphabetically ascending.
          .reduce((acc, curr) => acc + ', ' + curr)
      }
      return ''
    }
  },
  watch: {
    templateMaintenanceTemplatesLoaded: {
      handler(newValue) {
        if (newValue == true) {
          if (
            !this.templateDataTemplateCreatedCheck === false ||
            !this.templateDataTemplateUpdatedCheck === false
          ) {
            this.templateSelectedValue == ''
            this.$refs.templateFilter.selectedItem = {}
          }

          if (!this.templateMaintenance.templates.length > 0) {
            this.isTemplateDisabled = true
          } else {
            //this.isTemplateDisabled = false
          }

          if (this.templateDataTemplateUpdatedCheck === true) {
            this.$nextTick(() => {
              this.$refs.templateFilter.selectedItem = {
                //title: this.templateDataTemplateUpdated.result.Title,
                id: this.templateDataTemplateUpdated.result.Id
              }

              this.$store.dispatch(
                _targetStore + 'RESET_TEMPLATE_MAINTENANCE_TEMPLATE_UPDATED'
              )
            })
          }

          if (this.templateDataTemplateCreatedCheck === true) {
            this.$nextTick(() => {
              this.$refs.templateFilter.selectedItem = {
                title: this.templateDataTemplateCreated.result.Title,
                id: this.templateDataTemplateCreated.result.Id
              }

              this.$store.dispatch(
                _targetStore + 'RESET_TEMPLATE_MAINTENANCE_TEMPLATE_CREATED'
              )
            })
          }

          this.$store.dispatch(
            _targetStore + 'RESET_TEMPLATE_MAINTENANCE_TEMPLATES_LOADED'
          )
        }
      },
      deep: true
    },
    templateData: {
      handler() {
        this.languageSelectedValue = this.templateData.languageCode
        this.phaseSelectedValue = this.templateData.phaseId
        this.countriesSelectedValues = this.templateData.countries
        this.titleInput = this.templateData.title
        this.descriptionInput = this.templateData.description
        this.countriesSelectedValues = this.templateData.countries

        if (this.templateSelectedValue != '') {
          const preSelectPhase = this.templateMaintenanceReferenceData.phases.find(
            item => item.id === this.phaseSelectedValue
          )
          if (preSelectPhase === undefined) {
            // No item will be preselected
            this.$refs.phaseFilter.selectedItem = {}
          } else {
            this.$refs.phaseFilter.selectedItem = preSelectPhase
          }

          const preSelectLanguage = this.templateMaintenanceReferenceData.languages.find(
            item => item.code === this.languageSelectedValue
          )
          this.$refs.languageFilter.selectedItem = preSelectLanguage
        }
      },
      deep: true
    },
    templateSelectedValue: {
      handler(templateSelectedValue) {
        if (templateSelectedValue) {
          this.$store.dispatch(_targetStore + 'GET_TEMPLATE_DATA', templateSelectedValue)
          this.showAdditionalFilters = true
          this.isDeleteButtonDisabled = false
        } else {
          this.$store.dispatch(_targetStore + 'RESET_TEMPLATE_DATA')
          this.isDeleteButtonDisabled = true
        }
      },
      deep: true
    },
    templateDataTemplateDeleted: {
      handler(newValue) {
        this.phaseSelectedValue = '' // reset
        this.languageSelectedValue = '' // reset

        if (newValue == true) {
          this.$refs.serviceFilter.selectedItem = ''
          this.$refs.templateFilter.selectedItem = ''

          this.showAdditionalFilters = false
          this.isTemplateDisabled = true
          this.isButtonDisabled = true
          this.isDeleteButtonDisabled = true

          this.$store.dispatch(_targetStore + 'RESET_TEMPLATE_DATA_TEMPLATE_DELETED')
        }
      },
      deep: true
    },
    templateDataTemplateCreated: {
      handler(newValue) {
        if (newValue.templateCreated == true) {
          this.$refs.templateFilter.selectedItem = {
            title: this.templateDataTemplateCreated.result.Title,
            id: this.templateDataTemplateCreated.result.Id
          }

          this.$store.dispatch(
            _targetStore + 'GET_TEMPLATES_BY_SERVICE_ID',
            this.serviceSelectedValue
          )

          this.templateSelectedValue = this.templateDataTemplateCreated.result.Id
        }
      },
      deep: true
    },
    templateDataTemplateUpdated: {
      handler(newValue) {
        this.phaseSelectedValue = '' // reset
        this.languageSelectedValue = '' // reset
        if (newValue.templateUpdated == true) {
          this.$refs.serviceFilter.selectedItem = ''
          this.$refs.templateFilter.selectedItem = ''
          this.showAdditionalFilters = false
          this.isTemplateDisabled = true
          this.isButtonDisabled = true
          this.isDeleteButtonDisabled = true
          this.$store.dispatch(
            _targetStore + 'GET_TEMPLATES_BY_SERVICE_ID',
            this.serviceSelectedValue
          )

          this.templateSelectedValue = this.templateDataTemplateUpdated.result.Id
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styling/custom/settings/__main.scss';

.c-template-question {
  padding: 35px;
  background-color: $light-steel-blue;
  border-radius: 4px;

  + .c-template-question {
    margin-top: 16px;
  }

  &.c-template-question--edit {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);

    .c-template-question__title-input {
      width: 100%;
      max-width: 450px;
      padding-left: 20px;
    }
  }

  .c-template-question__title {
    padding: 0 0 0 20px;
  }

  .c-template-question__content {
    margin-top: 14px;
    padding: 0 0 0 48px;
  }

  .c-template-question__icons {
    display: flex;
    align-items: center;

    .c-template-question__icon {
      margin-left: 20px;
      cursor: pointer;

      &.c-template-question__icon--lock {
        width: 20px;
        height: 26px;
        background-image: url('~@/assets/images/icon_lock_open.svg');

        &:hover {
          background-image: url('~@/assets/images/icon_lock_open(hover).svg');
        }
      }

      &.c-template-question__icon--drag {
        width: 25px;
        height: 24px;
        background-image: url('~@/assets/images/icon_drag.svg');

        &:hover {
          background-image: url('~@/assets/images/icon_drag(hover).svg');
        }
      }

      &.c-template-question__icon--edit {
        width: 25px;
        height: 24px;
        background-image: url('~@/assets/images/icon_edit.svg');

        &:hover {
          background-image: url('~@/assets/images/icon_edit(hover).svg');
        }
      }

      &.c-template-question__icon--trash {
        width: 20px;
        height: 24px;
        background-image: url('~@/assets/images/icon_delete.svg');

        &:hover {
          background-image: url('~@/assets/images/icon_delete(hover).svg');
        }
      }
    }
  }
}

.c-btn {
  white-space: inherit;
  word-break: break-word;

  @include media-breakpoint-down(lg) {
    white-space: nowrap;
  }
}
</style>
