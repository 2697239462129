import debounce from 'lodash/debounce'
import Vue from 'vue'

const lg = 1200
// add more.

export default Vue.extend({
  data() {
    return {
      screen: window.screen,
      availWidth: window.screen.availWidth,
      availHeight: window.screen.availHeight
    }
  },
  created() {
    window.addEventListener('resize', debounce(this.onWindowResize, 300))
  },
  destroyed() {
    window.removeEventListener('resize', this.onWindowResize)
  },
  methods: {
    onWindowResize(e: any): void {
      this.screen = e.target.screen
      this.availWidth = e.target.screen.availWidth
      this.availHeight = e.target.screen.availHeight
    }
  },

  computed: {
    isDesktop(): boolean {
      return this.availWidth >= lg
    }
    //add more.
  }
})
