




















































































import mazTitle from '@/components/general/mazTitle/index.vue'
import mazFadeOverlay from '@/components/general/mazFadeOverlay/index.vue'
import mazPopover from '@/components/general/mazPopover/index.vue'
import mazButton from '@/components/general/mazButton/index.vue'
import mazTabNavigation from '@/components/general/mazTabNavigation/index.vue'

import mazClientMaintenanceOrganizationOverview from '@/components/custom/mazClientMaintenanceOrganizationOverview/index.vue'
import mazClientMaintenanceOrganizationDetails from '@/components/custom/mazClientMaintenanceOrganizationDetails/index.vue'
import mazClientMaintenanceOrganizationPermissions from '@/components/custom/mazClientMaintenanceOrganizationPermissions/index.vue'
import mazClientMaintenanceOrganizationServices from '@/components/custom/mazClientMaintenanceOrganizationServices/index.vue'
import mazClientMaintenanceOrganizationEmployees from '@/components/custom/mazClientMaintenanceOrganizationEmployees/index.vue'

import Vue from 'vue'
import store from '@/store/store2'
export default Vue.extend({
  name: 'mazClientMaintenanceOrganization',

  components: {
    mazTitle,
    mazFadeOverlay,
    mazPopover,
    mazButton,
    mazTabNavigation,
    mazClientMaintenanceOrganizationOverview,
    mazClientMaintenanceOrganizationDetails,
    mazClientMaintenanceOrganizationPermissions,
    mazClientMaintenanceOrganizationServices,
    mazClientMaintenanceOrganizationEmployees
  },

  data() {
    return {
      showActionsOverlay: false
    }
  },

  computed: {
    tabNavigation(): number {
      return store.getters.clientMaintenance.organization.TAB_NAVIGATION
    },
    clientDetails(): object {
      return store.getters.clientMaintenance.organization.CLIENT_DETAILS
    },
    labels(): object {
      return store.getters.clientMaintenance.organization.LABELS
    },
    permissions(): object {
      return store.getters.clientMaintenance.organization.PERMISSIONS
    }
  },

  methods: {
    redirectToPage(url: string) {
      window.location.href = url
    },

    updateClientMaintenanceTabIndex(index: number) {
      store.dispatch.clientMaintenance.organization.UPDATE_CLIENT_MAINTENANCE_TAB_INDEX(
        index
      )
    }
  }
})
